// @ts-nocheck
import { getDispatch } from 'reducers';
import { takeEvery } from 'redux-saga/effects';
import { deleteAPIDataV2, deleteAPIDataV3, getAPIDataV2, getAPIDataV3, patchAPIDataV2, patchAPIDataV3, postAPIDataV3, putAPIDataV3 } from 'utils/api';
import { setBookmarksList } from './reducer';
import * as sagaActions from './sagaActions';
import { putAPIDataV2 } from 'utils/api';
import { fetchGridFiltersAsync } from 'models/dynamicGrid/sagas';
import { refreshGridData } from 'models/dynamicGrid/sagaActions';
import { fetchTableColumnsAg } from 'models/tableColumns/sagaActions';
import { toggleLoader } from 'models/loaders/sagaActions';
import { REQUESTS } from 'utils/requests';
import { updateGridMeta } from 'models/dynamicGrid/sagaActions';

async function fetchBookmarksList({ payload }) {
	const dispatch = getDispatch();
	dispatch(
		toggleLoader({
			component: 'bookmarks',
			isLoading: true
		})
	);

	try {
		const { data } = await getAPIDataV3({
      service: REQUESTS.GET.GET_BOOKMARKS.service,
      url: REQUESTS.GET.GET_BOOKMARKS.url,
	 queryParams: { tableId: payload?.tableId },
    });
		dispatch(
			setBookmarksList({
				tableId: payload?.tableId,
				bookmarks: data
			})
		);
	} catch (e) {
		console.log(e)
	}


	dispatch(
		toggleLoader({
			component: 'bookmarks',
			isLoading: false
		})
	);
}
async function applyBookmark({ payload }) {
	const dispatch = getDispatch();

	dispatch(
		toggleLoader({
			component: 'bookmarks',
			isLoading: true
		})
	);
	try {

		await putAPIDataV3({
			service: REQUESTS.PUT.APPLY_BOOKMARK.service, url: REQUESTS.PUT.APPLY_BOOKMARK.url,
			pathParams: {
				bookmarkId: payload.bookmarkId
			} ,
			body: {
				tableId: payload?.tableId,
				}});
	// window.location.reload();

		await fetchGridFiltersAsync({ payload });
		dispatch(
			updateGridMeta(payload?.tableId, {
					refreshTimestamp: new Date()
			}, true)
		);
	} catch (e) {
		console.log(e)
	}
	dispatch(
		toggleLoader({
			component: 'bookmarks',
			isLoading: false
		})
	);
	// dispatch(refreshGridData({ tableId: payload.tableId }));
}

async function saveBookmark({ payload }) {
	const dispatch = getDispatch();

	dispatch(
		toggleLoader({
			component: 'bookmarks',
			isLoading: true
		})
	);
	const { bookmarkName, tableId, bookmarkId } = payload;
	try {
		if (bookmarkId) {
			await patchAPIDataV3({
        service: REQUESTS.PATCH.CREATE_UPDATE_BOOKMARK.service,
        url: REQUESTS.PATCH.CREATE_UPDATE_BOOKMARK.url,
        pathParams: { bookmarkId },
        queryParams: { bookmarkName, tableId },
      });
		} else {
			await postAPIDataV3({service: REQUESTS.POST.CREATE_UPDATE_BOOKMARK.service, url: REQUESTS.POST.CREATE_UPDATE_BOOKMARK.url, body: { bookmarkName, tableId }});
		}
	} catch (e) {
		console.log(e);
	}
	dispatch(
		toggleLoader({
			component: 'bookmarks',
			isLoading: false
		})
	);
	dispatch(sagaActions.fetchBookmarksList({ tableId }));
}

async function deleteBookmark({ payload }) {
	const dispatch = getDispatch();
	dispatch(
		toggleLoader({
			component: 'bookmarks',
			isLoading: true
		})
	);
  const { bookmarkId, tableId } = payload;
	try {
		await deleteAPIDataV3({
      service: REQUESTS.DELETE.DELETE_BOOKMARK.service,
      url: REQUESTS.DELETE.DELETE_BOOKMARK.url,
      pathParams: { bookmarkId },
    });
	}
	catch (e) {
		console.log(e)
	}

	dispatch(
		toggleLoader({
			component: 'bookmarks',
			isLoading: false
		})
	);
	dispatch(sagaActions.fetchBookmarksList({ tableId }));
}

export default function* rootSaga() {
	return [
		yield takeEvery(sagaActions.fetchBookmarksList, fetchBookmarksList),
		yield takeEvery(sagaActions.applyBookmark, applyBookmark),
		yield takeEvery(sagaActions.deleteBookmark, deleteBookmark),
		yield takeEvery(sagaActions.saveBookmark, saveBookmark)
	];
}
