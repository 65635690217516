// @ts-nocheck
import React from 'react';
import { useOutsideAlerter } from 'utils/helpers';
import './modal.css';
import classNames from 'classnames';

interface Props {
	children: React.ReactNode;
	onDomRef: any;
}

const ModalBody = (props: Props) => {
	return (
		<div ref={props.onDomRef} className="app-modal--body">
			{props.children}
		</div>
	);
};

export default ModalBody;
