export const OPTION_IDENTIFIERS = {
	APP_COLUMN_ALIASES: 'APP_COLUMN_ALIASES',
	USER_ROLES: 'USER_ROLES',
};
export const OPTIONS = [
	{
		title: 'App Column Aliases',
		description: 'Create or edit App Column Aliases',
		buttonText: 'View',
		url: '/settings/columnaliases'
	},
	{
		title: 'User Roles',
		description: 'User Roles',
		buttonText: 'View',
		url: '/settings/roles'
	},
	{
		title: 'App Users',
		description: 'App Users',
		buttonText: 'View',
		url: '/settings/users'
		
	},
	{
		title: 'App Data Restrictions',
		description: 'App Data Restrictions',
		buttonText: 'View',
		url: '/settings/restrictions'
		
	}
];
