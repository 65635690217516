// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const togglePlanogramState = createAction(
    'planogramDetails/togglePlanogramState',
    function prepare({     isActive, row, isSelectAll,selectAllSearch, selection ,isBulkUpdate }) {
        return {
            payload: {
                isActive, row, isSelectAll,selectAllSearch, selection ,isBulkUpdate
            },
        };
    }
);




export const updatePlanogramStores = createAction(
    'planogram/updatePlanogramStores',
    function prepare({     storeIds,
        planogramId,
        isSelectAll,
        selectAllSearch }) {
        return {
            payload: {
                storeIds,
                planogramId,
                isSelectAll,
                selectAllSearch
            },
        };
    }
);
