// @ts-nocheck
import { initialState } from './reducer';
import * as constants from './constants';

const uploadSelector = state => state.upload || initialState;

export const getUploadStatus = state => {
	return uploadSelector(state).uploadStatus;
};

export const fetchCustomers = state => {
	return uploadSelector(state).customers;
};

export const getActivePage = state => {
	return uploadSelector(state).activePage;
};
export const fetchWarehouses = state => {
	return uploadSelector(state).warehouses;
};

export const getUploadMetadata = (state, parentTableId) => {
	return uploadSelector(state)?.metaData?.[parentTableId];
};
