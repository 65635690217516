// @ts-nocheck
import _ from 'lodash';
import { put, takeEvery, all, select } from 'redux-saga/effects';
import { getAPIDataV2, getAPIDataV3, postAPIDataV3, putAPIDataV2, putAPIDataV3 } from 'utils/api';
import * as sagaActions from './sagaActions';
import * as constants from './constants';
import * as slice from './reducer';
import * as selectors from './selectors';
import { triggerNotification } from 'organisms/notifications/sagaActions';
import {
	fetchComponentUserSettings,
	refreshGridData,
	updateComponentUserSettings
} from 'models/dynamicGrid/sagaActions';
import { toggleLoader } from 'models/loaders/sagaActions';
import { getDispatch } from 'reducers';
import { toggleLoaderState, updateComponentRequestStatus } from 'models/loaders/reducer';
import { REQUEST_STATUS } from 'models/loaders/constants';
import { getTableColumns, getTableUploadColumns } from 'models/tableColumns/sagas';
import exportFromJSON from 'export-from-json';
import { REQUESTS } from 'utils/requests';
import { isRequestPending } from 'models/loaders/selectors';
import { onReceiveEvent } from 'models/subscriptions/sagaActions';

function* onActivate({ payload }) {}

function* resetUpload() {
	yield put(slice.resetUpload());
}
export const checkStatus = async (statusID: any) => {
	const { data } = await getAPIDataV3({
	  ...REQUESTS.GET.GET_PROCESS_STATUS,
	  pathParams: { statusId: statusID },
	  queryParams: { refresh: new Date().getTime().toString() },
	});
	return data?.data?.CurrentStatus?.toLowerCase();
  };
  
async function triggerUploadV2({ payload }) {
	const { tableId, params, uploadParams } = payload;

	const dispatch = getDispatch();
	dispatch(
		toggleLoader({
			component: 'upload',
			isLoading: true
		})
	);
	const data = new FormData();
	data.append('file', params?.file?.originFileObj);
	data.append('tableId', tableId);
	data.append('isIncrementalUpload', params.isIncrementalUpload);
	data.append('parentTableId', params.parentTableId);
	data.append('importType', params?.importType ?? constants.UPLOAD_TYPE.CSV);
	if ((params.parentTableId == 7 || params.parentTableId == 25) && params.importType === constants.UPLOAD_TYPE.ERP) {
		data.append('warehouse', params.warehouse);
		data.append('customer', params.customer);
	}

	const upload = await putAPIDataV3({service: uploadParams?.service, url: uploadParams?.url, body: data})
		.then(response => response)
		.catch(err => ({ isError: true, error: err }));
	dispatch(updateComponentUserSettings({ tableId: params.parentTableId, component: 'file-upload', metaData: _.omit(params, ['file']) }));

	if(upload?.data?.statusId){
		let interval = setInterval(async ()=>{
			let data = await checkStatus(upload?.data?.statusId);
			if(data === 'complete'){
				clearInterval(interval);
				dispatch(
					toggleLoader({
						component: 'upload',
						isLoading: false
					})
				);
				dispatch(
					slice.setActivePage({
						activePage: constants.PAGES.UPLOAD_GRID
					})
				);
			}
	
		},20000)
	}

	
	if (upload?.data?.isAsyncUpload) {
			return;
		}
	if (upload.isError) {
		dispatch(
			toggleLoader({
				component: 'upload',
				isLoading: false
			})
		);
		dispatch(triggerNotification({ message: upload?.error?.data?.message, duration: 5000 }));
		return;
	}

	dispatch(
		slice.setActivePage({
			activePage: constants.PAGES.UPLOAD_GRID
		})
	);
	dispatch(
		toggleLoader({
			component: 'upload',
			isLoading: false
		})
	);
}

function* onUploadFile({ payload }) {
	yield put(
		toggleLoader({
			component: 'upload',
			isLoading: true
		})
	);
	const { tableId, params } = payload;
	const data = new FormData();
	data.append('file', params.file);
	data.append('tableId', tableId);
	data.append('isIncrementalUpload', params.isIncrementalUpload);
	data.append('importType', params.importType);
	if ((tableId == 7 || tableId == 25) && params.importType === constants.UPLOAD_TYPE.ERP) {
		data.append('warehouse', params.warehouse);

		data.append('customer', params.customer);
	}

	const upload = yield postAPIDataV3({service: REQUESTS.POST.UPLOAD_CSV.service, url: REQUESTS.POST.UPLOAD_CSV.url, body: data})
		.then(response => response)
		.catch(err => ({ isError: true, error: err }));
	
	if (upload?.isError) {
		dispatch(triggerNotification({ message: upload?.error?.data?.error?.message }));
		dispatch(
			toggleLoader({
				component: 'upload',
				isLoading: false
			})
		);
		return;
	}
	yield put(updateComponentUserSettings({ tableId, component: 'file-upload', metaData: _.omit(params, ['file']) }));

	

	yield put(
		toggleLoader({
			component: 'upload',
			isLoading: false
		})
	);
}

function* gridUploadEventHandler({ payload }) {

	const dispatch = getDispatch();
	dispatch(
		slice.setActivePage({
			activePage: constants.PAGES.UPLOAD_GRID
		})
	);
	yield put(
		toggleLoader({
			component: 'upload',
			isLoading: false
		})
	);
}

function* fetchWarehouses() {
	const warehouses = yield getAPIDataV3({
    service: REQUESTS.GET.GET_WAREHOUSES.service,
    url: REQUESTS.GET.GET_WAREHOUSES.url,
  })
    .then((response) => {
      return response?.data.data;
    })
    .catch((err) => ({ isError: true, error: err }));

	if (warehouses?.isError) {
		yield put(
			triggerNotification({ message: 'An error occurred while fetching warehouses, please try again later.' })
		);
		return;
	}
	const fetchedWarehouses = [];
	_.map(warehouses, singleWarehouse => {
		const data = {
			displayName: singleWarehouse.Warehouse,
			value: singleWarehouse.WarehouseID
		};
		fetchedWarehouses.push(data);
	});

	yield put(
		slice.updateWarehouses({
			fetchedWarehouses
		})
	);
}

function* fetchCustomers() {
	const customers = yield postAPIDataV3({
		service: REQUESTS.POST.GET_GRID_DETAILS_V2.service, url: REQUESTS.POST.GET_GRID_DETAILS_V2.url, pathParams: {
		tableId: 10
	}})
		.then(response => {
			return response?.data?.data;
		})
		.catch(err => ({ isError: true, error: err }));

	if (customers?.isError) {
		yield put(
			triggerNotification({ message: 'An error occurred while fetching customers, please try again later.' })
		);
		return;
	}
	const fetchedCustomers = [];
	_.map(customers, singlecustomer => {
		const data = {
			displayName: singlecustomer.CustomerName,
			value: singlecustomer.CustomerID
		};
		fetchedCustomers.push(data);
	});

	yield put(
		slice.updateCustomers({
			fetchedCustomers
		})
	);
}


async function onDownloadTemplate({ payload }) {
	const { tableId, params, uploadParams } = payload;

	let uploadTableColumns = await getTableUploadColumns(tableId, true);
	console.log(uploadTableColumns)
	uploadTableColumns = [
		_.reduce(
			uploadTableColumns?.response?.data,
			(output, row) => {
				output[row.AppColumnName] = '';
				return output;
			},
			{}
		)
	];
	let fileName = `Export-Grid-Template-${tableId}`;
	
	exportFromJSON({
		data: uploadTableColumns,
		fileName,
		fields: [],
		exportType: 'csv'
	});
}
async function triggerSubmitUpload({ payload }) {
	const { tableId, parentTableId, uploadParams, isIncrementalUpload, fieldGroups } = payload;
	const dispatch = getDispatch();
	dispatch(
		toggleLoaderState({
			component: 'upload',
			status: REQUEST_STATUS.PENDING
		})
	);

	const upload = await postAPIDataV3({service: uploadParams?.submitService, url: uploadParams?.submitUrl,  body: {tableId, isIncrementalUpload, parentTableId,  fieldGroups}})
		.then(response => response)
		.catch(err => ({ isError: true, error: err }));
		if (upload?.isError) {
			 dispatch(
				triggerNotification({ message: 'An error occurred while submitting data, please try again later.' })
			);
			dispatch(
				toggleLoaderState({
					component: 'upload',
					status: REQUEST_STATUS.ERROR,
					isLoading: false
				})
			);
			return;
		}
	
}

async function revalidateUploadGrid({ payload }) {
	const { tableId, params, uploadParams, isIncrementalUpload } = payload;
	const dispatch = getDispatch();

	const upload = await putAPIDataV3({...REQUESTS.PUT.UPLOAD_STAGING_VALIDATE, body: { tableId, isIncrementalUpload}})
		.then(response => response)
		.catch(err => ({ isError: true, error: err }));
	dispatch(refreshGridData({ tableId }));
}


async function handleReceiveEvent({ payload }: TPayload) {
	const dispatch = getDispatch();

	switch (payload?.type) {
		case 'TABLE_EV':
			if (payload?.eventName === 'submit_upload') {
				if (payload.metaData?.type !== 'error') {
					dispatch(
						toggleLoaderState({	
							component: 'upload',
							status: REQUEST_STATUS.COMPLETE,
							isLoading: false
						})
					);
				
					dispatch(refreshGridData({ tableId: payload?.metaData?.parentTableId }));
				}
				if (payload.metaData?.type === 'error') {
					dispatch(
						triggerNotification({ message: 'An error occurred while submitting data, please try again later.' })
					);
					dispatch(
						toggleLoaderState({
							component: 'upload',
							status: REQUEST_STATUS.ERROR,
							isLoading: false
						})
					);
				}
			}
			break;
	}
}

const handleFetchUploadMetadata = async ({payload}) => {
	const dispatch = getDispatch()
	const upload = await getAPIDataV3({...REQUESTS.GET.GET_UPLOAD_METADATA, pathParams: { tableId: payload?.tableId}})
		.then(response => response.data.data.UploadMetaData)
		.catch(err => ({ isError: true, error: err }));
		console.log(upload)

		dispatch(slice.setUploadMetadata({tableId: payload?.tableId, metaData: upload }))
}



export default function* rootSaga() {
	return [
		yield takeEvery(sagaActions.onUploadFile, onUploadFile),
		yield takeEvery(sagaActions.getWarehouses, fetchWarehouses),
		yield takeEvery(sagaActions.getCustomers, fetchCustomers),
		yield takeEvery(sagaActions.resetUpload, resetUpload),
		yield takeEvery(sagaActions.triggerFetchDownloadTemplate, onDownloadTemplate),
		yield takeEvery(sagaActions.revalidateUploadGrid, revalidateUploadGrid),
		yield takeEvery(sagaActions.onUploadFileV2, triggerUploadV2),
		yield takeEvery(sagaActions.submitUploadFile, triggerSubmitUpload),
		yield takeEvery(onReceiveEvent, handleReceiveEvent),
		yield takeEvery(sagaActions.triggerFetchUploadMetadata, handleFetchUploadMetadata),

		yield takeEvery(sagaActions.handleAsyncGridUpload,  gridUploadEventHandler),
		
	];
}
