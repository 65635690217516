// @ts-nocheck
import './advanced.scss';

import FilterBarSection from 'atoms/filterBar/filterBarSection';
import _ from 'lodash';
import { getGridFiltersByColumn } from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AgGridSearchFilter } from './components/agGridSearchFilter';
import * as query from './query';
import { updateSearchQueries } from './reducer';
import * as types from './type';

type TProps = {
	parameters: types.TSearchParameter[];
	searchFilters: types.TSearchQuery[];
	tableId?: number;
	isServerHandled: boolean;
	allowOperator?: boolean;
	operator?: string;
	updateOperator?: (checked: boolean, event: MouseEvent) => void;
};



export default forwardRef((props, ref) => {
	const currentColumn = props?.colDef?.metaData;
	const tableId = currentColumn.AppTableID;
	let searchQueriesDefault = useSelector(state =>
		getGridFiltersByColumn(state, tableId, currentColumn.AppColumnName)
	);
	searchQueriesDefault = _.filter(searchQueriesDefault, val => val.id);
	const [searchQueries, updateSearchQueries] = useState(searchQueriesDefault);

	
	useImperativeHandle(ref, () => {
		return {
			doesFilterPass(params) { },
			isFilterActive(params) {
				return Boolean(searchQueries.length);
			},
			getModel(params) {
			},
			setModel() { }
		};
	});

	

	useEffect(() => {
		
		if (!_.isEqual(searchQueriesDefault, searchQueries)) {
			updateSearchQueries(searchQueriesDefault);
		}
	}, [searchQueries, searchQueriesDefault]);
	const dispatch = useDispatch();
	let queries = searchQueries?.length ? [...searchQueries] : [{}];
	return (
		<FilterBarSection>
			<Stack gutter="small" isVertical>
				{_.map(queries, (query, index) => (
					<StackItem isStretch>
						<Stack isVertical gutter="small" position="center" align="center">
							<StackItem isStretch>
								<AgGridSearchFilter
									
									key={query?.id}
									currentColumnRef={{ ...props }}
									currentQuery={query}
									tableId={tableId}
									isInline
									onUpdateQueries={params => {
										updateSearchQueries(params);
										props?.api?.refreshServerSide({ route: [], purge: true });
									}}
								/>
							</StackItem>
						</Stack>
					</StackItem>
				))}
			</Stack>
		</FilterBarSection>
	);
});
