// @ts-nocheck
import Card from 'atoms/card/card';
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import Icon from 'atoms/icons/icon';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import * as tableColumnSagaActions from 'models/tableColumns/sagaActions';
import * as tableColumnSelectors from 'models/tableColumns/selector';
import SideCar from 'molecules/sidecar/sidecar';
import Snackbar from 'molecules/snackbar/snackbar';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import CustomizeViewModal from 'organisms/CustomizeViewModal';
import ThetaMenu from 'organisms/thetaMenu/thetaMenu';
import UploadData from 'organisms/uploadData';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import * as constants from './constants';
import Breadcrumbs from 'atoms/breadcrumbs/breadcrumbs';
import ThetaDeleteModal from 'organisms/deleteModal/deleteModal';
import ExportGrid from 'organisms/exportGrid';
import ToggleAgGrid from 'organisms/toggleAgGrid';
import { REQUESTS } from 'utils/requests';
import GridActionBar from 'atoms/GridActionBar';
import GridCsvUpload from 'organisms/uploadData/gridCsvUpload';
import { isAclUsersReadWriteEnabled, isNewUploadEnabled } from 'models/user/selector';
import Button from 'atoms/button/button';
import { CreateRowAccess } from './components/createRowAccess';
import { triggeRemoveRowAccess } from './sagaActions';
import Label from 'atoms/label/label';
import { Space, Tag } from 'antd';
import { triggerFetchAutoComplete } from 'models/autoComplete/sagaActions';

const ITEMS_MODAL = 'items-modal';

// const getColumns = ({ onEditRow, onRemoveUserRole, onRemoveRowAccess }) => ([
//     // {
//     //     title: "RowAccess Name",
//     //     // dataIndex: 'name',
//     //     key: "UserName",
//     //     displayName: 'RowAccess Name',
//     //     render: (obj) => obj.UserName,
//     // },
//     {
//         title: "User Email",
//         // dataIndex: 'name',
//         key: "UserEmail",
//         displayName: 'User Email',
//         render: (obj) => obj.UserEmail,
//     },
//     {
//         title: "Access Restriction",
//         // dataIndex: 'name',
//         key: "AccessLevelName",
//         displayName: 'Access Restriction',
//         render: (obj) => obj.AccessLevelName,
//     },
//     {
//         title: "Allowed Access",
//         // dataIndex: 'name',
//         key: "AccessLevelEntities",
//         displayName: 'Allowed Access',
//         width: '30%',
//         render: (obj) => <Stack isWrap gutter="medium">
//             {_.map(obj.AccessLevelEntities, (a, index) => index < 3 && <StackItem><Tag >{a.DisplayValue} </Tag></StackItem>)}
//             {obj.AccessLevelEntities.length > 3 && <StackItem>
//                 <Link onClick={() => onEditRow(obj)}>
//                     <Label size="small">
//                         +{obj.AccessLevelEntities.length - 3} more
//                     </Label>
//                 </Link>

//             </StackItem>}
//         </Stack>,
//     },
//     {
//         title: "User Status",
//         // dataIndex: 'name',
//         key: "UserStatus",
//         displayName: 'User Status',
//         width: '15%',
//         render: (obj) => <Stack isWrap gutter="medium">{obj.IsActive ? 'Active' : 'Inactive'}</Stack>,
//     },
//     {
//         title: "Action",
//         key: "action",
//         render: (obj) => (
//             <Space size="middle">
//                 <a onClick={() => onEditRow(obj)}>Edit</a>
//                 <a onClick={() => onRemoveRowAccess(obj)}>Delete</a>
//             </Space>
//         ),
//     },
// ]);
let debounce;

const RowAccess: React.FunctionComponent = props => {
    const [openFilter, setOpenFilter] = React.useState<boolean>();
    const [isUploadEnabled, toggleUpload] = React.useState<boolean>(false);
    const [isDeleteModal, toggleDeleteModal] = React.useState(false);
    const gridMeta = useSelector(state => dynamicGridSelectors.getGridMetadata(state, constants.TABLE_ID));
    const [rowAccessInfo, setRowAccess] = React.useState(null);
    const [searchText, setSearchText] = useState('');
    const tableId = constants.TABLE_ID.toString();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const hasWriteAccess = useSelector(isAclUsersReadWriteEnabled);
    const hasDeleteAccess = true;
    const hasSearchFilters = useSelector(state => dynamicGridSelectors.hasSearchFilters(state, constants.TABLE_ID));

    console.log({hasSearchFilters})
    const isCustomizeViewModalEnabled = useSelector(state => tableColumnSelectors.isCustomizeViewModalEnabled(state));
    const onRemoveUserRole = (rowAccess, role) => {
        dispatch(triggerRemoveUserRole({ userId: rowAccess?.UserID, roles: [role?.RoleID] }))
    }
    React.useEffect(() => {
        if (openFilter === undefined) {
            setOpenFilter(hasSearchFilters);
        }
    }, [hasSearchFilters]);

    useEffect(() => {
        dispatch(
            triggerFetchAutoComplete({
                componentId: 'acl-all-users',
                options: {
                    service: 'acl',
                    url: 'users/list',
                    displayKey: 'UserName',
                    selectionKey: 'UserID',
                },
                params: { search: searchText },
                isAppend: false
                // params: {  fieldGroups: ['Roles', 'UserEmail'] }

            }));
        dispatch(
            triggerFetchAutoComplete({
                componentId: 'acl-user-accessLevels',
                options: {
                    service: 'acl',
                    url: 'accessLevel/users/list',
                    displayKey: 'UserName',
                    selectionKey: 'UserID',
                },
                params: { search: searchText },

                // params: {  fieldGroups: ['Roles', 'UserEmail'] }

            }));

    }, [searchText])


    const handleFilterClick = React.useCallback(() => {
        setOpenFilter(!openFilter);
    }, [openFilter]);

    const onRemoveRowAccess = (rowAccess) => {
        dispatch(triggeRemoveRowAccess({ userId: rowAccess?.UserID, selectedEntities: rowAccess?.AccessLevelEntities.map(ele => ele?.AccessLevelEntityID) }))
        setTimeout(() => {
            dispatch(
                dynamicGridSagaActions.refreshGridData({
                    tableId: constants.TABLE_ID,
                })
            );
        }, 2000)

    }

    const handleRefreshClick = React.useCallback(() => {
        dispatch(
            dynamicGridSagaActions.refreshGridData({
                tableId: constants.TABLE_ID,
            })
        );
    }, [tableId, dispatch]);

    const handleAddNewClick = () => {
        toggleAdModal(true);
    };

    const handleBackClick = React.useCallback(() => {
        navigate(`/masterData`);
    }, [navigate]);

    const handleResetTableColumns = React.useCallback(async () => {
        dispatch(tableColumnSagaActions.resetTableColumnsAg(constants.TABLE_ID));
    }, [dispatch, tableId]);

    const customizeViewHandler = React.useCallback((isCustomizeViewModalEnabled: boolean) => {
        dispatch(tableColumnSagaActions.setCustomizeViewModal(isCustomizeViewModalEnabled));
    }, []);

    const handleMenuItemClick = React.useCallback(
        (option: string) => {
            switch (option) {
                case constants.MENU_ITEMS_VALUES.CUSTOMIZE_VIEW:
                    return customizeViewHandler(true);
                case constants.MENU_ITEMS_VALUES.RESET_VIEW:
                    return handleResetTableColumns();
                case constants.MENU_ITEMS_VALUES.UPLOAD_DATA:
                    return toggleUpload(true);
                case constants.MENU_ITEMS_VALUES.DELETE:
                    return deleteItems();
                default:
                    return;
            }
        },
        [handleResetTableColumns]
    );

    const selectedItems = useSelector(state => dynamicGridSelectors.getTableSelection(state, constants.TABLE_ID));
    const getDisabledStatus = React.useCallback(
        (option: any) => {
            switch (option.value) {
                case constants.MENU_ITEMS_VALUES.CUSTOMIZE_VIEW:
                case constants.MENU_ITEMS_VALUES.RESET_VIEW:
                case constants.MENU_ITEMS_VALUES.UPLOAD_DATA:
                    return false;
                case constants.MENU_ITEMS_VALUES.DELETE:
                    return !selectedItems?.selectedRowKeys?.length;
                default:
                    return true;
            }
        },
        [selectedItems]
    );

    const hideCustomizeViewModal = React.useCallback(() => {
        customizeViewHandler(false);
    }, []);

    const uploadCloseClickHandler = React.useCallback(() => {
        toggleUpload(false);
    }, []);

    const handleBulkDeleteClick = () => {
        dispatch(dynamicGridSagaActions.handleBulkRowDelete({ tableId: constants.TABLE_ID }));
        toggleDeleteModal(false);
    };

    const deleteItems = () => {
        toggleDeleteModal(true);
    };
    const isNewUpload = useSelector(isNewUploadEnabled);

    const gridConfig = {
        enableSelection: true,
        enableSorting: true,
        enableMultiSort: true,
        gridActions: (hasWriteAccess || hasDeleteAccess),
        customActions: (hasWriteAccess || hasDeleteAccess) && {
            width: 170,
            enabled: true,
            component: ({ row }) => {
                return (
                    <Stack gutter="small" align="center" position='center'>
                        <StackItem>
                            <Button handleClick={() => onEditRow(row)}>Edit</Button>
                        </StackItem>
                        <StackItem>
                            <Button type="secondary" handleClick={() => onRemoveRowAccess(row)}>
                                Delete
                            </Button>
                        </StackItem>
                    </Stack>
                );
            }
        },
        selectionCallBack: () => { }
    };

    const templateConfig = {
        gridConfig,
        isFilterEnabled: openFilter === undefined ? false : openFilter,
        tableId: constants.TABLE_ID,

        dataProviderOptions: {
            type: 'POST',
            ...REQUESTS.POST.GET_MONGO_GRID_DETAILS_V2,
            pathParams: { tableId: constants.TABLE_ID },
            version: 2
        },
        selectRowKey: 'ItemID',
    };

    const onEditRow = (row) => {
        console.log("edit row", row)
        setRowAccess(row)
    }

    // const columns = getColumns({ onEditRow, onRemoveRowAccess, onRemoveUserRole })

    // console.log({columns})

    return (
        <>
            <Stack isVertical>
                <StackItem>
                    <GridActionBar>
                        <Stack gutter="medium">
                            <StackItem isGrow>
                                <Breadcrumbs crumbs={props.breadcrumbs} />
                            </StackItem>

                            <StackItem isGrow position='right' align='right'>
                                <Button handleClick={() => setRowAccess(true)}>Create Row Access</Button>

                            </StackItem>

                            {isNewUpload && gridMeta?.UploadTableID && <StackItem>
                                <GridCsvUpload uploadTableId={gridMeta?.UploadTableID} parentTableId={tableId} />
                            </StackItem>}
                            <StackItem>
                                <Icon isActive type="secondary" faAlias="faRecycle" onClick={handleRefreshClick} />
                            </StackItem>


                            {/* <StackItem>
                                <ExportGrid tableId={13} />
                            </StackItem> */}
                            <StackItem>
                                <ThetaMenu
                                    type="3Dots"
                                    options={
                                        hasWriteAccess ? constants.MENU_ITEMS : [constants.MENU_ITEMS[0]]
                                    }
                                    handleItemClick={handleMenuItemClick}
                                    isDisabled={getDisabledStatus}
                                />
                            </StackItem>
                        </Stack>
                    </GridActionBar>
                </StackItem>
                <StackItem isStretch isGrow>
                    <Sheet isPage>
                        <Stack isVertical gutter="medium">

                            <StackItem isGrow isStretch>
                                <ThetaGridPaginated config={templateConfig} />
                            </StackItem>
                        </Stack>
                    </Sheet>
                </StackItem>


            </Stack>

            {rowAccessInfo && <CreateRowAccess rowAccessInfo={rowAccessInfo} onHide={() => setRowAccess(false)} />}


            {isCustomizeViewModalEnabled && (
                <CustomizeViewModal hideModal={hideCustomizeViewModal} tableId={constants.TABLE_ID} />
            )}
            {isUploadEnabled && (
                <SideCar isActive={isUploadEnabled} handleOnClose={uploadCloseClickHandler}>
                    <UploadData tableId={tableId} />
                </SideCar>
            )}
            {isDeleteModal && (
                <ThetaDeleteModal
                    hideModal={() => {
                        toggleDeleteModal(false);
                    }}
                    modalTitle={'ITEMS'}
                    title={` (${selectedItems?.isSelectAll ? 'All' : selectedItems?.selectedRowKeys?.length
                        }) selected  item(s)`}
                    handleDeleteClick={handleBulkDeleteClick}
                    isLocked={true}
                />
            )}
        </>
    );
};
export default RowAccess;
