// @ts-nocheck
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import qs from 'qs';
import { put, select, takeEvery } from 'redux-saga/effects';
import { getAPIDataV2, getAPIDataV3, postAPIData, postAPIDataV3, putAPIData, putAPIDataV3 } from 'utils/api';
import * as slice from './reducer';
import * as sagaActions from './sagaActions';
import _ from 'lodash';
import * as advertisingSagaActions from 'models/advertisings/sagaActions';
import * as dynamicGridSlice from 'models/dynamicGrid/reducer';
import { getTableId, GROUP_MODAL_TABLE_IDS, TABLE_ID } from './constants';
import { triggerNotification } from 'organisms/notifications/sagaActions';
import { REQUESTS } from 'utils/requests';
import { getState } from 'reducers';
import { getWebAppSettings } from 'models/user/selector';

const getCustomers = (): Promise<any> => {
	const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

	return getAPIDataV3({
    service: REQUESTS.GET.GET_CUSTOMERS.service,
    url: REQUESTS.GET.GET_CUSTOMERS.url,
    queryParams: {
      isView
    }
  })
    .then((response) => ({ response: response?.data }))
    .catch((error) => ({ error, isError: true }));
};

const saveGroupData = (data: any): Promise<any> => {
	return postAPIDataV3({
      service: REQUESTS.POST.ADD_GROUP.service,
      url: REQUESTS.POST.ADD_GROUP.url,
      body: data
    })
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true }));
};

const ediSelectedGroupData = (data: any): Promise<any> => {
	return putAPIDataV3({
    service: REQUESTS.PUT.UPDATE_GROUP.service,
    url: REQUESTS.PUT.UPDATE_GROUP.url,
    body: data
  })
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true }));
};


function* updateModalToggle({ payload }: any) {
	const { isOpen } = payload;
	if (!isOpen) {
		yield put(
			slice.updateEditMode({
				edit: false
			})
		);
	}
	yield put(slice.updateModalToggle({ isOpen }));
}

function* updateGroupData({ payload }: any) {
	const { data } = payload;
	yield put(slice.updateGroupData({ data }));
}

function* cleanUp() {
	yield put(slice.cleanUp());
	yield put(
		dynamicGridSagaActions.cleanUpGrids({
			tableIds: [
				GROUP_MODAL_TABLE_IDS.GROUPS_STORE,
				GROUP_MODAL_TABLE_IDS.GROUPS_SKU,
				GROUP_MODAL_TABLE_IDS.GROUPS_CUSTOMERS
			]
		})
	);
}

function* fetchCustomers() {
	yield put(slice.updateLoader({ isLoading: true }));
	const { response, isError } = yield getCustomers();
	if (isError) {
		yield put(triggerNotification({ message: 'Unable to fetch customers, please try again later.' }));

		yield put(slice.updateLoader({ isLoading: false }));
		return;
	}
	yield put(slice.updateCustomersData({ data: response?.data }));
	yield put(slice.updateLoader({ isLoading: false }));
}

function* editGroupData({ payload }: any) {
	const { data } = payload;
	let selectedKeys = data?.MemberIDList ? data?.MemberIDList?.split(',') : [];
	selectedKeys = selectedKeys.map((item: string) => Number(item));
	yield put(
		slice.updateEditMode({
			edit: true
		})
	);
	yield put(
		dynamicGridSlice.updateSelectedRows({
			tableId: getTableId(data.GroupType),
			data: { selectedRowKeys: selectedKeys, isSelectAll: false }
		})
	);

	yield put(slice.updateEditData({ data }));
}

function* editData({ payload }: any) {
	const { data } = payload;

	yield put(slice.updateLoader({ isLoading: true }));
	const { response, isError } = yield ediSelectedGroupData(data).catch(error => ({ isError: true, error }));
	if (isError) {
		yield put(triggerNotification({ message: 'Unable to edit, please try again later.' }));
		yield put(slice.updateLoader({ isLoading: false }));
		return;
	}
	yield put(slice.updateLoader({ isLoading: false }));
	yield put(slice.updateModalToggle({ isOpen: false }));
	yield cleanUp();

	yield put(dynamicGridSagaActions.refreshGridData({ tableId: TABLE_ID }));
}

function* saveData({ payload }: any) {
	const { data } = payload;
	yield put(slice.updateLoader({ isLoading: true }));
	const { response, isError } = yield saveGroupData(data).catch(error => ({ isError: true, error }));
	if (isError) {
		yield put(triggerNotification({ message: 'Unable to save, please try again later.' }));
		yield put(slice.updateLoader({ isLoading: false }));
		return;
	}

	yield put(slice.updateLoader({ isLoading: false }));
	yield put(slice.updateModalToggle({ isOpen: false }));
	yield cleanUp();

	yield put(dynamicGridSagaActions.refreshGridData({ tableId: TABLE_ID }));
}

export default function* rootSaga() {
	return [
		yield takeEvery(sagaActions.updateModalToggle, updateModalToggle),
		yield takeEvery(sagaActions.updateGroupData, updateGroupData),
		yield takeEvery(sagaActions.cleanUp, cleanUp),
		yield takeEvery(sagaActions.fetchCustomers, fetchCustomers),
		yield takeEvery(sagaActions.editGroupData, editGroupData),
		yield takeEvery(sagaActions.editData, editData),
		yield takeEvery(sagaActions.saveData, saveData)
	];
}
