// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const getAvailabilityInfo = createAction('availability/info', function prepare({ tableId }) {
	return {
		payload: {
			tableId
		}
	};
});
