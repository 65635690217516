// @ts-nocheck
import { createAction, createSlice, Action, AnyAction } from '@reduxjs/toolkit';
import * as sagaActions from './sagaActions';
import * as constants from './constants';
import _ from 'lodash';

const queryFilters = {
	searchQueries: [],
	statusFilters: []
};

export const initialState = {
	orders: {},
	selectedOrders: {},
	queryFilters: {
		23: {
			...queryFilters
		},
		1: {
			...queryFilters
		},
		2: {
			...queryFilters
		}
	},
	isHealthCheckEnabled: false,
	isLoading: {},
	HealthCheckID: null,
	orderClone: {
		cloneTypes: [],
		selectedParameters: {}
	},
	orderSplit: {
		splitTypes: [],
		selectedParameters: {}
	},
	updateOrderStatus: {
		defaultStores: []
	},
	updateShipMethods: {
		defaultShippingMethods: []
	},
	openModalName: '',
	optimizeShipMethodNumber: null,
	bulkOrder: {
		bulkOrderTypes: [],
		bulkEditParams: {
			bulkOrderEditTypeId: 1,
			bulkOrderEditValue: 0
		}
	},
	loading: false,
	info: {},
	refreshInfoTimestamp: null
};

interface RejectedAction extends Action {
	error: Error;
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
	return action.type.endsWith('rejected');
}
const slice = createSlice({
	name: 'orders',
	initialState,
	reducers: {
		updateTableColumns(state, { payload: { tableId, columnsConfig } }) {
			return {
				...state,
				[tableId]: columnsConfig
			};
		},
		updateTableData(state, { payload: { activeTab, data } }) {
			return {
				...state,
				orders: {
					...state.orders,
					[activeTab]: data
				}
			};
		},
		toggleLoader(state, { payload: { loaderComponent, isLoading } }) {
			return {
				...state,
				isLoading: {
					[loaderComponent]: isLoading
				}
			};
		},
		createUpdateFilter(state, { payload: { tableId, searchFilters, searchQueries } }) {
			return {
				...state,
				queryFilters: {
					...state.queryFilters,
					[tableId]: {
						...state.queryFilters[tableId],
						..._.pickBy(
							{
								searchFilters,
								searchQueries
							},
							_.identity
						)
					}
				}
			};
		},
		toggleHealthCheck(state, { payload: { isEnabled, HealthCheckID } }) {
			return {
				...state,
				isHealthCheckEnabled: isEnabled,
				HealthCheckID: isEnabled ? HealthCheckID : null
			};
		},
		updateSelectedItems(state, { payload: { activeTab, data } }) {
			return {
				...state,
				selectedOrders: {
					...state.selectedOrders,
					[activeTab]: data
				}
			};
		},
		updatePaginationParams(state, { payload: { activeTab, query } }) {
			return {
				...state,
				pagination: {
					...state.pagination,
					[activeTab]: query
				}
			};
		},
		// changeInputvalue(state, { payload: { data, OrderDetailID  } }) {
		// 	return {
		// 		...state,
		// 		pagination: {
		// 			...state.pagination,
		// 			[activeTab]: query,
		// 		},
		// 	};
		// },
		updateOrderError(state, { payload: { OrderDetailID, message } }) {
			const index = _.findIndex(state.orders['1'], { OrderDetailID });
			const skus = [...state.orders['1']];
			skus[index] = {
				...skus[index],
				orderQtyError: message
			};
			return {
				...state,
				orders: {
					...state.orders,
					['1']: skus
				}
			};
		},
		//clone
		updateCloneSelectedParameters(state, { payload: { selectedParameters } }) {
			return {
				...state,
				orderClone: {
					...state.orderClone,
					selectedParameters: selectedParameters
				}
			};
		},
		updateCloneDefaultColumnTypes(state, { payload: { defaultColumns } }) {
			return {
				...state,
				orderClone: {
					...state.orderClone,
					cloneTypes: defaultColumns
				}
			};
		},
		//split
		updateSplitSelectedParameters(state, { payload: { selectedParameters } }) {
			return {
				...state,
				orderSplit: {
					...state.orderSplit,
					selectedParameters: selectedParameters
				}
			};
		},
		updateSplitDefaultColumnTypes(state, { payload: { defaultColumns } }) {
			return {
				...state,
				orderSplit: {
					...state.orderSplit,
					splitTypes: defaultColumns
				}
			};
		},
		//update order status
		updateDefaultOrderStatus(state, { payload: { defaultStores } }) {
			return {
				...state,
				updateOrderStatus: {
					defaultStores: defaultStores
				}
			};
		},
		//update ship method
		updateDefaultShipMethods(state, { payload: { defaultShippingMethods } }) {
			return {
				...state,
				updateShipMethods: {
					defaultShippingMethods: defaultShippingMethods
				}
			};
		},
		//open modal
		handleOpenModal(state, { payload: { openModalName } }) {
			return {
				...state,
				openModalName: openModalName
			};
		},
		//bulk order
		updateBulkOrderTypes(state, { payload: { defaultColumns } }) {
			return {
				...state,
				bulkOrder: {
					...state.bulkOrder,
					bulkOrderTypes: defaultColumns
				}
			};
		},
		updateBulkEditParams(state, { payload: { bulkParams } }) {
			return {
				...state,
				bulkOrder: {
					...state.bulkOrder,
					bulkEditParams: bulkParams
				}
			};
		},
		//open modal
		handleToggleLoading(state, { payload: { status } }) {
			return {
				...state,
				loading: status
			};
		},
		cleanUp(state) {
			return {
				...initialState,
				..._.pick(state, ['info', 'orders'])
			};
		},
		updateOrdersInfo(state, { payload: { info } }) {
			return {
				...state,
				info
			};
		},
		updateInfoTimestamp(state, { payload: { refreshInfoTimestamp } }) {
			return {
				...state,
				refreshInfoTimestamp
			};
		},
		updateOptimizeNumber(state, { payload: { optimizeShipMethodNumber } }) {
			return {
				...state,
				optimizeShipMethodNumber
			};
		},
		updateOptimizeAggregateData(state, { payload: { optimizeAggregateInfo } }) {
			return {
				...state,
				optimizeAggregateInfo
			};
		}
	}
});

export default slice;

export const {
	updateTableColumns,
	updateTableData,
	updateSelectedItems,
	toggleHealthCheck,
	toggleLoader,
	createUpdateFilter,
	updatePaginationParams,
	updateOptimizeAggregateData,
	updateOptimizeNumber,
	// changeInputvalue,
	updateOrderError,
	updateCloneSelectedParameters,
	updateCloneDefaultColumnTypes,
	updateSplitSelectedParameters,
	updateSplitDefaultColumnTypes,
	updateDefaultOrderStatus,
	updateDefaultShipMethods,
	handleOpenModal,
	updateBulkOrderTypes,
	handleToggleLoading,
	updateBulkEditParams,
	cleanUp,
	updateOrdersInfo,
	updateInfoTimestamp
} = slice.actions;
