export const DEMAND_PLANNING_TABLE_ID = 55;
export const DEMAND_MATRIX_TABLE_ID = 56;
export const CSV_UPLOAD_TABLE_ID = 61;

export const DEMAND_PLANNING_MENU_NAMES = {
	LOCK_DEMAND: 'Lock Demand',
	RESET_VIEW: 'Reset View',
	UPLOAD_DATA: 'Upload Data',
	DELETE: 'Delete'
};

export const DEMAND_PLANNING_MENU_VALUES = {
	LOCK_DEMAND: 'LOCK_DEMAND',
	RESET_VIEW: 'RESET_VIEW',
	UPLOAD_DATA: 'UPLOAD_DATA',
	DELETE: 'Delete'
};



export const DEMAND_PLANNING_MENU = [
	{
		displayName: DEMAND_PLANNING_MENU_NAMES.RESET_VIEW,
		value: DEMAND_PLANNING_MENU_VALUES.RESET_VIEW
	},
	{
		displayName: DEMAND_PLANNING_MENU_NAMES.LOCK_DEMAND,
		value: DEMAND_PLANNING_MENU_VALUES.LOCK_DEMAND
	}
];
