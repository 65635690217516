// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const getTableColumns = createAction('orders/get-table-columns');

export const refreshTableData = createAction('orders/reset-data', function prepare(tableId) {
	return {
		payload: {
			tableId
		}
	};
});

export const toggleLoader = createAction('orders/toggleLoader', function prepare({ loaderComponent, isLoading }) {
	return {
		payload: {
			isLoading,
			loaderComponent
		}
	};
});

export const toggleHealthCheck = createAction(
	'orders/toggleHealthCheckSagas',
	function prepare({ isEnabled, HealthCheckID }) {
		return {
			payload: {
				isEnabled,
				HealthCheckID
			}
		};
	}
);

export const lockUnlockRecords = createAction('orders/lockUnlock', function prepare({ selectedTab, data }) {
	return {
		payload: {
			tableId: selectedTab,
			data
		}
	};
});

export const resetTable = createAction('orders/reset', function prepare({ tableId }) {
	return {
		payload: {
			tableId
		}
	};
});

//Clone Modal
export const updateCloneSelectedParameters = createAction(
	'clone/update-clone-selected-parameters',
	function prepare(selectedParameters) {
		return {
			payload: {
				selectedParameters
			}
		};
	}
);

export const getCloneDefaultColumnTypes = createAction('clone/get-clone-default-column-type');

export const cloneOrders = createAction('clone/clone-order', function prepare({ tableId, data }) {
	return {
		payload: {
			tableId,
			data
		}
	};
});

//split

export const updateSplitSelectedParameters = createAction(
	'split/update-split-selected-parameters',
	function prepare(selectedParameters) {
		return {
			payload: {
				selectedParameters
			}
		};
	}
);

export const getSplitDefaultColumnTypes = createAction('split/get-split-default-column-type');

export const splitOrders = createAction('split/split-order', function prepare({ tableId, data }) {
	return {
		payload: {
			tableId,
			data
		}
	};
});
//update order status
export const getDefaultOrderStatus = createAction('orders/fetch-default-order-status');

export const updateOrderStatus = createAction('orders/updateOrderStatus', function prepare(data, selectedTab) {
	return {
		payload: {
			data,
			tableId: selectedTab
		}
	};
});

//ship methods
export const getShipMethods = createAction('orders/fetch-ship-methods', function prepare(reqData) {
	return {
		payload: {
			reqData: reqData
		}
	};
});

export const updateShipMethods = createAction('orders/update-ship-methods', function prepare(data, selectedTab) {
	return {
		payload: {
			data,
			tableId: selectedTab
		}
	};
});

//ship methods
export const publishOrders = createAction('orders/publish-orders', function prepare(data, selectedTab) {
	return {
		payload: {
			data,
			tableId: selectedTab
		}
	};
});

//delete order
export const deleteOrder = createAction('order/delete-order', function prepare(data, selectedTab) {
	return {
		payload: {
			data,
			tableId: selectedTab
		}
	};
});

// change PO Date
export const updatePODate = createAction('order/update-PO-Date', function prepare({ data, tableId }) {
	return {
		payload: {
			data,
			tableId
		}
	};
});

// openModal
export const toggleModalOpen = createAction('order/toggle-modal-open', function prepare(value) {
	return {
		payload: {
			openModalName: value
		}
	};
});

// closeModal
export const toggleModalClose = createAction('order/toggle-modal-close', function prepare(tableIds) {
	return {
		payload: {
			tableIds
		}
	};
});

//bulk order
export const getBulkOrderTypes = createAction('bulkOrder/get-bulk-order-types');

export const updateBulkEditParams = createAction('bulkOrder/update-bulk-edit-parameters', function prepare(bulkParams) {
	return {
		payload: {
			bulkParams
		}
	};
});

export const updateBulkOrders = createAction('bulkOrder/update-bulk-orders', function prepare(tableId, data, orderId) {
	return {
		payload: {
			tableId: tableId,
			data: data
		}
	};
});

// distributeAvailability
export const distrbuteAvailability = createAction('order/distribute-orders', function prepare({ tableId, distNum }) {
	return {
		payload: {
			tableId,
			distNum
		}
	};
});

// optimize Shipping
export const optimizeShipping = createAction('order/optimize-shipping', function prepare({ tableId }) {
	return {
		payload: {
			tableId
		}
	};
});

export const getOptimizeAggregateData = createAction('order/optimize-shipping/aggregate', function prepare(payload) {
	return {
		payload
	};
});

// toggle loader
export const toggleLoading = createAction('order/toggle-loading', function prepare(status) {
	return {
		payload: {
			status: status
		}
	};
});
//info
export const getOrdersSummaryInfo = createAction('order/info', function prepare({ tableId, search }) {
	return {
		payload: {
			tableId,
			search
		}
	};
});

export const getShipMethodOptimizeNumber = createAction(
	'order/get/optimizeshipmethod/number',
	function prepare({ tableId, userName }) {
		return {
			payload: {
				tableId,
				userName
			}
		};
	}
);
