// @ts-nocheck
import _ from 'lodash';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import React from 'react';
import { useSelector } from 'react-redux';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
// import { getDistributeSkusData } from '../api';
import { DISTRIBUTE_SKUS_TABLE_ID, SELECT_SKUS_TABLE_ID } from './constants';
import * as dynamicGridQuery from 'models/dynamicGrid/query';
import { getDistributeAvailabilityNumber } from './selectors';
import { REQUESTS } from 'utils/requests';

const DistributeSkus = (props: any) => {
	const { selectedTab, parent } = props;

	const skus = useSelector(state => dynamicGridSelectors.getGridData(state, SELECT_SKUS_TABLE_ID));
	const selectedSkus = useSelector(state => dynamicGridSelectors.getTableSelection(state, SELECT_SKUS_TABLE_ID));
	const ordersSearch = useSelector(state => dynamicGridSelectors.getGridFilters(state, selectedTab));
	const skusSearch = useSelector(state => dynamicGridSelectors.getGridFilters(state, SELECT_SKUS_TABLE_ID))?.search;
	const distSkusSearch = useSelector(state =>
		dynamicGridSelectors.getGridFilters(state, DISTRIBUTE_SKUS_TABLE_ID)
	)?.search;
	const selectedOrders = useSelector(state => dynamicGridSelectors.getTableSelection(state, selectedTab));
	const distNumber = useSelector(getDistributeAvailabilityNumber);
	// const distributeSkusData = () => {
	// 	let distributedSkus = [];
	// 	for (let selectedSku of selectedSkus?.selectedRowKeys) {
	// 		let sku = _.filter(skus, function (singleSku) {
	// 			return singleSku.SKUID === selectedSku;
	// 		});
	// 		distributedSkus.push(sku[0]);
	// 	}
	// 	return distributedSkus;
	// };

	const skusList = React.useMemo(
		() =>
			_.map(
				_.filter(skus, sku => {
					return selectedSkus?.selectedRowKeys.find(iSku => iSku === sku.SKUID);
				}),
				({ SKU }) => SKU
			),
		[selectedSkus, skus]
	);

	const extendedSearchFilters = React.useMemo(
		() =>
			selectedSkus.isSelectAll
				? [...skusSearch?.map(sku => ({ ...sku, isLocked: true }))]
				: [
						...skusSearch?.map(sku => ({ ...sku, isLocked: true })),
						{
							criteria: {
								displayName: 'SKU',
								value: 'SKU',
								rowIdentifier: 'SKU',
								type: 'text',
								queryName: 'SKU',
								label: 'SKU'
							},
							query: skusList.join(','),
							operator: {
								value: 'in',
								displayName: 'in',
								label: 'in'
							},
							isLocked: true
						}
				  ],
		[skusSearch, selectedSkus]
	);

	const gridConfig = React.useMemo(
		() => ({
			enableSelection: false,
			enableSorting: true,
			enableSelection: false,
			enableMultiSort: true,
			isViewImplementationOverride: true,
			selectionCallBack: () => {}
		}),
		[]
	);
	const userName = sessionStorage.getItem('userName') || 'None';

	const templateConfig = React.useMemo(
		() => ({
			gridConfig,
			isFilterEnabled: true,
			tableId: DISTRIBUTE_SKUS_TABLE_ID,
			extendedSearchFilters: null ,
			selectRowKey: 'DistAvailListID',
			
			dataProviderOptions: {
				version: 2,
				type: 'POST',
				...REQUESTS.POST.GET_DISTRIBUTE_SKUS,
				pathParams: { userName },
				body: {
					orderIds: (selectedOrders?.selectedRowKeys || []).join(','),
					isSelectAll: selectedOrders?.isSelectAll,
					selectAllSearch: JSON.stringify({
						filter: dynamicGridQuery.createFiltersFromQueries(ordersSearch?.search)
					}),
					tableId: Number(selectedTab),
					orderIds: (selectedOrders?.selectedRowKeys || []).join(','),
					selectAllSearch: JSON.stringify({
						filter: dynamicGridQuery.createFiltersFromQueries(ordersSearch?.search)
					}),
					isSelectAllSKU: selectedSkus.isSelectAll,
					selectAllSearchSKU: JSON.stringify({
						filter: dynamicGridQuery.createFiltersFromQueries(skusSearch)
					}),
					skuIdList: selectedSkus.selectedRowKeys,
					distNumber,
					isDistributeSkus: true
					// search: JSON.stringify({
					// 	filter: dynamicGridQuery.createFiltersFromQueries(distSkusSearch)
					// }),
				}
			}
		}),
		[
			selectedOrders,
			distNumber,
			selectedSkus,
			selectedTab,
			userName,
			gridConfig,
			extendedSearchFilters,
			ordersSearch,
			skusSearch
		]
	);

	return <ThetaGridPaginated isStorageEnabled={false} parent={parent} config={templateConfig} />;
};

export default DistributeSkus;
