// @ts-nocheck
import * as dynamicGridReducer from 'models/dynamicGrid/reducer';
import _ from 'lodash';
import * as sagaActions from './sagaActions';
import { put, takeEvery, select } from 'redux-saga/effects';
import { notification } from 'antd';
import * as slice from './reducer';
import { v4 as uuidv4 } from 'uuid';
import { getNotifications } from './selectors';
import { getAPIDataV2, getAPIDataV3 } from 'utils/api';
import { toggleLoader } from 'models/loaders/sagaActions';
import { isLoggedOut } from 'models/subscriptions/selector';
import { getState } from 'reducers';
import { REQUESTS } from 'utils/requests';

export default function* rootSaga() {
	function* triggerNotification({ payload }: any) {
		const isLoggedOutUser = isLoggedOut(getState());
		const notifications = yield select(state => getNotifications(state));
		const id = uuidv4();

		yield put(
			slice.updateNotifications({
				notifications: isLoggedOutUser
					? []
					: _.uniqBy([...notifications, { ...payload, id, isRead: false }], 'id')
			})
		);
	}
	function* markAsRead({ payload }: any) {
		let notifications = yield select(state => getNotifications(state));
		notifications = _.map(notifications, notification => {
			if (notification.id === payload?.notification?.id) {
				return { ...notification, isRead: true };
			}
			return notification;
		});
		yield put(slice.updateNotifications({ notifications: _.uniqBy([...notifications], 'id') }));
	}

	function* removeNotification({ payload }: any) {
		let notifications = yield select(state => getNotifications(state));
		notifications = _.reject(notifications, (n, i) => n.id === payload.id);
		yield put(
			slice.updateNotifications({
				notifications
			})
		);
	}
	function* fetchAllNotifications({ payload }: any) {
		const { data, isError } = yield getAPIDataV3({
      service: REQUESTS.GET.HANDLE_FETCH_NOTIFICATIONS.service,
      url: REQUESTS.GET.HANDLE_FETCH_NOTIFICATIONS.url,
    });
		yield put(
			toggleLoader({
				component: 'notification-center',
				isLoading: true
			})
		);
		let notifications = _.map(data, d => {
			d.isRead = true;
			return d;
		});
		yield put(
			toggleLoader({
				component: 'notification-center',
				isLoading: false
			})
		);
		yield put(
			slice.updateNotifications({
				notifications
			})
		);
	}

	return [
		yield takeEvery(sagaActions.triggerNotification, triggerNotification),
		yield takeEvery(sagaActions.removeNotification, removeNotification),
		yield takeEvery(sagaActions.fetchNotifications, fetchAllNotifications),
		yield takeEvery(sagaActions.markAsRead, markAsRead)
	];
}
