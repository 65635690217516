// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const createUpdateSearchQueries = createAction(
	'search/updateQueries',
	function prepare({
		selectedCriteria,
		searchInput,
		searchInputEnd,
		selectedOperator,
		tableId,
		isUpdate,
		id,
	}) {
		return {
			payload: {
				selectedCriteria,
				searchInput,
				searchInputEnd,
				tableId,
				selectedOperator,
				isUpdate,
				id,
			}
		};
	}
);
export const removeQuery = createAction('search/removeQuery', function prepare({ query, tableId }) {
	return {
		payload: {
			query,
			tableId,
		}
	};
});

export const updateAgQueries = createAction('search/updateQueriesAg', function prepare(payload) {
	return {
		payload
	};
});
