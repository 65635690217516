// @ts-nocheck
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React, { useState } from 'react';
import classNames from 'classnames';
import './selectInput.css';
type TProps = {
  text: string;
  selected: boolean;
  handleSelectInputClick: Function;
};

const SelectInput = (props: TProps) => {
  const { text, selected, handleSelectInputClick } = props;
  const classes: any = classNames([
    'selectInput',
    { [`selectInput--${selected}`]: true },
  ]);

  return (
    <div className={classes} onClick={() => handleSelectInputClick(text)}>
      {text}
    </div>
  );
};
export default SelectInput;
