// @ts-nocheck
import _ from 'lodash';
import { TTableColum } from '../../models/tableColumns/types';
import { TSearchParameter } from './type';
import * as constants from './constants';

export const createFilterOptionsFromColumns = (columns: TTableColum[]): TSearchParameter[] => {
	return _.map(_.filter(columns, ['IsInternalColumn', false]), column => ({
		displayName: column.AppColumnAliasName || column.AppColumn,
		value: column.AppColumnQueryName,
		queryName: column.AppColumnQueryName,
		rowIdentifier: column.AppColumnName,
		type: column.ColumnType ?? 'text'
	}));
};

export const isValidSearch = (searchInput: string) => {
	return searchInput.length >= 1;
};

export const getCriteriaOperators = criteria => {
	if (!criteria) {
		return;
	}
	let operators = [];
	switch (criteria.type) {
		case 'qty':
		case 'number':
			operators = ['in', 'not.in', 'eq', 'neq', 'bulk.in', 'gt', 'gte', 'lt', 'lte', 'btw', 'nbtw', 'empty', 'not.empty'];
			break;
		case 'text':
			operators = [
				'in', 'not.in',
				'eq',
				'neq',
				'bulk.in',
				'like',
				'not.like',
				'begins.with',
				'ends.with',
				'empty',
				'not.empty'
			];
			break;
		case 'date':
			operators = ['eq', 'neq', 'btw', 'nbtw', 'gt', 'gte', 'lt', 'lte', 'empty', 'not.empty'];
			break;
		case 'percentage':
		case 'percentagefull':
		case 'price':
			operators = ['eq', 'neq', 'btw', 'nbtw', 'gt', 'gte', 'lt', 'lte', 'empty', 'not.empty', 'bulk.in'];
			break;
		case 'bit':
			operators = ['is.true', 'is.false'];
			break;
	}
	return operators.map(o => _.filter(constants.operators, opr => opr.value === o)?.[0]);
	//   return _.filter(constants.operators, (operator) =>
	//     _.some(operators, (o) => o === operator.value)
	//   );
};

export const getSearchParameters = column => ({
	displayName: column.AppColumnAliasName || column.AppColumn,
	value: column.AppColumnQueryName,
	rowIdentifier: column.AppColumnName,
	type: column.ColumnType ?? 'text',
	queryName: column.AppColumnQueryName
});

export const createSearchFilters = (columns: any[]) => {
	return _.compact(
		_.map(columns, column => {
			if (column.IsCustomizableColumn && !column.IsInternalColumn) {
				return {
					displayName: column.AppColumnAliasName || column.AppColumn,
					value: column.AppColumnQueryName,
					rowIdentifier: column.AppColumnName,
					type: column.ColumnType ?? 'text',
					queryName: column.AppColumnQueryName
				};
			}

			return undefined;
		})
	);
};

export const createFiltersFromQueries = (queries: any[]) => {
	return _.map(queries, ({ criteria, query }) => ({
		field: criteria.rowIdentifier,
		value: query
	}));
};

export const isSearchEnabled = ({ selectedOperator, searchInput, searchInputEnd }) => {
	if (
		selectedOperator &&
		(selectedOperator.value === 'empty' ||
			selectedOperator.value === 'not.empty' ||
			selectedOperator.value === 'is.true' ||
			selectedOperator.value === 'is.false')
	) {
		return true;
	} else if (
		(selectedOperator?.value === 'btw' || selectedOperator?.value === 'nbtw') &&
		(!isValidSearch(searchInput) || !isValidSearch(searchInputEnd))
	) {
		return false;
	} else if (isValidSearch(searchInput)) {
		return true;
	} else {
		return false;
	}
};
