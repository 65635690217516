// @ts-nocheck
import * as React from 'react';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import Heading from 'atoms/heading/heading';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import Card from 'atoms/card/card';
import moment from 'moment';
import MasterDataListItem from 'molecules/masterDataList/masterDataListItem';
import { OPTIONS, OPTION_IDENTIFIERS } from './constants';
import { useNavigate } from 'react-router-dom';
import Divider from 'atoms/divider/divider';
import Scrollable from 'atoms/scrollable/scrollable';
import { getEnabledOptions } from './selectors';
import { useSelector } from 'react-redux';

export type OptionTypes = {
	title: string;
	description: string;
	buttonText: string;
	itemIdentifier: string;
};
const today = moment().format('MM/DD/YYYY');

const Settings: React.FunctionComponent = () => {
	const navigate = useNavigate();

	const handleMasterDataButtonClick = (option) => {
				navigate(option.url);
	};

	const getDisabledStatus = (itemIdentifier: string) => {
		switch (itemIdentifier) {
			case OPTION_IDENTIFIERS.APP_COLUMN_ALIASES:
				return false;
			default:
				return true;
		}
	};

	const options = useSelector(state => getEnabledOptions(state));

	console.log(options)

	return (
		<Sheet>
			<SheetBody>
				<Stack isVertical gutter="small">
				
					<StackItem isStretch >
						<Stack  gutter="medium" isWrap>
							{/* <InfiniteScroll
            initialLoad={false}
            style={{ width: '100%', height: '700px' }}
            pageStart={0}
            loadMore={handleLoadMore}
            hasMore={parameterGroups && parameterGroups.hasMore}
            useWindow={false}> */}
							{options.map(option => {
                                return (
									<MasterDataListItem
										title={option.title}
										description={option.description}
										buttonText={option.buttonText}
										handleButtonClick={() => handleMasterDataButtonClick(option)}
										itemIdentifier={option.itemIdentifier}
										disabled={getDisabledStatus(option.itemIdentifier)}
                                        />
								);
							})}

							{/* </InfiniteScroll> */}
						</Stack>
					</StackItem>
				</Stack>
			</SheetBody>
		</Sheet>
	);
};
export default Settings;
