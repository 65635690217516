// @ts-nocheck
import Heading from 'atoms/heading/heading';
import _, { isNumber } from 'lodash';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as React from 'react';
import { List, Switch } from 'antd';
import Tabs from 'atoms/tabs/tabs';
import { Tab } from 'atoms/tab/tab';
import * as constants from './constants';
import * as selectors from './selectors';
import * as queries from './queries';
import { useDispatch, useSelector } from 'react-redux';
import * as sagaActions from './sagaActions';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import NumberInput from 'atoms/NumberInput/numberInput';
import slice, { updateParameters } from './reducer';
import Icon from 'atoms/icons/icon';
import Validation from 'atoms/validation/validation';

type Props = {
	parameter: any;
	selectedTab: string;
	selector: string;
	groupData: Object;
};

const ParameterDetailItem = ({ parameter, selectedTab, selector, groupData }: Props) => {
	const {
		ParameterTitle,
		IsActive,
		IsLocked,
		ParameterFormat,
		Detail_Value,
		Default_Value,
		IsOn,
		ParameterDescription,
		...rest
	} = parameter;
	const [paramValue, updateParamValue] = React.useState(
		ParameterFormat === 'Bit'
			? parameter.UpdatedValue
			: parameter.Detail_Value && isNumber(parameter.Detail_Value)
			? parameter.Detail_Value
			: parameter.Default_Value
	);

	const dispatch = useDispatch();
	const handleUpdateParameter = React.useCallback(
		(updates, isEdit?) => {
			const parametersList = _.map(groupData, p => {
				if (p.ParameterID === updates.ParameterID) {
					return {
						...p,
						...updates
					};
				}
				return p;
			});

			dispatch(
				updateParameters({
					parametersList
				})
			);
			// dispatch(
			// 	sagaActions.upsertParameterDetails({
			// 		...updates,
			// 		ParameterTypeID: selectedTab,
			// 		ParameterDetailID: parameter.ParameterDetailID,
			// 		ParameterID: parameter.ParameterID,
			// 		ParameterValue: _.has(updates, 'UpdatedValue')
			// 			? updates.UpdatedValue
			// 			: parameter.Detail_Value || parameter.Default_Value || 0,
			// 		ParameterMasterID: groupData.ParameterMasterID,
			// 		ParameterGroupItemID: groupData[selector],
			// 		ParameterSelector: selector
			// 	})
			// );
		},
		[dispatch, selectedTab, groupData, updateParamValue]
	);

	return (
		<Stack gutter="large" position="center">
			<StackItem>
				<Switch
					onChange={IsOn =>
						handleUpdateParameter({
							ParameterID: parameter.ParameterID,
							IsOn: Number(IsOn)
							// UpdatedValue: Boolean(Number(IsOn))
						})
					}
					checked={IsOn}
					size={'small'}
				></Switch>
			</StackItem>
			<StackItem isGrow>
				<Stack isVertical>
					<StackItem>
						<Heading type="tertiary">{ParameterTitle}</Heading>
					</StackItem>
					<StackItem>{ParameterDescription}</StackItem>
				</Stack>
			</StackItem>
			{ParameterFormat !== 'Bit' && (
				<StackItem>
					{/* <Validation {...queries.isValidParamValue(paramValue)}> */}
					<NumberInput
						align="right"
						minValue={0}
						onChange={value => {
							handleUpdateParameter({
								UpdatedValue: value,
								ParameterID: parameter.ParameterID
							});
						}}
						value={parameter.UpdatedValue || parameter.Default_Value}
						defaultValue={parameter.UpdatedValue || parameter.Default_Value}
					/>
					{/* </Validation> */}
				</StackItem>
			)}

			{/* {Boolean(IsOn || parameter.IsLocked) && ParameterFormat === 'Bit' && (
				<StackItem>
					{
						<Checkbox
							checked={parameter.Detail_Value}
							onChange={e =>
								handleActivateParameter(
									{
										UpdatedValue: e.target.checked,
										ParameterID: parameter.ParameterID
									},
									true
								)
							}
						></Checkbox>
					}
				</StackItem>
			)} */}
		</Stack>
	);
};

export default ParameterDetailItem;
