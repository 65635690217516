// @ts-nocheck
import * as React from 'react';
import './accordion.css';
import accordionItem from './accordionItem';

interface Props {
  children: React.ReactElement[];
}

const Accordion: React.FunctionComponent<any> = (props: Props) => {
  const { children } = props;
  return <div className="accordion">{children}</div>;
};

export const AccordionItem = accordionItem;
export default Accordion;
