// @ts-nocheck
import { userInfo } from 'os';
import React from 'react';
import Kits from './kits';

export default [
  {
    path: '/orders/kits',
    key: 'key',
    componentId: 'kits',
    component: () => <Kits title="asd" />,
    routes: [],
    title: 'Kits',
  },
];
