// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import { InfiniteScroll } from 'antd';

const PAGE_SIZE = 20;

export const initialState = {};

export const multiSelectDropdownSlice = createSlice({
	name: 'multiSelectDropdown',
	initialState,
	reducers: {
		setOptions: (state, action) => {
			state[action.payload.componentId] = {
				options: action.payload.options,
				loading: false,
				hasMore: true,
				selectedValues: []
			};
		},
		setMoreOptions: (state, action) => {
			return {
				...state,
				[action.payload.componentId]: {
					...state[action.payload.componentId],
					options: action.payload.isAppend
						? [...(state[action.payload.componentId].options ?? []), ...action.payload.options]
						: action.payload.options
				}
			};
		},

		setHasMore: (state, action) => {
			return {
				...state,
				[action.payload.componentId]: {
					...state[action.payload.componentId],
					hasMore: action.payload.hasMore
				}
			};
		},
		setSelectedValues: (state, action) => {
			return {
				...state,
				[action.payload.componentId]: {
					...state[action.payload.componentId],
					selectedValues: action.payload.selectedValues
				}
			};
		}
	}
});

export const { setOptions, setMoreOptions, setHasMore, setSelectedValues } = multiSelectDropdownSlice.actions;
export default multiSelectDropdownSlice;
