// @ts-nocheck

import groupModalSagas from './groupModal/sagas';
import notificationSagas from './notifications/sagas';
import notificationReducer from './notifications/reducer';
import groupModalReducer from './groupModal/reducer';
import kitsModalReducer from './kitsModal/reducer';
import kitsModalSagas from './kitsModal/sagas';
import deleteModalSagas from './deleteModal/sagas';
import deleteModalReducer from './deleteModal/reducer';
import advancedSearchSagas from './searchFilter/sagas';
import advancedSearchReducer from './searchFilter/reducer';
import userSettingsSagas from './settingsDialog/sagas';
import userSettingsReducer from './settingsDialog/reducer';
import multiSelectSagas from './multiSelect/sagas';
import multiSelectReducer from './multiSelect/reducer';
import uploadDataReducer from './uploadData/reducer';
import uploadDataSagas from './uploadData/sagas';

export const organismsSagas = [
	notificationSagas,
	groupModalSagas,
	kitsModalSagas,
	deleteModalSagas,
	advancedSearchSagas,
	multiSelectSagas,
	userSettingsSagas,
	uploadDataSagas
];
export const organismsReducers = [
	notificationReducer,
	kitsModalReducer,
	groupModalReducer,
	deleteModalReducer,
	advancedSearchReducer,
	uploadDataReducer,
	multiSelectReducer,
	userSettingsReducer
];
