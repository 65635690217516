// @ts-nocheck
import Sheet from 'atoms/sheet/sheet';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as React from 'react';
import { useState } from 'react';
import { getDispatch } from 'reducers';
import {
	triggerFetchDemandPlanningGroupDetails,
	triggerFetchDemandPlanningGroups,
	triggerSaveDemandPlanningAttributeDetail
} from './sagaActions';
import Sidebar from 'molecules/sidebar/sidebar';
import SidebarItem from 'molecules/sidebar/sidebarItem';
import BodyText from 'atoms/bodyText/bodyText';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import Heading from 'atoms/heading/heading';
import Card from 'atoms/card/card';
import { useDispatch, useSelector } from 'react-redux';
import {
	getDemandDimesionGroups,
	getDemandPlanningDimensionDetail,
	getDemandPlanningDimensions,
	getSelectedAttributeDetail
} from './selectors';
import Scrollable from 'atoms/scrollable/scrollable';
import Label from 'atoms/label/label';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import { CHECK_STYLED_GROUP_NAMES_TABLEID, PG_TABLE_ID } from './constants';
import Modal from 'atoms/modal/modal';
import ModalHeader from 'atoms/modal/modalHeader';
import ModalBody from 'atoms/modal/modalBody';
import ModalActions from 'atoms/modal/modalActions';
import ReactModal from 'atoms/modal/reactModal';
import Button from 'atoms/button/button';
import DropDown from 'atoms/dropdown/dropdown';
import { getDimesionAttributes } from './selectors';
import TextInput from 'atoms/TextInput/textInput';
import { setSelectedGroupAttributeDetail } from './reducer';
import MultiSelectDropdown from 'organisms/multiSelect/multiSelect';
import { REQUESTS } from 'utils/requests';

const GroupDimensionDetail = ({ planningDimensionId }) => {
	const dimensionAttributes = useSelector(state => getDimesionAttributes(state, planningDimensionId));
	const demandPlanningDimensionDetails = useSelector(state =>
		getDemandPlanningDimensionDetail(state, planningDimensionId)
	);
	const [groupSelectionOptions, setGroupSelectionOptions] = useState(null);
	const selectedAttributeDetails = useSelector(getSelectedAttributeDetail);
	const options = _.map(dimensionAttributes, ({ name }) => ({
		displayName: name,
		value: name
	}));
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(
			setSelectedGroupAttributeDetail({
				selectedAttributeDetail: null
			})
		);
	};
	const [attributeName, setAttributeName] = useState(options?.[0]);
	const [groupName, setGroupName] = useState('');
	React.useEffect(() => {
		if (selectedAttributeDetails) {
			setAttributeName(options.find(d => d.value === selectedAttributeDetails?.Attribute));
			setGroupName(selectedAttributeDetails?.GroupName);
		}
		return () => {
			setAttributeName(null);
			setGroupName('');
			setGroupSelectionOptions('')
		};
	}, [selectedAttributeDetails]);

	const handleSave = () => {
		dispatch(
			triggerSaveDemandPlanningAttributeDetail({
				attributeDetails: {
					planningGroupId: planningDimensionId,
					planningGroupValue: groupName,
					groupAttribute: attributeName?.displayName,
					groupId: selectedAttributeDetails?.GroupID,
				},
				groupSelectionOptions
			})
		);
	};

	React.useEffect(() => {
		if (!attributeName) {
			setAttributeName(options?.[0]);
		}
	}, [options, attributeName]);
	const isFormValid = groupName?.trim() && attributeName;
	const selectionProviderOptions = React.useMemo(() => ({
		type: 'GET',
		version: 2,
		...REQUESTS.GET.PLANNING_GROUP_DROPDOWN,
		tableId: CHECK_STYLED_GROUP_NAMES_TABLEID,
		body: {
			attributeName: attributeName?.displayName
		},
		pathParams: {
			planningDimensionId
		}
	}), [planningDimensionId, attributeName]);


	if (!selectedAttributeDetails) {
		return null;
	}

	return (
		<ReactModal height={550} width={400} hideModal={handleClose}>
			<ModalHeader>
				<Heading type="tertiary">Group Attribute Details</Heading>
			</ModalHeader>
			<ModalBody>
				<Stack isVertical gutter="medium">
					<StackItem>
						<Stack isVertical gutter="small">
							<StackItem>
								<Label>Attribute: </Label>
							</StackItem>
							<StackItem>
								<DropDown
									key={'options'}
									options={options}
									updateHandler={e => {
										setAttributeName(e);
										setGroupName('');
									}}
									selectedOption={attributeName}
								></DropDown>
							</StackItem>
						</Stack>
					</StackItem>
					<StackItem>
						<Stack isVertical gutter="small">
							<StackItem>
								<Label>Group Name: </Label>
							</StackItem>
							<StackItem isGrow>
								<MultiSelectDropdown
									componentId={'select-' + demandPlanningDimensionDetails.ColumnName}
									key={'select-' + demandPlanningDimensionDetails.ColumnName}
									onChange={(e, selectAllOptions) => {
										setGroupName(e.target.value, selectAllOptions);
										setGroupSelectionOptions(selectAllOptions)
									}}
									selection={groupName}
									isInline
									hasInputToggle
									// isSingleSelect
									selectionProviderOptions={selectionProviderOptions}
									columnName={demandPlanningDimensionDetails.ColumnName}
								/>
							</StackItem>
						</Stack>
					</StackItem>
				</Stack>
			</ModalBody>
			<ModalActions>
				<Stack gutter="small" align="right">
					<StackItem>
						<Button type="secondary" handleClick={handleClose}>
							{' '}
							Cancel
						</Button>
					</StackItem>
					<StackItem>
						<Button handleClick={handleSave} type="primary" disabled={!isFormValid}>
							Save
						</Button>
					</StackItem>
				</Stack>
			</ModalActions>
		</ReactModal>
	);
};

export default GroupDimensionDetail;
