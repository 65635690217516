// @ts-nocheck
import BodyText from 'atoms/bodyText/bodyText';
import DatePicker from 'atoms/datePicker/datePicker';
import Dropdown from 'atoms/dropdown/dropdown';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as sagaActions from '../sagaActions';
import * as selectors from '../selectors';

export interface OrderCloneType {
	CloneOrderTypeID: number;
	CloneOrderType: string;
}

interface DropdownType {
	displayName: string;
	value: string;
}
interface Props {
	dropdownTypes: ReadonlyArray<OrderCloneType>;
}

const SelectParameters = (props: Props) => {
	const { dropdownTypes } = props;

	const today = moment().format('MM-DD-YYYY');
	const dispatch = useDispatch();
	const selectedParams = useSelector(state => selectors.getCloneSelectedParameters(state));

	const selectedOption = dropdownTypes.filter(option => option.value === selectedParams?.orderCloneType);

	const handleDropdownSelect = (item: DropdownType) => {
		const selectedParameters = {
			...selectedParams,
			orderCloneType: item.value
		};
		dispatch(sagaActions.updateCloneSelectedParameters(selectedParameters));
	};
	const handleDateChange = e => {
		const selectedParameters = {
			...selectedParams,
			[e.target.name]: e.target.value
		};
		dispatch(sagaActions.updateCloneSelectedParameters(selectedParameters));

		// updateData(e.target.name, e.target.value)
	};

	return (
		<Stack isVertical gutter="small">
			<StackItem>
				<Stack divisions={5}>
					<StackItem span={3} isGrow>
						<BodyText type="secondary">Clone Order Type*</BodyText>
					</StackItem>
					<StackItem span={2}>
						<Dropdown
							placeholder={'Order Clone Type'}
							options={dropdownTypes}
							updateHandler={handleDropdownSelect}
							selectedOption={selectedOption?.[0]}
						/>
					</StackItem>
				</Stack>
			</StackItem>
			<StackItem>
				<Stack>
					<StackItem isGrow>
						<BodyText> New Ship Date* </BodyText>
					</StackItem>
					<StackItem>
						<DatePicker
							name={'shipDate'}
							min={today}
							max={moment().add(3, 'months').format('MM-DD-YYYY')}
							selectedDate={selectedParams?.shipDate || ''}
							handleClick={handleDateChange}
						/>
					</StackItem>
				</Stack>
			</StackItem>
			<StackItem>
				<Stack>
					<StackItem isGrow>
						<BodyText> New PO Date*</BodyText>
					</StackItem>
					<StackItem>
						<DatePicker
							name={'poDate'}
							min={today}
							max={moment().add(3, 'months').format('MM-DD-YYYY')}
							selectedDate={selectedParams?.poDate || ''}
							handleClick={handleDateChange}
						/>
					</StackItem>
				</Stack>
			</StackItem>
		</Stack>
	);
};

export default SelectParameters;
