// @ts-nocheck
import _ from 'lodash';
import { downloadGridData, fetchGridData } from 'models/dynamicGrid/sagaActions';
import { updateUserInfo } from 'models/user/reducer';
import { getWebAppSettings } from 'models/user/selector';
import { triggerNotification } from 'organisms/notifications/sagaActions';
import { updateStatusID } from 'pages/home/sagaActions';
import { getState } from 'reducers';
import { put, takeEvery, select, all } from 'redux-saga/effects';
import { getAPIDataV2, logOut, putAPIDataV2 } from 'utils/api';
import * as slice from './reducer';
import * as sagaActions from './sagaActions';
import * as selector from './selector';
import * as types from './types';
import { handleAsyncGridUpload } from 'organisms/uploadData/sagaActions';

type TPayload = {
	payload: {
		eventName: 'refresh' | 'notification' | 'updates';
		metaData: types.EventMetaData;
		type: 'NOTIF_EV' | 'SYS_EV' | 'ACTIV_EV';
	};
};

export default function* rootSaga() {
	function* handlePublishEvent({ payload }) {
		yield put(updateStatusID(null));
		yield put(
			triggerNotification({
				message: payload?.metaData?.message,
				type: payload?.metaData?.type,
				isPersistent: payload?.metaData?.isPersistent
			})
		);
	}
	function* handleReceiveEvent({ payload }: TPayload) {
		switch (payload?.type) {
			// all TABLE_EV's are handled in individual tables
			case 'RELEASE_EV':
				const webAppSettings = getWebAppSettings(getState());
				yield put(
					updateUserInfo({
						webAppSettings: {
							...webAppSettings,
							ReleaseVersion: payload.metaData.ReleaseVersion,
							isBlocking: payload.metaData.isBlocking
						}
					})
				);
				break;
			case 'GRID_UPLOAD_EVENT':
					yield put(
						handleAsyncGridUpload({
							...payload.metaData
						})
					);
					break;
			case 'STATUS_EV':
				yield put(
					sagaActions.handlePublishEvent({
						...payload
					})
				);
				break;

			case 'NOTIF_EV':
				yield put(
					triggerNotification({
						message: payload?.metaData?.message,
						type: payload?.metaData?.type,
						isPersistent: payload?.metaData?.isPersistent
					})
				);
				break;
			case 'SYS_EV':
				if (payload.metaData.isHardRefresh) {
					window.location.reload();
				}
				if (payload.metaData.isHardLogout) {
					logOut();
				}
				break;
			case 'DB_STATUS':
				let state;
				yield put(
					slice.updateAppState({
						isActive: payload?.metaData?.isConnected,
						hasNetworkError: payload?.metaData?.hasNetworkError,
						...{ isInvalidUser: payload?.metaData?.isInvalidUser },
						...{ isLoggedOutUser: payload?.metaData?.isLoggedOutUser }
					})
				);
				break;
			case 'FILE_DOWNLOAD_EV':
				//
				yield put(downloadGridData({ link: payload?.metaData?.link, tableId: payload?.metaData?.tableId }));
				break;
			case 'TABLE_EV':
				if (payload?.metaData?.message) {
					yield put(
						triggerNotification({
							type: payload?.metaData?.type,
							message: payload?.metaData?.message
						})
					);
				}
				return;
			default:
				yield put(
					triggerNotification({
						type: payload?.metaData?.type,
						message: payload?.metaData?.message,
						isPersistent: payload?.metaData?.isPersistent
					})
				);
		}
	}

	return [
		yield takeEvery(sagaActions.onReceiveEvent, handleReceiveEvent),
		yield takeEvery(sagaActions.handlePublishEvent, handlePublishEvent)
	];
}
