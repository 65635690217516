// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const togglePlanogramState = createAction(
    'planogram/togglePlanogramState',
    function prepare({ row,
        id,
        isActive,
        selection,
        isSelectAll,
        selectAllSearch, isBulkUpdate }) {
        return {
            payload: {
                row,
                id,
                selection,
                isActive,
                isSelectAll,
				selectAllSearch,
                isBulkUpdate
            },
        };
    }
);



export const addPlanogramStores = createAction(
    'planogram/addPlanogramStores',
    function prepare({     storeIds,
        planogramIds,
        isSelectAll,
        selectAllSearch }) {
        return {
            payload: {
                storeIds,
                planogramIds,
                isSelectAll,
                selectAllSearch
            },
        };
    }
);
