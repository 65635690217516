// @ts-nocheck
import Heading from 'atoms/heading/heading';
import Sheet from 'atoms/sheet/sheet';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import ActionBar from 'pages/orders/actionBar/actionBar';
import * as sagaActions from 'pages/orders/sagaActions';
import React from 'react';
import { useDispatch } from 'react-redux';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import { REQUESTS } from 'utils/requests';
import * as constants from './constants';
const Kits: React.FunctionComponent = () => {
	const [isFilterEnabled, updateFilterEnabled] = React.useState(false);

	const tableId = constants.TABLE_ID.toString();
	const dispatch = useDispatch();

	const gridConfig = {
		enableSelection: true,
		enableSorting: true,
		selectionCallBack: () => {}
	};

	const handleResetViewClick = async () => {
		try {
			dispatch(sagaActions.resetTable({ tableId: tableId }));
		} catch (e) {
			console.error(e);
		}
	};

	const templateConfig = {
		gridConfig,
		isFilterEnabled: isFilterEnabled === undefined ? false : isFilterEnabled,
		tableId: constants.TABLE_ID,
   		 pathParams: { tableId: 30 },
		dataProviderOptions: {
			type: 'POST',
			...REQUESTS.POST.GET_GRID_DETAILS_V2,
			version: 2
		},
		selectRowKey: 'OrderKitID'
	};

	return (
		<React.Fragment>
			<Sheet isPage>
				<Stack isVertical gutter="medium">
					<StackItem>
						<Stack gutter="medium">
							<StackItem isGrow>
								<Heading type="primary">Kits</Heading>
							</StackItem>
							<StackItem>
								<ActionBar
									isFilterEnabled={isFilterEnabled}
									handleUpdateFilter={updateFilterEnabled}
									selectedTab={tableId}
									handleResetViewClick={handleResetViewClick}
								/>
							</StackItem>
						</Stack>
					</StackItem>
					<StackItem isGrow isStretch>
						<ThetaGridPaginated hasChildRows config={templateConfig} />
					</StackItem>
				</Stack>
			</Sheet>
		</React.Fragment>
	);
};
export default Kits;
