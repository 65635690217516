// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import * as constants from './constants';
import { TReducer } from './types';

export const initialState: TReducer = {
	data: {},
	isLoading: {},
	metaData: {},
	customers: {},
	adTypes: {},
	stores: {},
	adTabs: []
};

const slice = createSlice({
	initialState,
	name: `${constants.MODEL_NAME}`,
	reducers: {
		updateAdsData(state, { payload: { name, data } }) {
			return {
				...state,
				data: {
					...state.data,
					[name]: data
				}
			};
		},
		updateLoader(state, { payload: { eventName, isLoading } }) {
			return {
				...state,
				isLoading: {
					...state.isLoading,
					[eventName]: isLoading
				}
			};
		},
		updateMetadata(state, { payload: { name, metaData } }) {
			return {
				...state,
				metaData: {
					...state.metaData,
					[name]: metaData
				}
			};
		},
		updateCustomers(state, { payload: { name, data } }) {
			return {
				...state,
				customers: {
					...state.customers,
					[name]: data
				}
			};
		},
		updateAdTypes(state, { payload: { name, data } }) {
			return {
				...state,
				adTypes: data
			};
		},
		updateAdTabs(state, { payload: { data } }) {
			return {
				...state,
				adTabs: data
			};
		},
		updateStores(state, { payload: { name, stores } }) {
			return {
				...state,
				stores: {
					...state.stores,
					[name]: stores
				}
			};
		}
	}
});

export default slice;

export const {
	updateAdsData,
	updateLoader,
	updateMetadata,
	updateCustomers,
	updateAdTypes,
	updateAdTabs,
	updateStores
} = slice.actions;
