export const GRID_EVENTS = {
	UPDATE_GRID: 'UPDATE',
	UPDATE_GRID_CELL: 'UPDATE_CELL',
	DELETE_ROWS: 'DELETE_ROWS',
	REFRESH_GRID: 'REFRESH',
	PURGE_GRID_ROUTE: 'PURGE_GRID_ROUTE',
	CLEAR_SELECTION: 'CLEAR_SELECTION',
	EXPORT: 'EXPORT'
};

export const PAGE_SIZE = 50;
