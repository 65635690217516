/* eslint-disable import/no-anonymous-default-export */
// @ts-nocheck
import React from 'react';
import Advertising from './advertising';
import AdvertisingSagas from 'pages/advertising/sagas';
import adModalReducer from './adModal/reducer';
import adModalSagas from './adModal/sagas';
import { isAdvertisingReadWrite } from 'models/user/selector';
export default [
	{
		path: '/advertising',
		key: 'key',
		componentId: 'advertising',
		component: () => <Advertising title="asd" />,
		routes: [],
		title: 'Skulicity | Advertising',
		importSagas: () => {
			return [AdvertisingSagas, adModalSagas];
		},
		isEnabled: (state) => {
			return isAdvertisingReadWrite(state)
		},
		importReducer: () => {
			return [adModalReducer];
		},
		headerName: 'Advertising'
	}
];
