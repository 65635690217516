// @ts-nocheck
import * as React from 'react';
import './sidecar.scss';
import classNames from 'classnames';
import Icon from 'atoms/icons/icon';

interface Props extends React.PropsWithChildren<any> {
	isActive: boolean;
}

const SideCar: React.FunctionComponent<any> = (props: Props) => {
	const { isActive, children, handleOnClose, isFixed = true } = props;
	const classes: any = classNames(['sidecar', { [`sidecar--active`]: isActive }, { [`sidecar--fixed`]: isFixed }]);

	return (
		<div className={classes}>
			<div className="sidecar--close">
				<Icon isActive size="large" faAlias="faTimes" onClick={handleOnClose} />
			</div>
			<div className="sidecar--body">{children}</div>
		</div>
	);
};

export default SideCar;
