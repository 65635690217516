// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import * as constants from './constants';
import update from 'react-addons-update';

export const initialState: any = {
	groupData: {},
	isOpen: false,
	editMode: false,
	editData: {},
	isLoading: false,
	customers: []
};

const slice = createSlice({
	initialState,
	name: `${constants.MODEL_NAME}`,
	reducers: {
		updateEditData(state, { payload: { data } }) {
			return {
				...state,
				editData: data
			};
		},
		cleanUp() {
			return {
				...initialState
			};
		},
		updateEditMode(state, { payload: { edit } }) {
			return {
				...state,
				editMode: edit
			};
		},
		updateGroupData(state, { payload: { data } }) {
			return {
				...state,
				groupData: data
			};
		},
		updateModalToggle(state, { payload: { isOpen } }) {
			return {
				...state,
				isOpen: isOpen
			};
		},
		updateLoader(state, { payload: { isLoading } }) {
			return {
				...state,
				isLoading: isLoading
			};
		},
		updateCustomersData(state, { payload: { data } }) {
			return {
				...state,
				customers: data
			};
		}
	}
});

export default slice;
export const {
	updateGroupData,
	updateModalToggle,
	updateEditMode,
	updateEditData,
	cleanUp,
	updateLoader,
	updateCustomersData
} = slice.actions;
