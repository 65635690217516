// @ts-nocheck
import React from "react";
import Link from "atoms/link/link";
import BodyText from "atoms/bodyText/bodyText";
import Stack from "molecules/stack/stack";
import StackItem from "molecules/stackItem/stackItem";
import Icon from "atoms/icons/icon";

const Breadcrumbs = ({ crumbs }) => {
  if (!crumbs || crumbs.length <= 1) {
    return null;
  }
  return (
    <div className="breadcrumbs">
      <Stack gutter="small" position="center">
        {crumbs.map(({ name, path }, key) =>
          key + 1 === crumbs.length ? (
            <StackItem position="center" key={key}>
              <BodyText>{name?.charAt?.(0).toUpperCase() + name?.slice(1)}</BodyText>
            </StackItem>
          ) : (
            <StackItem position="center" key={key}>
              <Stack gutter="small" position="center">
                <StackItem position="center">
                  <Link type="tertiary" route={path}>
                    {name?.charAt?.(0)?.toUpperCase() + name?.slice(1)}
                  </Link>
                </StackItem>
                <StackItem position="center">
                  <Icon faAlias="faAngleRight"></Icon>
                </StackItem>
              </Stack >
            </StackItem >
          )
        )}
      </Stack >
    </div >
  );
};
export default Breadcrumbs;
