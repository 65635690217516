// @ts-nocheck

import { createAction } from "@reduxjs/toolkit";

// update user settings
export const updateUserAgGridSettings = createAction(
  "settingsModal/update-user--ag-grid-settings",
  function prepare(payload) {
    return {
      payload: payload,
    };
  }
);
