// @ts-nocheck
import qs from "qs";
import { put, takeEvery } from "redux-saga/effects";
import {
  getAPIData,
  getAPIDataV2,
  deleteAPIDataV2,
  putAPIDataV2,
  postAPIDataV3,
} from "utils/api";
import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";
import * as constants from "./constants";
import { getItemFromLocalStorage, setItemInLocalStorage } from "utils/storage";
import { triggerNotification } from "organisms/notifications/sagaActions";
import { getDispatch, getState } from "reducers";
import {
  handleRowUpdateSuccess,
  refreshGridData,
} from "models/dynamicGrid/sagaActions";
import { getMultiSelectOptions } from "organisms/multiSelect/selectors";
import { getGridFilters, getTableSelection } from "models/dynamicGrid/selector";
import { createFiltersFromQueries } from "models/dynamicGrid/query";
import { REQUESTS } from "utils/requests";

async function deleteColumnAlias({ payload: { ColumnName } }) {
  try {
    const tableSelection = getTableSelection(
      getState(),
      constants.COLUMNS_TABLE_ID
    );
    const searchParams = getGridFilters(getState(), constants.COLUMNS_TABLE_ID);
    const dispatch = getDispatch();
    const response = await postAPIDataV3({
      service: REQUESTS.POST.REMOVE_COLUMN_ALIAS.service,
      url: REQUESTS.POST.REMOVE_COLUMN_ALIAS.url,
      body: {
        rowIdentifier: ColumnName || tableSelection?.selectedRowKeys,
        isSelectAll: tableSelection?.isSelectAll,
        selectAllSearch: JSON.stringify({
          filter: createFiltersFromQueries(searchParams?.search),
        }),
      },
    });
    dispatch(
      refreshGridData({
        tableId: constants.COLUMNS_TABLE_ID,
      })
    );
  } catch (e) {
    console.log(e);
  }
}

async function createAppColumnAlias({ payload: { columnName, aliasName } }) {
  try {
    const { options } = getMultiSelectOptions(
      getState(),
      constants.SELECTION_COMPONENT
    );
    const selectedColumn = options.find((o) => o.label === columnName);
    const dispatch = getDispatch();
    const response = await postAPIDataV3({
      service: REQUESTS.POST.ADD_COLUMN_ALIAS.service,
      url: REQUESTS.POST.ADD_COLUMN_ALIAS.url,
      body: {
        AppColumnName: selectedColumn?.value,
        AppColumnAlias: aliasName,
        AppColumn: selectedColumn?.label,
      },
    });
    dispatch(
      refreshGridData({
        tableId: constants.COLUMNS_TABLE_ID,
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export default function* rootSaga() {
  return [
    yield takeEvery(sagaActions.triggerDeleteColumnAlias, deleteColumnAlias),
    yield takeEvery(
      sagaActions.triggerCreateAppColumnAlias,
      createAppColumnAlias
    ),
  ];
}
