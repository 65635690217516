// @ts-nocheck

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { getGridData, getTableSelection, isLoading } from 'models/dynamicGrid/selector';

const SelectBoxAg = props => {
	const { rowIndex, onChangeHandler, data, selectedData = [] } = props;
	const tableId = props.column.colDef.metaData.tableId;
	const selectedRowKeys: any = useSelector(state => getTableSelection(state, tableId));
	const isLoadingGrid = useSelector(state => isLoading(state, tableId));
	const hasGridData = useSelector(state => getGridData(state, tableId)?.length);
	const [selected, setSelected] = useState(false);

	useEffect(() => {
		setSelected(selectedRowKeys?.isSelectAll);
	}, [selectedRowKeys?.isSelectAll]);

	const onChangeSelectBox = () => {
		setSelected(!selected);
		onChangeHandler(!selected, data?.[rowIndex]);
	};

	return (
		<div className="select-box">
			<input
				disabled={isLoadingGrid && !hasGridData}
				type={'checkbox'}
				onChange={onChangeSelectBox}
				checked={selected}
				style={{ cursor: 'pointer' }}
			/>
		</div>
	);
};

export default SelectBoxAg;
