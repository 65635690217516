// @ts-nocheck
import { initialState } from './reducer';

const getStateData = (state: any) => state.storeSchedules || initialState;

export const isLoading = (state: any, eventName: string): boolean => {
  const reducer = getStateData(state);

  return reducer.isLoading[eventName];
};

export const getFilters = (state: any): any => {
  const reducer = getStateData(state);

  return reducer.filters;
};

export const getStoreSchedules = (state: any): any => {
  const reducer = getStateData(state);

  return reducer.storeSchedules;
};

export const getSelectedSchedule = (state: any): any => {
  const reducer = getStateData(state);

  return reducer.selectedSchedule;
};

export const isStoreScheduleModalVisible = (state: any): any => {
  const reducer = getStateData(state);

  return reducer.isStoreScheduleModalVisible;
};

export const isScheduleDaysModalVisible = (state: any): any => {
  const reducer = getStateData(state);

  return reducer.isScheduleDaysModalVisible;
};
