// @ts-nocheck
import Divider from 'atoms/divider/divider';
import GridActionBar from 'atoms/GridActionBar';
import Heading from 'atoms/heading/heading';
import Icon from 'atoms/icons/icon';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import { Tab } from 'atoms/tab/tab';
import Tabs from 'atoms/tabs/tabs';
import _ from 'lodash';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import * as tableColumnSagaActions from 'models/tableColumns/sagaActions';
import * as tableColumnSelectors from 'models/tableColumns/selector';
import {
	isAvailabilityDelete,
	isAvailabilitySummaryDelete,
	isAvailabilitySummaryReadWrite,
	isAvailabilitySummaryWrite,
	isAvailabilityWrite,
} from 'models/user/selector';
import SideCar from 'molecules/sidecar/sidecar';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import moment from 'moment';
import CustomizeViewModal from 'organisms/CustomizeViewModal';
import ThetaDeleteModal from 'organisms/deleteModal/deleteModal';
import * as deleteModalSagaActions from 'organisms/deleteModal/sagaActions';
import * as deleteModalSelectors from 'organisms/deleteModal/selectors';
import ExportGrid from 'organisms/exportGrid';
import KitsModal from 'organisms/kitsModal';
import { updateModalToggle } from 'organisms/kitsModal/reducer';
import ThetaMenu from 'organisms/thetaMenu/thetaMenu';
import UploadData from 'organisms/uploadData';
import GridCsvUpload from 'organisms/uploadData/gridCsvUpload';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import { REQUESTS } from 'utils/requests';

import AvailabilityInfo from './availabilityInfo';
import * as constants from './constants';
import NotificationCards from './notificationCards';
import * as sagaActions from './sagaActions';
import { getAvailabilityInfo } from './selectors';
import { TTableTab } from './types';
import { isNewUploadEnabled } from 'models/user/selector';
import Label from 'atoms/label/label';

export interface TProps {
	title?: string;
}
const AVAILABILITY_DELETE_MODAL = 'availabilityDeleteModal';

const Availability: React.FunctionComponent<TProps> = (props: TProps) => {
	const [isSideCarEnabled, setSideCarVisibility] = React.useState<boolean>(false);
	const [sideCar, setSideCar] = React.useState<string>();
	const [activeTable, setActiveTable] = React.useState<TTableTab>(constants.AVAILABILITY_TYPES_TABS[0]);
	const hasSearchFilters = useSelector(state => dynamicGridSelectors.hasSearchFilters(state, activeTable.tableId));
	const gridMeta = useSelector(state => dynamicGridSelectors.getGridMetadata(state, activeTable.tableId))

	const [isFilterEnabled, updateFilterEnabled] = React.useState<boolean>();

	React.useEffect(() => {
		if (isFilterEnabled === undefined) {
			updateFilterEnabled(hasSearchFilters);
		}
	}, [hasSearchFilters]);

	const dispatch = useDispatch();
	const isDeleteEnabled = useSelector(isAvailabilityDelete);
	const updateActiveTable = React.useCallback(
		({ value }: { value: number }) => {
			const tab = _.find(constants.AVAILABILITY_TYPES_TABS, {
				tableId: value
			});

			if (tab) {
				setActiveTable(tab);
			}
		},
		[dispatch]
	);


	const openModalName = useSelector(state => deleteModalSelectors.getOpenModalName(state));

	const filters = useSelector(state => dynamicGridSelectors.getGridFilters(state, activeTable.tableId));

	const isCustomizeViewModalEnabled = useSelector(state => tableColumnSelectors.isCustomizeViewModalEnabled(state));

	const customizeViewHandler = React.useCallback((isCustomizeViewModalEnabled: boolean) => {
		dispatch(tableColumnSagaActions.setCustomizeViewModal(isCustomizeViewModalEnabled));
	}, []);

	const hideCustomizeViewModal = React.useCallback(() => {
		customizeViewHandler(false);
	}, []);

	const handleFilterClick = React.useCallback(() => {
		updateFilterEnabled(!isFilterEnabled);
	}, [isFilterEnabled]);

	const handleRefreshClick = React.useCallback(() => {
		dispatch(
			dynamicGridSagaActions.refreshGridData({
				tableId: activeTable.tableId,
			})
		);
		dispatch(sagaActions.getAvailabilityInfo({ tableId: activeTable.tableId }));
	}, [activeTable, filters]);

	const handleResetTableColumns = React.useCallback(async () => {
			dispatch(tableColumnSagaActions.resetTableColumnsAg(activeTable.tableId));
	}, [activeTable]);



	const hideSideCar = React.useCallback(() => {
		setSideCarVisibility(false);
	}, []);

	const updateSideCar = React.useCallback((value: string) => {
		setSideCarVisibility(true);
		setSideCar(value);
	}, []);

	const loading = useSelector(state => deleteModalSelectors.getLoadingStatus(state));

	const selectedAvailability = useSelector(state =>
		dynamicGridSelectors.getTableSelection(state, activeTable.tableId)
	);

	const getDisabledStatus = React.useCallback(
		(option: any) => {
			switch (option.value) {
				case constants.AVAILABILITY_MENU_VALUES.CUSTOMIZE_VIEW:
				case constants.AVAILABILITY_MENU_VALUES.RESET_VIEW:
				case constants.AVAILABILITY_MENU_VALUES.UPLOAD_DATA:
					return false;
				case constants.AVAILABILITY_MENU_VALUES.DELETE:
					return !selectedAvailability?.selectedRowKeys?.length;
				case constants.AVAILABILITY_MENU_VALUES.CREATE_KIT:
					return !selectedAvailability?.selectedRowKeys?.length;
				default:
					return true;
			}
		},
		[selectedAvailability]
	);

	const handleBulkDeleteClick = () => {
		dispatch(deleteModalSagaActions.deleteClick(activeTable.tableId));
	};

	const deleteAvailability = () => {
		dispatch(deleteModalSagaActions.toggleModalOpen(AVAILABILITY_DELETE_MODAL));
	};
	const hasAvailabilityWrite = useSelector(isAvailabilityWrite);
	const hasAvailabilityDelete = useSelector(isAvailabilityDelete);

	const hasAvailabilitySummaryAccess = useSelector(isAvailabilitySummaryReadWrite);
	const hasAvailabilitySummaryWrite = useSelector(isAvailabilitySummaryWrite);
	const hasAvailabilitySummaryDelete = useSelector(isAvailabilitySummaryDelete);


	const hasGridActions = () => {
		if (activeTable.tableId === constants.AVAILABILITY && hasAvailabilityDelete) {
			return true
		}
		else if (activeTable.tableId === constants.AVAILABILITY_SUMMARY) {
			return false
		}
		return false
	}

	const getMenuOptions = () => {
		if (activeTable.tableId === constants.AVAILABILITY && hasAvailabilityWrite) {
			return menuItems.filter(x => x.value !== 'CUSTOMIZE_VIEW')
		}
		else if (activeTable.tableId === constants.AVAILABILITY_SUMMARY && hasAvailabilitySummaryWrite) {
			return menuItems.filter(x => x.value !== 'CUSTOMIZE_VIEW')

		}
		return menuItems.slice(0, 1)
	}


	const handleMenuItemClick = React.useCallback(
		(option: any) => {
			switch (option) {
				case constants.AVAILABILITY_MENU_VALUES.CUSTOMIZE_VIEW:
					return customizeViewHandler(true);
				case constants.AVAILABILITY_MENU_VALUES.RESET_VIEW:
					return handleResetTableColumns();
				case constants.AVAILABILITY_MENU_VALUES.UPLOAD_DATA:
					return updateSideCar(constants.SIDE_CAR_COMPONENTS[0]);
				case constants.AVAILABILITY_MENU_VALUES.DELETE:
					return deleteAvailability();
				case constants.AVAILABILITY_MENU_VALUES.CREATE_KIT:
					return dispatch(
						updateModalToggle({
							isOpen: true,
							isAvailabilityUpdate: true,
							tableId: activeTable.tableId
						})
					);
				default:
					return;
			}
		},
		[handleResetTableColumns, selectedAvailability, activeTable]
	);

	const gridConfig = {
		enableSelection: activeTable.tableId === 7 && true,
		enableSorting: true,
		enableMultiSort: true,
		selectionCallBack: () => { },
		gridActions: hasGridActions()
	};

	const templateConfig = React.useMemo(
		() => ({
			gridConfig,
			isFilterEnabled: isFilterEnabled === undefined ? false : isFilterEnabled,
			tableId: activeTable.tableId,
			showOpenedGroup:true, 
			groupHideOpenParents:true,
			dataProviderOptions: {
				...REQUESTS.POST.GET_GRID_DETAILS_V2,
				type: 'POST',
				version: 2,
				pathParams: { tableId: activeTable.tableId },
			},
			selectRowKey: activeTable.tableId === 7 && 'AvailabilityID'
		}),
		[activeTable, isFilterEnabled, gridConfig]
	);

	const availabilityInfo = useSelector(state => getAvailabilityInfo(state));

	let lastUpdateAt: string = '--';

	if (availabilityInfo?.LastUpdate) {
		lastUpdateAt = moment(availabilityInfo?.LastUpdate).format('MM/DD/YYYY');
	}

	const hideDeleteModal = () => {
		dispatch(deleteModalSagaActions.toggleModalClose());
	};
	const isNewUpload = useSelector(isNewUploadEnabled);

	const menuItems = activeTable.tableId === 7 ? constants.AVAILABILITY_MENU : constants.AVAILABILITY_SUMMARY_MENU;
	console.log(gridMeta)
	return (
		<React.Fragment>
			<Stack isVertical>
				<StackItem isStretch>
					<GridActionBar>
						<StackItem>
							<Stack gutter="medium">
								<StackItem isGrow>
									<Label isWhite isHiglight>Last Updated: {lastUpdateAt}</Label>
								</StackItem>
								<StackItem>
									<Stack gutter="medium">
										<StackItem isGrow />
										{isNewUpload && gridMeta?.UploadTableID && <StackItem>
											<GridCsvUpload uploadTableId={gridMeta?.UploadTableID} parentTableId={activeTable.tableId} />
										</StackItem>}
										<StackItem>
											<Icon
												isActive
												type="secondary"
												faAlias="faRecycle"
												onClick={handleRefreshClick}
											/>
										</StackItem>

										<StackItem>
											<ExportGrid key={activeTable.tableId} tableId={activeTable.tableId} />
										</StackItem>
										<StackItem>
											<ThetaMenu
												type="3Dots"
												options={
													getMenuOptions()
												}
												handleItemClick={handleMenuItemClick}
												isDisabled={getDisabledStatus}
											/>
										</StackItem>
									</Stack>
								</StackItem>
							</Stack>
						</StackItem>
					</GridActionBar>
				</StackItem>
				<StackItem isStretch isGrow>
					<Sheet isPage>
						<KitsModal></KitsModal>

						<Stack isVertical gutter="small">

							<StackItem isStretch>
								<Divider />
							</StackItem>
							<StackItem>
								{<NotificationCards availabilityInfo={availabilityInfo} tableId={activeTable.tableId} />}
							</StackItem>
							<StackItem isStretch isGrow>
								<Stack isVertical>
									<StackItem>
										<Tabs>
											{_.compact(_.map(constants.AVAILABILITY_TYPES_TABS, (tab, index) => {
												if (!hasAvailabilitySummaryAccess && tab.tableId === constants.AVAILABILITY_SUMMARY) {
													return null;
												}
												return (
													<Tab
														key={index}
														value={tab.tableId}
														isSelected={activeTable.tableId === tab.tableId}
														onSelectTabItem={updateActiveTable}
													>
														{tab.name}
													</Tab>
												);
											}))}
										</Tabs>
									</StackItem>
									<StackItem isGrow>
										<SheetBody>
											<ThetaGridPaginated key={activeTable.tableId} config={templateConfig} />
										</SheetBody>
									</StackItem>
								</Stack>
							</StackItem>
						</Stack>
					</Sheet>
				</StackItem>
			</Stack>


			{isCustomizeViewModalEnabled && (
				<CustomizeViewModal hideModal={hideCustomizeViewModal} tableId={activeTable.tableId} />
			)}

			{isSideCarEnabled && (
				<SideCar isActive handleOnClose={hideSideCar}>
					{sideCar === constants.SIDE_CAR_COMPONENTS[0] && (
						<UploadData isAvailability tableId={activeTable.tableId.toString()} />
					)}
					{sideCar === constants.SIDE_CAR_COMPONENTS[1] && <AvailabilityInfo />}
				</SideCar>
			)}
			{openModalName === AVAILABILITY_DELETE_MODAL && (
				<ThetaDeleteModal
					hideModal={hideDeleteModal}
					modalTitle={'Availability'}
					title={` (${selectedAvailability?.isSelectAll ? 'All' : selectedAvailability?.selectedRowKeys?.length
						}) selected  item(s)`}
					handleDeleteClick={handleBulkDeleteClick}
					loading={loading}
				/>
			)}
		</React.Fragment>
	);
};

export default Availability;
