// @ts-nocheck

import { EventType, PublicClientApplication, CacheLookupPolicy } from '../msal-browser';
import { msalConfig } from 'authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

export const getCachePolicyInstance = () => CacheLookupPolicy;
// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
	// Account selection logic is app dependent. Adjust as needed for different use cases.
	msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

// Listen for sign-in event and set active account
msalInstance.addEventCallback(event => {
	if (event?.eventType === EventType.LOGIN_SUCCESS && event?.payload?.account) {
		const account = event.payload.account;
		msalInstance.setActiveAccount(account);
	}
});

export const getMsalInstance = () => msalInstance;
