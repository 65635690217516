// @ts-nocheck
import Icon from 'atoms/icons/icon';
import jwt_decode from 'jwt-decode';
import _ from 'lodash';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
// import Menu, { MenuItem, SubMenu } from 'rc-menu';
import React from 'react';
import { isMobile } from 'react-device-detect';
import './intelligence.css';
import * as services from './service';
// import 'rc-menu/assets/index.css';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import ThetaMenu, { ThetaMenuItem } from 'atoms/thetamenu/thetamenu';
import Divider from 'atoms/divider/divider';
import DropDown from 'atoms/dropdown/dropdown';
import Label from 'atoms/label/label';

const defaultReportSettings = {
	filterPaneEnabled: true,
	personalBookmarksEnabled: true,
	persistentFiltersEnabled: true,
	panes: {
		bookmarks: { visible: false },
		fields: { expanded: false },
		filters: { expanded: false, visible: true },
		pageNavigation: {
			visible: true,
			position: models.PageNavigationPosition.Left
		},
		selection: { visible: false },
		syncSlicers: { visible: false },
		visualizations: { expanded: false }
	}
};

const defaultReportConfig = {
	type: 'report',
	embedUrl: undefined,
	tokenType: models.TokenType.Aad,
	accessToken: undefined,
	id: undefined
};

type TProps = {};

const REPORT_DROPDOWN_ITEMS = [
	{
		displayName: 'Bookmarks',
		value: 'bookmarks_toggle'
	},
	{
		displayName: 'Full Screen',
		value: 'full_screen_report'
	},
	{
		displayName: 'Print',
		value: 'print_report'
	},
	{
		displayName: 'Refresh',
		value: 'refresh'
	}
];

const Intelligence = (props: TProps) => {
	const {} = props;
	const [reportConfig, setReportConfig] = React.useState(defaultReportConfig);
	const [reportSettings] = React.useState(defaultReportSettings);
	const [accessToken, setAccessToken] = React.useState<string>('');
	const [workspaces, updateWorkspaces] = React.useState<any[]>([]);
	const [tokenExpiryListener, updateTokenExpiryListener] = React.useState<number>(0);
	const [currentReport, setCurrentReport] = React.useState<any>(undefined);

	React.useEffect(() => {
		document.addEventListener('visibilitychange', () => {
			// Check the access token when the tab is visible
			if (!document.hidden) {
				let tokenExpiryTime;

				if (accessToken) {
					const currentTime = Date.now();
					const expiration = jwt_decode<any>(accessToken).exp * 1000;
					tokenExpiryTime = expiration - currentTime;
				}

				if (tokenExpiryTime !== undefined && tokenExpiryTime <= 0) {
					getReportData();
				}
			}
		});
	}, [accessToken]);

	const getReportData = React.useCallback(() => {
		services.getReportData().then(data => {
			if (data.embedToken) {
				setAccessToken(data.embedToken);
			}

			if (data.workspaces) {
				let workspaces = _.sortBy(data.workspaces, ['name']).map(workspace => {
					return {
						...workspace,
						reports: _.sortBy(workspace.reports, ['name'])
					};
				});
				workspaces = _.reject(workspaces, w => !w.reports.length);

				if (!currentReport?.config?.id) {
					const initialReportData = workspaces[0]?.reports?.[workspaces[0]?.reports?.length - 1];
					setReportConfig({
						...reportConfig,
						embedUrl: initialReportData.embedUrl,
						id: initialReportData.id
					});
				}

				updateWorkspaces(workspaces);
			}
		});
	}, [currentReport]);

	React.useEffect(() => {
		let currentTime;
		let expiration;
		let safetyInterval;
		let tokenExpiryTime;

		if (accessToken) {
			// minutesToRefresh = 10; // set before two mins to expiry

			currentTime = Date.now();
			expiration = jwt_decode<any>(accessToken).exp * 1000;
			safetyInterval = 100;
			tokenExpiryTime = expiration - currentTime + safetyInterval;
		}

		if (tokenExpiryListener) {
			clearTimeout(tokenExpiryListener);
		}

		const timeout = setTimeout(() => {
			getReportData();
		}, tokenExpiryTime);
		updateTokenExpiryListener(timeout);

		if (currentReport && accessToken) {
			(async accessToken => {
				if (currentReport !== undefined) {
					await currentReport.setAccessToken(accessToken);
					currentReport.reload();
				}
			})(accessToken);
		}
	}, [accessToken, updateTokenExpiryListener, currentReport]);

	const getClassName = () => {
		if (isMobile) {
			return 'mobileMode';
		}

		return 'desktopMode';
	};

	const menuItemClickHandler = (info: any) => {
		switch (info.key) {
			case 'print_report':
				if (currentReport) {
					currentReport.print();
				}
				break;
			case 'full_screen_report':
				if (currentReport) {
					currentReport.fullscreen();
				}
				break;
			case 'bookmarks_toggle':
				if (currentReport) {
					currentReport.updateSettings({ bookmarksPaneEnabled: true });
				}
				break;
			case 'refresh':
				if (currentReport) {
					currentReport.refresh();
				}
				break;
			default:
				let report;
				for (const workspace of workspaces) {
					report = _.find(workspace.reports, { id: info.key });
					if (report) {
						break;
					}
				}

				if (report) {
					setReportConfig({
						...reportConfig,
						embedUrl: report.embedUrl,
						id: report.id
					});
				}
		}
	};

	const getEmbeddedReport = (report: any) => {
		setCurrentReport(report);
	};

	const eventHandlers = new Map([
		['loaded', function () {}],
		['rendered', function () {}],
		['error', function (event: any) {}]
	]);

	return (
		<div id="intelligence">
			<Sheet isInset>
				<SheetBody>
					<Stack isVertical gutter="small">
						<StackItem>
							<Stack>
								<StackItem isGrow>
									<Stack gutter="medium">
										{workspaces.map(workspace => {
											const dropdownOptions = _.map(workspace.reports, singleWorkspace => {
												return {
													displayName: singleWorkspace.name,
													value: singleWorkspace.id
												};
											});
											const selectedOption = _.filter(
												dropdownOptions,
												function (singleWorkspace) {
													return singleWorkspace.value == reportConfig.id;
												}
											);
											return (
												<StackItem>
													<Stack isVertical>
														<StackItem>
															<Label> {workspace.name}</Label>
														</StackItem>
														<StackItem>
															<DropDown
																placeholder="Select"
																options={dropdownOptions}
																updateHandler={(item: any) =>
																	menuItemClickHandler({ key: item.value })
																}
																selectedOption={selectedOption[0]}
															/>
														</StackItem>
													</Stack>
												</StackItem>
											);
										})}
									</Stack>
								</StackItem>
								<StackItem position="center">
									<DropDown
										placeholder="Report Options"
										options={REPORT_DROPDOWN_ITEMS}
										updateHandler={(item: any) => menuItemClickHandler({ key: item.value })}
									/>
								</StackItem>
							</Stack>
						</StackItem>
						<StackItem isStretch>
							<Divider />
						</StackItem>
						<StackItem isGrow>
							<PowerBIEmbed
								embedConfig={{
									...reportConfig,
									accessToken,
									settings: reportSettings
								}}
								eventHandlers={eventHandlers}
								cssClassName={'report-style-class'}
								getEmbeddedComponent={getEmbeddedReport}
							/>
						</StackItem>
					</Stack>
				</SheetBody>
			</Sheet>
		</div>
	);
};

export default Intelligence;
