// @ts-nocheck

import { createAction } from "@reduxjs/toolkit";

export const deleteClick = createAction(
  "deleteModal/delete",
  function prepare(tableId) {
    return {
      payload: {
        tableId,
      },
    };
  }
);

// toggle loader
export const toggleLoading = createAction(
  "deleteModal/toggle-loading",
  function prepare(status) {
    return {
      payload: {
        status: status,
      },
    };
  }
);

// openModal
export const toggleModalOpen = createAction(
  "deleteModal/toggle-modal-open",
  function prepare(value) {
    return {
      payload: {
        openModalName: value,
      },
    };
  }
);

// closeModal
export const toggleModalClose = createAction(
  "deleteModal/toggle-modal-close"
);
