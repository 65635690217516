// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';

export const AG_TABLE_IDS = [
	23, 1, 2, 7, 25, 44, 10, 27, 11, 12, 13, 14, 15, 16, 17, 18, 19, 24, 26, 20, 21, 22, 48, 49, 50, 51, 52, 53, 32, 37,
	35, 41, 38, 42, 31, 33, 46, 45, 28, 47, 30
];

export const initialState = {
	tableIds: AG_TABLE_IDS,
	isAgViewEnabled: false
};

const slice = createSlice({
	initialState,
	name: 'settingsModal',
	reducers: {
		//update Settings
		handleUpdateUserSettings(state, { payload: { isAgViewEnabled } }) {
			return {
				...state,
				isAgViewEnabled: isAgViewEnabled
			};
		}
	}
});

export default slice;

export const { handleUpdateUserSettings } = slice.actions;
