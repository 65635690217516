// @ts-nocheck
import BodyText from 'atoms/bodyText/bodyText';
import _ from 'lodash';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import { useSelector } from 'react-redux';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import { getSelectSkusData } from '../api';
import { SELECT_SKUS_TABLE_ID } from './constants';
import { getTableIdSelector } from '../actionBar/query';
import * as dynamicGridQuery from 'models/dynamicGrid/query';
import { REQUESTS } from 'utils/requests';

const SelectSkus = (props: any) => {
	const { selectedTab, parent } = props;

	const orders = useSelector(state => dynamicGridSelectors.getGridData(state, selectedTab));
	const ordersSearch = useSelector(state => dynamicGridSelectors.getGridFilters(state, selectedTab));
	const selectedOrders = useSelector(state => dynamicGridSelectors.getTableSelection(state, selectedTab));

	const getSelectedStores = () => {
		let selectedStores = [];
		for (let selectedOrder of selectedOrders?.selectedRowKeys) {
			let order = _.filter(orders, function (singleOrder) {
				return singleOrder[getTableIdSelector(selectedTab)] === selectedOrder;
			});
			selectedStores.push(order[0].StoreID);
		}
		return selectedStores.join(',');
	};

	const gridConfig = {
		enableSelection: true,
		enableSorting: true,
		selectionCallBack: () => {}
	};
	const userName = sessionStorage.getItem('userName') || 'None';

	const templateConfig = {
		gridConfig,
		isFilterEnabled: true,
		tableId: SELECT_SKUS_TABLE_ID,
		selectRowKey: 'SKUID',
		
		dataProviderOptions: {
			version: 2,
			...REQUESTS.POST.GET_DISTRIBUTE_SKUS,
			pathParams: { userName },
			type: 'POST',
			multiSelectFieldGroups: {
				OrderIDList: (selectedOrders?.selectedRowKeys || []).join(','),
				isSelectAll: selectedOrders?.isSelectAll,
				AppTableID: Number(selectedTab)
			},
			multiSelectFilterFieldGroups: {
				selectAllSearch: ordersSearch?.search ?? []
			},
			body: {
				orderIds: (selectedOrders?.selectedRowKeys || []).join(','),
				isSelectAll: selectedOrders?.isSelectAll,
				selectAllSearch: JSON.stringify({
					filter: dynamicGridQuery.createFiltersFromQueries(ordersSearch?.search)
				}),
				tableId: Number(selectedTab)
			}
		}
	};

	return (
		<Stack isVertical>
			<StackItem isGrow>
				<ThetaGridPaginated isStorageEnabled={false} parent={parent} config={templateConfig} />
			</StackItem>
		</Stack>
	);
};

export default SelectSkus;
