// @ts-nocheck
import Button from 'atoms/button/button';
import Loader from 'atoms/loader';
import _ from 'lodash';
import * as advertisingSelectors from 'models/advertisings/selector';
import * as adModalSelectors from './selector';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ThetaGrid from 'templates/thetaGrid';
import { getPricingData } from './api';
import { Tprops } from './types';
import { AD_MODAL_TABLE_IDS } from './constants';
import ModalBody from 'atoms/modal/modalBody';
import ModalActions from 'atoms/modal/modalActions';
import { createFiltersFromQueries } from 'models/dynamicGrid/query';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import { useEffect } from 'react';
import { createPricingData } from './sagaActions';
import { updatePricingDataId } from './reducer';
import { REQUESTS } from 'utils/requests';

const EnterPricing = (props: Tprops) => {
	const { hideModal, handleNextClick, goBack } = props;
	const [ref, handleUpdateRef] = React.useState(null);
	const name = 'adSkus';
	const enterPricingData = useSelector(state => adModalSelectors.getPricingData(state));
	const dispatch = useDispatch();
	const pricingDataId = useSelector(adModalSelectors.getPricingDataId);

	const gridConfig = {
		enableSelection: false,
		enableSorting: true
	};

	const selectedStores = useSelector(state =>
		dynamicGridSelectors.getTableSelection(state, AD_MODAL_TABLE_IDS.STORES)
	);
	const searchQueryStores = useSelector(state =>
		dynamicGridSelectors.getGridFilters(state, AD_MODAL_TABLE_IDS.STORES)
	);

	useEffect(() => {
		dispatch(createPricingData());
	}, []);

	const templateConfig = {
		gridConfig,
		isFilterEnabled: true,
		tableId: 33,
  
		dataProviderOptions: {
			type: 'POST',
			...REQUESTS.POST.GET_PRICING_PARAMS,
			pathParams: { AdTempID: pricingDataId },
			version: 2
		}
	};

	const renderLoader = () => {
		return (
			<StackItem isGrow isStretch align="center">
				<Loader />
			</StackItem>
		);
	};
	return (
		<>
			<ModalBody onDomRef={handleUpdateRef}>
				<Stack>
					{!pricingDataId ? renderLoader() : <ThetaGridPaginated parent={ref} config={templateConfig} />}
				</Stack>
			</ModalBody>
			<ModalActions>
				<Stack gutter="medium">
					<StackItem isGrow isStretch>
						<Stack align="left" gutter="small">
							<StackItem>
								<Button isSecondary name="RE-SELECT SKUS" handleClick={goBack} />
							</StackItem>
						</Stack>
					</StackItem>
					<StackItem>
						<Stack align="right" gutter="small">
							<StackItem>
								<Button isSecondary name="CANCEL" handleClick={hideModal} />
							</StackItem>
							<StackItem isStretch align="right">
								<Button name={'REVIEW AD'} handleClick={handleNextClick} />
							</StackItem>
						</Stack>
					</StackItem>
				</Stack>
			</ModalActions>
		</>
	);
};

export default EnterPricing;
