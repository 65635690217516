// @ts-nocheck
import * as React from 'react';
import classNames from 'classnames';
import '../sheet.scss';

interface Props {
	//   isFillHeight?: boolean;
	children: React.ReactNode;
}

function SheetBody(props: Props) {
	//   const { isFillHeight } = props;
	const classes: any = classNames(['sheet--body', { 'sheet--body-transparent': props.isTransparent }]);

	return <div className={classes}>{props.children}</div>;
}

export default SheetBody;
