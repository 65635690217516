// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { Alert, Checkbox, Collapse, Divider, Popover, Radio, Upload, message } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import Modal from 'atoms/modal/modal';
import StackItem from 'molecules/stackItem/stackItem';
import FileUploader from 'molecules/fileUploader';
import ModalBody from 'atoms/modal/modalBody';
import Stack from 'molecules/stack/stack';
import { getTableSelection } from 'models/dynamicGrid/selector';
import ModalHeader from 'atoms/modal/modalHeader';
import { toggleLoaderState } from 'models/loaders/reducer';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import Sheet from 'atoms/sheet/sheet';
import { PAGES, UPLOAD_DATA_CHANGE_TYPES, UPLOAD_TYPE } from './constants';
import * as sagaActions from './sagaActions';
import Dropdown from 'atoms/dropdown/dropdown';
import Heading from 'atoms/heading/heading';
import ModalActions from 'atoms/modal/modalActions';
import Button from 'atoms/button/button';
import { useDispatch, useSelector } from 'react-redux';
import { onUploadFileV2, revalidateUploadGrid, submitUploadFile, triggerFetchDownloadTemplate } from './sagaActions';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import { getActivePage } from './selectors';
import * as selectors from './selectors';

import { setActivePage } from './reducer';
import Icon from 'atoms/icons/icon';
import { isLoadingEnabled, isRequestComplete, isRequestPending } from 'models/loaders/selectors';
import Loader from 'atoms/loader';
import BodyText from 'atoms/bodyText/bodyText';
import ReactModal from 'atoms/modal/reactModal';
import { getComponentUserSettings, getERPStatus, getGridData, getGridMetadata } from 'models/dynamicGrid/selector';
import { fetchComponentUserSettings, handleBulkRowDelete, refreshGridData } from 'models/dynamicGrid/sagaActions';
import { REQUESTS } from 'utils/requests';
import Label from 'atoms/label/label';
import Scrollable from 'atoms/scrollable/scrollable';
import { REQUEST_STATUS } from 'models/loaders/constants';
import GridCsvUploadModal from './gridCsvUploadModal';
import _ from 'lodash';

const { Dragger } = Upload;

interface Props {
	visible: boolean;
	onCancel: () => void;
	onUpload: ({ file, params }: { files: UploadFile; params: any }) => void;
}



const GridCsvUpload: React.FC<Props> = (props) => {
	const [isGridUpload, setGridUpload] = useState(false);
	const dispatch = useDispatch();
	console.log("GridCsvUpload", props)
	useEffect(() => {
		if (!isGridUpload) {
			dispatch(setActivePage({ activePage: PAGES.UPLOAD }));
		}
		dispatch(sagaActions.triggerFetchUploadMetadata({ tableId: props?.parentTableId }));

	}, [dispatch, isGridUpload]);

	const onUploadComplete = React.useCallback(
	  () => {
		setGridUpload(false)
	  },
	  [],
	)
	


	return (
		<>
			<Icon isActive onClick={() => setGridUpload(true)} faAlias="faUpload"></Icon>
			{isGridUpload && (
				<GridCsvUploadModal  onUploadComplete={onUploadComplete} onClose={() => {
					setGridUpload(false)
				}} {...props} />
			)}
		</>
	);
};

export default GridCsvUpload;
