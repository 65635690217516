// @ts-nocheck
import _ from 'lodash';
import { fetchGridMetaData } from 'models/tableColumns/sagaActions';
import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { getDispatch } from 'reducers';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import * as tableColumnSelectors from 'models/tableColumns/selector';
import ThetaGridPaginatedInner from './thetaGridPaginated';
import ThetaGridPaginatedAg from './thetaGridPaginatedAg';
import { useSelector } from 'react-redux';
import Loader from 'atoms/loader';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import { isLoadingEnabled } from 'models/loaders/selectors';
import { fetchGridFiltersAsync, updateGridFilters, updateGridMeta } from 'models/dynamicGrid/sagaActions';
import { updateFilters } from 'models/dynamicGrid/reducer';
import { INITIAL_FILTER } from 'models/dynamicGrid/constants';
import { hasNetworkError } from 'models/subscriptions/selector';
import { REQUESTS } from 'utils/requests';
import { triggerSynchronousAgGridLoading } from '../../models/dynamicGrid/sagaActions';
import { isAppActive } from 'models/subscriptions/selector';

type TConfig = {
	gridConfig: any;
	isFilterEnabled: boolean;
	tableId: number;

	dataProviderOptions: {
		version: number;
		type: string;
		body: object;
		url: string;
		service: string;
		queryParams: object;
	};
	selectRowKey?: string;
};

type TProps = {
	parent?: any;
	config: TConfig;
	extendFilters?: React.FunctionComponent | HTMLElement | JSX.Element;
};

const ACTIONS = {
	DELETE: 'DELETE',
	EDIT: 'EDIT',
	SAVE: 'SAVE'
};

const ThetaGridPaginated = (props: TProps) => {
	const { isStorageEnabled = true } = props;
	const isNetworkError = useSelector(hasNetworkError);

	const isLoadingMeta = useSelector(state =>
		isLoadingEnabled(state, `agGridLoading/${props.config.tableId}`)
	);
	const isActive = useSelector(state => isAppActive(state));
	const dispatch = getDispatch();
	useEffect(() => {
		if (!isActive) {
			return;
		}
		dispatch(
			triggerSynchronousAgGridLoading({
				tableId: props.config.tableId
			}))
		// dispatch(fetchGridMetaData(props.config.tableId));
	}, [dispatch, isActive]);

	if (isLoadingMeta || isLoadingMeta === undefined) {
		return (
			<Stack isVertical>
				<StackItem isGrow isStretch align="center">
					<Loader />
				</StackItem>
			</Stack>
		);
	}
	const agProps = _.pick(props, ['config', "extendFilters", "parent", "isStorageEnabled", "offset", "rowIndex",
		"hasChildRows", "refreshTimestamp", "childCustomActions", "childCellRendererParams", "refreshChildGridData"])
	return <ThetaGridPaginatedAg key={props.config.tableId} {
		...agProps
	}
	/>;
};

export default ThetaGridPaginated;
