// @ts-nocheck
import BodyText from 'atoms/bodyText/bodyText';
import DatePicker from 'atoms/datePicker/datePicker';
import Modal from 'atoms/modal/modal';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import _ from 'lodash';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalActions from '../common/modalActions';
import ModalHeader from '../common/modalHeader';
import * as sagaActions from '../sagaActions';
import * as selectors from '../selectors';
import Loader from 'atoms/loader';
interface TProps {
	hideModal: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
	selectedTab: string;
}
const today = moment().format('MM-DD-YYYY');

const ModalContent = ({ changePODate, poDate }: any) => {
	return (
		<Stack isVertical gutter="medium">
			<StackItem>
				<BodyText>Changing the P.O. dates on selected orders.</BodyText>
			</StackItem>
			<StackItem iStretch>
				<Stack gutter="medium">
					<StackItem isStretch isGrow>
						<BodyText>P.O. Date</BodyText>
					</StackItem>
					<StackItem>
						<DatePicker
							name={'poDate'}
							min={today}
							max={moment().add(3, 'months').format('MM-DD-YYYY')}
							selectedDate={poDate || ''}
							handleClick={e => changePODate(e.target.value)}
						/>
					</StackItem>
				</Stack>
			</StackItem>
		</Stack>
	);
};

const ThetaUpdateOrderStatusModal = (props: TProps) => {
	const { hideModal, selectedTab } = props;
	const [poDate, changePODate] = React.useState('');
	const dispatch = useDispatch();

	const selectedOrders = useSelector(state => dynamicGridSelectors.getTableSelection(state, Number(selectedTab)));
	const orders = useSelector(state => dynamicGridSelectors.getGridData(state, Number(selectedTab)));

	const getOrderIds = () => {
		if (Number(selectedTab) == 30) {
			let filteredOrders = _.filter(orders, singleOrder =>
				selectedOrders?.selectedRowKeys?.includes(singleOrder.OrderKitID)
			);
			filteredOrders = filteredOrders.map(singleOrder => singleOrder.OrderID);
			return filteredOrders.join(',');
		} else {
			return selectedOrders?.selectedRowKeys?.join(',');
		}
	};

	const handleUpdateOrder = (e: any) => {
		const data = {
			orderIds: getOrderIds(),
			poDate: poDate
		};
		dispatch(sagaActions.updatePODate({ data, tableId: selectedTab }));
	};
	const isLoading = useSelector(state => selectors.getLoadingStatus(state));

	return (
		<Modal hideModal={hideModal} height={400}>
			<Sheet isInset>
				<SheetBody>
					<Stack isVertical gutter="small">
						<StackItem>
							<ModalHeader title={'Change PO Date'} />
						</StackItem>
						<StackItem isStretch isGrow>
							{isLoading ? (
								<Stack isVertical>
									<StackItem isGrow isStretch align="center">
										<Loader />
									</StackItem>
								</Stack>
							) : (
								<ModalContent
									selectedOrders={selectedOrders?.selectedRowKeys}
									poDate={poDate}
									changePODate={changePODate}
								/>
							)}
						</StackItem>
						<StackItem isStretch>
							<ModalActions
								secondaryButtonName={'Close'}
								primaryButtonName={'Update Orders'}
								primaryButtonClick={handleUpdateOrder}
								secondaryButtonClick={hideModal}
								getPrimaryDisabledStatus={() => !poDate}
								isLoading={isLoading}
							/>
						</StackItem>
					</Stack>
				</SheetBody>
			</Sheet>
		</Modal>
	);
};

export default ThetaUpdateOrderStatusModal;
