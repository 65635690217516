// @ts-nocheck
import Icon from 'atoms/icons/icon';
import Sheet from 'atoms/sheet/sheet';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import * as tableColumnSagaActions from 'models/tableColumns/sagaActions';
import * as tableColumnSelectors from 'models/tableColumns/selector';
import SideCar from 'molecules/sidecar/sidecar';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import CustomizeViewModal from 'organisms/CustomizeViewModal';
import ThetaMenu from 'organisms/thetaMenu/thetaMenu';
import UploadData from 'organisms/uploadData';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import * as constants from './constants';
import Breadcrumbs from 'atoms/breadcrumbs/breadcrumbs';
import ThetaDeleteModal from 'organisms/deleteModal/deleteModal';
import ExportGrid from 'organisms/exportGrid';
import { REQUESTS } from 'utils/requests';
import GridActionBar from 'atoms/GridActionBar';
import GridCsvUpload from 'organisms/uploadData/gridCsvUpload';
import { isAclUsersReadWriteEnabled, isNewUploadEnabled } from 'models/user/selector';
import Button from 'atoms/button/button';
import { triggerCreateRole, triggerToggleRoleState, triggerAddOperation, triggerRemoveOperation } from './sagaActions';
import ReactModal from 'atoms/modal/reactModal';
import ModalHeader from 'atoms/modal/modalHeader';
import ModalBody from 'atoms/modal/modalBody';
import { Checkbox, Input } from 'antd';
import ModalActions from 'atoms/modal/modalActions';
import { getAutoCompleteData } from 'models/autoComplete/selectors';
import { triggerFetchAutoComplete } from 'models/autoComplete/sagaActions';
import { triggerFetchAvailableWorkflows } from 'models/appSelector/sagaActions';
import { getAvailableWorkflows } from 'models/appSelector/selectors';
import { handleAddOperation, handleRemoveOperation } from './sagas';
import { triggerNotification } from 'organisms/notifications/sagaActions';

const Roles: React.FunctionComponent = props => {
    const tableId = constants.TABLE_ID.toString();
    const [openFilter, setOpenFilter] = React.useState<boolean>();
    const [isUploadEnabled, toggleUpload] = React.useState<boolean>(false);
    const [isDeleteModal, toggleDeleteModal] = React.useState(false);
    const gridMeta = useSelector(state => dynamicGridSelectors.getGridMetadata(state, constants.TABLE_ID));
    const [isCreateEnabled, toggleCreate] = React.useState(false);
    const [roleName, setRoleName] = React.useState('')
    const rolesList = useSelector(state => getAutoCompleteData(state, 'acl-roles'));
    const [role, setRole] = React.useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const hasWriteAccess = useSelector(isAclUsersReadWriteEnabled);
    const hasDeleteAccess = true;
    const hasSearchFilters = useSelector(state => dynamicGridSelectors.hasSearchFilters(state, constants.TABLE_ID));
    const workflows = useSelector(getAvailableWorkflows);
    const allOperations = workflows.reduce((acc, w) => _.uniqBy([...acc, ...w.Operations], 'OperationValue'), []);
    const userOperations = useSelector(state => getAutoCompleteData(state, 'acl-user-operations'));
    const isCustomizeViewModalEnabled = useSelector(state => tableColumnSelectors.isCustomizeViewModalEnabled(state));

    useEffect(() => {
    }, [allOperations, userOperations])

    React.useEffect(() => {
        if (openFilter === undefined) {
            setOpenFilter(hasSearchFilters);
        }
    }, [hasSearchFilters]);

    const fetchRolesList = (search) => {
        dispatch(
            triggerFetchAutoComplete({
                componentId: 'acl-roles',
                options: {
                    service: 'acl',
                    url: 'roles/list',
                    displayKey: 'RoleName',
                    selectionKey: 'RoleID',
                    body: {
                        IsActive: true,
                        search
                    },
                },


            }))
    }
    useEffect(() => {
        fetchRolesList()
        dispatch(triggerFetchAvailableWorkflows())
    }, []);


    useEffect(() => {
        if (rolesList?.length && !rolesList.find(r => r.RoleID === role?.roleId)) {
            setRole({ roleName: rolesList[0]?.RoleName, roleId: rolesList[0]?.RoleID })
        }
    }, [rolesList, role])


    const handleFilterClick = React.useCallback(() => {
        setOpenFilter(!openFilter);
    }, [openFilter]);

    const handleRefreshClick = React.useCallback(() => {
        dispatch(
            dynamicGridSagaActions.refreshGridData({
                tableId: constants.TABLE_ID,
            })
        );
    }, [tableId, dispatch]);

    const handleBackClick = React.useCallback(() => {
        navigate(`/masterData`);
    }, [navigate]);

    const handleResetTableColumns = React.useCallback(async () => {
        dispatch(tableColumnSagaActions.resetTableColumnsAg(constants.TABLE_ID));
    }, [dispatch, tableId]);

    const customizeViewHandler = React.useCallback((isCustomizeViewModalEnabled: boolean) => {
        dispatch(tableColumnSagaActions.setCustomizeViewModal(isCustomizeViewModalEnabled));
    }, []);

    const handleMenuItemClick = React.useCallback(
        (option: string) => {
            switch (option) {
                case constants.MENU_ITEMS_VALUES.CUSTOMIZE_VIEW:
                    return customizeViewHandler(true);
                case constants.MENU_ITEMS_VALUES.RESET_VIEW:
                    return handleResetTableColumns();
                case constants.MENU_ITEMS_VALUES.UPLOAD_DATA:
                    return toggleUpload(true);
                case constants.MENU_ITEMS_VALUES.DELETE:
                    return deleteItems();
                default:
                    return;
            }
        },
        [handleResetTableColumns]
    );

    const selectedItems = useSelector(state => dynamicGridSelectors.getTableSelection(state, constants.TABLE_ID));
    const getDisabledStatus = React.useCallback(
        (option: any) => {
            switch (option.value) {
                case constants.MENU_ITEMS_VALUES.CUSTOMIZE_VIEW:
                case constants.MENU_ITEMS_VALUES.RESET_VIEW:
                case constants.MENU_ITEMS_VALUES.UPLOAD_DATA:
                    return false;
                case constants.MENU_ITEMS_VALUES.DELETE:
                    return !selectedItems?.selectedRowKeys?.length;
                default:
                    return true;
            }
        },
        [selectedItems]
    );

    const hideCustomizeViewModal = React.useCallback(() => {
        customizeViewHandler(false);
    }, []);

    const uploadCloseClickHandler = React.useCallback(() => {
        toggleUpload(false);
    }, []);

    const handleBulkDeleteClick = () => {
        dispatch(dynamicGridSagaActions.handleBulkRowDelete({ tableId: constants.TABLE_ID }));
        toggleDeleteModal(false);
    };

    const deleteItems = () => {
        toggleDeleteModal(true);
    };

    const handleAddNewClick = () => {
        triggerToggleRoleState(true);
    };

    const onCreateRole = () => {
        dispatch(triggerCreateRole({
            roleName,
            componentId: 'acl-roles',
        }));
        setTimeout(() => {
            dispatch(
                dynamicGridSagaActions.refreshGridData({
                    tableId: constants.TABLE_ID,
                })
            );
        }, 2000)
        toggleCreate(false);
    }

    const onClose = () => {
        toggleCreate(false);
    }

    const onDeactivateRole = (row) => {
        try {
            dispatch(triggerToggleRoleState({ roleId: row.RoleID, componentId: 'acl-roles' }));
            setTimeout(() => {
                dispatch(
                    dynamicGridSagaActions.refreshGridData({
                        tableId: constants.TABLE_ID,
                    })
                );
            }, 2000)
        }
        catch (e) {
            console.log(e)
        }
    }


    const isNewUpload = useSelector(isNewUploadEnabled);

    const gridConfig = {
        enableSelection: true,
        enableSorting: true,
        enableMultiSort: true,
        // gridActions: (hasWriteAccess || hasDeleteAccess),
        customActions: (hasWriteAccess || hasDeleteAccess) && {
            width: 90,
            enabled: true,
            component: ({ row }) => {
                return (
                    <Stack gutter="small" align="center" position='center'>
                        <StackItem>
                            <Icon
                                isActive
                                type="secondary"
                                size="small"
                                disabled={row?.IsReadOnly}
                                onClick={() => onDeactivateRole(row)}
                                faAlias={'faTrash'}
                            ></Icon>
                        </StackItem>
                    </Stack>

                );
            }
        },
        selectionCallBack: () => { }
    };



    const handleModulePermission = async (params) => {
        const { colDef, data, } = params
        const queryName = colDef?.metaData?.AppColumnQueryName
        if (colDef?.metaData?.ColumnType === "checkbox") {
            const checked = data[queryName]
            const operationId = `${queryName.substring(2)}Id`;
            const payload = {
                roleId: data.RoleId,
                operations: [data[operationId]],
            };

            try {
                let response;
                if (checked) {
                    response = await handleRemoveOperation({ payload });
                    return response
                } else {
                    response = await handleAddOperation({ payload });
                    return response
                }
            } catch (error) {
                return error
            }
        }
    };

    const templateConfig = {
        gridConfig,
        isFilterEnabled: openFilter === undefined ? false : openFilter,
        tableId: constants.TABLE_ID,
        childGridDataProviderOptions: {
            ...REQUESTS.POST.GET_GRID_DETAILS_V2,
        },
        dataProviderOptions: {
            type: 'POST',
            ...REQUESTS.POST.GET_MONGO_GRID_DETAILS_V2,
            pathParams: { tableId: constants.TABLE_ID },
            version: 2
        },
        selectRowKey: 'ItemID',
        childCellRendererParams: { sortModal: [{ colId: "WorkflowDisplayName", sort: "asc" }] },
        // childCustomActions: (params) => {
        //     const { colDef, data, } = params
        //     const queryName = colDef?.metaData?.AppColumnQueryName
        //     const checked = data[queryName]
        //     const onCreateRole = () => handleModulePermission(data, queryName, colDef)
        //     return colDef?.metaData?.ColumnType === "checkbox" ?
        //         <Checkbox checked={checked}
        //             onClick={onCreateRole} /> :
        //         data.WorkflowDisplayName
        // },
        childCustomActions: handleModulePermission,
    };

    return (
        <>
            <Stack isVertical>
                <StackItem>
                    <GridActionBar>
                        <Stack gutter="medium">
                            <StackItem isGrow>
                                <Breadcrumbs crumbs={props.breadcrumbs} />
                            </StackItem>

                            <StackItem>
                                {hasWriteAccess && <Button handleClick={() => toggleCreate(true)}> Create New Role </Button>}
                            </StackItem>

                            {isNewUpload && gridMeta?.UploadTableID && <StackItem>
                                <GridCsvUpload uploadTableId={gridMeta?.UploadTableID} parentTableId={tableId} />
                            </StackItem>}
                            <StackItem>
                                <Icon isActive type="secondary" faAlias="faRecycle" onClick={handleRefreshClick} />
                            </StackItem>


                            {/* <StackItem>
                                <ExportGrid tableId={13} />
                            </StackItem> */}
                            <StackItem>
                                <ThetaMenu
                                    type="3Dots"
                                    options={
                                        hasWriteAccess ? constants.MENU_ITEMS : [constants.MENU_ITEMS[0]]
                                    }
                                    handleItemClick={handleMenuItemClick}
                                    isDisabled={getDisabledStatus}
                                />
                            </StackItem>
                        </Stack>
                    </GridActionBar>
                </StackItem>
                <StackItem isStretch isGrow>
                    <Sheet isPage>
                        <Stack isVertical gutter="medium">

                            <StackItem isGrow isStretch>
                                <ThetaGridPaginated config={templateConfig} />
                            </StackItem>
                        </Stack>
                    </Sheet>
                </StackItem>
            </Stack>


            {isCreateEnabled && <ReactModal>
                <ModalHeader>Create Role</ModalHeader>
                <ModalBody>
                    <Stack isVertical gutter="small">
                        <StackItem>
                            <Input placeholder='Role Name' type="text" onChange={e => setRoleName(e.target.value)} />
                        </StackItem>
                    </Stack>
                </ModalBody>
                <ModalActions>
                    <Stack align="right" gutter="small">
                        <StackItem>
                            <Button type='secondary' handleClick={onClose} > Cancel</Button>
                        </StackItem>
                        <StackItem>
                            <Button disabled={!roleName} handleClick={onCreateRole} > Create Role</Button>
                        </StackItem>
                    </Stack>
                </ModalActions>
            </ReactModal>}

            {isCustomizeViewModalEnabled && (
                <CustomizeViewModal hideModal={hideCustomizeViewModal} tableId={constants.TABLE_ID} />
            )}
            {isUploadEnabled && (
                <SideCar isActive={isUploadEnabled} handleOnClose={uploadCloseClickHandler}>
                    <UploadData tableId={tableId} />
                </SideCar>
            )}
            {isDeleteModal && (
                <ThetaDeleteModal
                    hideModal={() => {
                        toggleDeleteModal(false);
                    }}
                    modalTitle={'ITEMS'}
                    title={` (${selectedItems?.isSelectAll ? 'All' : selectedItems?.selectedRowKeys?.length
                        }) selected  item(s)`}
                    handleDeleteClick={handleBulkDeleteClick}
                    isLocked={true}
                />
            )}
        </>
    );
};
export default Roles;
