// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const triggerCreateUser = createAction('users/triggerCreate', ({ userName, userEmail, componentId, roles }) => ({
	payload: {
		userName,
		userEmail,
		componentId,
		roles
	}
}));


export const triggerAddUserRole = createAction('users/addRole', ({ userId, roles, isForced }) => ({
	payload: {
		userId, roles, isForced
	}
}));


export const triggerRemoveUserRole = createAction('users/removeRoles', ({ userId, roles }) => ({
	payload: {
		userId, roles
	}
}));

export const triggerDeactivateUser = createAction('users/deactivateUser', ({ userId }) => ({
	payload: {
		userId
	}
}));


export const triggerActivateUser = createAction('users/activateUser', ({ userId }) => ({
	payload: {
		userId
	}
}));
