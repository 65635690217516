// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const fetchColumns = createAction(
  'customize-modal/fetch-columns',
  function prepare(tableId) {
    return {
      payload: {
        tableId,
      },
    };
  }
);

export const fetchSelecetdColumns = createAction(
  'customize-modal/fetch-selecetd-columns',
  function prepare(tableId) {
    return {
      payload: {
        tableId,
      },
    };
  }
);
export const updateSelectedColumns = createAction(
  'orders/update-selected-columns',
  function prepare(tableId, selectedColumns) {
    return {
      payload: {
        selectedColumns,
        tableId,
      },
    };
  }
);
export const updateColumns = createAction(
  'orders/update-columns',
  function prepare(tableId, columns) {
    return {
      payload: {
        columns,
        tableId,
      },
    };
  }
);
export const updateTableColumns = createAction(
  'orders/update-table-columns',
  function prepare(selectedTab, selectedColumns) {
    return {
      payload: {
        selectedTab,
        selectedColumns,
      },
    };
  }
);
