// @ts-nocheck
import * as React from 'react';
import './sidebar.css';
import classNames from 'classnames';

interface Props extends React.PropsWithChildren<any> {
  isExpanded?: boolean;
  isActive: boolean;
  item?: any;
	onClick: React.MouseEventHandler;
	isChildren?: boolean
}

const SidebarItem: React.FunctionComponent<Props> = (props: Props) => {
  const { children, isActive, onClick, isChildren } = props;
  const classes: any = classNames([
    'sidebar-item',
    { 'sidebar-item--active': isActive },
    { 'sidebar-item--child': isChildren },
  ]);

  return (
    <div onClick={onClick} className={classes}>
      {children}
    </div>
  );
};

// SidebarItem.defaultProps = defaultProps;

export default SidebarItem;
