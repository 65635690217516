// @ts-nocheck

import { formatPercentage, formatMoney, formatThousands } from 'utils/utilities';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as React from 'react';
import Label from 'atoms/label/label';

const OrderSummary = ({ dataLoading, info }) => {
	return (
		<Stack gutter="medium" align='center'>
			<StackItem>
				<Stack gutter="small" position='center'>
					<StackItem>
						<Label isHighlight>Shipping:</Label>
					</StackItem>
					<StackItem>{!info?.ShipQty ? 0.0 : formatThousands(info?.ShipQty)} Racks</StackItem>
				</Stack>
			</StackItem>
			<StackItem>
				<Stack gutter="small" position='center'>
					<StackItem>
						<Label isHighlight>Total Dollars:</Label>
					</StackItem>
					<StackItem>{!info?.OrderCost ? 0 : `${formatMoney(info?.OrderCost)}`}</StackItem>
				</Stack>
			</StackItem>
			<StackItem>
				<Stack gutter="small" position='center'>
					<StackItem>
						<Label isHighlight>Total Stores:</Label>
					</StackItem>
					<StackItem>{!info?.StoreCount ? 0 : info?.StoreCount}</StackItem>
				</Stack>
			</StackItem>

			<StackItem position='center'>
				<Stack gutter="small">
					<StackItem >
						<Label isHighlight>Order Total:</Label>
					</StackItem>
					<StackItem>{formatThousands(info?.OrderQty ?? 0)}</StackItem>
				</Stack>
			</StackItem>
		</Stack>
	);
};
export default OrderSummary;
