// @ts-nocheck
import Button from 'atoms/button/button';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import ModalHeader from 'atoms/modal/modalHeader';
import ReactModal from 'atoms/modal/reactModal';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import AutoCompleteSelector from 'organisms/autoCompleteSelector/autoCompleteSelector';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { ACL_TAGS_COMPONENTS } from '../../constants';
import { triggerCreateTag, triggerUpdateToggleTagState } from '../../sagaActions';
import Heading from 'atoms/heading/heading';
import { REQUESTS } from 'utils/requests';

const TagSelector = ({ onClose }) => {

    const dispatch = useDispatch();

    const onDeleteActiveTag = (tag) => {
        dispatch(triggerUpdateToggleTagState({
            tagId: tag.metaData.TagID,
            state: 'Inactive'
        }))
    }


    const onDeleteInactiveTag = (tag) => {
        dispatch(triggerUpdateToggleTagState({
            tagId: tag.metaData.TagID,
            state: 'Deleted'
        }))
    }


    const onSelectInactiveTag = (tag) => {
        dispatch(triggerUpdateToggleTagState({
            tagId: tag.metaData.TagID,
            state: 'Active'
        }))
    }

    const onAddTag = (tagName) => {
        dispatch(triggerCreateTag({
            tagName
        }))
    }
    const activeTagOptions = React.useMemo(() => ({
       ...REQUESTS.GET.GET_TAGS,
        body: {
            isActive: true,
        },
        displayKey: 'Tag',
        selectionKey: 'TagID'
    }), [])

    const inActiveTagOptions = React.useMemo(() => ({
        ...REQUESTS.GET.GET_TAGS,
        body: {
            isActive: false,
        },
        displayKey: 'Tag',
        selectionKey: 'TagID'
    }), [])



    return (
        <>
            <ReactModal height={700} width={700}>
                <ModalHeader>
                    Select Group Hub Tags
                </ModalHeader>
                <ModalBody>
                    <Stack divisions={2}>
                        <StackItem span={1}>
                            <Stack isVertical gutter="tiny">
                                <StackItem>
                                    <Heading type="tertiary">
                                        Inactive Tags
                                    </Heading>
                                </StackItem>
                                <StackItem isGrow>
                                    <AutoCompleteSelector
                                        key="tags-unused"
                                        componentId={ACL_TAGS_COMPONENTS.UNUSED}
                                        placeholder="Search Tags"
                                        options={inActiveTagOptions}
                                        actions={['add', 'delete']}
                                        onAdd={
                                            onSelectInactiveTag
                                        }
                                        onSubmit={
                                            onAddTag
                                        }
                                        onRemove={
                                            onDeleteInactiveTag
                                        }
                                    />
                                </StackItem>

                            </Stack>
                        </StackItem>
                        <StackItem span={1}>
                            <Stack isVertical  gutter="tiny">
                                <StackItem>
                                    <Heading type="tertiary">
                                        Active Tags
                                    </Heading>
                                </StackItem>
                                <StackItem isGrow>
                                    <AutoCompleteSelector
                                        key="user-tags"
                                        componentId={ACL_TAGS_COMPONENTS.ACTIVE}
                                        placeholder="Search User Tags"
                                        options={activeTagOptions}
                                        actions={['delete']}
                                        onRemove={
                                            onDeleteActiveTag
                                        }
                                    />
                                </StackItem>
                            </Stack>
                        </StackItem>
                    </Stack>

                </ModalBody>
                <ModalActions>
                    <Stack align='right'>
                        <StackItem isGrow position='end'>
                            <Button handleClick={onClose}>Close</Button>
                        </StackItem>
                    </Stack>
                </ModalActions>
            </ReactModal>
        </>
    );
};

export default TagSelector;
