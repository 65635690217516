// @ts-nocheck
import Button from 'atoms/button/button';
import Divider from 'atoms/divider/divider';
import DropDown from 'atoms/dropdown/dropdown';
import Heading from 'atoms/heading/heading';
import Modal from 'atoms/modal/modal';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import TextInput from 'atoms/TextInput/textInput';
import _ from 'lodash';
import * as tableColumnSagaActions from 'models/tableColumns/sagaActions';
import * as tableColumnSelectors from 'models/tableColumns/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DROPDOWN_OPTIONS } from './constants';
import CustomizeColumnGroup from './customizeColumnGroup';
import './customizeVIewModal.css';
import * as query from './query';
import Loader from 'atoms/loader';

type TProps = {
	tableId: number;
	hideModal: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
};

const CustomizeViewModal = (props: TProps) => {
	const { hideModal, tableId } = props;
	const [columns, updateColumns] = React.useState<any>(undefined);
	const [searchInput, updateSearchInput] = React.useState('');
	const [dropdown, setDropdown] = React.useState(DROPDOWN_OPTIONS[2]);
	const [activeGroup, updateGroup] = React.useState<string>('');
	const [isUpdating, setUpdating] = React.useState<boolean>(false);

	const dispatch = useDispatch();

	const sourceColumns = useSelector(state => tableColumnSelectors.getTableColumnsByGroups(state, tableId));

	React.useEffect(() => {
		dispatch(tableColumnSagaActions.fetchTableColumns(tableId));
	}, [dispatch, tableId]);

	React.useEffect(() => {
		if (!columns) {
			updateColumns(sourceColumns);
			updateGroup(sourceColumns[0]?.name);
		}
	}, [sourceColumns]);

	const searchInputHandler = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>): void => {
			updateSearchInput(e.target.value);
		},
		[updateSearchInput, searchInput]
	);

	const toggleColumn = React.useCallback(
		(columnId: number) => {
			updateColumns(query.toggleColumn(columns, columnId));
		},
		[columns]
	);

	const selectHandler = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			toggleColumn(parseInt(e.target.value, 10));
		},
		[columns]
	);

	const updateClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
		setUpdating(true);
		dispatch(tableColumnSagaActions.updateTableColumns(tableId, query.getSelectedColumnIds(columns)));
	};

	const handleDropdownChange = (item: any) => {
		setDropdown(item);
	};

	const columnsForRendering = query.getColumnsForRendering(columns, dropdown, searchInput);

	return (
		<Modal hideModal={hideModal} height={500} width={680}>
			<Sheet isInset>
				<SheetBody>
					<Stack isVertical gutter="small">
						<StackItem isStretch>
							<Heading> Customize Table View </Heading>
						</StackItem>
						{isUpdating ? (
							<StackItem isStretch isGrow>
								<Loader />
							</StackItem>
						) : (
							<React.Fragment>
								<StackItem isStretch>
									<Divider />
								</StackItem>
								<StackItem>
									<TextInput
										size="medium"
										value={searchInput}
										onChange={searchInputHandler}
										placeholder={'Search Menu Item'}
									/>
								</StackItem>
								<StackItem>
									<DropDown
										placeholder="Select"
										options={DROPDOWN_OPTIONS}
										updateHandler={handleDropdownChange}
										selectedOption={dropdown}
									/>
								</StackItem>
								<StackItem isStretch isGrow>
									<Sheet>
										<SheetBody>
											<div className="scrollable" style={{ height: '280px' }}>
												{columns && (
													<Stack isVertical gutter="tiny">
														{_.map(columnsForRendering, group =>
															group.columns.length ? (
																<StackItem isStretch>
																	<CustomizeColumnGroup
																		name={group.name}
																		columns={group.columns}
																		selectHandler={selectHandler}
																		key={group.name}
																		isActive={activeGroup === group.name}
																		setActiveGroup={updateGroup}
																	/>
																</StackItem>
															) : (
																<React.Fragment key={group.name} />
															)
														)}
													</Stack>
												)}
											</div>
										</SheetBody>
									</Sheet>
								</StackItem>
							</React.Fragment>
						)}
						<StackItem isStretch>
							<Stack align="right" gutter="small">
								<StackItem>
									<Button isSecondary name="CANCEL" handleClick={hideModal} />
								</StackItem>
								<StackItem isStretch align="right">
									<Button name={'UPDATE TABLE'} handleClick={updateClickHandler} />
								</StackItem>
							</Stack>
						</StackItem>
					</Stack>
				</SheetBody>
			</Sheet>
		</Modal>
	);
};

export default CustomizeViewModal;
