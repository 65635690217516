// @ts-nocheck
import _ from 'lodash';
import { criteria } from 'models/dynamicGrid/constants';

export const OrderStatusID = 'OrderStatusID';

export const getStatusQuery = (value: any, tableId: any) => {
	let query = {
		criteria: _.cloneDeep(criteria),
		query: value
	};
	if (Number(tableId) == 30) {
		// TODO: Critical
		query.criteria.queryName = 'OrderStatusID';
	}
	return query;
};
