// @ts-nocheck

import { getDispatch, getState } from 'reducers';
import { takeEvery } from 'redux-saga/effects';
import { loadMultiSelectOptions } from './sagaActions';
import { setHasMore, setMoreOptions } from './reducer';
import { getAPIDataV2, getAPIDataV3, postAPIDataV3 } from 'utils/api';
import { toggleLoader } from 'models/loaders/sagaActions';
import { getGridMetadata } from 'models/dynamicGrid/selector';
import { createFiltersFromQueries } from 'models/dynamicGrid/query';

async function loadOptionsSaga(action) {
	const dispatch = getDispatch();
	const { componentId, page, pageSize, isAppend, selectionProviderOptions, columnName, columnLabelName, search } = action.payload;
	try {



		dispatch(
			toggleLoader({
				component: 'multiselect/loadOptions/' + componentId,
				isLoading: true
			})
		);
		const options = await fetchOptions({ page, pageSize, selectionProviderOptions, columnName, columnLabelName, search });
		dispatch(
			setMoreOptions({
				componentId,
				options,
				isAppend
			})
		);
		if (options?.length < pageSize) {
			dispatch(setHasMore({ componentId, hasMore: false }));
		}
	} catch (error) {
		// Handle error
	} finally {
		const { componentId } = action.payload;
	}
	dispatch(
		toggleLoader({
			component: 'multiselect/loadOptions/' + componentId,
			isLoading: false
		})
	);
}

export async function fetchOptions({ page, pageSize, isAppend, selectionProviderOptions, columnName, columnLabelName, search }) {
	let requestFunction;
	switch (selectionProviderOptions?.type) {
		case 'POST':
			requestFunction = postAPIDataV3;
			break;
		case 'GET':
		default:
			requestFunction = getAPIDataV3;
			break;
	}
	const gridMetaData = getGridMetadata(getState(), selectionProviderOptions.tableId);
	const filterFieldGroups = {};
	try {
		_.forEach(_.keys(gridMetaData?.dataProviderOptions?.multiSelectFilterFieldGroups), key => {
			if (gridMetaData?.dataProviderOptions?.multiSelectFilterFieldGroups[key]) {
				filterFieldGroups[key] = JSON.stringify({
					filter: createFiltersFromQueries(gridMetaData?.dataProviderOptions?.multiSelectFilterFieldGroups[key])
				});
			}
			return undefined;
		});
	} catch (e) {
		console.log(e);
	}

	const options = await requestFunction({
		service: selectionProviderOptions?.service,
		url: selectionProviderOptions.url,
		pathParams: selectionProviderOptions.pathParams,
		[selectionProviderOptions?.type === 'GET' ? 'queryParams' : 'body']: {
			...(selectionProviderOptions?.body ?? {}),
			pageNumber: page,
			itemsPerPage: pageSize,
			isColumnFilter: true,
			tableId: selectionProviderOptions?.tableId,
			columnName,
			search,
			fieldGroups: gridMetaData?.dataProviderOptions?.multiSelectFieldGroups ? {
				...gridMetaData?.dataProviderOptions?.multiSelectFieldGroups
			} : null,
			filterFieldGroups: Object.keys(filterFieldGroups)?.length ? filterFieldGroups : null
		}
	}).then(response =>
		response?.data?.data?.map((data) => ({
			label: data[columnLabelName] ?? data?.GroupName,
			total: data?.TotalRecordCount ?? response?.data?.data?.length,
			value: data[columnName] ?? data?.GroupName
		}))
	);

	return Promise.resolve(options);
}

export default function* rootSaga() {
	return [yield takeEvery(loadMultiSelectOptions, loadOptionsSaga)];
}
