// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';
import * as query from './query';
//get --> fetch
//post --> trigger /

export const updateModalToggle = createAction(
  query.createActionName('open-group-modal'),
  isOpen => ({
    payload: { isOpen },
  })
);

export const updateGroupData = createAction(
  query.createActionName('update-group-data'),
  data => ({
    payload: { data },
  })
);

export const editGroupData = createAction(
  query.createActionName('edit-group-data'),
  data => ({
    payload: { data },
  })
);

export const cleanUp = createAction('group-clean-up');

export const fetchCustomers = createAction(
  query.createActionName('fetch-group-customers')
);

export const editData = createAction(
  query.createActionName('edit-group-modal-data'),
  data => ({
    payload: { data },
  })
);

export const saveData = createAction(
  query.createActionName('save-group-modal-data'),
  data => ({
    payload: { data },
  })
);
