// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const triggerAddAccessLevel = createAction('rowAccess/triggerCreate', ({ 	selectedEntities,
	accessLevel,
	userId}) => ({
	payload: {
		selectedEntities,
				accessLevel,
				userId
	}
}));


export const triggerAddUserRole = createAction('users/addRole', ({ userId, roles, isForced }) => ({
	payload: {
		userId, roles, isForced
	}
}));


export const triggerRemoveUserRole = createAction('users/removeRoles', ({ userId, roles }) => ({
	payload: {
		userId, roles
	}
}));

export const triggeRemoveRowAccess = createAction('rowAccess/removeAccess', ({ userId,selectedEntities }) => ({
	payload: {
    userId,
		selectedEntities
	}
}));
