// @ts-nocheck
import qs from 'qs';
import { put, takeEvery } from 'redux-saga/effects';
import { getAPIData, getAPIDataV2, deleteAPIDataV2, putAPIDataV2, postAPIDataV3, getAPIDataV3 } from 'utils/api';
import * as slice from './reducer';
import * as sagaActions from './sagaActions';
import * as constants from './constants';
import { getItemFromLocalStorage, setItemInLocalStorage } from 'utils/storage';
import { triggerNotification } from 'organisms/notifications/sagaActions';
import { REQUESTS } from 'utils/requests';
import { getWebAppSettings } from 'models/user/selector';
import { getState } from 'reducers';

function* handleDefaultStoreSchedulesFetch() {
	yield put(
		slice.toggleLoader({
			loaderComponent: constants.LOADER_COMPONENTS.DEFAULT_ORDERS,
			isLoading: true
		})
	);
	const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

	console.log(getWebAppSettings(getState()))

	console.log(isView)

	const { data, isError, error } = yield postAPIDataV3({
		...REQUESTS.POST.GET_AVAILABILITY_INFO, body: {
			isView
		}
	})
		.then(response => ({
			data: response?.data
		}))
		.catch(error => ({ error }));

	if (isError) {
		yield put(
			triggerNotification({ message: 'An error occurred while fetching availability, please try again later' })
		);
		//   return;
	}
	yield put(slice.updateInventoryData({ data: data || {} }));

	yield put(
		slice.toggleLoader({
			loaderComponent: constants.LOADER_COMPONENTS.DEFAULT_ORDERS,
			isLoading: false
		})
	);
}

function* updateStatusID({ payload }: any) {
	const { statusID } = payload;

	setItemInLocalStorage('statusID', statusID);

	yield put(slice.updateStatusID({ statusID }));
}

function* updateIntervalID({ payload }: any) {
	const { statusIntervalID } = payload;

	yield put(slice.updateIntervalID({ statusIntervalID }));
}

function* loadPage() {
	//   yield put(triggerNotification({info: 'as', type: '1'}))
}

export const checkStatus = async (statusID: any) => {
	const { data } = await getAPIDataV3({
		...REQUESTS.GET.GET_PROCESS_STATUS,
		pathParams: { statusId: statusID },
		queryParams: { refresh: new Date().getTime().toString() },
	});
	return data?.data?.CurrentStatus?.toLowerCase();
};

function* checkOrderStatus({ payload: { statusID } }) {
	if (!statusID) {
		return;
	}
	let status = yield checkStatus(statusID);
	if (status === 'complete') {
		yield updateStatusID({ payload: { statusID: null } });
	}
}

export default function* rootSaga() {
	return [
		yield takeEvery(sagaActions.loadPage, loadPage),
		yield takeEvery(sagaActions.getInventoryData, handleDefaultStoreSchedulesFetch),
		yield takeEvery(sagaActions.updateStatusID, updateStatusID),
		yield takeEvery(sagaActions.updateIntervalID, updateIntervalID),
		yield takeEvery(sagaActions.checkOrderStatus, checkOrderStatus)
	];
}
