// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import { STORE_PREFIX } from './constants';

export const initialState = {

};

const slice = createSlice({
  initialState,
  name: STORE_PREFIX,
  reducers: {
   
  },
});

export default slice;

export const {setIsStoresEnabled, setIsAttributesEnabled } = slice.actions;
