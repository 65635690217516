// @ts-nocheck
import * as React from 'react';
import './card.scss';
import classNames from 'classnames';

interface Props {
	isFillHeight?: boolean;
	isDescription?: boolean;
	children: React.ReactNode;
}

function Card(props: Props) {
	const { isFillHeight, isDescription, isDefault, isBordered, height, className, width, maxWidth } = props;
	const classes: any = classNames([
		'card',
		{
			[`card--fill`]: isFillHeight,
			[`card--description`]: isDescription,
			['card--default']: isDefault,
			['card--bordered']: isBordered
		},
		className
	]);
	return (
		<div style={{ height: `${height}px`, minWidth: `${width}px`, maxWidth:`${maxWidth}px`  }} className={classes}>
			{props.children}
		</div>
	);
}

export default Card;
