// @ts-nocheck
import { IToolPanel } from 'ag-grid-community';
import Button from 'atoms/button/button';
import Heading from 'atoms/heading/heading';
import Label from 'atoms/label/label';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import ModalHeader from 'atoms/modal/modalHeader';
import ReactModal from 'atoms/modal/reactModal';
import TextInput from 'atoms/TextInput/textInput';
import { getDispatch } from 'reducers';
import { applyBookmark, saveBookmark } from 'models/bookmarks/sagaActions';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React, { useEffect, useMemo, useState } from 'react';
import { triggerAddAccessLevel, triggerAddUserRole, triggerCreateUser } from '../sagaActions';
import TagSelector from 'atoms/tagSelector';
import { getAutoCompleteData } from 'models/autoComplete/selectors';
import { useSelector } from 'react-redux';
import { triggerFetchAutoComplete } from 'models/autoComplete/sagaActions';
import { Select } from 'antd';
import DropdownInner from 'atoms/dropdown/dropdown';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import * as constants from './../constants';

interface CustomToolPanelProps extends IToolPanel { }

export const CreateRowAccess: React.FC<CustomToolPanelProps> = props => {
	const { tableId, selectedBookmark, onHide, rowAccessInfo, userInfo } = props;
	const [user, setUser] = useState({
		value: rowAccessInfo?.UserID,
		label: rowAccessInfo?.UserEmail
	});
	const [accessLevel, setAccessLevel] = useState({
		value: rowAccessInfo?.AccessLevelID,
		label: rowAccessInfo?.AccessLevelName
	});
	const [selectedEntities, setSelectedEntities] = useState(rowAccessInfo?.AccessLevelEntities?.map(a => a?.AccessLevelEntityID))
	const [searchText, setSearchText] = useState('');

	const dispatch = getDispatch();
	const usersList = useSelector(state => getAutoCompleteData(state, 'acl-all-users'))
	const accessLevels = useSelector(state => getAutoCompleteData(state, 'acl-all-accessLevels'))
	const accessLevelEntities = useSelector(state => getAutoCompleteData(state, 'acl-all-accessLevelEntities'))

	console.log(rowAccessInfo, accessLevel)

	useEffect(() => {
		dispatch(
			triggerFetchAutoComplete({
				componentId: 'acl-all-users',
				options: {
					service: 'acl',
					url: 'users/list',
				},
				params: { fieldGroups: ['UserEmail'] }

			}));

		dispatch(
			triggerFetchAutoComplete({
				componentId: 'acl-all-accessLevels',
				options: {
					service: 'acl',
					url: 'accessLevel/list',
				},
				params: { fieldGroups: ['UserEmail'] }

			}));
	}, []);

	useEffect(() => {
		if (accessLevel?.value) {
			dispatch(
				triggerFetchAutoComplete({
					componentId: 'acl-all-accessLevelEntities',
					options: {
						service: 'acl',
						url: 'accessLevel/entity/list',
					},
					params: { AccessLevelID: accessLevel?.value }
				}));
		}

	}, [accessLevel, dispatch])

	const handleCreate = () => {
		dispatch(triggerAddAccessLevel({
			selectedEntities,
			accessLevel: accessLevel.value,
			userId: user.value,
		}))
		setTimeout(() => {
			dispatch(
				dynamicGridSagaActions.refreshGridData({
					tableId: constants.TABLE_ID,
				})
			);
		}, 2000)

		onHide();
	};

	const userOptions = useMemo(() => usersList?.filter(u => u?.UserEmail?.toLowerCase().match(searchText?.toLowerCase()) || !searchText).map(u => ({
		displayName: u.UserEmail,
		value: u.UserID
	})), [usersList, searchText]);

	console.log("accesslevel", accessLevel)
	console.log("user", accessLevel)


	return (
		<>
			<ReactModal hideModal={onHide} height={400} width={400}>
				<ModalHeader>
					<Heading type="secondary">Create Row Access Controls</Heading>
				</ModalHeader>
				<ModalBody>
					<Stack isVertical gutter="small">
						<StackItem>
							<Label>User Email:</Label>
						</StackItem>
						<StackItem isStretch>
							<DropdownInner disabled={Boolean(rowAccessInfo?.UserID)}
								key={'options'}
								selectedOption={user}
								options={userOptions}
								isFullWidth
								updateHandler={user => {
									console.log(user)
									setUser(user)
								}}
								isAutocomplete={true}
							>
							</DropdownInner>

						</StackItem>

						<StackItem>
							<Label>Access Level:</Label>
						</StackItem>
						<StackItem isStretch>
							<DropdownInner disabled={Boolean(rowAccessInfo?.AccessLevelID)}
								key={'options'}
								selectedOption={accessLevel}
								options={accessLevels?.map(u => ({
									displayName: u.AccessLevelDisplayName,
									value: u.AccessLevelID
								}))}
								isFullWidth
								updateHandler={level => {
									setAccessLevel(level)
								}}
								isAutocomplete={true}
							>
							</DropdownInner>

						</StackItem>
						<StackItem>
							<Label>Access:</Label>
						</StackItem>
						<StackItem isStretch>
							<TagSelector onChange={entities => setSelectedEntities(entities)}
								defaultValues={selectedEntities} options={
									accessLevelEntities?.map(r => ({
										value: r.AccessLevelEntityID,
										label: r.DisplayValue
									}))
								} />
						</StackItem>
					</Stack>
				</ModalBody>
				<ModalActions>
					<Stack align="right" gutter="small">
						<StackItem>
							<Button isSecondary name="CANCEL" handleClick={onHide} />
						</StackItem>

						<StackItem isStretch align="right">
							<Button
								disabled={!user || !selectedEntities?.length || !accessLevel}
								name={rowAccessInfo?.AccessLevelID ? "UPDATE" : "CREATE"}
								handleClick={handleCreate}
							/>
						</StackItem>
					</Stack>
				</ModalActions>
			</ReactModal>
		</>
	);
};
