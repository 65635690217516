// @ts-nocheck
import * as constants from './constants';
import _ from 'lodash';
import { TFilter } from './types';
import { operators } from 'organisms/searchFilter/constants';
import { getWebAppSettings } from 'models/user/selector';
import { getState } from 'reducers';

const filterUsingAND = (data: any[], filters: any[]): any[] => {
	return _.filter(data, item => {
		for (const filter of filters) {
			if (item[filter.criteria.rowIdentifier] !== filter.query) {
				return false;
			}
		}
		return true;
	});
};

export const isRowUpdateNotificationEnabled = tableId => ![1, 2, 3, 4, 5, 6, 30, 33].includes(Number(tableId));

export const getGridFilterName = tableId => `GRID_FILTERS_${tableId}`;

const filterUsingOR = (data: any[], filters: any[]): any[] => {
	return _.filter(data, item => {
		for (const filter of filters) {
			if (item[filter.criteria.rowIdentifier] === filter.query) {
				return true;
			}
		}
		return false;
	});
};

export const createActionName = (eventName: string) => {
	return `${constants.MODEL_NAME}-action/${eventName}`;
};

export const createFiltersFromQueries = (queries: any[], isView) => {
	const isViewEnabled = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

	return _.map(_.compact(queries), ({ criteria, query, operator }) => ({
		field: criteria?.rowIdentifier,
		value: query,
		operator: operator?.value,
		symbol: operator?.symbol,
		type: criteria?.type,
		queryName: isView || isViewEnabled ? criteria?.rowIdentifier : criteria?.queryName
	}));
};

export const getFilteredData = (sourceData: any[] = [], filters: TFilter): any[] => {
	let resultData = [];

	if (filters && filters.search && filters.search.length) {
		resultData =
			filters.operator === 'AND'
				? filterUsingAND(sourceData, filters.search)
				: filterUsingOR(sourceData, filters.search);
	} else {
		resultData = [...sourceData];
	}

	if (filters?.sortField) {
		resultData = _.orderBy(resultData, [filters.sortField], [filters?.sortDirection.toLowerCase()]);
	}

	return resultData;
};

export const ORDER_PAGE_ID = [23, 1, 2, 30];
