// @ts-nocheck
import BodyText from 'atoms/bodyText/bodyText';
import Modal from 'atoms/modal/modal';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import _ from 'lodash';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalActions from '../common/modalActions';
import ModalHeader from '../common/modalHeader';
import * as sagaActions from '../sagaActions';
import * as selectors from '../selectors';
import Loader from 'atoms/loader';
import * as dynamicGridQuery from 'models/dynamicGrid/query';

interface TProps {
	hideModal: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
	selectedTab: string;
}

const ModalContent = ({ selectedOrders }: any) => {
	return (
		<Stack isVertical gutter="medium">
			<StackItem>
				<BodyText>{`Are you sure you want to delete the ${selectedOrders} selected orders?`}</BodyText>
			</StackItem>
		</Stack>
	);
};

const ThetaOrderDeleteModal = (props: TProps) => {
	const { hideModal, selectedTab } = props;
	const dispatch = useDispatch();

	const selectedOrders = useSelector(state => dynamicGridSelectors.getTableSelection(state, Number(selectedTab)));

	const orders = useSelector(state => dynamicGridSelectors.getGridData(state, Number(selectedTab)));
	const ordersSearch = useSelector(state => dynamicGridSelectors.getGridFilters(state, selectedTab));
	const totalCount = useSelector(state => dynamicGridSelectors.getTotalCount(state, Number(selectedTab)));
	const getOrderIds = () => {
		if (Number(selectedTab) == 30) {
			let filteredOrders = _.filter(orders, singleOrder =>
				selectedOrders?.selectedRowKeys?.includes(singleOrder.OrderKitID)
			);
			filteredOrders = filteredOrders.map(singleOrder => singleOrder.OrderID);
			return filteredOrders.join(',');
		} else {
			return selectedOrders?.selectedRowKeys?.join(',');
		}
	};
	const handleDeleteOrder = (e: any) => {
		const data = {
			//   OrderIDs: getOrderIds(),
			tableId: selectedTab,
			orderIds: getOrderIds(),
			isSelectAll: selectedOrders?.isSelectAll,
			selectAllSearch: JSON.stringify({
				filter: dynamicGridQuery.createFiltersFromQueries(ordersSearch.search)
			})
		};
		dispatch(sagaActions.deleteOrder(data, selectedTab));
	};
	const isLoading = useSelector(state => selectors.getLoadingStatus(state));

	return (
		<Modal hideModal={hideModal} height={300}>
			<Sheet isInset>
				<SheetBody>
					<Stack isVertical gutter="small">
						<StackItem>
							<ModalHeader title={'Delete Order'} />
						</StackItem>
						<StackItem isStretch isGrow>
							{isLoading ? (
								<Stack isVertical>
									<StackItem isGrow isStretch align="center">
										<Loader />
									</StackItem>
								</Stack>
							) : (
								<ModalContent
									selectedOrders={
										selectedOrders?.isSelectAll
											? totalCount
											: selectedOrders?.selectedRowKeys?.length
									}
								/>
							)}
						</StackItem>
						<StackItem isStretch>
							<ModalActions
								secondaryButtonName={'NO, DO NOT DELETE'}
								primaryButtonName={'YES, DELETE'}
								primaryButtonClick={handleDeleteOrder}
								secondaryButtonClick={hideModal}
								getPrimaryDisabledStatus={() => false}
								isLoading={isLoading}
							/>
						</StackItem>
					</Stack>
				</SheetBody>
			</Sheet>
		</Modal>
	);
};

export default ThetaOrderDeleteModal;
