// @ts-nocheck
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';

interface TProps {
  title: string;
}

const ModalHeader = (props: TProps) => {
  const { title } = props;
  return (
    <Stack isVertical gutter="small">
      <StackItem isStretch>
        <Heading>{title}</Heading>
      </StackItem>
      <StackItem isStretch>
        <Divider />
      </StackItem>
    </Stack>
  );
};

export default ModalHeader;
