import moment from 'moment-timezone';
import { relogin, silentLogin } from './api';
import jwt_decode from 'jwt-decode';
import _ from 'lodash';

export function formatDate(date) {
	if (!date) {
		return '';
	}
	if (typeof date === 'string') {
		date = date.replace('Z', '');
	}

	let dateObj = moment(new Date(date));
	dateObj = dateObj.startOf('day');
	dateObj = dateObj.format('L');

	return dateObj;
}

export function formatDateTz(date) {
	if (typeof date === 'string') {
		date = date.replace('Z', '');
	} else {
		date = new Date(date);
	}
	return moment(date);
}

export function truncateWithEllipses(text, max) {
	return text?.substr(0, max - 1) + (text?.length > max ? '...' : '');
}

export function formatDateV1(date) {
	if (!(Object.prototype.toString.call(date) === '[object Date]')) {
		//If date format is 'MM-DD-YYYY' add string or date object subtracts date\\
		var regEx = /^\d{4}-\d{2}-\d{2}$/;
		if (date.match(regEx) !== null) {
			date = `${date}T00:00:00`;
		}
	}

	var d = moment.tz(new Date(date), 'America/Georgia'),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate(),
		year = d.getFullYear();

	return [month, day, year].join('/');
}

export function formatFormDate(date) {
	if (!(Object.prototype.toString.call(date) === '[object Date]')) {
		//If date format is 'MM-DD-YYYY' add string or date object subtracts date\\
		var regEx = /^\d{4}-\d{2}-\d{2}$/;
		if (date.match(regEx) !== null) {
			date = `${date}T00:00:00`;
		}
	}

	var d = new Date(date),
		month = '' + (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1),
		day = '' + (d.getDate() < 10 ? '0' : '') + d.getDate(),
		year = '' + d.getFullYear();
	return [year, month, day].join('-');
}

export function formatShortDate(date) {
	if (date && !(Object.prototype.toString.call(date) === '[object Date]')) {
		//If date format is 'MM-DD-YYYY' add string or date object subtracts date\\
		var regEx = /^\d{4}-\d{2}-\d{2}$/;
		if (date.match(regEx) !== null) {
			date = `${date}T00:00:00`;
		}
	}

	var d = new Date(date),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate();

	return [month, day].join('/');
}

export function formatMoney(moneyValue, showCents) {
	if (moneyValue) {
		let moneyString = '';
		try {
			moneyString = parseFloat(moneyValue).toFixed(2);
			if (!showCents) moneyString = Math.round(moneyString);
			moneyString = '$' + moneyString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
			return moneyString;
		} catch (error) {
			console.error(error);
			return moneyValue;
		}
	} else {
		if (moneyValue === 0) {
			return '$0';
		}
		return null;
	}
}

export const getObjectDiff = (obj1, obj2, compareRef = false) => {
	return Object.keys(obj1).reduce((result, key) => {
		if (!obj2.hasOwnProperty(key)) {
			result.push(key);
		} else if (_.isEqual(obj1[key], obj2[key])) {
			const resultKeyIndex = result.indexOf(key);

			if (compareRef && obj1[key] !== obj2[key]) {
				result[resultKeyIndex] = `${key} (ref)`;
			} else {
				result.splice(resultKeyIndex, 1);
			}
		}
		return result;
	}, Object.keys(obj2));
};

export function tokenExpiryListener(accessToken) {
	// minutesToRefresh = 10; // set before two mins to expiry

	let currentTime = Date.now();
	let expiration = jwt_decode(accessToken).exp * 1000;
	let safetyInterval = 100;
	let tokenExpiryTime = expiration - currentTime - 10000;
	if (tokenExpiryTime < -10000) {
		// relogin();
		return;
	}

	if (tokenExpiryTime < 0) {
		silentLogin();
	}
}

export function formatThousands(value) {
	return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatPercentage(floatValue, isFull) {
	if (floatValue === null || floatValue === undefined || isNaN(floatValue)) {
		return null;
	}
	let percentValue = null;
	percentValue = isFull ? floatValue + '%' : (parseFloat(floatValue).toFixed(2) * 100).toFixed(0).toString() + '%';
	return percentValue;
}

export function formatCapitalize(string) {
	return string.replace(/^\w/, c => c.toUpperCase());
}

export function removeCapitalize(string) {
	return string.replace(/^\w/, c => c.toLowerCase());
}

export function devProxyBI() {
	if (process.env.NODE_ENV === 'production') {
		return 'https://apim-thetaretail-dev.azure-api.net/api/v2/login/token';
	} else {
		return 'https://apim-thetaretail-dev.azure-api.net/api/v2/login/token';
	}
}

export function devProxyAPI() {
	if (process.env.NODE_ENV === 'production') {
		return 'https://apim-thetaretail-dev.azure-api.net/api/v2/login/token';
	} else {
		return 'https://apim-thetaretail-dev.azure-api.net/api/v2/login/token';
	}
}

export function isThetaApp() {
	return window.location.host.match('thetaretail-staging') || window.location.host.match('thetaretail.azure')
}

export function parseJwt(token) {
	var base64Url = token?.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(
		atob(base64)
			?.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);

	return JSON.parse(jsonPayload);
}

export function unformatNumericString(valStr) {
	if (valStr) {
		if (isNaN(valStr)) {
			if (valStr.includes(',') || valStr.includes('$')) {
				let val = valStr.replace('$', '').replace(/,/g, '');

				if (!isNaN(val) && val !== '') {
					val = Number(val);
					return val;
				}
			} else if (valStr.includes('%')) {
				return parseInt(valStr);
			}
		} else if (typeof valStr === 'string' && valStr !== '') {
			return Number(valStr);
		}
	}

	//If not an eligible number, return original value
	return valStr;
}

export function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getProjectedOnHand(item) {
	let ProjectedOnHand = item['ProjectedOnHand'] === '' ? 0 : parseInt(item['ProjectedOnHand']);
	// let onOrder = (item["OnOrder"] === "" ? 0 : parseInt(item["OnOrder"]));
	// let inTransit = (item["InTransit"] === "" ? 0 : parseInt(item["InTransit"]));
	let SKUOrderQty = parseInt(item['SKUOrderQty']);
	return ProjectedOnHand + SKUOrderQty;
}

export function getRefreshQueryString() {
	let d = new Date();
	return `refresh=${d.getTime().toString()}`;
}

export function roundToOne(num) {
	return Math.round((num + Number.EPSILON) * 10) / 10;
}
