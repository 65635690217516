// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import { STORE_PREFIX } from './constants';
import { TTransactionReducer } from './types';

export const initialState: TTransactionReducer = {};

const slice = createSlice({
  initialState,
  name: STORE_PREFIX,
  reducers: {},
});

export default slice;

export const {} = slice.actions;
