// @ts-nocheck

import { isDemandChangeReadWrite, isDemandReportingReadWrite } from 'models/user/selector'


export const getDemandPlanningTabs = (state) => {

	return _.filter([
		{
			name: 'Demand Reporting',
			tableId: 55,
			isEnabled: isDemandReportingReadWrite(state)
		},
		{
			name: 'Demand Change',
			tableId: 56,
			isEnabled: isDemandChangeReadWrite(state)
		}
	], f => f.isEnabled)

}