// @ts-nocheck
import * as React from 'react';

import Heading from 'atoms/heading/heading';
import Sheet from 'atoms/sheet/sheet';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import { List } from 'antd';
import Scrollable from 'atoms/scrollable/scrollable';
import Tabs from 'atoms/tabs/tabs';
import { Tab } from 'atoms/tab/tab';
import * as constants from './constants';
import * as selectors from './selectors';
import { useDispatch, useSelector } from 'react-redux';
import * as sagaActions from './sagaActions';
import Loader, { LoaderWrap } from 'atoms/loader';
import ParameterDetails from './parameterDetails';
import ParameterDetailItem from './parameterDetailItem';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import ParameterGroup from './parameterGroup';

const ParameterConfig: React.FunctionComponent = ({
  selectedTab,
  selector,
  parameterGroups,
}) => {
  const isLoadingDetails = useSelector(state => {
    return selectors.isLoading(
      state,
      constants.LOADER_COMPONENTS.PARAMETER_DETAILS
    );
  });

  return (
    <Sheet>
      <SheetBody>
        <LoaderWrap isLoading={Boolean(isLoadingDetails)}>
          <Stack isVertical gutter="medium" style={{ width: '80%' }}>
            {_.map(parameterGroups.data, (parameter, index) => {
              return (
                <StackItem key={_.uniqueId()}>
                  <ParameterDetailItem
                    selector={selector}
                    selectedTab={selectedTab}
                    parameter={parameter}
                    groupData={parameterGroups.data}></ParameterDetailItem>
                </StackItem>
              );
            })}
          </Stack>
        </LoaderWrap>
      </SheetBody>
    </Sheet>
  );
};

export default ParameterConfig;
