// @ts-nocheck
import { TABS_IDENTIFIER } from 'pages/masterData/groups/constants';

export const MODEL_NAME = 'groupModal';

export const GROUP_MODAL_TABLE_IDS = {
	GROUPS_STORE: 57,
	GROUPS_SKU: 58,
	GROUPS_CUSTOMERS: 59
};

export const CUSTOMER_DROPDOWN = [
	{ displayName: TABS_IDENTIFIER.STORES, value: TABS_IDENTIFIER.STORES },
	{ displayName: TABS_IDENTIFIER.SKUS, value: TABS_IDENTIFIER.SKUS },
	{ displayName: TABS_IDENTIFIER.CUSTOMERS, value: TABS_IDENTIFIER.CUSTOMERS }
];

export const getTableId = GroupType => {
	switch (GroupType) {
		case TABS_IDENTIFIER.SKUS:
			return GROUP_MODAL_TABLE_IDS.GROUPS_SKU;
		case TABS_IDENTIFIER.CUSTOMERS:
			return GROUP_MODAL_TABLE_IDS.GROUPS_CUSTOMERS;
		case TABS_IDENTIFIER.STORES:
		default:
			return GROUP_MODAL_TABLE_IDS.GROUPS_STORE;
	}
};

export const TABLE_ID = 46;
