// @ts-nocheck
import { put, takeEvery, select } from 'redux-saga/effects';
import { getAPIDataV2 } from 'utils/api';
import * as slice from './reducer';
import * as selectors from './selectors';
import _ from 'lodash';
import * as sagaActions from './sagaActions';
import qs from 'qs';
import * as ModelSaga from '../../models/resetModel/sagas';
import { getItemFromLocalStorage, setItemInLocalStorage } from 'utils/storage';
import * as paginationSelectors from 'pages/models/paginationModel/selectors';
import * as constants from './constants';
function* handleFetchTableColumns({ payload }) {
	const { tableId } = payload;
	yield put(
		slice.toggleLoader({
			loaderComponent: constants.LOADER_COMPONENTS.KITS_COLUMN_DEF,
			isLoading: true
		})
	);
	const data = yield getAPIDataV2(`custom/${tableId}`).then(response => response?.data);
	yield put(slice.updateTableColumns({ data }));
	yield put(
		slice.toggleLoader({
			loaderComponent: constants.LOADER_COMPONENTS.KITS_COLUMN_DEF,
			isLoading: false
		})
	);
}

function* handleResetTable({ payload }) {
	yield ModelSaga.resetTable({ payload });
	yield refreshTableData({ payload });
}

function* createUpdateFilterQuery({ payload }) {
	let { searchFilters, searchQueries, tableId, isClearSearchParam } = payload;

	const localStorageQueries = (!isClearSearchParam && getItemFromLocalStorage(tableId)) || [];

	searchQueries = _.compact(_.uniqWith([...localStorageQueries, ...searchQueries], _.isEqual));
	yield put(
		slice.createUpdateFilter({
			tableId,
			searchFilters,
			searchQueries
		})
	);
	if (searchQueries) {
		setItemInLocalStorage(tableId, searchQueries);
	}
}

function* refreshTableData({ payload: { tableId } }) {
	let paginationFilters = yield select(state => {
		return paginationSelectors.getPaginationFilters(state, tableId);
	});
	let queryFilters = yield select(state => {
		return selectors.getQueryFilters(state, tableId);
	});

	let filters = { ...paginationFilters, ...queryFilters };
	const updatedFilters = {
		..._.omit(filters, 'searchQueries'),
		search: JSON.stringify({
			filter: [
				..._.map(filters.searchQueries, ({ criteria, query }) => ({
					field: criteria.rowIdentifier,
					value: query
				}))
			]
		})
	};
	let newFilters = _.cloneDeep(updatedFilters);
	if (newFilters.perPage) {
		newFilters.itemsPerPage = newFilters.perPage;
		delete newFilters.perPage;
	}

	const apiQuery = {
		...newFilters,
		refresh: new Date().getTime().toString()
	};

	yield put(
		slice.toggleLoader({
			loaderComponent: constants.LOADER_COMPONENTS.KITS_GRID,
			isLoading: true
		})
	);
	yield handleFetchTableColumns({ payload: { tableId } });
	let data = yield getAPIDataV2(`kits?${qs.stringify(apiQuery)}`).then(response => response?.data.data);
	yield put(slice.updateTableData({ data }));

	yield put(
		slice.toggleLoader({
			loaderComponent: constants.LOADER_COMPONENTS.KITS_GRID,
			isLoading: false
		})
	);
}

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
	return [
		yield takeEvery(sagaActions.fetchTableColumns, handleFetchTableColumns),
		yield takeEvery(sagaActions.resetTable, handleResetTable),
		yield takeEvery(sagaActions.createUpdateFilterQuery, createUpdateFilterQuery),
		yield takeEvery(sagaActions.refreshTableData, refreshTableData)
	];
}
