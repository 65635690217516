// @ts-nocheck
import React from 'react';

import RowAccessReducer from './reducer';
import RowAccessSagas from './sagas';
import RowAccess from './rowAccessAg';
import { isAclDataRestrictionsReadWriteEnabled } from 'models/user/selector';

export default [
	{
		path: '/settings/restrictions',
		key: 'key',
		componentId: 'rowAccess',
		component: (props) => <RowAccess {...props} title="asd" />,
		routes: [],
		isEnabled: state => isAclDataRestrictionsReadWriteEnabled(state),
		importReducer: () => {
			return [RowAccessReducer];
		},
		importSagas: () => {
			return [RowAccessSagas];
		},
		title: 'Acl Row Access',
		headerName: 'Row Access',
		name: 'Row Access'
	}

];
