// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const fetchStores = createAction(
  'newOrder/fetch-stores',
  function prepare(customerNum, query) {
    return {
      payload: {
        customerNum,
        query,
      },
    };
  }
);

export const updateSelectedStores = createAction(
  'newOrder/update-selected-stores',
  function prepare(selectedStores) {
    return {
      payload: {
        selectedStores,
      },
    };
  }
);

export const updateSKUs = createAction('newOrder/update-skus', function prepare(
  skus
) {
  return {
    payload: {
      skus,
    },
  };
});

export const updateSelectedSkus = createAction(
  'newOrder/update-selected-skus',
  function prepare(selectedSkus) {
    return {
      payload: {
        selectedSkus,
      },
    };
  }
);

export const updateSelectedReserveStores = createAction(
  'newOrder/update-selected-reserve-stores',
  function prepare(reserveSelectedStores) {
    return {
      payload: {
        reserveSelectedStores,
      },
    };
  }
);

export const reviewOrders = createAction(
  'newOrder/review-orders',
  function prepare(batchData, orderInfo, storeOrderSet) {
    return {
      payload: {
        batchData,
        orderInfo,
        storeOrderSet,
      },
    };
  }
);

export const subscribeStores = createAction(
  'newOrder/update-stores',
  function prepare(data) {
    return {
      payload: {
        data,
      },
    };
  }
);

export const getCustomers = createAction('newOrder/get-customers');
export const getShippingMethods = createAction('newOrder/get-shipping-methods');
export const getWarehouses = createAction('newOrder/get-warehouses');
export const getSchedules = createAction('newOrder/get-schedules');
export const resetStore = createAction('newOrder/reset-store');
