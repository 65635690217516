// @ts-nocheck
import Divider from 'atoms/divider/divider';
import Dropdown from 'atoms/dropdown/dropdown';
import FileInput from 'atoms/fileInput/fileInput';
import Heading from 'atoms/heading/heading';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import _ from 'lodash';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as constants from './constants';
import { UPLOAD_TYPE, UPLOAD_DATA_CHANGE_TYPES } from './constants';
import * as sagaActions from './sagaActions';
import * as selectors from './selectors';
import Button from 'atoms/button/button';
import { getComponentUserSettings, getERPStatus, isLoading } from 'models/dynamicGrid/selector';
import Loader from 'atoms/loader';
import { isLoadingEnabled } from 'models/loaders/selectors';
import { fetchComponentUserSettings } from 'models/dynamicGrid/sagaActions';
interface Props {
	disabled?: boolean;
	isSecondary?: boolean;
	tableId: string;
	isAvailability?: boolean;
}

function UploadData(props: Props) {
	const { tableId, isAvailability } = props;
	const dispatch = useDispatch();
	const templates = constants.FILE_TEMPLATES;

	const [uploadedFile, onUploadFile] = React.useState(null);
	const [uploadType, setUploadType] = React.useState(UPLOAD_TYPE.CSV);
	const [customers, setCustomers] = React.useState([]);
	const [selectedCustomer, setSelectedCustomer] = React.useState({});
	const [selectedWarehouse, setSelectedWarehouse] = React.useState({});
	const isLoadingErp = useSelector(state => isLoadingEnabled(state, 'isLoadingErp'));
	const [warehouses, setWarehouses] = React.useState([]);
	const userSettings = useSelector(state => getComponentUserSettings(state, 'file-upload'));
	const isLoadingUserSettings = useSelector(state => isLoadingEnabled(state, `file-upload/${tableId}`));
	const isLoadingUpload = useSelector(state => isLoadingEnabled(state, `upload`));

	const [isIncrementalUpload, handleIsIncrementalUpload] = React.useState({
		displayName: UPLOAD_DATA_CHANGE_TYPES[1].displayName,
		value: UPLOAD_DATA_CHANGE_TYPES[1].value
	});

	const handleFileUpload = React.useCallback(
		event => {
			onUploadFile(event.target.files[0]);
		},
		[onUploadFile]
	);
	React.useEffect(() => {
		dispatch(sagaActions.resetUpload());
		if (isAvailability) {
			dispatch(sagaActions.getWarehouses());
			dispatch(sagaActions.getCustomers());
		}
		dispatch(fetchComponentUserSettings({ tableId, component: 'file-upload' }));
		return () => {
			dispatch(sagaActions.resetUpload());
		};
	}, []);
	const handleUpload = React.useCallback(() => {
		const params = {
			isIncrementalUpload: isIncrementalUpload.value,
			file: uploadedFile,
			importType: uploadType
		};
		if (isAvailability && uploadType === UPLOAD_TYPE.ERP) {
			params['warehouse'] = selectedWarehouse.value;
			params['customer'] = selectedCustomer.value;
		}
		dispatch(
			sagaActions.onUploadFile({
				tableId,
				params
			})
		);
	}, [isIncrementalUpload, uploadedFile, tableId, uploadType, selectedWarehouse, selectedCustomer]);

	const uploadStatus = useSelector(state => selectors.getUploadStatus(state));
	const isErpEnabled = useSelector(state => getERPStatus(state, tableId)) || false;
	const fetchedCustomers = useSelector(state => selectors.fetchCustomers(state)) || [];
	const fetchedWarehouses = useSelector(state => selectors.fetchWarehouses(state)) || [];

	React.useEffect(() => {
		if (isErpEnabled && userSettings?.importType === 'erp') {
			setUploadType(UPLOAD_TYPE.ERP);
		}
		if (isLoadingUserSettings) {
			return;
		}
		setCustomers(fetchedCustomers);
		setWarehouses(fetchedWarehouses);

		setUploadType(userSettings?.importType || 'csv');
		setSelectedWarehouse(
			fetchedWarehouses.find(({ value }) => value === userSettings?.warehouse) || fetchedWarehouses[0]
		);
		setSelectedCustomer(
			fetchedCustomers.find(({ value }) => value === userSettings?.customer) || fetchedCustomers[0]
		);
		handleIsIncrementalUpload(
			UPLOAD_DATA_CHANGE_TYPES.find(({ value }) => value === userSettings?.isIncrementalUpload) ||
				UPLOAD_DATA_CHANGE_TYPES[1]
		);
	}, [isErpEnabled, fetchedCustomers, fetchedWarehouses, userSettings, isLoadingUserSettings]);

	const updateUploadType = value => {
		setUploadType(value);
	};

	return (
		<Sheet>
			<SheetBody>
				{isLoadingErp || isLoadingUserSettings || isLoadingUpload ? (
					<Stack isVertical>
						<StackItem isGrow>
							<Loader />
						</StackItem>
					</Stack>
				) : (
					<Stack isVertical gutter="medium">
						{uploadStatus &&
							(uploadStatus.isError ? (
								<StackItem isStretch>
									<Heading type="secondary" error>
										{uploadStatus.errorMessage}
									</Heading>
								</StackItem>
							) : (
								<StackItem isStretch>
									<Heading type="secondary" success>
										Import Submitted
									</Heading>
								</StackItem>
							))}
						<StackItem>
							<Stack isVertical gutter="small">
								<StackItem>
									<Heading type="secondary">Import Data</Heading>
								</StackItem>
								<StackItem isStretch>
									<Divider></Divider>
								</StackItem>
								<StackItem>
									<form style={{ width: '100%' }}>
										<Stack>
											<StackItem isStretch isGrow>
												<Stack gutter="small" position="center">
													<StackItem>
														<input
															type={'radio'}
															name="UploadDataType"
															value={UPLOAD_TYPE.CSV}
															style={{ cursor: 'pointer' }}
															onChange={e => {
																updateUploadType(UPLOAD_TYPE.CSV);
															}}
															checked={uploadType === UPLOAD_TYPE.CSV}
														/>
													</StackItem>
													<StackItem>
														<Heading type="tertiary">CSV</Heading>
													</StackItem>
												</Stack>
											</StackItem>
											<StackItem isStretch isGrow>
												<Stack gutter="small" position="center">
													<StackItem>
														<input
															type={'radio'}
															name="UploadDataType"
															onChange={e => {
																updateUploadType(UPLOAD_TYPE.ERP);
															}}
															value={UPLOAD_TYPE.ERP}
															style={{ cursor: 'pointer' }}
															checked={uploadType === UPLOAD_TYPE.ERP}
															disabled={!isErpEnabled}
														/>
													</StackItem>
													<StackItem>
														<Heading type="tertiary">ERP</Heading>
													</StackItem>
												</Stack>
											</StackItem>
										</Stack>
									</form>
								</StackItem>
							</Stack>
						</StackItem>
						<StackItem isGrow>
							<Stack isVertical gutter="medium">
								<StackItem>
									<Stack isVertical gutter="small">
										<StackItem>
											<Heading type="tertiary" disabled={uploadType === UPLOAD_TYPE.ERP}>
												1.) Download a CSV template (optional)
												{/* <br /> */}
												{/* &nbsp;&nbsp;&nbsp;&nbsp;(optional) */}
											</Heading>
										</StackItem>
										<StackItem>
											<Button
												isCsv
												disabled={uploadType === UPLOAD_TYPE.ERP}
												csvFileName={templates?.[tableId]?.name}
												csvFileData={templates?.[tableId]?.data}
											></Button>
										</StackItem>
									</Stack>
								</StackItem>

								<StackItem>
									<Heading type="tertiary" disabled={uploadType === UPLOAD_TYPE.ERP}>
										2.) Edit your CSV
									</Heading>
								</StackItem>
								<StackItem>
									<Stack isVertical gutter="small">
										<StackItem>
											<Heading type="tertiary" disabled={uploadType === UPLOAD_TYPE.ERP}>
												3.) Upload your CSV
											</Heading>
										</StackItem>
										<StackItem>
											<FileInput
												onChange={handleFileUpload}
												disabled={uploadType === UPLOAD_TYPE.ERP}
											></FileInput>
										</StackItem>
									</Stack>
								</StackItem>
								{uploadType === UPLOAD_TYPE.CSV && (
									<StackItem>
										<Stack isVertical gutter="small">
											<StackItem>
												<Heading type="tertiary">4.) Select Data Change Type</Heading>
											</StackItem>
											<StackItem>
												<Dropdown
													selectedOption={isIncrementalUpload}
													updateHandler={handleIsIncrementalUpload}
													options={UPLOAD_DATA_CHANGE_TYPES}
												></Dropdown>
											</StackItem>
										</Stack>
									</StackItem>
								)}
								{isAvailability && uploadType === UPLOAD_TYPE.ERP && (
									<StackItem>
										<Stack isVertical gutter="small">
											<StackItem>
												<Heading type="tertiary">4.) Customer</Heading>
											</StackItem>
											<StackItem>
												<Dropdown
													selectedOption={selectedCustomer}
													updateHandler={setSelectedCustomer}
													options={customers}
												></Dropdown>
											</StackItem>
										</Stack>
									</StackItem>
								)}
								{isAvailability && uploadType === UPLOAD_TYPE.ERP && (
									<StackItem>
										<Stack isVertical gutter="small">
											<StackItem>
												<Heading type="tertiary">5.) Warehouses</Heading>
											</StackItem>
											<StackItem>
												<Dropdown
													selectedOption={selectedWarehouse}
													updateHandler={setSelectedWarehouse}
													options={warehouses}
												></Dropdown>
											</StackItem>
										</Stack>
									</StackItem>
								)}
								{uploadType === UPLOAD_TYPE.ERP && (
									<StackItem>
										<Stack isVertical gutter="small">
											<StackItem>
												<Heading type="tertiary">
													{isAvailability ? '6.)' : '4.)'} Select Data Change Type
												</Heading>
											</StackItem>
											<StackItem>
												<Dropdown
													selectedOption={isIncrementalUpload}
													updateHandler={handleIsIncrementalUpload}
													options={UPLOAD_DATA_CHANGE_TYPES}
												></Dropdown>
											</StackItem>
										</Stack>
									</StackItem>
								)}
								<StackItem isGrow>
									<Stack align="right" position="bottom">
										<StackItem>
											<Button
												disabled={uploadType === UPLOAD_TYPE.CSV ? !uploadedFile : false}
												handleClick={handleUpload}
												size="medium"
											>
												Upload Data
											</Button>
										</StackItem>
									</Stack>
								</StackItem>
							</Stack>
						</StackItem>
					</Stack>
				)}
			</SheetBody>
		</Sheet>
	);
}

export default UploadData;
