// @ts-nocheck
import { takeEvery } from 'redux-saga/effects';
import { putAPIDataV2 } from 'utils/api';
import * as sagaActions from './sagaActions';

function* resetTable({ payload }) {
  const { tableId } = payload;
  yield putAPIDataV2(`table/columns/reset/${tableId}`);
}
export {resetTable}

export default function* rootSaga() {
  return [yield takeEvery(sagaActions.reset, resetTable)];
}
