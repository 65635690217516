// @ts-nocheck
import { Action, AnyAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const queryFilters = {
  searchQueries: [],
};

export const initialState = {
  tableColumns: [],
  tableData: [],
  queryFilters: {
    29: {
      ...queryFilters,
    },
  },
  isLoading: {},
};

interface RejectedAction extends Action {
  error: Error;
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith('rejected');
}
const slice = createSlice({
  name: 'masterDataKits',
  initialState,
  reducers: {
    updateTableColumns(state, { payload: { data } }) {
      return {
        ...state,
        tableColumns: data,
      };
    },
    updateTableData(state, { payload: { data } }) {
      return {
        ...state,
        tableData: data,
      };
    },
    updatePaginationParams(state, { payload: { queryData } }) {
      return {
        ...state,
        pagination: queryData,
      };
    },
    toggleLoader(state, { payload: { loaderComponent, isLoading } }) {
      return {
        ...state,
        isLoading: {
          [loaderComponent]: isLoading,
        },
      };
    },
    createUpdateFilter(
      state,
      {
        payload: {
          tableId,
          searchFilters,
          searchQueries,
        },
      }
    ) {
      return {
        ...state,
        queryFilters: {
          ...state.queryFilters,
          [tableId]: {
            ...state.queryFilters[tableId],
            ..._.pickBy(
              {
                searchFilters,
                searchQueries,
              },
              _.identity
            ),
          },
        },
      };
    },
  },
});

export default slice;

export const {
  updateTableColumns,
  updateTableData,
  updatePaginationParams,
  toggleLoader,
  createUpdateFilter
} = slice.actions;
