// @ts-nocheck
import React, { lazy } from 'react';
import OrderReducer from './reducer';
import OrderSagas from './sagas';
import CustomizeModalReducer from 'pages/customizeView/reducer';
import HealthCheckReducer from 'pages/orders/healthCheck/reducer';
import HealthCheckSagas from 'pages/orders/healthCheck/sagas';
import Orders from './orders';
import { isAllOrdersReadWrite, isOrderItemsReadWrite, isOrderKitsReadWrite, isOrderSkusReadWrite } from 'models/user/selector';
// const Orders = lazy(() => import('./orders'));
const OrderDetails = lazy(() => import('./orderDetails'));

export default [
	{
		path: '/orders',
		key: 'key',
		componentId: 'orders',
		component: Orders,
		routes: [],
		importReducer: () => {
			return [OrderReducer, CustomizeModalReducer, HealthCheckReducer];
		},
		importSagas: () => {
			return [OrderSagas, HealthCheckSagas];
		},
		title: 'Skulicity | Orders Page',
		isEnabled: (state) => {
			return isAllOrdersReadWrite(state) || isOrderKitsReadWrite(state) || isOrderSkusReadWrite(state) || isOrderItemsReadWrite(state)
		},
		headerName: 'Order Management',
		exact: true,
		name: 'Orders'
	},
	{
		path: '/orders/details/:id',
		key: 'key',
		componentId: 'orderDetails',
		component: OrderDetails,
		routes: [],
		isEnabled: (state) => {
			return isAllOrdersReadWrite(state)
		},
		headerName: 'Order Management',
		title: 'Skulicity | Order Details',
		name: 'Order Details'
	}
];
