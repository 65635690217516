// @ts-nocheck
import _, { filter } from "lodash";
import { type } from "os";
import { put, select, takeEvery } from "redux-saga/effects";
import {
  getAPIData,
  getAPIDataV2,
  getAPIDataV3,
  postAPIDataV3,
  deleteAPIDataV2,
  putAPIDataV2,
  postAPIDataV2,
} from "utils/api";
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
  setItemInLocalStorage,
} from "utils/storage";
import * as tableColumnSagaActions from "../tableColumns/sagaActions";
import * as constants from "./constants";
import * as query from "./query";
import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";
import * as selector from "./selector";
import { triggerNotification } from "organisms/notifications/sagaActions";
import { TFilter, TMetadata } from "./types";
import { getTableColumns } from "models/tableColumns/selector";
import { updateTableColumns } from "models/tableColumns/reducer";
import { toggleLoader } from "models/loaders/sagaActions";
import { getColumnInfoByIndentifier } from "models/tableColumns/selector";
import { updateSearchQueries } from "organisms/searchFilter/reducer";
import { getDispatch, getState } from "reducers";
import { Events } from "molecules/ReactGrid/agGrid/utils/events";
import { GRID_EVENTS } from "molecules/ReactGrid/agGrid/constants";
import { getStatusQuery } from "pages/orders/query";
import { getOrdersSummaryInfo } from "pages/orders/sagaActions";
import { getUserSettings } from "models/user/selector";
import { number } from "yargs";
import { getObjectDiff } from "utils/utilities";
import { REQUESTS } from "utils/requests";
import { putAPIDataV3 } from "utils/api";
import { getWebAppSettings } from "models/user/selector";
import {
  fetchGridMetaData,
  fetchTableColumnsAg,
} from "models/tableColumns/sagas";

type TPayload = {
  payload: {
    tableId: number;
    url?: string;
    filters?: TFilter;
    metaData?: TMetadata;
    dataProvider?: any;
  };
};

const getData = (
  metaData,
  filters,
  isAdvancedSearch = true,
  exportParams
): Promise<any> => {
  const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

  const search = {
    filter: query.createFiltersFromQueries(filters.search, filters.isView),
  };
  if (!metaData) {
    return Promise.resolve({});
  }

  let { dataProviderOptions = { type: "GET", version: 2 } } = metaData;
  const { url, service } = dataProviderOptions;

  //hard check for ag grid kits children - tableID 30
  const apiQuery =
    (Number(filters?.tableId) === 30 || Number(filters?.tableId) === 29) &&
      filters?.groupKeys?.length
      ? {
        ..._.omit(filters, ["search"]),
        isAdvancedSearch: Number(isAdvancedSearch),
        refresh: new Date().getTime().toString(),
      }
      : {
        ...filters,
        search: JSON.stringify(search),
        isAdvancedSearch: Number(isAdvancedSearch),
        refresh: new Date().getTime().toString(),
      };

  if (dataProviderOptions?.body?.filterGroups) {
    const filterGroups = {};
    _.map(_.keys(dataProviderOptions.body.filterGroups), (key) => {
      if (dataProviderOptions.body.filterGroups[key]) {
        filterGroups[key] = JSON.stringify({
          filter: query.createFiltersFromQueries(
            dataProviderOptions.body.filterGroups[key],
            filters.isView
          ),
        });
      }
      return undefined;
    });
    dataProviderOptions = {
      ...dataProviderOptions,
      body: {
        ...dataProviderOptions.body,
        filterGroups,
      },
    };
  }

  if (dataProviderOptions.type === "GET" && dataProviderOptions.version === 1) {
    return getAPIData(dataProviderOptions.url, {
      ...apiQuery,
      ...dataProviderOptions?.queryParams,
    })
      .then((response) => ({ response: response?.data }))
      .catch((error) => ({ error, isError: true }));
  }
  if (
    dataProviderOptions.type === "POST" &&
    dataProviderOptions.version === 2
  ) {
    return postAPIDataV3({
      service: dataProviderOptions.service,
      url: dataProviderOptions.url,
      body: {
        ...apiQuery,
        ...dataProviderOptions?.body,
        ...exportParams,
      },
      pathParams: dataProviderOptions?.pathParams,
      queryParams: dataProviderOptions?.queryParams,
    })
      .then((response) => ({ response: response?.data }))
      .catch((error) => ({ error, isError: true }));
  }

  return getAPIDataV3({
    service: dataProviderOptions.service,
    url: dataProviderOptions.url,
    queryParams: {
      ...apiQuery,
      ...dataProviderOptions?.queryParams,
      ...exportParams,
    },
    pathParams: dataProviderOptions?.pathParams,
  })
    .then((response) => ({ response: response?.data }))
    .catch((error) => ({ error, isError: true }));
};

const getStatusFilters = (tableId) => {
  if (Number(tableId) == 30) {
    // TODO: Critical
    return constants.OrderKitsFilter;
  }
  return constants.OrderStatusFilter;
};

export const rowDelete = async (payload, deleteActionFieldGroups) => {
  try {
    const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

    await postAPIDataV3({
      ...(payload?.isUploadGrid
        ? REQUESTS.POST.DELETE_UPLOAD_STAGING_ROW_DATA
        : REQUESTS.POST.DELETE_ROW_DATA),
      body: { ...payload, isView, fieldGroups: deleteActionFieldGroups },
    });
    return { isError: false };
  } catch (error) {
    return { error: error?.data || error, isError: true };
  }
};

export const updateColWidth = async (payload) => {
  try {
    await putAPIDataV3({ ...REQUESTS.PUT.UPDATE_COLUMN_CONFIG, body: payload });
    return { isError: false };
  } catch (error) {
    return { error: error?.data || error, isError: true };
  }
};
async function refreshGridData({ payload }) {
  const dispatch = getDispatch();
  const { tableId, isSoftRefresh = false } = payload;

  dispatch(tableColumnSagaActions.fetchTableColumnsAg(tableId));

  dispatch(
    slice.updateMetadata({
      tableId,
      isAppend: true,
      metaData: {
        refreshTimestamp: Date.now(),
        isSoftRefresh,
      },
    })
  );
}

export async function fetchGridData({ payload }: TPayload) {
  const state = getState();
  const dispatch = getDispatch();
  let {
    tableId,
    isAdvancedSearch,
    isAppend,
    extendedSearchFilters,
    pageNumber,
    sortDirection,
    itemsPerPage,
    sortField,
    multiSortColumns,
    isMultiSort,
    isView,
    isPivot,
  } = payload;
  dispatch(
    slice.updateLoader({ eventName: tableId.toString(), isLoading: true })
  );
  dispatch(
    slice.updateMetadata({
      isAppend: true,
      tableId,
      metaData: {
        isPristine: true,
      },
    })
  );
  let filters = selector.getGridFilters(state, tableId);
  const metaData = selector.getGridMetadata(state, tableId);
  if (extendedSearchFilters && filters) {
    filters.search = [...filters?.search, ...extendedSearchFilters];
  }

  const {
    response = null,
    isError,
    error,
  } = await getData(
    metaData,
    {
      ...filters,
      pageNumber: pageNumber || filters?.pageNumber || 1,
      itemsPerPage: itemsPerPage || filters?.itemsPerPage || 50,
      sortDirection: sortDirection || filters?.sortDirection,
      sortField: sortField || filters?.sortField,
      multiSortColumns: multiSortColumns || filters?.multiSortColumns,
      isMultiSort: multiSortColumns?.length,
      isView,
      isPivot,
    },
    isAdvancedSearch
  );

  const settings = selector.getComponentUserSettings(
    getState(),
    query.getGridFilterName(tableId)
  );
  const updatedSettings = {
    tableId,
    component: query.getGridFilterName(tableId),
    metaData: {
      ...filters,
      tableId,
      pageNumber: pageNumber || filters?.pageNumber || 1,
      itemsPerPage: itemsPerPage || filters?.itemsPerPage || 50,
      sortDirection: sortDirection || filters?.sortDirection,
      sortField: sortField || filters?.sortField,
      multiSortColumns: multiSortColumns || filters?.multiSortColumns,
      isMultiSort: isMultiSort || filters?.isMultiSort,
    },
  };
  if (updatedSettings.metaData.pageNumber === 1) {
    dispatch(
      sagaActions.updateComponentUserSettings({
        tableId,
        component: query.getGridFilterName(tableId),
        metaData: {
          ...filters,
          tableId,
          pageNumber: pageNumber || filters?.pageNumber || 1,
          itemsPerPage: itemsPerPage || filters?.itemsPerPage || 50,
          sortDirection: sortDirection || filters?.sortDirection,
          sortField: sortField || filters?.sortField,
          multiSortColumns: multiSortColumns || filters?.multiSortColumns,
          isMultiSort: isMultiSort || filters?.isMultiSort,
        },
      })
    );
  }

  if (error?.message === "CANCELLED") {
    return;
  }
  if (isError) {
    // TODO: Error Handling
    dispatch(
      triggerNotification({
        message: !error?.data?.isSqlConnError
          ? error?.message || error?.data?.error?.message
          : "An error occurred while fetching data, please try again later.",
      })
    );
    dispatch(
      slice.updateLoader({ eventName: tableId.toString(), isLoading: false })
    );
    return;
  }

  dispatch(
    slice.updateGridData({
      tableId,
      data:
        response?.data?.map((d, index) => ({
          ...d,
          AppTableID: tableId,
          id: d[metaData?.selectRowKey],
          index,
        })) ?? [],
      isAppend,
    })
  );
  dispatch(
    slice.updateLoader({ eventName: tableId.toString(), isLoading: false })
  );

  return Promise.resolve({
    data:
      response?.data?.map((d, index) => ({
        ...d,
        AppTableID: tableId,
        id: d[metaData?.selectRowKey],
        index,
      })) ?? [],
    columns: response?.columns,
  });
}
export function ConvertToCSV(data) {
  let csv = data.map((row) => Object.values(row));
  csv?.unshift(Object.keys(data[0]));
  return csv.join("\r\n");
}

function* exportGridData({ payload }) {
  const { tableId, exportName, fieldGroups, extraFilters, selectColumnList } =
    payload;
  let filters = yield select((state) =>
    selector.getGridFilters(state, tableId)
  );
  let updatedFilters = _.cloneDeep(filters);
  if (extraFilters) {
    updatedFilters.search = [...updatedFilters.search, ...extraFilters];
  }
  let metaData = yield select((state) =>
    selector.getGridMetadata(state, tableId)
  );
  console.log(metaData)
  filters = { ...filters, fieldGroups };
  if (constants.exportTableIds.includes(tableId.toString())) {
    metaData = {
      ...metaData,
      dataProviderOptions: { type: "POST", version: 2 },
    };
    metaData.service = "grid";
  }
  yield put(
    toggleLoader({
      component: "download/" + payload.tableId,
      isLoading: true,
    })
  );
  metaData = { ...metaData, exportName, tableId, selectColumnList };
  const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

  try {
    yield getData(
      {
        ...metaData,
        dataProviderOptions: {
          ...metaData.dataProviderOptions,
          ...REQUESTS.POST.EXPORT_GRID_DATA,
          pathParams: { tableId },
        },
      },
      {
        ...updatedFilters,
        isView,
        pageNumber: updatedFilters?.pageNumber || 1,
      },
      true,
      { exportName, selectColumnList }
    );
    yield put(
      triggerNotification({
        message: "Please wait while we process your download",
        type: "success",
      })
    );
  } catch (e) { }
}

async function downloadGridData({ payload }) {
  const dispatch = getDispatch();
  // yield put(
  dispatch(toggleLoader({
    component: "download/" + payload?.tableId,
    isLoading: false,
  }))
  // );
  const blobName = payload.link.split("download-grid/")[1]
  console.log({ payload })
  console.log({ blobName })
  console.log(REQUESTS.POST.GET_SAS_TOKEN)
  try {
    const response = await postAPIDataV3({
      service: REQUESTS.POST.GET_SAS_TOKEN.service,
      url: REQUESTS.POST.GET_SAS_TOKEN.url,
      body: {
        blobName
      },
    });
    console.log(response)
    let link = document.createElement("a");
    link.setAttribute("href", response.data.blobUrl)
    link.setAttribute("target", "downloadIframe");
    link.style.visibility = "hidden";
    document.querySelector("#download-ref").appendChild(link);
    link.click();
    document.querySelector("#download-ref").removeChild(link);
    if (response.status === 200) {
      setTimeout(async () => {
        await putAPIDataV3({
          service: REQUESTS.PUT.DELETE_BLOB_DATA.service,
          url: REQUESTS.PUT.DELETE_BLOB_DATA.url,
          body: {
            url: blobName,
          },
        });
        // dispatch(triggerNotification({
        //   type: removebolbURl?.status === 200 ? 'success' : "error",
        //   autoClose: removebolbURl?.status === 200 ? 100 : 500,
        //   message: removebolbURl?.status === 200 ? `Data Downloaded Successfully` :
        //     `Data Download failed`
        // }))
      }, 2000);
    }
  } catch (error) {
    dispatch(triggerNotification({
      type: "error",
      autoClose: 500,
      message: `Data Download failed`
    }))
  }
  // try {
  // try {
  //   let link = document.createElement("a");
  //   link.setAttribute("href", "https://dlsthetaretaildev.blob.core.windows.net/download-grid/tmp/3e97f4ba-8cc4-40e2-9dfe-8d5b8096dc8b/%20%20bb.csv?sp=r&st=2024-09-19T13:33:20Z&se=2024-09-19T21:33:20Z&spr=https&sv=2022-11-02&sr=b&sig=4GBeO8LOzv3f9fAFN4YvwMjYSMd8J58PeskpmlrmfY8%3D");
  //   link.setAttribute("target", "downloadIframe");
  //   link.style.visibility = "hidden";
  //   document.querySelector("#download-ref").appendChild(link);
  //   link.click();
  //   document.querySelector("#download-ref").removeChild(link);
  // } catch (e) {
  //   console.log(e);
  // }

  //   setTimeout(async () => {
  //     try {
  //       await putAPIDataV3({
  //         service: REQUESTS.PUT.DELETE_BLOB_DATA.service,
  //         url: REQUESTS.PUT.DELETE_BLOB_DATA.url,
  //         body: {
  //           url: `${payload.link.split("download-grid/")[1]}`,
  //         },
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }, 2000);
  // } catch (e) {}
}

function* handleIsErpEnabled({ payload }) {
  try {
    yield put(toggleLoader({ component: "isLoadingErp", isLoading: false }));

    const { tableId } = payload;
    const isEnabled = yield select((state) =>
      selector.getERPStatus(state, tableId)
    );
    if (isEnabled !== undefined) {
      return;
    }
    const isErpEnabled = yield getAPIDataV3({
      service: REQUESTS.GET.GET_ERP_ENABLED.service,
      url: REQUESTS.GET.GET_ERP_ENABLED.url,
      queryParams: { tableId },
    })
      .then((response) => response?.data.isErpEnabled)
      .catch((err) => ({ isError: true, error: err }));

    if (isErpEnabled?.isError) {
      //TBD error handling;
      yield put(toggleLoader({ component: "isLoadingErp", isLoading: false }));

      yield put(
        triggerNotification({
          message: "Something went wrong, please try again later",
        })
      );
      return;
    }
    yield put(
      slice.updateMetadata({
        tableId,
        isAppend: true,
        metaData: {
          isErpEnabled,
        },
      })
    );
    yield put(toggleLoader({ component: "isLoadingErp", isLoading: false }));
  } catch (e) { }
}

function* updateGridFilters({ payload }: TPayload) {
  const {
    tableId,
    filters,
    isPaginated = true,
    isStorageEnabled = true,
  } = payload;

  const filterData = yield select((state) =>
    selector.getGridFilters(state, tableId)
  );

  const selectedData = yield select((state) =>
    selector.getTableSelection(state, tableId)
  );

  let updatedFilters = { ...filterData, ...filters };

  if (!updatedFilters.isMultiSort) {
    updatedFilters = _.omit(updatedFilters, [
      "isMultiSort",
      "multiSortColumns",
    ]);
  }

  yield put(slice.updateFilters({ tableId, filters: updatedFilters }));
}

function* updateGridMeta({ payload }: TPayload) {
  const { tableId, metaData, isAppend } = payload;
  yield put(slice.updateMetadata({ tableId, metaData, isAppend }));
}

function* loadGrid({ payload }: any) {
  const {
    tableId,
    dataProviderOptions,
    isPaginated = true,
    isAdvancedSearch = false,
    isStorageEnabled = true,
    isReset,
    extendedSearchFilters,
    childTableSelectRowKey,
    selectRowKey,
    isUploadEnabled,
  } = payload;
  let metaData;
  let filters = yield select((state) =>
    selector.getGridFilters(state, tableId)
  );
  if (isStorageEnabled) {
    filters = isPaginated && getItemFromLocalStorage(tableId);
  }

  try {
    // if (!filters) {
    filters = { ..._.cloneDeep(constants.INITIAL_FILTER), ...filters };
    // }
    if (extendedSearchFilters) {
      filters.search = [...filters.search, ...extendedSearchFilters];
    }
    if (
      !_.find(filters.search, function (singleCreteria) {
        return singleCreteria?.criteria?.rowIdentifier === "OrderStatusID";
      }) &&
      query.ORDER_PAGE_ID.includes(tableId)
    ) {
      filters.search.push(getStatusFilters(tableId));
      filters.search = _.uniqWith(filters.search, _.isEqual);
    }

    if (isReset) {
      yield updateGridFilters({
        payload: {
          tableId,
          filters: { ...filters, pageNumber: 1 },
          isStorageEnabled,
        },
      });
    }

    metaData = {
      isStorageEnabled,
      selectRowKey,
      childTableSelectRowKey,
      isUploadEnabled,
      dataProviderOptions,
    };
  } catch (e) { }

  yield updateGridMeta({
    payload: { tableId, metaData: metaData ?? {}, isAppend: true },
  });
}

function* loadGridData({ payload }: any) {
  const { tableId, dataProvider } = payload;
  console.log({ payload })
  yield put(
    slice.updateLoader({ eventName: tableId.toString(), isLoading: true })
  );

  yield updateGridFilters({
    payload: { tableId, filters: constants.INITIAL_FILTER },
  });

  try {
    const { data, isError, error } = yield dataProvider();
    if (error?.message === "CANCELLED") {
      return;
    }
    if (isError) {
      // TODO: Handler Error
      yield put(
        triggerNotification({
          message:
            "An error occurred while fetching data, please try again later.",
        })
      );
      yield put(
        slice.updateLoader({ eventName: tableId.toString(), isLoading: false })
      );
      return;
    }
    yield put(slice.updateGridData({ tableId, data }));
    yield put(
      slice.updateLoader({ eventName: tableId.toString(), isLoading: false })
    );
  } catch (e) {
    return;
  }
}

function* updateGrid({ payload }: any) {
  const { tableId, filters, isAppend } = payload;

  const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

  let isStorageEnabled = yield select((state) =>
    selector.isTableStorageEnabled(state, tableId)
  );

  try {
    yield updateGridFilters({
      payload: { tableId, filters, isStorageEnabled },
    });
  } catch (e) { }
  yield refreshGridData({
    payload: {
      tableId,
      isView,
    },
  });
}

function* loadMore({ payload: { tableId, isReset } }: any) {

  const { pageNumber = 1, ...filters } = yield select(
    (state) => selector.getGridFilters(state, tableId) || {}
  );
  const data: any = yield select((state) =>
    selector.getGridData(state, tableId)
  );
  if (
    !(data && data.length) ||
    pageNumber * filters.itemsPerPage > data?.[0]?.TotalRecordCount
  ) {
    return;
  }
  //   yield updateSelectedRows({
  //     payload: { tableId, selectedRowKeys: [], isSelectAll: false },
  //   });
  yield updateGridFilters({
    payload: {
      tableId,
      filters: { ...filters, pageNumber: isReset ? 1 : pageNumber + 1 },
      isPaginated: false,
      isStorageEnabled: false,
    },
  });

  if (isReset) {
    return;
  }
  yield fetchGridData({ payload: { tableId, isAppend: true } });
}

function* updateSelectedRows({ payload }: any) {

  const { tableId, selectedRowKeys, isSelectAll, rowGroup } = payload;
  if (!isSelectAll && !selectedRowKeys?.length) {
    Events.publish(GRID_EVENTS.CLEAR_SELECTION, {
      tableId,
    });
  }
  yield put(
    slice.updateSelectedRows({
      tableId,
      data: { selectedRowKeys, isSelectAll, rowGroup },
    })
  );
}

function* updateGridRow({ payload }: any) {
  yield put(slice.updateGridRowData(payload));
}

function* updateTableCell({ payload }: any) {

  const { tableId, row, rowIdentifier, updatedValue } = payload;

  Events.publish(GRID_EVENTS.UPDATE_GRID_CELL, {
    tableId,
    updatedRow: payload,
  });
}

function* onGridCleanUp({ payload }: any) {

  for (let index in payload.tableIds) {
    yield put(slice.cleanupGridData({ tableId: payload.tableIds[index] }));
    yield put(
      updateSearchQueries({
        tableId: payload.tableIds[index],
        searchQueries: null,
      })
    );
    removeItemFromLocalStorage(payload.tableIds[index]);
  }

  yield put(tableColumnSagaActions.cleanUpGrids(payload));
}

function* updateGridData({ payload }: any) {

  const { tableId, data } = payload;

  yield put(slice.updateGridData({ tableId, data }));
}

function* handleRowDelete({
  payload: { deleteActionFieldGroups, ...payload },
}: any) {
  const { tableId } = payload;
  const { isError, error } = yield rowDelete(payload, deleteActionFieldGroups);

  if (isError || error?.warning) {
    yield put(
      triggerNotification({
        message:
          error?.data?.message ||
          error?.message ||
          "Failed to delete row, Please try again later.",
      })
    );
    if (!error?.warning) {
      return;
    }
  }

  Events.publish(GRID_EVENTS.DELETE_ROWS, {
    tableId,
    deletedRows: payload.deletedRows,
  });
  yield put(
    sagaActions.handleRowDeleteSuccess({
      tableId,
    })
  );
}

function* handleRowUpdateToggle({ payload }: any) {
  yield put(
    slice.handleRowUpdateToggle({
      ...payload,
    })
  );
}

async function handleRowGroupKeyUpdates(payload) {
  let tableFilters = selector.getGridFilters(getState(), payload.tableId);
  const dispatch = getDispatch();

  const { isError, error, data } = await postAPIDataV3({
    service: REQUESTS.POST.POST_ORDER_BULK_EDIT.service,
    url: REQUESTS.POST.POST_ORDER_BULK_EDIT.url,
    body: {
      searchParams: [
        ...payload.groupSearchParams,
        {
          column: "OrderStatusID",
          value: 3,
          type: "number",
        },
      ],
      IsGroupUpdate: true,
      updatedQty: payload.updatedValue,
      tableId: payload.tableId,
      isView: true,
    },
  }).catch((error) => ({ isError: true, error: error.data }));
  Events.publish(GRID_EVENTS.PURGE_GRID_ROUTE, {
    tableId: payload.tableId,
    route: tableFilters.groupKeys,
  });
  if (data.isWarning) {
    dispatch(
      triggerNotification({
        message: data?.message,
        type: "warning",
      })
    );
  }
  if (isError) {
    dispatch(
      triggerNotification({
        message:
          error?.data?.message ||
          error?.message ||
          "Failed to Update. Please try again later.",
      })
    );
  }
  dispatch(getOrdersSummaryInfo({ tableId: payload.tableId }));
}

function* handleUpdateRowData({ payload }: any) {
  try {
    const {
      tableId,
      row,
      updatedValue,
      rowIdentifier,
      isInlineEdit,
      isGroupNode,
      isPivotMode,
      fieldGroups,
      isUploadGrid,
    } = payload;
    if (!isInlineEdit) {
      return;
    }
    if (payload?.updatedValue?.hasError) {
      return;
    }

    const metaData = yield select((state) =>
      selector.getGridMetadata(state, tableId)
    );
    let tableFilters = yield select((state) =>
      selector.getGridFilters(state, tableId)
    );
    tableFilters = {
      ...tableFilters,
      search: JSON.stringify({
        filter: query.createFiltersFromQueries(tableFilters?.search ?? []),
      }),
    };

    let groupSearchParams = fieldGroups
      ? [
        {
          column: "OrderID",
          value: fieldGroups.OrderID,
          type: "number",
        },
      ]
      : [];
    if (tableFilters?.rowGroupCols?.length) {
      groupSearchParams = [
        ...groupSearchParams,
        ..._.filter(
          _.map(tableFilters.rowGroupCols, (col) => ({
            column: col.AliasName,
            value: row[col.AliasName],
            type: getColumnInfoByIndentifier(getState(), tableId, col.AliasName)
              ?.ColumnType,
          })),
          (c) => c.value
        ),
      ];
    }
    if (tableFilters?.groupKeys?.length) {
      groupSearchParams = [
        ...groupSearchParams,
        ..._.filter(
          _.map(
            tableFilters.rowGroupCols.slice(
              0,
              tableFilters.rowGroupCols.length - 1
            ),
            (col, index) => ({
              column: col.AliasName,
              value: tableFilters.groupKeys[index],
              type: getColumnInfoByIndentifier(
                getState(),
                tableId,
                col.AliasName
              )?.ColumnType,
            })
          ),
          (c) => c.value
        ),
      ];
    }
    if (tableFilters?.pivotColumns?.length) {
      groupSearchParams = [
        ...groupSearchParams,
        ..._.filter(
          _.map(tableFilters.pivotColumns, (col, index) => ({
            column: col.AliasName,
            value: rowIdentifier.split("|")[index],
            type: getColumnInfoByIndentifier(getState(), tableId, col.AliasName)
              ?.ColumnType,
          })),
          (c) => c.value
        ),
      ];
    }

    groupSearchParams = _.uniqBy(groupSearchParams, "column");
    if (tableId === 30 || tableId === 33) {
      return;
    }
    if (
      groupSearchParams?.length &&
      (isPivotMode || isGroupNode) &&
      [1, 2, 3, 23, 30].includes(tableId)
    ) {
      yield handleRowGroupKeyUpdates(
        _.omit(
          {
            tableId,
            updatedValue,
            rowIdentifier,
            selectedRowKeyName: metaData.selectRowKey,
            selectedRowKeyValue: row[metaData.selectRowKey],
            ...([3, 4, 5, 6].includes(tableId) && {
              fieldGroups: { OrderID: row?.["OrderID"] }, ///////// TBD
            }),
            groupSearchParams,
            ...(!query.isRowUpdateNotificationEnabled(tableId) && {
              ...tableFilters,
              pageNumber: 1,
              itemsPerPage: (tableFilters.pageNumber || 1) * 50,
              isMultiRowUpdate: true,
            }),
          },
          ["sortField", "sortDirection"]
        )
      );
      return;
    }
    const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;
    let service = isUploadGrid
      ? REQUESTS.PUT.UPDATE_UPLOAD_ROW_DATA.service
      : REQUESTS.PUT.UPDATE_ROW_DATA.service
    let url = isUploadGrid
      ? REQUESTS.PUT.UPDATE_UPLOAD_ROW_DATA.url
      : REQUESTS.PUT.UPDATE_ROW_DATA.url
    let body = _.omit(
      {
        tableId,
        updatedValue,
        rowIdentifier,
        isView,
        selectedRowKeyName: metaData.selectRowKey,
        selectedRowKeyValue: row[metaData.selectRowKey],
        ...([3, 4, 5, 6].includes(tableId) && {
          fieldGroups: { OrderID: row?.["OrderID"] }, ///////// TBD
        }),
        groupSearchParams,
        ...(!query.isRowUpdateNotificationEnabled(tableId) && {
          ...tableFilters,
          pageNumber: 1,
          itemsPerPage: (tableFilters.pageNumber || 1) * 50,
          isMultiRowUpdate: true,
        }),
      },
      ["sortField", "sortDirection"]
    )
    if (tableId === 62) {
      service = payload.metaData.dataProviderOptions.service
      url = "column/alias"
      body = row
    }
    const { isError, error, data } = yield putAPIDataV3({
      service,
      url,
      body,
    }).catch((error) => ({ isError: true, error: error?.data }));

    if (isError || error?.warning) {
      payload.updatedValue = {
        value: payload.updatedValue,
        hasError: true,
        errorMessage:
          error?.message || "Failed to update, Please try again later.",
      };
      Events.publish(GRID_EVENTS.UPDATE_GRID_CELL, {
        tableId,
        updatedRow: payload,
      });
      yield put(sagaActions.updateTableCell(payload));
      if (!error?.warning) {
        return;
      }
    }

    Events.publish(GRID_EVENTS.UPDATE_GRID, {
      tableId,
      updatedRows: data?.updatedRows?.map((r) => {
        if (row[metaData?.selectRowKey] === r[metaData?.selectRowKey]) {
          return {
            ...r,
            [rowIdentifier]: {
              isUpdated: true,
              value: r[rowIdentifier],
            },
          };
        }
        return r;
      }),
    });

    yield put(
      slice.updateMultiRows({
        tableId,
        updatedRows: data?.updatedRows,
        selectRowKey: metaData.selectRowKey,
        rowIdentifier,
      })
    );
    yield put(
      slice.updateMetadata({
        isAppend: true,
        tableId,
        metaData: {
          isPristine: false,
        },
      })
    );
    yield put(
      sagaActions.handleRowUpdateSuccess({
        tableId,
        row,
        rowIdentifier,
      })
    );
    // if (query.isRowUpdateNotificationEnabled(tableId)) {
    // 	yield put(
    // 		triggerNotification({
    // 			message: `Successfully updated row for ${rowIdentifier
    // 				.split('_')
    // 				.join(' ')
    // 				.replace(/([a-z0-9])([A-Z])/g, '$1 $2')}: ${updatedValue}`,
    // 			type: 'success'
    // 		})
    // 	);
    // }
  } catch (e) {
    console.log("update error", e);
  }
}

export async function handleUpdateColWidth({ payload }: any) {
  const dispatch = getDispatch();
  const { columnId, tableId, columnWidth, currentWidth } = payload;
  const tableColumns = getTableColumns(getState(), tableId);
  let updated = tableColumns.map((c) => {
    return c.AppColumnSurrogateID === columnId
      ? {
        ...c,
        ColumnWidth: c.ColumnWidth + columnWidth,
      }
      : c;
  });

  // dispatch(updateTableColumns({ tableId, tableColumns: updated }));
  await updateColWidth({ tableId, columnId, columnWidth });
}

function* handleBulkRowDelete({ payload }) {
  const { tableId, isUploadGrid } = payload;
  const searchQuery = yield select((state) =>
    selector.getGridFilters(state, tableId)
  );
  const tableSelection = yield select((state) =>
    selector.getTableSelection(state, tableId)
  );
  const { isError, error } = yield rowDelete({
    tableId: tableId,
    rowIdentifier: tableSelection?.selectedRowKeys,
    isSelectAll: tableSelection?.isSelectAll,
    isUploadGrid,
    selectAllSearch: JSON.stringify({
      filter: query.createFiltersFromQueries(searchQuery?.search, true),
    }),
  });
  if (isError || error?.warning) {
    yield put(
      triggerNotification({
        message:
          error?.message ||
          "An error occurred while deleting sku exclusions, please try again later",
      })
    );
  }

  yield refreshGridData({
    payload: {
      tableId,
      isSoftRefresh: true,
    },
  });

  yield put(
    sagaActions.updateSelectedRows({
      tableId,
      isSelectAll: false,
      selectedRowKeys: [],
    })
  );
  yield put(tableColumnSagaActions.cleanUpGrids([tableId]));
}

async function updateUserGridMetaData({ payload }) {
  const dispatch = getDispatch();
  dispatch(
    slice.updateMetadata({
      tableId: payload.tableId,
      metaData: {},
      isAppend: true,
    })
  );

  await putAPIDataV3({
    service: REQUESTS.PUT.UPDATE_USER_GRID_DATA.service,
    url: REQUESTS.PUT.UPDATE_USER_GRID_DATA.url,
    body: {
      tableId: payload.tableId,
      attributes: {},
    },
    pathParams: {
      tableId: payload.tableId,
    },
  });
}

async function fetchUserGridData(payload) {
  dispatch(
    slice.updateGridData({ tableId, data: response?.data, isAppend: true })
  );
}

export async function fetchGridFiltersAsync({ payload }) {
  try {
    const { tableId, component, enableCache = false } = payload;

    if (enableCache && selector.getGridFilters(getState(), tableId)) {
      return;
    }

    const dispatch = getDispatch();
    dispatch(
      toggleLoader({
        component: "fetch_grid_filters/" + tableId,
        isLoading: true,
      })
    );
    let {
      data: { userSettings },
    } = await getAPIDataV3({
      service: REQUESTS.GET.GET_COMPONENT_USER_SETTINGS.service,
      url: REQUESTS.GET.GET_COMPONENT_USER_SETTINGS.url,
      queryParams: { component: query.getGridFilterName(tableId) },
      pathParams: {
        tableId,
      },
    });

    if (!userSettings?.ComponentMetaData?.search) {
      userSettings.ComponentMetaData = {
        ...userSettings.ComponentMetaData,
        search: [],
      };
    }
    if (
      [1, 2, 23, 30].includes(tableId) &&
      !userSettings?.ComponentMetaData?.search?.length &&
      !_.some(
        userSettings?.ComponentMetaData?.search,
        (s) => s.field === "OrderStatusID"
      )
    ) {
      userSettings?.ComponentMetaData.search.push(getStatusQuery("3", tableId));
    }

    dispatch(
      slice.updateFilters({ tableId, filters: userSettings?.ComponentMetaData })
    );
    dispatch(
      toggleLoader({
        component: "fetch_grid_filters/" + tableId,
        isLoading: false,
      })
    );
    if (userSettings) {
      dispatch(slice.updateComponentSettings(userSettings));
    }
  } catch (e) {
    console.log(e);
  }
}

async function fetchComponentUserSettings({ payload }) {
  try {
    const { tableId, component } = payload;
    const dispatch = getDispatch();
    dispatch(
      toggleLoader({
        component,
        isLoading: true,
      })
    );

    const {
      data: { userSettings },
    } = await getAPIDataV3({
      service: REQUESTS.GET.GET_COMPONENT_USER_SETTINGS.service,
      url: REQUESTS.GET.GET_COMPONENT_USER_SETTINGS.url,
      queryParams: { component },
      pathParams: {
        tableId,
      },
    });

    if (userSettings) {
      dispatch(slice.updateComponentSettings(userSettings));
    }
    dispatch(
      toggleLoader({
        component: component,
        isLoading: false,
      })
    );
  } catch (e) {
    console.log(e);
  }
}
export async function updateComponentUserSettings({ payload }) {
  try {
    const { tableId, component, metaData } = payload;
    const dispatch = getDispatch();
    dispatch(
      toggleLoader({
        component: component,
        isLoading: true,
      })
    );
    const results = await putAPIDataV3({
      service: REQUESTS.PUT.UPDATE_COMPONENT_USER_SETTINGS.service,
      url: REQUESTS.PUT.UPDATE_COMPONENT_USER_SETTINGS.url,
      pathParams: { tableId },
      body: { component, metaData },
    });

    dispatch(
      slice.updateComponentSettings({
        ...metaData,
        AppTableID: tableId,
        ComponentName: component,
      })
    );
    // await fetchComponentUserSettings({ payload: { tableId, component } });
    dispatch(
      toggleLoader({
        component: component,
        isLoading: false,
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export async function handleSynchronousAgGridLoading(payload) {
  const { payload: pOptions } = payload;
  const dispatch = getDispatch();
  dispatch(
    toggleLoader({
      component: "agGridLoading/" + pOptions.tableId,
      isLoading: true,
    })
  );
  let gridMetaData = await fetchGridMetaData(payload);
  await fetchGridFiltersAsync(payload);

  await fetchTableColumnsAg({
    payload: {
      tableId: pOptions.tableId,
    },
  });
  if (gridMetaData?.ChildTableID) {
    await fetchGridMetaData({
      payload: {
        tableId: gridMetaData?.ChildTableID,
      },
    });
    await fetchTableColumnsAg({
      payload: {
        tableId: gridMetaData?.ChildTableID,
      },
    });
  }

  dispatch(
    toggleLoader({
      component: "agGridLoading/" + pOptions.tableId,
      isLoading: false,
    })
  );
}

export default function* rootSaga() {
  return [
    yield takeEvery(sagaActions.loadGrid, loadGrid),
    yield takeEvery(sagaActions.updateTableCell, updateTableCell),
    yield takeEvery(sagaActions.fetchGridFiltersAsync, fetchGridFiltersAsync),
    yield takeEvery(sagaActions.loadGridData, loadGridData),
    yield takeEvery(sagaActions.fetchGridData, fetchGridData),
    yield takeEvery(sagaActions.refreshGridData, refreshGridData),
    yield takeEvery(sagaActions.updateGridFilters, updateGridFilters),
    yield takeEvery(sagaActions.updateGridMeta, updateGridMeta),
    yield takeEvery(sagaActions.updateGrid, updateGrid),
    yield takeEvery(sagaActions.lazyLoad, loadMore),
    yield takeEvery(sagaActions.updateGridRow, updateGridRow),
    yield takeEvery(sagaActions.updateSelectedRows, updateSelectedRows),
    yield takeEvery(sagaActions.cleanUpGrids, onGridCleanUp),
    yield takeEvery(sagaActions.updateGridData, updateGridData),
    yield takeEvery(sagaActions.handleRowDelete, handleRowDelete),
    yield takeEvery(sagaActions.handleRowUpdateToggle, handleRowUpdateToggle),
    yield takeEvery(sagaActions.updateRowData, handleUpdateRowData),
    yield takeEvery(sagaActions.updateColumnWidth, handleUpdateColWidth),
    yield takeEvery(sagaActions.erpEnabled, handleIsErpEnabled),
    yield takeEvery(sagaActions.handleBulkRowDelete, handleBulkRowDelete),
    yield takeEvery(sagaActions.exportGridData, exportGridData),
    yield takeEvery(sagaActions.downloadGridData, downloadGridData),
    yield takeEvery(
      sagaActions.fetchComponentUserSettings,
      fetchComponentUserSettings
    ),
    yield takeEvery(
      sagaActions.updateComponentUserSettings,
      updateComponentUserSettings
    ),
    yield takeEvery(
      sagaActions.triggerSynchronousAgGridLoading,
      handleSynchronousAgGridLoading
    ),
  ];
}
