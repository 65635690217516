// @ts-nocheck
import { getGridFilterName } from 'models/dynamicGrid/query';
import { fetchGridFiltersAsync, refreshGridData, updateRowData } from 'models/dynamicGrid/sagaActions';
import { updateComponentUserSettings } from 'models/dynamicGrid/sagas';
import { toggleLoader } from 'models/loaders/sagaActions';
import { triggerNotification } from 'organisms/notifications/sagaActions';
import { getDispatch } from 'reducers';
import { takeEvery } from 'redux-saga/effects';
import { REQUESTS } from 'utils/requests';

import * as constants from './constants';
import * as sagaActions from './sagaActions';
import { putAPIDataV3 } from 'utils/api';

async function createDemandMatrix({ payload: { tableId, filters } }) {
	const dispatch = getDispatch();
	dispatch(
		toggleLoader({
			component: 'create-demand-matrix',
			isLoading: true
		})
	);
	await updateComponentUserSettings({
		payload: {
			tableId,
			component: getGridFilterName(tableId),
			metaData: {
				search: filters,
				tableId,
				pageNumber: 1,
				itemsPerPage: 50
			}
		}
	});

	dispatch(
		fetchGridFiltersAsync({
			tableId,
			enableCache: false
		})
	);
	dispatch(
		toggleLoader({
			component: 'create-demand-matrix',
			isLoading: false
		})
	);
}

async function handleUpdateDemandData({ payload }) {
	const { tableId, row, updatedValue, rowIdentifier, isInlineEdit, isGroupNode, isPivotMode, fieldGroups } = payload;
	if (!row || tableId !== constants.DEMAND_MATRIX_TABLE_ID) {
		return;
	}
	try {
		const { data, isError, error } = await putAPIDataV3({service: REQUESTS.PUT.UPDATE_DEMAND.service, url: REQUESTS.PUT.UPDATE_DEMAND.url, body: {
			rowIdentifier: row?.MatrixKey,
			overrideQty: updatedValue
		}})
			.then()
			.catch(e => ({ isError: true, error: e }));
	} catch (e) {
		console.log(e, '--');
	}
}

async function triggerSubmitDemand() {
	const dispatch = getDispatch();
	try {
		const { data, isError, error } = await putAPIDataV3({service: REQUESTS.PUT.UPDATE_PLAN_QTY.service, url: REQUESTS.PUT.UPDATE_PLAN_QTY.url})
			.then()
			.catch(e => ({ isError: true, error: e }));

		if (!isError) {
			dispatch(
				triggerNotification({
					type: 'success',
					message: 'Demand Submitted successfully'
				})
			);
			dispatch(refreshGridData({ tableId: constants.DEMAND_MATRIX_TABLE_ID }));
		}
	} catch (e) {
		console.log(e, '--');
	}
}

export default function* rootSaga() {
	return [
		yield takeEvery(sagaActions.createDemandMatrix, createDemandMatrix),
		yield takeEvery(updateRowData, handleUpdateDemandData),
		yield takeEvery(sagaActions.triggerSubmitDemand, triggerSubmitDemand)
	];
}
