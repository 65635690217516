// @ts-nocheck
import Heading from 'atoms/heading/heading';
import _, { isNumber } from 'lodash';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as React from 'react';
import { Switch } from 'antd';
import * as constants from './constants';
import * as queries from './queries';
import { useDispatch, useSelector } from 'react-redux';
import * as sagaActions from './sagaActions';
import NumberInput from 'atoms/NumberInput/numberInput';
import Icon from 'atoms/icons/icon';
import Validation from 'atoms/validation/validation';
import { isParametersWrite } from 'models/user/selector';

type Props = {
	parameter: any;
	selectedTab: string;
	selector: string;
	groupData: Object;
};

const ParameterDetailItem = ({ parameter, selectedTab, selector, groupData }: Props) => {
	const {
		ParameterTitle,
		ParameterFormat,
		IsOn,
		ParameterDescription,
	} = parameter;
	const [paramValue, updateParamValue] = React.useState(
		ParameterFormat === 'Bit'
			? parameter.UpdatedValue
			: parameter.Detail_Value && isNumber(parameter.Detail_Value)
			? parameter.Detail_Value
			: parameter.Default_Value
	);
	const hasParametersWrite = useSelector(isParametersWrite);
	const dispatch = useDispatch();
	const handleActivateParameter = React.useCallback(
		(updates, isEdit?) => {
			dispatch(
				sagaActions.upsertParameterDetails({
					...updates,
					ParameterTypeID: selectedTab,
					ParameterDetailID: parameter.ParameterDetailID,
					ParameterID: parameter.ParameterID,
					ParameterValue: _.has(updates, 'UpdatedValue')
						? updates.UpdatedValue
						: parameter.Detail_Value || parameter.Default_Value || 0,
					ParameterMasterID: groupData.ParameterMasterID,
					ParameterGroupItemID: groupData[selector],
					ParameterSelector: selector
				})
			);
		},
		[dispatch, selectedTab, parameter.ParameterDetailID, parameter.ParameterID, parameter.Detail_Value, parameter.Default_Value, groupData, selector]
		// [dispatch, selectedTab, groupData, updateParamValue, parameter.ParameterDetailID, parameter.ParameterID]

	);

	return (
		<Stack gutter="large" position="center">
			{selectedTab !== constants.PARAMETER_TYPES.DEFAULT && (
				<StackItem>
					{parameter.IsLocked ? (
						<Icon faAlias="faLock" />
					) : (
						<Switch
							onChange={IsOn =>
								handleActivateParameter({
									ParameterID: parameter.ParameterID,
									IsOn: Number(IsOn),
									UpdatedValue: Boolean(Number(IsOn))
								})
							}
							disabled={!hasParametersWrite}
								
							checked={IsOn}
							size={'small'}
						></Switch>
					)}
				</StackItem>
			)}
			<StackItem isGrow>
				<Stack isVertical>
					<StackItem>
						<Heading type="tertiary">{ParameterTitle}</Heading>
					</StackItem>
					<StackItem>{ParameterDescription}</StackItem>
				</Stack>
			</StackItem>
			{ParameterFormat !== 'Bit' && (
				<StackItem>
					<Validation {...queries.isValidParamValue(paramValue)}>
						<NumberInput
							align="right"
							minValue={0}
							onChange={value => {
								if (value >= 0) {
									updateParamValue(value);
									handleActivateParameter(
										{
											UpdatedValue: value,
											ParameterID: parameter.ParameterID
										},
										true
									);
								} else {
									updateParamValue(value);
								}
							}}
							value={parameter.Detail_Value || parameter.Default_Value}
							isEnabled={hasParametersWrite}
						/>
					</Validation>
				</StackItem>
			)}

			{/* {Boolean(IsOn || parameter.IsLocked) && ParameterFormat === 'Bit' && (
				<StackItem>
					{
						<Checkbox
							checked={parameter.Detail_Value}
							onChange={e =>
								handleActivateParameter(
									{
										UpdatedValue: e.target.checked,
										ParameterID: parameter.ParameterID
									},
									true
								)
							}
						></Checkbox>
					}
				</StackItem>
			)} */}
		</Stack>
	);
};

export default ParameterDetailItem;
