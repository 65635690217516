export const DROPDOWN_DISPLAY_NAMES = {
	CUSTOMIZE_VIEW: 'Customize View',
	RESET_VIEW: 'Reset View',
	CHANGE_PO_DATE: 'Change PO Date',
	CLONE_ORDERS: 'Clone Order(s)',
	DELETE_ORDERS: 'Delete Orders',
	DISTRIBUTE_EXCESS_AVAIL: 'Distribute Excess Avail.',
	OPTIMIZE_SHIPPING_METHOD: 'Optimize Shipping Method',
	PUBISH_ORDERS: 'Publish Orders',
	SPLIT_ORDERS: 'Split Order(s)',
	UPDATE_SHIPPING_METHOD: 'Update Shipping Method',
	UPDATE_ORDER_STATUS: 'Update Order Status',
	LOCK_RECORDS: 'Lock Records',
	UNLOCK_RECORDS: 'Unlock Records',
	UPLOAD_DATA: 'Upload Data',
	BULK_ORDER_QTY_EDIT: 'Bulk Order Qty Edit',
	// CREATE_KIT: 'Create Kits'
};

export const DROPDOWN_VALUES = {
	CUSTOMIZE_VIEW: 'customize_view',
	RESET_VIEW: 'reset_view',
	CHANGE_PO_DATE: 'po_date',
	CLONE_ORDERS: 'clone_order',
	DELETE_ORDERS: 'delete_order',
	DISTRIBUTE_EXCESS_AVAIL: 'excess_avail',
	OPTIMIZE_SHIPPING_METHOD: 'optimize_shipping',
	PUBISH_ORDERS: 'publish_orders',
	SPLIT_ORDERS: 'split_order',
	UPDATE_SHIPPING_METHOD: 'update_shipping',
	UPDATE_ORDER_STATUS: 'update_order_status',
	LOCK_RECORDS: 'lock_records',
	UNLOCK_RECORDS: 'unlock_records',
	UPLOAD_DATA: 'upload_data',
	BULK_ORDER_QTY_EDIT: 'bulk_order_qty_edit',
	// CREATE_KIT: 'create_kit'
};

export const DROPDOWN_MENU_ALL_ORDERS = [
	{
		displayName: DROPDOWN_DISPLAY_NAMES.CUSTOMIZE_VIEW,
		value: DROPDOWN_VALUES.CUSTOMIZE_VIEW
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.RESET_VIEW,
		value: DROPDOWN_VALUES.RESET_VIEW
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.BULK_ORDER_QTY_EDIT,
		value: DROPDOWN_VALUES.BULK_ORDER_QTY_EDIT
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.CHANGE_PO_DATE,
		value: DROPDOWN_VALUES.CHANGE_PO_DATE
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.CLONE_ORDERS,
		value: DROPDOWN_VALUES.CLONE_ORDERS
	},

	{
		displayName: DROPDOWN_DISPLAY_NAMES.DELETE_ORDERS,
		value: DROPDOWN_VALUES.DELETE_ORDERS
	},

	{
		displayName: DROPDOWN_DISPLAY_NAMES.DISTRIBUTE_EXCESS_AVAIL,
		value: DROPDOWN_VALUES.DISTRIBUTE_EXCESS_AVAIL
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.OPTIMIZE_SHIPPING_METHOD,
		value: DROPDOWN_VALUES.OPTIMIZE_SHIPPING_METHOD
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.PUBISH_ORDERS,
		value: DROPDOWN_VALUES.PUBISH_ORDERS
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.SPLIT_ORDERS,
		value: DROPDOWN_VALUES.SPLIT_ORDERS
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.UPDATE_SHIPPING_METHOD,
		value: DROPDOWN_VALUES.UPDATE_SHIPPING_METHOD
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.UPDATE_ORDER_STATUS,
		value: DROPDOWN_VALUES.UPDATE_ORDER_STATUS
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.LOCK_RECORDS,
		value: DROPDOWN_VALUES.LOCK_RECORDS
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.UNLOCK_RECORDS,
		value: DROPDOWN_VALUES.UNLOCK_RECORDS
	}
];

export const DROPDOWN_MENU_ORDER_SKUS = [
	{
		displayName: DROPDOWN_DISPLAY_NAMES.CUSTOMIZE_VIEW,
		value: DROPDOWN_VALUES.CUSTOMIZE_VIEW
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.RESET_VIEW,
		value: DROPDOWN_VALUES.RESET_VIEW
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.BULK_ORDER_QTY_EDIT,
		value: DROPDOWN_VALUES.BULK_ORDER_QTY_EDIT
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.DISTRIBUTE_EXCESS_AVAIL,
		value: DROPDOWN_VALUES.DISTRIBUTE_EXCESS_AVAIL
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.UPDATE_SHIPPING_METHOD,
		value: DROPDOWN_VALUES.UPDATE_SHIPPING_METHOD
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.LOCK_RECORDS,
		value: DROPDOWN_VALUES.LOCK_RECORDS
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.UNLOCK_RECORDS,
		value: DROPDOWN_VALUES.UNLOCK_RECORDS
	}
];

export const DROPDOWN_MENU_ORDER_ITEMS = [
	{
		displayName: DROPDOWN_DISPLAY_NAMES.CUSTOMIZE_VIEW,
		value: DROPDOWN_VALUES.CUSTOMIZE_VIEW
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.RESET_VIEW,
		value: DROPDOWN_VALUES.RESET_VIEW
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.BULK_ORDER_QTY_EDIT,
		value: DROPDOWN_VALUES.BULK_ORDER_QTY_EDIT
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.LOCK_RECORDS,
		value: DROPDOWN_VALUES.LOCK_RECORDS
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.UNLOCK_RECORDS,
		value: DROPDOWN_VALUES.UNLOCK_RECORDS
	}
];

export const DROPDOWN_MENU_ORDER_AVAILABILITY = [
	{
		displayName: DROPDOWN_DISPLAY_NAMES.CUSTOMIZE_VIEW,
		value: DROPDOWN_VALUES.CUSTOMIZE_VIEW
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.RESET_VIEW,
		value: DROPDOWN_VALUES.RESET_VIEW
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.UPLOAD_DATA,
		value: DROPDOWN_VALUES.UPLOAD_DATA
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.CREATE_KIT,
		value: DROPDOWN_VALUES.CREATE_KIT
	}
];

export const MODAL_COLUMNS = {
	ORDER_CLONE: [31],
	SPLIT_COLUMNS: [35],
	DISTRIBUTE: [36, 38],
	OPTIMIZE: [37, 38]
};

export const KEYS = {
	23: 'OrderID',
	30: 'OrderKitID',
	1: 'OrderDetailID',
	2: 'OrderDetailVarietyID'
};

export const DROPDOWN_MENU_ALL_ORDERS_COMPLETED = [
	{
		displayName: DROPDOWN_DISPLAY_NAMES.CUSTOMIZE_VIEW,
		value: DROPDOWN_VALUES.CUSTOMIZE_VIEW
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.RESET_VIEW,
		value: DROPDOWN_VALUES.RESET_VIEW
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.UPDATE_ORDER_STATUS,
		value: DROPDOWN_VALUES.UPDATE_ORDER_STATUS
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.PUBISH_ORDERS,
		value: DROPDOWN_VALUES.PUBISH_ORDERS
	}
];

export const DROPDOWN_MENU_ORDER_KITS = [
	{
		displayName: DROPDOWN_DISPLAY_NAMES.CUSTOMIZE_VIEW,
		value: DROPDOWN_VALUES.CUSTOMIZE_VIEW
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.RESET_VIEW,
		value: DROPDOWN_VALUES.RESET_VIEW
	},

	{
		displayName: DROPDOWN_DISPLAY_NAMES.DELETE_ORDERS,
		value: DROPDOWN_VALUES.DELETE_ORDERS
	},

	{
		displayName: DROPDOWN_DISPLAY_NAMES.LOCK_RECORDS,
		value: DROPDOWN_VALUES.LOCK_RECORDS
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.UNLOCK_RECORDS,
		value: DROPDOWN_VALUES.UNLOCK_RECORDS
	}
];
