// @ts-nocheck
import _ from 'lodash';
import * as constants from './constants';
import { initialState } from './reducer';
import { TTransactionReducer, TOrderData } from './types';

const stateData = (state: any): TTransactionReducer => state[constants.MODAL_NAME] || initialState;

export const isOpen = (state: any): boolean => {
	const data = stateData(state);

	return data.isOpen;
};

export const getPlanogramTypes = (state: any): boolean => {
	const data = stateData(state);

	return data.planogramTypes;
};


export const isLoading = (state: any, eventName: string): boolean => {
	const data = stateData(state);

	return data.isLoading[eventName];
};

export const getData = (state: any): TOrderData => {
	const reducer = stateData(state);

	return reducer.data;
};

export const getActiveTabs = state => {
	const useReserveInventory = stateData(state).data.useReserveInventory;

	if (useReserveInventory) {
		return constants.STEPS;
	}

	return _.reject(constants.STEPS, ({ key }) => key === 'RESERVE_INVENTORY');
};

export const hasReserverInventory = state => {
	return stateData(state).data.useReserveInventory;
};

export const getCustomerOptions = (state: any): any[] => {
	const reducer = stateData(state);
	return (
		_.map(reducer.customers, customer => ({
			displayName: customer.CustomerName,
			value: customer.CustomerID
		})) || []
	);
};

export const getParameterList = (state: any): any[] => {
	const reducer = stateData(state);
	return reducer.parametersList;
};

export const getShippingMethodsOptions = (state: any): any[] => {
	const reducer = stateData(state);
	return (
		_.map(reducer.shippingMethods, method => ({
			displayName: method.ShipMethodDescription,
			value: method.ShipMethodID
		})) || []
	);
};

export const getWarehouseOptions = (state: any): any[] => {
	const reducer = stateData(state);
	return (
		_.map(reducer.warehouses, warehouse => ({
			displayName: warehouse.Warehouse,
			value: warehouse.WarehouseID
		})) || []
	);
};

export const getStoreScheduleOptions = (state: any): any[] => {
	const reducer = stateData(state);
	return (
		_.map(reducer.storeSchedules, storeSchedule => ({
			displayName: storeSchedule.StoreScheduleDescription,
			value: storeSchedule.StoreScheduleID
		})) || []
	);
};

export const getError = (state: any): any => {
	const data = stateData(state);

	return data.error;
};
