// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import { MODAL_NAME } from './constants';
import { TTransactionReducer } from './types';

export const initialState: TTransactionReducer = {
	data: {
		useStoreSchedule: false,
		isKitOrder: false,
		shipStartDate: '',
		shipDateRange: null,
		PODate: '',
		customerID: null,
		shipMethodID: 0,
		warehouseID: 0,
		orderBatchMemo: '',
		PlanogramParameters: {
			PlanogramTypeID: 1
		},
		isPrimaryWarehouse: false
	},
	isOpen: false,
	customers: [],
	shippingMethods: [],
	warehouses: [],
	parametersList: [],
	planogramTypes: [],
	storeSchedules: [],
	isLoading: {},
	error: {
		hasError: false,
		message: ''
	}
};

const slice = createSlice({
	initialState,
	name: MODAL_NAME,
	reducers: {
		setModalView(state, { payload: { isOpen } }) {
			return {
				...state,
				isOpen
			};
		},
		updateModalData(state, { payload: { data } }) {
			return {
				...state,
				data: {
					...state.data,
					...data
				}
			};
		},
		updateParametersList(state, { payload: { parametersList } }) {
			return {
				...state,
				parametersList
			};
		},
		updateCustomerData(state, { payload: { customers } }) {
			return {
				...state,
				customers
			};
		},
		updateShippingMethods(state, { payload: { shippingMethods } }) {
			return {
				...state,
				shippingMethods
			};
		},
		updateWarehouses(state, { payload: { warehouses } }) {
			return {
				...state,
				warehouses
			};
		},
		updateStoreSchedules(state, { payload: { storeSchedules } }) {
			return {
				...state,
				storeSchedules
			};
		},
		updateParameters(state, { payload: { parametersList } }) {
			return {
				...state,
				parametersList
			};
		},
		updatePlanogramTypes(state, { payload: { planogramTypes } }) {
			return {
				...state,
				planogramTypes
			};
		},
		updateLoader(state, { payload: { eventName, isLoading } }) {
			return {
				...state,
				isLoading: {
					...state.isLoading,
					[eventName]: isLoading
				}
			};
		},
		updateError(state, { payload }) {
			return {
				...state,
				error: payload
			};
		},
		cleanUP() {
			return { ...initialState };
		}
	}
});

export default slice;

export const {
	setModalView,
	updateModalData,
	updateCustomerData,
	updateShippingMethods,
	updateWarehouses,
	updateParameters,
	updateStoreSchedules,
	updateLoader,
	updatePlanogramTypes,
	updateError,
	cleanUP
} = slice.actions;
