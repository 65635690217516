// @ts-nocheck
import Button from 'atoms/button/button';
import Icon from 'atoms/icons/icon';
import Sheet from 'atoms/sheet/sheet';
import Tab from 'atoms/tab/tab';
import Tabs from 'atoms/tabs/tabs';
import _ from 'lodash';
import * as advertisingSagaActions from 'models/advertisings/sagaActions';
import * as advertisingSelectors from 'models/advertisings/selector';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import GridActionBar from 'atoms/GridActionBar';


import * as tableColumnSagaActions from 'models/tableColumns/sagaActions';
import * as tableColumnSelectors from 'models/tableColumns/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import ThetaDeleteModal from 'organisms/deleteModal/deleteModal';
import GroupModal from 'organisms/groupModal';
import * as groupModalSagaActions from 'organisms/groupModal/sagaActions';
import * as groupModalSelectors from 'organisms/groupModal/selector';
import * as adModalSelectors from 'pages/advertising/adModal/selector';
import qs from 'qs';
import React from 'react';
import * as groupDataSagaActions from './sagaActions';

import { useEffect } from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ThetaGridPaginated from 'templates/thetaGridPaginated';

import * as constants from './constants';
import ExportGrid from 'organisms/exportGrid';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import Breadcrumbs from 'atoms/breadcrumbs/breadcrumbs';
import { REQUESTS } from 'utils/requests';
import { isMasterDataGroupsDelete, isMasterDataGroupsWrite } from 'models/user/selector';

// import AdModal from './adModal';
// import * as adModalSagaActions from './adModal/sagaActions';
const Groups: React.FunctionComponent = props => {
	const name = 'advertising';
	const [openFilter, setOpenFilter] = React.useState<boolean>();
	const [isUploadEnabled, toggleUpload] = React.useState<boolean>(false);
	const [selectedGroupTab, setSelectedGroupTab] = React.useState<any>(null);
	const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
	const [selectedGroup, setSelectedGroup] = React.useState<string>({});
	const hasGroupsDelete = useSelector(isMasterDataGroupsDelete);
	const hasGroupsWrite = useSelector(isMasterDataGroupsWrite);
	const tableId = constants.TABLE_ID.toString();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const adModalOpen = useSelector(state => adModalSelectors.getAdModalState(state, name));
	const hasSearchFilters = useSelector(state => dynamicGridSelectors.hasSearchFilters(state, constants.TABLE_ID));
	const customers = useSelector(state => advertisingSelectors.getCustomers(state, name));
	const isCustomizeViewModalEnabled = useSelector(state => tableColumnSelectors.isCustomizeViewModalEnabled(state));
	const location = useLocation<LocationState>();

	const query: any = qs.parse(location.search.replace('?', ''));
	const [refreshTimestamp, updateRefreshTimeStamp] = React.useState(Date.now());

	const createUrl = (selectedTab: string) => {
		return `/masterdata/groups?selectedTab=${selectedTab}`;
	};

	React.useEffect(() => {
		if (!query || !query.selectedTab) {
			return navigate(createUrl(constants.TABS_IDENTIFIER.STORES));
		}
		setSelectedGroupTab(query.selectedTab);
	}, [query]);

	const hideAdModal = () => {
		// dispatch(adModalSagaActions.updateModalToggle(name, false));
	};

	const toggleAdModal = (status: boolean) => {
		// dispatch(adModalSagaActions.updateModalToggle(name, status));
	};

	React.useEffect(() => {
		if (openFilter === undefined) {
			setOpenFilter(hasSearchFilters);
		}
	}, [hasSearchFilters]);

	const handleFilterClick = React.useCallback(() => {
		setOpenFilter(!openFilter);
	}, [openFilter]);

	const handleRefreshClick = React.useCallback(() => {
		dispatch(
			dynamicGridSagaActions.refreshGridData({
				tableId: constants.TABLE_ID,
			})
		);
	}, [tableId, dispatch]);

	const handleResetTableColumns = React.useCallback(async () => {
			dispatch(tableColumnSagaActions.resetTableColumnsAg(constants.TABLE_ID));
	}, [dispatch, tableId]);

	const customizeViewHandler = React.useCallback((isCustomizeViewModalEnabled: boolean) => {
		dispatch(tableColumnSagaActions.setCustomizeViewModal(isCustomizeViewModalEnabled));
	}, []);

	const hideCustomizeViewModal = React.useCallback(() => {
		customizeViewHandler(false);
	}, []);

	const uploadCloseClickHandler = React.useCallback(() => {
		toggleUpload(false);
	}, []);

	const onSelectGroupTab = (identifier: string) => {
		// setSelectedGroup(identifier)
		navigate(createUrl(identifier));
		updateRefreshTimeStamp(Date.now());

		// return
	};

	const handleDeleteClick = (item: any) => {
		setSelectedGroup(item);
		setDeleteModalOpen(true);
	};

	const hideDeleteModal = () => {
		setDeleteModalOpen(false);
	};

	const handleAdDelete = () => {
		dispatch(advertisingSagaActions.deleteAdvertisings(selectedAd.AdID));
		setDeleteModalOpen(false);
	};

	const gridConfig = {
		enableSelection: false,
		enableSorting: true,
		enableMultiSort: true,
		customActions: (hasGroupsWrite || hasGroupsDelete) && {
			width: 170,
			enabled: true,
			component: ({ row }) => {
				return (
					<Stack gutter="small" align="center">
						{hasGroupsWrite && <StackItem>
							<Button handleClick={() => handleEditClick(row)}>Edit</Button>
						</StackItem>}
						{hasGroupsDelete && <StackItem>
							<Button type="secondary" handleClick={() => handleDeleteClick(row)}>
								Delete
							</Button>
						</StackItem>}
					</Stack>
				);
			}
		},
		selectionCallBack: () => { }
	};

	const templateConfig = useMemo(
		() => ({
			gridConfig,
			tableId: constants.TABLE_ID,
			isFilterEnabled: openFilter === undefined ? false : openFilter,

			refreshTimestamp,
			selectRowKey: 'GroupID',
			dataProviderOptions: {
				type: 'POST',
				version: 2,
				...REQUESTS.POST.GET_GRID_DETAILS_V2,
				pathParams: { tableId: constants.TABLE_ID },
				body: {
					fieldGroups: {
						type: selectedGroupTab
					}
				}
			}
		}),
		[selectedGroupTab, openFilter]
	);

	const groupModalOpen = useSelector(state => groupModalSelectors.getGroupModalState(state, name));

	const hideGroupModal = () => {
		dispatch(groupModalSagaActions.updateModalToggle(false));
	};

	const handleDeleteGroup = () => {
		dispatch(groupDataSagaActions.deleteGroup(selectedGroup.GroupID, [selectedGroup]));
		setDeleteModalOpen(false);
	};

	const handleEditClick = (item: any) => {
		dispatch(groupModalSagaActions.editGroupData(item));
		setSelectedGroup(item);
		handleAddNewClick();
	};

	const handleAddNewClick = () => {
		dispatch(groupModalSagaActions.updateModalToggle(name, true));
	};

	return (
		<React.Fragment>
			<Stack isVertical align="center">
				<StackItem>
					<GridActionBar>
						<Stack>
							<StackItem isGrow>
								<Breadcrumbs crumbs={props.breadcrumbs} />
							</StackItem>
							<StackItem>
								<Stack gutter="medium" position='center'>
									<StackItem isGrow>
										{hasGroupsWrite && <Button handleClick={handleAddNewClick}>Create Group</Button>}
									</StackItem>
									<StackItem>
										<Icon isActive type="secondary" faAlias="faRecycle" onClick={handleRefreshClick} />
									</StackItem>
									<StackItem>
										<ExportGrid tableId={constants.TABLE_ID} />
									</StackItem>
								</Stack>
							</StackItem>
						</Stack>

					</GridActionBar>
				</StackItem>
				<StackItem isStretch isGrow>
					<Sheet isPage>
						<Stack isVertical gutter="medium">

							<StackItem isStretch isGrow>
								<Stack isVertical>
									<StackItem>
										<Tabs>
											{_.map(constants.DEFAULT_TABS, (tab, index) => {
												return (
													<Tab
														key={index}
														value={''}
														isSelected={
															selectedGroupTab?.toLowerCase() === tab?.identifier?.toLowerCase()
														}
														onSelectTabItem={() => {
															onSelectGroupTab(tab.identifier);
														}}
													>
														{tab.title}
													</Tab>
												);
											})}
										</Tabs>
									</StackItem>
									<StackItem isGrow isStretch>
										<SheetBody>
											{selectedGroupTab && (
												<ThetaGridPaginated
													refreshTimestamp={refreshTimestamp}
													key={selectedGroupTab}
													config={templateConfig}
												/>
											)}
										</SheetBody>
									</StackItem>
								</Stack>
							</StackItem>
						</Stack>
					</Sheet>
				</StackItem>
			</Stack>

			{groupModalOpen && <GroupModal name={name} hideModal={hideGroupModal} />}
			{deleteModalOpen && (
				<ThetaDeleteModal
					hideModal={hideDeleteModal}
					modalTitle={'Group'}
					title={selectedGroup.GroupI}
					handleDeleteClick={handleDeleteGroup}
				/>
			)}
		</React.Fragment>
	);
};
export default Groups;

