// @ts-nocheck

import { createAction } from '@reduxjs/toolkit';
import { takeLatest } from 'redux-saga/effects';

export const loadMultiSelectOptions = createAction(
	'multiSelectDropdown/loadOptions',
	function prepare({ page, pageSize, componentId, isAppend, selectionProviderOptions, columnName, search, columnLabelName }) {
		return {
			payload: { page, pageSize, componentId, isAppend, selectionProviderOptions, columnName, search, columnLabelName }
		};
	}
);
