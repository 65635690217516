// @ts-nocheck

import { isAclDataRestrictionsReadWriteEnabled, isAclRolesReadWriteEnabled, isAclUsersReadWriteEnabled } from 'models/user/selector';
export const getEnabledOptions = (state) => {
    return [
        {
            title: 'App Column Aliases',
            description: 'Create or edit App Column Aliases',
            buttonText: 'View',
            url: '/settings/columnaliases',
            isEnabled: true,
        },
        {
            title: 'User Roles',
            description: 'User Roles',
            buttonText: 'View',
            isEnabled: isAclRolesReadWriteEnabled(state),
            url: '/settings/roles'
        },
        {
            title: 'App Users',
            description: 'App Users',
            buttonText: 'View',
            isEnabled: isAclUsersReadWriteEnabled(state),
            url: '/settings/users'
		
        },
        {
            title: 'App Data Restrictions',
            description: 'App Data Restrictions',
            buttonText: 'View',
            isEnabled: isAclDataRestrictionsReadWriteEnabled(state),
            url: '/settings/restrictions'
        }
    ].filter(x => x.isEnabled)
};
