// @ts-nocheck

import Button from 'atoms/button/button';
import Icon from 'atoms/icons/icon';
import { createFiltersFromQueries } from 'models/dynamicGrid/query';
import { handleRowDelete } from 'models/dynamicGrid/sagaActions';
import { getGridMetadata, getTableSelection } from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getDispatch, getState } from 'reducers';
const defaultActionHandler = props => {
	let row = props.data;
	const dispatch = getDispatch();
	const handleDelete = () => {
		const metaData = getGridMetadata(getState(), props.row.AppTableID);
		const selectedRowKeys: any = getTableSelection(getState(), props.row.AppTableID.toString());
		dispatch(
			handleRowDelete({
				tableId: props.row.AppTableID,
				rowIdentifier: row[metaData.selectRowKey],
				deletedRows: [row],
				isUploadGrid: props?.gridConfig?.isUploadGrid,
				deleteActionFieldGroups: metaData?.dataProviderOptions?.body?.deleteActionFieldGroups,
				// isSelectAll: selectedRowKeys?.isSelectAll,
				// selectAllSearch: JSON.stringify({
				// 	filter: createFiltersFromQueries(selectedRowKeys?.search)
				// })
			})
		);
	};
	if (props.node.leafGroup) {
		return null;
	}
	return (
		<Stack gutter="medium" align="center">
			<StackItem position="center" align="center">
				<Icon
					size="small"
					disabled={row?.IsReadOnly}
					onClick={() => handleDelete()}
					isActive={row?.IsReadOnly ? false : true}
					faAlias={'faTrash'}
				></Icon>
			</StackItem>
		</Stack>
	);
};

function GridActionHandler(props) {
	let Component = props?.customActions?.component ?? defaultActionHandler;
	return (
		<>
			{/* Render the cell value here */}
			{Component && <Component {...props} row={props.data}></Component>}
		</>
	);
}

export default GridActionHandler;
