// @ts-nocheck
import { updateWorkspace } from 'models/user/reducer';
import * as React from 'react';
import { useDispatch } from 'react-redux';

function Workspace(props) {
	const dispatch = useDispatch();
	React.useEffect(() => {
		// dispatch(actions);
		dispatch(updateWorkspace({ workspaceProps: props?.componentProps }));
	}, [dispatch, props?.componentProps]);

	return props.children;
}

export default Workspace;
