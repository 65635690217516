// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const fetchUserData = createAction('fetch/user');

export const checkDbHealth = createAction('fetch/healthcheckDb');

export const fetchTenentList = createAction('fetch/tenentList', ({ email }) => ({
	payload: {
		email
	}
}));

export const updateTenent = createAction('update/tenant', ({ email, partnerId}) => ({
	payload: {
		email, partnerId
	}
}));