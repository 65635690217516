// @ts-nocheck
import _, { uniqueId } from 'lodash';
import * as React from 'react';
import './dropdown.scss';
import classNames from 'classnames';
import DropDownItem from './dropdownItem';
import { DownOutlined } from '@ant-design/icons';
import { useOutsideAlerter } from 'utils/helpers';
import Icon from 'atoms/icons/icon';
import { AutoComplete, Input, Select, Tag, Dropdown, Space, Menu, Button } from 'antd';
import Loader from 'atoms/loader';
const { Option } = Select;

export type Option = { displayName: string; value: string };

interface Props extends React.PropsWithChildren<any> {
	options: ReadonlyArray<Option>;
	placeholder: string;
	updateHandler: Function;
	selectedOption?: Option;
	isAutocomplete?: boolean;
}

const tagRender = (props: CustomTagProps) => {
	const { label, value, closable, onClose } = props;
	const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
		event.preventDefault();
		event.stopPropagation();
	};
	return (
		<Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
			{label}
		</Tag>
	);
};

const DropdownInner: React.FunctionComponent<any> = (props: Props) => {
	const { options, placeholder, updateHandler, selectedOption, isAutocomplete, isMultiselect, defaultValue,
		 isFullWidth, disabled, isLoading = false} = props;

	const [isItemsEnabled, toggleItems] = React.useState(false);
	const classes: any = classNames(['dropdown']);
	const autocompleteClasses: any = classNames({'autocomplete_dropdown': true, 'autocomplete_dropdown--full-width' : isFullWidth});
	const showDropdownItems = React.useCallback(() => {
		toggleItems(true);
	}, [toggleItems]);
	const wrapperRef = React.useRef(null);
	const [selectedItem, setSelectedItem] = React.useState<any>(null);
	const [value, setInputValue] = React.useState(selectedItem?.label);
	React.useEffect(() => {
		if (selectedOption) {
			setSelectedItem(selectedOption);
			setInputValue(selectedOption.label);
		} else {
			setInputValue('');
			setSelectedItem(null);
		}
	}, [selectedOption]);

	useOutsideAlerter(wrapperRef, () => {
		toggleItems(false);
	});

	const handleSelectDropdownItem = React.useCallback(
		item => {
			setSelectedItem(item);
			toggleItems(false);
			updateHandler(item);
		},
		[toggleItems, updateHandler, setSelectedItem]
	);

	let updatedOptions = options?.map(item => {
		return {
			label: item?.displayName,
			value: item?.value
		};
	});

	const getSelectedItemDisplayName = React.useCallback(() => {
		if (!selectedItem) {
			return placeholder;
		}
		return selectedItem.displayName;
	}, [selectedItem, placeholder]);

	// React.useEffect(() => {
	// 	selectedItem?.label && setInputValue(selectedItem.label);
	// }, [selectedItem, placeholder]);

	const handleSearch = value => {
		setInputValue(value);
	};
	if (isMultiselect) {
		return (
			<Select
				filterOption="false"
				mode="multiple"
				placeholder={placeholder}
				tagRender={tagRender}
				filterOption={(a, options) => {
					return options.label.match(new RegExp(a, 'i'));
				}}
				value={selectedOption || []}
				defaultValue={selectedOption || []}
				onChange={handleSelectDropdownItem}
				onMouseDown={e => e.stopPropagation()}
				options={updatedOptions}
			></Select>
		);
	}

	if (isAutocomplete) {
	
		return (
			<div className={autocompleteClasses}>
				<AutoComplete disabled={disabled}
					data-test-id="dropdown-autocomplete"
					options={updatedOptions}
					// options={updatedOptions.filter(o => !value || o.label.match(value))}
					placeholder={placeholder}
					// filterOption={(inputValue, option) => {
					// 	return value === selectedOption?.displayName
					// 		? updatedOptions.filter(o => !selectedOption?.displayName || o.label === selectedOption?.displayName)
					// 		: option?.label?.toUpperCase().indexOf(value?.toUpperCase()) !== -1;
					// }}
					onSelect={(value, option) => {
						handleSelectDropdownItem({
							..._.find(options, (o, i) => o?.displayName === option?.label),
							...{ label: option.label }
						});
					}}
					onSearch={value => handleSearch(value)}
					value={value}
					defaultValue={defaultValue}
				>
					<Input
						suffix={
							<span className="dropdown_icon">
								{
									isLoading ? <Icon isActive faAlias="faSpinner" /> :
									<Icon faAlias="faCaretDown" /> 
								}
							</span>
						}
						placeholder={placeholder}
					/>
				</AutoComplete>
			</div>
		);
	}

	return (
		<Select
			value={selectedOption?.displayName}
			style={{ width: '100%', height: '2rem', minWidth: '10rem' }}
			onChange={value => {
				let item = _.find(options, o => o?.value.toString() === value.toString());

				handleSelectDropdownItem(item);
			}}
			onMouseDown={e => e.stopPropagation()}
			placeholder="Select a Value"
		>
			{_.map(options, option => (
				<Option key={option.value}>{option.displayName}</Option>
			))}
		</Select>
	);
};

Dropdown.defaultProps = {
	placeholder: 'Select a Value',
	updateHandler: () => {}
};

export default DropdownInner;
