// @ts-nocheck
import Button from 'atoms/button/button';
import Heading from 'atoms/heading/heading';
import Loader from 'atoms/loader';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import _ from 'lodash';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as groupModalSagaActions from 'organisms/groupModal/sagaActions';
import * as groupModalSelectors from 'organisms/groupModal/selector';
import { TABS_IDENTIFIER } from 'pages/masterData/groups/constants';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTableId } from './constants';
import { Tprops } from './types';

const ReviewGroup = (props: Tprops) => {
	const { hideModal, handleNextClick, goBack } = props;
	const dispatch = useDispatch();
	const groupData: any = useSelector(state => groupModalSelectors.getGroupModalData(state)) || {};

	const selectedMembers = useSelector(state =>
		dynamicGridSelectors.getTableSelection(state, getTableId(groupData.GroupType))
	);

	const isEditMode: boolean = useSelector(state => groupModalSelectors.getEditMode(state)) || false;

	const editData: any = useSelector(state => groupModalSelectors.getEditData(state)) || {};

	const isLoading = useSelector(state => groupModalSelectors.isLoading(state));

	const getGroupTypeID = () => {
		switch (groupData.GroupType) {
			case TABS_IDENTIFIER.SKUS:
				return '3';
			case TABS_IDENTIFIER.CUSTOMERS:
				return '2';
			case TABS_IDENTIFIER.STORES:
			default:
				return '1';
		}
	};

	const handleSaveAdClick = async (e: any) => {
		let data: any = {
			GroupDescription: groupData.GroupDescription,
			GroupID: null,
			GroupTitle: groupData.GroupTitle,
			GroupTypeID: getGroupTypeID(), //TODO:to be confirmed what is this
			MemberIDList:
				selectedMembers &&
				selectedMembers?.selectedRowKeys &&
				selectedMembers?.selectedRowKeys.length &&
				selectedMembers?.selectedRowKeys.join(',')
		};
		if (groupData.GroupType !== TABS_IDENTIFIER.CUSTOMERS) {
			data.CustomerID = groupData.CustomerID;
		}
		if (isEditMode) {
			data.GroupID = editData.GroupID;
			dispatch(groupModalSagaActions.editData(data));
		} else {
			dispatch(groupModalSagaActions.saveData(data));
		}
	};
	const renderLoader = () => {
		return (
			<StackItem isGrow isStretch align="center">
				<Loader />
			</StackItem>
		);
	};

	return (
		<>
			<ModalBody>
				{isLoading ? (
					renderLoader()
				) : (
					<Stack isVertical gutter="medium">
						<StackItem isStretch isGrow>
							<Stack isVertical gutter="medium">
								<StackItem isStretch>
									<Heading type="secondary">{groupData.GroupTitle}</Heading>
								</StackItem>
								<StackItem isStretch>
									<Heading type="secondary">
										{' '}
										This group contains {selectedMembers?.selectedRowKeys?.length}{' '}
										{groupData.GroupType}
									</Heading>
								</StackItem>
							</Stack>
						</StackItem>
					</Stack>
				)}
			</ModalBody>
			<ModalActions>
				<Stack gutter="medium">
					<StackItem isStretch isGrow>
						<Stack align="left" gutter="small">
							<StackItem>
								<Button
									disabled={isLoading}
									isSecondary
									name="RE-SELECT MEMBERS"
									handleClick={goBack}
								/>
							</StackItem>
						</Stack>
					</StackItem>
					<StackItem>
						<Stack align="right" gutter="small">
							<StackItem>
								<Button disabled={isLoading} isSecondary name="CANCEL GROUP" handleClick={hideModal} />
							</StackItem>
							<StackItem isStretch align="right">
								<Button disabled={isLoading} name={'SAVE GROUP'} handleClick={handleSaveAdClick} />
							</StackItem>
						</Stack>
					</StackItem>
				</Stack>
			</ModalActions>
		</>
	);
};

export default ReviewGroup;
