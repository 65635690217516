// @ts-nocheck

let subscribers = {};

function publish(event, data) {
	if (!subscribers[event]) return;
	subscribers[event].forEach(subscriberCallback => subscriberCallback(data));
}

function subscribe(event, callback) {
	if (!subscribers[event]) {
		subscribers[event] = [];
	}
	subscribers[event].push(callback);
}

export const Events = {
	publish,
	subscribe
};
