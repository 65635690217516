// @ts-nocheck
export const FILE_TEMPLATES = {
	7: {
		name: 'Availability.csv',
		data: [
			{
				Warehouse: '',
				Customer: '',
				Kit: '',
				SKU: '',
				Item: '',
				MultiplePerShipMethodOverride: '',
				UOM: '',
				UOMConversion: '',
				AvailableQty: '',
				Priority: ''
			}
		]
	},
	56: {
		name: 'Demand Planning.csv',
		data: [
			{
				ItemPlanningGroup: '',
				CustomerPlanningGroup: '',
				TimeplanningGroup: '',
				DemandFlag: '',
				Year: '',
				OverrideQty: ''
			}
		]
	},
	47: {
		name: 'PrimaryStoreSKU.csv',
		data: [
			{
				Customer: '',
				PrimaryStatus: '',
				Store: '',
				SKU: '',
				StartDate: '',
				EndDate: '',
				UserEmail: ''
			}
		]
	},
	25: {
		name: 'Availability.csv',
		data: [
			{
				Warehouse: '',
				Customer: '',
				Kit: '',
				SKU: '',
				Item: '',
				AvailableQty: '',
				Priority: ''
			}
		]
	},
	26: {
		name: 'Store-Order-List.csv',
		data: [
			{
				Customer: '',
				Store: ''
			}
		]
	},
	27: {
		name: 'Inventory-transactions.csv',
		data: [
			{
				TransactionDate: '',
				Customer: '',
				Store: '',
				SKU: '',
				Item: '',
				ReasonCode: '',
				AdjustmentQty: '',
				AdjustmentCost: '',
				AdjustmentRetail: ''
			}
		]
	},
	28: {
		name: 'retail-shipments-list.csv',
		data: [
			{
				ShipDate: '',
				PODate: '',
				Customer: '',
				Store: '',
				SKU: '',
				Item: '',
				ShipQty: '',
				ShipCost: ''
			}
		]
	},
	20: {
		name: 'Stores.csv',
		data: [
			{
				Customer: '',
				Store: '',
				StoreDescription: '',
				Address: '',
				City: '',
				State: '',
				PostalCode: '',
				Country: '',
				Latitude: '',
				Longitude: '',
				VolumeClass: '',
				Attribute1: '',
				Attribute2: '',
				Attribute3: '',
				Attribute4: '',
				Attribute5: '',
				Attribute6: '',
				Attribute7: '',
				Attribute8: '',
				Attribute9: '',
				Attribute10: '',
				ERPStoreID: ''
			}
		]
	},
	15: {
		name: 'StoreSkuCost.csv',
		data: [
			{
				Customer: '',
				Store: '',
				SKU: '',
				StartDate: '',
				EndDate: '',
				Cost: '',
				Retail: ''
			}
		]
	},
	18: {
		name: 'SKU.csv',
		data: [
			{
				Customer: '',
				SKU: '',
				SKUDescription: '',
				Genus: '',
				Size: '',
				Brand: '',
				Attribute1: '',
				Attribute2: '',
				Attribute3: '',
				Attribute4: '',
				Attribute5: '',
				ERPSKUID: ''
			}
		]
	},
	13: {
		name: 'Item.csv',
		data: [
			{
				Item: '',
				ItemDescription: '',
				Genus: '',
				Series: '',
				Variety: '',
				Attribute1: '',
				Attribute2: '',
				Attribute3: '',
				Attribute4: '',
				Attribute5: '',
				Attribute6: '',
				Attribute7: '',
				Attribute8: '',
				Attribute9: '',
				Attribute10: '',
				ERPItemID: ''
			}
		]
	},
	17: {
		name: 'SkuShipmentConfiguration.csv',
		data: [
			{
				ShipMethodDescription: '',
				SKU: '',
				IsFullShelf: '',
				QtyPerShelf: '',
				ShelvesPerRack: ''
			}
		]
	},
	12: {
		name: 'ShipMethodItemConfiguration.csv',
		data: [
			{
				ShipMethodDescription: '',
				Item: '',
				IsFullShelf: '',
				QtyPerShelf: '',
				ShelvesPerRack: ''
			}
		]
	},
	22: {
		name: 'ValidStoreSKU.csv',
		data: [
			{
				Customer: '',
				Store: '',
				SKU: ''
			}
		]
	},
	16: {
		name: 'ExcludeStoreSKU.csv',
		data: [
			{
				Customer: '',
				Store: '',
				SKU: '',
				ExcludeStoreSKUType: ''
			}
		]
	},
	21: {
		name: 'SkuItemMapping.csv',
		data: [
			{
				Customer: '',
				SKU: '',
				Item: ''
			}
		]
	},
	11: {
		name: 'ExcludeStoreItem.csv',
		data: [
			{
				Customer: '',
				Store: '',
				Item: ''
			}
		]
	},
	19: {
		name: 'ShipMethod.csv',
		data: [
			{
				ShipMethodType: '',
				ShipMethodDescription: ''
			}
		]
	},
	29: {
		name: 'Kit',
		data: [
			{
				Customer: '',
				Kit: '',
				KitDescription: '',
				SKU: '',
				ShipMethodDescription: '',
				SKUQty: ''
			}
		]
	},
	44: {
		name: 'Advertising',
		data: [
			{
				AdType: '',
				Customer: '',
				AdTitle: '',
				AdDescription: '',
				StartDate: '',
				EndDate: '',
				LoadDate: '',
				UseStoreInventory: '',
				UseAssortedSKU: '',
				Store: '',
				SKU: '',
				MinOrderQty: '',
				AdCost: ''
			}
		]
	}
};

export const UPLOAD_TYPE = {
	ERP: 'erp',
	CSV: 'csv'
};

export const PAGES = {
	UPLOAD: 'upload',
	UPLOAD_GRID: 'upload-grid'
};

export const UPLOAD_DATA_CHANGE_TYPES = [
	{ displayName: 'Full', value: false },
	{ displayName: 'Augment', value: true }
];
