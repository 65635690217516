// @ts-nocheck
import BodyText from 'atoms/bodyText/bodyText';
import Button from 'atoms/button/button';
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import { Tab } from 'atoms/tab/tab';
import Tabs from 'atoms/tabs/tabs';
import _ from 'lodash';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as sagaActions from '../sagaActions';
import * as selectors from '../selectors';
import SelectParameters from './selectParameters';
import SelectStores from './selectOrderDetails';
import { SELECT_ORDER_DETAILS_TABLE_ID } from './constants';
import Loader from 'atoms/loader';
import ModalHeader from 'atoms/modal/modalHeader';
import ReactModal from 'atoms/modal/reactModal';
import ModalBody from 'atoms/modal/modalBody';
import ModalActions from 'atoms/modal/modalActions';
import { formatDate } from 'utils/utilities';
interface TProps {
	hideModal: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
	selectedTab: string;
}

const SPLIT_ODRER_STEPS = {
	SELECT_PARAMETERS: 'selectParameters',
	SELECT_ORDER_DETAILS: 'selectOrderDetails',
	REVIEW_SPLIT: 'reviewSplit'
};

const steps = [
	{
		title: 'Select Parameters',
		identifier: SPLIT_ODRER_STEPS.SELECT_PARAMETERS
	},
	{
		title: 'Select Order Details',
		identifier: SPLIT_ODRER_STEPS.SELECT_ORDER_DETAILS
	},
	{
		title: 'Review Split',
		identifier: SPLIT_ODRER_STEPS.REVIEW_SPLIT
	}
];

const getDisabledStatus = (orderSplitInfo: any, identifier: string) => {
	return (
		!orderSplitInfo?.selectedParameters?.orderSplitType ||
		!orderSplitInfo?.selectedParameters?.shipDate ||
		!orderSplitInfo?.selectedParameters?.poDate
	);
};

const SplitModalHeader = ({
	selectedStep,
	setSelectedStep,
	selectedOrders,
	steps,
	isMultipleSplit,
	orderSplitInfo,
	isLoading
}: any) => {
	return (
		<Stack isVertical gutter="small">
			<StackItem isStretch>
				<Heading>{isMultipleSplit ? 'Split Orders' : 'Split Order'}</Heading>
			</StackItem>
			<StackItem isStretch>
				<Divider />
			</StackItem>
			<StackItem isStretch>
				<Tabs type="secondary">
					{_.map(steps, (step, index) => {
						return (
							<Tab
								key={index}
								value={index}
								isSelected={selectedStep.identifier === step.identifier}
								onSelectTabItem={() => setSelectedStep(step)}
								disabled={getDisabledStatus(orderSplitInfo, step.identifier) || isLoading}
							>
								{step.title}
							</Tab>
						);
					})}
				</Tabs>
			</StackItem>
		</Stack>
	);
};

const SplitModalContent = ({
	selectedStep,
	setSelectedStep,
	hideModal,
	selectedOrders,
	orderSplitInfo,
	selectedSplitType,
	dropdownTypes,
	parent,
	orderSplitId,
	tableId
}: any) => {
	switch (selectedStep.identifier) {
		case SPLIT_ODRER_STEPS.SELECT_ORDER_DETAILS:
			return <SelectStores orderSplitId={orderSplitId} parent={parent} orderSplitInfo={orderSplitInfo} tableId={tableId} />;
		case SPLIT_ODRER_STEPS.REVIEW_SPLIT:
			return (
				<Stack isVertical gutter="medium">
					<StackItem>
						<BodyText>
							You are splitting {selectedOrders.length} orders using {selectedSplitType?.[0]?.displayName}.
						</BodyText>
					</StackItem>
					<StackItem>
						<BodyText>
							Your new Ship Date is {formatDate(orderSplitInfo?.selectedParameters?.shipDate)}.
						</BodyText>
					</StackItem>
					<StackItem>
						<BodyText>
							Your new P.O. Date is {formatDate(orderSplitInfo?.selectedParameters?.poDate)}.
						</BodyText>
					</StackItem>
				</Stack>
			);
		case SPLIT_ODRER_STEPS.SELECT_PARAMETERS:
		default:
			return <SelectParameters dropdownTypes={dropdownTypes} />;
	}
};

const SplitModalActions = ({
	selectedStep,
	setSelectedStep,
	hideModal,
	splitOrder,
	steps,
	isMultipleSplit,
	orderSplitInfo,
	selectedStores,
	isLoading
}: any) => {
	const handleReviewOrderClick = () => {
		switch (selectedStep.identifier) {
			case SPLIT_ODRER_STEPS.SELECT_ORDER_DETAILS:
				return setSelectedStep(steps[2]);
			case SPLIT_ODRER_STEPS.REVIEW_SPLIT:
				return splitOrder();
			case SPLIT_ODRER_STEPS.SELECT_PARAMETERS:
			default:
				return setSelectedStep(steps[1]);
		}
	};
	const checkIfDisabled = () => {
		return (
			!orderSplitInfo?.selectedParameters?.orderSplitType ||
			!orderSplitInfo?.selectedParameters?.shipDate ||
			!orderSplitInfo?.selectedParameters?.poDate ||
			isLoading
		);
	};
	return (
		<Stack gutter="small" align="right">
			<StackItem>
				<Button isSecondary name="Cancel Order Split" handleClick={hideModal} disabled={isLoading} />
			</StackItem>
			<StackItem>
				<Button
					name="Review Order Split"
					handleClick={handleReviewOrderClick}
					disabled={checkIfDisabled() || isLoading}
				/>
			</StackItem>
		</Stack>
	);
};

const ThetaOrderSplitModal = (props: TProps) => {
	const { hideModal, selectedTab } = props;
	const dispatch = useDispatch();
	const [ref, handleUpdateRef] = React.useState(null);

	const selectedOrders = useSelector(state => dynamicGridSelectors.getTableSelection(state, Number(selectedTab)));

	const [selectedStep, setSelectedStep] = React.useState<any>(steps[0]);
	React.useEffect(() => {
		dispatch(sagaActions.getSplitDefaultColumnTypes());
	}, [dispatch]);

	const orderSplitInfo = useSelector(state => selectors.getSplitOrderInfo(state));

	const selectedStores = useSelector(state =>
		dynamicGridSelectors.getTableSelection(state, SELECT_ORDER_DETAILS_TABLE_ID)
	);

	const data = useSelector(state => dynamicGridSelectors.getFilteredGirdData(state, SELECT_ORDER_DETAILS_TABLE_ID));

	const splitOrder = (e: any) => {
		const reqData = {
			OrderSplitID: data[0]?.OrderSplitID
		};
		dispatch(sagaActions.splitOrders({ tableId: selectedTab, data: reqData }));
	};
	const dropdownOptionTypes = useSelector(state => selectors.getDefaultSplitTypes(state)) || [];
	const dropdownTypes = dropdownOptionTypes.map(order => {
		return { displayName: order.CloneOrderType, value: order.CloneOrderTypeID };
	});
	const selectedOption = dropdownTypes.filter(
		option => option.value === orderSplitInfo?.selectedParameters?.orderSplitType
	);
	const isLoading = useSelector(state => selectors.getLoadingStatus(state));

	return (
		<ReactModal hideModal={hideModal} height={700} width={700}>
			<ModalHeader>
				<SplitModalHeader
					selectedStep={selectedStep}
					setSelectedStep={setSelectedStep}
					selectedOrders={selectedOrders?.selectedRowKeys}
					steps={steps}
					orderSplitInfo={orderSplitInfo}
					isLoading={isLoading}
				/>
			</ModalHeader>
			<ModalBody onDomRef={handleUpdateRef}>
				{isLoading ? (
					<Stack isVertical>
						<StackItem isGrow isStretch align="center">
							<Loader />
						</StackItem>
					</Stack>
				) : (
					<SplitModalContent
						selectedStep={selectedStep}
						setSelectedStep={setSelectedStep}
						hideModal={hideModal}
						selectedOrders={selectedOrders?.selectedRowKeys}
						orderSplitInfo={orderSplitInfo}
						orderSplitId={ data[0]?.OrderSplitID}
						steps={steps}
						selectedSplitType={selectedOption}
						dropdownTypes={dropdownTypes}
						parent={ref}
						tableId={selectedTab}
					/>
				)}
			</ModalBody>
			<ModalActions>
				<SplitModalActions
					selectedStep={selectedStep}
					setSelectedStep={setSelectedStep}
					hideModal={hideModal}
					splitOrder={splitOrder}
					steps={steps}
					selectedStores={selectedStores}
					orderSplitInfo={orderSplitInfo}
					isLoading={isLoading}
				/>
			</ModalActions>
		</ReactModal>
	);
};

export default ThetaOrderSplitModal;
