export const TABLE_ID = 29;

export const STORE_PREFIX = 'skus';

export const MENU_ITEMS_NAMES = {
	CUSTOMIZE_VIEW: 'Customize View',
	RESET_VIEW: 'Reset View',
	UPLOAD_DATA: 'Upload Data'
};

export const MENU_ITEMS_VALUES = {
	CUSTOMIZE_VIEW: 'CUSTOMIZE_VIEW',
	RESET_VIEW: 'RESET_VIEW',
	UPLOAD_DATA: 'UPLOAD_DATA'
};

export const MENU_ITEMS = [

	{
		displayName: MENU_ITEMS_NAMES.RESET_VIEW,
		value: MENU_ITEMS_VALUES.RESET_VIEW
	},
	{
		displayName: MENU_ITEMS_NAMES.UPLOAD_DATA,
		value: MENU_ITEMS_VALUES.UPLOAD_DATA
	}
];

export const KITS_ITEMS_NAMES = {
	CUSTOMIZE_VIEW: 'Customize View',
	RESET_VIEW: 'Reset View',
	UPLOAD_DATA: 'Upload Data'
};

export const KITS_ITEMS = {
	CUSTOMIZE_VIEW: 'CUSTOMIZE_VIEW',
	RESET_VIEW: 'RESET_VIEW',
	UPLOAD_DATA: 'UPLOAD_DATA'
};

export const DROPDOWN_KITS_METHODS = [
	{
		displayName: KITS_ITEMS_NAMES.CUSTOMIZE_VIEW,
		value: KITS_ITEMS.CUSTOMIZE_VIEW
	},
	{
		displayName: KITS_ITEMS_NAMES.RESET_VIEW,
		value: KITS_ITEMS.RESET_VIEW
	},
	{
		displayName: KITS_ITEMS_NAMES.UPLOAD_DATA,
		value: KITS_ITEMS.UPLOAD_DATA
	}
];

export const MASTER_KITS_NAMES = {
	KIT_SKU: 'Kit SKU',
	KIT_SKU_DESCRIPTION: 'KIT SKU Description',
	SKU: 'SKU',
	SKU_DESCRIPTION: 'SKU Description',
	SHIP_METHOD_DISCRIPTION: 'Ship Method Description',
	SKU_QTY: 'SKU Qty'
};
export const MASTER_KITS_VALUES = {
	KIT_SKU: 'KitSKU',
	KIT_SKU_DESCRIPTION: 'KITSKUDescription',
	SKU: 'SKU',
	SKU_DESCRIPTION: 'SKUDescription',
	SHIP_METHOD_DISCRIPTION: 'ShipMethodDescription',
	SKU_QTY: 'SKUQty'
};

export const MASTER_KITS_FILTER = [
	{
		displayName: MASTER_KITS_NAMES.KIT_SKU,
		value: MASTER_KITS_VALUES.KIT_SKU,
		rowIdentifier: MASTER_KITS_NAMES.KIT_SKU
	},
	{
		displayName: MASTER_KITS_NAMES.KIT_SKU_DESCRIPTION,
		value: MASTER_KITS_VALUES.KIT_SKU_DESCRIPTION,
		rowIdentifier: MASTER_KITS_NAMES.KIT_SKU_DESCRIPTION
	},
	{
		displayName: MASTER_KITS_NAMES.SKU,
		value: MASTER_KITS_VALUES.SKU,
		rowIdentifier: MASTER_KITS_NAMES.SKU
	},
	{
		displayName: MASTER_KITS_NAMES.SKU_DESCRIPTION,
		value: MASTER_KITS_VALUES.SKU_DESCRIPTION,
		rowIdentifier: MASTER_KITS_NAMES.SKU_DESCRIPTION
	},

	{
		displayName: MASTER_KITS_NAMES.SHIP_METHOD_DISCRIPTION,
		value: MASTER_KITS_VALUES.SHIP_METHOD_DISCRIPTION,
		rowIdentifier: MASTER_KITS_NAMES.SHIP_METHOD_DISCRIPTION
	},
	{
		displayName: MASTER_KITS_NAMES.SKU_QTY,
		value: MASTER_KITS_VALUES.SKU_QTY,
		rowIdentifier: MASTER_KITS_NAMES.SKU_QTY
	}
];

export const LOADER_COMPONENTS = {
	KITS_COLUMN_DEF: 'COLUMN_DEF',
	KITS_GRID: 'KITS_GRID'
};
