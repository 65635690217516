// @ts-nocheck
import { triggerNotification } from 'organisms/notifications/sagaActions';
import qs from 'qs';
import { put, takeEvery } from 'redux-saga/effects';
import { getAPIDataV2, getAPIDataV3, postAPIData, postAPIDataV3 } from 'utils/api';
import { REQUESTS } from 'utils/requests';

import { LOADER_COMPONENTS } from '../constants';
import * as homeSlice from '../reducer';
import * as slice from './reducer';
import * as sagaActions from './sagaActions';

const delay = ms => new Promise(res => setTimeout(res, ms));

function* handleFetchStores({ payload }) {
	const { customerNum, query } = payload;
	yield put(
		homeSlice.toggleLoader({
			loaderComponent: LOADER_COMPONENTS.SELECT_STORES,
			isLoading: true
		})
	);
	const stores = yield getAPIDataV2(`orders/available/store/${customerNum}?${qs.stringify(query)}`).then(
		response => response?.data?.Table1
	);
	yield put(
		homeSlice.toggleLoader({
			loaderComponent: LOADER_COMPONENTS.SELECT_STORES,
			isLoading: true
		})
	);
	yield put(slice.updateStores({ stores }));
	yield put(
		homeSlice.toggleLoader({
			loaderComponent: LOADER_COMPONENTS.SELECT_STORES,
			isLoading: false
		})
	);
}

function* handleUpdateSelectedStores({ payload }) {
	const { selectedStores } = payload;
	yield put(slice.updateSelectedStores({ selectedStores }));
}

function* handleUpdateSkus({ payload }) {
	const { skus } = payload;
	yield put(
		homeSlice.toggleLoader({
			loaderComponent: LOADER_COMPONENTS.SELECT_SKUS,
			isLoading: true
		})
	);
	yield put(slice.updateSkus({ skus }));
	yield put(
		homeSlice.toggleLoader({
			loaderComponent: LOADER_COMPONENTS.SELECT_SKUS,
			isLoading: false
		})
	);
}

function* handleUpdateSelectedSkus({ payload }) {
	const { selectedSkus } = payload;
	yield put(slice.updateSelectedSkus({ selectedSkus }));
}
function* handleUpdateSelectedReserveStores({ payload }) {
	const { reserveSelectedStores } = payload;
	yield put(slice.updateReserveSelectedStores({ reserveSelectedStores }));
}
function* handleReviewOrders({ payload }) {
	const { batchData, orderInfo, storeOrderSet } = payload;
	yield put(
		homeSlice.toggleLoader({
			loaderComponent: LOADER_COMPONENTS.REVIEW_ORDERS,
			isLoading: true
		})
	);
	const batchReturn = yield postAPIDataV3({...REQUESTS.POST.POST_ORDER_BATCH, body: batchData}).then(response => response);
	const batchID = batchReturn.data ? batchReturn.data.toString() : '-1';
	const statusObj = {
		OrderBatchID: batchID,
		StatusTypeID: 1
	};
	const statusReturn = yield getAPIDataV3({...REQUESTS.GET.GET_ORDER_STATUS, queryParams: statusObj }).then(response => response);
	const orderForSubmit = {
		CustomerID: `${orderInfo.customerID}`,
		DataSource: 'UI Order Generate Process',
		OrderBatchID: batchID,
		PODate: orderInfo.PODate,
		ShipDate: orderInfo.shipStartDate,
		ShipMethodID: `${orderInfo.shipmethodID}`,
		StatusID: statusReturn.data.OutputParameters.StatusID,
		UserName: 'None',
		StoreSKUList: storeOrderSet,
		BatchID: batchID
	};
	yield postAPIDataV3({...REQUESTS.POST.CREATE_ORDER, body: orderForSubmit });
	yield put(slice.reserState());
	yield put(
		homeSlice.toggleLoader({
			loaderComponent: LOADER_COMPONENTS.REVIEW_ORDERS,
			isLoading: false
		})
	);
}
//unused to be removed
function* handleSubscribeStores({ payload }) {
	// const { data } = payload;
	// yield put(
	// 	homeSlice.toggleLoader({
	// 		loaderComponent: LOADER_COMPONENTS.SUBSCRIBE_STORES,
	// 		isLoading: true
	// 	})
	// );
	// yield put(
	// 	homeSlice.toggleLoader({
	// 		loaderComponent: LOADER_COMPONENTS.SELECT_SKUS,
	// 		isLoading: true
	// 	})
	// );
	// const skus = yield postAPIDataV3({service: 'orders', url: 'available/sku/subscribe', body: data}).then(
	// 	response => response?.data?.Table1
	// );
	// yield put(slice.updateSkus({ skus }));
	// yield put(
	// 	homeSlice.toggleLoader({
	// 		loaderComponent: LOADER_COMPONENTS.SELECT_SKUS,
	// 		isLoading: false
	// 	})
	// );
	// yield put(
	// 	homeSlice.toggleLoader({
	// 		loaderComponent: LOADER_COMPONENTS.SUBSCRIBE_STORES,
	// 		isLoading: false
	// 	})
	// );
}
//unused to be removed
function* handleGetCustomers() {
	// yield put(
	// 	homeSlice.toggleLoader({
	// 		loaderComponent: LOADER_COMPONENTS.ORDER_INFO,
	// 		isLoading: true
	// 	})
	// );
	// const { data, isError, error } = yield getAPIDataV3({ service: 'customers' }).catch(error => ({ isError: true, error }));
	// if (isError) {
	// 	yield put(
	// 		triggerNotification({ message: 'An error occurred while fetching customers, please try again later.' })
	// 	);
	// 	return;
	// }
	// yield put(slice.updateCustomers({ customers: data.data }));
}

function* handleGetShippingMethods() {
	const response = yield postAPIDataV3({
    service: REQUESTS.POST.GET_GRID_DETAILS_V2.service,
    url: REQUESTS.POST.GET_GRID_DETAILS_V2.url,
    pathParams: { tableId: 19 },
  })
		.then(response => response?.data?.Table1)
		.catch(error => ({
			isError: true,
			error
		}));

	yield put(slice.updateShippingMethod({ shippingMethods: response }));
}
// unused to e removed
function* handleGetWarehouses() {
	// const response = yield getAPIDataV3({ service: 'warehouses' }).then(response => response?.data[0]);
	// yield put(slice.updateWarehouses({ warehouses: response }));
}

function* handleGetSchedules() {
	const response = yield getAPIDataV2('store/schedule').then(response => response?.data.data.storeSchedules);
	yield put(slice.updateSchedules({ schedules: response }));
	yield put(
		homeSlice.toggleLoader({
			loaderComponent: LOADER_COMPONENTS.ORDER_INFO,
			isLoading: false
		})
	);
}

function* handleResetStore() {
	yield put(slice.reserState());
}

export default function* rootSaga() {
	return [
		yield takeEvery(sagaActions.fetchStores, handleFetchStores),
		yield takeEvery(sagaActions.updateSelectedStores, handleUpdateSelectedStores),
		yield takeEvery(sagaActions.updateSKUs, handleUpdateSkus),
		yield takeEvery(sagaActions.updateSelectedSkus, handleUpdateSelectedSkus),
		yield takeEvery(sagaActions.updateSelectedSkus, handleUpdateSelectedSkus),
		yield takeEvery(sagaActions.updateSelectedReserveStores, handleUpdateSelectedReserveStores),
		yield takeEvery(sagaActions.reviewOrders, handleReviewOrders),
		yield takeEvery(sagaActions.subscribeStores, handleSubscribeStores),
		yield takeEvery(sagaActions.getCustomers, handleGetCustomers),
		yield takeEvery(sagaActions.getShippingMethods, handleGetShippingMethods),
		yield takeEvery(sagaActions.getWarehouses, handleGetWarehouses),
		yield takeEvery(sagaActions.getSchedules, handleGetSchedules),
		yield takeEvery(sagaActions.resetStore, handleResetStore)
	];
}
