// @ts-nocheck
import Button from 'atoms/button/button';
import Heading from 'atoms/heading/heading';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import SelectInput from 'atoms/selectInput/selectInput';
import TextInput from 'atoms/TextInput/textInput';
import Validation from 'atoms/validation/validation';
import _ from 'lodash';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import { useSelector } from 'react-redux';
import { getStoreSchedules } from '../selectors';
import * as constants from './constants';

const ScheduleDetails = (props: any) => {
	const { data, updateData } = props;
	const storeSchedules = useSelector(state => getStoreSchedules(state));

	const inputChangeHandler = React.useCallback(
		(e: any) => {
			updateData({
				...data,
				storeSchedule: e.target.value
			});
		},
		[data]
	);
	const isDescriptionInvalid = () => {
		if (
			storeSchedules.find(
				s =>
					s.StoreScheduleDescription?.toLowerCase().trim() === data.storeSchedule?.toLowerCase().trim() &&
					s.StoreScheduleID !== data.storeScheduleId
			)
		) {
			return {
				errorMessage: 'Please select a different name, this Schedule name already exists.',
				hasError: true
			};
		}
		return null;
	};

	const validateForm = React.useCallback(() => {
		if (data.storeSchedule?.length < 3) {
			return false;
		}

		if (!data.selectedDays?.length) {
			return false;
		}

		if (isDescriptionInvalid()) {
			return false;
		}
		return true;
	}, [data, isDescriptionInvalid]);

	const cardClickHandler = React.useCallback(
		(day: any) => {
			const index = _.findIndex(data.selectedDays, day);
			const newSelectedDays: any = [...data.selectedDays];

			if (index >= 0) {
				newSelectedDays.splice(index, 1);
			} else {
				newSelectedDays.push(day);
			}
			updateData({
				...data,
				selectedDays: newSelectedDays
			});
		},
		[data]
	);


	return (
		<React.Fragment>
			<ModalBody>
				<Stack isVertical gutter="small">
					<StackItem isStretch>
						<Stack isVertical gutter="small">
							<StackItem isGrow>
								<Heading type="tertiary"> Schedule Name </Heading>
							</StackItem>
							<StackItem isStretch isGrow>
								<Validation isTheme {...isDescriptionInvalid()}>
									<TextInput
										size="medium"
										value={data.storeSchedule}
										onChange={inputChangeHandler}
										placeholder={'Please enter a schedule name'}
										name={'storeSchedule'}
									/>
								</Validation>
							</StackItem>
						</Stack>
					</StackItem>
					<StackItem isStretch>
						<Stack isVertical gutter="small">
							<StackItem isGrow>
								<Heading type="tertiary"> Days to Schedule </Heading>
							</StackItem>
						</Stack>
					</StackItem>
					<StackItem isStretch>
						<Stack isWrap>
							{_.map(constants.DAYS, (day, index) => (
								<Card
									key={index}
									day={day}
									selected={data.selectedDays}
									handleClick={() => {
										cardClickHandler(day);
									}}
								/>
							))}
						</Stack>
					</StackItem>
				</Stack>
			</ModalBody>

			<ModalActions>
				<Stack align="right" gutter="small">
					<StackItem>
						<Button isSecondary name="CANCEL" handleClick={props.hideModal} />
					</StackItem>

					<StackItem isStretch align="right">
						<Button name="SELECT STORES" disabled={!validateForm()} handleClick={props.handleNextClick} />
					</StackItem>
				</Stack>
			</ModalActions>
		</React.Fragment>
	);
};

export default ScheduleDetails;

const Card = (props: any) => {
	const { selected, day, handleClick } = props;
	return (
		<StackItem>
			<SelectInput text={day.key} selected={_.includes(selected, day)} handleSelectInputClick={handleClick} />
		</StackItem>
	);
};
