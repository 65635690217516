// @ts-nocheck
import Button from 'atoms/button/button';
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import Loader from 'atoms/loader';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import ModalHeader from 'atoms/modal/modalHeader';
import ReactModal from 'atoms/modal/reactModal';
import { Tab } from 'atoms/tab/tab';
import Tabs from 'atoms/tabs/tabs';
import _ from 'lodash';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as sagaActions from '../sagaActions';
import * as selectors from '../selectors';
import ReviewOrders from './reviewOrders';
import SelectBulkTypes from './selectBulkTypes';

interface TProps {
	hideModal: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
	selectedTab: string;
}

const BULK_ODRER_STEPS = {
	BULK_EDIT_TYPE: 'bulkEditType',
	REVIEW_ORDERS: 'reviewOrders'
};

const BULK_STEPS = [
	{
		title: 'Bulk Edit Type',
		identifier: BULK_ODRER_STEPS.BULK_EDIT_TYPE
	},
	{
		title: 'Review Orders',
		identifier: BULK_ODRER_STEPS.REVIEW_ORDERS
	}
];

const getDisabledStatus = (identifier: string) => {
	switch (identifier) {
		case BULK_ODRER_STEPS.BULK_EDIT_TYPE:
			return false;
		case BULK_ODRER_STEPS.REVIEW_ORDERS:
			return false;
		default:
			return true;
	}
};

const BulkOrderModalHeader = ({ selectedStep, setSelectedStep, steps, orderCloneInfo }: any) => {
	return (
		<Stack isVertical gutter="small">
			<StackItem isStretch>
				<Heading>Bulk Order Qty Edit</Heading>
			</StackItem>
			<StackItem isStretch>
				<Divider />
			</StackItem>
			<StackItem isStretch>
				<Tabs type="secondary">
					{_.map(steps, (step, index) => {
						return (
							<Tab
								value={index}
								key={index}
								isSelected={selectedStep.identifier === step.identifier}
								onSelectTabItem={() => setSelectedStep(step)}
								disabled={false}
							>
								{step.title}
							</Tab>
						);
					})}
				</Tabs>
			</StackItem>
		</Stack>
	);
};

const BulkOrderModalContent = ({ selectedStep, dropdownTypes, selectedTab, parent }: any) => {
	switch (selectedStep.identifier) {
		case BULK_ODRER_STEPS.BULK_EDIT_TYPE:
			return <SelectBulkTypes dropdownTypes={dropdownTypes} />;
		case BULK_ODRER_STEPS.REVIEW_ORDERS:
			return <ReviewOrders parent={parent} selectedTab={selectedTab} />;
		default:
			return <> </>;
	}
};

const BulkOrderModalActions = ({ selectedStep, setSelectedStep, hideModal, steps, updateOrders, isLoading }: any) => {
	const bulkEditParams = useSelector(state => selectors.getBulkEditParams(state));
	const getNextAction = () => {
		switch (selectedStep.identifier) {
			case BULK_ODRER_STEPS.BULK_EDIT_TYPE:
				return (
					<Button
						name="Review Order"
						handleClick={() => setSelectedStep(steps[1])}
						disabled={
							(bulkEditParams.bulkOrderEditTypeId === 1 && bulkEditParams.bulkOrderEditValue < 0) ||
							isLoading
						}
					/>
				);
			case BULK_ODRER_STEPS.REVIEW_ORDERS:
				return <Button disabled={isLoading} name="Update Orders" handleClick={updateOrders} />;
			default:
				return <> </>;
		}
	};
	return (
		<Stack gutter="small" align="right">
			<StackItem>
				<Button isSecondary name="Cancel Edit" handleClick={hideModal} />
			</StackItem>
			<StackItem>{getNextAction()}</StackItem>
		</Stack>
	);
};

const ThetaBulkOrderQtyEdit = (props: TProps) => {
	const { hideModal, selectedTab } = props;
	const dispatch = useDispatch();
	const [ref, handleUpdateRef] = React.useState(null);

	const selectedOrders = useSelector(state => dynamicGridSelectors.getTableSelection(state, Number(selectedTab)));

	const steps = BULK_STEPS;
	const [selectedStep, setSelectedStep] = React.useState<any>(steps[0]);
	React.useEffect(() => {
		dispatch(sagaActions.getBulkOrderTypes());
	}, [dispatch]);

	const dropdownOptionTypes = useSelector(state => selectors.getBulkOrderTypes(state)) || [];
	const dropdownTypes = dropdownOptionTypes.map(singleType => {
		return { displayName: singleType.name, value: singleType.value };
	});

	const bulkEditParams = useSelector(state => selectors.getBulkEditParams(state));
	let OrderDetailId;
	if (window.location.pathname.match('orders/details')) {
		OrderDetailId = window.location.pathname?.split('/').reverse()[0];
	}

	const updateOrders = () => {
		const reqData = {
			tableId: Number(selectedTab),
			bulkOrderEditTypeId: Number(bulkEditParams.bulkOrderEditTypeId),
			orderId: OrderDetailId,
			bulkOrderEditValue: Number(bulkEditParams.bulkOrderEditValue)
		};
		dispatch(sagaActions.updateBulkOrders(Number(selectedTab), reqData));
	};
	const isLoading = useSelector(state => selectors.getLoadingStatus(state));
	return (
		<ReactModal hideModal={hideModal} height={selectedStep?.identifier === BULK_ODRER_STEPS.BULK_EDIT_TYPE ? 400:  800} width={selectedStep?.identifier === BULK_ODRER_STEPS.BULK_EDIT_TYPE ? 400: 800}>
			<ModalHeader>
				<BulkOrderModalHeader
					selectedStep={selectedStep}
					setSelectedStep={setSelectedStep}
					selectedOrders={selectedOrders?.selectedRowKeys}
					steps={steps}
				/>
			</ModalHeader>
			<ModalBody onDomRef={handleUpdateRef}>
				{isLoading ? (
					<Stack isVertical>
						<StackItem isGrow isStretch align="center">
							<Loader />
						</StackItem>
					</Stack>
				) : (
					<BulkOrderModalContent
						selectedStep={selectedStep}
						setSelectedStep={setSelectedStep}
						hideModal={hideModal}
						selectedOrders={selectedOrders?.selectedRowKeys}
						steps={steps}
						selectedTab={selectedTab}
						dropdownTypes={dropdownTypes}
						parent={ref}
					/>
				)}
			</ModalBody>
			<ModalActions>
				<BulkOrderModalActions
					selectedStep={selectedStep}
					setSelectedStep={setSelectedStep}
					hideModal={hideModal}
					isLoading={isLoading}
					steps={steps}
					updateOrders={updateOrders}
				/>
			</ModalActions>
		</ReactModal>
	);
};

export default ThetaBulkOrderQtyEdit;
