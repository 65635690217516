// @ts-nocheck
import Icon from 'atoms/icons/icon';
import classNames from 'classnames';
import _ from 'lodash';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import CustomizeViewModal from 'organisms/CustomizeViewModal';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ThetaMenu from '../../../organisms/thetaMenu/thetaMenu';
import * as sagaActions from '../sagaActions';
import * as selectors from '../selectors';
import ThetaBulkOrderQtyEditModal from '../thetaBulkOrderQtyEditModal';
import ThetaOrderDeleteModal from '../thetaDeleteOrderModal';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import ThetaDistributeExcessAvailabilityModal from '../thetaDistributeExcessAvailability';
import ThetaOptimizeShippingMethodModal from '../thetaOptimizeShippingModal';
import ThetaOrderCloneModal from '../thetaOrderCloneModal';
import ThetaSplitOrderModal from '../thetaOrderSplitModal';
import ThetaChangePODateModal from '../thetaPOUpdateModal';
import ThetaOrderPublishModal from '../thetaPublishOrderModal';
import ThetaChangeOrderStatusModal from '../thetaUpdateOrderStatusModal';
import ThetaUpdateShippingMethodModal from '../thetaUpdateShippingMethodModal';
import SideCar from 'molecules/sidecar/sidecar';
import './actionBar.css';
import {
	DROPDOWN_MENU_ALL_ORDERS,
	DROPDOWN_MENU_ALL_ORDERS_COMPLETED,
	DROPDOWN_MENU_ORDER_ITEMS,
	DROPDOWN_MENU_ORDER_SKUS,
	DROPDOWN_VALUES,
	MODAL_COLUMNS,
	DROPDOWN_MENU_ORDER_KITS
} from './constants';
import * as tableColumnSagaActions from 'models/tableColumns/sagaActions';
import * as tableColumnSelectors from 'models/tableColumns/selector';
import ExportGrid from 'organisms/exportGrid';
import { updateModalToggle } from 'organisms/kitsModal/reducer';
import { getDropdownMenu } from './selectors';
import { getOrderFilter } from 'models/dynamicGrid/constants';
import { useLocation } from 'react-router-dom';
import { ORDER_DETAILS_TABS } from '../orderDetails/constants';
import { isNewUploadEnabled } from 'models/user/selector';
import GridCsvUpload from 'organisms/uploadData/gridCsvUpload';
import UploadData from 'organisms/uploadData';

interface DropdownType {
	displayName: string;
	value: string;
}
interface Props {
	type: 'primary' | 'secondary' | 'tertiary';
	children: React.ReactNode;
	isFilterEnabled: boolean;
	selectedTab: string;
	handleResetViewClick: Function;
	handleUpdateFilter: Function;
	orderStatus: DropdownType;
	gridMeta: TMetadata
}

const ActionBar: React.FunctionComponent<any> = (props: Props) => {
	const { type, handleUpdateFilter, isFilterEnabled, selectedTab, handleResetViewClick,
		orderStatus, idd, setTrigger, gridMeta, parentTableId } = props;
	const dispatch = useDispatch();
	const openModalName = useSelector(state => selectors.getOpenModalName(state));
	const filters = useSelector(state => dynamicGridSelectors.getGridFilters(state, Number(selectedTab)));
	const classes: any = classNames(['actionBar', { [`actionBar--${type}`]: true }]);
	const isNewUpload = useSelector(isNewUploadEnabled);
	const [isUploadEnabled, toggleUpload] = React.useState<boolean>(false);

	// console.log("isNewUpload", isNewUpload)

	const selectedOrders = useSelector(state =>
		dynamicGridSelectors.getTableSelection(state, Number(selectedTab))
	)?.selectedRowKeys;

	const isSelectAll = useSelector(state =>
		dynamicGridSelectors.getTableSelection(state, Number(selectedTab))
	)?.isSelectAll;

	const handleClick = React.useCallback(() => {
		handleUpdateFilter(!isFilterEnabled);
	}, [isFilterEnabled]);

	const openModal = (value: string) => {
		dispatch(sagaActions.toggleModalOpen(value));
	};

	const closeModal = (tableIds: any[]) => {
		dispatch(sagaActions.toggleModalClose(tableIds));
	};

	const isCustomizeViewModalEnabled = useSelector(state => tableColumnSelectors.isCustomizeViewModalEnabled(state));

	const customizeViewHandler = React.useCallback((isCustomizeViewModalEnabled: boolean) => {
		dispatch(tableColumnSagaActions.setCustomizeViewModal(isCustomizeViewModalEnabled));
	}, []);

	const handleMenuItemClick = (value: string) => {
		switch (value) {
			case DROPDOWN_VALUES.LOCK_RECORDS:
				return handleLockUnlockRecordsClick(DROPDOWN_VALUES.LOCK_RECORDS);
			case DROPDOWN_VALUES.UNLOCK_RECORDS:
				return handleLockUnlockRecordsClick(DROPDOWN_VALUES.UNLOCK_RECORDS);
			case DROPDOWN_VALUES.CUSTOMIZE_VIEW:
				return customizeViewHandler(true);
			case DROPDOWN_VALUES.RESET_VIEW:
				return handleResetViewClick();
			case DROPDOWN_VALUES.CREATE_KIT:
				dispatch(updateModalToggle({ isOpen: true, isAvailabilityUpdate: false, tableId: selectedTab }));
				break;
			default:
				return openModal(value);
		}
	};

	const handleRefreshClick = () => {
		if (idd) {
			setTrigger()
			dispatch(dynamicGridSagaActions.refreshGridData({ tableId: selectedTab }));
		} else {
			dispatch(dynamicGridSagaActions.refreshGridData({ tableId: selectedTab }));
			dispatch(
				sagaActions.getOrdersSummaryInfo({
					search: _.get(filters, 'search'),
					tableId: selectedTab
				})
			);
		}
	};

	const handleLockUnlockRecordsClick = async (type: string) => {
		let data = {};
		if (type === DROPDOWN_VALUES.LOCK_RECORDS) {
			data = {
				IsReadOnly: true,
				OrderIDs: selectedOrders.join(','),
				isSelectAll
			};
			dispatch(sagaActions.lockUnlockRecords({ selectedTab, data }));
		} else {
			data = {
				IsReadOnly: false,
				OrderIDs: selectedOrders.join(','),
				isSelectAll
			};
			dispatch(sagaActions.lockUnlockRecords({ selectedTab, data }));
		}
	};



	const dropdownMenu = useSelector(state => getDropdownMenu(state, selectedTab, orderStatus))

	const hideCustomizeViewModal = React.useCallback(() => {
		customizeViewHandler(false);
	}, []);

	const getDisabledStatus = option => {
		switch (option.value) {
			case DROPDOWN_VALUES.CUSTOMIZE_VIEW:
				return false;
			case DROPDOWN_VALUES.RESET_VIEW:
				return false;
			default:
				return !selectedOrders?.length && !isSelectAll;
		}
	};

	console.log(gridMeta)

	return (
		<div className={classes}>
			<Stack position="bottom" align="right" gutter="large">
				{
					isNewUpload &&
					gridMeta?.UploadTableID &&
					<StackItem>
						<GridCsvUpload uploadTableId={gridMeta?.UploadTableID} parentTableId={parentTableId} />
					</StackItem>}
				{/* {true && (
					<SideCar isActive={true} 
					// handleOnClose={uploadCloseClickHandler}
					>
						<UploadData tableId={activeTable.tableId.toString()} />
					</SideCar>
				)} */}
				<StackItem>
					<Icon isActive type="secondary" faAlias="faRecycle" onClick={handleRefreshClick} />
				</StackItem>

				<StackItem>
					<ExportGrid key={selectedTab} tableId={selectedTab} />
				</StackItem>
				<StackItem>
					<ThetaMenu
						type="3Dots"
						options={
							dropdownMenu.filter(x => x.value !== DROPDOWN_VALUES.CUSTOMIZE_VIEW)
						}
						handleItemClick={handleMenuItemClick}
						isDisabled={getDisabledStatus}
					/>
				</StackItem>
			</Stack>
			{openModalName === DROPDOWN_VALUES.CLONE_ORDERS && (
				<ThetaOrderCloneModal
					hideModal={() => closeModal(MODAL_COLUMNS.ORDER_CLONE)}
					selectedTab={selectedTab}
				/>
			)}
			{openModalName === DROPDOWN_VALUES.SPLIT_ORDERS && (
				<ThetaSplitOrderModal
					hideModal={() => closeModal(MODAL_COLUMNS.SPLIT_COLUMNS)}
					selectedTab={selectedTab}
				/>
			)}
			{openModalName === DROPDOWN_VALUES.DELETE_ORDERS && (
				<ThetaOrderDeleteModal hideModal={() => closeModal([])} selectedTab={selectedTab} />
			)}
			{openModalName === DROPDOWN_VALUES.UPDATE_SHIPPING_METHOD && (
				<ThetaUpdateShippingMethodModal hideModal={() => closeModal([])} selectedTab={selectedTab} />
			)}
			{openModalName === DROPDOWN_VALUES.CHANGE_PO_DATE && (
				<ThetaChangePODateModal hideModal={() => closeModal([])} selectedTab={selectedTab} />
			)}
			{openModalName === DROPDOWN_VALUES.PUBISH_ORDERS && (
				<ThetaOrderPublishModal hideModal={() => closeModal([])} selectedTab={selectedTab} />
			)}
			{openModalName === DROPDOWN_VALUES.UPDATE_ORDER_STATUS && (
				<ThetaChangeOrderStatusModal hideModal={() => closeModal([])} selectedTab={selectedTab} />
			)}
			{openModalName === DROPDOWN_VALUES.OPTIMIZE_SHIPPING_METHOD && (
				<ThetaOptimizeShippingMethodModal
					hideModal={() => closeModal(MODAL_COLUMNS.OPTIMIZE)}
					selectedTab={selectedTab}
				/>
			)}
			{openModalName === DROPDOWN_VALUES.DISTRIBUTE_EXCESS_AVAIL && (
				<ThetaDistributeExcessAvailabilityModal
					hideModal={() => closeModal(MODAL_COLUMNS.DISTRIBUTE)}
					selectedTab={selectedTab}
				/>
			)}
			{isCustomizeViewModalEnabled && (
				<CustomizeViewModal hideModal={hideCustomizeViewModal} tableId={Number(selectedTab)} />
			)}
			{openModalName === DROPDOWN_VALUES.BULK_ORDER_QTY_EDIT && (
				<ThetaBulkOrderQtyEditModal hideModal={() => closeModal([])} selectedTab={selectedTab} />
			)}
		</div>
	);
};

ActionBar.defaultProps = {
	type: 'primary'
};

export default ActionBar;
