// @ts-nocheck
import * as React from 'react';
import './stackItem.scss';
import classNames from 'classnames';

interface Props extends React.PropsWithChildren<any> {
	position?: 'top' | 'bottom' | 'center';
	align?: 'right' | 'left' | 'center';
	isStretch?: boolean;
	isGrow?: boolean;
	hasScroll?: boolean;
	children?: React.ReactNode;
	isEllipsis?: boolean;
}

const StackItem: React.FunctionComponent<Props> = (props: Props) => {
	const { position, children, isStretch, isGrow, hasScroll, isEllipsis,isEllipsisFull, align, onDomRef, span, divisions } = props;
	const classes: any = classNames([
		'stackItem',
		{
			[`stackItem--position-${position}`]: Boolean(position),
			[`stackItem--align-${align}`]: Boolean(position),
			[`stackItem--stretch`]: isStretch,
			[`stackItem--grow`]: isGrow,
			[`stackItem--scroll`]: hasScroll,
			[`stackItem--ellipsis`]: isEllipsis,
			[`stackItem--ellipsis-full`]: isEllipsisFull,
			
		}
	]);

	return (
		<div
			onDomRef={onDomRef}
			data-test-id={props?.['data-test-id']}
			className={classes}
			style={{ flexBasis: divisions && span && `${(span / divisions) * 100}%` }}
		>
			{children}
		</div>
	);
};

StackItem.defaultProps = {
	isGrow: false,
	isStretch: false,
	position: 'top'
};

export default StackItem;
