/* eslint-disable import/no-anonymous-default-export */
// @ts-nocheck
import UploadDataSlice from 'organisms/uploadData/reducer';
import UploadDataSagas from 'organisms/uploadData/sagas';
import React from 'react';
import Availability from './availability';
import OrderReducer from './reducer';
import AvailabilitySagas from './sagas';
import { isAvailabilityReadWrite, isAvailabilitySummaryReadWrite } from 'models/user/selector';

export default [
	{
		path: '/availability',
		key: 'key',
		componentId: 'availability',
		component: () => <Availability title="asd" />,
		routes: [],
		importReducer: () => {
			return [OrderReducer, UploadDataSlice];
		},
		importSagas: () => {
			return [AvailabilitySagas, UploadDataSagas];
		},
		isEnabled: (state) => {
			return isAvailabilityReadWrite(state) || isAvailabilitySummaryReadWrite(state)
		},
		title: 'Skulicity | Availability',
		headerName: 'Availability'
	}
];
