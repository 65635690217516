// @ts-nocheck
import { initialState } from './reducer';
import * as constants from './constants';

const getStateData = (state: any) => state[constants.MODEL_NAME] || initialState;

export const getPricingDataId = (state: any, eventName: string): boolean => {
	return getStateData(state)?.pricingDataId;
};
export const getAdModalState = (state: any, eventName: string): boolean => {
	const reducer = getStateData(state);

	return reducer.isOpen;
};

export const getAdModalData = (state: any, eventName: string): boolean => {
	const reducer = getStateData(state);

	return reducer.adData;
};

export const getPricingData = (state: any): any[] => {
	const reducer = getStateData(state);
	return reducer.pricingData;
};

export const getEditMode = (state: any): boolean => {
	const reducer = getStateData(state);
	return reducer.editMode;
};

export const getEditData = (state: any): boolean => {
	const reducer = getStateData(state);
	return reducer.editData;
};

export const getAdModalTypeData = (state: any): boolean => {
	const reducer = getStateData(state);

	return reducer.adTypeData;
};
