// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const getHealthData = createAction(
  'healthData/updateOrderStatus',
  function prepare(healthCheckId) {
    return {
      payload: {
        healthCheckId,
      },
    };
  }
);

export const updateHealthCheck = createAction(
  'healthData/updateHealthCheck',
  function prepare({ HealthCheckID, isIgnoreAlert }) {
    return {
      payload: {
        HealthCheckID,
        isIgnoreAlert,
      },
    };
  }
);
