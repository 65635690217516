/* eslint-disable import/no-anonymous-default-export */
// @ts-nocheck
import React from 'react';
import ColumnAliasesSagas from './columnAliases/sagas';
import ColumnAliasesReducer from './columnAliases/reducer';
import ColumnAliases from './columnAliases/columnAliases';
import Settings from './settings';
import { isAppAliasSettingReadWrite } from 'models/user/selector';
import users from './accessControl/users';
import rowAccess from './accessControl/rowAccess';
import roles from './accessControl/roles';


const AdminSettings = [
	{
		path: '/settings',
		key: 'key',
		componentId: 'adminSettings',
		component: (props) => <Settings  {...props} />,
		routes: [],
		importReducer: () => {
			return [ColumnAliasesReducer];
		},
		importSagas: () => {
			return [ColumnAliasesSagas];
		},
		title: 'Skulicity | Admin Settings',
		headerName: 'Admin Settings',
		name: 'Admin Settings',
		isEnabled: state => isAppAliasSettingReadWrite(state)
	},
	{
		path: '/settings/columnaliases',
		key: 'key',
		componentId: 'columnAliases',
		component: (props) => <ColumnAliases {...props} />,
		routes: [],
		importReducer: () => {
			return [ColumnAliasesReducer];
		},
		isEnabled: state => isAppAliasSettingReadWrite(state),
		importSagas: () => {
			return [ColumnAliasesSagas];
		},
		title: 'Skulicity | Admin Settings',
		name: 'Admin Settings - Column Aliases',
		headerName: 'Admin Settings - Column Aliases'
	},
	...users,
	...rowAccess,
	...roles
];

export default AdminSettings;
