// @ts-nocheck
import { triggerNotification } from 'organisms/notifications/sagaActions';
import { put, takeEvery } from 'redux-saga/effects';
import { putAPIDataV2, putAPIDataV3 } from 'utils/api';
import * as sagaActions from './sagaActions';
import { toggleLoader } from 'models/loaders/sagaActions';
import { AG_TABLE_IDS } from './reducer';
import { REQUESTS } from 'utils/requests';

export const updateUserSettings = async payload => {
	try {
		await putAPIDataV3({ service: REQUESTS.PUT.UPDATE_USER_CONFIG.service, url:REQUESTS.PUT.UPDATE_USER_CONFIG.url, body: { settings: payload } });

		return { isError: false };
	} catch (error) {
		return { error: error?.data || error, isError: true };
	}
};

export default function* rootSaga() {
	//update user settings
	function* handleUpdateUserAgGirdSettings({ payload }) {
		yield put(toggleLoader({ component: 'USER_SETTINGS', isLoading: true }));
		const { isError, error } = yield updateUserSettings(payload);

		if (isError || error?.warning) {
			yield put(
				triggerNotification({
					message: error?.message || 'An error occurred while updating the settings, please try again later'
				})
			);
		}

		window.location.reload();
		yield put(toggleLoader({ component: 'USER_SETTINGS', isLoading: false }));
	}

	return [yield takeEvery(sagaActions.updateUserAgGridSettings, handleUpdateUserAgGirdSettings)];
}
