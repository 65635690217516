// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import { STORE_PREFIX, UPLOAD_PAGES } from './constants';

export const initialState = {
  activeUploadPageName: ''
};

const slice = createSlice({
  initialState,
  name: STORE_PREFIX,
  reducers: {
    setActiveUploadPageName:(state, { payload: {  pageName } }) => {
      return {
        ...state,
        activeUploadPageName: pageName
      }
  }
  },
});

export default slice;

export const {setActiveUploadPageName } = slice.actions;
