// @ts-nocheck
import * as React from 'react';
import './header.css';
import classNames from 'classnames';

interface Props {
  type: 'primary' | 'secondary' | 'tertiary';
  children: React.ReactNode;
}

const Header = (props: Props) => {
  const { type } = props;
  const classes: any = classNames(['header', { [`header--${type}`]: true }]);

  return <div className={classes}>{props.children}</div>;
};

export default Header;
