// @ts-nocheck
import tableColumnSagas from './tableColumns/sagas';
import tableColumnReducer from './tableColumns/reducer';
import dynamicGridSagas from './dynamicGrid/sagas';
import dynamicGridReducer from './dynamicGrid/reducer';
import advertisingSagas from './advertisings/sagas';
import advertisingReducer from './advertisings/reducer';
import subscriptionReducer from './subscriptions/reducer';
import subscriptionSagas from './subscriptions/sagas';
import userSagas from './user/sagas';
import userReducer from './user/reducer';
import loaderReducer from './loaders/reducer';
import loaderSagas from './loaders/sagas';
import bookmarksSagas from './bookmarks/sagas';
import bookmarksReducer from './bookmarks/reducer';
import dynamicListReducer from './dynamicList/reducer';
import dynamicListSagas from './dynamicList/sagas';
import autoCompleteSagas from './autoComplete/sagas';
import autoCompleteReducer from './autoComplete/reducer';




import appSelectorReducer from './appSelector/reducer';
import appSelectorSagas from './appSelector/sagas';


export const modelSagas = [
	tableColumnSagas,
	loaderSagas,
	dynamicGridSagas,
	advertisingSagas,
	dynamicListSagas,
	bookmarksSagas,
	subscriptionSagas,
	autoCompleteSagas,
	userSagas,
	appSelectorSagas
];
export const modelReducers = [
	tableColumnReducer,
	loaderReducer,
	dynamicListReducer,
	dynamicGridReducer,
	subscriptionReducer,
	advertisingReducer,
	autoCompleteReducer,
	bookmarksReducer,
	userReducer,
	appSelectorReducer
];






