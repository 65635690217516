// @ts-nocheck
export const LOADER_COMPONENTS = {
	DEFAULT_ORDERS: 'defaultOrders',
	SELECT_STORES: 'selectStores',
	REVIEW_ORDERS: 'reviewOrders',
	SUBSCRIBE_STORES: 'subscribeStores',
	ORDER_INFO: 'orderIndo',
	SELECT_SKUS: 'selectSkus'
};

export const COMPLETE_ORDER_STATUSES = [
	'dataready',
	'orderready',
	'inprogress',
	'complete',
	'complete-final',
	'inventory'
];

export const DISABLED_STATUSES = ['pending', 'processing', 'complete-final'];

export const STATUS_INTERVAL_TIMEOUT = 3000;

export const STATUS_CONTENT = {
	pending: {
		title: 'Your data is not yet ready for the day.',
		p1: "We're still loading all the data from last night. Good job getting an early start to the day, you deserve a donut.",
		p2: 'Your data should be ready around 7am',
		b1: {
			text: 'Loading Status...',
			link: 'function'
		}
	},
	dataready: {
		title: 'Your data is ready, would you like to begin ordering?',
		p1: "We've imported all of your inventory data, and compiled it against data points like weather and your sales schedule.",
		b1: {
			text: 'Begin My Orders',
			link: 'function'
		}
	},
	processing: {
		title: 'Your orders are processing',
		p1: 'Approx. 10 minutes remain.',
		p2: 'You deserve a cup of coffee.',
		b1: {
			text: 'Processing...',
			link: 'function'
		}
	},
	orderready: {
		title: 'Click below to view your open orders.',
		p1: "We've put together a series or recommendations that you can customize.",
		b1: {
			text: 'Show me My Orders',
			link: 'function'
		}
	},
	inprogress: {
		title: 'Your orders are in progress.',
		p1: "You've started working on them, but have not published your orders yet.",
		b1: {
			text: 'Take me to my orders',
			link: 'function'
		}
	},
	complete: {
		title: 'Your orders are complete!',
		p1: "You've published your orders for the day.",
		p2: "You can generate more orders with today's data, or wait til tomorrow.",
		b1: {
			text: 'Generate more orders',
			link: 'function'
		}
	},
	'complete-final': {
		title: 'Your orders are complete!',
		p1: 'Good job finishing your orders today.'
	}
};
