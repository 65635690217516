// @ts-nocheck
import { IToolPanel } from 'ag-grid-community';
import Button from 'atoms/button/button';
import Heading from 'atoms/heading/heading';
import Label from 'atoms/label/label';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import ModalHeader from 'atoms/modal/modalHeader';
import ReactModal from 'atoms/modal/reactModal';
import TextInput from 'atoms/TextInput/textInput';
import { getDispatch } from 'reducers';
import { applyBookmark, saveBookmark } from 'models/bookmarks/sagaActions';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React, { useEffect, useState } from 'react';
import { triggerAddUserRole, triggerCreateUser } from '../sagaActions';
import TagSelector from 'atoms/tagSelector';
import { getAutoCompleteData } from 'models/autoComplete/selectors';
import { useSelector } from 'react-redux';

interface CustomToolPanelProps extends IToolPanel { }

export const CreateUser: React.FC<CustomToolPanelProps> = props => {
	const { tableId, selectedBookmark, onHide, userInfo } = props;
	const [userName, setUserName] = useState(userInfo.UserName);
	const [selectedRoles, setSelectedRoles] = useState(userInfo?.Roles?.map(r => r.Identifier))
	const [userEmail, setUserEmail] = useState(userInfo.UserEmail);
	const dispatch = getDispatch();
	const rolesList = useSelector(state => getAutoCompleteData(state, 'acl-roles'));

	const handleCreateUser = async () => {
		try {
			if (userInfo.UserID) {
				// Trigger add user role if UserID exists
				await dispatch(triggerAddUserRole({
					userId: userInfo.UserID,
					roles: selectedRoles,
					isForced: true
				}));
			} else {
				// Trigger create user if UserID does not exist
				await dispatch(triggerCreateUser({
					userName,
					userEmail,
					roles: selectedRoles
				}));
			}
			onHide();

		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	return (
		<>
			<ReactModal hideModal={onHide} height={400} width={400}>
				<ModalHeader>
					<Heading type="secondary">Create User</Heading>
				</ModalHeader>
				<ModalBody>
					<Stack isVertical gutter="small">
						<StackItem>
							<Label>User Name:</Label>
						</StackItem>
						<StackItem isStretch>
							<TextInput
								disabled={userInfo?.UserID}
								value={userName}
								onChange={e => {
									setUserName(e.target.value)
								}}
							></TextInput>
						</StackItem>

						<StackItem>
							<Label>User Email:</Label>
						</StackItem>
						<StackItem isStretch>
							<TextInput
								type="email"
								disabled={userInfo?.UserID}
								value={userEmail}
								onChange={e => {
									setUserEmail(e.target.value)
								}}
							></TextInput>
						</StackItem>
						<StackItem>
							<Label>Roles:</Label>
						</StackItem>
						<StackItem isStretch>
							<TagSelector onChange={roles => setSelectedRoles(roles)} defaultValues={selectedRoles} options={
								rolesList?.map(r => ({
									value: r.RoleID,
									label: r.RoleName
								}))
							} />
						</StackItem>
					</Stack>
				</ModalBody>
				<ModalActions>
					<Stack align="right" gutter="small">
						<StackItem>
							<Button isSecondary name="CANCEL" handleClick={onHide} />
						</StackItem>

						<StackItem isStretch align="right">
							<Button
								disabled={!userEmail || !userName}
								name={userInfo?.UserID ? "UPDATE" : "CREATE"}
								handleClick={handleCreateUser}
							/>
						</StackItem>
					</Stack>
				</ModalActions>
			</ReactModal>
		</>
	);
};
