// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useOutsideAlerter } from 'utils/helpers';
import './modal.scss';
import classNames from 'classnames';
import { Resizable } from 'react-resizable';
import 'react-resizable/css/styles.css';
import { getUserSettings } from 'models/user/selector';
import { useSelector } from 'react-redux';

interface Props {
	hideModal: Function;
	children: React.ReactNode;
	size?: 'small' | 'medium' | 'large';
	height?: number | null;
	width?: number | null;
}

const ReactModal = (props: Props) => {
	const { hideModal, children, size = 'small', height = 300, width = 300, onDomRef } = props;
	const wrapperRef = React.useRef(null);
	const classes: any = classNames(['app-modal-content', { [`app-modal-content--${size}`]: true }]);
	const userSettings: any = useSelector(state => getUserSettings(state));

	useOutsideAlerter(wrapperRef, () => {
		// hideModal();
	});
	React.useEffect(() => {
		updateState({ width, height });
	}, [height, width]);

	React.useEffect(() => {
		return () => {
			hideModal && hideModal();
		};
	}, []);
	const [state, updateState] = useState({ width, height });
	const onResize = (event, { element, size, handle }) => {
		updateState({ width: size.width, height: size.height });
	};

	const renderInnerContent = () => {
		return (
			<div
				onClick={e => {
					e.stopPropagation();
				}}
				className={`app-modal--inner`}
				style={{ height: state.height || '100%', width: state.width || '100%' }}
			>
				{children}
			</div>
		);
	};
	return (
		<div className={`app-modal`}>
				<Resizable maxConstraints={[1400, 1400]} minConstraints={ [height, width]} height={state.height} width={state.width} onResize={onResize}>
					{renderInnerContent()}
				</Resizable>
		</div>
	);
};

export default ReactModal;
