// @ts-nocheck
import * as React from "react";
import "./heading.scss";
import classNames from "classnames";

interface Props {
  type: "primary" | "secondary" | "tertiary";
  disabled?: Boolean;
  success?: Boolean;
  error?: Boolean;
  normalWeight?: Boolean;
  children: React.ReactNode;
}

const Heading: React.FunctionComponent<any> = (props: Props) => {
  const { type, disabled, success, error, normalWeight } = props;
  const classes: any = classNames([
    "heading",
    { [`heading--${type}`]: true },
    { [`heading--${disabled && "disabled"}`]: true },
    { [`heading--${success && "success"}`]: true },
    { [`heading--${error && "error"}`]: true },
    { [`heading--${normalWeight && "normalWeight"}`]: true },
  ]);

  return <div className={classes}>{props.children}</div>;
};

Heading.defaultProps = {
  type: "primary",
};

export default Heading;
