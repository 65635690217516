// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';


export const triggerDeleteColumnAlias = createAction('adminSettings/triggerDeleteColumnAlias', ({ ColumnName }) => ({
	payload: { ColumnName }
}));

export const triggerCreateAppColumnAlias = createAction('adminSettings/triggerCreateAppColumnAlias',  ({ columnName, aliasName }) => ({
	payload: { columnName, aliasName }
}) );