// @ts-nocheck
export const DAYS = [
  { key: 'Monday', value: 'Monday' },
  { key: 'Tuesday', value: 'Tuesday' },
  { key: 'Wednesday', value: 'Wednesday' },
  { key: 'Thursday', value: 'Thursday' },
  { key: 'Friday', value: 'Friday' },
  { key: 'Saturday', value: 'Saturday' },
  { key: 'Sunday', value: 'Sunday' },
];
