// @ts-nocheck
import * as React from 'react';
import './fileInput.scss';
import classNames from 'classnames';

interface Props {
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  size?: string;
  disabled?: Boolean;
}

const FileInput = (props: Props) => {
  const { value, onChange, size, disabled } = props;
  const classes: any = classNames(['text-input'], {
    [`text-input--${size}`]: true,
    [`text-input--${disabled && 'disabled'}`]: true,
  });
  return (
    <input
      value={value}
      disabled={disabled}
      type="file"
      className={classes}
      onChange={onChange}
    />
  );
};

export default FileInput;
