// @ts-nocheck
import * as React from 'react';
import './label.scss';
import classNames from 'classnames';

interface Props {
	isSecondary?: boolean;
	children: React.ReactNode;
	size: 'small' | 'medium' | 'large' | 'default' | 'tiny';
	align: 'left' | 'right' | 'center';
	isHighlight?: boolean;
}

function Label(props: Props) {
	const { isSecondary, size = 'default', align = 'left', isHighlight, isWhite } = props;
	const classes: any = classNames([
		'app-label',
		{ [`app-label--secondary`]: isSecondary },
		{ [`app-label--${size}`]: true },
		{ [`app-label--${align}`]: true },
		{ ['app-label--white']: isWhite },
		{ [`app-label--highlight`]: isHighlight }
	]);

	return <div className={classes}>{props.children}</div>;
}

export default Label;
