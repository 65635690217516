// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';
import * as query from './query';
//get --> fetch
//post --> trigger /

export const updateModalParams = createAction(query.createActionName('kits/update-modal-params'), data => ({
	payload: { data }
}));

export const cleanUp = createAction('organisms/kits-clean-up');

export const saveData = createAction(query.createActionName('save-kits-modal-data'), data => ({
	payload: { data }
}));

export const fetchShippingMethods = createAction(query.createActionName('fetch-ship-methods'), data => ({
	payload: { data }
}));
