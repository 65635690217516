// @ts-nocheck
export const TABLE_ID = 14;

export const STORE_PREFIX = 'parameterview';

export const MENU_ITEMS_NAMES = {
  CUSTOMIZE_VIEW: 'Customize View',
  RESET_VIEW: 'Reset View',
  UPLOAD_DATA: 'Upload Data',
};

export const MENU_ITEMS_VALUES = {
  CUSTOMIZE_VIEW: 'CUSTOMIZE_VIEW',
  RESET_VIEW: 'RESET_VIEW',
  UPLOAD_DATA: 'UPLOAD_DATA',
};

export const MENU_ITEMS = [

  {
    displayName: MENU_ITEMS_NAMES.RESET_VIEW,
    value: MENU_ITEMS_VALUES.RESET_VIEW,
  },
  {
    displayName: MENU_ITEMS_NAMES.UPLOAD_DATA,
    value: MENU_ITEMS_VALUES.UPLOAD_DATA,
  },
];

export const LOADER_COMPONENTS = {
  COLUMN_DEF: 'COLUMN_DEF',
  GRID_DATA: 'GRID_DATA',
};
