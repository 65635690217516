// @ts-nocheck
import { initialState } from './reducer';
// import * as reducer from './reducer';
const notificationsSelector = state => state.notifications || initialState;

export const getNotifications = state => {
	return notificationsSelector(state).notificationsList;
};

export const isNotificationCenterEnabled = state => {
	return notificationsSelector(state).isNotificationCenterEnabled;
};
