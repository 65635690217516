// @ts-nocheck
import { createAction, createSlice, Action, AnyAction } from '@reduxjs/toolkit';
import * as sagaActions from './sagaActions';
export const initialState = {
	columns: {},
	selecetdColumns: {}
};

interface RejectedAction extends Action {
	error: Error;
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
	return action.type.endsWith('rejected');
}
const slice = createSlice({
	name: 'customize',
	initialState,
	reducers: {
		handleUpdateFetchedColumns(state, { payload: { tableId, columns } }) {
			return {
				...state,
				columns: {
					...state?.columns,
					[tableId]: columns
				}
			};
		},
		handleUpdateSelectedColumns(state, { payload: { tableId, selectedColumns } }) {
			return {
				...state,
				selecetdColumns: {
					...state.selecetdColumns,
					[tableId]: selectedColumns
				}
			};
		},
		handleUpdateColumns(state, { payload: { tableId, columns } }) {
			return {
				...state,
				columns: {
					...state.selecetdColumns,
					[tableId]: columns
				}
			};
		}
	}
});

export default slice;

export const { handleUpdateFetchedColumns, handleUpdateSelectedColumns, handleUpdateColumns } = slice.actions;
