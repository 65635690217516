// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import * as constants from './constants';
import { TTransactionReducer } from './types';

export const initialState: TTransactionReducer = {
	selectedAttributeDetail: null,
	demandPlanningDimensions: []
};
export const reducerName = 'demand-group-hub';
const slice = createSlice({
	initialState,
	name: reducerName,
	reducers: {
		setAvailableDimensions(state, { payload: { demandPlanningDimensions } }) {
			return {
				...state,
				demandPlanningDimensions
			};
		},
		setAvailableDimensionGroups(state, { payload: { dimensionPlanningGroupId, dimensionGroups } }) {
			return {
				...state,
				[dimensionPlanningGroupId]: {
					...state[dimensionPlanningGroupId],
					dimensionGroups
				}
			};
		},
		setSelectedGroupAttributeDetail(state, { payload: { selectedAttributeDetail } }) {
			return {
				...state,
				selectedAttributeDetail
			};
		},
		setDimensionAttributes(state, { payload: { dimensionPlanningGroupId, attributes } }) {
			return {
				...state,
				[dimensionPlanningGroupId]: {
					...state[dimensionPlanningGroupId],
					dimensionAttributes: attributes
				}
			};
		}
	}
});

export default slice;

export const {
	setAvailableDimensions,
	setSelectedGroupAttributeDetail,
	setAvailableDimensionGroups,
	setDimensionAttributes
} = slice.actions;
