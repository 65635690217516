// @ts-nocheck
import { put, takeEvery, select } from 'redux-saga/effects';
import * as slice from './reducer';
import * as sagaActions from './sagaActions';
import { getAPIDataV2, putAPIData, postAPIData, postAPIDataV3, putAPIDataV2, getAPIDataV3, putAPIDataV3 } from 'utils/api';
import qs from 'qs';
import * as dynamicGridSlice from 'models/dynamicGrid/reducer';
import _ from 'lodash';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import * as advertisingSagaActions from 'models/advertisings/sagaActions';
import * as advertisingSlice from 'models/advertisings/reducer';
import { AD_MODAL_TABLE_IDS, AD_TYPES_TABLE } from './constants';
import { updateAdTypeData } from '../../../organisms/adTypeModal/api';
import { triggerNotification } from 'organisms/notifications/sagaActions';
import { cleanUpGrids } from 'models/tableColumns/sagaActions';
import { getGridFilters, getTableSelection } from 'models/dynamicGrid/selector';
import { createFiltersFromQueries } from 'models/dynamicGrid/query';
import { getPricingDataId, getAdModalData } from './selector';
import { TABLE_ID } from '../constants';
import { toggleLoader } from 'models/loaders/sagaActions';
import { REQUESTS } from 'utils/requests';
import { getWebAppSettings } from 'models/user/selector';
import { getState } from 'reducers';

function* updateModalToggle({ payload }: any) {
	const { eventName, isOpen } = payload;
	yield put(slice.updateModalToggle({ eventName, isOpen }));
	if (!isOpen) {
		yield put(dynamicGridSagaActions.cleanUpGrids([33, 31, 1]));
		yield put(cleanUpGrids([33, 31, 1]));
	}
}

function* updateAdData({ payload }: any) {
	const { eventName, data } = payload;
	yield put(slice.updateAdData({ eventName, data }));
}

function* editAdData({ payload }: any) {
	const { eventName, data } = payload;
	yield updateModalToggle({ payload: { eventName: eventName, isOpen: true } });
	const apiQuery = {
		refresh: new Date().getTime().toString()
	};

	yield put(slice.updateAdData({ eventName, data }));
	const { response, isError, error } = yield getAPIDataV3({
    service: REQUESTS.GET.GET_AD_DETAILS.service,
    url: REQUESTS.GET.GET_AD_DETAILS.url,
    pathParams: { adId: data.AdID },
  })
    .then((response) => ({ response: response?.data }))
    .catch((error) => ({ error, isError: true }));

	if (error?.message === 'CANCELLED') {
		return;
	}
	if (isError) {
		yield put(triggerNotification({ message: 'An error occurred while fetching data, please try again later.' }));
		//TODO: Do something here
		return;
	}
	let selectedStores = _.map(response?.data, item => item.StoreID);
	let selectedSkus = _.map(response?.data, item => item.SKUID);
	let selectedRowKeys = _.uniq(selectedSkus);
	let pricingData = [];
	for (let key of selectedRowKeys) {
		let index = _.findIndex(response?.data, function (e: any) {
			return e.SKUID === key;
		});
		if (index > -1) {
			pricingData.push(response?.data[index]);
		}
	}
	yield put(
		slice.updatePricingData({
			data: pricingData
		})
	);

	yield put(
		slice.updateEditMode({
			edit: true
		})
	);
	yield put(
		slice.updateEditData({
			editData: response?.data?.length ? response?.data : [data]
		})
	);
	yield put(
		dynamicGridSlice.updateSelectedRows({
			tableId: AD_MODAL_TABLE_IDS.STORES,
			data: { selectedRowKeys: _.uniq(selectedStores), isSelectAll: false }
		})
	);
	yield put(
		dynamicGridSlice.updateSelectedRows({
			tableId: AD_MODAL_TABLE_IDS.SKUS,
			data: { selectedRowKeys: _.uniq(selectedSkus), isSelectAll: false }
		})
	);
}

function* updatePricingData({ payload }: any) {
	const { data } = payload;

	yield put(
		slice.updatePricingData({
			data: data
		})
	);
}

function* handleCreateAdvertising({ payload }: any) {
	yield put(toggleLoader({ component: 'adTypesModal', isLoading: true }));

	const adModalData = yield select(getAdModalData);
	const pricingDataId = yield select(getPricingDataId);

	yield postAPIDataV3({service: REQUESTS.POST.CREATE_AD.service, url: REQUESTS.POST.CREATE_AD.url, body: { ...adModalData, AdTempID: pricingDataId, AdID: payload?.AdID }});
	yield put(sagaActions.updateModalToggle('advertising', false));

		yield put(dynamicGridSagaActions.refreshGridData({ tableId: TABLE_ID }));

	// console.log(adModalData)
	// yield put(
	// 	slice.updatePricingData({
	// 		data: data
	// 	})
	// );
}

function* cleanUp() {
	yield put(slice.cleanUp());
	yield put(
		toggleLoader({
			isLoading: false,
			component: 'adTypesModal'
		})
	);
	yield put(slice.updatePricingDataId({ pricingDataId: null }));
	yield put(dynamicGridSagaActions.cleanUpGrids({ tableIds: [50, 32, 33] }));
}

function* editAdType({ payload }: any) {
	const { eventName, data } = payload;
	yield updateModalToggle({ payload: { eventName: eventName, isOpen: true } });
	yield put(slice.updateAdTypeData({ eventName, data }));
	yield put(
		slice.updateEditMode({
			edit: true
		})
	);
}

function* updateAdType({ payload }: any) {
	const { eventName, data } = payload;
	yield put(advertisingSlice.updateLoader({ eventName: 'adTypesModal', isLoading: true }));
	const { isError, error } = yield putAPIDataV3({
    service: REQUESTS.PUT.UPDATE_AD_TYPE.service,
    url: REQUESTS.PUT.UPDATE_AD_TYPE.url,
    body: data
  }).catch(error => ({ isError: true, error }));
	yield put(advertisingSlice.updateLoader({ eventName: 'adTypesModal', isLoading: false }));
	if (error?.message === 'CANCELLED') {
		return;
	}
	if (isError) {
		yield put(triggerNotification({ message: 'An error occurred while fetching data, please try again later.' }));
		return;
	}
	yield updateModalToggle({ payload: { eventName: eventName, isOpen: false } });
	yield cleanUp();


		yield put(dynamicGridSagaActions.refreshGridData({ tableId: AD_TYPES_TABLE }));
		return;
}

function* saveAdType({ payload }: any) {
	const { title } = payload;
	const adData = {
		AdID: -1,
		AdTitle: title,
		AdType: title,
		AdTypeID: -1,
		value: title
	};
	yield put(advertisingSlice.updateLoader({ eventName: 'adTypesModal', isLoading: true }));
	const { isError, error } = yield postAPIDataV3({
    service: REQUESTS.POST.ADD_AD_TYPE.service,
    url: REQUESTS.POST.ADD_AD_TYPE.url,
    body: adData
  }).catch(e => ({ isError: true, error: e }));
	yield put(advertisingSlice.updateLoader({ eventName: 'adTypesModal', isLoading: false }));
	if (error?.message === 'CANCELLED') {
		return;
	}
	if (isError) {
		yield put(triggerNotification({ message: 'An error occurred while fetching data, please try again later.' }));
		return;
	}
	yield updateModalToggle({ payload: { eventName: 'adTypes', isOpen: false } });
	yield cleanUp();


		yield put(dynamicGridSagaActions.refreshGridData({ tableId: AD_TYPES_TABLE }));
		return;
}

function* handleUpdatePricingData({ payload }: any) {
	const { tableId, row, updatedValue, rowIdentifier, isInlineEdit } = payload;
	if (tableId !== 33) {
		return;
	}
	const AdTempID = yield select(getPricingDataId);

	try {
		yield putAPIDataV3({
			service: REQUESTS.PUT.UPDATE_PRICING_PARAMS.service, url: REQUESTS.PUT.UPDATE_PRICING_PARAMS.url, body: {
			SKUID: row.SKUID,
			[rowIdentifier]: updatedValue
			},
			pathParams: {
				AdTempID
			}
});
	} catch (e) {}
}

function* handleCreatePricingData({ payload }: any) {
	try {
		const { tableId, row, updatedValue, rowIdentifier, isInlineEdit } = payload;

		const storeFilters = yield select(state => getGridFilters(state, 50));
		const skuFilters = yield select(state => getGridFilters(state, 32));
		const storeIds = yield select(state => getTableSelection(state, 50));
		const skuIds = yield select(state => getTableSelection(state, 32));
		const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

		const { data } = yield postAPIDataV3({
			service:REQUESTS.POST.CREATE_PRICING_PARAMS.service, url: REQUESTS.POST.CREATE_PRICING_PARAMS.url, body: {
				isAdvancedSearch: true,
				storeIds: storeIds?.selectedRowKeys.join(','),
				skuIds: skuIds?.selectedRowKeys.join(','),
				isSelectAllStore: storeIds.isSelectAll,
				isSelectAllSKU: skuIds.isSelectAll,
        		isView,
				selectAllSearchStore: JSON.stringify({ filter: createFiltersFromQueries(storeFilters.search) }),
				selectAllSearchSku: JSON.stringify({ filter: createFiltersFromQueries(skuFilters.search) })
			}
		});
		yield put(
			slice.updatePricingDataId({
				pricingDataId: data.AdTempID
			})
		);
	} catch (e) {
		console.log(e);
	}
}

export default function* rootSaga() {
	return [
		yield takeEvery(sagaActions.updateModalToggle, updateModalToggle),
		yield takeEvery(sagaActions.updateAdData, updateAdData),
		yield takeEvery(sagaActions.editAdData, editAdData),
		yield takeEvery(sagaActions.updatePricingData, updatePricingData),
		yield takeEvery(sagaActions.cleanUp, cleanUp),
		yield takeEvery(sagaActions.editAdType, editAdType),
		yield takeEvery(sagaActions.updateAdType, updateAdType),
		yield takeEvery(sagaActions.saveAdType, saveAdType),
		yield takeEvery(sagaActions.createPricingData, handleCreatePricingData),
		yield takeEvery(dynamicGridSagaActions.updateRowData, handleUpdatePricingData),
		yield takeEvery(sagaActions.createNewAdvertising, handleCreateAdvertising)
	];
}

