// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import { STORE_PREFIX } from './constants';

export const initialState = {
  isStoresEnabled: false,
  isAttributesEnabled: false,
};

const slice = createSlice({
  initialState,
  name: STORE_PREFIX,
  reducers: {
    setIsStoresEnabled:(state, { payload: {  isStoresEnabled } }) => {
      return {
        ...state,
        isStoresEnabled
      }
  },
  setIsAttributesEnabled:(state, { payload: {  isAttributesEnabled } }) => {
    return {
      ...state,
      isAttributesEnabled
    }
}
  },
});

export default slice;

export const {setIsStoresEnabled, setIsAttributesEnabled } = slice.actions;
