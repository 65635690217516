// @ts-nocheck
import Button from 'atoms/button/button';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ThetaGrid from 'templates/thetaGrid';
import * as constants from './constants';
import * as query from './query';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import * as dynamicGridQuery from 'models/dynamicGrid/query';
import * as generateOrderSelectors from './selectors';
import _ from 'lodash';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import Sheet from 'atoms/sheet/sheet';
import { REQUESTS } from 'utils/requests';

type TProps = {
	hideModal: React.MouseEventHandler;
	handleNextClick: React.MouseEventHandler;
	goBack: React.MouseEventHandler;
};

const ReserveInventory = (props: TProps) => {
	const { hideModal, handleNextClick, goBack } = props;

	const data = useSelector(state => generateOrderSelectors.getData(state));

	const selectedStores = useSelector(state =>
		dynamicGridSelectors.getTableSelection(state, constants.MODAL_TABLE_IDS.STORES)
	);

	const selectedReserveInventory = useSelector(state =>
		dynamicGridSelectors.getTableSelection(state, constants.MODAL_TABLE_IDS.INVENTORY)
	);
	const storeSearch = useSelector(state =>
		dynamicGridSelectors.getGridFilters(state, constants.MODAL_TABLE_IDS.STORES)
	);

	const stores = useSelector(state => dynamicGridSelectors.getGridData(state, constants.MODAL_TABLE_IDS.STORES));

	const validateFormData = React.useCallback(() => {
		let hasError = true;

		if (selectedReserveInventory?.isSelectAll || selectedReserveInventory.selectedRowKeys?.length) {
			hasError = false;
		}

		return hasError;
	}, [selectedReserveInventory]);
	const [ref, handleUpdateRef] = useState(null);



	const gridConfig = {
		enableSelection: true,
		enableSorting: true,
		selectionCallBack: () => { }
	};
	let parsedStoreSearch;

	if (selectedStores.isSelectAll) {
		parsedStoreSearch = {
			filter: dynamicGridQuery.createFiltersFromQueries(storeSearch ? storeSearch.search : [])
		};
	}
	const customerOptions = useSelector(state => generateOrderSelectors.getCustomerOptions(state));

	let templateConfig = {
		gridConfig,
		isFilterEnabled: true,
		tableId: constants.MODAL_TABLE_IDS.INVENTORY,

		dataProviderOptions: {
			version: 2,
			type: 'POST',
			queryParams: {},
			...REQUESTS.POST.GET_GRID_DETAILS_V2,
			pathParams: { tableId: constants.MODAL_TABLE_IDS.INVENTORY },
			multiSelectFieldGroups: {
				CustomerID: data.customerID.includes('-1')
					? customerOptions.map(({ value }) => value).join(',')
					: data.customerID,
				StoreScheduleID: data.useStoreSchedule ? data?.storeScheduleID : undefined,
				StartShipDate: data.shipStartDate,
				EndShipDate: data.shipToDate,
				StoreList: selectedStores?.isSelectAll ? 'ALL' : selectedStores?.selectedRowKeys.join(',')
			},
			multiSelectFilterFieldGroups: {
				StoreSearch: selectedStores?.isSelectAll ? storeSearch?.search ?? [] : [],
				UsePlanogram: data.usePlanogram
			},

			body: {
				fieldGroups: {
					CustomerID: data.customerID.includes('-1')
						? customerOptions.map(({ value }) => value).join(',')
						: data.customerID,
					StoreScheduleID: data.useStoreSchedule ? data?.storeScheduleID : undefined,
					StartShipDate: data.shipStartDate,
					EndShipDate: data.shipToDate,
					StoreList: selectedStores?.isSelectAll ? 'ALL' : selectedStores?.selectedRowKeys.join(','),
					UsePlanogram: data.usePlanogram
					// StoreSearch:
				},
				filterGroups: {
					StoreSearch: selectedStores?.isSelectAll ? storeSearch?.search : []
				}
			}
		},
		selectRowKey: 'StoreID'
	};
	//   }

	return (
		<Stack isVertical gutter="small">
			<StackItem isStretch isGrow onDomRef={handleUpdateRef}>
				{/* {selectedStores.isSelectAll ? (
          <ThetaGrid parent={ref} config={templateConfig} />
        ) : ( */}
				<Sheet isTab>
					<SheetBody>
						<ThetaGridPaginated offset={60} parent={ref} config={templateConfig} />
					</SheetBody>
				</Sheet>
				{/* )} */}
			</StackItem>
			<StackItem>
				<Stack align="left" gutter="small">
					<StackItem isGrow>
						<Button isSecondary name="Reselect SKUs" handleClick={goBack} />
					</StackItem>

					<StackItem isStretch align="right">
						<Button name={'Review Order'} handleClick={handleNextClick} />
					</StackItem>
				</Stack>
			</StackItem>
		</Stack>
	);
};

export default ReserveInventory;
