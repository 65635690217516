// @ts-nocheck
import FilterBar from 'atoms/filterBar/filterBar';
import Loader from 'atoms/loader';
import Pagination from 'atoms/pagination/pagination';
import _ from 'lodash';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import * as tableColumnSagaActions from 'models/tableColumns/sagaActions';
import * as tableColumnSelectors from 'models/tableColumns/selector';
import Grid from 'molecules/ReactGrid';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import SearchFilter from 'organisms/searchFilter';
import AdvancedSearchFilter from 'organisms/searchFilter/legacy/advanced';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as query from './query';
import Icon from 'atoms/icons/icon';
import { createFiltersFromQueries, getGridFilterName } from 'models/dynamicGrid/query';
import Button from 'atoms/button/button';
import { useEffect } from 'react';
import { Progress, Switch } from 'antd';
import Label from 'atoms/label/label';
import ExportGrid from 'organisms/exportGrid';
import AgGrid from 'molecules/ReactGrid/agGrid/agGrid';
import { isLoadingEnabled } from 'models/loaders/selectors';
import FullScreen from 'atoms/fullScreen/fullScreen';
import { updateGridMeta } from 'models/dynamicGrid/sagaActions';
import { formatThousands } from 'utils/utilities';

type TConfig = {
	gridConfig: any;
	isFilterEnabled: boolean;
	tableId: number;
	url: string;
	service: string;
	dataProviderOptions: {
		version: number;
		type: string;
		body: object;
		queryParams: object;
	};
	selectRowKey?: string;
};

type TProps = {
	parent?: any;
	config: TConfig;
	extendFilters?: React.FunctionComponent | HTMLElement | JSX.Element;
};

const ACTIONS = {
	DELETE: 'DELETE',
	EDIT: 'EDIT',
	SAVE: 'SAVE'
};

const ThetaGridPaginatedAg = (props: TProps) => {
	const {
		config,
		extendFilters,
		parent,
		isStorageEnabled = true,
		offset,
		onDataRender,
		hasChildRows,
		config: { groupBy, childCellRendererParams = null, refreshChildGridData = false, rowIndex = -1},
		refreshTimestamp: componentRefresh,
	} = props;
	const dispatch = useDispatch();
	const gridConfig = { ...config.gridConfig };
	const [gridRef, handleGridRef] = React.useState(null);
	const isGroupMode = Boolean(
		gridRef?.current?.columnApi?.columnModel?.pivotMode ||
		gridRef?.current?.columnApi?.columnModel?.rowGroupColumns?.length
	);
	const [isMultiSortEnabled, toggleMultiSort] = React.useState(false);
	const [refreshTimestamp, updateRefreshTimestamp] = React.useState(componentRefresh ? componentRefresh : null);

	const handleToggleMultiSort = () => {
		toggleMultiSort(!isMultiSortEnabled);
		updateRefreshTimestamp(Date.now());
	};

	const data = useSelector(state => dynamicGridSelectors.getGridData(state, config.tableId));
	const metaData = useSelector(state => dynamicGridSelectors.getGridMetadata(state, config.tableId))
	const columnLoading = useSelector(state => tableColumnSelectors.isLoading(state, config.tableId.toString()));
	const filterLoading = useSelector(state => isLoadingEnabled(state, 'fetch_grid_filters/' + config.tableId));
	const isLoadingColumnSync = useSelector(state => isLoadingEnabled(state, 'sync-grid-columns'));

	const dataLoading = useSelector(state => dynamicGridSelectors.isLoading(state, config.tableId.toString()));

	const selectedRowKeys: any = useSelector(state =>
		dynamicGridSelectors.getTableSelection(state, config.tableId.toString())
	);

	React.useEffect(() => {
		dispatch(dynamicGridSagaActions.erpEnabled({ tableId: config.tableId }));
	}, [config.tableId]);





	React.useEffect(() => {


		if (!_.isNull(config.url)) {
			dispatch(
				dynamicGridSagaActions.loadGrid({
					tableId: config.tableId,
					url: config.url,
					pathParams: config.pathParams,
					queryParams: config.queryParams,
					service: config.service,
					selectRowKey: config.selectRowKey,
					childTableSelectRowKey: config.childTableSelectRowKey,
					dataProviderOptions: config.dataProviderOptions,
					isAdvancedSearch: true,
					isReset: false,
					extendedSearchFilters: config.extendedSearchFilters,
					isStorageEnabled,
					isUploadEnabled: config?.gridConfig?.isUploadGrid
				})
			);
		}
	}, [
		dispatch,
		config.tableId,
		config.url,
		config.extendedSearchFilters,
		config.refreshTimestamp,
		isStorageEnabled
	]);



	const columns = useSelector(state => tableColumnSelectors.getTableColumns(state, config.tableId));

	let filters = useSelector(state => dynamicGridSelectors.getGridFilters(state, config.tableId));
	filters = [];

	let index =
		filters &&
		filters.search &&
		_.findIndex(filters.search, function (singleCreteria) {
			return singleCreteria?.criteria?.rowIdentifier === 'OrderStatusID';
		});

	const updateSearchQueries = (search: any) => {
		search.push(filters.search[index]);
		dispatch(
			dynamicGridSagaActions.updateGrid({
				tableId: config.tableId,
				filters: {
					search: _.compact(search),
					pageNumber: 1,
					itemsPerPage: 50
				},
				isStorageEnabled
			})
		);
	};

	const updatePerPage = useCallback(
		(itemsPerPage: number) => {
			dispatch(
				dynamicGridSagaActions.updateGrid({
					tableId: config.tableId,
					filters: {
						itemsPerPage,
						pageNumber: 1
					},
					isStorageEnabled: false
				})
			);
		},
		[dispatch, filters]
	);
	const updatePageNo = useCallback(() => {
		dispatch(dynamicGridSagaActions.lazyLoad({ tableId: config.tableId }));
	}, [dispatch, config]);

	const handleUpdateSort = useCallback(
		({
			sortDirection = 'ASC',
			sortField,
			isMultiSort,
			multiSortColumns
		}: {
			sortField: string;
			sortDirection: string;
			isMultiSort: boolean;
			multiSortColumns: Array;
		}) => {
			dispatch(
				dynamicGridSagaActions.updateGrid({
					tableId: config.tableId,
					filters: {
						sortDirection,
						sortField,
						pageNumber: 1,
						multiSortColumns,
						isMultiSort
					},
					isAppend: false,
					isStorageEnabled
				})
			);
		},
		[dispatch, filters]
	);

	// if (columns === undefined || columnLoading || filterLoading) {
	// 	return (
	// 		<Stack isVertical>
	// 			<StackItem isGrow isStretch align="center">
	// 				<Loader />
	// 			</StackItem>
	// 		</Stack>
	// 	);
	// }

	const handleIconClick = (action, row) => {
		switch (action) {
			case ACTIONS.EDIT:
				dispatch(
					dynamicGridSagaActions.handleRowUpdateToggle({
						tableId: config.tableId,
						rowIdentifier: row[config.selectRowKey],
						row,
						isEditEnabled: true
					})
				);
				break;

			case ACTIONS.SAVE:
				dispatch(
					dynamicGridSagaActions.handleRowUpdateToggle({
						tableId: config.tableId,
						rowIdentifier: row[config.selectRowKey],
						row,
						isEditEnabled: false
					})
				);
				break;

			case ACTIONS.DELETE:
				dispatch(
					dynamicGridSagaActions.handleRowDelete({
						tableId: config.tableId,
						isUploadGrid: config.isUploadGrid,
						rowIdentifier: row[config.selectRowKey],
						isSelectAll: selectedRowKeys?.isSelectAll,
						selectAllSearch: JSON.stringify({
							filter: createFiltersFromQueries(selectedRowKeys?.search)
						}),
					})
				);
				break;

			default:
				break;
		}
	};

	//
	// if (filters?.sortField) {
	gridConfig.sorting =
		filters?.isMultiSort && isMultiSortEnabled
			? _.map(filters?.multiSortColumns, ({ sortDirection, sortField }) => ({
				order: sortDirection,
				field: sortField
			}))
			: {
				field: filters?.sortField,
				order: filters?.sortDirection?.toLowerCase()
			};
	// }
	return (
		<Stack isVertical align="right" gutter="small">
			<StackItem isGrow>
				{
					<FullScreen>
						<AgGrid
							data={data}
							onDomRef={ref => {
								props?.onDomRef && props?.onDomRef(ref);
								handleGridRef(ref);
							}}
							key={metaData?.refreshTimestamp}
							groupBy={groupBy}
							parent={parent}
							childGridDataProviderOptions={config.childGridDataProviderOptions}
							selectRowKey={config.selectRowKey}
							config={gridConfig}
							isMultiSortEnabled={isMultiSortEnabled}
							tableId={config.tableId}
							changePer={updatePerPage}
							isFilterEnabled={config.isFilterEnabled}
							changePage={updatePageNo}
							currentPage={filters?.pageNumber}
							handleUpdateSort={handleUpdateSort}
							isStorageEnabled={isStorageEnabled}
							refreshTimestamp={refreshTimestamp}
							perPage={filters?.itemsPerPage}
							isLoading={config.url && dataLoading}
							isServerHandled
							hasChildRows={hasChildRows}
							offset={offset}
							isPaginated
							onDataRender={onDataRender}
							showOpenedGroup={props.config.showOpenedGroup}
							groupHideOpenParents={props.config.groupHideOpenParents}
							childCustomActions={config.childCustomActions}
							childCellRendererParams={childCellRendererParams}
							refreshChildGridData={refreshChildGridData}
							rowIndex={rowIndex}
						/>
					</FullScreen>
				}
			</StackItem>
			<StackItem isStretch position="right" align="right">
				<Stack gutter="small">
					<StackItem isGrow>
						{isLoadingColumnSync && 'Saving column changes...'}
					</StackItem>
					{!isGroupMode && (
						<StackItem>
							<div className="pageInfo">
								{data?.[0]?.TotalRecordCount ? `Total Record Count: ${formatThousands(data?.[0]?.TotalRecordCount)}` : ''}
							</div>
						</StackItem>

					)}
				</Stack>
			</StackItem>

		</Stack>
	);
};

export default ThetaGridPaginatedAg;
