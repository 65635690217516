// @ts-nocheck
import Button from 'atoms/button/button';
import Icon from 'atoms/icons/icon';
import Sheet from 'atoms/sheet/sheet';
import * as advertisingSelectors from 'models/advertisings/selector';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import * as advertisingSagaActions from 'models/advertisings/sagaActions';
import qs from 'qs';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import * as tableColumnSagaActions from 'models/tableColumns/sagaActions';
import * as tableColumnSelectors from 'models/tableColumns/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import ThetaDeleteModal from 'organisms/deleteModal/deleteModal';
import ThetaMenu from 'organisms/thetaMenu/thetaMenu';
import * as adModalSelectors from 'pages/advertising/adModal/selector';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ThetaGridPaginated from 'templates/thetaGridPaginated';

import AdModal from './adModal';
import * as adModalSagaActions from './adModal/sagaActions';
import * as constants from './constants';
import Tabs from 'atoms/tabs/tabs';
import Tab from 'atoms/tab/tab';
import { useLocation } from 'react-router';
import { createUrl } from './query';
import Loader from 'atoms/loader';
import CustomizeViewModal from 'organisms/CustomizeViewModal';
import { useEffect } from 'react';
import { useMemo } from 'react';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import UploadData from 'organisms/uploadData';
import SideCar from 'molecules/sidecar/sidecar';
import { constant } from 'lodash';
import { REQUESTS } from 'utils/requests';
import { isAdvertisingDelete, isAdvertisingWrite } from 'models/user/selector';
import GridActionBar from 'atoms/GridActionBar';
import { getWebAppSettings } from 'models/user/selector';
import  GridCsvUpload  from 'organisms/uploadData/gridCsvUpload';
import { isNewUploadEnabled } from 'models/user/selector';

const Advertising: React.FunctionComponent = props => {
	const name = 'advertising';
	const [openFilter, setOpenFilter] = React.useState<boolean>();
	const [isUploadEnabled, toggleUpload] = React.useState<boolean>(false);
	const [selectedAd, setSelectedAd] = React.useState<any>({});
	const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
	const [selectedGroup, setSelectedGroup] = React.useState<string>(null);
	const tabsList = useSelector(advertisingSelectors.getAdTabs);
	const tableId = constants.TABLE_ID.toString();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const adModalOpen = useSelector(state => adModalSelectors.getAdModalState(state, name));
	const hasSearchFilters = useSelector(state => dynamicGridSelectors.hasSearchFilters(state, constants.TABLE_ID));
	const customers = useSelector(state => advertisingSelectors.getCustomers(state, name));
	const isCustomizeViewModalEnabled = useSelector(state => tableColumnSelectors.isCustomizeViewModalEnabled(state));
	const location = useLocation<LocationState>();
	const selectedRowKeys = useSelector(state => dynamicGridSelectors.getTableSelection(state, constants.TABLE_ID));
	const query: any = qs.parse(location.search.replace('?', ''));
	const [refreshTimestamp, updateRefreshTimeStamp] = React.useState(Date.now());
	const gridMeta = useSelector(state => dynamicGridSelectors.getGridMetadata(state, constants.TABLE_ID));

	React.useEffect(() => {
		if (!query || !query.selectedTab) {
			return navigate(createUrl(constants.TABS_IDENTIFIER.ALL));
		}
		setSelectedGroup(query.selectedTab);
	}, [query]);

	useEffect(() => {
		dispatch(advertisingSagaActions.fetchAdTabs());
	}, []);

	const hideAdModal = () => {
		dispatch(adModalSagaActions.updateModalToggle(name, false));
	};

	const toggleAdModal = (status: boolean) => {
		dispatch(adModalSagaActions.updateModalToggle(name, status));
	};

	React.useEffect(() => {
		if (openFilter === undefined) {
			setOpenFilter(hasSearchFilters);
		}
	}, [hasSearchFilters]);

	const handleFilterClick = React.useCallback(() => {
		setOpenFilter(!openFilter);
	}, [openFilter]);

	const handleRefreshClick = React.useCallback(() => {
		dispatch(dynamicGridSagaActions.fetchGridData(constants.TABLE_ID));
	}, [tableId, dispatch]);

	const handleAddNewClick = () => {
		toggleAdModal(true);
	};

	const handleResetTableColumns = React.useCallback(async () => {
		dispatch(tableColumnSagaActions.resetTableColumns(constants.TABLE_ID));
	}, [dispatch, tableId]);

	const customizeViewHandler = React.useCallback((isCustomizeViewModalEnabled: boolean) => {
		dispatch(tableColumnSagaActions.setCustomizeViewModal(isCustomizeViewModalEnabled));
	}, []);

	const handleMenuItemClick = React.useCallback(
		(option: string) => {
			switch (option) {
				case constants.MENU_ITEMS_VALUES.CUSTOMIZE_VIEW:
					return customizeViewHandler(true);
				case constants.MENU_ITEMS_VALUES.RESET_VIEW:
					return handleResetTableColumns();
				case constants.MENU_ITEMS_VALUES.UPLOAD_DATA:
					return toggleUpload(true);
				default:
					return;
			}
		},
		[handleResetTableColumns]
	);
	const isNewUpload = useSelector(isNewUploadEnabled);

	const getDisabledStatus = React.useCallback(
		(option: any) => {
			switch (option.value) {
				case constants.MENU_ITEMS_VALUES.CUSTOMIZE_VIEW:
				case constants.MENU_ITEMS_VALUES.RESET_VIEW:
				case constants.MENU_ITEMS_VALUES.UPLOAD_DATA:

				default:
					return false;
			}
		},
		[selectedRowKeys]
	);

	const hideCustomizeViewModal = React.useCallback(() => {
		customizeViewHandler(false);
	}, []);

	const uploadCloseClickHandler = React.useCallback(() => {
		toggleUpload(false);
	}, []);

	const handleEditClick = (item: any) => {
		dispatch(adModalSagaActions.editAdData(name, item));
		setSelectedAd(item);
		// toggleAdModal(true)
	};

	const onSelectGroup = (identifier: string) => {
		// setSelectedGroup(identifier)
		navigate(createUrl(identifier));
		updateRefreshTimeStamp(Date.now());

		// return
	};

	const handleDeleteClick = (item: any) => {
		setSelectedAd(item);
		setDeleteModalOpen(true);
	};

	const hideDeleteModal = () => {
		setDeleteModalOpen(false);
	};

	const handleAdDelete = () => {
		dispatch(advertisingSagaActions.deleteAdvertisings(selectedAd.AdID));
		setDeleteModalOpen(false);
	};


	const hasAdvertisingDelete = useSelector(isAdvertisingDelete);
	const hasAdvertisingWrite = useSelector(isAdvertisingWrite);


	const gridConfig = {
		enableSelection: false,
		enableSorting: true,
		enableMultiSort: true,
		customActions: (hasAdvertisingWrite || hasAdvertisingDelete) ? {
			width: 170,
			enabled: true,
			component: ({ row }) => {

				return (
					<Stack gutter="small" align="center">
						{hasAdvertisingWrite && <StackItem>
							<Button handleClick={() => handleEditClick(row)}>Edit</Button>
						</StackItem>}
						{hasAdvertisingDelete && <StackItem>
							<Button type="secondary" handleClick={() => handleDeleteClick(row)}>
								Delete
							</Button>
						</StackItem>
						}
					</Stack>
				);
			}
		} : null,
		selectionCallBack: () => { }
	};
	const isView = useSelector(state => getWebAppSettings(state)?.IsAclRowLevelAccessEnabled);

	const templateConfig = useMemo(
		() => ({
			gridConfig,
			tableId: constants.TABLE_ID,
			isFilterEnabled: openFilter === undefined ? false : openFilter,

			refreshTimestamp,
			selectRowKey: 'AdID',
			dataProviderOptions: {
				type: 'POST',
				version: 2,
				...REQUESTS.POST.GET_GRID_DETAILS_V2,
				pathParams: { tableId: constants.TABLE_ID },
				body: {
					fieldGroups: {
						type: selectedGroup
					},
      			    isView
				}
			}
		}),
		[selectedGroup, openFilter, isView]
	);

	if (!tabsList.length) {
		return (
			<Stack>
				<StackItem isStretch isGrow>
					<Loader />
				</StackItem>
			</Stack>
		);
	}

	return (
		<React.Fragment>
			<Stack isVertical>
				<StackItem>
					<GridActionBar>
						<Stack gutter="medium" position="center">
							<StackItem align='left' >
								<Button type="action" handleClick={handleAddNewClick}>Create Ad</Button>
							</StackItem>

							<StackItem align="right" isGrow>
								<Icon isActive type="secondary" faAlias="faRecycle" onClick={handleRefreshClick} />
							</StackItem>
							{isNewUpload && gridMeta?.UploadTableID && <StackItem>
								<GridCsvUpload uploadTableId={gridMeta?.UploadTableID} parentTableId={constants.TABLE_ID} />
							</StackItem>}
							<StackItem>
								<ThetaMenu
									type="3Dots"
									options={constants.MENU_ITEMS}
									handleItemClick={handleMenuItemClick}
									isDisabled={getDisabledStatus}
								/>
							</StackItem>
						</Stack>
					</GridActionBar>
				</StackItem>
				<StackItem isStretch isGrow>
					<Sheet isPage>
						<Stack isVertical gutter="medium"> 

							<StackItem isGrow isStretch>
								<Stack isVertical>
									<StackItem>
										<Tabs>
											{_.map(tabsList, (tab, index) => {
												return (
													<Tab
														key={index}
														value={''}
														isSelected={selectedGroup?.toLowerCase() === tab?.AdTab?.toLowerCase()}
														onSelectTabItem={() => {
															onSelectGroup(tab.AdTab);
														}}
													>
														{tab.AdTab}
													</Tab>
												);
											})}
										</Tabs>
									</StackItem>
									<StackItem isGrow isStretch>
										<SheetBody>
											{selectedGroup && (
												<ThetaGridPaginated
													refreshTimestamp={refreshTimestamp}
													key={selectedGroup}
													config={templateConfig}
												/>
											)}
										</SheetBody>
									</StackItem>
								</Stack>
							</StackItem>
						</Stack>
					</Sheet>
				</StackItem>
			</Stack>

			{isUploadEnabled && (
				<SideCar isActive={isUploadEnabled} handleOnClose={uploadCloseClickHandler}>
					<UploadData tableId={constants.TABLE_ID} />
				</SideCar>
			)}
			{isCustomizeViewModalEnabled && (
				<CustomizeViewModal hideModal={hideCustomizeViewModal} tableId={constants.TABLE_ID} />
			)}
			{adModalOpen && <AdModal name={name} hideModal={hideAdModal} />}

			{deleteModalOpen && (
				<ThetaDeleteModal
					hideModal={hideDeleteModal}
					modalTitle={'Ad'}
					title={selectedAd.AdTitle}
					handleDeleteClick={handleAdDelete}
				/>
			)}
		</React.Fragment>
	);
};
export default Advertising;
