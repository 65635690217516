// @ts-nocheck
import _ from 'lodash';

export const createActionName = (eventName: string) => {
	return `store-schedule-action/${eventName}`;
};

export const createSearchFilters = (columns: any[]) => {
	return _.map(columns, column => ({
		displayName: column.header,
		value: column.rowIdentifier,
		rowIdentifier: column.rowIdentifier
	}));
};

export const createStoreScheduleDropdownOption = (item: any) => {
	return {
		displayName: item ? item.StoreScheduleDescription : '',
		value: item ? item.StoreScheduleID : 0
	};
};

export const createStoreScheduleDropdownOptions = (storeSchedules: any[]): any[] => {
	return storeSchedules.map(item => createStoreScheduleDropdownOption(item));
};

export const addDayWiseData = (schedules: any[] = []) => {
	return _.map(schedules, schedule => {
		const days = {
			monday: false,
			tuesday: false,
			wednesday: false,
			thursday: false,
			friday: false,
			saturday: false,
			sunday: false
		};
		const dayData = schedule.Day?.split(',');

		_.each(dayData, day => {
			days[day.toLowerCase()] = true;
		});

		return { ...schedule, ...days };
	});
};
