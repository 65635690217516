// @ts-nocheck
import * as React from 'react';
import classNames from 'classnames';
import './scrollable.css';

interface Props extends React.PropsWithChildren<any> {
	value: string;
}

const Scrollable = (props: Props) => {
	const classes: any = classNames(['scrollable']);
	const containerRef = React.useRef(null);
	React.useEffect(() => {
		if (containerRef?.current) {
			const parentRef = containerRef?.current?.parentNode;
			const parentHeight = parentRef?.offsetHeight;
			containerRef.current.style.height = parentHeight - 80 + 'px';
		}
	}, [containerRef]);
	return (
		<div ref={props.onDomRef || containerRef} style={props.style} className={classes}>
			{props.children}
		</div>
	);
};

export default Scrollable;
