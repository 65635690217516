// @ts-nocheck
import { put, takeEvery } from 'redux-saga/effects';
import { postAPIDataV3, putAPIDataV3 } from 'utils/api';
import { triggerAddOperation, triggerCreateRole, triggerRemoveOperation, triggerRemoveRole, triggerToggleRoleState } from './sagaActions';
import { toggleLoader } from 'models/loaders/sagaActions';
import { LOADER_COMPONENTS } from './constants';
import { getDispatch } from 'reducers';
import { triggerFetchAutoComplete } from 'models/autoComplete/sagaActions';
import { triggerNotification } from 'organisms/notifications/sagaActions';

export const handleCreateRole = async ({ payload: { roleName, componentId } }) => {
	const dispatch = getDispatch();

	try {
		if (!roleName) {
			return
		}
		dispatch(toggleLoader({
			componentId: LOADER_COMPONENTS.CREATE_ROLE,
			isLoading: true
		}));
		const { data } = await postAPIDataV3({
			service: 'acl', url: 'roles', body: {
				RoleName: roleName
			}
		});
		// dispatch(triggerFetchAutoComplete({
		// 	componentId,
		// }))
		dispatch(toggleLoader({
			componentId: LOADER_COMPONENTS.CREATE_ROLE,
			isLoading: false
		}));
		dispatch(triggerNotification({
			type: 'success',
			message: `Role: ${roleName} has been created successfully`
		}))
	}
	catch (e) {
		dispatch(triggerNotification({
			type: 'error',
			message: e.data.message
		}))
	}

};


export const handleToggleRoleState = async ({ payload: { roleId, componentId, isActive } }) => {
	const dispatch = getDispatch();
	try {
		try {
			await putAPIDataV3({ service: 'acl', url: `roles/state/${roleId}`, body: { IsActive: isActive, IsForced: true } })
			dispatch(triggerFetchAutoComplete({
				componentId,
			}));
			dispatch(triggerFetchAutoComplete({
				componentId,
			}))
			dispatch(triggerNotification({
				type: 'success',
				message: `Role been deleted successfully.`
			}))
		}
		catch (e) {
			dispatch(triggerNotification({
				type: 'success',
				message: `Unable to Delete Role, Please try again later.`
			}))
			console.log(e)
		}
	}
	catch (e) {
		console.log(e)
	}

};


export const handleAddOperation = async ({ payload: { roleId, operations } }) => {
	const dispatch = getDispatch();
	try {


		const response = await putAPIDataV3({
			service: 'acl', url: `roles/${roleId}`, body: {
				Operations: operations
			}
		});
		dispatch(triggerNotification({
			type: response?.status === 200 ? 'success' : "error",
			autoClose: response?.status === 200 ? 100 : 500,
			message: response?.data?.message ?? `Permission Successfully Added`
		}))
		return response

	}
	catch (e) {
		dispatch(triggerNotification({
			type: "error",
			autoClose: 500,
			message: e?.data?.message ?? `Unable to Add Permission, Please try again later.`,
		}))
		return e
	}

};

export const handleRemoveOperation = async ({ payload: { roleId, operations } }) => {
	const dispatch = getDispatch();
	try {


		const response = await putAPIDataV3({
			service: 'acl', url: `roles/operations/${roleId}`, body: {
				Operations: operations
			}
		});
		dispatch(triggerNotification({
			type: response?.status === 200 ? 'success' : "error",
			message: response?.data?.message ?? `Permission Successfully Added`,
			autoClose: response?.status === 200 ? 100 : 500,
		}))
		return response

	}
	catch (e) {
		dispatch(triggerNotification({
			type: "error",
			autoClose: 500,
			message: e?.data?.message ?? `Unable to Add Permission, Please try again later.`
		}))
		return e
	}

};

export default function* rootSaga() {
	return [
		yield (takeEvery(triggerCreateRole, handleCreateRole)),
		yield (takeEvery(triggerToggleRoleState, handleToggleRoleState)),
		yield (takeEvery(triggerAddOperation, handleAddOperation)),
		yield (takeEvery(triggerRemoveOperation, handleRemoveOperation))
	];
}
