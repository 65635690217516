// @ts-nocheck
export const PG_TABLE_ID = 60;
export const CHECK_STYLED_GROUP_NAMES_TABLEID = 56;
export const AVAILABILITY_TYPES_TABS: TTableTab[] = [
	{
		name: 'Availability',
		tableId: 7,
	},
	{
		name: 'Availability Summary',
		tableId: 25,
	}
];

export const ACL_TAGS_COMPONENTS = {
	UNUSED: 'acl-tags-unused',
	ACTIVE: 'active-tags'
}

export const MENU_NAMES = {
	RESET_VIEW: 'Reset View',
	DELETE: 'Delete'
};

export const MENU_VALUES = {
	RESET_VIEW: 'RESET_VIEW',
	DELETE: 'Delete'
};

export const MENU = [
	{
		displayName: MENU_NAMES.RESET_VIEW,
		value: MENU_VALUES.RESET_VIEW
	},

	{
		displayName: MENU_NAMES.DELETE,
		value: MENU_VALUES.DELETE
	}
];
