// @ts-nocheck
import { put, takeEvery } from "redux-saga/effects";
import { getAPIDataV2, patchAPIDataV3, postAPIDataV3 } from "utils/api";
import * as constants from "./constants";
// import * as slice from './reducer';
import * as sagaActions from "./sagaActions";
import { updateComponentRequestStatus } from "models/loaders/reducer";
import { REQUEST_STATUS } from "models/loaders/constants";
import {
  refreshGridData,
  updateTableCell,
} from "models/dynamicGrid/sagaActions";
import { TABLE_ID, PLANOGRAM_STORES_TABLE_ID } from "./constants";
import { getDispatch, getState } from "reducers";
import { GRID_EVENTS } from "molecules/ReactGrid/agGrid/constants";
import { Events } from "molecules/ReactGrid/agGrid/utils/events";
import { getGridMetadata } from "models/dynamicGrid/selector";
import { setIsStoresEnabled } from "./reducer";

async function togglePlanogramState({ payload }) {
  const { isActive, row, isSelectAll, selectAllSearch, selection , isBulkUpdate } = payload;
  const dispatch = getDispatch();
 
 
  if(!isBulkUpdate){
    dispatch(
        updateTableCell({
          tableId: PLANOGRAM_ALL_ATTRIBUTES_LIST,
          row,
          updatedValue: isActive,
          rowIdentifier: "IsActive",
        })
      );
  }

  let request = await patchAPIDataV3({
    service: "planogram",
    url: `toggle/state`,
    body: {
      rowIdentifier: row?.PlanogramID ?? selection.join(","),
      isSelectAll,
      selectAllSearch,
      isActive,
    },
  }).then((response) => response?.data);

  if(isBulkUpdate){
    dispatch(
        refreshGridData({
        tableId: PLANOGRAM_ALL_ATTRIBUTES_LIST,
        })
    );
  }

}

async function updatePlanogramStores({ payload }) {
  const { storeIds, planogramId, isSelectAll, selectAllSearch } = payload;

  let request = await postAPIDataV3({
    service: "planogram",
    url: `stores`,
    body: {
      storeIds,
      planogramIds: planogramId,
      isSelectAll,
      selectAllSearch,
    },
  })
    .then((response) => response?.data)
    .catch((e) => ({ isError: true, error: e }));

  let dispatch = getDispatch();
  dispatch(
    refreshGridData({
      tableId: PLANOGRAM_STORES_TABLE_ID,
    })
  );
  dispatch(setIsStoresEnabled({ isStoresEnabled: false }));
}

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
  return [
    yield takeEvery(sagaActions.togglePlanogramState, togglePlanogramState),
    yield takeEvery(sagaActions.updatePlanogramStores, updatePlanogramStores),
  ];
}
