// @ts-nocheck
import * as dynamicGridReducer from 'models/dynamicGrid/reducer';
import * as tableColumnSagaActions from 'models/tableColumns/sagaActions';
import { put, takeEvery } from 'redux-saga/effects';

export default function* rootSaga() {
	function* updateTableInput({ payload }: any) {
		const { tableId, rowIdentifier, newValue, row } = payload;
		if (tableId === '33') {
			//TODO: DO something if you want from updated value
			yield put(
				dynamicGridReducer.updateTableCell({
					tableId,
					rowIdentifier,
					updatedValue,
					row
				})
			);
		}
	}

	return [yield takeEvery(tableColumnSagaActions.updateTableInput, updateTableInput)];
}
