// @ts-nocheck
import { createAction, createSlice, Action, AnyAction } from '@reduxjs/toolkit';
import * as sagaActions from './sagaActions';
export const initialState = {
  stores: [],
  selectedStores: [],
  skus: [],
  selectedSkus: [],
  reserveSelectedStores: [],
  customers: [],
  shippingMethods: [],
  warehouses: [],
  schedules: [],
};

interface RejectedAction extends Action {
  error: Error;
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith('rejected');
}
const slice = createSlice({
  name: 'newOrders',
  initialState,
  reducers: {
    updateStores(state, { payload: { stores } }) {
      return {
        ...state,
        stores,
      };
    },
    updateSelectedStores(state, { payload: { selectedStores } }) {
      return {
        ...state,
        selectedStores,
      };
    },
    updateSkus(state, { payload: { skus } }) {
      return {
        ...state,
        skus,
      };
    },
    updateSelectedSkus(state, { payload: { selectedSkus } }) {
      return {
        ...state,
        selectedSkus,
      };
    },
    updateReserveSelectedStores(state, { payload: { reserveSelectedStores } }) {
      return {
        ...state,
        reserveSelectedStores,
      };
    },
    updateCustomers(state, { payload: { customers } }) {
      return {
        ...state,
        customers,
      };
    },
    updateShippingMethod(state, { payload: { shippingMethods } }) {
      return {
        ...state,
        shippingMethods,
      };
    },
    updateWarehouses(state, { payload: { warehouses } }) {
      return {
        ...state,
        warehouses,
      };
    },
    updateSchedules(state, { payload: { schedules } }) {
      return {
        ...state,
        schedules,
      };
    },
    reserState() {
      return {
        ...initialState,
      };
    },
  },
});

export default slice;

export const {
  updateStores,
  updateSelectedStores,
  updateSkus,
  updateSelectedSkus,
  updateReserveSelectedStores,
  updateCustomers,
  updateShippingMethod,
  updateWarehouses,
  updateSchedules,
  reserState,
} = slice.actions;
