// @ts-nocheck
import Heading from 'atoms/heading/heading';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import TextArea from 'atoms/TextArea/textArea';
import Loader from 'atoms/loader';
import Button from 'atoms/button/button';
import * as selectors from './selector';
import * as sagaActions from './sagaActions';
import ModalBody from 'atoms/modal/modalBody';
import ModalActions from 'atoms/modal/modalActions';
import { Tprops } from './types';
import { useDispatch, useSelector } from 'react-redux';
import Validation from 'atoms/validation/validation';
import TextInput from 'atoms/TextInput/textInput';
import AntDropdown from 'atoms/antDropdown/antDropdown';
import { TABS_IDENTIFIER } from 'pages/masterData/groups/constants';
import _ from 'lodash';
import { CUSTOMER_DROPDOWN } from './constants';
import NumberInput from 'atoms/NumberInput/numberInput';
import { Checkbox } from 'antd';
import Dropdown from 'atoms/dropdown/dropdown';

const KitParams = (props: Tprops) => {
	const { hideModal, handleNextClick } = props;

	const isLoading = useSelector(state => selectors.isLoading(state));
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(sagaActions.fetchShippingMethods());
	}, [dispatch]);

	const modalParams = useSelector(selectors.getModalParams);
	let shipMethods = useSelector(selectors.getShipMethods);

	const updateModalParams = React.useCallback(params => {
		dispatch(sagaActions.updateModalParams(params));
	}, []);

	shipMethods = _.map(shipMethods, value => ({
		displayName: value?.ShipMethodDescription,
		value: value?.ShipMethodID
	}));

	const isAvailabilityFlow = useSelector(selectors.isAvailabilityUpdate);

	const isFormValid = () => modalParams.kitConfigurations >= 0;

	const renderStartGroupData = () => {
		return (
			<Stack isVertical gutter="medium">
				<StackItem>
					<Stack>
						<StackItem isGrow isStretch>
							<Heading type="tertiary"> Maximum Kit Configurations* </Heading>
						</StackItem>
						<StackItem>
							<Validation
								hasError={modalParams.kitConfigurations < 0}
								errorMessage={'Value cannot be less than 0'}
							>
								<NumberInput
									size="x-small"
									minValue="0"
									value={modalParams?.kitConfigurations}
									onChange={value => {
										updateModalParams({
											kitConfigurations: value
										});
									}}
								/>
							</Validation>
						</StackItem>
					</Stack>
				</StackItem>
				<StackItem>
					<Stack>
						<StackItem isGrow isStretch>
							<Heading type="tertiary"> Shipping Methods* </Heading>
						</StackItem>
						<StackItem>
							<AntDropdown
								placeholder="Select"
								selectedOption={_.find(shipMethods, { value: modalParams?.shipMethodID }) || {}}
								options={shipMethods}
								updateHandler={({ value }) => {
									updateModalParams({
										shipMethodID: value
									});
								}}
							></AntDropdown>
						</StackItem>
					</Stack>
				</StackItem>

				{isAvailabilityFlow && (
					<StackItem>
						<Stack>
							<StackItem isGrow isStretch>
								<Heading type="tertiary">Use Store Demand* </Heading>
							</StackItem>
							<StackItem>
								<Checkbox
									checked={modalParams?.isStoreDemand}
									onChange={value => {
										updateModalParams({
											isStoreDemand: !modalParams?.isStoreDemand
										});
									}}
								/>
							</StackItem>
						</Stack>
					</StackItem>
				)}
			</Stack>
		);
	};

	const renderLoader = () => {
		return (
			<Stack isVertical>
				<StackItem isGrow isStretch align="center">
					<Loader />
				</StackItem>
			</Stack>
		);
	};

	return (
		<>
			<ModalBody>
				<Stack isVertical gutter="medium">
					<StackItem isStretch isGrow>
						{isLoading ? renderLoader() : renderStartGroupData()}
					</StackItem>
				</Stack>
			</ModalBody>
			<ModalActions>
				<Stack isVertical gutter="medium">
					<StackItem isStretch>
						<Stack align="right" gutter="small">
							<StackItem>
								<Button disabled={isLoading} isSecondary name="CANCEL" handleClick={hideModal} />
							</StackItem>
							<StackItem isStretch align="right">
								<Button
									disabled={!isFormValid()}
									name={modalParams.isStoreDemand ? 'Select Stores' : 'Review'}
									handleClick={handleNextClick}
								/>
							</StackItem>
						</Stack>
					</StackItem>
				</Stack>
			</ModalActions>
		</>
	);
};

export default KitParams;
