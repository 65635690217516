// @ts-nocheck
import Modal from 'antd/lib/modal/Modal';
import Icon from 'atoms/icons/icon';
import Label from 'atoms/label/label';
import Loader from 'atoms/loader';
import { isAppActive, isInvalidUser, isLoggedOut } from 'models/subscriptions/selector';
import {  getUserInfo } from 'models/user/selector';
import { SignOutButton } from 'molecules/authentication/authentication';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import Heading from 'atoms/heading/heading';
import { isLoadingEnabled } from 'models/loaders/selectors';
import { useSelector } from 'react-redux';

export const AppLoading = (props: any) => {
	const isActive = useSelector(state => isAppActive(state));
	const isUserInValid: any = useSelector(state => isInvalidUser(state));
	const userInfo: any = useSelector(state => getUserInfo(state));
	const isLoggedOutUser = useSelector(isLoggedOut);

	const isUserDataloading = useSelector(state => isLoadingEnabled(state, 'user-data-loading'));
	if (isActive) {
		localStorage.setItem('dbHealthTimestamp', new Date());
	}

	if (isUserInValid && isActive) {
		return (
			<div style={{ width: '100%', height: '100%' }}>
				<Modal
					centered
					visible
					closable={false}
					maskClosable={false}
					footer={null}
					className="modal-invalid-user"
				>
					<Stack gutter="small" isVertical align="center">
						<StackItem>
							<Heading type="primary">Invalid Credentials</Heading>
						</StackItem>
						<StackItem>
							<Icon imgAlias="ThetaLogoDark" size="large" />
						</StackItem>
						<StackItem>
							<Label>
								<Stack isVertical align="center">
									<StackItem>
										<strong>{userInfo?.userEmail}</strong>
									</StackItem>
									<StackItem>
										does not have data access. Please reach out to your administrator or
									</StackItem>
									<StackItem>
										<a style={{ marginRight: '2.5px' }} href="mailto:help@skulicity.com">help@skulicity.com</a> for assistance.
									</StackItem>
									<StackItem></StackItem>
								</Stack>
							</Label>
						</StackItem>
						<StackItem>
							<SignOutButton></SignOutButton>
						</StackItem>
					</Stack>
				</Modal>
			</div>
		);
	}

	return (
		!isLoggedOutUser && (
			<div style={{ width: '100%', height: 'calc(100% - 10px)', overflow: 'auto' }}>
				{isActive && !isUserDataloading ? (
					props.children
					// <SelectTenent>{props.children}</SelectTenent>
				) : (
					<Modal centered visible closable={false} maskClosable={false} footer={null}>
						<Stack gutter="medium" isVertical align="center">
							<StackItem>
								<Loader />
							</StackItem>

							{navigator.onLine ? (
								<StackItem>Please wait while we get things ready for you.</StackItem>
							) : (
								<StackItem>Network connection issue, please reconnect to continue.</StackItem>
							)}
						</Stack>
					</Modal>
				)}
			</div>
		)
	);
};
