// @ts-nocheck
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import ModalHeader from 'atoms/modal/modalHeader';
import ReactModal from 'atoms/modal/reactModal';
import { Tab } from 'atoms/tab/tab';
import Tabs from 'atoms/tabs/tabs';
import _ from 'lodash';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as selectors from './selector';
import * as sagaActions from './sagaActions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReviewGroup from './reviewKit';
import SelectStores from './selectStores';
import StartGroup from './kitParams';
import { getKitsModalState } from './selector';
import { MODAL_STEPS } from './constants';
import KitParams from './kitParams';
import ReviewKit from './reviewKit';
import { updateModalToggle } from './reducer';
type TProps = {
	name: string;
	hideModal: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
};

const steps = [
	{
		title: 'Select Parameters',
		identifier: MODAL_STEPS.SELECT_PARAMS
	},
	{
		title: 'Select Stores',
		identifier: MODAL_STEPS.SELECT_STORES
	},
	{
		title: 'Review',
		identifier: MODAL_STEPS.REVIEW
	}
];

const KitsModal = (props: TProps) => {
	const { name } = props;
	const [selectedStep, setSelectedStep] = React.useState<any>(steps[0]);
	const isModalEnabled = useSelector(getKitsModalState);
	const dispatch = useDispatch();

	const hideModal = () => {
		setSelectedStep(steps[0]);
		dispatch(updateModalToggle({ isOpen: false }));
		dispatch(sagaActions.cleanUp());
	};
	const modalParams: any = useSelector(state => selectors.getModalParams(state));

	const getDisabledStatus = (identifier: string) => {
		switch (identifier) {
			case MODAL_STEPS.SELECT_PARAMS:
				return false;
			case MODAL_STEPS.SELECT_STORES:
				return false;
			case MODAL_STEPS.REVIEW:
				return !Boolean(Object.keys(modalParams).length);
			default:
				return true;
		}
	};

	const getScreen = React.useCallback(() => {
		switch (selectedStep.identifier) {
			case MODAL_STEPS.SELECT_STORES:
				return (
					<SelectStores
						goBack={() => setSelectedStep(steps[0])}
						handleNextClick={() => setSelectedStep(steps[2])}
						hideModal={hideModal}
					/>
				);
			case MODAL_STEPS.REVIEW:
				return (
					<ReviewKit
						goBack={() => setSelectedStep(steps[modalParams.isStoreDemand ? 1 : 0])}
						handleNextClick={hideModal}
						hideModal={hideModal}
					/>
				);
			case MODAL_STEPS.SELECT_PARAMS:
			default:
				return (
					<KitParams
						handleNextClick={() => setSelectedStep(steps[modalParams.isStoreDemand ? 1 : 2])}
						hideModal={hideModal}
					/>
				);
		}
	}, [selectedStep.identifier, modalParams]);

	if (!isModalEnabled) {
		return null;
	}

	const updatedSteps = _.filter(steps, (s, i) => {
		if (i === 1 && !modalParams.isStoreDemand) {
			return false;
		}
		return true;
	});

	return (
		<ReactModal hideModal={hideModal} height={700} width={700}>
			<ModalHeader>
				<Stack isVertical gutter="small">
					<StackItem isStretch>
						<Heading>Kit Creator</Heading>
					</StackItem>
					<StackItem isStretch>
						<Divider />
					</StackItem>
					<StackItem isStretch>
						<Stack isVertical gutter="small">
							<StackItem isStretch>
								<Tabs type="secondary">
									{_.map(updatedSteps, (step, index) => {
										return (
											<Tab
												key={index}
												value={index}
												isSelected={selectedStep.identifier === step.identifier}
												onSelectTabItem={() => setSelectedStep(step)}
												disabled={getDisabledStatus(step.identifier)}
											>
												{step.title}
											</Tab>
										);
									})}
								</Tabs>
							</StackItem>
						</Stack>
					</StackItem>
				</Stack>
			</ModalHeader>
			{getScreen()}
		</ReactModal>
	);
};

export default KitsModal;
