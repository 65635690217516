// @ts-nocheck
import Breadcrumbs from 'atoms/breadcrumbs/breadcrumbs';
import Sheet from 'atoms/sheet/sheet';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as adModalSagaActions from 'pages/advertising/adModal/sagaActions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdvertisingTemplate from 'templates/advertising';
import { SEARCH_PARAMS, TABLE_ID } from './constants';
import MasterDataAdTypeModal from 'organisms/adTypeModal';
import * as adModalSelectors from 'pages/advertising/adModal/selector';
import _ from 'lodash';
import ThetaDeleteModal from 'organisms/deleteModal/deleteModal';
import * as advertisingSagaActions from 'models/advertisings/sagaActions';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import Button from 'atoms/button/button';
import Icon from 'atoms/icons/icon';
import ExportGrid from 'organisms/exportGrid';
import { REQUESTS } from 'utils/requests';
import { fetchGridData } from 'models/dynamicGrid/sagaActions';
import { isMasterDataAdTypesDelete, isMasterDataAdTypesWrite } from 'models/user/selector';
import GridActionBar from 'atoms/GridActionBar';
import { isNewUploadEnabled } from 'models/user/selector';
import  GridCsvUpload  from 'organisms/uploadData/gridCsvUpload';
import { getGridMetadata } from 'models/dynamicGrid/selector';

const name = 'adTypes';

const MasterDataAdTypes: React.FunctionComponent = props => {
	const [openFilter, setOpenFilter] = React.useState<boolean>(false);
	const [filters, setFilters] = React.useState<any>([]);
	const [selectedAd, setSelectedAd] = React.useState<any>({});
	const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
	const dispatch = useDispatch();
	const hasAdTypesDelete = useSelector(isMasterDataAdTypesDelete);
	const hasAdTypesWrite = useSelector(isMasterDataAdTypesWrite);
	const gridMeta = useSelector(state => getGridMetadata(state, TABLE_ID));
	const isNewUpload = useSelector(isNewUploadEnabled);

	const getTitle = (item: any) => {
		return item.AdType;
	};
	const getSubTitle = (item: any) => {
		return '';
	};
	const getDescription = (item: any) => {
		return '';
	};

	const getters = {
		getTitle: getTitle,
		getSubTitle: getSubTitle,
		getDescription: getDescription
	};

	const handleFilterClick = () => {
		setOpenFilter(!openFilter);
	};

	const toggleAdModal = (status: boolean) => {
		dispatch(adModalSagaActions.updateModalToggle(name, status));
	};

	const handleAddNewClick = () => {
		toggleAdModal(true);
	};
	const handleEditClick = (item: any) => {
		dispatch(adModalSagaActions.editAdType(name, item));
		setSelectedAd(item);
		// toggleAdModal(true)
	};
	const handleDeleteClick = (item: any) => {
		setSelectedAd(item);
		setDeleteModalOpen(true);
	};

	const getData = (data: any) => {
		let tabsData = _.cloneDeep(data) || [];

		if (filters && filters.length) {
			let groupedData = [];
			for (let filter of filters) {
				groupedData = _.filter(tabsData, function (item) {
					return item[filter.criteria.rowIdentifier] === filter.query;
				});
			}
			return groupedData;
		}
		return tabsData;
	};

	const handleUpdateFilter = (searchQueries: any) => {
		setFilters(searchQueries);
	};

	const onSelectGroup = (identifier: string) => {
		// setSelectedGroup(identifier)
		return;
	};

	const adModalOpen = useSelector(state => adModalSelectors.getAdModalState(state, name));

	const hideAdModal = () => {
		dispatch(adModalSagaActions.updateModalToggle(name, false));
		dispatch(adModalSagaActions.cleanUp());
	};

	const hideDeleteModal = (item: any) => {
		setSelectedAd(item);
		setDeleteModalOpen(false);
	};

	const handleAdTypeDelete = () => {
		dispatch(advertisingSagaActions.deleteAdType(selectedAd.AdTypeID));
		setDeleteModalOpen(false);
	};
	const gridConfig = {
		enableSelection: false,
		enableSorting: true,
		enableMultiSort: true,
		customActions: (hasAdTypesWrite || hasAdTypesDelete) && {
			width: 170,
			enabled: true,
			component: ({ row }) => {
				return (
					<Stack gutter="small" align="center">
						{hasAdTypesWrite && <StackItem>
							<Button handleClick={() => handleEditClick(row)}>Edit</Button>
						</StackItem>}
						{hasAdTypesDelete && <StackItem>
							<Button type="secondary" handleClick={() => handleDeleteClick(row)}>
								Delete
							</Button>
						</StackItem>}
					</Stack>
				);
			}
		},
		selectionCallBack: () => { }
	};
	const handleRefreshClick = React.useCallback(() => {
		dispatch(fetchGridData(TABLE_ID));
	}, [dispatch]);

	const templateConfig = React.useMemo(
		() => ({
			gridConfig,
			tableId: TABLE_ID,
			isFilterEnabled: openFilter === undefined ? false : openFilter,

			selectRowKey: 'AdTypeID',
			dataProviderOptions: {
				type: 'POST',
				version: 2,
				...REQUESTS.POST.GET_GRID_DETAILS_V2,
				pathParams: { tableId: TABLE_ID },
				body: {}
			}
		}),
		[openFilter]
	);

	return (
		<>
			<Stack isVertical>
				<StackItem>
					<GridActionBar>
						<Stack>
							<StackItem>
								<Stack gutter="medium">
									<StackItem isGrow>
										<StackItem>
											<Breadcrumbs crumbs={props.breadcrumbs} />
										</StackItem>
									</StackItem>
								</Stack>
							</StackItem>
							<StackItem isGrow>
								<Stack gutter="medium" align="right" position="center">
									<StackItem isGrow>
										{hasAdTypesWrite && <Button handleClick={handleAddNewClick}> Create New Ad </Button>}
									</StackItem>
									<StackItem>
										<Icon isActive type="secondary" faAlias="faRecycle" onClick={handleRefreshClick} />
									</StackItem>
									{isNewUpload && gridMeta?.UploadTableID && <StackItem>
								<GridCsvUpload uploadTableId={gridMeta?.UploadTableID} parentTableId={TABLE_ID} />
							</StackItem>}
									<StackItem>
										<ExportGrid tableId={TABLE_ID} />
									</StackItem>
								</Stack>
							</StackItem>
						</Stack>
					</GridActionBar>
				</StackItem>
				<StackItem isStretch isGrow>
					<Sheet isPage>
						{/* <Stack isVertical gutter="small">
					<StackItem>
						<Breadcrumbs crumbs={props.breadcrumbs} />
					</StackItem>
					<StackItem isGrow isStretch></StackItem>
				</Stack> */}

						<Stack isVertical gutter="medium">

							<StackItem isGrow isStretch>
								<ThetaGridPaginated config={templateConfig} />
							</StackItem>
						</Stack>
					</Sheet>
				</StackItem>
			</Stack>

			{adModalOpen && <MasterDataAdTypeModal hideModal={hideAdModal} />}
			{deleteModalOpen && (
				<ThetaDeleteModal
					hideModal={hideDeleteModal}
					modalTitle={'Ad'}
					title={selectedAd.AdType}
					handleDeleteClick={handleAdTypeDelete}
				/>
			)}
		</>
	);
};

export default MasterDataAdTypes;
