// @ts-nocheck
import { Input } from 'antd';
import Validation from 'atoms/validation/validation';
import _ from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as dynamicGridSagaActions from '../dynamicGrid/sagaActions';
import { RenderCell } from './gridComponents';
import { getGridMetadata } from 'models/dynamicGrid/selector';

const RenderInput = (props: any) => {
	const dispatch = useDispatch();
	const [debounce, updateDebounce] = React.useState(null);
	const {
		row,
		rowIdentifier,
		tableId,
		isPaginated,
		isUploadGrid,
		id,
		type = 'number',
		isInlineEdit,
		align,
		params,
		isPivotMode
	} = props;
	const gridMetaData = useSelector(state => getGridMetadata(state, tableId));
	const value = row?.[rowIdentifier]?.hasOwnProperty('value') ? row?.[rowIdentifier]?.value:  row?.[rowIdentifier];
	
	const [inputValue, updateInputValue] = React.useState(value);
	const handleUpdate = parsedValue => {
		updateInputValue(parsedValue);
		clearTimeout(debounce);
		let debounceFn = setTimeout(
			() => {
				if (_.isEmpty(parsedValue)) {
					return;
				}
				dispatch(
					dynamicGridSagaActions.updateRowData({
						tableId: Number(tableId),
						row,
						rowIdentifier,
						isUploadGrid,
						updatedValue: type === 'number' ? Number(parsedValue) : parsedValue,
						isInlineEdit,
						isGroupNode: params?.node?.group,
						isPivotMode: isPivotMode
					})
				);
			},
			2000 || isPaginated ? 1000 : 100
		);
		updateDebounce(debounceFn);
	};

	React.useEffect(() => {
		return () => updateInputValue(value);
	}, [value]);

	React.useEffect(() => {
		let value =  row?.[rowIdentifier]?.hasOwnProperty('value') ? row?.[rowIdentifier]?.value:  row?.[rowIdentifier];
		updateInputValue(value);
	}, [row?.[rowIdentifier]]);

	if (row?.IsParent === 0 || !row.hasOwnProperty(rowIdentifier)) {
		return <RenderCell row={row} rowIdentifier={rowIdentifier} type={'number'} />;
	}
	
	const hasGridEditOperation = gridMetaData?.dataProviderOptions?.isGridEditDisabledOverride;
	const isLocked = row?.IsReadOnly || row?.OrderStatusID === 2 || hasGridEditOperation ||  false;
	return (
		<div className="render-input-right">
			<Validation row={row} rowIdentifier={rowIdentifier}  {...row[rowIdentifier]}>
				<Input
					type={type === 'number' || type === 'qty' ? 'number' : 'text'}
					min={tableId === 27 ? null : 0}
					onChange={e => {
						handleUpdate(e.target.value, {});
						e.stopPropagation();
					}}
					placeholder=""
					value={inputValue}
					step={row['QtyPerShelf'] || row['OrderMultiple']}
					disabled={isLocked}
				/>
			</Validation>
		</div>
	);
};

export default RenderInput;
