// @ts-nocheck
import { Modal } from 'antd';
import Button from 'atoms/button/button';
import Icon from 'atoms/icons/icon';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import { hasNetworkError } from 'models/subscriptions/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import { useCallback } from 'react';
import { useLayoutEffect } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// import './userBar.scss';
// import { ReactComponent as UserSVG } from '../../atoms/miscSvg/user.svg';
// import { logOut, getUserBarData, checkLocal, getUserRefresh, getUserGraphData } from '../../API.js';
// import { parseJwt } from '../../Utilities.js';

export const NetworkValidator = props => {
	const isNetworkError = useSelector(hasNetworkError);

	return isNetworkError ? (
		<div
			style={{
				height: '25px',
				width: '100%',
				background: '#ff0000c4',
				textAlign: 'center',
				verticalAlign: 'center',
				fontSize: '14px',
				color: '#001528'
			}}
		>
			<Stack align="center" gutter="small">
				<StackItem>
					<Icon faAlias="faInfoCircle" size="small" />
				</StackItem>
				<StackItem isStretch align="center" position="center">
					Network Connection Error
				</StackItem>
			</Stack>
		</div>
	) : null;
};
