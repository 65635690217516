// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';
import { table } from 'console';

export const getPaginationQuery = createAction('paginationModel/get-pagination', function prepare(name, tableId) {
	return {
		payload: {
			name,
			tableId
		}
	};
});

export const updatePagination = createAction(
	'paginationModel/update-pagination',
	function prepare(tableId, { pageNo, perPage, sortField, sortDirection }) {
		return {
			payload: {
				tableId,
				pageNo,
				perPage,
				sortDirection,
				sortField
			}
		};
	}
);
