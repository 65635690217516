// @ts-nocheck
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import Loader from 'atoms/loader';
import ModalHeader from 'atoms/modal/modalHeader';
import ReactModal from 'atoms/modal/reactModal';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import Button from 'atoms/button/button';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import SelectInput from 'atoms/selectInput/selectInput';
import * as constants from './constants';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as sagaActions from '../sagaActions';
import * as selectors from '../selectors';

const StoreScheduleDaysModal = (props: any) => {
	const { selectedStores, storeScheduleID, selectedStoresSearch } = props;
	const dispatch = useDispatch();

	const [selectedDays, setSelectedDays] = React.useState([]);

	const isLoading = useSelector(state => selectors.isLoading(state, 'updateScheduleDays'));

	const hideModal = React.useCallback(() => {
		props.hideModal();
	}, []);

	const cardClickHandler = React.useCallback(
		(day: any) => {
			const index = _.findIndex(selectedDays, day);
			const newSelectedDays: any = [...selectedDays];

			if (index >= 0) {
				newSelectedDays.splice(index, 1);
			} else {
				newSelectedDays.push(day);
			}
			setSelectedDays(newSelectedDays);
		},
		[selectedDays]
	);

	const submitHandler = React.useCallback(() => {
		dispatch(
			sagaActions.updateScheduleDays({
				storeScheduleId: storeScheduleID,
				days: _.map(selectedDays, 'value'),
				stores: selectedStores?.selectedRowKeys,
				selectAllSearch: selectedStoresSearch,
				isSelectAll: selectedStores.isSelectAll
			})
		);
	}, [selectedDays, selectedStores, selectedStoresSearch]);

	// const isLoading = false;

	return (
		<ReactModal hideModal={hideModal} height={600} width={600}>
			<ModalHeader>
				<Stack isVertical gutter="small">
					<StackItem isStretch>
						<Heading>Update Store Schedule</Heading>
					</StackItem>
					<StackItem isStretch>
						<Divider />
					</StackItem>
				</Stack>
			</ModalHeader>

			<ModalBody>
				{isLoading ? (
					<Stack isVertical>
						<StackItem isGrow isStretch align="center">
							<Loader />
						</StackItem>
					</Stack>
				) : (
					<Stack isVertical gutter="small">
						<StackItem isStretch>
							<Stack isVertical gutter="small">
								<StackItem isGrow>
									<Heading type="tertiary"> Days to Schedule </Heading>
								</StackItem>
							</Stack>
						</StackItem>
						<StackItem isStretch>
							<Stack isWrap>
								{_.map(constants.DAYS, (day, index) => (
									<Card
										key={index}
										day={day}
										selected={selectedDays}
										handleClick={() => {
											cardClickHandler(day);
										}}
									/>
								))}
							</Stack>
						</StackItem>
					</Stack>
				)}
			</ModalBody>
			<ModalActions>
				<Stack align="right" gutter="small">
					<StackItem>
						<Button disabled={isLoading} isSecondary name="CANCEL" handleClick={props.hideModal} />
					</StackItem>

					<StackItem isStretch align="right">
						<Button
							name="Submit"
							disabled={!selectedDays?.length || isLoading}
							handleClick={submitHandler}
						/>
					</StackItem>
				</Stack>
			</ModalActions>
		</ReactModal>
	);
};

export default StoreScheduleDaysModal;

const Card = (props: any) => {
	const { selected, day, handleClick } = props;
	return (
		<StackItem>
			<SelectInput text={day.key} selected={_.includes(selected, day)} handleSelectInputClick={handleClick} />
		</StackItem>
	);
};
