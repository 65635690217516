// @ts-nocheck
import _ from 'lodash';
import { put, takeEvery, all, select } from 'redux-saga/effects';
import { getAPIDataV2, putAPIDataV2 } from 'utils/api';
import * as sagaActions from './sagaActions';
import * as slice from './reducer';
import qs from 'qs';

export const parseTableColumns = columns => {
	const data = {};
	const result = {};

	_.map(columns, column => {
		if (!data[column.AppColumnGroup]) {
			data[column.AppColumnGroup] = [];
		}

		data[column.AppColumnGroup].push(column);
	});

	const intermediate = [];

	for (const key in data) {
		intermediate.push({
			key,
			length: data[key].length
		});
	}

	const sortedOrder = _.orderBy(intermediate, ['length'], ['desc']);

	for (const order of sortedOrder) {
		result[order.key] = data[order.key];
	}

	return result;
};

function* handleFetchColumns({ payload }: any) {
	const { tableId } = payload;
	let columns = yield getAPIDataV2(`table/columns/${tableId}`).then(response => response?.data.data);
	columns = parseTableColumns(columns);
	yield put(slice.handleUpdateFetchedColumns({ tableId, columns }));
}

function* onUpdateSelectedColumns({ payload }) {
	const { tableId, selectedColumns } = payload;
	yield put(slice.handleUpdateSelectedColumns({ tableId, selectedColumns }));
}

function* handleColumnsUpdate({ payload }) {
	const { tableId, columns } = payload;
	yield put(slice.handleUpdateColumns({ tableId, columns }));
}
// to be deprecated
function* handleUpdatetableColumns({ payload }) {
	// const { selectedTab, selectedColumns } = payload;
	// const data = {
	// 	ColumnId: selectedColumns.join(',')
	// };
	
	// yield putAPIDataV3({service: `custom/${selectedTab}`, url: data});
	// handleFetchColumns({ payload: { tableId: selectedTab } });
}
export default function* rootSaga() {
	return [
		yield takeEvery(sagaActions.fetchColumns, handleFetchColumns),
		yield takeEvery(sagaActions.updateSelectedColumns, onUpdateSelectedColumns),
		yield takeEvery(sagaActions.updateColumns, handleColumnsUpdate),
		yield takeEvery(sagaActions.updateTableColumns, handleUpdatetableColumns)
	];
}
