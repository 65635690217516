// @ts-nocheck
import { userInfo } from 'os';
import React from 'react';
import Home from './home';
import GenerateOrder from './generateOrderModal';

import generateOrderModalSagas from './generateOrderModal/sagas';
import generateOrderModalReducer from './generateOrderModal/reducer';
import OrderReducer from './newOrders/reducer';
import OrderSagas from './newOrders/sagas';
import HomeReducer from './reducer';
import HomeSagas from './sagas';
import { isCreateOrderReadWrite } from 'models/user/selector';
export default [
	{
		path: '/',
		key: 'key',
		componentId: 'home',
		component: () => <Home title="asd" />,
		routes: [],
		importReducer: () => {
			return [OrderReducer, HomeReducer];
		},
		importSagas: () => {
			return [OrderSagas, HomeSagas];
		},
		title: 'Skulicity | Home'
	},
	{
		path: '/home',
		key: 'key',
		componentId: 'home',
		component: () => <Home title="asd" />,
		routes: [],
		importReducer: () => {
			return [OrderReducer, HomeReducer];
		},
		importSagas: () => {
			return [OrderSagas, HomeSagas];
		},
		title: 'Skulicity | Home'
	},
	{
		path: '/create',
		key: 'key',
		componentId: 'create_order',
		headerName: 'Generate New Orders',
		component: () => <GenerateOrder title="asd" />,
		routes: [],
		importReducer: () => {
			return [OrderReducer, HomeReducer, generateOrderModalReducer];
		},
		isEnabled: (state) => {
			return isCreateOrderReadWrite(state)
		},
		importSagas: () => {
			return [OrderSagas, HomeSagas, generateOrderModalSagas];
		},
		title: 'Skulicity | Home'
	}
];
