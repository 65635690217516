// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import * as constants from './constants';
import update from 'react-addons-update';

export const initialState: any = {
	adData: {},
	isOpen: false,
	pricingData: [],
	pricingDataId: null,
	editMode: false,
	editData: {},
	adTypeData: {}
};

const slice = createSlice({
	initialState,
	name: `${constants.MODEL_NAME}`,
	reducers: {
		updateAdData(state, { payload: { eventName, data } }) {
			return {
				...state,
				adData: data
			};
		},
		updatePricingDataId(state, { payload: { pricingDataId } }) {
			return {
				...state,
				pricingDataId
			};
		},
		updateModalToggle(state, { payload: { eventName, isOpen } }) {
			return {
				...state,
				isOpen: isOpen
			};
		},
		updatePricingData(state, { payload: { data } }) {
			return {
				...state,
				pricingData: data
			};
		},
		updateEditMode(state, { payload: { edit } }) {
			return {
				...state,
				editMode: edit
			};
		},
		updateTableCell(state, { payload: { tableId, row, rowIdentifier, updatedValue } }) {
			return update(state, {
				pricingData: {
					[row.key]: {
						[rowIdentifier]: { $set: updatedValue }
					}
				}
			});
		},
		updateEditData(state, { payload: { editData } }) {
			return {
				...state,
				editData: editData
			};
		},
		cleanUp() {
			return {
				...initialState
			};
		},
		updateAdTypeData(state, { payload: { eventName, data } }) {
			return {
				...state,
				adTypeData: data
			};
		}
	}
});

export default slice;

export const {
	updateAdData,
	updateModalToggle,
	updatePricingData,
	updateEditMode,
	updatePricingDataId,
	updateTableCell,
	updateEditData,
	cleanUp,
	updateAdTypeData
} = slice.actions;
