// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { IToolPanel } from 'ag-grid-community';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import Heading from 'atoms/heading/heading';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import Label from 'atoms/label/label';
import Dropdown from 'atoms/dropdown/dropdown';
import Icon from 'atoms/icons/icon';
import { Tag, Tooltip } from 'antd';
import Divider from 'atoms/divider/divider';
import Link from 'atoms/link/link';
import Button from 'atoms/button/button';
import Modal from 'atoms/modal/modal';
import TextInput from 'atoms/TextInput/textInput';
import ReactModal from 'atoms/modal/reactModal';
import ModalBody from 'atoms/modal/modalBody';
import ModalHeader from 'atoms/modal/modalHeader';
import ModalActions from 'atoms/modal/modalActions';
import { getDispatch } from 'reducers';
import { applyBookmark, deleteBookmark, fetchBookmarksList, saveBookmark } from 'models/bookmarks/sagaActions';
import { useSelector } from 'react-redux';
import { getUserGridBookmarks } from 'models/bookmarks/selectors';
import { BookmarkDialog } from './bookMarkDialog';
import ThetaMenu from 'organisms/thetaMenu/thetaMenu';
import { DROPDOWN_MENU_BOOKMARK, DROPDOWN_VALUES } from './constants';
import BodyText from 'atoms/bodyText/bodyText';
import { isLoadingEnabled } from 'models/loaders/selectors';
import Loader from 'atoms/loader';

interface CustomToolPanelProps extends IToolPanel {}

export const Bookmarks: React.FC<CustomToolPanelProps> = props => {
	const tableId = props?.columnApi?.columnModel?.columnDefs[0]?.metaData?.tableId;
	const [bookmarkDialogConfig, setBookmarkDialog] = useState({ isEnabled: false, bookmark: null });
	const gridBookmarks = useSelector(state => getUserGridBookmarks(state, tableId));
	const isLoadingBookmarks = useSelector(state => isLoadingEnabled(state, 'bookmarks'));
	// implement the IToolPanel interface
	const dispatch = getDispatch();
	useEffect(() => {
		dispatch(fetchBookmarksList({ tableId }));
	}, []);
	const handleApplyBookmark = bookmarkId => {
		dispatch(applyBookmark({ bookmarkId, tableId }));
	};
	const handleDeleteBookmark = bookmarkId => {
		dispatch(deleteBookmark({ bookmarkId, tableId }));
	};
	const handleMenuItemClick = (value: string, bookmark) => {
		switch (value) {
			case DROPDOWN_VALUES.UPDATE:
				dispatch(
					saveBookmark({
						tableId,
						bookmarkId: bookmark?.BookmarkID,
						bookmarkName: bookmark?.BookmarkName
					})
				);
				return;
			case DROPDOWN_VALUES.RENAME:
				setBookmarkDialog({
					isEnabled: true,
					bookmark
				});
				return;
			case DROPDOWN_VALUES.DELETE:
				dispatch(deleteBookmark({ bookmarkId: bookmark?.BookmarkID, tableId }));
				return;
		}
	};
	return (
		<>
			{bookmarkDialogConfig?.isEnabled && (
				<BookmarkDialog
					onHide={() => setBookmarkDialog(null)}
					selectedBookmark={bookmarkDialogConfig?.bookmark}
					tableId={tableId}
				/>
			)}
			<Sheet>
				<SheetBody>
					<Stack isVertical gutter="small">
						<StackItem>
							<Stack>
								<StackItem isGrow>
									<Heading type="primary">Bookmarks</Heading>
								</StackItem>
								<StackItem>
									<Button size="small" handleClick={() => setBookmarkDialog({ isEnabled: true })}>
										<Stack align="space-between" gutter="small" position="center">
											<StackItem isGrow align="center">
												Add
											</StackItem>
											<StackItem isStretch>
												<Divider size="small" isVertical></Divider>
											</StackItem>
											<StackItem>
												<Icon disabled={false} isPadded={false} faAlias="faPlus" size="tiny" />
											</StackItem>
										</Stack>
									</Button>
								</StackItem>
							</Stack>
						</StackItem>
						<StackItem isStretch>
							<Divider></Divider>
						</StackItem>
						{isLoadingBookmarks ? (
							<StackItem>
								<Loader size="small" />
							</StackItem>
						) : (
							<StackItem isStretch>
								<Stack isVertical gutter="medium">
									<StackItem isStretch>
										<Label isHighlight>Select Bookmark</Label>
									</StackItem>
									<StackItem isVertical isGrow>
										<Stack gutter="small" isVertical>
											{_.map(gridBookmarks, ({ BookmarkName, BookmarkID }) => (
												<StackItem isStretch align="center" key={BookmarkID}>
													<Stack gutter="none">
														<StackItem isStretch isGrow>
															<Tag
																style={{ width: '100%', height: '2rem' }}
																onClick={() => handleApplyBookmark(BookmarkID)}
															>
																<Tooltip title={BookmarkName}>
																	<BodyText ellipsis={35}>{BookmarkName}</BodyText>
																</Tooltip>
															</Tag>
														</StackItem>
														<StackItem>
															<ThetaMenu
																type="3DotsButton"
																options={DROPDOWN_MENU_BOOKMARK}
																handleItemClick={params =>
																	handleMenuItemClick(params, {
																		BookmarkName,
																		BookmarkID
																	})
																}
																isDisabled={() => false}
															/>
														</StackItem>
													</Stack>
												</StackItem>
											))}
										</Stack>
									</StackItem>
								</Stack>
							</StackItem>
						)}
					</Stack>
				</SheetBody>
			</Sheet>
		</>
	);
};
