// @ts-nocheck
import _ from 'lodash';
import { initialState } from './reducer';
import { APP_OPERATIONS } from './constants';

const stateData = (state: any): TReducer => state['user'] || initialState;

export const getUserInfo = state => stateData(state);
export const getUserEmail = state => stateData(state)?.userEmail;
export const getUserGrpId = state => stateData(state)?.grpId;
export const getUserTenentList = state => stateData(state)?.allTenentList;
export const getWebAppSettings = state => stateData(state)?.webAppSettings ?? {};
export const getUserSettings = state => getUserInfo(state).settings;
export const getAclOperations = state => stateData(state)?.operations;
export const getAclRole = state => stateData(state)?.role;
export const getCurrentWorkspace = state => stateData(state)?.currentWorkspace ?? {};
export const isAccessControlEnabled = state => getUserSettings(state)?.IsAccessControlEnabled;
export const isNewUploadEnabled = state => getUserSettings(state)?.IsNewUploadEnabled;

export const hasAclOperations = state => isAccessControlEnabled(state) ? Boolean(getAclOperations(state)?.length) : true

const findAccessOperation = (state, operation) => {
    return Boolean(getAclOperations(state).find(o => o.operation === operation))

    // return isAccessControlEnabled(state) ? Boolean(getAclOperations(state).find(o => o.operation === operation)) : true
}

export const isCreateOrderRead = state => findAccessOperation(state, APP_OPERATIONS.OP_CREATE_ORDER_READ);
export const isCreateOrderReadWrite = state => isCreateOrderRead(state) || findAccessOperation(state, APP_OPERATIONS.OP_CREATE_ORDER_WRITE);


export const isAvailabilityRead = state => findAccessOperation(state, APP_OPERATIONS.OP_AVAILABILITY_READ);
export const isAvailabilityWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_AVAILABILITY_WRITE);
export const isAvailabilityDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_AVAILABILITY_DELETE);
export const isAvailabilityReadWrite = state => Boolean(isAvailabilityRead(state) || isAvailabilityWrite(state));


export const isAvailabilitySummaryRead = state => findAccessOperation(state, APP_OPERATIONS.OP_AVAILABILITY_SUMMARY_READ);
export const isAvailabilitySummaryWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_AVAILABILITY_SUMMARY_WRITE);
export const isAvailabilitySummaryDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_AVAILABILITY_SUMMARY_DELETE);
export const isAvailabilitySummaryReadWrite = state => isAvailabilitySummaryRead(state) || isAvailabilitySummaryWrite(state);


export const isAllOrdersRead = state => findAccessOperation(state, APP_OPERATIONS.OP_ALL_ORDERS_READ)
export const isAllOrdersWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_ALL_ORDERS_WRITE);
export const isAllOrdersDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_ALL_ORDERS_DELETE);
export const isAllOrdersReadWrite = state => isAllOrdersRead(state) || isAllOrdersWrite(state);

export const isOrderItemsRead = state => findAccessOperation(state, APP_OPERATIONS.OP_ORDER_ITEMS_READ)
export const isOrderItemsWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_ORDER_ITEMS_WRITE);
export const isOrderItemsDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_ORDER_ITEMS_DELETE);
export const isOrderItemsReadWrite = state => isOrderItemsRead(state) || isOrderItemsWrite(state);


export const isOrderSkusRead = state => findAccessOperation(state, APP_OPERATIONS.OP_ORDER_SKUS_READ)
export const isOrderSkusWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_ORDER_SKUS_WRITE);
export const isOrderSkusDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_ORDER_SKUS_DELETE);
export const isOrderSkusReadWrite = state => isOrderSkusRead(state) || isOrderSkusWrite(state);

export const isOrderKitsRead = state => findAccessOperation(state, APP_OPERATIONS.OP_ORDER_KITS_READ)
export const isOrderKitsWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_ORDER_KITS_WRITE);
export const isOrderKitsDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_ORDER_KITS_DELETE);
export const isOrderKitsReadWrite = state => isOrderKitsRead(state) || isOrderKitsWrite(state);

export const isAdvertisingRead = state => findAccessOperation(state, APP_OPERATIONS.OP_ADVERTISING_READ)
export const isAdvertisingWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_ADVERTISING_WRITE);
export const isAdvertisingDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_ADVERTISING_DELETE);
export const isAdvertisingReadWrite = state => isAdvertisingRead(state) || isAdvertisingWrite(state);


export const isParametersRead = state => findAccessOperation(state, APP_OPERATIONS.OP_PARAMETERS_READ)
export const isParametersWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_PARAMETERS_WRITE);
export const isParametersDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_PARAMETERS_DELETE);
export const isParametersReadWrite = state => isParametersRead(state) || isParametersWrite(state);


export const isDemandChangeRead = state => findAccessOperation(state, APP_OPERATIONS.OP_DEMAND_CHANGE_READ)
export const isDemandChangeWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_DEMAND_CHANGE_WRITE)
export const isDemandChangeDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_DEMAND_CHANGE_DELETE)
export const isDemandChangeReadWrite = state => isDemandChangeRead(state) || isDemandChangeWrite(state);

export const isDemandReportingRead = state => findAccessOperation(state, APP_OPERATIONS.OP_DEMAND_REPORTING_READ)
export const isDemandReportingWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_DEMAND_REPORTING_WRITE)
export const isDemandReportingDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_DEMAND_REPORTING_DELETE)
export const isDemandReportingReadWrite = state => isDemandReportingRead(state) || isDemandReportingWrite(state);

export const isDemandGroupHubRead = state => findAccessOperation(state, APP_OPERATIONS.OP_DEMAND_GROUP_HUB_READ)
export const isDemandGroupHubWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_DEMAND_GROUP_HUB_WRITE)
export const isDemandGroupHubDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_DEMAND_GROUP_HUB_DELETE)
export const isDemandGroupHubReadWrite = state => isDemandGroupHubRead(state) || isDemandGroupHubWrite(state);

export const isIntelligenceRead = state => findAccessOperation(state, APP_OPERATIONS.OP_INTELLIGENCE_READ)


export const isAppSettingsRead = state => findAccessOperation(state, APP_OPERATIONS.OP_APP_SETTINGS_READ);
export const isAppAliasSettingWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_APP_SETTINGS_WRITE)
export const isAppAliasSettingDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_APP_SETTINGS_DELETE)
export const isAppAliasSettingReadWrite = state => isAppSettingsRead(state) || isAppAliasSettingWrite(state);


// Define the access operation functions for "Ad Types" module
export const isMasterDataAdTypesRead = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_AD_TYPES_READ);
export const isMasterDataAdTypesWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_AD_TYPES_WRITE);
export const isMasterDataAdTypesDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_AD_TYPES_DELETE);
export const isMasterDataAdTypesReadWrite = state => isMasterDataAdTypesRead(state) || isMasterDataAdTypesWrite(state);

// Define the access operation functions for "Customers" module
export const isMasterDataCustomersRead = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_CUSTOMERS_READ);
export const isMasterDataCustomersWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_CUSTOMERS_WRITE);
export const isMasterDataCustomersDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_CUSTOMERS_DELETE);
export const isMasterDataCustomersReadWrite = state => isMasterDataCustomersRead(state) || isMasterDataCustomersWrite(state);

// Define the access operation functions for "Groups" module
export const isMasterDataGroupsRead = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_GROUPS_READ);
export const isMasterDataGroupsWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_GROUPS_WRITE);
export const isMasterDataGroupsDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_GROUPS_DELETE);
export const isMasterDataGroupsReadWrite = state => isMasterDataGroupsRead(state) || isMasterDataGroupsWrite(state);

// Define the access operation functions for "Inventory Transactions" module
export const isMasterDataInventoryTransactionsRead = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_INVENTORY_TRANSACTIONS_READ);
export const isMasterDataInventoryTransactionsWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_INVENTORY_TRANSACTIONS_WRITE);
export const isMasterDataInventoryTransactionsDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_INVENTORY_TRANSACTIONS_DELETE);
export const isMasterDataInventoryTransactionsReadWrite = state => isMasterDataInventoryTransactionsRead(state) || isMasterDataInventoryTransactionsWrite(state);

// Define the access operation functions for "Items" module
export const isMasterDataItemsRead = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_ITEMS_READ);
export const isMasterDataItemsWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_ITEMS_WRITE);
export const isMasterDataItemsDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_ITEMS_DELETE);
export const isMasterDataItemsReadWrite = state => isMasterDataItemsRead(state) || isMasterDataItemsWrite(state);

// Define the access operation functions for "Item Exclusions" module
export const isMasterDataItemExclusionsRead = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_ITEM_EXCLUSIONS_READ);
export const isMasterDataItemExclusionsWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_ITEM_EXCLUSIONS_WRITE);
export const isMasterDataItemExclusionsDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_ITEM_EXCLUSIONS_DELETE);
export const isMasterDataItemExclusionsReadWrite = state => isMasterDataItemExclusionsRead(state) || isMasterDataItemExclusionsWrite(state);

// Define the access operation functions for "Item Shipping Method Configuration" module
export const isMasterDataItemShippingMethodConfigurationRead = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_ITEM_SHIPPING_METHOD_CONFIGURATION_READ);
export const isMasterDataItemShippingMethodConfigurationWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_ITEM_SHIPPING_METHOD_CONFIGURATION_WRITE);
export const isMasterDataItemShippingMethodConfigurationDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_ITEM_SHIPPING_METHOD_CONFIGURATION_DELETE);
export const isMasterDataItemShippingMethodConfigurationReadWrite = state => isMasterDataItemShippingMethodConfigurationRead(state) || isMasterDataItemShippingMethodConfigurationWrite(state);

// Define the access operation functions for "Kits" module
export const isMasterDataKitsRead = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_KITS_READ);
export const isMasterDataKitsWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_KITS_WRITE);
export const isMasterDataKitsDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_KITS_DELETE);
export const isMasterDataKitsReadWrite = state => isMasterDataKitsRead(state) || isMasterDataKitsWrite(state);

// Define the access operation functions for "Parameters" module
export const isMasterDataParametersRead = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_PARAMETERS_READ);
export const isMasterDataParametersWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_PARAMETERS_WRITE);
export const isMasterDataParametersDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_PARAMETERS_DELETE);
export const isMasterDataParametersReadWrite = state => isMasterDataParametersRead(state) || isMasterDataParametersWrite(state);

// Define the access operation functions for "Pricing" module
export const isMasterDataPricingRead = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_PRICING_READ);
export const isMasterDataPricingWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_PRICING_WRITE);
export const isMasterDataPricingDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_PRICING_DELETE);
export const isMasterDataPricingReadWrite = state => isMasterDataPricingRead(state) || isMasterDataPricingWrite(state);

// Define the access operation functions for "Primary Store SKU" module
export const isMasterDataPrimaryStoreSkuRead = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_PRIMARY_STORE_SKU_READ);
export const isMasterDataPrimaryStoreSkuWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_PRIMARY_STORE_SKU_WRITE);
export const isMasterDataPrimaryStoreSkuDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_PRIMARY_STORE_SKU_DELETE);
export const isMasterDataPrimaryStoreSkuReadWrite = state => isMasterDataPrimaryStoreSkuRead(state) || isMasterDataPrimaryStoreSkuWrite(state);

// Define the access operation functions for "Schedules" module
export const isMasterDataSchedulesRead = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_SCHEDULES_READ);
export const isMasterDataSchedulesWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_SCHEDULES_WRITE);
export const isMasterDataSchedulesDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_SCHEDULES_DELETE);
export const isMasterDataSchedulesReadWrite = state => isMasterDataSchedulesRead(state) || isMasterDataSchedulesWrite(state);

// Define the access operation functions for "Shipments" module
export const isMasterDataShipmentsRead = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_SHIPMENTS_READ);
export const isMasterDataShipmentsWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_SHIPMENTS_WRITE);
export const isMasterDataShipmentsDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_SHIPMENTS_DELETE);
export const isMasterDataShipmentsReadWrite = state => isMasterDataShipmentsRead(state) || isMasterDataShipmentsWrite(state);

// Define the access operation functions for "Shipping Methods" module
export const isMasterDataShippingMethodsRead = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_SHIPPING_METHODS_READ);
export const isMasterDataShippingMethodsWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_SHIPPING_METHODS_WRITE);
export const isMasterDataShippingMethodsDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_SHIPPING_METHODS_DELETE);
export const isMasterDataShippingMethodsReadWrite = state => isMasterDataShippingMethodsRead(state) || isMasterDataShippingMethodsWrite(state);

// Define the access operation functions for "SKUs" module
export const isMasterDataSkusRead = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_SKUS_READ);
export const isMasterDataSkusWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_SKUS_WRITE);
export const isMasterDataSkusDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_SKUS_DELETE);
export const isMasterDataSkusReadWrite = state => isMasterDataSkusRead(state) || isMasterDataSkusWrite(state);

// Define the access operation functions for "SKU Exclusions" module
export const isMasterDataSkuExclusionsRead = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_SKU_EXCLUSIONS_READ);
export const isMasterDataSkuExclusionsWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_SKU_EXCLUSIONS_WRITE);
export const isMasterDataSkuExclusionsDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_SKU_EXCLUSIONS_DELETE);
export const isMasterDataSkuExclusionsReadWrite = state => isMasterDataSkuExclusionsRead(state) || isMasterDataSkuExclusionsWrite(state);

// Define the access operation functions for "Stores" module
export const isMasterDataStoresRead = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_STORES_READ);
export const isMasterDataStoresWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_STORES_WRITE);
export const isMasterDataStoresDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_STORES_DELETE);
export const isMasterDataStoresReadWrite = state => isMasterDataStoresRead(state) || isMasterDataStoresWrite(state);

// Define the access operation functions for "Store Order List" module
export const isMasterDataStoreOrderListRead = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_STORE_ORDER_LIST_READ);
export const isMasterDataStoreOrderListWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_STORE_ORDER_LIST_WRITE);
export const isMasterDataStoreOrderListDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_STORE_ORDER_LIST_DELETE);
export const isMasterDataStoreOrderListReadWrite = state => isMasterDataStoreOrderListRead(state) || isMasterDataStoreOrderListWrite(state);

// Define the access operation functions for "Valid SKU-Item Combinations" module
export const isMasterDataValidSkuItemCombinationsRead = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_VALID_SKU_ITEM_COMBINATIONS_READ);
export const isMasterDataValidSkuItemCombinationsWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_VALID_SKE_ITEM_COMBINATIONS_WRITE);
export const isMasterDataValidSkuItemCombinationsDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_VALID_SKU_ITEM_COMBINATIONS_DELETE);
export const isMasterDataValidSkuItemCombinationsReadWrite = state => isMasterDataValidSkuItemCombinationsRead(state) || isMasterDataValidSkuItemCombinationsWrite(state);

// Define the access operation functions for "Valid Store-SKU Combinations" module
export const isMasterDataValidStoreSkuCombinationsRead = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_VALID_STORE_SKU_COMBINATIONS_READ);
export const isMasterDataValidStoreSkuCombinationsWrite = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_VALID_STORE_SKU_COMBINATIONS_WRITE);
export const isMasterDataValidStoreSkuCombinationsDelete = state => findAccessOperation(state, APP_OPERATIONS.OP_MASTER_DATA_VALID_STORE_SKU_COMBINATIONS_DELETE);
export const isMasterDataValidStoreSkuCombinationsReadWrite = state => isMasterDataValidStoreSkuCombinationsRead(state) || isMasterDataValidStoreSkuCombinationsWrite(state);

export const isAclRolesReadEnabled = state => {
    return isAccessControlEnabled(state) && findAccessOperation(state, APP_OPERATIONS.OP_ACL_ROLES_READ)
}
export const isAclRolesWriteEnabled = state => hasAclOperations(state) && findAccessOperation(state, APP_OPERATIONS.OP_ACL_ROLES_WRITE);
export const isAclRolesReadWriteEnabled = state => (isAclRolesReadEnabled(state) || isAclRolesWriteEnabled(state));

export const isAclUsersReadEnabled = state => hasAclOperations(state) && findAccessOperation(state, APP_OPERATIONS.OP_ACL_USERS_READ);
export const isAclUsersWriteEnabled = state => hasAclOperations(state) && findAccessOperation(state, APP_OPERATIONS.OP_ACL_USERS_WRITE);
export const isAclUsersReadWriteEnabled = state => isAclUsersReadEnabled(state) || isAclUsersWriteEnabled(state);


export const isAclDataRestrictionsReadEnabled = state => hasAclOperations(state) && findAccessOperation(state, APP_OPERATIONS.OP_ACL_DATA_RESTRICTIONS_READ);
export const isAclDataRestrictionsWriteEnabled = state => hasAclOperations(state) && findAccessOperation(state, APP_OPERATIONS.OP_ACL_DATA_RESTRICTIONS_WRITE);
export const isAclDataRestrictionsReadWriteEnabled = state => isAclDataRestrictionsReadEnabled(state) || isAclDataRestrictionsWriteEnabled(state);


export const isPlanogramRestrictionsReadEnabled = state => hasAclOperations(state) && findAccessOperation(state, APP_OPERATIONS.OP_PLANOGRAM_READ);
export const isPlanogramRestrictionsWriteEnabled = state => hasAclOperations(state) && findAccessOperation(state, APP_OPERATIONS.OP_PLANOGRAM_WRITE);
export const isPlanogramRestrictionsReadWriteEnabled = state => isPlanogramRestrictionsReadEnabled(state) || isPlanogramRestrictionsWriteEnabled(state);


export const isMasterDataRead = state => (
    isMasterDataAdTypesReadWrite(state) ||
    isMasterDataCustomersReadWrite(state) ||
    isMasterDataGroupsReadWrite(state) ||
    isMasterDataInventoryTransactionsReadWrite(state) ||
    isMasterDataItemsReadWrite(state) ||
    isMasterDataItemExclusionsReadWrite(state) ||
    isMasterDataItemShippingMethodConfigurationReadWrite(state) ||
    isMasterDataKitsReadWrite(state) ||
    isMasterDataParametersReadWrite(state) ||
    isMasterDataPricingReadWrite(state) ||
    isMasterDataPrimaryStoreSkuReadWrite(state) ||
    isMasterDataSchedulesReadWrite(state) ||
    isMasterDataShipmentsReadWrite(state) ||
    isMasterDataShippingMethodsReadWrite(state) ||
    isMasterDataSkusReadWrite(state) ||
    isMasterDataSkuExclusionsReadWrite(state) ||
    isMasterDataStoresReadWrite(state) ||
    isMasterDataStoreOrderListReadWrite(state) ||
    isMasterDataValidSkuItemCombinationsReadWrite(state) ||
    isMasterDataValidStoreSkuCombinationsReadWrite(state)
);
