// @ts-nocheck
import Heading from 'atoms/heading/heading';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import TextArea from 'atoms/TextArea/textArea';
import Loader from 'atoms/loader';
import Button from 'atoms/button/button';
import * as groupModalSelectors from 'organisms/groupModal/selector';
import * as groupModalSagaActions from 'organisms/groupModal/sagaActions';
import ModalBody from 'atoms/modal/modalBody';
import ModalActions from 'atoms/modal/modalActions';
import { Tprops } from './types';
import { useDispatch, useSelector } from 'react-redux';
import Validation from 'atoms/validation/validation';
import TextInput from 'atoms/TextInput/textInput';
import AntDropdown from 'atoms/antDropdown/antDropdown';
import { TABS_IDENTIFIER } from 'pages/masterData/groups/constants';
import _ from 'lodash';
import { CUSTOMER_DROPDOWN } from './constants';
import { updateGroupData } from './reducer';
import { useSearchParams } from 'react-router-dom';

const StartGroup = (props: Tprops) => {
	const { hideModal, handleNextClick } = props;
	const [title, setTitle] = React.useState<string>('');
	const [description, setDescription] = React.useState<string>('');
	const [selectedCustomer, setSelectedCustomer] = React.useState<any>('');
	const [selectedGroupType, setSelectedGroupType] = React.useState<any>('');
	const [searchParams, setSearchParams] = useSearchParams();
	const selectedTab = searchParams.get('selectedTab');

	const isLoading = useSelector(state => groupModalSelectors.isLoading(state));
	const dispatch = useDispatch();
	const [formErrors, setFormErrors] = React.useState<any>({});

	React.useEffect(() => {
		dispatch(groupModalSagaActions.fetchCustomers());
	}, [dispatch]);

	React.useEffect(() => {
		setSelectedGroupType(_.find(CUSTOMER_DROPDOWN, ({ value }) => selectedTab === value));
		props.handleUpdateMemberType(selectedTab);
	}, [selectedTab]);

	const customers = useSelector(state => groupModalSelectors.getCustomers(state));
	const editData = useSelector(state => groupModalSelectors.getEditData(state));

	React.useEffect(() => {
		if (editData?.GroupID) {
			setTitle(editData?.GroupTitle);
			setDescription(editData?.GroupDescription);
			setSelectedCustomer(getSelectedItem(customers, editData?.CustomerID, 'CustomerName', 'CustomerID'));
			// setSelectedGroupType({
			// 	displayName: editData?.GroupType,
			// 	value: editData?.GroupType
			// });
		} else {
			customers &&
				customers.length &&
				setSelectedCustomer(getSelectedItem(customers, customers[0].CustomerID, 'CustomerName', 'CustomerID'));
			// setSelectedGroupType(CUSTOMER_DROPDOWN[0]);
		}
	}, [editData, customers]);

	const getSelectedItem = (data: any[], value: string | number, attributeName: string, attribuetValue: string) => {
		let index = _.findIndex(data, function (item: any) {
			return item[attribuetValue] === value;
		});
		if (index > -1) {
			return {
				displayName: data[index][attributeName],
				value: data[index][attribuetValue]
			};
		} else {
			return {};
		}
	};

	const getCustomerDropdown = () => {
		let customerDropdowns: any[] = [];
		customers &&
			customers.length &&
			customers.map((singleItem: any) => {
				let data = {
					displayName: singleItem.CustomerName,
					value: singleItem.CustomerID
				};
				customerDropdowns.push(data);
			});
		return customerDropdowns;
	};

	const renderStartGroupData = () => {
		return (
			<Stack isVertical gutter="medium">
				<StackItem>
					<Stack isVertical>
						<StackItem>
							<Heading type="tertiary"> TITLE* </Heading>
						</StackItem>
						<StackItem>
							<Validation
								hasError={formErrors?.title?.hasError}
								errorMessage={formErrors?.title?.errMessage}
							>
								<TextInput
									size="medium"
									value={title}
									onChange={e => setTitle(e.target.value)}
									placeholder={'Name of group'}
								/>
							</Validation>
						</StackItem>
					</Stack>
				</StackItem>
				<StackItem>
					<Stack isVertical>
						<StackItem>
							<Heading type="tertiary"> DESCRIPTION* </Heading>
						</StackItem>
						<StackItem>
							<Validation
								hasError={formErrors?.description?.hasError}
								errorMessage={formErrors?.description?.errMessage}
							>
								<TextArea
									value={description}
									onChange={e => setDescription(e.target.value)}
									placeholder={'Add a description for the group'}
								/>
							</Validation>
						</StackItem>
					</Stack>
				</StackItem>
				<StackItem>
					<Stack>
						<StackItem isStretch isGrow>
							<Heading type="tertiary"> GROUP TYPE </Heading>
						</StackItem>
						<StackItem>
							<AntDropdown
								placeholder="Select"
								selectedOption={selectedGroupType}
								options={CUSTOMER_DROPDOWN}
								updateHandler={(item: any) => {
									setSelectedGroupType(item);
									props.handleUpdateMemberType(item.value);
								}}
							></AntDropdown>
						</StackItem>
					</Stack>
				</StackItem>
				{selectedGroupType.value != TABS_IDENTIFIER.CUSTOMERS && (
					<StackItem>
						<Stack>
							<StackItem isStretch isGrow>
								<Heading type="tertiary"> CUSTOMER </Heading>
							</StackItem>
							<StackItem>
								<AntDropdown
									placeholder="Select"
									selectedOption={selectedCustomer}
									options={getCustomerDropdown()}
									updateHandler={(item: any) => {
										setSelectedCustomer(item);
									}}
								></AntDropdown>
							</StackItem>
						</Stack>
					</StackItem>
				)}
			</Stack>
		);
	};

	const renderLoader = () => {
		return (
			<Stack isVertical>
				<StackItem isGrow isStretch align="center">
					<Loader />
				</StackItem>
			</Stack>
		);
	};
	const checkIfSelectMembersEnabled = () => {
		let data: any = {
			GroupTitle: title,
			GroupDescription: description,
			GroupType: selectedGroupType.value
		};
		if (selectedGroupType.value !== TABS_IDENTIFIER.CUSTOMERS) {
			data.CustomerID = selectedCustomer.value;
		}
		let keys = _.keys(data);
		for (let key of keys) {
			let keyValue = data[key] == '' || _.isNil(data[key]);
			if (keyValue) {
				return true;
			}
		}
		return false;
	};

	const handleSelectMembersClick = e => {
		let data: any = {
			GroupTitle: title,
			GroupDescription: description,
			GroupType: selectedGroupType.value
		};
		if (selectedGroupType.value !== TABS_IDENTIFIER.CUSTOMERS) {
			data.CustomerID = selectedCustomer.value;
		}
		dispatch(updateGroupData({ data }));
		handleNextClick(e);
	};

	return (
		<>
			<ModalBody>
				<Stack isVertical gutter="medium">
					<StackItem isStretch isGrow>
						{isLoading ? renderLoader() : renderStartGroupData()}
					</StackItem>
				</Stack>
			</ModalBody>
			<ModalActions>
				<Stack isVertical gutter="medium">
					<StackItem isStretch>
						<Stack align="right" gutter="small">
							<StackItem>
								<Button disabled={isLoading} isSecondary name="CANCEL" handleClick={hideModal} />
							</StackItem>
							<StackItem isStretch align="right">
								<Button
									disabled={checkIfSelectMembersEnabled() || isLoading}
									name={'SELECT MEMBERS'}
									handleClick={handleSelectMembersClick}
								/>
							</StackItem>
						</Stack>
					</StackItem>
				</Stack>
			</ModalActions>
		</>
	);
};

export default StartGroup;
