// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';
import * as query from './query';

// ****************************** Ag Grid start **************************

export const fetchTableColumnsAg = createAction(
	query.createActionName('fetch/Ag'),
	(tableId: number, options) => ({
		payload: { tableId, ...options }
	})
);

export const resetTableColumnsAg = createAction(query.createActionName('reset/Ag'), (tableId: number) => ({
	payload: { tableId }
}));

export const updateTableColumnConfigAg = createAction(query.createActionName('update/config/Ag'), payload => ({
	...payload
}));

export const updateTableColumnsAg = createAction(
	query.createActionName('update/Ag'),
	({ tableId, columns }: { tableId: number; columns: number[] }) => ({
		payload: { tableId, columns }
	})
);

// ******************************* Ag Grid end ***************************

export const fetchTableColumns = createAction(query.createActionName('fetch'), (tableId: number, forced: boolean) => ({
	payload: { tableId }
}));

export const fetchGridMetaData = createAction(
	query.createActionName('fetch/metadata'),
	(tableId: number, forced: boolean) => ({
		payload: { tableId, forced }
	})
);

export const resetTableColumns = createAction(query.createActionName('reset'), (tableId: number) => ({
	payload: { tableId }
}));

export const updateTableColumns = createAction(
	query.createActionName('update'),
	(tableId: number, columns: number[]) => ({
		payload: { tableId, columns }
	})
);

export const setCustomizeViewModal = createAction(
	query.createActionName('set-customize-view-modal'),
	(isCustomizeViewModalEnabled: boolean) => ({
		payload: { isCustomizeViewModalEnabled }
	})
);

export const updateTableInput = createAction('update-input-value', (tableId, rowIdentifier, newValue, row) => ({
	payload: { tableId, rowIdentifier, newValue, row }
}));

export const cleanUpGrids = createAction(query.createActionName('trigger-grids-cleanup'), (tableIds: [string]) => ({
	payload: tableIds
}));
