// @ts-nocheck
import { Action, AnyAction, createSlice } from '@reduxjs/toolkit';
export const initialState = {
	selectedUser: null
};

interface RejectedAction extends Action {
	error: Error;
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
	return action.type.endsWith('rejected');
}

const slice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		updateSelectedUser: (state, { payload: { selectedUser } }) => {
			return {
				...state, 
				selectedUser
			}
		}
	}
});

export default slice;

export const {updateSelectedUser} = slice.actions;
