// @ts-nocheck
import React, { useEffect, useState } from 'react';
import SideCar from '../../../molecules/sidecar/sidecar';
import * as sagaActions from './sagaActions';
import * as selectors from './selectors';
import * as orderSelectors from '../selectors';
import { useDispatch, useSelector } from 'react-redux';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import Heading from 'atoms/heading/heading';
import Divider from 'atoms/divider/divider';
import Button from 'atoms/button/button';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import * as OrdersSelectors from '../selectors';
import * as constants from '../constants';
import Loader from 'atoms/loader';

type Item = {
  value: string;
};

interface Props extends React.PropsWithChildren<any> {
  isActive;
  handleOnClose;
}

const HealthCheck: React.FunctionComponent<any> = (props: Props) => {
  const { isActive, handleOnClose, healthCheckId } = props;
  const [errorClicked, setErrorClicked] = useState(false);
  const [warningsClicked, setWarningsClicked] = useState(false);

  const dispatch = useDispatch();
  const isLoading = useSelector(state =>
    OrdersSelectors.getIsLoading(
      state,
      constants.LOADER_COMPONENTS.HEALTH_CHECK
    )
  );
  //   const healthCheckId = useSelector(state =>
  //     orderSelectors.getSelectedHealthCheckId(state)
  //   );
  const healthCheckData = useSelector(state =>
    selectors.getHealthCheckData(state)
  );

  const errors =
    healthCheckData[2] &&
    healthCheckData[2][0] &&
    healthCheckData[2][0]['HealthCheckDesc'];
  const warnings =
    healthCheckData[1] &&
    healthCheckData[1][0] &&
    healthCheckData[1][0]['HealthCheckDesc'];
  const informations =
    healthCheckData[0] &&
    healthCheckData[0][0] &&
    healthCheckData[0][0]['HealthCheckDesc'];

  const handleIgnoreClick = (data, type) => {
    if (type === 'errors') {
      dispatch(
        sagaActions.updateHealthCheck({
          HealthCheckID: healthCheckId,
          isIgnoreAlert: 1,
        })
      );
      setErrorClicked(true);
    } else if (type === 'warnings') {
      dispatch(
        sagaActions.updateHealthCheck({
          HealthCheckID: healthCheckId,
          isIgnoreWarn: 1,
        })
      );
      setWarningsClicked(true);
    }
  };

  useEffect(() => {
    dispatch(sagaActions.getHealthData(healthCheckId));
  }, [dispatch, healthCheckId]);

  return (
    <SideCar isFixed handleOnClose={handleOnClose} isActive={true || isActive}>
      <Sheet>
        <SheetBody>
          {isLoading ? (
            <Stack>
              <StackItem isGrow isStretch align="center">
                <Loader />
              </StackItem>
            </Stack>
          ) : (
            <Stack isVertical gutter="medium">
              <StackItem isStretch>
                <Divider />
              </StackItem>
              <StackItem isStretch>
                <Stack isVertical gutter="medium">
                  <StackItem>
                    <Stack>
                      <StackItem isGrow>
                        <Heading type="primary">Errors</Heading>
                      </StackItem>
                      <StackItem>
                        <Button
                          isSecondary
                          name={'Ignore'}
                          handleClick={() =>
                            handleIgnoreClick(healthCheckData[2][0], 'errors')
                          }
                          disabled={!errors || errorClicked}
                        />
                      </StackItem>
                    </Stack>
                  </StackItem>
                  <StackItem>
                    <Stack>
                      <StackItem>
                        {errors && !errorClicked ? (
                          <Heading type="tertiary">
                            {healthCheckData[2][0]['HealthCheckDesc']}
                          </Heading>
                        ) : (
                          <Heading type="tertiary">
                            No errors for this item.
                          </Heading>
                        )}
                      </StackItem>
                    </Stack>
                  </StackItem>
                </Stack>
              </StackItem>
              <StackItem isStretch>
                <Divider />
              </StackItem>
              <StackItem isStretch>
                <Stack isVertical gutter="medium">
                  <StackItem>
                    <Stack>
                      <StackItem isGrow>
                        <Heading type="primary">Warning</Heading>
                      </StackItem>
                      <StackItem>
                        <Button
                          isSecondary
                          name={'Ignore'}
                          handleClick={() =>
                            handleIgnoreClick(healthCheckData[1][0], 'warnings')
                          }
                          disabled={!warnings || warningsClicked}
                        />
                      </StackItem>
                    </Stack>
                  </StackItem>
                  <StackItem>
                    <Stack>
                      <StackItem>
                        {warnings && !warningsClicked ? (
                          <Heading type="tertiary">
                            {healthCheckData[1][0]['HealthCheckDesc']}
                          </Heading>
                        ) : (
                          <Heading type="tertiary">
                            No warnings for this item.
                          </Heading>
                        )}
                      </StackItem>
                    </Stack>
                  </StackItem>
                </Stack>
              </StackItem>
              <StackItem isStretch>
                <Divider />
              </StackItem>
              <StackItem isStretch>
                <Stack isVertical gutter="medium">
                  <StackItem>
                    <Stack>
                      <StackItem isGrow>
                        <Heading type="primary">Information</Heading>
                      </StackItem>
                      <StackItem>
                        <Button
                          isSecondary
                          name={'Ignore'}
                          handleClick={() =>
                            handleIgnoreClick(
                              healthCheckData[0][0],
                              'information'
                            )
                          }
                          disabled={!informations}
                        />
                      </StackItem>
                    </Stack>
                  </StackItem>
                  <StackItem>
                    <Stack>
                      <StackItem>
                        {informations ? (
                          <Heading type="tertiary">
                            {healthCheckData[0][0]['HealthCheckDesc']}
                          </Heading>
                        ) : (
                          <Heading type="tertiary">
                            No Information for this item.
                          </Heading>
                        )}
                      </StackItem>
                    </Stack>
                  </StackItem>
                </Stack>
              </StackItem>
            </Stack>
          )}
        </SheetBody>
      </Sheet>
    </SideCar>
  );
};

export default HealthCheck;
