// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Card, Input, List } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { triggerFetchAutoComplete } from 'models/autoComplete/sagaActions';
import { getAutoCompleteData, isAutoCompleteLoading } from 'models/autoComplete/selectors';
import Button from 'atoms/button/button';
import Icon from 'atoms/icons/icon';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import './autoCompleteSelector.scss';
import Loader from 'atoms/loader';

let debounce;

const AutoCompleteSelector = ({
  componentId,
  options,
  placeholder,
  refreshTimetamp,
  onSelect,
  actions,
  debounceTime,
  onSubmit,
  onAdd,
  onRemove,
  onToggle,
}) => {
  const [searchText, setSearchText] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const isLoading = useSelector(state => isAutoCompleteLoading(state, componentId))
  const handleOnSelect = (selected) => {
    if (!onSelect) {
      return;
    }
    onSelect(selected);
    setSelectedItem(selected);
  };

  const handleAdd = (e, option) => {
    onAdd(option);
    e.stopPropagation();
  };

  useEffect(() => {
    if (searchText) {
      return;
    }
    setSelectedItem(null);
    dispatch(
      triggerFetchAutoComplete({
        componentId,
        options,
      })
    );
  }, [componentId, options, refreshTimetamp, searchText, dispatch]);

  useEffect(() => {
    clearTimeout(debounce);
    if (!searchText) {
      return;
    }
    debounce = setTimeout(() => {
      dispatch(
        triggerFetchAutoComplete({
          componentId,
          options,
          params: { search: searchText },
        })
      );
    }, debounceTime || 1000);
  }, [searchText]);

  const autoCompleteOptions = useSelector((state) => getAutoCompleteData(state, componentId));

  let displayedOptions = autoCompleteOptions?.map((option) => ({
    displayName: option[options?.displayKey],
    value: option[options?.selectionKey],
    isAdded: option.IsAdded,
    isActive: option.STATUS !== 'Inactive',
  }));

  const handleSearch = (e) => {
    let text = e.target.value;
    setSearchText(text);
  };

  return (
    <Card className='autocomplete-card'>
      <div className='autocomplete-header'>
        <Stack gutter='small'>
          <StackItem isGrow>
            <Input placeholder={placeholder} value={searchText} onChange={handleSearch} />
          </StackItem>
          {Boolean(onSubmit && searchText && !displayedOptions?.length) && (
            <StackItem position='center'>
              <Icon isActive onClick={() => onSubmit(searchText)} type='active' faAlias='faPlus' />
            </StackItem>
          )}
        </Stack>
      </div>

      <div className='autocomplete-list' onScroll={() => { }}>
        {isLoading ? <Loader size="small"/> :
        <List
          dataSource={displayedOptions}
          renderItem={(option, index) =>
            !option.isAdded && (
              <Stack gutter='medium' divisions={actions?.includes('toggle') ? 10 : 20}>
                <StackItem position='center' isGrow isStretch span={actions?.includes('toggle') ? 8 : 18}>
                  <List.Item
                    onClick={() => handleOnSelect({ ...option, metaData: { ...autoCompleteOptions[index] } })}
                    className={`autocomplete-item ${selectedItem?.value && option?.value === selectedItem?.value ? 'selected' : ''}`}
                  >
                    <List.Item.Meta title={option.displayName} description={option.email} />
                  </List.Item>
                </StackItem>
                {actions && (
                  <StackItem position='center' align='right' span={2}>
                    <Stack gutter='medium'>
                      {actions?.includes('add') && option.isActive && (
                        <StackItem>
                          <Icon
                            isActive
											disabled={option.isAdded}
											size="small"
                            onClick={(e) => handleAdd(e, { ...option, metaData: { ...autoCompleteOptions[index] } })}
                            faAlias='faPlus'
                          />
                        </StackItem>
                      )}
                      {actions?.includes('toggle') && (
                        <StackItem>
                          <Button
                            type={option?.isActive ? 'reject' : 'active'}
                            handleClick={(e) => onToggle(e, { ...option, metaData: { ...autoCompleteOptions[index] } })}
                          >
                            {option.isActive ? 'Disable' : 'Enable'}
                          </Button>
                        </StackItem>
                      )}
                      {actions?.includes('delete') && option.isActive && (
                        <StackItem>
                          <Icon
                            isActive
											  onClick={(e) => onRemove({ ...option, metaData: { ...autoCompleteOptions[index] } })}
											  size="small"
                            faAlias='faTrash'
                          />
                        </StackItem>
                      )}
                    </Stack>
                  </StackItem>
                )}
              </Stack>
            )
          }
        />}
      </div>
    </Card>
  );
};

export default AutoCompleteSelector;

