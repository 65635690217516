// @ts-nocheck
import React from 'react';

import UserReducer from './reducer';
import UserSagas from './sagas';
import UserList from './usersAg';
import { isAclUsersReadWriteEnabled } from 'models/user/selector';

export default [
	{
		path: '/settings/users',
		key: 'key',
		componentId: 'users',
		component: (props) => <UserList {...props} title="asd" />,
		routes: [],
		importReducer: () => {
			return [UserReducer];
		},
		importSagas: () => {
			return [UserSagas];
		},
		isEnabled: state => isAclUsersReadWriteEnabled(state),
		title: 'Acl Users',
		headerName: 'Users',
		name: 'Users'

	},


];
