// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const savePlanogramAttribute = createAction(
    'planogram/triggerSavePlanogramAttribute',
    function prepare({ planogramAttributeId, planogramAttributeDetails }) {
        return {
            payload: {
                planogramAttributeId,
                planogramAttributeDetails
            },
        };
    }
);

export const togglePlanogramAttributeState = createAction(
    'planogram/togglePlanogramAttributeState',
    function prepare({    isActive, row, isSelectAll,selectAllSearch, selection ,isBulkUpdate}) {
        return {
            payload: {
                isActive, row, isSelectAll,selectAllSearch, selection ,isBulkUpdate
            },
        };
    }
);