// @ts-nocheck
import _ from 'lodash';
import qs from 'qs';
import { put, select, takeEvery } from 'redux-saga/effects';
import { getAPIData, getAPIDataV2, deleteAPIData, postAPIDataV3, deleteAPIDataV2, getAPIDataV3, deleteAPIDataV3 } from 'utils/api';
import * as slice from './reducer';
import * as sagaActions from './sagaActions';
import * as selector from './selector';
import { TMetadata } from './types';
import { triggerNotification } from 'organisms/notifications/sagaActions';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import { toggleLoader } from 'models/loaders/sagaActions';
import { getGridData } from 'models/dynamicGrid/selector';
import { ADS_TABLE_ID, AD_TYPES_TABLE } from './constants';
import { fetchGridData, updateGridData } from 'models/dynamicGrid/sagaActions';
import { REQUESTS } from 'utils/requests';
import { getWebAppSettings } from 'models/user/selector';
import { getState } from 'reducers';

type TPayload = {
	payload: {
		name: string;
		url?: string;
		metaData?: TMetadata;
	};
};

const getData = (url: string): Promise<any> => {
	const apiQuery = {
		refresh: new Date().getTime().toString()
	};

	return getAPIData(`${url}?${qs.stringify(apiQuery)}`)
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true }));
};

const getCustomers = (): Promise<any> => {
	const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

	const apiQuery = {
		refresh: new Date().getTime().toString(),
		pageNumber: 1,
		itemsPerPage: 50,
   		isView
	};

	return getAPIDataV3({service: REQUESTS.GET.GET_CUSTOMERS.service, url: REQUESTS.GET.GET_CUSTOMERS.url, queryParams: apiQuery})
		.then(response => ({ customers: response?.data.data }))
		.catch(error => ({ error, isCustomerFetchError: true }));
};

export default function* rootSaga() {
	function* loadAdvertisings({ payload }: TPayload) {
		const { name, url } = payload;
		if (!url) {
			console.error('load advertising url not found');
			return;
		}
		const metaData: TMetadata = {
			dataURL: url
		};
		yield updateAdsMeta({ payload: { name, metaData } });
		yield fetchAdsData({ payload: { name } });
	}

	function* updateAdsMeta({ payload }: TPayload) {
		const { name, metaData } = payload;
		yield put(slice.updateMetadata({ name, metaData }));
	}

	function* fetchAdsData({ payload }: TPayload) {
		const { name } = payload;

		yield put(slice.updateLoader({ eventName: name, isLoading: true }));

		const metaData = yield select(state => selector.getAdsMetadata(state, name));
		if (name == 'advertising') {
			const { customers, isCustomerFetchError } = yield getCustomers();

			if (isCustomerFetchError) {
				yield put(slice.updateLoader({ eventName: name, isLoading: false }));
				return;
			}
			yield put(slice.updateCustomers({ name, data: customers }));
		}

		const { response, isError, error } = yield getData(metaData.dataURL);
		if (error?.message === 'CANCELLED') {
			return;
		}

		if (isError) {
			// TODO: Error Handling
			yield put(slice.updateLoader({ eventName: name, isLoading: false }));
			yield put(
				triggerNotification({ message: 'An error occurred while fetching data, please try again later.' })
			);
			return;
		}
		yield put(slice.updateAdsData({ name, data: response?.data || [] }));

		yield put(slice.updateLoader({ eventName: name, isLoading: false }));
	}

	function* fetchAdTypes() {
		const apiQuery = {
			refresh: new Date().getTime().toString()
		};
		yield put(slice.updateLoader({ eventName: 'adTypes', isLoading: true }));

		const { customers, isCustomerFetchError } = yield getCustomers();

		if (isCustomerFetchError) {
			// yield put(slice.updateLoader({ eventName: , isLoading: false }));
			return;
		}
		yield put(slice.updateCustomers({ name: 'advertising', data: customers }));

		let { response, isError, error } = yield postAPIDataV3({
      service: REQUESTS.POST.GET_GRID_DETAILS_V2.service,
      url: REQUESTS.POST.GET_GRID_DETAILS_V2.url,
      pathParams: { tableId: 45 },
      queryParams: { refresh: new Date().getTime().toString() },
    })
      .then((response) => ({ response: response?.data }))
      .catch((error) => ({ error, isError: true, error }));
		if (error?.message === 'CANCELLED') {
			return;
		}
		if (isError) {
			yield put(
				triggerNotification({ message: 'An error occurred while fetching data, please try again later.' })
			);
			yield put(slice.updateLoader({ eventName: 'adTypes', isLoading: false }));
			return;
		}
		yield put(slice.updateAdTypes({ data: response?.data }));
		yield put(slice.updateLoader({ eventName: 'adTypes', isLoading: false }));
	}

	function* fetchAdTabs() {
		try {
			const apiQuery = {
				refresh: new Date().getTime().toString()
			};
			yield put(toggleLoader({ component: 'AD_TABS', isLoading: true }));
      const { data } = yield getAPIDataV3(
        {
          service: REQUESTS.GET.GET_AD_TABS.service, 
          url: REQUESTS.GET.GET_AD_TABS.url,
        }
      ).then(response => response?.data);

			yield put(slice.updateAdTabs({ data }));

			yield put(toggleLoader({ component: 'AD_TABS', isLoading: false }));
		} catch (e) {
			console.log(e);
		}
	}

	function* fetchStores({ payload }: TPayload) {
		const { name, customerId } = payload;
		const apiQuery = {
			refresh: new Date().getTime().toString()
		};
		yield put(slice.updateLoader({ eventName: name, isLoading: true }));

		let { response, isError, error } = yield postAPIDataV3({ ...REQUESTS.POST.GET_VALID_STORES, pathParams: { customerId } })
			.then(response => ({ response: response?.data }))
			.catch(error => ({ error, isError: true }));
		if (error?.message === 'CANCELLED') {
			return;
		}
		if (isError) {
			yield put(
				triggerNotification({ message: 'An error occurred while fetching data, please try again later.' })
			);
			yield put(slice.updateLoader({ eventName: name, isLoading: false }));
			return;
		}
		yield put(slice.updateStores({ name: name, data: response?.data }));
		yield put(slice.updateLoader({ eventName: name, isLoading: false }));
	}

	function* deleteAdverising({ payload }: any) {
		const { adId } = payload;
		let { response, error, isError } = yield deleteAPIDataV3({
      service: REQUESTS.DELETE.DELETE_AD.service,
      url: REQUESTS.DELETE.DELETE_AD.url,
      pathParams: {adId}
    })
			.then(response => ({ response: response?.data }))
			.catch(error => ({ error, isError: true }));
		if (error?.message === 'CANCELLED') {
			return;
		}
		if (isError) {
			yield put(
				triggerNotification({ message: 'An error occurred while deleting data, please try again later.' })
			);
			return;
		}
			yield put(dynamicGridSagaActions.refreshGridData({ tableId: ADS_TABLE_ID }));
		// yield put(slice.updateAdsData({ name: 'advertising', data: ads }));
	}

	function* deleteAdType({ payload }: any) {
		const { adTypeId } = payload;
		let { response, isError, error } = yield deleteAPIDataV3({
      service: REQUESTS.DELETE.DELETE_AD_TYPE.service,
      url: REQUESTS.DELETE.DELETE_AD_TYPE.url,
      pathParams: {adTypeId}
    })
			.then(response => ({ response: response?.data }))
			.catch(error => ({ error, isError: true }));
		if (error?.message === 'CANCELLED') {
			return;
		}
		if (isError) {
			yield put(triggerNotification({ message: error?.data?.message ?? 'An error occurred while deleting, please try again later.' }));
			return;
		}
			yield put(dynamicGridSagaActions.refreshGridData({ tableId: AD_TYPES_TABLE }));
			return;
	}

	return [
		yield takeEvery(sagaActions.loadAdvertisings, loadAdvertisings),
		yield takeEvery(sagaActions.fetchAdsData, fetchAdsData),
		yield takeEvery(sagaActions.updateAdsMeta, updateAdsMeta),
		yield takeEvery(sagaActions.fetchAdTypes, fetchAdTypes),
		yield takeEvery(sagaActions.fetchAdTabs, fetchAdTabs),
		yield takeEvery(sagaActions.fetchStores, fetchStores),
		yield takeEvery(sagaActions.deleteAdvertisings, deleteAdverising),
		yield takeEvery(sagaActions.deleteAdType, deleteAdType)
	];
}
