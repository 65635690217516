// @ts-nocheck
import Card from 'atoms/card/card';
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import Icon from 'atoms/icons/icon';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import * as tableColumnSagaActions from 'models/tableColumns/sagaActions';
import * as tableColumnSelectors from 'models/tableColumns/selector';
import SideCar from 'molecules/sidecar/sidecar';
import Snackbar from 'molecules/snackbar/snackbar';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import CustomizeViewModal from 'organisms/CustomizeViewModal';
import ThetaMenu from 'organisms/thetaMenu/thetaMenu';
import UploadData from 'organisms/uploadData';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import * as constants from './constants';
import Breadcrumbs from 'atoms/breadcrumbs/breadcrumbs';
import ThetaDeleteModal from 'organisms/deleteModal/deleteModal';
import ExportGrid from 'organisms/exportGrid';
import ToggleAgGrid from 'organisms/toggleAgGrid';
import { REQUESTS } from 'utils/requests';
import { isMasterDataItemsWrite } from 'models/user/selector';
import { isMasterDataItemsDelete } from '../../../models/user/selector';
import GridActionBar from 'atoms/GridActionBar';
import GridCsvUpload from 'organisms/uploadData/gridCsvUpload';
import { isNewUploadEnabled } from 'models/user/selector';

const ITEMS_MODAL = 'items-modal';

const ItemsMasterData: React.FunctionComponent = props => {
	const [openFilter, setOpenFilter] = React.useState<boolean>();
	const [isUploadEnabled, toggleUpload] = React.useState<boolean>(false);
	const [isDeleteModal, toggleDeleteModal] = React.useState(false);
	const gridMeta = useSelector(state => dynamicGridSelectors.getGridMetadata(state, constants.TABLE_ID));

	const tableId = constants.TABLE_ID.toString();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const hasWriteAccess = useSelector(isMasterDataItemsWrite);
	const hasDeleteAccess = useSelector(isMasterDataItemsDelete);
	const hasSearchFilters = useSelector(state => dynamicGridSelectors.hasSearchFilters(state, constants.TABLE_ID));

	const isCustomizeViewModalEnabled = useSelector(state => tableColumnSelectors.isCustomizeViewModalEnabled(state));
	// React.useEffect(() => {
	//   dispatch(tableColumnSagaActions.fetchTableColumns(constants.TABLE_ID));
	// }, [dispatch, tableId]);
	React.useEffect(() => {
		if (openFilter === undefined) {
			setOpenFilter(hasSearchFilters);
		}
	}, [hasSearchFilters]);

	const handleFilterClick = React.useCallback(() => {
		setOpenFilter(!openFilter);
	}, [openFilter]);

	const handleRefreshClick = React.useCallback(() => {
		dispatch(
			dynamicGridSagaActions.refreshGridData({
				tableId: constants.TABLE_ID,
			})
		);
	}, [tableId, dispatch]);

	const handleBackClick = React.useCallback(() => {
		navigate(`/masterData`);
	}, [navigate]);

	const handleResetTableColumns = React.useCallback(async () => {
		dispatch(tableColumnSagaActions.resetTableColumnsAg(constants.TABLE_ID));
	}, [dispatch, tableId]);

	const customizeViewHandler = React.useCallback((isCustomizeViewModalEnabled: boolean) => {
		dispatch(tableColumnSagaActions.setCustomizeViewModal(isCustomizeViewModalEnabled));
	}, []);

	const handleMenuItemClick = React.useCallback(
		(option: string) => {
			switch (option) {
				case constants.MENU_ITEMS_VALUES.CUSTOMIZE_VIEW:
					return customizeViewHandler(true);
				case constants.MENU_ITEMS_VALUES.RESET_VIEW:
					return handleResetTableColumns();
				case constants.MENU_ITEMS_VALUES.UPLOAD_DATA:
					return toggleUpload(true);
				case constants.MENU_ITEMS_VALUES.DELETE:
					return deleteItems();
				default:
					return;
			}
		},
		[handleResetTableColumns]
	);

	const selectedItems = useSelector(state => dynamicGridSelectors.getTableSelection(state, constants.TABLE_ID));
	const getDisabledStatus = React.useCallback(
		(option: any) => {
			switch (option.value) {
				case constants.MENU_ITEMS_VALUES.CUSTOMIZE_VIEW:
				case constants.MENU_ITEMS_VALUES.RESET_VIEW:
				case constants.MENU_ITEMS_VALUES.UPLOAD_DATA:
					return false;
				case constants.MENU_ITEMS_VALUES.DELETE:
					return !selectedItems?.selectedRowKeys?.length;
				default:
					return true;
			}
		},
		[selectedItems]
	);

	const hideCustomizeViewModal = React.useCallback(() => {
		customizeViewHandler(false);
	}, []);

	const uploadCloseClickHandler = React.useCallback(() => {
		toggleUpload(false);
	}, []);

	const handleBulkDeleteClick = () => {
		dispatch(dynamicGridSagaActions.handleBulkRowDelete({ tableId: constants.TABLE_ID }));
		toggleDeleteModal(false);
	};

	const deleteItems = () => {
		toggleDeleteModal(true);
	};
	const isNewUpload = useSelector(isNewUploadEnabled);

	const gridConfig = {
		enableSelection: true,
		enableSorting: true,
		enableMultiSort: true,
		gridActions: hasDeleteAccess,
		selectionCallBack: () => { }
	};

	const templateConfig = {
		gridConfig,
		isFilterEnabled: openFilter === undefined ? false : openFilter,
		tableId: constants.TABLE_ID,

		dataProviderOptions: {
			type: 'POST',
			...REQUESTS.POST.GET_GRID_DETAILS_V2,
			pathParams: { tableId: 13 },
			version: 2
		},
		selectRowKey: 'ItemID'
	};

	return (
		<>
			<Stack isVertical>
				<StackItem>
					<GridActionBar>
						<Stack gutter="medium">
							<StackItem isGrow>
								<Breadcrumbs crumbs={props.breadcrumbs} />
							</StackItem>
							{isNewUpload && gridMeta?.UploadTableID && <StackItem>
								<GridCsvUpload uploadTableId={gridMeta?.UploadTableID} parentTableId={tableId} />
							</StackItem>}
							<StackItem>
								<Icon isActive type="secondary" faAlias="faRecycle" onClick={handleRefreshClick} />
							</StackItem>


							<StackItem>
								<ExportGrid tableId={13} />
							</StackItem>
							<StackItem>
								<ThetaMenu
									type="3Dots"
									options={
										hasWriteAccess ? constants.MENU_ITEMS : [constants.MENU_ITEMS[0]]
									}
									handleItemClick={handleMenuItemClick}
									isDisabled={getDisabledStatus}
								/>
							</StackItem>
						</Stack>
					</GridActionBar>
				</StackItem>
				<StackItem isStretch isGrow>
					<Sheet isPage>
						<Stack isVertical gutter="medium">

							<StackItem isGrow isStretch>
								<ThetaGridPaginated config={templateConfig} />
							</StackItem>
						</Stack>
					</Sheet>
				</StackItem>
			</Stack>


			{isCustomizeViewModalEnabled && (
				<CustomizeViewModal hideModal={hideCustomizeViewModal} tableId={constants.TABLE_ID} />
			)}
			{isUploadEnabled && (
				<SideCar isActive={isUploadEnabled} handleOnClose={uploadCloseClickHandler}>
					<UploadData tableId={tableId} />
				</SideCar>
			)}
			{isDeleteModal && (
				<ThetaDeleteModal
					hideModal={() => {
						toggleDeleteModal(false);
					}}
					modalTitle={'ITEMS'}
					title={` (${selectedItems?.isSelectAll ? 'All' : selectedItems?.selectedRowKeys?.length
						}) selected  item(s)`}
					handleDeleteClick={handleBulkDeleteClick}
					isLocked={true}
				/>
			)}
		</>
	);
};
export default ItemsMasterData;
