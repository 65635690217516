// @ts-nocheck
import _ from 'lodash';
import * as constants from './constants';
import { createComponent } from './gridComponents';
import { TTableColum, TTableColumnGroup } from './types';

export const getColumnIdsFromColumns = (columns: TTableColum[]) => {
	return _.map(
		_.filter(columns, column => column.selected),
		'AppColumnSurrogateID'
	);
};

export const createActionName = (eventName: string) => {
	return `${constants.MODEL_NAME}-action/${eventName}`;
};

export const groupColumns = (columns: TTableColum[]): TTableColumnGroup[] => {
	const data = {};
	const result = {};

	_.map(columns, column => {
		if (!data[column.AppColumnGroup]) {
			data[column.AppColumnGroup] = [];
		}

		data[column.AppColumnGroup].push(column);
	});

	const intermediate = [];

	for (const key in data) {
		intermediate.push({
			key,
			length: data[key].length
		});
	}

	const sortedOrder = _.orderBy(intermediate, ['length'], ['desc']);

	for (const order of sortedOrder) {
		result[order.key] = data[order.key];
	}

	return _.map(result, (value, index) => ({
		name: index,
		columns: value
	}));
};

export const getColumnDefinitionComponentMap = (tableId: number, tableColumns: any[], isPaginated: boolean) =>
	_.compact(
		_.map(tableColumns, column => {
			if ((column.selected || !column.IsCustomizableColumn) && !column.IsInternalColumn) {
				return {
					colId: column.AppColumnSurrogateID?.toString(),
					rowIdentifier: column.AppColumnName,
					header: column.AppColumnAliasName || column.AppColumn,
					componentName: column.ComponentName,
					width: column.ColumnWidth,
					defaultSortOrder: column.DefaultSortOrder,
					isFixed: column.IsFixedColumn,
					isEditable: column.IsEditableColumn,
					component: createComponent(column.ComponentName, {
						rowIdentifier: column.AppColumnName,
						tableId,
						type: column.ColumnType,
						isPaginated
					})
				};
			}
			return undefined;
		})
	);
