// @ts-nocheck
import Button from 'atoms/button/button';
import Heading from 'atoms/heading/heading';
import Label from 'atoms/label/label';
import Loader from 'atoms/loader';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import _ from 'lodash';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as groupModalSagaActions from 'organisms/groupModal/sagaActions';
import * as selectors from './selector';
import { TABS_IDENTIFIER } from 'pages/masterData/groups/constants';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTableId } from './constants';
import { saveData } from './sagaActions';
import { getActiveTable, getModalParams, isAvailabilityUpdate } from './selector';
import { Tprops } from './types';

const ReviewKit = (props: Tprops) => {
	const { hideModal, handleNextClick, goBack } = props;
	const dispatch = useDispatch();
	const isAvailabilityFlow = useSelector(isAvailabilityUpdate);
	const modalParams = useSelector(getModalParams);
	const selectedStores = useSelector(state => dynamicGridSelectors.getTableSelection(state, 20));
	const isLoading = useSelector(state => selectors.isLoading(state));

	const handleSave = async (e: any) => {
		dispatch(saveData());
	};
	const renderLoader = () => {
		return (
			<StackItem isGrow isStretch align="center">
				<Loader />
			</StackItem>
		);
	};

	let modalBody = (
		<Stack isVertical gutter="medium">
			<StackItem isStretch isGrow>
				<Stack isVertical gutter="medium">
					<StackItem isStretch>
						<Label>Skulicity will generate Kits using the following parameters:</Label>
					</StackItem>
					<StackItem>
						<Stack isVertical>
							<StackItem>
								<Label isHighlight>Maximum Kit Configurations: {modalParams.kitConfigurations}</Label>
							</StackItem>
							{selectedStores && (
								<>
									<StackItem>from (orders/availability)</StackItem>
									<StackItem>
										<Label isHighlight>
											Sales Demand selected from{' '}
											{selectedStores.isSelectAll
												? 'All'
												: selectedStores?.selectedRowKeys.length}{' '}
											stores
										</Label>
									</StackItem>
								</>
							)}
						</Stack>
					</StackItem>
				</Stack>
			</StackItem>
		</Stack>
	);

	if (isAvailabilityFlow && !modalParams.isStoreDemand) {
		modalBody = (
			<ModalBody>
				<Stack isVertical gutter="large">
					<StackItem>
						<Label isHighlight>Maximum Kit Configurations: {modalParams.kitConfigurations}</Label>
					</StackItem>
					<StackItem>
						<Label isHighlight>No Sales Demand Selected</Label>
					</StackItem>
				</Stack>
			</ModalBody>
		);
	}
	return (
		<>
			<ModalBody>{isLoading ? renderLoader() : modalBody}</ModalBody>
			<ModalActions>
				<Stack gutter="medium">
					<StackItem isStretch isGrow>
						<Stack align="left" gutter="small">
							<StackItem>
								<Button
									disabled={isLoading}
									isSecondary
									name="Re-Select Parameters"
									handleClick={goBack}
								/>
							</StackItem>
						</Stack>
					</StackItem>
					<StackItem>
						<Stack align="right" gutter="small">
							<StackItem>
								<Button disabled={isLoading} isSecondary name="Cancel" handleClick={hideModal} />
							</StackItem>
							<StackItem isStretch align="right">
								<Button disabled={isLoading} name={'Create Kits'} handleClick={handleSave} />
							</StackItem>
						</Stack>
					</StackItem>
				</Stack>
			</ModalActions>
		</>
	);
};

export default ReviewKit;
