// @ts-nocheck
import _ from 'lodash';
import * as constants from './constants';

export const getDaysArr = (days: string[] = []) => {
	return _.compact(
		_.map(constants.DAYS, (day: any) => {
			if (days?.includes(day.value)) {
				return day;
			}
		})
	);
};
