// @ts-nocheck
import { put, takeEvery } from 'redux-saga/effects';
import { getAPIDataV2, patchAPIDataV3, postAPIDataV2 } from 'utils/api';
import * as constants from './constants';
// import * as slice from './reducer';
import * as sagaActions from './sagaActions';
import { updateComponentRequestStatus } from 'models/loaders/reducer';
import { REQUEST_STATUS } from 'models/loaders/constants';
import { refreshGridData, updateTableCell } from 'models/dynamicGrid/sagaActions';
import { TABLE_ID } from './constants';
import { getDispatch } from 'reducers';

function* savePlanogramAttribute({ payload }) {
    const { attributeName, attributeDescription } = payload?.planogramAttributeDetails;
    const dispatch = getDispatch();
    yield put(
        updateComponentRequestStatus({
            component: constants.LOADER_COMPONENTS.SAVE_ATTRIBUTE_DETAILS,
            isLoading: true,
            status: REQUEST_STATUS.PENDING
        })
    );
    const data = yield postAPIDataV2('planogram', `attribute`, {
        attributeName,
        attributeDescription
    }).then(response => response?.data);

    dispatch(
        refreshGridData({
            tableId: TABLE_ID,
        })
    );
    // yield put(slice.updateTableColumns({ data }));
    yield put(
        updateComponentRequestStatus({
            component: constants.LOADER_COMPONENTS.SAVE_ATTRIBUTE_DETAILS,
            isLoading: false,
            status: REQUEST_STATUS.COMPLETE
        })
    );
}

async function togglePlanogramAttributeState({ payload }) {
        const { isActive, row, isSelectAll,selectAllSearch, selection, isBulkUpdate  } = payload;
        const dispatch = getDispatch();
        // console.log({
        //     tableId: TABLE_ID,
        //     isAgGrid: true,
        //     updatedRow: { ...row, IsActive: isActive }
        // })
        if(!isBulkUpdate){
            dispatch(updateTableCell({
                tableId: TABLE_ID,
                row,
                updatedValue: isActive,
                rowIdentifier: "IsActive"
            }))
        }
       
        let request = await patchAPIDataV3(
            {service: 'planogram', 
            url: `attributes/toggle/state`,
            body: {
                rowIdentifier: row?.PlanogramAttributeID ?? selection?.join(','),
                isSelectAll,
                selectAllSearch,
                isActive
    }}).then(response => response?.data);

    if(isBulkUpdate){
        dispatch(refreshGridData({
            tableId: TABLE_ID
        }))
    }
}

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
    return [
        yield takeEvery(sagaActions.togglePlanogramAttributeState, togglePlanogramAttributeState),
        yield takeEvery(sagaActions.savePlanogramAttribute, savePlanogramAttribute),
    ];
}
