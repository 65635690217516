// @ts-nocheck
import _ from 'lodash';
import { triggerNotification } from 'organisms/notifications/sagaActions';
import { put, takeEvery, select, all } from 'redux-saga/effects';
import { getAPIDataV2, getAPIDataV3, putAPIDataV2, putAPIDataV3 } from 'utils/api';
import * as slice from './reducer';
import * as sagaActions from './sagaActions';
import * as selector from './selector';
import { getDispatch, getState } from './../../reducers/index';
import { updateGridMeta } from 'models/dynamicGrid/sagaActions';
import { updateMetadata } from 'models/dynamicGrid/reducer';
import { REQUESTS } from 'utils/requests';
import { getGridMetadata } from 'models/dynamicGrid/selector';
import { getColumnOperationsQueue } from './selector';
import { REQUEST_STATUS } from 'models/loaders/constants';
import { getTableColumnQueue } from './selector';
import { addColumnOperations } from 'models/tableColumns/reducer';
import { toggleLoader } from 'models/loaders/sagaActions';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import { tab } from '@testing-library/user-event/dist/tab';

type TPayload = {
	payload: {
		tableId: number;
		columns?: number[];
		force?: boolean;
	};
};

export const getTableColumns = (tableId: number, isUploadEnabled?: boolean): Promise<any> => {
	return getAPIDataV3({
		service: REQUESTS.GET.GET_TABLE_COLUMNS_MONGO.service,
		url:  REQUESTS.GET.GET_TABLE_COLUMNS_MONGO.url,
		queryParams: { isUploadEnabled },
		pathParams: { tableId }
	})
		.then((response) => ({ response: response?.data }))
		.catch((error) => ({ error, isError: true }));
};

export const getTableUploadColumns = (tableId: number, isUploadEnabled: boolean): Promise<any> => {
	return getAPIDataV3({
		service: REQUESTS.GET.GET_TABLE_UPLOAD_COLUMNS.service,
		url: REQUESTS.GET.GET_TABLE_UPLOAD_COLUMNS.url,
		pathParams: { tableId },
		queryParams: {
			isUploadEnabled
		}
	})
		.then((response) => ({ response: response?.data }))
		.catch((error) => ({ error, isError: true }));
};


const putResetTableColumns = (tableId: number): Promise<any> => {
	return putAPIDataV3({ service: REQUESTS.PUT.RESET_TABLE_COLUMNS_MONGO.service, url: REQUESTS.PUT.RESET_TABLE_COLUMNS_MONGO.url, pathParams: { tableId } })
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true }));
};

const putUpdateTableColumns = (
	tableId: number,
	columns: number[] = [],
	options: any
): Promise<any> => {
	return putAPIDataV3({
		service: REQUESTS.PUT.UPDATE_USER_COLUMN_VIEW.service,
		url: REQUESTS.PUT.UPDATE_USER_COLUMN_VIEW.url,
		pathParams: {
			tableId
		},
		body: options?.isBulkUpdate ? options.bulkUpdateColumns : { ColumnIds: columns.join(',') }
	}
	)
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true }));
};

const putUpdateTableColumnConfig = (tableId: number, columnId: any, attributes: any, options: any): Promise<any> => {
	return putAPIDataV3({
		service: REQUESTS.PUT.UPDATE_GRID_COLUMN_MONGO.service,
		url: REQUESTS.PUT.UPDATE_GRID_COLUMN_MONGO.url,
		pathParams: {
			tableId
		},
		body: options.isBulkUpdate
			? _.omit({ ...options }, ['_id', '_v'])
			: {
				columnId,
				attributes
			}

	})
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true }));
};

// ************************* Ag Grid start ***********************************************
export async function fetchGridMetaData({ payload: { tableId } }) {
	try {

		const dispatch = getDispatch();

		dispatch(slice.updateLoader({ eventName: `fetch/metadata/${tableId}`, isLoading: true }));

		const metaData = await getAPIDataV3({
			service: REQUESTS.GET.GET_USER_GRID_DATA.service,
			url: REQUESTS.GET.GET_USER_GRID_DATA.url,
			pathParams: { tableId }
		});
		const data = _.pick(metaData?.data, [
			'IsPivot',
			'IsERPEnabled',
			'IsExportEnabled',
			'IsGroupingEnabled',
			'MultiSelectOptions',
			'UploadTableID',
			'ChildTableID',
			'ChildTableFieldGroups'
		]);
		dispatch(updateMetadata({ tableId, metaData: data, isAppend: true }));

		dispatch(slice.updateLoader({ eventName: `fetch/metadata/${tableId}`, isLoading: false }));
		return data
	} catch (e) {
		console.log(e);
	}
}

export async function fetchTableColumnsAg({ payload }: TPayload) {
	const { tableId, forceUpdate = true, isUploadGrid, parentTableId } = payload;

	const state = getState();
	const dispatch = getDispatch();
	const tableColumns = selector.getTableColumns(state, tableId);
	const gridMeta = getGridMetadata(getState(), tableId);

	if (!forceUpdate && tableColumns?.length) {
		return;
	}
	dispatch(slice.updateLoader({ eventName: tableId.toString(), isLoading: true }));

	dispatch(addColumnOperations({ tableId, columnOperations: [], isReplace: true }));

	const { response, isError, error } = await (isUploadGrid || gridMeta?.isUploadEnabled ? getTableUploadColumns(tableId) : getTableColumns(tableId, true));



	if (error?.message === 'CANCELLED') {
		return;
	}

	if (isError) {
		dispatch(triggerNotification({ message: 'Unable to fetch table columns, please try again later.', parentTableId, tableId }));

		return;
	}

	dispatch(slice.updateTableColumns({ tableId, tableColumns: response?.data }));
	dispatch(slice.updateLoader({ eventName: tableId.toString(), isLoading: false }));
}

function* resetTableColumnsAg({ payload }: TPayload) {
	const { tableId } = payload;
	yield put(slice.updateLoader({ eventName: tableId.toString(), isLoading: true }));
	const gridMeta = getGridMetadata(getState(), tableId);
	const { isError, error } = yield putResetTableColumns(tableId, true);

	if (error?.message === 'CANCELLED') {
		return;
	}
	if (gridMeta.ChildTableID) {
		const { isError, error } = yield putResetTableColumns(gridMeta.ChildTableID, true);
	}
	if (isError) {
		// TODO: Error Handling
		yield put(triggerNotification({ message: 'Unable to reset table columns, please try again later.' }));
		yield put(slice.updateLoader({ eventName: tableId.toString(), isLoading: false }));
		return;
	}

	yield fetchTableColumnsAg({ payload: { tableId, isForced: true } });
	if (gridMeta.ChildTableID) {
		yield fetchTableColumnsAg({ payload: { tableId: gridMeta.ChildTableID, isForced: true } });
		// const { isError, error } = yield putResetTableColumns(gridMeta.ChildTableID, true);
	}
	yield put(slice.updateLoader({ eventName: tableId.toString(), isLoading: false }));
}

export async function updateTableColumnsAg({ payload }: TPayload) {
	const { tableId, columns = [] } = payload;
	const dispatch = getDispatch();

	// yield put(
	// 	slice.updateLoader({ eventName: tableId.toString(), isLoading: true })
	// );

	const { isError, error } = await putUpdateTableColumns(tableId, columns, true);

	// if (error?.message === 'CANCELLED') {
	// 	return;
	// }

	const tableColumns = selector.getTableColumnsInfo(getState(), tableId);

	dispatch(
		slice.updateTableColumns({
			tableId,
			tableColumns: _.map(tableColumns, c => {
				return { ...c, IsColumnEnabled: Boolean(columns.includes(c.AppColumnSurrogateID)) };
			})
		})
	);

	if (isError) {
		dispatch(triggerNotification({ message: 'Unable to update table columns, please try again later.' }));
		// TODO: Error Handling
		dispatch(slice.updateLoader({ eventName: tableId.toString(), isLoading: false }));
		return;
	}

	// dispatch(slice.setCustomizeViewModal({ isCustomizeViewModalEnabled: false }));
	// await fetchTableColumns({ payload: { tableId, force: true } });
	// dispatch(slice.updateLoader({ eventName: tableId.toString(), isLoading: false }));
}

export async function updateTableColumnConfigAg({ payload }: TPayload) {
	const { columnId, attributes, tableId, isBulkUpdate, bulkUpdateColumns, isReset, updatesQueue } = payload;
	const dispatch = getDispatch();

	dispatch(toggleLoader({ component: 'sync-grid-columns', isLoading: true }));


	const { isError, error } = await putUpdateTableColumnConfig(tableId, columnId, attributes, {
		isBulkUpdate,
		bulkUpdateColumns
	})
	
	dispatch(toggleLoader({ component: 'sync-grid-columns', isLoading: false }));
	// setTimeout(()=>{
	// 	dispatch(
	// 		dynamicGridSagaActions.refreshGridData({
	// 			tableId: tableId,
	// 		})
	// 	);
	// },2000)

	if (isReset) {
		dispatch(
			slice.updateTableColumns({
				tableId,
				tableColumns: bulkUpdateColumns
			})
		);
	}
	// if (!getTableColumnQueue(getState(), tableId)?.length) {

	// }
	if (isError) {
		dispatch(triggerNotification({ message: 'Unable to update table columns, please try again later.' }));
		// TODO: Error Handling
		dispatch(slice.updateLoader({ eventName: tableId?.toString(), isLoading: false }));
		return;
	}

	// dispatch(slice.setCustomizeViewModal({ isCustomizeViewModalEnabled: false }));
	// await fetchTableColumns({ payload: { tableId, force: true } });
	// dispatch(slice.updateLoader({ eventName: tableId.toString(), isLoading: false }));
}

// ************************* Ag Grid end ***********************************************

export default function* rootSaga() {
	function* resetTableColumns({ payload }: TPayload) {
		const { tableId } = payload;

		yield put(slice.updateLoader({ eventName: tableId.toString(), isLoading: true }));

		const { isError, error } = yield putResetTableColumns(tableId);
		if (error?.message === 'CANCELLED') {
			return;
		}

		if (isError) {
			// TODO: Error Handling
			yield put(triggerNotification({ message: 'Unable to reset table columns, please try again later.' }));
			yield put(slice.updateLoader({ eventName: tableId.toString(), isLoading: false }));
			return;
		}

		yield fetchTableColumns({ payload: { tableId, force: true } });
		yield put(slice.updateLoader({ eventName: tableId.toString(), isLoading: false }));
	}

	function* setCustomizeViewModal({ payload }: any) {
		const { isCustomizeViewModalEnabled } = payload;

		yield put(slice.setCustomizeViewModal({ isCustomizeViewModalEnabled }));
	}

	function* onGridsCleanUp({ payload }: any) {
		for (let index in payload.tableIds) {
			yield put(slice.updateTableColumns({ tableId: payload.tableIds[index], tableColumns: null }));
		}
	}

	async function updateTableColumns({ payload }: TPayload) {
		const { tableId, columns = [] } = payload;
		const dispatch = getDispatch();
		// yield put(
		// 	slice.updateLoader({ eventName: tableId.toString(), isLoading: true })
		// );

		const { isError, error } = await putUpdateTableColumns(tableId, columns);

		if (error?.message === 'CANCELLED') {
			return;
		}

		if (isError) {
			dispatch(triggerNotification({ message: 'Unable to update table columns, please try again later.' }));
			// TODO: Error Handling
			dispatch(slice.updateLoader({ eventName: tableId.toString(), isLoading: false }));
			return;
		}

		dispatch(slice.setCustomizeViewModal({ isCustomizeViewModalEnabled: false }));

		let a = await fetchTableColumns({ payload: { tableId, forced: true } });

		dispatch(slice.updateLoader({ eventName: tableId.toString(), isLoading: false }));
	}

	async function fetchTableColumns({ payload }: TPayload) {
		try {
			const state = getState();
			const dispatch = getDispatch();
			const { tableId, forced = false } = payload;
			dispatch(slice.updateLoader({ eventName: tableId.toString(), isLoading: true }));

			// const data = await fetchGridMetaData(tableId);
			// dispatch(updateGridMeta(tableId, data, true));
		
			const tableColumns = selector.getTableColumns(state, tableId);
			if (tableColumns && !forced) {
				dispatch(slice.updateLoader({ eventName: tableId.toString(), isLoading: false }));

				return false;
			}
			// dispatch(slice.updateLoader({ eventName: tableId.toString(), isLoading: true }));

			const { response, isError, error } = await getTableColumns(tableId);

			if (error?.message === 'CANCELLED') {
				dispatch(slice.updateLoader({ eventName: tableId.toString(), isLoading: false }));
				return;
			}

			if (isError) {
				dispatch(slice.updateLoader({ eventName: tableId.toString(), isLoading: false }));

				// TODO: Error Handling
				dispatch(triggerNotification({ message: 'Unable to fetch table columns, please try again later.' }));
				// yield put(
				// 	slice.updateLoader({ eventName: tableId.toString(), isLoading: false })
				// );
				return;
			}

			dispatch(slice.updateTableColumns({ tableId, tableColumns: response?.data }));
			dispatch(slice.updateLoader({ eventName: tableId.toString(), isLoading: false }));
		} catch (e) {
			console.log(e);
		}
	}

	return [
		yield takeEvery(sagaActions.fetchTableColumns, fetchTableColumns),
		yield takeEvery(sagaActions.fetchTableColumnsAg, fetchTableColumnsAg),

		yield takeEvery(sagaActions.resetTableColumns, resetTableColumns),
		yield takeEvery(sagaActions.resetTableColumnsAg, resetTableColumnsAg),

		yield takeEvery(sagaActions.updateTableColumns, updateTableColumns),
		yield takeEvery(sagaActions.updateTableColumnsAg, updateTableColumnsAg),

		yield takeEvery(sagaActions.fetchGridMetaData, fetchGridMetaData),

		yield takeEvery(sagaActions.updateTableColumnConfigAg, updateTableColumnConfigAg),

		yield takeEvery(sagaActions.setCustomizeViewModal, setCustomizeViewModal),

		yield takeEvery(sagaActions.cleanUpGrids, onGridsCleanUp)
	];
}
