export const DROPDOWN_VALUES = {
	UPDATE: 'EDIT',
	DELETE: 'DELETE',
	RENAME: 'RENAME'
};
export const DROPDOWN_DISPLAY_NAMES = {
	UPDATE: 'Update Bookmark',
	RENAME: 'Rename and Update Bookmark',
	DELETE: 'Delete Bookmark'
};

export const DROPDOWN_MENU_BOOKMARK = [
	{
		displayName: DROPDOWN_DISPLAY_NAMES.UPDATE,
		value: DROPDOWN_VALUES.UPDATE
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.DELETE,
		value: DROPDOWN_VALUES.DELETE
	},
	{
		displayName: DROPDOWN_DISPLAY_NAMES.RENAME,
		value: DROPDOWN_VALUES.RENAME
	}
];
