// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';
import * as query from './query';
import * as types from './types';

export const onReceiveEvent = createAction(
	'subscriptions/handle-recieve-subscription-event',
	(eventData: types.EventMetaData) => ({
		payload: eventData
	})
);

export const handlePublishEvent = createAction(
	'subscriptions/handle-publish-event',
	(eventData: types.PublishEventMetaData) => ({
		payload: eventData
	})
);
