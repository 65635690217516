// @ts-nocheck
import BodyText from 'atoms/bodyText/bodyText';
import Modal from 'atoms/modal/modal';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import _ from 'lodash';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalActions from '../common/modalActions';
import ModalHeader from '../common/modalHeader';
import * as sagaActions from '../sagaActions';
import Loader from 'atoms/loader';
import * as selectors from '../selectors';
import { Checkbox } from 'antd';
import Label from 'atoms/label/label';
import { isLoadingEnabled } from 'models/loaders/selectors';

interface TProps {
	hideModal: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
	selectedTab: string;
}

const ThetaOrderPublishModal = (props: TProps) => {
	const { hideModal, selectedTab } = props;
	const [erpStatus, updateErpStatus] = useState(false);
	const dispatch = useDispatch();
	const isErpEnabled = useSelector(state => dynamicGridSelectors.getERPStatus(state, selectedTab));

	const selectedOrders = useSelector(state => dynamicGridSelectors.getTableSelection(state, Number(selectedTab)));
	const orders = useSelector(state => dynamicGridSelectors.getGridData(state, Number(selectedTab)));
	const orderTotalCount = useSelector(state => dynamicGridSelectors.getTotalCount(state, Number(selectedTab)));

	useEffect(() => {
		updateErpStatus(isErpEnabled);
	}, [isErpEnabled]);

	const getOrderIds = () => {
		if (Number(selectedTab) === 30) {
			let filteredOrders = _.filter(orders, singleOrder =>
				selectedOrders?.selectedRowKeys?.includes(singleOrder.OrderKitID)
			);
			filteredOrders = filteredOrders.map(singleOrder => singleOrder.OrderID);
			return filteredOrders.join(',');
		} else {
			return selectedOrders?.selectedRowKeys?.join(',');
		}
	};

	const handlePublishOrder = (e: any) => {
		const data = {
			OrderIDs: getOrderIds(),
			isErpEnabled: erpStatus
		};
		dispatch(sagaActions.publishOrders(data, selectedTab));
	};
	const isLoadingErp = useSelector(state => isLoadingEnabled(state, 'isLoadingErp'));

	const isLoading = useSelector(state => selectors.getLoadingStatus(state));

	return (
		<Modal hideModal={hideModal} height={300}>
			<Sheet isInset>
				<SheetBody>
					<Stack isVertical gutter="small">
						<StackItem>
							<ModalHeader title={'Publish Orders'} />
						</StackItem>
						<StackItem isStretch isGrow>
							{isLoading || isLoadingErp ? (
								<Stack isVertical>
									<StackItem isGrow isStretch align="center">
										<Loader />
									</StackItem>
								</Stack>
							) : (
								<Stack isVertical gutter="medium">
									<StackItem>
										<BodyText>
											Are you sure you'd like to publish the{' '}
											{selectedOrders?.isSelectAll
												? orderTotalCount
												: selectedOrders?.selectedRowKeys.length}{' '}
											selected orders?
										</BodyText>
									</StackItem>
									{isErpEnabled && (
										<StackItem>
											<Stack gutter="small">
												<StackItem>
													<Checkbox
														onChange={e => updateErpStatus(e.target.checked)}
														checked={erpStatus}
													></Checkbox>
												</StackItem>
												<StackItem position="center">
													<Label>ERP Enabled</Label>
												</StackItem>
											</Stack>
										</StackItem>
									)}
								</Stack>
							)}
						</StackItem>
						<StackItem isStretch>
							<ModalActions
								secondaryButtonName={'NO, DO NOT PUBLISH THEM'}
								primaryButtonName={'YES, PUBLISH THEM'}
								primaryButtonClick={handlePublishOrder}
								secondaryButtonClick={hideModal}
								getPrimaryDisabledStatus={() => false}
								isLoading={isLoading}
							/>
						</StackItem>
					</Stack>
				</SheetBody>
			</Sheet>
		</Modal>
	);
};

export default ThetaOrderPublishModal;
