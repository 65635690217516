/* eslint-disable import/no-anonymous-default-export */
// @ts-nocheck

import DemandGroupHub from './demandGroupHub/demandGroupHub';
import DemandPlanning from './demandPlanning';
import DemandPlanningSagas from './sagas';
import DemandPlanningGroupHubSagas from './demandGroupHub/sagas';
import DemandPlanningGroupHubReducer from './demandGroupHub/reducer';
import { isDemandChangeReadWrite, isDemandGroupHubReadWrite, isDemandReportingReadWrite } from 'models/user/selector';

export default [
	{
		path: '/demand-planning',
		key: 'key',
		componentId: 'demand-planning',
		component: () => <DemandPlanning />,
		routes: [],
		importReducer: () => {
			return [];
		},
		importSagas: () => {
			return [DemandPlanningSagas];
		},
		headerName: 'Demand Planning',
		isEnabled: state =>  isDemandChangeReadWrite(state) || isDemandReportingReadWrite(state),
		title: 'Skulicity | Demand Planning'
	},
	{
		path: '/demand-planning/hub',
		key: 'key',
		componentId: 'demand-group-hub',
		component: () => <DemandGroupHub />,
		routes: [],
		importReducer: () => {
			return [DemandPlanningGroupHubReducer];
		},
		isEnabled: state =>  isDemandGroupHubReadWrite(state) ,
		importSagas: () => {
			return [DemandPlanningGroupHubSagas];
		},
		headerName: 'Demand Planning',
		title: 'Skulicity | Demand Planning'
	}
];
