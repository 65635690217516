// @ts-nocheck
import { initialState } from './reducer';
import { TReducer, TFilter, TMetadata } from './types';
import * as constants from './constants';
import * as query from './query';
import { getUserSettings } from 'models/user/selector';

const getStateData = (state: any): TReducer => state[constants.MODEL_NAME] || initialState;

export const isLoading = (state: any, eventName: string): boolean => {
	const reducer = getStateData(state);

	return reducer.isLoading[eventName];
};

export const getGridData = (state: any, tableId: number): any[] => {
	const reducer = getStateData(state);

	const data = reducer.data[tableId?.toString()];

	return data;
};

export const getGridRowData = (state: any, tableId: number, callback: any): any[] => {
	const reducer = getStateData(state);

	const data = reducer.data[tableId?.toString()];

	return _.findIndex(data, callback)
};

export const getTableSelection = (state: any, tableId: number) => {
	const reducer = getStateData(state);
	return reducer.selection[tableId.toString()];
};

export const getComponentUserSettings = (state: any, componentName: string) => {
	const reducer = getStateData(state);
	return reducer.userSettings[componentName]?.metaData;
};

export const getGridFilters = (state: any, tableId: number): TFilter => {
	const reducer = getStateData(state);

	return reducer?.filters[tableId?.toString()];
};

export const getGridFiltersPivotMode = (state: any, tableId: number): TFilter => {
	const reducer = getStateData(state);

	return reducer?.filters[tableId?.toString()]?.isPivotMode;
};


export const getGridFiltersByColumn = (state: any, tableId: number, columnName: string): TFilter => {
	return _.filter(getGridFilters(state, tableId)?.search, ({ criteria }) => criteria?.rowIdentifier === columnName);
};





export const isAgPivotEnabled = (state: any, tableId: number) => {
	const reducer = getStateData(state);
	return reducer.metaData?.[tableId?.toString()]?.IsPivot;
};
export const isAgGroupingEnabled = (state: any, tableId: number) => {
	const reducer = getStateData(state);
	return reducer.metaData?.[tableId?.toString()]?.IsGroupingEnabled;
};



export const getGridMetadata = (state: any, tableId: number): TMetadata => {
	const reducer = getStateData(state);
	return reducer.metaData?.[tableId?.toString()];
};

export const getERPStatus = (state, tableId) => {
	return getGridMetadata(state, tableId)?.isErpEnabled;
};

export const isTableStorageEnabled = (state: any, tableId: number): TMetadata => {
	return getGridMetadata(state, tableId)?.isStorageEnabled;
};

export const hasSearchFilters = (state: any, tableId: number): boolean | undefined => {
	const reducer = getStateData(state);

	const filters = reducer.filters[tableId.toString()];

	if (!filters || !filters.search) {
		return undefined;
	}

	return !!filters.search.length;
};

export const getFilteredGirdData = (state: any, tableId: number): any[] => {
	const reducer = getStateData(state);

	const data = reducer.data[tableId.toString()];
	const filters = reducer.filters[tableId.toString()];

	return query.getFilteredData(data || [], filters || []);
};

export const getTotalCount = (state: any, tableId: number) => {
	const reducer = getStateData(state);
	return reducer.data[tableId.toString()]?.[0]?.['TotalRecordCount'] || 0;
};
