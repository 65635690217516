// @ts-nocheck
import { Menu, Dropdown, Button } from 'antd';
import React from 'react';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import _ from 'lodash';
const AntDropdown = (props: any) => {
  const { placeholder, options, selectedOption, updateHandler } = props;

  const handleMenuClick = ({key}:any) => {
    let index = _.findIndex(options, function(item:any){
      return item.value == key
    })
    if(index > -1){
      updateHandler(options[index]);
    }
  };

  const menu = () => {
    return (
      <Menu onClick={handleMenuClick} selectedKeys={[selectedOption.value]}>
        {options.map((item: any) => {
          return <Menu.Item key={item.value}>{item.displayName}</Menu.Item>;
        })}
      </Menu>
    );
  };

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button>
        {selectedOption.value ? selectedOption.displayName: placeholder}
      <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default AntDropdown;
