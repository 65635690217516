// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import * as constants from './constants';
import { TReducer } from './types';

export const initialState: TReducer = {
	data: {},
	isLoading: {},
	metaData: {},
	customers: {},
	adTypes: {},
	stores: {}
};

const slice = createSlice({
	initialState,
	name: `${constants.MODEL_NAME}`,
	reducers: {
		updateAdsData(state, { payload: { name, data } }) {
			return {
				...state,
				data: {
					...state.data,
					[name]: data
				}
			};
		}
	}
});

export default slice;

export const { updateAdsData } = slice.actions;
