// @ts-nocheck
import { put, takeEvery } from 'redux-saga/effects';
import { toggleLoaderState, updateAutoCompleteData, updateAvailableApps, updateAvailableWorkflows, updateSelectedApp, updateSelectedWorkflow } from './reducer';
import { toggleLoader, triggerFetchAutoComplete, triggerFetchAvailableApps, triggerFetchAvailableWorkflows } from './sagaActions';
import { getAPIData, getAPIDataV3 } from 'utils/api';
import { REQUEST_STATUS } from 'models/loaders/constants';
import { getAvailableApps } from './selectors';
import { getDispatch } from 'reducers';

export const handleFetchAvailableApps = async ({ payload: { } }) => {
	try {
		const dispatch = getDispatch();
	if (getAvailableApps(getState())?.length) {
		return;
	}
	dispatch(
		updateAvailableApps({
			status: REQUEST_STATUS.PENDING
		})
	);
	const { data } = await getAPIData('apps', 'list');
	dispatch(
		updateAvailableApps({
			data,
			status: REQUEST_STATUS.COMPLETE
		})
	);
	
	dispatch(
		updateSelectedApp({
			selectedApp: data?.[0]
		})
	);
	}
	catch (e) {
		console.log(e)
	}
	
};

export const handleFetchAvailableWorkflows = async ({ payload: { } }) => {
	try {
		const dispatch = getDispatch();
	
	dispatch(
		updateAvailableWorkflows({
			status: REQUEST_STATUS.PENDING
		})
	);
		const { data } = await getAPIDataV3({ service: 'acl', url: 'workflows/list' , 
			queryParams: {
				fieldGroups: ['Operations']
		}
	});
	dispatch(
		updateAvailableWorkflows({
			data: data.data,
			status: REQUEST_STATUS.COMPLETE
		})
	);
	dispatch(
		updateSelectedWorkflow({
			selectedWorkflow: null
		})
	);
	}
	catch (e) {
		console.log(e)
	}
	
};

export default function* rootSaga() {
	return [
		yield takeEvery(triggerFetchAvailableApps, handleFetchAvailableApps),
	yield takeEvery(triggerFetchAvailableWorkflows, handleFetchAvailableWorkflows)];

}
