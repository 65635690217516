// @ts-nocheck
export const TABLE_ID = 30;

export const STORE_PREFIX = 'skus';

export const MENU_ITEMS_NAMES = {
	CUSTOMIZE_VIEW: 'Customize View',
	RESET_VIEW: 'Reset View',
	UPLOAD_DATA: 'Upload Data'
};

export const MENU_ITEMS_VALUES = {
	CUSTOMIZE_VIEW: 'CUSTOMIZE_VIEW',
	RESET_VIEW: 'RESET_VIEW',
	UPLOAD_DATA: 'UPLOAD_DATA'
};

export const MENU_ITEMS = [
	{
		displayName: MENU_ITEMS_NAMES.CUSTOMIZE_VIEW,
		value: MENU_ITEMS_VALUES.CUSTOMIZE_VIEW
	},
	{
		displayName: MENU_ITEMS_NAMES.RESET_VIEW,
		value: MENU_ITEMS_VALUES.RESET_VIEW
	},
	{
		displayName: MENU_ITEMS_NAMES.UPLOAD_DATA,
		value: MENU_ITEMS_VALUES.UPLOAD_DATA
	}
];

export const KITS_COLUMN_TABLES = [8, 7, 9, 25];

export const KITS_TYPES_TABS = [
	{
		name: 'Kits',
		tableId: '30'
	}
];

export const KITS_TABLE_ID = '30';

export const LOADER_COMPONENTS = {
	COLUMN_DEF: 'columnDef',
	HEALTH_CHECK: 'healthCheck'
};

export const KITS_SUMMARY_SUB_TYPES_TABS = [
	{
		name: 'Custom View',
		tableId: '25'
	}
];

export const PAGINATION_DEFAULTS = {
	perPage: 50
};

export const FILTER_QUERY_TYPES = {
	PER_PAGE: 'perPage',
	STATUS: 'statusFilters',
	QUERIES: 'searchQueries',
	PAGE_NO: 'pageNo',
	SORT_FIELD: 'sortField',
	SORT_DIRECTION: 'sortDirection'
};
