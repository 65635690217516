// @ts-nocheck
import * as React from 'react';
import './icon.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import * as svgs from './navSvg';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
	src: 'string';
	disabled: boolean;
	faAlias?: 'string';
	imgAlias?: 'string';
	size?: 'small' | 'medium' | 'large' | 'default' | 'tiny' | 'extraLarge';
	isActive?: boolean;
	type?: string;
	onClick?: React.MouseEventHandler<SVGSVGElement>;
	extraClass?: '';
	isPadded?: boolean;
}

const faSizes = {
	large: 'xlg',
	large: 'lg',
	medium: 'md',
	small: 'sm',
	tiny: 'xs'
};

const Icon: React.FunctionComponent<any> = (props: Props) => {
	const {
		src,
		faAlias,
		isActive,
		isPadded,
		size = 'default',
		onClick,
		type,
		disabled = false,
		imgAlias,
		height,
		width,
		extraClass
	} = props;
	const [state, updateIconState] = React.useState(isActive ? 'active' : '');

	React.useEffect(() => {
		updateIconState(isActive ? type || 'active' : '');
	}, [isActive]);

	const classes: any = classNames([
		'icon',
		{
			['icon--disabled']: disabled,
			[`icon--padded`]: isPadded,
			[`icon--no-pad`]: isPadded === false,
			[`icon--${state}`]: true,
			[`icon--${size}`]: true,
			'icon--alias-active': isActive,
			'icon--alias': Boolean(imgAlias),
			[`icon--${extraClass}`]: true
		}
	]);

	return imgAlias ? (
		<div className={classes}>{imgAlias && svgs[imgAlias] ? React.createElement(svgs[imgAlias], {}) : null}</div>
	) : faAlias ? (
		<FontAwesomeIcon
			className={classes}
			icon={faIcons[faAlias]}
			onClick={isActive ? onClick : () => { }}
			size={faSizes[size] as SizeProp}
		/>
	) : src ? (
		<img src={src} height={height} width={width} className={classes} />
	) : null;
};

Icon.defaultProps = {
	src: '',
	onClick: () => { }
};

export default Icon;
