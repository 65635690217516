// @ts-nocheck
import _, { constant } from 'lodash';
import { put, takeEvery, all, select } from 'redux-saga/effects';
import { getAPIDataV2, getAPIDataV3, postAPIDataV3, putAPIDataV2, putAPIDataV3 } from 'utils/api';
import * as sagaActions from './sagaActions';
import * as constants from './constants';
import * as slice from './reducer';
import * as selectors from './selectors';
import qs from 'qs';
import { TABS_VALUES, PARAMTER_TYPES } from './constants';
import { getItemFromLocalStorage, setItemInLocalStorage } from 'utils/storage';
import * as ModelSaga from '../models/resetModel/sagas';
import * as paginationSelectors from 'pages/models/paginationModel/selectors';
import { REQUESTS } from 'utils/requests';
import { getWebAppSettings } from 'models/user/selector';
import { getState } from 'reducers';
const delay = ms => new Promise(res => setTimeout(res, ms));

const getData = (url: string, filters: any = {}): Promise<any> => {
	const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

	const apiQuery = {
		...filters,
		isView
	};

	return getAPIDataV3({ service: REQUESTS.GET.GET_PARAMETER_DETAILS.service, url, queryParams: apiQuery })
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true }));
};

function* fetchParameterGroups({ payload }) {
	yield put(
		slice.updateLoader({
			eventName: constants.LOADER_COMPONENTS.GROUPS,
			isLoading: true
		})
	);

	let { parameters, isAppend } = payload;
	if (parameters.search) {
		parameters.search = JSON.stringify(parameters.search);
	}

	const { response, isError } = yield getData('groups', parameters).catch(() => ({
		isError: true
	}));

	if (isError) {
		return;
	}
	if (!isAppend && response?.data.length && parameters.parameterTypeId > 2) {
		yield put(
			sagaActions.fetchParameterDetails({
				parameterMasterId: response?.data[0].ParameterMasterID
			})
		);
	}
	let { parametersList } = yield select(state =>
		selectors.getSelectedGroupParameters(state, parameters.parameterTypeId)
	);
	if (!(parametersList && parametersList.length)) {
		const { response } = yield getData('list', _.pick(parameters, ['parameterTypeId']));
		parametersList = response?.data || [];
	}

	yield put(
		slice.updateParameterGroups({
			customers: response?.data,
			isAppend,
			parametersList,
			selectedGroupId: parameters.parameterTypeId
		})
	);
	yield put(
		slice.updateLoader({
			eventName: constants.LOADER_COMPONENTS.GROUPS,
			isLoading: false
		})
	);
}

function* fetchParameterDetails({ payload }) {
	const { parameters } = payload;
	const parameterDetails = yield select(state => selectors.getParamterDetails(state, parameters.parameterMasterId));

	if (!parameters.forced && parameterDetails && parameterDetails.length) {
		return;
	}
	yield put(
		slice.updateLoader({
			eventName: constants.LOADER_COMPONENTS.PARAMETER_DETAILS,
			isLoading: true
		})
	);
	const { response } = yield getData('details', {
		...parameters,
		...{ itemsPerPage: 50, pageNumber: 1 }
	});
	// const parametersList = yield getData('parameters/list', _.pick(parameters, ['parameterTypeId']));

	yield put(
		slice.updateParameterDetails({
			parameterMasterId: parameters.parameterMasterId,
			details: response?.data
		})
	);
	yield put(
		slice.updateLoader({
			eventName: constants.LOADER_COMPONENTS.PARAMETER_DETAILS,
			isLoading: false
		})
	);
}

function* upsertParameterDetails({ payload }) {
	try {
		yield put(
			slice.updateLoader({
				eventName: constants.LOADER_COMPONENTS.PARAMETER_DETAILS,
				isLoading: true
			})
		);
		const { ...parameterRow } = payload;

		if (payload.ParameterDetailID) {
			yield putAPIDataV3({
				...REQUESTS.PUT.UPDATE_PARAMETER_DETAILS,
				body: payload,
				// queryParams,
				pathParams: { parameterDetailId: payload.ParameterDetailID }
			})
			const data = yield select(state =>
				selectors.getParamterDetails(state, payload.ParameterMasterID)
			);
			const details = _.map(data, d => d.ParameterDetailID === payload.ParameterDetailID ?
				{
					...d, ParameterDetailID: payload.ParameterDetailID,
					ParameterMasterID: payload.ParameterMasterID,
					IsOn: payload.IsOn,
					IsActive: payload.IsOn
				} : d
			)
			yield put(slice.updateParameterValue({
				parameterMasterId: payload.ParameterMasterID, details
			}))

		} else {
			const resp = yield postAPIDataV3({ ...REQUESTS.POST.ADD_PARAMETER, body: parameterRow });

			const { data } = yield select(state =>
				selectors.getSelectedGroupParameters(state, payload.ParameterTypeID)
			);
			const rowIndex = _.findIndex(data, d => d[payload.ParameterSelector] === payload.ParameterGroupItemID);
			yield put(
				slice.updateParameterGroupDetails({
					parameterTypeId: payload.ParameterTypeID,
					updatedData: {
						ParameterDetailID: resp.data.data.ParameterDetailID,
						ParameterMasterID: resp.data.data.ParameterMasterID
					},
					rowIndex
				})
			);
			// yield put(slice.updateParameterValue({ parameterMasterId: payload.ParameterTypeID, updatedData: { ParameterMasterID: resp.data.data.ParameterMasterID }, rowIndex }))
		}
		if (
			payload.ParameterTypeID === constants.PARAMETER_TYPES.GLOBAL ||
			payload.ParameterTypeID === constants.PARAMETER_TYPES.DEFAULT
		) {
			yield put(
				sagaActions.fetchParameterGroups(
					{
						parameterTypeId: payload.ParameterTypeID,
						itemsPerPage: 50,
						pageNumber: 1
					},
					false
				)
			);
			yield put(
				slice.updateLoader({
					eventName: constants.LOADER_COMPONENTS.PARAMETER_DETAILS,
					isLoading: false
				})
			);
			return;
		}
		if (payload.ParameterTypeID > 2)
			yield fetchParameterDetails({
				payload: {
					parameters: {
						parameterMasterId: payload.ParameterMasterID || resp.data.data.ParameterMasterID,
						forced: true
					}
				}
			});
		else
			yield put(
				slice.updateLoader({
					eventName: constants.LOADER_COMPONENTS.PARAMETER_DETAILS,
					isLoading: false
				})
			);
	} catch (e) {
		console.log(e, "----------")
		yield put(
			slice.updateLoader({
				eventName: constants.LOADER_COMPONENTS.PARAMETER_DETAILS,
				isLoading: false
			})
		);
	}
	// if (_.has(updatedParam, 'ParameterValue')) {

	// }
	// const parametersList = yield getData('parameters/list', _.pick(parameters, ['parameterTypeId']));

	// yield put(slice.updateParameterDetails({ parameterMasterId: parameters.parameterMasterId, details: response?.data }))
	// yield put(slice.updateLoader({
	// 	eventName: constants.LOADER_COMPONENTS.PARAMETER_DETAILS,
	// 	isLoading: false
	// }))
}

function* updateParameterGroupState({ payload }) {
	const { ParameterMasterID, rowIndex } = payload;
	yield put(
		slice.updateLoader({
			eventName: constants.LOADER_COMPONENTS.PARAMETER_DETAILS,
			isLoading: true
		})
	);

	if (!ParameterMasterID) {
		const { response, isError } = yield postAPIDataV3({
			...REQUESTS.POST.ADD_PARAMETER_GROUP, body: {
				ParameterTypeID: payload.ParameterTypeID,
				InsertedID: payload.rowSelector
			}
		})
			.then(({ data }) => ({ response: data?.data }))
			.catch(e => ({ isError: true }));
		yield put(
			slice.updateParameterGroupDetails({
				parameterTypeId: payload.ParameterTypeID,
				updatedData: { ParameterMasterID: response.ParameterMasterID },
				rowIndex
			})
		);
	} else {
		yield putAPIDataV3({
			...REQUESTS.PUT.UPDATE_PARAMETER_GROUPS, pathParams: { parameterMasterId: ParameterMasterID }, body: {
				updatedValue: payload.UpdatedValue
			}
		});
		if (payload.ParameterTypeID > 2) {
			yield fetchParameterDetails({
				payload: {
					parameters: {
						parameterMasterId: payload.ParameterMasterID,
						forced: true
					}
				}
			});
		}
	}

	yield put(
		slice.updateLoader({
			eventName: constants.LOADER_COMPONENTS.PARAMETER_DETAILS,
			isLoading: false
		})
	);
}

export default function* rootSaga() {
	return [
		yield takeEvery(sagaActions.fetchParameterGroups, fetchParameterGroups),
		yield takeEvery(sagaActions.fetchParameterDetails, fetchParameterDetails),
		yield takeEvery(sagaActions.upsertParameterDetails, upsertParameterDetails),
		yield takeEvery(sagaActions.updateParameterGroupState, updateParameterGroupState)
	];
}
