// @ts-nocheck
import { TMenuItem } from './types';
import _ from 'lodash';

export const checkIsActive = (
  item: TMenuItem,
  selectedTab: number
): boolean => {
  if (item.id === selectedTab) {
    return true;
  }

  if (!item.children) {
    return false;
  }

  return _.some(item.children, { id: selectedTab });
};

export const getSelectedTab = (
  routes: TMenuItem[],
  pathname: string
): number => {
  let selectedTab: number = 0;

  for (const route of routes) {
    const { children = [] } = route;
    if (route.Route === pathname) {
      selectedTab = route.id;
      break;
    }

    for (const childRoute of children) {
      if (childRoute.Route === pathname) {
        selectedTab = childRoute.id;
        break;
      }
    }

    if (selectedTab) {
      break;
    }
  }

  return selectedTab;
};

export const isRouteChild = (
  routes: TMenuItem[],
  pathname: string
): boolean => {
  let isRouteChild = false;

  for (const route of routes) {
    const { children = [] } = route;
    if (route.Route === pathname) {
      break;
    }

    for (const childRoute of children) {
      if (childRoute.Route === pathname) {
        isRouteChild = true;
        break;
      }
    }

    if (isRouteChild) {
      break;
    }
  }

  return isRouteChild;
};
