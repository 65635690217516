// @ts-nocheck
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import Sheet from 'atoms/sheet/sheet';
import { Tab } from 'atoms/tab/tab';
import Tabs from 'atoms/tabs/tabs';
import _ from 'lodash';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import ActionBar from 'pages/orders/actionBar/actionBar';
import qs from 'qs';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import * as constants from './constants';
import HealthCheck from './healthCheck/healthCheck';
import * as sagaActions from './sagaActions';
import * as selectors from './selectors';
import { TTableTab } from './types';
import { getTableIdSelector } from './actionBar/query';
import DropDown from 'atoms/dropdown/dropdown';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import Label from 'atoms/label/label';
import { getStatusQuery } from './query';
import { checkFilterOpen } from 'templates/thetaGridPaginated/query';
import { url } from 'inspector';
import OrderSummary from './summary';
import Button from 'atoms/button/button';
import KitsModal from 'organisms/kitsModal';
import { updateModalToggle } from 'organisms/kitsModal/reducer';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import { resetTableColumns, resetTableColumnsAg } from 'models/tableColumns/sagaActions';
import { getUserSettings } from 'models/user/selector';
import { REQUESTS } from 'utils/requests';
import GridActionBar from 'atoms/GridActionBar';
import { getWebAppSettings } from 'models/user/selector';
export interface Props {
	title: string;
}
interface LocationState {
	from: {
		pathname: string;
	};
}
export interface DropdownType {
	displayName: string;
	value: string;
}



const Orders: React.FunctionComponent<Props> = ({ title }) => {
	const navigate = useNavigate();
	const location = useLocation<LocationState>();
	const query = qs.parse(location.search.replace('?', ''));
	const [gridRef, handleGridRef] = React.useState(null);
	const orderTabs = useSelector(selectors.getOrderTabs);
	const getUrl = value => {
		let url;
		switch (value) {
			case 1:
				url = '/orders?selectedTab=skus';
				break;
			case 2:
				url = '/orders?selectedTab=items';
				break;
			case 30:
				url = '/orders?selectedTab=kits';
				break;
			default:
				url = '/orders?selectedTab=all';
				break;
		}
		return url;
	};
	const getActiveTable = React.useCallback(() => {
		if (!orderTabs?.find(t => t.tableId === constants.ORDER_TABS[query.selectedTab?.toUpperCase()])) {
			navigate(getUrl(orderTabs[0].tableId));
			return orderTabs[0];
		}
		switch (query.selectedTab) {
			case 'skus':
				if (orderTabs[2]?.isEnabled) {
					return orderTabs[2];
				}
			case 'items':
				if (orderTabs[3]?.isEnabled) {
					return orderTabs[3];
				}
			case 'kits':
				if (orderTabs[1]?.isEnabled) {
					return orderTabs[1];
				}
			default:
				return orderTabs[0];
		}
	}, [query.selectedTab]);

	const [isFilterEnabled, updateFilterEnabled] = React.useState(false);
	const [activeTable, setActiveTable] = React.useState<TTableTab>(getActiveTable());
	const dispatch = useDispatch();
	const info = useSelector(state => selectors.getOrderInfo(state));
	const isHealthCheckActive = useSelector(state => selectors.isHealthCheckActive(state));
	const filters = useSelector(state => dynamicGridSelectors.getGridFilters(state, Number(activeTable.tableId)));
	const dataLoading = useSelector(state => dynamicGridSelectors.isLoading(state, activeTable.tableId.toString()));
	const gridMeta = useSelector(state => dynamicGridSelectors.getGridMetadata(state, Number(activeTable.tableId)));

	const isGroupMode = Boolean(
		gridRef?.current?.columnApi?.columnModel?.pivotMode ||
		gridRef?.current?.columnApi?.columnModel?.rowGroupColumns?.length
	);

	React.useEffect(() => {
		setActiveTable(getActiveTable());
	}, [query]);

	React.useEffect(() => {
		if (!filters?.search) {
			return;
		}
		dispatch(
			sagaActions.getOrdersSummaryInfo({
				search: _.get(filters, 'search'),
				tableId: activeTable.tableId
			})
		);
	}, [dispatch, activeTable, filters?.search]);

	const closeHealthCheck = React.useCallback(() => {
		dispatch(sagaActions.toggleHealthCheck({ isEnabled: false }));
	}, [dispatch]);


	const updateActiveTable = React.useCallback(
		({ value }: { value: number }, tableId) => {
			let url = getUrl(value);
			navigate(url);

			const active = orderTabs.find(tabType => tabType.tableId === tableId);
			setActiveTable(active);
		},
		[dispatch]
	);

	const handleResetViewClick = async () => {
			dispatch(resetTableColumnsAg(activeTable.tableId));
	};
	const isView = useSelector(state => getWebAppSettings(state)?.IsAclRowLevelAccessEnabled);


	const gridConfig = {
		enableSelection: true,
		enableSorting: true,
		rowGroupPanelShow: activeTable.tableId != constants.ORDER_TABS.KITS,
		// defaultRowGroupColumn: activeTable.tableId === Number(constants.ORDER_TABS.KITS) ? 'OrderKitID' : null,
		// defaultRowGroupColumnDisplayOverride:
		// 	activeTable.tableId === Number(constants.ORDER_TABS.KITS) ? 'Kit-KitDescription' : null,
		rowPivotPanelShow: activeTable.tableId != constants.ORDER_TABS.KITS,
		isViewImplementationOverride: isView || activeTable.tableId === Number(constants.ORDER_TABS.KITS),
		enableMultiSort: true,
		selectionCallBack: () => { }
	};

	const isFilterOpen = () => {
		if (isFilterEnabled) {
			return true;
		}
		if (checkFilterOpen(filters, activeTable.tableId)) {
			updateFilterEnabled(true);
			return true;
		}
	};

	const templateConfig = React.useMemo(
		() => ({
			gridConfig,
			isFilterEnabled: (isFilterEnabled === undefined ? false : isFilterEnabled) || isFilterOpen(),
			tableId: activeTable.tableId,
			// groupBy: activeTable.tableId === 30 ? 'OrderKitID' : null,
			selectRowKey: activeTable.tableId === 30 || getTableIdSelector(activeTable.tableId),
			childTableSelectRowKey: 'OrderDetailVarietyID',
			dataProviderOptions: {
				type: 'POST',
				version: 2,
				...REQUESTS.POST.GET_GRID_DETAILS_V2,
				pathParams: {
					tableId: activeTable.tableId
				},
				body: {
				}
			}
		}),
		[activeTable, isFilterEnabled, filters]
	);

	const updateStatusParams = (item: any) => {
		let updatedSearch = _.cloneDeep(filters);
		if (!updatedSearch.search) {
			updatedSearch.search = [];
		}
		let index = _.findIndex(updatedSearch.search, function (singleCreteria) {
			return singleCreteria?.criteria?.rowIdentifier === 'OrderStatusID';
		});
		if (index > -1) {
			updatedSearch.search.splice(index, 1);
		}

		updatedSearch.search.push(getStatusQuery(item.value, activeTable.tableId));
		dispatch(
			dynamicGridSagaActions.updateGrid({
				tableId: activeTable.tableId,
				filters: updatedSearch,
			})
		);
	};

	const getSelectedOption = () => {
		let orderStatus: any =
			filters &&
			_.find(filters.search, function (singleCreteria) {
				return singleCreteria?.criteria?.rowIdentifier === 'OrderStatusID';
			});
		if (orderStatus?.query) {
			return _.find(constants.ORDER_STATUS_PARAMS, function (singleItem) {
				return singleItem.value == orderStatus.query;
			});
		}
		return {};
	};

	return (
		<>
			<Stack isVertical>
				<StackItem isStretch align="right">
					<GridActionBar>

						<ActionBar gridMeta={gridMeta} parentTableId={activeTable.tableId}
							isFilterEnabled={isFilterEnabled}
							handleUpdateFilter={updateFilterEnabled}
							selectedTab={activeTable.tableId.toString()}
							handleResetViewClick={handleResetViewClick}
							orderStatus={getSelectedOption()}
						/>
					</GridActionBar>

				</StackItem>
				<StackItem isStretch isGrow>
					<Sheet isPage>
						<KitsModal></KitsModal>
						<Stack isVertical gutter="small">

							<StackItem isGrow>
								<Stack isVertical>
									<StackItem>
										<Tabs>
											{_.map(orderTabs, (tab, index) => {
												return (
													<Tab
														key={index}
														value={tab.tableId}
														isSelected={activeTable.tableId === tab.tableId}
														onSelectTabItem={value => updateActiveTable(value, tab.tableId)}
													>
														<Link to={getUrl(tab.tableId)}>{tab.name}</Link>
													</Tab>
												);
											})}
										</Tabs>
									</StackItem>
									<StackItem isGrow>
										<SheetBody>
											<Stack isVertical gutter="medium">
												<StackItem>
													<Stack isVertical gutter="medium">
														<StackItem>
															<Stack gutter="small">
																<StackItem isGrow={!(isGroupMode)}>
																	<Stack gutter="medium">
																		<StackItem position="center">
																			<Label>Order Status:</Label>
																		</StackItem>
																		<StackItem>
																			<DropDown
																				options={constants.ORDER_STATUS_PARAMS}
																				updateHandler={updateStatusParams}
																				selectedOption={getSelectedOption()}
																				// isAutocomplete={true}
																				placeholder={'Enter Order Status value'}
																			/>
																		</StackItem>
																	</Stack>
																</StackItem>
																{isGroupMode && (
																	<StackItem isGrow>
																		<Stack gutter="medium">
																			<StackItem position="center">
																				<Label>Grouped Edit Type:</Label>
																			</StackItem>
																			<StackItem>
																				<DropDown
																					options={constants.GROUP_EDIT_TYPES}
																					updateHandler={() => { }}
																					selectedOption={
																						constants.GROUP_EDIT_TYPES[0]
																					}
																					// isAutocomplete={true}
																					placeholder={'Enter Order Status value'}
																				/>
																			</StackItem>
																		</Stack>
																	</StackItem>
																)}
																<StackItem>
																	<OrderSummary dataLoading={dataLoading} info={info} />
																</StackItem>
															</Stack>
														</StackItem>
													</Stack>
												</StackItem>

												<StackItem isStretch isGrow>
													<Stack isVertical>
														<StackItem isGrow>
															<ThetaGridPaginated
																// extendFilters={<Filters />}
																onDomRef={handleGridRef}
																key={activeTable.tableId}
																config={templateConfig}
																hasChildRows
															/>
														</StackItem>
													</Stack>
												</StackItem>
											</Stack>
										</SheetBody>
									</StackItem>
								</Stack>
							</StackItem>
						</Stack>
					</Sheet>
				</StackItem>
			</Stack>

			{isHealthCheckActive && (
				<HealthCheck
					isActive={isHealthCheckActive}
					handleOnClose={closeHealthCheck}
					tableId={activeTable.tableId}
				/>
			)}
		</>
	);
};

export default Orders;
