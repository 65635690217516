// @ts-nocheck
import { getGridFilters } from 'models/dynamicGrid/selector';
import { initialState } from './reducer';
// import * as reducer from './reducer';
const queriesSelector = state => state.searchFilters || initialState;

export const getQueries = (state, tableId) => {
	return getGridFilters(state, tableId)?.search;
};

export const getQueriesByColumn = (state, tableId, columnName) => {
	return _.filter(getQueries(state, tableId), ({ criteria }) => criteria?.rowIdentifier === columnName);
};
