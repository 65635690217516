// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";
import { STORE_PREFIX } from "./constants";

export const initialState = {
  openModalName: "",
  loading: false,
};

const slice = createSlice({
  initialState,
  name: STORE_PREFIX,
  reducers: {
    //open modal
    handleOpenModal(state, { payload: { openModalName } }) {
      return {
        ...state,
        openModalName: openModalName,
      };
    },
    handleToggleLoading(state, { payload: { status } }) {
      return {
        ...state,
        loading: status,
      };
    },
  },
});

export default slice;

export const { handleOpenModal, handleToggleLoading } = slice.actions;
