// @ts-nocheck
import { getAPIDataV2, putAPIDataV2 } from 'utils/api';
import qs from 'qs';
import _ from 'lodash';

export const getDropdownOptions = allowedColumns => {
  return _.compact(
    _.map(allowedColumns, column => {
      if (column.colId !== '#') {
        return {
          displayName: column.header,
          value: column.colId,
          rowIdentifier: column.rowIdentifier,
        };
      }
    })
  );
};

export const getUpdatedParameters = (defaultParams, updatedParams) => {
  return _.map(defaultParams, param => {
    if (_.find(updatedParams, { ParameterID: param.ParameterID })) {
      return {
        isEnabled: true,
        ..._.find(updatedParams, { ParameterID: param.ParameterID }),
      };
    }
    return param;
  });
};

export const isValidParamValue = (value: number) => {
  return value < 0
    ? {
        hasError: true,
        errorMessage: 'Value should be greater than or equal to 0',
      }
    : null;
};
