// @ts-nocheck
export const DROPDOWN_OPTIONS_NAME = {
  ENABLED: 'Enabled',
  NOT_ENABLED: 'Not-Enabled',
  ALL: 'All',
};

export const DROPDOWN_OPTIONS_VALUES = {
  ENABLED: '1',
  NOT_ENABLED: '2',
  ALL: '3',
};

export const DROPDOWN_OPTIONS = [
  {
    displayName: DROPDOWN_OPTIONS_NAME.ENABLED,
    value: DROPDOWN_OPTIONS_VALUES.ENABLED,
  },
  {
    displayName: DROPDOWN_OPTIONS_NAME.NOT_ENABLED,
    value: DROPDOWN_OPTIONS_VALUES.NOT_ENABLED,
  },
  {
    displayName: DROPDOWN_OPTIONS_NAME.ALL,
    value: DROPDOWN_OPTIONS_VALUES.ALL,
  },
];
