// @ts-nocheck
import * as React from 'react';
import './numberInput.css';
import classNames from 'classnames';
import _ from 'lodash';
// import { Input } from 'postcss';
import { Input } from 'antd';
import { useEffect } from 'react';

interface Props {
	value?: string;
	onChange?: any;
	onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
	size?: string;
	placeholder?: string;
	minValue?: string;
	defaultValue?: any;
	maxValue?: string;
	isEnabled?: boolean;
	debounceTime?: number;
	align?: string;
}
let debounce;

const NumberInput = (props: Props) => {
	const {
		value,
		onChange,
		onKeyDown,
		size,
		placeholder,
		defaultValue,
		debounceTime,
		minValue,
		maxValue,
		align,
		isEnabled = true
	} = props;
	const classes: any = classNames(['text-input'], {
		[`text-input--${size}`]: true
	});
	const [inputValue, updateInputValue] = React.useState(value);

	const handleUpdate = e => {
		const value = e.target.value;
		updateInputValue(e.target.value);
		// onChange(value);
		clearTimeout(debounce);
		debounce = setTimeout(() => {
			onChange(value);
		}, debounceTime || 1000);
	};

	useEffect(() => {
		updateInputValue(value);
	}, [value]);

	return (
		<Input
			disabled={!isEnabled}
			value={inputValue}
			defaultValue={defaultValue}
			type="number"
			className={classes}
			onChange={handleUpdate}
			//   style={{ textAlign: align || 'left' }}
			onKeyDown={onKeyDown}
			placeholder={placeholder || ''}
			min={minValue}
			max={maxValue}
		/>
	);
};

export default NumberInput;
