// @ts-nocheck
export const TABS_IDENTIFIER = {
	UPCOMING: 'UPCOMING',
	PAST: 'PAST',
	ALL: 'ALL'
};

export const DEFAULT_TABS = [
	{
		title: 'Upcoming',
		type: TABS_IDENTIFIER.UPCOMING
	},
	{
		title: 'Past',
		type: TABS_IDENTIFIER.PAST
	},
	{
		title: 'All',
		type: TABS_IDENTIFIER.ALL
	}
];
export const SEARCH_PARAMS = [
	{
		value: 1,
		rowIdentifier: 'AdTitle',
		displayName: 'Ad Title'
	},
	{
		value: 2,
		rowIdentifier: 'AdDescription',
		displayName: 'Ad Description'
	},
	{
		value: 3,
		rowIdentifier: 'CustomerName',
		displayName: 'Customer Name'
	},
	{
		value: 4,
		rowIdentifier: 'AdType',
		displayName: 'Ad Type'
	}
];

// @ts-nocheck
export const TABLE_ID = 44;

export const STORE_PREFIX = 'items';

export const MENU_ITEMS_NAMES = {
	CUSTOMIZE_VIEW: 'Customize View',
	RESET_VIEW: 'Reset View',
	UPLOAD_DATA: 'Upload Data'
};

export const MENU_ITEMS_VALUES = {
	CUSTOMIZE_VIEW: 'CUSTOMIZE_VIEW',
	RESET_VIEW: 'RESET_VIEW',
	UPLOAD_DATA: 'UPLOAD_DATA'
};

export const MENU_ITEMS = [
	{
		displayName: MENU_ITEMS_NAMES.CUSTOMIZE_VIEW,
		value: MENU_ITEMS_VALUES.CUSTOMIZE_VIEW
	},
	{
		displayName: MENU_ITEMS_NAMES.RESET_VIEW,
		value: MENU_ITEMS_VALUES.RESET_VIEW
	},
	{
		displayName: MENU_ITEMS_NAMES.UPLOAD_DATA,
		value: MENU_ITEMS_VALUES.UPLOAD_DATA
	}
];
