// @ts-nocheck
// userSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { REQUEST_STATUS } from 'models/loaders/constants';

// Initial state
export const initialState = {
	apps: [],
	workflows: [],
	app: {
		status: REQUEST_STATUS.NONE,
		selected: null
	},
	workflow: {
		selected: null,
		status:  REQUEST_STATUS.NONE
	},
	status:  REQUEST_STATUS.NONE
};

// Slice
const slice = createSlice({
	name: 'availableApps',
	initialState,
	reducers: {
		updateAvailableApps: (state, { payload: { data, status } }) => {
			state.apps = data ?? state.apps;
			state.status = status ?? state.status;
		},
		updateSelectedApp: (state, { payload: { selectedApp, status } }) => {
			state.app = {
				selected: selectedApp,
				status: status || state.app.status
			}
		},
		updateAvailableWorkflows: (state, { payload: { data, status } }) => {
			state.workflows = data ?? state.workflows;
			state.status = status ?? state.status;
		},
		updateSelectedWorkflow: (state, { payload: { selectedWorkflow, status } }) => {
			state.workflow =  {
				selected: selectedWorkflow,
				status: status || state.workflow.status 
			}
		}
	}
});

// Actions
export const { updateAvailableApps, updateSelectedApp, updateAvailableWorkflows, updateSelectedWorkflow } = slice.actions;

// Reducer
export default slice;
