// @ts-nocheck
import * as React from 'react';

import Heading from 'atoms/heading/heading';
import Sheet from 'atoms/sheet/sheet';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import { List, Switch } from 'antd';
import Scrollable from 'atoms/scrollable/scrollable';
import Tabs from 'atoms/tabs/tabs';
import { Tab } from 'atoms/tab/tab';
import * as constants from './constants';
import * as selectors from './selectors';
import { useDispatch, useSelector } from 'react-redux';
import * as sagaActions from './sagaActions';
import Loader from 'atoms/loader';
import ParameterDetails from './parameterDetails';
import ParameterDetailItem from './parameterDetailItem';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import Icon from 'atoms/icons/icon';
import { isParametersWrite } from 'models/user/selector';

export type Props = {
  parameterGroup: Record<string, any>;
  selector: string;
  selectedTab: string;
  index: number;
  updateParentGroup: (index: number) => {};
  isActive: boolean;
};

const ParameterGroup = ({
  parameterGroup,
  selector,
  selectedTab,
  index,
  updateParentGroup,
  isActive,
}: Props) => {
  const dispatch = useDispatch();

  const handleUpdateParameterGroup = React.useCallback(
    isEnabled => {
      updateParameterGroup(isEnabled);
      dispatch(
        sagaActions.updateParameterGroupState({
          ParameterTypeID: selectedTab,
          ParameterMasterID: parameterGroup.ParameterMasterID,
          rowSelector: parameterGroup[selector],
          UpdatedValue: isEnabled,
          rowIndex: index,
        })
      );
    },
    [dispatch, index, parameterGroup]
  );
  const hasParametersWrite = useSelector(isParametersWrite)
  const [isGroupEnabled, updateParameterGroup] = React.useState(
    parameterGroup.IsOn
  );
  return (
    <Stack isVertical>
      <StackItem>
        <Stack>
          <StackItem isGrow>
            <Stack gutter="small">
              <StackItem>
                <Switch
                  checked={isGroupEnabled}
                  onChange={handleUpdateParameterGroup}
                  disabled={!hasParametersWrite}
                />
              </StackItem>
              <StackItem>
                <Heading type="secondary">
                  {parameterGroup.GroupTitle ||
                    parameterGroup.CustomerName ||
                    parameterGroup.StoreDescription ||
                    parameterGroup.SKUDescription}
                </Heading>
              </StackItem>
            </Stack>
          </StackItem>
          <StackItem align="right">
            <Icon
              isActive={parameterGroup.ParameterMasterID}
              onClick={() => {
                if (isActive) {
                  updateParentGroup(null);
                  return;
                }
                updateParentGroup(index);
                dispatch(
                  sagaActions.fetchParameterDetails({
                    parameterMasterId: parameterGroup.ParameterMasterID,
                  })
                );
              }}
              faAlias={isActive ? 'faCaretDown' : 'faCaretRight'}
            />
          </StackItem>
        </Stack>
      </StackItem>
      {isActive && (
        <StackItem>
          <ParameterDetails
            parameterGroup={parameterGroup}
            key={index}
            selector={constants.DATA_SELECTORS[selectedTab]}
            selectedTab={selectedTab}
            index={index}></ParameterDetails>
        </StackItem>
      )}
    </Stack>
  );
};

export default ParameterGroup;
