// @ts-nocheck

import { getComponentUserSettings, getGridFilters, getGridMetadata } from 'models/dynamicGrid/selector';
import { createComponent, RenderCell, RenderCellAg } from 'models/tableColumns/gridComponents';
import AgGridSearch from 'organisms/searchFilter/agGridSearch';
import AgGridSearchMultiSelect from 'organisms/searchFilter/components/agGridSearchFilter/agGridSearchMultiSelect';

import React from 'react';
import { getState } from 'reducers';
import { getAllowedAggFuncs } from './queries';
import { getGridFilterName } from 'models/dynamicGrid/query';

var KEY_UP = 38;
var KEY_DOWN = 40;
var KEY_LEFT = 37;
var KEY_RIGHT = 39;

export const getChildTableColumnDefinitions = (
	tableColumns,
	tableId,
	cellRendererFramework,
	cellRendererParams
) => {
	return _.map(tableColumns, (t, index) => {
		return {
			field: t.AppColumnName,
			headerName: t.AppColumnAliasName || t.AppColumn,
			width: Number(t.ColumnWidth),
			metaData: { ...t, tableId },
			resizable: true,
			cellRenderer: (props) => {
				return createComponent(t.ComponentName, {
					...props,
					rowIdentifier: t.AppColumnName,
					tableId,
					type: t.ColumnType,
					cellRendererFramework: cellRendererFramework
				});
			},
			// cellDataType: 'boolean',
			// cellRenderer: 'agCheckboxCellRenderer',
			// cellEditor: "agCheckboxCellEditor",
			sortable: t.IsSortingEnabled,
			sort: cellRendererParams?.sortModal?.find(res => res.colId === t.AppColumnQueryName)?.sort,
			// autoHeight: true,
			wrapText: true,
			pinned: t.IsFixedColumn && t.PinPreference,
			//TBD
			suppressMovable: true,
			// 	props => (
			// 	<AgGridSearch
			// 		tableId={tableId}
			// 		{...props}
			// 		// handler={updateSearchQueries}
			// 		isServerHandled
			// 		// searchFilters={getSearchQueries()}
			// 	/>
			// ),
			hide: !t.IsColumnEnabled || t.IsInternalColumn,
			suppressColumnsToolPanel: t.IsInternalColumn,
			// menuTabs: ['generalMenuTab'],
		};
	});
};


export const getColumnDefinitions = (
	tableColumns,
	tableId,
	colDefs,
	refreshTimestamp,
	{
		defaultRowGroupColumn,
		defaultRowGroupColumnDisplayOverride,
		isPivotViewEnabled,
		isPivotMode,
		isUploadGrid,
		isMutliSelectFilterEnabled
	}
) => {
	const gridFilters = getGridFilters(getState(), tableId);
	const gridMetaData = getGridMetadata(getState(), tableId);
	const userSettings = getComponentUserSettings(getState(), getGridFilterName(tableId));
	let updatedTableColumns = [...(tableColumns ?? [])].sort((a, b) => {
		const aIndex = _.findIndex(gridFilters?.rowGroupCols, group => a.AppColumnName === group.AliasName);
		const bIndex = _.findIndex(gridFilters?.rowGroupCols, group => b.AppColumnName === group.AliasName);
		if (aIndex >= 0 || bIndex >= 0) {
			return aIndex - bIndex;
		} else {
			return a.DefaultSortOrder < b.DefaultSortOrder ? -1 : 1;
		}
	});



	return _.map(updatedTableColumns, (t, index) => {
		let hasDefaultRowGroup;
		let cellRenderer;
		const sort = userSettings?.multiSortColumns?.find(({ sortField }) => sortField === t.AppColumnName || sortField === t.AppColumnQueryName)?.sortDirection
		const rowGroupColumn =
			t.AppColumnName === defaultRowGroupColumn
				? t
				: gridFilters?.rowGroupCols?.find(({ AliasName }) => AliasName === t.AppColumnName);
		const groupAggregationColumn = gridFilters?.groupAggregation?.find(
			({ AliasName }) => AliasName === t.AppColumnName
		);
		const pivotColumn = gridFilters?.pivotColumns?.find(({ AliasName }) => AliasName === t.AppColumnName);

		const defaultAggFunction = groupAggregationColumn ? groupAggregationColumn.QueryName?.split('(')[0] : null;

		const group = colDefs?.find(c => c.metaData.AppColumn === t.AppColumn)?.rowGroup;

		if (defaultRowGroupColumn) {
			hasDefaultRowGroup = rowGroupColumn
				? true
				: group
			cellRenderer = hasDefaultRowGroup && "agGroupCellRenderer"
		} else if (rowGroupColumn && refreshTimestamp === null) {
			hasDefaultRowGroup = true;
			cellRenderer = "agGroupCellRenderer";
		} else {
			hasDefaultRowGroup = group
			cellRenderer = group && "agGroupCellRenderer";
		}
		const childCellGroupIndex = updatedTableColumns.findIndex(col => col.IsColumnEnabled && !col.IsInternalColumn);
		return {
			field: t.AppColumnName,
			headerName: t.AppColumnAliasName || t.AppColumn,
			width: Number(t.ColumnWidth),
			metaData: { ...t, tableId },
			resizable: true,
			filter: !t.IsInternalColumn && AgGridSearch,
			aggFunc: defaultAggFunction,
			suppressKeyboardEvent: function (params) {
				// return true (to suppress) if editing and user hit up/down keys
				var keyCode = params.event.keyCode;
				var gridShouldDoNothing =
					keyCode === KEY_UP || keyCode === KEY_DOWN || keyCode == KEY_LEFT || keyCode === KEY_RIGHT;
				return t.ComponentName === 'RenderInput' && gridShouldDoNothing;
			},

			cellRenderer: gridMetaData?.ChildTableID && index === childCellGroupIndex ?
				"agGroupCellRenderer" : cellRenderer ? cellRenderer : props => {
					return createComponent(t.ComponentName, {
						...props,
						rowIdentifier: t.AppColumnName,
						tableId,
						type: t.ColumnType,
						defaultRowGroupColumn,
						defaultRowGroupColumnDisplayOverride,
						isPivotMode,
						isUploadGrid: isUploadGrid
					});
				},

			// cellRenderer: gridMetaData.ChildTableID && index === childCellGroupIndex ? 'agGroupCellRenderer' : props => {
			// 	return createComponent(t.ComponentName, {
			// 		...props,
			// 		rowIdentifier: t.AppColumnName,
			// 		tableId,
			// 		type: t.ColumnType,
			// 		defaultRowGroupColumn,
			// 		defaultRowGroupColumnDisplayOverride,
			// 		isPivotMode,
			// 		isUploadGrid: isUploadGrid
			// 	});
			// },
			cellRendererParams: gridMetaData?.ChildTableID && index === childCellGroupIndex && {
				innerRenderer: props => {
					return createComponent(t.ComponentName, {
						...props,
						rowIdentifier: t.AppColumnName,
						tableId,
						type: t.ColumnType,
						defaultRowGroupColumn,
						defaultRowGroupColumnDisplayOverride,
						isPivotMode,
						isUploadGrid: isUploadGrid,

					})
				}
			},
			sortable: t.IsSortingEnabled,
			// autoHeight: true,
			wrapText: true,
			//TBD
			rowGroup: hasDefaultRowGroup,
			enablePivot: isPivotViewEnabled,
			enableRowGroup: t.IsGroupingEnabled,
			enableValue: t.IsGroupingEnabled,
			allowedAggFuncs: t.Aggregation || getAllowedAggFuncs(t.ColumnType),
			editable: t.IsEditableColumn,
			type: t.IsEditableColumn ? 'editableColumn' : null,
			suppressMovable: isPivotMode,
			pivot: Boolean(pivotColumn),
			sort,
			valueGetter: `data.${t.AppColumnName}`,
			// 	props => (
			// 	<AgGridSearch
			// 		tableId={tableId}
			// 		{...props}
			// 		// handler={updateSearchQueries}
			// 		isServerHandled
			// 		// searchFilters={getSearchQueries()}
			// 	/>
			// ),
			pinned: t.IsFixedColumn && t.PinPreference,
			hide: !t.IsColumnEnabled || t.IsInternalColumn,
			suppressColumnsToolPanel: t.IsInternalColumn
		};
	});
};
