// @ts-nocheck
import * as React from 'react';
import './textArea.css';
import classNames from 'classnames';

interface Props {
  value?: string;
  onChange?: any;
  onKeyDown?: any;
  size?: string;
  placeholder?: string;
}

const TextArea = (props: Props) => {
  const { value, onChange, onKeyDown, placeholder, rows = 3 } = props;
  const classes: any = classNames(['text-area']);
  return (
    <textarea
      rows={rows}
      value={value}
      className={classes}
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder || ''}
    />
  );
};

export default TextArea;
