// @ts-nocheck
import { getGridData, getTableSelection, getTableSelectionRows } from 'models/dynamicGrid/selector';
import { getColumnInfoByIndentifier } from 'models/tableColumns/selector';
import React from 'react';
import { useSelector } from 'react-redux';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import { REQUESTS } from 'utils/requests';
import * as selectors from '../selectors';
import { SELECT_STORES_TABLE_ID } from './constants';
const SelectStores = (props: any) => {
	const orderCloneInfo = useSelector(state => selectors.getCloneOrderInfo(state));
	const apiQuery = {
		refresh: new Date().getTime().toString(),
		shipStartDate: orderCloneInfo?.selectedParameters?.shipDate
	};

	const gridData = useSelector(state => getGridData(state, 23));

	const { CustomerNum } = _.find(gridData, r => _.includes(props.selectedOrders, r.OrderID));

	const gridConfig = {
		enableSelection: true,
		enableSorting: true,
		selectionCallBack: () => {}
	};

	const templateConfig = {
		gridConfig,
		isFilterEnabled: true,
		tableId: SELECT_STORES_TABLE_ID,
	
		dataProviderOptions: {
			version: 2,
			...REQUESTS.POST.GET_VALID_STORES,
			pathParams: { customerId: CustomerNum },
			type: 'POST',
			queryParams: apiQuery,
			body: {},
			multiSelectFieldGroups: {
				CustomerID: CustomerNum
			},
		},
		selectRowKey: 'StoreID'
	};

	return <ThetaGridPaginated isStorageEnabled={false} parent={props.parent} config={templateConfig} />;
};

export default SelectStores;
