// @ts-nocheck
import { TFilter } from './types';

export const MODEL_NAME = 'dynamicGrid';

export const INITIAL_FILTER: TFilter = {
	itemsPerPage: 50,
	pageNumber: 1,
	sortDirection: 'ASC',
	search: [],
	operator: 'AND'
};

export const OrderStatusID = 'OrderStatusID';

export const criteria = {
	displayName: 'Order Status ID',
	value: OrderStatusID,
	rowIdentifier: OrderStatusID,
	queryName: `o.${OrderStatusID}`,
	type: 'number'
};

export const OrderStatusFilter = {
	criteria: criteria,
	query: '3'
};

export const OrderKitsFilter = {
	criteria: { ...criteria, queryName: `OrderStatusID` },
	query: '3'
};

export const OrderID = 'OrderID';

export const orderCriteria = {
	displayName: 'Order ID',
	value: OrderID,
	rowIdentifier: OrderID,
	queryName: `o.${OrderID}`,
	type: 'number'
};

export const OrderFilter = {
	criteria: orderCriteria,
	query: ''
};

export const getOrderFilter = value => {
	return { ...OrderFilter, query: value };
};

export const exportTableIds = ['29', '24', '30'];
