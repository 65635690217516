// @ts-nocheck
import { initialState } from './reducer';
import * as constants from './constants';

const getStateData = (state: any) => state[constants.MODEL_NAME] || initialState;

export const getKitsModalState = (state: any): boolean => {
	const reducer = getStateData(state);

	return reducer.isOpen;
};

export const getModalParams = (state: any): boolean => {
	const reducer = getStateData(state);

	return reducer.modalParams;
};

export const isAvailabilityUpdate = (state: any) => getStateData(state)?.isAvailabilityUpdate;

export const getActiveTable = (state: any) => getStateData(state)?.tableId;

export const getEditMode = (state: any): boolean => {
	const reducer = getStateData(state);
	return reducer.editMode;
};

export const getEditData = (state: any): boolean => {
	const reducer = getStateData(state);
	return reducer.editData;
};

export const isLoading = (state: any): boolean => {
	const reducer = getStateData(state);
	return reducer.isLoading;
};

export const getShipMethods = (state: any) => {
	const reducer = getStateData(state);
	return reducer.shipMethods;
};
