// @ts-nocheck
import Button from 'atoms/button/button';
import DatePicker from 'atoms/datePicker/datePicker';
import Divider from 'atoms/divider/divider';
import Dropdown from 'atoms/dropdown/dropdown';
import Heading from 'atoms/heading/heading';
import Label from 'atoms/label/label';
import Loader from 'atoms/loader';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import TextInput from 'atoms/TextInput/textInput';
import Validation from 'atoms/validation/validation';
import _ from 'lodash';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import moment from 'moment';
import * as generateOrderActions from './sagaActions';
import * as generateOrderSelectors from './selectors';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as query from './query';
import ParameterDetailItem from './parameterDetailItem';
import PlanogramDetails from './planogramDetails';

type TProps = {
	hideModal: React.MouseEventHandler;
	handleNextClick: React.MouseEventHandler;
};

const OrderInfo = (props: TProps) => {
	const { hideModal, handleNextClick } = props;
	const dispatch = useDispatch();
	const data = useSelector(state => generateOrderSelectors.getData(state));

	const customerOptions = useSelector(state => generateOrderSelectors.getCustomerOptions(state));
	const updatedOptions = [
		{
			displayName: 'All',
			value: '-1'
		},
		...customerOptions
	];

	const shippingMethodOptions = useSelector(state => generateOrderSelectors.getShippingMethodsOptions(state));

	const warehouseOptions = useSelector(state => generateOrderSelectors.getWarehouseOptions(state));

	const storeScheduleOptions = useSelector(state => generateOrderSelectors.getStoreScheduleOptions(state));

	const modalLoading = useSelector(state => generateOrderSelectors.isLoading(state, 'loadModal'));
	const today = moment().format('MM-DD-YYYY');
	const validateFormData = React.useCallback((): boolean => {
		return query.validateForm(data);
	}, [data]);
	const parametersList = useSelector(generateOrderSelectors.getParameterList);

	const inputChangeHandler = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e?.target?.type === 'checkbox' ? e.target.checked : e.target?.value;
		if (e.target.name === 'isKitOrder' && value) {
			dispatch(
				generateOrderActions.updateModalData({
					isKitOrder: value,
					usePlanogram: false
				})
			);
			return
		}
		else if (e.target.name === 'usePlanogram' && value) {
			dispatch(
				generateOrderActions.updateModalData({
					isKitOrder: false,
					usePlanogram: value
				})
			);
			return
		}
		dispatch(
			generateOrderActions.updateModalData({
				[e.target.name]: value
			})
		);
	}, []);

	const customerChangeHandler = React.useCallback(
		(item: any) => {
			if (item?.includes('-1') && !data.customerID?.includes('-1')) {
				dispatch(
					generateOrderActions.updateModalData({
						customerID: ['-1']
					})
				);
				return;
			}
			if (data?.customerID?.includes('-1')) {
				dispatch(
					generateOrderActions.updateModalData({
						customerID: _.filter(item, value => Number(value) > 0)
					})
				);
				return;
			}
			dispatch(
				generateOrderActions.updateModalData({
					customerID: item
				})
			);
		},
		[data]
	);

	const shipMethodChangeHandler = React.useCallback((item: any) => {
		dispatch(
			generateOrderActions.updateModalData({
				shipMethodID: item.value
			})
		);
	}, []);

	const warehouseChangeHandler = React.useCallback((item: any) => {
		dispatch(
			generateOrderActions.updateModalData({
				warehouseID: item.value
			})
		);
	}, []);

	const storeScheduleChangeHandler = React.useCallback((item: any) => {
		dispatch(
			generateOrderActions.updateModalData({
				storeScheduleID: item.value
			})
		);
	}, []);

	if (modalLoading) {
		return (
			<Sheet isTab>
				<SheetBody>
					<Stack isVertical>
						<StackItem isGrow isStretch align="center">
							<Loader />
						</StackItem>
					</Stack>
				</SheetBody>
			</Sheet>
		);
	}

	return (
		<Stack isVertical gutter="small">
			<StackItem isGrow>
				<Sheet isTab>
					<SheetBody>
						<Stack isVertical gutter="medium">
							<StackItem isStretch>
								<Heading type="secondary">
									Enter a target Ship and P.O. Date and customer to start the ordering process.
								</Heading>
							</StackItem>

							<StackItem>
								<Stack align="space-between" gutter="large">
									<StackItem isStretch>
										<Stack gutter="small" isVertical>
											<StackItem isGrow>
												<Label isHighlight>
													Ship Date <sup>*</sup>{' '}
												</Label>
											</StackItem>
											<StackItem isGrow>
												<Stack gutter="small" isVertical>
													{data.useStoreSchedule ? (
														<StackItem isGrow isStretch>
															<Validation
																isTheme={true}
																{...query.isValid(
																	'shipDateRange',
																	data.shipDateRange,
																	data
																)}
															>
																<DatePicker
																	isRange={true}
																	placeholder={['From Date', 'To Date']}
																	name={'shipDateRange'}
																	min={today}
																	selectedDate={
																		data.shipDateRange
																			? [
																				data.shipDateRange[0],
																				data.shipDateRange[1]
																			]
																			: null
																	}
																	handleClick={inputChangeHandler}
																/>
															</Validation>
														</StackItem>
													) : (
														<StackItem isStretch isGrow>
															<Validation
																isTheme={true}
																{...query.isValid(
																	'shipStartDate',
																	data.shipStartDate,
																	data
																)}
															>
																<DatePicker
																	placeholder="Ship Start Date"
																	name={'shipStartDate'}
																	min={today}
																	selectedDate={data.shipStartDate}
																	handleClick={inputChangeHandler}
																/>
															</Validation>
														</StackItem>
													)}
												</Stack>
											</StackItem>
										</Stack>
									</StackItem>

									<StackItem isStretch>
										<Stack gutter="small" isVertical align="top">
											<StackItem>
												<Label isHighlight>
													{' '}
													P.O. Date <sup>*</sup>{' '}
												</Label>
											</StackItem>
											<StackItem>
												<Validation isTheme={true} {...query.isValid('PODate', data.PODate)}>
													<DatePicker
														name={'PODate'}
														min={today}
														selectedDate={data.PODate}
														handleClick={inputChangeHandler}
													/>
												</Validation>
											</StackItem>
										</Stack>
									</StackItem>
									<StackItem isStretch>
										<Stack gutter="small" isVertical>
											<StackItem isGrow>
												<Label isHighlight>
													Customer <sup>*</sup>
												</Label>
											</StackItem>
											<StackItem isGrow>
												<Validation
													isTheme={true}
													{...query.isValid('customerID', data.customerID)}
												>
													<Dropdown
														isMultiselect={true}
														placeholder={'Select Customer'}
														options={updatedOptions}
														updateHandler={customerChangeHandler}
														selectedOption={data.customerID}
													/>
												</Validation>
											</StackItem>
										</Stack>
									</StackItem>
								</Stack>
							</StackItem>

							<StackItem>
								<Stack align="space-between" gutter="large">
									<StackItem isStretch>
										<Stack gutter="small" isVertical>
											<StackItem>
												<Label isHighlight>
													Shipping Methods <sup>*</sup>
												</Label>
											</StackItem>
											<StackItem>
												<Validation
													isTheme={true}
													{...query.isValid('shipMethodID', data.shipMethodID)}
												>
													<Dropdown
														placeholder={'Select Shipping Methods'}
														options={shippingMethodOptions}
														updateHandler={shipMethodChangeHandler}
														selectedOption={_.find(shippingMethodOptions, {
															value: data.shipMethodID
														})}
													/>
												</Validation>
											</StackItem>
										</Stack>
									</StackItem>

									<StackItem isStretch>
										<Stack gutter="tiny" isVertical>
											<StackItem>
												<Label isHighlight>
													Warehouses <sup>*</sup>
												</Label>
											</StackItem>
											<StackItem>
												<Validation
													isTheme={true}
													{...query.isValid('warehouseID', data.warehouseID)}
												>
													<Dropdown
														placeholder={'Select Warehouse'}
														options={warehouseOptions}
														updateHandler={warehouseChangeHandler}
														selectedOption={_.find(warehouseOptions, {
															value: data.warehouseID
														})}
													/>
												</Validation>
											</StackItem>
										</Stack>
									</StackItem>
									<StackItem isStretch>
										<Stack gutter="tiny" isVertical align="start">
											<StackItem>
												<Label isHighlight> Memo </Label>
											</StackItem>
											<StackItem align="center">
												<Validation isTheme={true}>
													<TextInput
														name={'orderBatchMemo'}
														size="small"
														value={data.orderBatchMemo}
														onChange={inputChangeHandler}
													/>
												</Validation>
											</StackItem>
										</Stack>
									</StackItem>
								</Stack>
							</StackItem>

							<StackItem>
								{data.useStoreSchedule && (
									<Stack gutter="small" isVertical>
										<StackItem isGrow>
											<Label isHighlight>Store Schedule</Label>
										</StackItem>
										<StackItem>
											<Validation
												isTheme={true}
												{...query.isValid('storeScheduleID', data.storeScheduleID, data)}
											>
												<Dropdown
													placeholder={'Select a Store Schedule'}
													options={storeScheduleOptions}
													updateHandler={storeScheduleChangeHandler}
													selectedOption={_.find(storeScheduleOptions, {
														value: data.storeScheduleID
													})}
												/>
											</Validation>
										</StackItem>
									</Stack>
								)}
							</StackItem>
							<StackItem>
								<Divider></Divider>
							</StackItem>
							<StackItem>
								<Stack gutter="large">
									<StackItem>
										<Stack gutter="small">
											<StackItem isGrow>
												<Label isHighlight> Use Store Schedule </Label>
											</StackItem>
											<StackItem>
												<input
													type={'checkbox'}
													onChange={inputChangeHandler}
													checked={data.useStoreSchedule}
													name={'useStoreSchedule'}
												/>
											</StackItem>
										</Stack>
									</StackItem>
									<StackItem>
										<Stack gutter="small">
											<StackItem isGrow>
												<Label isHighlight> Show Reserve Inventory </Label>
											</StackItem>
											<StackItem>
												<input
													type={'checkbox'}
													onChange={inputChangeHandler}
													checked={data.useReserveInventory}
													name={'useReserveInventory'}
												/>
											</StackItem>
										</Stack>
									</StackItem>
									<StackItem>
										<Stack gutter="small">
											<StackItem isGrow>
												<Label isHighlight> Use Kits </Label>
											</StackItem>
											<StackItem>
												<input
													type={'checkbox'}
													onChange={inputChangeHandler}
													checked={data.isKitOrder}
													name={'isKitOrder'}
												/>
											</StackItem>
										</Stack>
									</StackItem>
									<StackItem>
										<Stack gutter="small">
											<StackItem isGrow>
												<Label isHighlight> Use Primary Warehouse </Label>
											</StackItem>
											<StackItem>
												<input
													type={'checkbox'}
													onChange={inputChangeHandler}
													checked={data.isPrimaryWarehouse}
													name={'isPrimaryWarehouse'}
												/>
											</StackItem>
										</Stack>
									</StackItem>
									<StackItem>
										<Stack gutter="small">
											<StackItem isGrow>
												<Label isHighlight>Zero Quantity Order </Label>
											</StackItem>
											<StackItem>
												<input
													type={'checkbox'}
													onChange={inputChangeHandler}
													checked={data.isZeroQty}
													name={'isZeroQty'}
												/>
											</StackItem>
										</Stack>
									</StackItem>
									<StackItem>
										<Stack gutter="small">
											<StackItem isGrow>
												<Label isHighlight>Show Parameter Settings</Label>
											</StackItem>
											<StackItem>
												<input
													type={'checkbox'}
													onChange={inputChangeHandler}
													checked={data.hasParameters}
													name={'hasParameters'}
												/>
											</StackItem>
										</Stack>
									</StackItem>
									<StackItem>
										<Stack gutter="small">
											<StackItem isGrow>
												<Label isHighlight>Use Planogram</Label>
											</StackItem>
											<StackItem>
												<input
													type={'checkbox'}
													onChange={inputChangeHandler}
													checked={data.usePlanogram}
													name={'usePlanogram'}
												/>
											</StackItem>
										</Stack>
									</StackItem>
								</Stack>
							</StackItem>
							{data.hasParameters && (
								<>
									<StackItem isStretch>
										<Divider />
									</StackItem>
									<StackItem>
										<Stack isVertical gutter="small">
											{_.map(parametersList, (parameter, index) => {
												return (
													<StackItem key={_.uniqueId()}>
														<ParameterDetailItem
															// selector={selector}
															parameter={parameter}
															groupData={parametersList}
														></ParameterDetailItem>
													</StackItem>
												);
											})}
										</Stack>
									</StackItem>
								</>
							)}
							{
								data.usePlanogram && (
									<>
										<StackItem isStretch>
											<Divider />
										</StackItem>
										<StackItem>
											<PlanogramDetails inputChangeHandler={inputChangeHandler}></PlanogramDetails>
										</StackItem>
									</>
								)
							}
						</Stack>
					</SheetBody>
				</Sheet>
			</StackItem>
			<StackItem isStretch>
				<Stack align="right" gutter="small">
					<StackItem position="end">
						<Button
							data-test-id="generate-order-select-stores"
							name="SELECT STORES"
							disabled={validateFormData()}
							handleClick={handleNextClick}
						/>
					</StackItem>
				</Stack>
			</StackItem>
		</Stack>
	);
};

export default OrderInfo;
