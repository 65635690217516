// @ts-nocheck
import { createFiltersFromQueries } from 'models/dynamicGrid/query';
import { getGridFilters } from 'models/dynamicGrid/selector';
import { put, takeEvery, select } from 'redux-saga/effects';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import { postAPIDataV3 } from 'utils/api';
import * as slice from './reducer';
import * as sagaActions from './sagaActions';
import { REQUESTS } from 'utils/requests';
import { getWebAppSettings } from 'models/user/selector';
import { getState } from 'reducers';

function* refetchInfo({ payload: { tableId } }) {
	if (tableId !== 7) {
		return;
	}
	const filters = yield select(state => getGridFilters(state, tableId));

	const searchFilter = {
		filter: createFiltersFromQueries(filters?.search)
	};
	const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

	const { data, isError } = yield postAPIDataV3({service: REQUESTS.POST.GET_AVAILABILITY_INFO.service, url:  REQUESTS.POST.GET_AVAILABILITY_INFO.url, body: {
		search: JSON.stringify(searchFilter),
   		 isView
	}})
		.then(response => ({ data: response?.data }))
		.catch(e => ({ isError: true }));

	yield put(slice.updateAvailabilityInfo({ info: data }));
}

export default function* rootSaga() {
	return [
		yield takeEvery(sagaActions.getAvailabilityInfo, refetchInfo),
		yield takeEvery(dynamicGridSagaActions.handleRowUpdateSuccess, refetchInfo),
		yield takeEvery(dynamicGridSagaActions.handleRowDeleteSuccess, refetchInfo)
	];
}
