// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';
import * as query from './query';

export const loadPage = createAction(query.createActionName('load'), () => ({
  payload: {},
}));

export const changeSchedule = createAction(
  query.createActionName('change-schedule'),
  (schedule: any) => {
    return {
      payload: { schedule },
    };
  }
);

export const getScheduleDetails = createAction(
  query.createActionName('get-schedule-details'),
  (scheduleId: number) => {
    return {
      payload: { scheduleId },
    };
  }
);

export const createSchedule = createAction(
  query.createActionName('create-schedule'),
  (schedule: any) => {
    return {
      payload: { schedule },
    };
  }
);

export const updateSchedule = createAction(
  query.createActionName('update-schedule'),
  (schedule: any) => {
    return {
      payload: { schedule },
    };
  }
);

export const updateScheduleDays = createAction(
  query.createActionName('update-schedule-days'),
  (schedule: any) => {
    return {
      payload: { schedule },
    };
  }
);

export const deleteSchedule = createAction(
  query.createActionName('delete-schedule'),
  (storeScheduleId: number | string) => {
    return {
      payload: { storeScheduleId },
    };
  }
);

export const updateStoreScheduleModalVisibility = createAction(
  query.createActionName('update-store-schedule-visibility'),
  (isStoreScheduleModalVisible: boolean) => {
    return {
      payload: { isStoreScheduleModalVisible },
    };
  }
);

export const updateScheduleDaysModalVisibility = createAction(
  query.createActionName('update-schedule-days-modal-visibility'),
  (isScheduleDaysModalVisible: boolean) => {
    return {
      payload: { isScheduleDaysModalVisible },
    };
  }
);
