// @ts-nocheck
import { IToolPanel } from 'ag-grid-community';
import Button from 'atoms/button/button';
import Heading from 'atoms/heading/heading';
import Label from 'atoms/label/label';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import ModalHeader from 'atoms/modal/modalHeader';
import ReactModal from 'atoms/modal/reactModal';
import TextArea from 'atoms/TextArea/textArea';
import TextInput from 'atoms/TextInput/textInput';
// import { applyAttribute, saveAttribute } from 'models/attributes/sagaActions';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React, { useEffect, useState } from 'react';
import { getDispatch } from 'reducers';
import { savePlanogramAttribute } from './sagaActions';
import { isRequestComplete } from 'models/loaders/selectors';
import { useSelector } from 'react-redux';
import { LOADER_COMPONENTS } from './constants';

interface CustomToolPanelProps extends IToolPanel { }

export const AttributeDetails: React.FC<CustomToolPanelProps> = props => {
    const { tableId, selectedAttribute, onHide = _.noop } = props;
    const [isSubmitted, setSubmitted] = React.useState(false);
    const apiRequestComplete = useSelector(state => isRequestComplete(state, LOADER_COMPONENTS.SAVE_ATTRIBUTE_DETAILS))
    const [attributeName, setAttributeName] = useState(selectedAttribute?.AttributeName);
    const [attributeDescription, setAttributeDescription] = useState(selectedAttribute?.AttributeDescription);
    const dispatch = getDispatch();

    useEffect(() => {
        if (apiRequestComplete && isSubmitted) {
            onHide();

        }
    }, [apiRequestComplete, isSubmitted])
    const handleSaveAttribute = () => {
        dispatch(
            savePlanogramAttribute({
                planogramAttributeId: selectedAttribute?.id,
                planogramAttributeDetails: {
                    attributeName,
                    attributeDescription
                }
            })
        );
        setSubmitted(true);
        // if (selected) dispatch(applyAttribute({ attributeId, tableId }));
    };
    return (
        <>
            <ReactModal hideModal={onHide} height={400} width={400}>
                <ModalHeader>
                    <Heading type="secondary">Create Attribute</Heading>
                </ModalHeader>
                <ModalBody>
                    <Stack isVertical gutter="small">
                        <StackItem>
                            <Label>Attribute Name<sup>*</sup></Label>
                        </StackItem>
                        <StackItem isStretch>
                            <TextInput
                                value={attributeName}
                                placeholder={'Planning attribute name'}
                                onChange={e => {
                                    setAttributeName(e.target.value);
                                }}
                            ></TextInput>
                        </StackItem>
                        <StackItem>
                            <Label>Attribute Description</Label>
                        </StackItem>
                        <StackItem isStretch>
                            <TextArea
                                value={attributeDescription}
                                placeholder="Planning attribute description"
                                onChange={e => {
                                    setAttributeDescription(e.target.value);
                                }}
                            ></TextArea>
                        </StackItem>
                    </Stack>
                </ModalBody>
                <ModalActions>
                    <Stack align="right" gutter="small">
                        <StackItem>
                            <Button isSecondary name="CANCEL" handleClick={onHide} />
                        </StackItem>

                        <StackItem isStretch align="right">
                            <Button
                                disabled={!attributeName?.trim()?.length}
                                name="CREATE"
                                handleClick={handleSaveAttribute}
                            />
                        </StackItem>
                    </Stack>
                </ModalActions>
            </ReactModal>
        </>
    );
};
