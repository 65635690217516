// @ts-nocheck
import { STORE_PREFIX } from "./constants";
import { initialState } from "./reducer";

const pricingSelector = (state) => state[STORE_PREFIX] || initialState;
//open modal
export const getOpenModalName = (state) => {
  return pricingSelector(state)["openModalName"];
};

export const getLoadingStatus = (state) => {
  return pricingSelector(state)["loading"];
};
