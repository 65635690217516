// @ts-nocheck
import Button from 'atoms/button/button';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as generateOrderSelectors from './selectors';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as constants from './constants';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import _ from 'lodash';
import { REQUESTS } from 'utils/requests';
import { createFiltersFromQueries } from 'models/dynamicGrid/query';

type TProps = {
	hideModal: React.MouseEventHandler;
	handleNextClick: React.MouseEventHandler;
	goBack: React.MouseEventHandler;
};

const SelectSKUs = (props: TProps) => {
	const { hideModal, handleNextClick, goBack, hasReserveInventory } = props;
	const data = useSelector(state => generateOrderSelectors.getData(state));

	const selectedSKUs = useSelector(state =>
		dynamicGridSelectors.getTableSelection(
			state,
			data.isKitOrder ? constants.MODAL_TABLE_IDS.KIT_SKUS : constants.MODAL_TABLE_IDS.SKUS
		)
	);
	const isLoadingGrid = useSelector(state =>
		dynamicGridSelectors.isLoading(
			state,
			data.isKitOrder ? constants.MODAL_TABLE_IDS.KIT_SKUS : constants.MODAL_TABLE_IDS.SKUS
		)
	);
	const hasGridData = useSelector(
		state =>
			dynamicGridSelectors.getGridData(
				state,
				data.isKitOrder ? constants.MODAL_TABLE_IDS.KIT_SKUS : constants.MODAL_TABLE_IDS.SKUS
			)?.length
	);
	const selectedStores = useSelector(state =>
		dynamicGridSelectors.getTableSelection(state, constants.MODAL_TABLE_IDS.STORES)
	);

	const storeSearch = useSelector(state =>
		dynamicGridSelectors.getGridFilters(state, constants.MODAL_TABLE_IDS.STORES)
	);
	const [ref, handleUpdateRef] = useState(null);

	const validateFormData = React.useCallback(() => {
		let hasError = true;

		if (selectedSKUs && ((selectedSKUs.isSelectAll && hasGridData) || selectedSKUs?.selectedRowKeys?.length)) {
			hasError = false;
		}

		return hasError;
	}, [selectedSKUs]);

	const gridConfig = {
		enableSelection: true,
		enableSorting: true,
		selectionCallBack: () => {}
	};
	const customerOptions = useSelector(state => generateOrderSelectors.getCustomerOptions(state));
	const templateConfig = {
		gridConfig,
		isFilterEnabled: true,
		tableId: data.isKitOrder ? constants.MODAL_TABLE_IDS.KIT_SKUS : constants.MODAL_TABLE_IDS.SKUS,
  
		dataProviderOptions: {
			version: 2,
			type: 'POST',
			queryParams: {},
			...REQUESTS.POST.GET_GRID_DETAILS_V2,
			pathParams: { tableId: data.isKitOrder ? constants.MODAL_TABLE_IDS.KIT_SKUS : constants.MODAL_TABLE_IDS.SKUS },
			multiSelectFieldGroups: {
					CustomerID: data.customerID.includes('-1')
						? customerOptions.map(({ value }) => value).join(',')
						: data.customerID,
					StoreList: selectedStores.isSelectAll ? 'ALL' : selectedStores?.selectedRowKeys.join(','),
					WarehouseID: data.warehouseID,
					ShippingMethodID: data.shipMethodID,
					StoreScheduleID: data.useStoreSchedule ? data.storeScheduleID : undefined,
					IsKitOrder: data.isKitOrder,
					StartShipDate: data.shipStartDate,
					UsePrimaryWarehouse: data.isPrimaryWarehouse,
					UsePlanogram: data.usePlanogram,
					EndShipDate: data.shipToDate,
					
			},
			multiSelectFilterFieldGroups: {
				StoreSearch: selectedStores?.isSelectAll ? storeSearch?.search ?? [] : []
			},
			body: {
				fieldGroups: {
					CustomerID: data.customerID.includes('-1')
						? customerOptions.map(({ value }) => value).join(',')
						: data.customerID,
					StoreList: selectedStores.isSelectAll ? 'ALL' : selectedStores?.selectedRowKeys.join(','),
					WarehouseID: data.warehouseID,
					ShippingMethodID: data.shipMethodID,
					StoreScheduleID: data.useStoreSchedule ? data.storeScheduleID : undefined,
					IsKitOrder: data.isKitOrder,
					StartShipDate: data.shipStartDate,
					UsePrimaryWarehouse: data.isPrimaryWarehouse,
					UsePlanogram: data.usePlanogram,
					EndShipDate: data.shipToDate
				},
				filterGroups: {
					StoreSearch: selectedStores?.isSelectAll ? storeSearch?.search ?? [] : []
				}
			}
		},
		selectRowKey: 'SKUID'
	};
	return (
		<Stack isVertical gutter="small">
			<StackItem onDomRef={handleUpdateRef} isStretch isGrow>
				<Sheet isTab>
					<SheetBody>
						<ThetaGridPaginated isStorageEnabled={false} offset={60} parent={ref} config={templateConfig} />
					</SheetBody>
				</Sheet>
			</StackItem>
			<StackItem>
				<Stack align="left" gutter="small">
					<StackItem isGrow>
						<Button isSecondary name="Reselect Stores" handleClick={goBack} />
					</StackItem>

					<StackItem isStretch align="right">
						<Button
							disabled={(isLoadingGrid && !hasGridData) || validateFormData()}
							name={hasReserveInventory ? 'Reserve Inventory' : 'Review Orders'}
							handleClick={handleNextClick}
						/>
					</StackItem>
				</Stack>
			</StackItem>
		</Stack>
	);
};

export default SelectSKUs;
