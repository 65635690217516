// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import * as constants from './constants';
import { TReducer } from './types';

export const initialState: TReducer = {
	tableColumns: [],
	isLoading: {},
	isCustomizeViewModalEnabled: false
};

const slice = createSlice({
	initialState,
	name: `${constants.MODEL_NAME}`,
	reducers: {
		updateTableColumns(state, { payload: { tableId, tableColumns } }) {
			return {
				...state,
				tableColumns: {
					...state.tableColumns,
					[tableId]: tableColumns
				}
			};
		},
		updateLoader(state, { payload: { eventName, isLoading } }) {
			return {
				...state,
				isLoading: {
					...state.isLoading,
					[eventName]: isLoading
				}
			};
		},
		setCustomizeViewModal(state, { payload: { isCustomizeViewModalEnabled } }) {
			return {
				...state,
				isCustomizeViewModalEnabled
			};
		},
		addColumnOperations: (state, { payload: { tableId, columnOperations, isReplace } }) => {
			return {
				...state,
				columnOperations: {
					...state.columnOperations,
					[tableId]: isReplace ? columnOperations : [...(state?.columnOperations?.[tableId] ?? []), ...columnOperations]

				}
			};
		}
	}
});

export default slice;

export const { updateTableColumns, addColumnOperations, updateLoader, setCustomizeViewModal } = slice.actions;
