// @ts-nocheck
import { onReceiveEvent } from 'models/subscriptions/sagaActions';
import { getUserEmail, getUserGrpId } from 'models/user/selector';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';

import { getSocketBaseURL } from './helpers';

// import * as query from './query';
let socketRetryCounter = 0;
// tbd ******************* this to be moved to socket context ************
const Socket = (props: TProps) => {
	// tbd ******************* this to be moved to auth context ************
	const userEmail = useSelector(state => getUserEmail(state));
	const grpId = useSelector(state => getUserGrpId(state));
	const dispatch = useDispatch();
	const [isConnected, setIsConnected] = useState(false);

	useEffect(() => {
		// Update network status
		const handleStatusChange = () => {
			dispatch(onReceiveEvent({ type: 'DB_STATUS', metaData: { hasNetworkError: !navigator.onLine } }));
			setIsConnected(navigator.onLine);
		};

		// Listen to the online status
		window.addEventListener('online', handleStatusChange);

		// Listen to the offline status
		window.addEventListener('offline', handleStatusChange);

		// Specify how to clean up after this effect for performance improvment
		return () => {
			window.removeEventListener('online', handleStatusChange);
			window.removeEventListener('offline', handleStatusChange);
		};
	}, [isConnected]);
	// *************************
	let socket;
	// tbd move to event handlers
	// if (getApiBaseURL()) {

	// } else {
	// 	socket = io.connect('wss://as-thetaretail-dev.azurewebsites.net/');
	// }

	useEffect(() => {
		if (grpId && userEmail) {
			socket = io.connect(getSocketBaseURL(), {
				transports: ['websocket']
			});
			console.log(io)
			console.log(socket)
			socket.on('connect', connection => {
				console.log("connected")
				dispatch(onReceiveEvent({ type: 'DB_STATUS', metaData: { hasNetworkError: false } }));

				socket.emit('create', grpId + '::' + userEmail);
			});
			socket.on('disconnect', connection => {
				console.log('disconnected');
				dispatch(onReceiveEvent({ type: 'DB_STATUS', metaData: { isConnected: false } }));
			});
			socket.on('message', message => {
				dispatch(onReceiveEvent(message));
				socket.connect();
			});
		}
	}, [grpId, userEmail, isConnected]);

	// if (!grpId) {
	// 	return null;
	// }
	return <React.Fragment>{props.children}</React.Fragment>;
};

export default Socket;
