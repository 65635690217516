// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getDispatch } from 'reducers';

import * as dynamicGridSagaActions from '../../../models/dynamicGrid/sagaActions';

export const selectAllHandler = ({ tableId, isSelectAll, gridRef, selectRowKey }) => {
	const dispatch = getDispatch();
	gridRef?.current?.api?.forEachNode(function (node) {
		node.setSelected(isSelectAll);
	});
	const selectedRowKeys = isSelectAll
		? _.map(gridRef?.current?.api?.getRenderedNodes(), n => n?.data?.[selectRowKey])
		: [];

	dispatch(
		dynamicGridSagaActions.updateSelectedRows({
			tableId,
			selectedRowKeys,
			isSelectAll
		})
	);
};
