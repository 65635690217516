// @ts-nocheck
import '../advanced.scss';

import DateWrapper from 'atoms/datePicker/datePicker';
import DropDown from 'atoms/dropdown/dropdown';
import Icon from 'atoms/icons/icon';
import Tag from 'atoms/tag/tag';
import TextInput from 'atoms/TextInput/textInput';
import _ from 'lodash';
import { getGridFilters, getGridFiltersByColumn } from 'models/dynamicGrid/selector';
import { GRID_EVENTS } from 'molecules/ReactGrid/agGrid/constants';
import { Events } from 'molecules/ReactGrid/agGrid/utils/events';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import MultiSelectDropdown from 'organisms/multiSelect/multiSelect';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { getDispatch, getState } from 'reducers';
import { formatDate } from 'utils/utilities';
import { v4 as uuidv4 } from 'uuid';

import * as query from '../query';
import * as sagaActions from '../sagaActions'

const MultiSelectConfig = ({
    isOperatorBtwn,
    currentSearchInput,
    isOperatorIn,
    isOperatorBulkIn,
    selectedCriteria,
    currentSearchInputEnd,
    onUpdateModel,
    updateCurrentSearchInputEnd,
    updateCurrentSearchInput,
    selectionProviderOptions,
    isOperatorDate,
    isEqualsOperator,
    onUpdateInput,
    isOperatorEmpty,
    handleSubmit,
    isInline,
    columnData }) => {

    if (isOperatorBulkIn) {
        return (<StackItem isStretch isGrow position="center">
            <MultiSelectDropdown
                componentId={'select-' + columnData.AppColumnName}
                key={'select-start-' + columnData.AppColumnName}
                onChange={e => {
                    onUpdateInput(e);
                    handleSubmit(null, true, {
                        currentSearchInput: e.target.value
                    });
                }}
                isInline={isInline}
                selection={currentSearchInput}
                columnName={columnData.AppColumnName}
                type="textarea"
            />

        </StackItem>)
    }

    if (isOperatorDate) {
        return !isOperatorEmpty && (<StackItem isStretch isGrow position="center">
            <DateWrapper
                selectedDate={
                    isOperatorBtwn ? [currentSearchInput, currentSearchInputEnd] : currentSearchInput
                }
                allowEmpty
                handleClick={e => onUpdateModel({ dateEvent: e })}
                isRange={isOperatorBtwn}
            />
        </StackItem>)
    }
    if (isOperatorIn) {
        return (
            <StackItem span={2}>
                <Stack gutter="small">
                    <StackItem isStretch isGrow>
                        <MultiSelectDropdown
                            componentId={'select-' + columnData.AppColumnName}
                            key={'select-' + columnData.AppColumnName}
                            onChange={e => {
                                onUpdateInput(e);
                                handleSubmit(null, true, {
                                    currentSearchInput: e.target.value
                                });
                            }}
                            isInline={isInline}
                            selection={currentSearchInput}
                            selectionProviderOptions={selectionProviderOptions}
                            columnName={columnData.AppColumnName}
                        />
                    </StackItem>
                </Stack>
            </StackItem>
        )
    }

    if (isEqualsOperator) {
        return (<StackItem isStretch isGrow position="center">
            <Stack gutter="small">
                <StackItem isStretch isGrow>
                    <MultiSelectDropdown
                        componentId={'select-' + columnData.AppColumnName}
                        key={'select-start-' + columnData.AppColumnName}
                        onChange={e => {
                            onUpdateInput(e);
                            handleSubmit(null, true, {
                                currentSearchInput: e.target.value
                            });
                        }}
                        isInline={isInline}
                        isSingleSelect
                        selection={currentSearchInput}
                        selectionProviderOptions={selectionProviderOptions}
                        columnName={columnData.AppColumnName}
                    />
                </StackItem>
            </Stack>
        </StackItem>)
    }

    if (isOperatorBtwn) {
        return (<>
            <StackItem isStretch isGrow position="center">

                {/* <MultiSelectDropdown
                        componentId={'select-' + columnData.AppColumnName}
                        key={'select-start' + columnData.AppColumnName}
                        onChange={e => {
                            onUpdateInput(e);
                            if (currentSearchInputEnd && !isInline) {
                                   handleSubmit(null, true, {
                                currentSearchInput: e.target.value
                                 });
                            }
                            
                         
                        }}
                        size="small"
                        isInline={isInline}
                        hasInputToggle={isInline}
                        isSingleSelect
                        selection={currentSearchInput}
                        selectionProviderOptions={selectionProviderOptions}
                        columnName={columnData.AppColumnName}
                    /> */}
                <TextInput
                    value={currentSearchInput}
                    onKeyDown={_.debounce(handleSubmit, 500)}
                    type={selectedCriteria.type}
                    onChange={onUpdateInput}
                ></TextInput>
            </StackItem>

            <StackItem isStretch isGrow position="center">
                {/* <MultiSelectDropdown
                        componentId={'select-end-' + columnData.AppColumnName}
                        key={'select-end' + columnData.AppColumnName}
                        size="small"
                        onChange={e => {
                            updateCurrentSearchInputEnd(e.target.value)
                            if (currentSearchInput && !isInline) {
                                handleSubmit(null, true, {
                                    currentSearchInputEnd: e.target.value
                                });
                            }
                          
                        }}
                        isInline={isInline}
                        hasInputToggle={isInline}
                        isSingleSelect
                        selection={currentSearchInputEnd}
                        selectionProviderOptions={selectionProviderOptions}
                        columnName={columnData.AppColumnName}
                    /> */}
                <TextInput
                    value={currentSearchInputEnd}
                    onKeyDown={_.debounce(handleSubmit, 500)}
                    type={selectedCriteria.type}
                    onChange={e => updateCurrentSearchInputEnd(e.target.value)}
                ></TextInput>
            </StackItem> </>)
    }

    return (<StackItem isStretch isGrow position="center">
        <TextInput
            value={currentSearchInput}
            onKeyDown={_.debounce(handleSubmit, 500)}
            onChange={onUpdateInput}
            disabled={isOperatorEmpty}
            type={isOperatorBulkIn ? 'text' : selectedCriteria.type}
        ></TextInput>
    </StackItem>)
}


const DefaultConfig = ({ isOperatorBtwn,
    currentSearchInput,
    isOperatorIn,
    currentSearchInputEnd,
    isOperatorDate,
    isOperatorBulkIn,
    isOperatorEmpty,
    selectedCriteria,
    onUpdateModel,
    onUpdateInput,
    handleSubmit }) => {



    if (isOperatorDate) {
        return <StackItem isStretch isGrow position="center">
            <DateWrapper
                selectedDate={
                    isOperatorBtwn ? [currentSearchInput, currentSearchInputEnd] : currentSearchInput
                }
                allowEmpty
                handleClick={e => onUpdateModel({ dateEvent: e })}
                isRange={isOperatorBtwn}
            />
        </StackItem>
    }

    if (isOperatorBtwn) {
        return (<>
            <StackItem isStretch isGrow position="center">
                <TextInput
                    value={currentSearchInput}
                    onKeyDown={_.debounce(handleSubmit, 500)}
                    onChange={onUpdateInput}
                    type={isOperatorIn ? 'text' : selectedCriteria.type}
                ></TextInput>
                {/* <TextInput
                            value={currentSearchInput}
                            onKeyDown={_.debounce(handleSubmit, 500)}
                            type={selectedCriteria.type}
                            onChange={onUpdateInput}
                        ></TextInput> */}
            </StackItem>

            <StackItem isStretch isGrow position="center">
                <TextInput
                    value={currentSearchInputEnd}
                    onKeyDown={_.debounce(handleSubmit, 500)}
                    type={selectedCriteria.type}
                    onChange={e => updateCurrentSearchInputEnd(e.target.value)}
                ></TextInput>
                {/* <TextInput
                            value={currentSearchInputEnd}
                            onKeyDown={_.debounce(handleSubmit, 500)}
                            type={selectedCriteria.type}
                            onChange={e => updateCurrentSearchInputEnd(e.target.value)}
                        ></TextInput> */}
            </StackItem> </>)
    }

    return (<StackItem isStretch isGrow position="center">
        <TextInput
            value={currentSearchInput}
            onKeyDown={_.debounce(handleSubmit, 500)}
            onChange={onUpdateInput}
            disabled={isOperatorEmpty}
            type={isOperatorBulkIn ? 'text' : selectedCriteria.type}
        ></TextInput>
    </StackItem>)

}
const agGridSelectColumnDefinitions = {

    DEFAULT_CONFIG: {
        getTemplate: (props) => {
            return <DefaultConfig {...props} />
        }
    },
    MULTISELECT_CONFIG: {
        getTemplate: (props) => {

            return <MultiSelectConfig {...props} />
        }
    }

}

export default agGridSelectColumnDefinitions;