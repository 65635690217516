// @ts-nocheck
import React, { useState } from 'react';
import { useOutsideAlerter } from 'utils/helpers';
import './modal.scss';
import classNames from 'classnames';
import { Resizable } from 'react-resizable';
import 'react-resizable/css/styles.css';
interface Props {
	hideModal: Function;
	children: React.ReactNode;
	size?: 'small' | 'medium' | 'large';
	height?: number | null;
	width?: number | null;
	isLocked?: false;
}

const Modal = (props: Props) => {
	const { hideModal, children, size = 'small', height, width, onDomRef, isLocked } = props;
	const [state, updateState] = useState({ width, height });
	const onResize = (event, { element, size, handle }) => {
		updateState({ width: size.width, height: size.height });
	};

	const wrapperRef = React.useRef(null);
	const classes: any = classNames(['modal-content', { [`modal-content--${size}`]: true }]);

	useOutsideAlerter(wrapperRef, () => {
		// hideModal();
	});

	return (
		<div className={`app-modal`}>
			<div ref={onDomRef || wrapperRef} className={classes} onClick={e => e.stopPropagation()}>
				<Resizable minConstraints={[100, 100]} height={state.height} width={state.width} onResize={onResize}>
					<div className="app-modal--body" style={{ height: height || '100%', width: width || '100%' }}>
						{children}
					</div>
				</Resizable>
			</div>
		</div>
	);
};

export default Modal;
