// @ts-nocheck
import './actionBar.scss';
import classNames from 'classnames';


interface DropdownType {
	displayName: string;
	value: string;
}
interface Props {
}

const GridActionBar: React.FunctionComponent<any> = ({type, menuActions, children}: Props) => {
	const classes: any = classNames(['grid-actionBar', { [`grid-actionBar--${type}`]: true }]);
	

	if (children) {
		return <>
			
		<div className={classes}>
			{children}
			</div>
			</>
	}
	return null;
}

GridActionBar.defaultProps = {
};

export default GridActionBar;
