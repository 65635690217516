// @ts-nocheck
import * as React from 'react';
import classNames from 'classnames';
import './menuItem.css';

export interface Option {
  displayName: string;
  value: string;
}

interface Props {
  item: Option;
  onSelectItem: (item: Option) => void;
  disabled: boolean;
}

const MenuItem = (props: Props) => {
  const { item, onSelectItem, disabled } = props;
  const classes: any = classNames([
    'menu-item',
    `${disabled ? 'menu-item--disabled' : ''}`,
  ]);

  const handleSelectItem = React.useCallback(() => {
    onSelectItem(item);
  }, [onSelectItem, item]);

  return (
    <div onClick={handleSelectItem} className={classes}>
      {item.displayName}
    </div>
  );
};

export default MenuItem;
