// @ts-nocheck
import React, { useState } from 'react';
import { Radio, Select, Space } from 'antd';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import type { SelectProps, RadioChangeEvent } from 'antd';



const TagSelector: React.FC = ({options = [], defaultValues, onChange}) => {
  const [size, setSize] = useState<SizeType>('middle');

  const handleSizeChange = (e: RadioChangeEvent) => {
    setSize(e.target.value);
  };
 
  return (
        <Select
          mode="multiple"
          size={size}
          placeholder="Please select"
          defaultValue={defaultValues?? []}
          onChange={onChange}
          style={{ width: '100%' }}
          options={options}
        />
      
  );
};

export default TagSelector;