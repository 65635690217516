// @ts-nocheck
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import ModalHeader from 'atoms/modal/modalHeader';
import ReactModal from 'atoms/modal/reactModal';
import { Tab } from 'atoms/tab/tab';
import Tabs from 'atoms/tabs/tabs';
import _ from 'lodash';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as generateOrderActions from './sagaActions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as constants from './constants';
import OrderInfo from './orderInfo';
import ReserveInventory from './reserveInventory';
import ReviewOrders from './reviewOrders';
import SelectSKUs from './selectSKU';
import SelectStores from './selectStores';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import PageLayout from 'templates/pageLayout';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import { getActiveTabs, hasReserverInventory } from './selectors';

type TProps = {
	// hideModal: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
};

const GenerateOrderModal = (props: TProps) => {
	const {} = props;
	const dispatch = useDispatch();
	const [selectedStep, setSelectedStep] = React.useState(constants.STEPS[0]);

	React.useEffect(() => {
		dispatch(generateOrderActions.loadModal());
	}, [dispatch]);

	React.useEffect(() => {
		return () => {
			dispatch(generateOrderActions.cleanUp());
		};
	}, []);

	const getDisabledStatus = (key: string) => {
		switch (key) {
			case constants.STEPS_KEYS.ORDER_INFO:
				return false;
			default:
				return true;
		}
	};

	const hideModal = React.useCallback(() => {
		dispatch(
			dynamicGridSagaActions.cleanUpGrids({
				tableIds: [
					constants.MODAL_TABLE_IDS.STORES,
					constants.MODAL_TABLE_IDS.SKUS,
					constants.MODAL_TABLE_IDS.INVENTORY
				]
			})
		);
		dispatch(generateOrderActions.cleanUp());
	}, []);

	const updateProcessStep = React.useCallback((step: any) => {
		return () => setSelectedStep(step);
	}, []);

	const activeTabs = useSelector(getActiveTabs);
	const hasReserveInventory = useSelector(hasReserverInventory);

	return (
		<PageLayout>
			<Stack isVertical gutter="small">
				{/* <StackItem isStretch>
					<Heading> Generate New Orders </Heading>
				</StackItem>
				<StackItem isStretch>
					<Divider />
				</StackItem> */}
				<Stack isVertical>
					<StackItem isStretch>
						<Stack isVertical gutter="medium">
							<StackItem isStretch>
								<Tabs>
									{_.map(activeTabs, (step, index) => {
										return (
											<Tab
												value={index}
												key={index}
												isSelected={selectedStep.key === step.key}
												onSelectTabItem={updateProcessStep(step)}
												disabled={getDisabledStatus(step.key)}
											>
												{step.title}
											</Tab>
										);
									})}
								</Tabs>
							</StackItem>
						</Stack>
					</StackItem>
					<StackItem isStretch isGrow>
						{selectedStep.key === constants.STEPS_KEYS.ORDER_INFO && (
							<OrderInfo
								key={'orderInfo'}
								hideModal={hideModal}
								handleNextClick={updateProcessStep(constants.STEPS[1])}
							/>
						)}
						{selectedStep.key === constants.STEPS_KEYS.SELECT_STORE && (
							<SelectStores
								key={'selectStores'}
								hideModal={hideModal}
								handleNextClick={updateProcessStep(constants.STEPS[2])}
								goBack={updateProcessStep(constants.STEPS[0])}
							/>
						)}
						{selectedStep.key === constants.STEPS_KEYS.SELECT_SKU && (
							<SelectSKUs
								hideModal={hideModal}
								handleNextClick={updateProcessStep(
									hasReserveInventory ? constants.STEPS[3] : constants.STEPS[4]
								)}
								hasReserveInventory={hasReserveInventory}
								goBack={updateProcessStep(constants.STEPS[1])}
							/>
						)}
						{selectedStep.key === constants.STEPS_KEYS.RESERVE_INVENTORY && hasReserveInventory && (
							<ReserveInventory
								hideModal={hideModal}
								handleNextClick={updateProcessStep(constants.STEPS[4])}
								goBack={updateProcessStep(constants.STEPS[2])}
							/>
						)}
						{selectedStep.key === constants.STEPS_KEYS.REVIEW_ORDERS && (
							<ReviewOrders
								hideModal={hideModal}
								goBack={
									hasReserveInventory
										? updateProcessStep(constants.STEPS[3])
										: updateProcessStep(constants.STEPS[2])
								}
							/>
						)}
					</StackItem>
				</Stack>
			</Stack>
		</PageLayout>
	);
};

export default GenerateOrderModal;
