import Icon from 'atoms/icons/icon';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import { updateStatusID } from 'pages/home/reducer';
import { checkOrderStatus } from 'pages/home/sagaActions';
import { getStatusID } from 'pages/home/selectors';
import React, { Suspense, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getItemFromLocalStorage } from 'utils/storage';

const orderStatusID = getItemFromLocalStorage('statusID');

const CreateOrderStatus = () => {
	const statusID: number = useSelector(state => getStatusID(state));
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(checkOrderStatus({ statusID }));
	}, [statusID]);

	if (!statusID) {
		return null;
	}
	return (
		<div
			style={{
				height: '25px',
				width: '100%',
				background: 'orange',
				textAlign: 'center',
				verticalAlign: 'center',
				fontSize: '14px',
				color: '#001528'
			}}
		>
			<Stack align="center" gutter="small">
				<StackItem>
					<Icon faAlias="faInfoCircle" size="small" />
				</StackItem>
				<StackItem isStretch align="center" position="center">
					We are currently processing your orders and will notify you once they are complete
				</StackItem>
			</Stack>
		</div>
	);
};

export default CreateOrderStatus;
