// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { Alert, Checkbox, Divider, Radio, Upload, message } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import Modal from 'atoms/modal/modal';
import StackItem from 'molecules/stackItem/stackItem';
import FileUploader from 'molecules/fileUploader';
import ModalBody from 'atoms/modal/modalBody';
import Stack from 'molecules/stack/stack';
import { getTableSelection } from 'models/dynamicGrid/selector';
import ModalHeader from 'atoms/modal/modalHeader';
import { toggleLoaderState } from 'models/loaders/reducer';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import Sheet from 'atoms/sheet/sheet';
import { PAGES, UPLOAD_DATA_CHANGE_TYPES, UPLOAD_TYPE } from './constants';
import * as sagaActions from './sagaActions';
import Dropdown from 'atoms/dropdown/dropdown';
import Heading from 'atoms/heading/heading';
import ModalActions from 'atoms/modal/modalActions';
import Button from 'atoms/button/button';
import { useDispatch, useSelector } from 'react-redux';
import { onUploadFileV2, revalidateUploadGrid, submitUploadFile, triggerFetchDownloadTemplate } from './sagaActions';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import { getActivePage } from './selectors';
import * as selectors from './selectors';

import { setActivePage } from './reducer';
import Icon from 'atoms/icons/icon';
import { isLoadingEnabled, isRequestComplete, isRequestPending } from 'models/loaders/selectors';
import Loader from 'atoms/loader';
import BodyText from 'atoms/bodyText/bodyText';
import ReactModal from 'atoms/modal/reactModal';
import { getComponentUserSettings, getERPStatus, getGridData, getGridMetadata } from 'models/dynamicGrid/selector';
import { fetchComponentUserSettings, handleBulkRowDelete, refreshGridData } from 'models/dynamicGrid/sagaActions';
import { REQUESTS } from 'utils/requests';
import Label from 'atoms/label/label';
import Scrollable from 'atoms/scrollable/scrollable';
import { REQUEST_STATUS } from 'models/loaders/constants';
import GridCsvUploadModal from 'organisms/uploadData/gridCsvUploadModal';
import SelectStores from './selectStores';
import { addPlanogramStores } from '../sagaActions';
import { getActiveUploadPage, getIsStoresEnabled } from '../selectors';
import { setActiveUploadPageName, setIsStoresEnabled } from '../reducer';
import { UPLOAD_PAGES } from '../constants';

const { Dragger } = Upload;

interface Props {
    visible: boolean;
    onCancel: () => void;
    onUpload: ({ file, params }: { files: UploadFile; params: any }) => void;
}

const CreatePlanogram: React.FC<Props> = (props) => {
    const [isGridUpload, setGridUpload] = useState(false);
    const  activeUploadPage = useSelector(getActiveUploadPage);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!isGridUpload) {
            // dispatch(setActivePage({ activePage: PAGES.UPLOAD }));
        }
    }, [dispatch, isGridUpload]);

    return (
        <>

            <Button size="small" name={'Create Planogram'} handleClick={() => { 
                setGridUpload(true)
                dispatch(setActiveUploadPageName({pageName: UPLOAD_PAGES.STORES}))
             }}>
                <Stack align="space-between" gutter="small" position="center">
                    <StackItem isGrow align="center" >
                        Create Planogram
                    </StackItem>
                    <StackItem isStretch>
                        <Divider size="small" isVertical></Divider>
                    </StackItem>
                    <StackItem>
                        <Icon disabled={false} isPadded={false} faAlias="faPlus" size="tiny" />
                    </StackItem>
                </Stack>
            </Button>
            {isGridUpload && activeUploadPage === UPLOAD_PAGES.STORES && (
                <GridCsvUploadModal hasDataChangeType={false} onUploadComplete={()=>{
                    dispatch(setActiveUploadPageName({pageName: UPLOAD_PAGES.ATTRIBUTES}))
                }} title={'Create Planogram - Add Attributes'} uploadTableId={96} parentTableId={92} onClose={() => {
                    setGridUpload(false);
                }} {...props} />
            )}
            {isGridUpload &&  activeUploadPage === UPLOAD_PAGES.ATTRIBUTES && 
                <GridCsvUploadModal hasDataChangeType={false} onUploadComplete={()=>{
                    dispatch(setActiveUploadPageName({pageName: ''}))
                }} title={'Create Planogram - POG List import'} uploadTableId={101} parentTableId={94} onClose={() => {
                    setGridUpload(false);
                }} {...props} />
            }

        </>
    );
};

export default CreatePlanogram;
