// @ts-nocheck
import _ from "lodash";
import * as constants from "./constants";
import * as query from "./query";
import { initialState } from "./reducer";
import {
  TReducer,
  TTableColum,
  TTableColumnGroup,
  TColumnDefinition,
  TGridColumn,
} from "./types";
import { createComponent } from "./gridComponents";
import { createSelector } from "reselect";

const stateData = (state: any): TReducer =>
  state[constants.MODEL_NAME] || initialState;

export const getTableColumnQueue = (state, tableId) => {
  return stateData(state).columnOperations?.[tableId] ?? [];
};

export const getTableActiveColumnIds = (
  state: any,
  tableId: number
): number[] => {
  const data = stateData(state);

  const columns: TTableColum[] = data.tableColumns[tableId.toString()];

  return query.getColumnIdsFromColumns(columns);
};

export const getTableColumns = (state: any, tableId: number): TTableColum[] => {
  const data = stateData(state);
  if (!tableId) {
    return [];
  }
  const columns: TTableColum[] = data.tableColumns[tableId.toString()];
  return columns;
};

export const getTableColumnsByGroups = (
  state: any,
  tableId: number
): TTableColumnGroup[] => {
  const data = stateData(state);

  const columns: TTableColum[] = data.tableColumns[tableId.toString()];

  return query.groupColumns(columns);
};

export const isLoading = (state: any, eventName: string): boolean => {
  const data = stateData(state);

  return data.isLoading[eventName];
};

export const isCustomizeViewModalEnabled = (state: any): boolean => {
  const data = stateData(state);

  return data.isCustomizeViewModalEnabled;
};

export const getTableGridDefinition = (
  state: any,
  tableId: number
): TColumnDefinition[] => {
  const tableColumns = createSelector(stateData, (items) => items.tableColumns);
  const data = tableColumns(state);
  const columns = data ? data[tableId] : [];
  return _.compact(
    _.map(columns, (column) => {
      if (column.selected) {
        return {
          colId: column.AppColumnSurrogateID?.toString(),
          rowIdentifier: column.AppColumnName,
          header: column.AppColumnAliasName || column.AppColumn,
          componentName: column.ComponentName,
          component: createComponent(column.ComponentName, {
            rowIdentifier: column.AppColumnName,
            tableId,
          }),
        };
      }

      return undefined;
    })
  );
};

export const getTableColumnsInfo = (
  state: any,
  tableId: number
): TColumnDefinition[] => {
  const tableColumns = createSelector(stateData, (items) => items.tableColumns);
  const data = tableColumns(state);
  const columns = data ? data[tableId] : [];
  return columns;
};

export const getColumnInfoByIndentifier = (state, tableId, identifier) => {
  const data = stateData(state)["tableColumns"];
  const columns = data ? data[tableId] : [];

  return _.find(columns, (column) => identifier === column.AppColumnName);
};

export const getTableGridColumns = (
  state: any,
  tableId: number
): TGridColumn[] => {
  const data = stateData(state)["tableColumns"];
  const columns = data ? data[tableId] : [];

  return _.map(columns, ({ ...column }) => ({
    colId: column.AppColumnSurrogateID?.toString(),
    rowIdentifier: column.AppColumnName,
    header: column.AppColumnAliasName,
    componentName: column.ComponentName,
  }));
};
