// @ts-nocheck
export const operators = [
	{
		value: 'eq',
		symbol: '=',
		displayName: 'Equals'
	},
	{
		value: 'neq',
		symbol: '!=',
		displayName: 'Not Equals'
	},
	{
		value: 'bulk.in',
		// symbol: '!=',
		displayName: 'Bulk In'
	},
	{
		value: 'in',
		//    symbol: '<=',
		displayName: 'In'
	},
	{
		value: 'not.in',
		displayName: 'Not In'
	},
	{
		value: 'like',
		//    symbol: '<=',
		displayName: 'Like'
	},
	{
		value: 'not.like',
		//    symbol: '<=',
		displayName: 'Not Like'
	},
	{
		value: 'start.with',
		//    symbol: '<=',
		displayName: 'Starts With'
	},
	{
		value: 'begins.with',
		//    symbol: '<=',
		displayName: 'Begins With'
	},
	{
		value: 'ends.with',
		//    symbol: '<=',
		displayName: 'Ends With'
	},
	{
		value: 'empty',
		//    symbol: '<=',
		displayName: 'Empty'
	},
	{
		value: 'not.empty',
		//    symbol: '<=',
		displayName: 'Not Empty'
	},
	{
		value: 'btw',
		//    symbol: 'btw',
		displayName: 'Between'
	},
	{
		value: 'nbtw',
		//    symbol: 'btw',
		displayName: 'Not Between'
	},
	{
		value: 'gt',
		symbol: '>',
		displayName: 'Greater Than'
	},
	{
		value: 'gte',
		symbol: '>=',
		displayName: 'Greater than Equals'
	},
	{
		value: 'lt',
		symbol: '<',
		displayName: 'Lesser Than'
	},
	{
		value: 'lte',
		symbol: '<=',
		displayName: 'Lesser than Equals'
	},

	{
		value: 'is.false',
		//  symbol: '=',
		displayName: 'Is Not Enabled'
	},
	{
		value: 'is.true',
		//  symbol: '=',
		displayName: 'Is Enabled'
	}
];
