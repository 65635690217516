// @ts-nocheck
import * as React from 'react';
import './sheet.scss';
import classNames from 'classnames';

interface Props {
	//   isFillHeight?: boolean;
	children: React.ReactNode;
	isInset?: boolean;
	isPage?: boolean;
	isBanner?: boolean;
	isSidebar?: boolean;
}

function Sheet(props: Props) {
	//   const { isFillHeight } = props;
	const classes: any = classNames([
		'sheet',
		{ 'sheet--inset': props.isInset },
		{ 'sheet--tab': props.isTab },
		{ 'sheet--banner': props.isBanner },
		{ 'sheet--page': props.isPage },
		{ 'sheet--sidebar': props.isSidebar }
	]);

	return <div className={classes}>{props.children}</div>;
}

export default Sheet;
