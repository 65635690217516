// @ts-nocheck
import { initialState } from './reducer';

const masterDataKitsSelector = state => state.masterDataKits || initialState;

export const getTableColumns = state => {
  return masterDataKitsSelector(state)['tableColumns'];
};
export const getTableData = state => {
  return masterDataKitsSelector(state)['tableData'];
};
export const getQueryFilters = (state: any, tableId: string) => {
  return masterDataKitsSelector(state)['queryFilters'][tableId];
};

export const getIsLoading = (state: any, tableId: string) =>
  masterDataKitsSelector(state).isLoading[tableId];
