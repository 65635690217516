// @ts-nocheck
import * as React from 'react';
import { truncateWithEllipses } from 'utils/utilities';
import './bodyText.scss';

interface Props extends React.PropsWithChildren<any> {}

const BodyText: React.FunctionComponent<any> = (props: Props) => {
	return (
		<div className="body--text">
			{props.ellipsis ? truncateWithEllipses(props.children, props.ellipsis) : props.children}{' '}
		</div>
	);
};

export default BodyText;
