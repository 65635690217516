// @ts-nocheck
import "./agGrid.scss";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";

import { AgGridReact } from "ag-grid-react";
import { Empty } from "antd";
import Label from "atoms/label/label";
import _, { sortBy } from "lodash";
import {
  updateGridMeta,
  updateRowData,
  updateSelectedRows,
} from "models/dynamicGrid/sagaActions";
import {
  getGridData,
  getGridFilters,
  getGridFiltersPivotMode,
  getGridMetadata,
  getTableSelection,
  getTotalCount,
  isLoading,
} from "models/dynamicGrid/selector";
import { getTableColumnsInfo } from "models/tableColumns/selector";
import Stack from "molecules/stack/stack";
import StackItem from "molecules/stackItem/stackItem";
import { removeQuery } from "organisms/searchFilter/sagaActions";
import { node } from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { getDispatch, getState } from "reducers";
import * as gridSelectors from "../../../models/dynamicGrid/selector";
import {
  getChildTableColumnDefinitions,
  getColumnDefinitions,
} from "./columnDefinitions";
import { PAGE_SIZE } from "./constants";
import { selectAllHandler } from "./handlers";
import { onProcessColumnQueue, useAgGrid } from "./hooks/useAgGrid";
import {
  createServer,
  createServerSideDatasource,
  getActionColumn,
  getSelectionColumn,
  getToolPanelConfig,
} from "./queries";
import { updateMetadata } from "models/dynamicGrid/reducer";
import { REQUESTS } from "utils/requests";
import FullScreen from "atoms/fullScreen/fullScreen";
import GridCsvUpload from "organisms/uploadData/gridCsvUpload";
import { fetchTableColumnsAg } from "models/tableColumns/sagaActions";
import { addColumnOperations } from "models/tableColumns/reducer";
import { isLoadingEnabled } from "models/loaders/selectors";
import { getWebAppSettings } from "models/user/selector";
import { getGridFilterName } from "models/dynamicGrid/query";
import { exportGridData } from "models/dynamicGrid/sagaActions";
import Icon from "atoms/icons/icon";
import { Events } from "molecules/ReactGrid/agGrid/utils/events";
import { GRID_EVENTS } from "./constants";
import { FilterTags } from "./filterTags/filterTags";
import { formatThousands } from "utils/utilities";

const debouncer = {};

const updateColumnOperations = ({ params, tableId, isReset }) => {
	const dispatch = getDispatch();
	dispatch(addColumnOperations({
		tableId, columnOperations: [params]
	}))

	clearTimeout(debouncer?.[tableId]);
	debouncer[tableId] = setTimeout(() => {
		onProcessColumnQueue({
			params, tableId, isReset
		})
	}, 500)
}

const columnTypes = {
  editableColumn: {
    editable: (params) => {
      return params.colDef.editable;
    },
    cellStyle: (params) => {
      if (params.colDef.editable) {
        return { cursor: "text", padding: "0.2rem" };
      }
    },
  },
};

const AgGrid = (props) => {
  const {
    tableId,
    selectRowKey,
    groupBy,
    isStorageEnabled,
    config,
    onDomRef,
    showOpenedGroup,
    groupHideOpenParents,
    childCustomActions,
    onDataRender = () => { },
    childCellRendererParams = null,
    refreshChildGridData = () => { },
    rowIndex
  } = props

  // console.log(":))", props)

  const gridRef = useRef();
  const childGridRef = useRef();
  const selectedRowKeys: any = useSelector((state) =>
    getTableSelection(state, tableId)
  );
  const gridMeta = useSelector((state) => getGridMetadata(state, tableId));

  const isGridFilterPivotMode = useSelector((state) =>
    getGridFiltersPivotMode(state, tableId)
  );

  const {
    onColumnMoved,
    onColumnPinned,
    handleColumnsChange,
    onColumnResized,
  } = useAgGrid({
    tableId,
    gridRef,
    childGridRef,
    childTableId: gridMeta?.ChildTableID,
  });
  const isPivotEnabled = useSelector((state) =>
    gridSelectors.isAgPivotEnabled(state, tableId)
  );
  const isAgGroupingEnabled = useSelector((state) =>
    gridSelectors.isAgGroupingEnabled(state, tableId)
  );
  const isViewRLA = useSelector(
    (state) => getWebAppSettings(state)?.IsAclRowLevelAccessEnabled
  );
  const {
    gridActions,
    customActions,
    childGridActions,
    childGridCustomActions,
    enableSelection = true,
    rowGroupPanelShow = isAgGroupingEnabled,
    rowPivotPanelShow = isPivotEnabled,
    defaultRowGroupColumn,
    defaultRowGroupColumnDisplayOverride,
    isViewImplementationOverride,
    isParentSelectionEnabled,
  } = config;
  const dispatch = getDispatch();
  const [refreshTimestamp, updateTimestamp] = useState(null);
  const totalCount = useSelector((state) => getTotalCount(state, tableId));
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const tableColumns = useSelector((state) =>
    getTableColumnsInfo(state, tableId)
  );

  let childTableColumns = useSelector((state) =>
    gridMeta?.ChildTableID
      ? getTableColumnsInfo(state, gridMeta?.ChildTableID)
      : []
  );
  const [isGroupingEnabled, handleUpdateGrouping] = useState(false);
  const onChildGridReady = useCallback(
    (params) => {
      dispatch(
        fetchTableColumnsAg(gridMeta?.ChildTableID, {
          isForced: true,
        })
      );
      const server = createServer(gridMeta?.ChildTableID);

      var datasource = createServerSideDatasource(server, childGridRef, {
        isStorageEnabled,
        isViewImplementationOverride: isViewImplementationOverride || isViewRLA,
        tableId: gridMeta?.ChildTableID,
      });
      params.api.setServerSideDatasource(datasource);
    },
    [gridMeta?.ChildTableID]
  );

  useEffect(() => {
    if (
      gridMeta?.refreshTimestamp !== refreshTimestamp &&
      gridMeta?.refreshTimestamp &&
      gridRef
    ) {
      gridRef?.current?.api?.refreshServerSide({ route: [], purge: true });
    }
    updateTimestamp(Date.now());
  }, [gridMeta?.refreshTimestamp, gridRef]);

  useEffect(() => {
    if (isGridFilterPivotMode === false) {
      gridRef?.current?.columnApi?.setSecondaryColumns([]);
      gridRef?.current?.api?.refreshHeader();
    }
  }, [isGridFilterPivotMode]);

  let [columnDefs, updateColumndefs] = React.useState([]);
  let [childColumnDefs, updateChildColumndefs] = React.useState([]);

  useEffect(() => {
    const colDefs =
      gridRef && gridRef?.current?.columnApi?.columnModel?.columnDefs;
    let columns = getColumnDefinitions(
      tableColumns,
      tableId,
      colDefs,
      refreshTimestamp,
      {
        isPivotViewEnabled: rowPivotPanelShow,
        isPivotMode: gridRef?.current?.columnApi?.columnModel?.pivotMode,
        defaultRowGroupColumn,
        defaultRowGroupColumnDisplayOverride,
        isUploadGrid: config.isUploadGrid,
      }
    );

    if (selectRowKey && enableSelection) {
      columns?.unshift(
        getSelectionColumn(
          tableId,
          gridRef,
          isParentSelectionEnabled,
          selectRowKey
        )
      );
    }
    if (gridActions || customActions) {
      columns?.push(
        getActionColumn({
          tableId,
          gridRef,
          customActions,
          isGroupingEnabled,
          gridConfig: config,
        })
      );
    }
    updateColumndefs(columns);
  }, [
    gridRef?.current?.columnApi?.columnModel?.pivotMode,
    selectRowKey,
    tableId,
    updateColumndefs,
    tableColumns,
    gridMeta?.refreshTimestamp,
    isGroupingEnabled,
    refreshTimestamp,
    gridActions,
    enableSelection,
    isParentSelectionEnabled,
  ]);

  useEffect(() => {
    if (!gridMeta?.ChildTableID) {
      return;
    }
    let columns = getChildTableColumnDefinitions(
      childTableColumns,
      gridMeta?.ChildTableID,
      childCustomActions,
      childCellRendererParams
    );

    if (childGridActions || childGridCustomActions) {
      columns?.push(
        getActionColumn({
          tableId: gridMeta?.ChildTableID,
          childGridRef,
          customActions: childGridCustomActions,
        })
      );
    }
    updateChildColumndefs(columns);
  }, [
    childGridRef?.current?.columnApi?.columnModel?.pivotMode,
    selectRowKey,
    gridMeta?.refreshTimestamp,
    // updateChildColumndefs,
    childTableColumns,
    enableSelection,
    gridMeta?.ChildTableID,
    isParentSelectionEnabled,
  ]);

  const onGridReady = useCallback(
    (params) => {
      if (!gridRef) {
        return;
      }
      let server = createServer(tableId);
      var datasource = createServerSideDatasource(server, gridRef, {
        isStorageEnabled,
        isViewImplementationOverride: isViewImplementationOverride || isViewRLA,
        tableId,
      });
      if (onDomRef) {
        onDomRef(gridRef);
      }
      params.api.setServerSideDatasource(datasource);
    },
    [gridRef, tableId]
  );
  const detailCellRendererParams = useMemo(() => {
    if (!gridMeta?.ChildTableID) {
      return;
    }
    return {
      detailGridOptions: {
        ref: childGridRef,
        onColumnMoved: (params) => {
          updateColumnOperations({
            params,
            tableId: gridMeta?.ChildTableID,
            isReset: true,
          });
        },
        onColumnPinned: (params) => {
          updateColumnOperations({
            params,
            tableId: gridMeta?.ChildTableID,
            isReset: true,
          });
        },
        onColumnResized: (params) => {
          updateColumnOperations({
            params,
            tableId: gridMeta?.ChildTableID,
            isReset: true,
          });
        },
        onColumnVisible: (params) => {
          updateColumnOperations({
            params,
            tableId: gridMeta?.ChildTableID,
            isReset: true,
          });
        },
        onGridReady: onChildGridReady,
        columnDefs: childColumnDefs,
        paginateChildRows: true,
        rowModelType: "serverSide",
        pagination: true,
        rowHeight: 30,
        wrapText: true,
        headerHeight: 30,
        paginationPageSize: PAGE_SIZE,
        serverSideInfiniteScroll: true,
        serverSideInitialRowCount: PAGE_SIZE,
        maxConcurrentDatasourceRequests: 3,
        cacheBlockSize: PAGE_SIZE,
        // autoHeight: true,
        defaultColDef: {
          flex: 1,
          //child table editable
          editable: (params) => Boolean(params.colDef?.metaData?.ColumnType === "text") &&
            Boolean(params.colDef?.metaData?.IsEditableColumn),
        },
      },
      getDetailRowData: (params) => {
        dispatch(
          updateMetadata({
            tableId: gridMeta?.ChildTableID,
            metaData: {
              selectRowKey: gridMeta?.childTableSelectRowKey,
              dataProviderOptions: {
                ...REQUESTS.POST.GET_GRID_DETAILS_V2,
                type: "POST",
                service: gridMeta?.dataProviderOptions?.service,
                isSubgrid: true,
                parentTable: tableId,
                version: 2,
                url: gridMeta?.dataProviderOptions?.url,
                pathParams: {
                  tableId: gridMeta?.ChildTableID,
                },

                body: {
                  fieldGroups: {
                    ..._.reduce(
                      gridMeta?.ChildTableFieldGroups,
                      (result, key) => {
                        result[key] = params.data[key];
                        return result;
                      },
                      {}
                    ),
                  },
                },
              },
            },
            isAppend: true,
          })
        );
        // supply details records to detail cell renderer (i.e. detail grid)
        // params.successCallback(params.data.callRecords);
      },
    };
  }, [childTableColumns, childColumnDefs, onGridReady, tableId, gridMeta]);


  const getContextMenuItems = useCallback(
    (params) => {
      var result = [
        "copy",
        "copyWithHeaders",
        "copyWithGroupHeaders",
        "paste",
        ...(gridMeta?.IsExportEnabled
          ? [
            "separator",
            {
              name: "Export",
              action: () => {
                Events.publish(GRID_EVENTS.EXPORT, {
                  tableId,
                  isAgGrid: true,
                });
              },
            },
          ]
          : []),
        "separator",
        {
          name: "Expand All",
          action: () => {
            params.api.expandAll();
          },
        },
        {
          name: "Collapse All",
          action: () => {
            params.api.collapseAll();
          },
        },
      ];
      return result;
    },
    [tableId, gridMeta]
  );

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 250,
      sortable: true,
      resizable: true,
      filter: true,
      // cellRenderer: 'agGroupCellRenderer',

      // filterValueGetter: (params) => {
      // 	console.log(params)
      // 	if (params.node) {
      // 		var colGettingGrouped = params.colDef.showRowGroup + '';

      // 	  return params.api.getValue(colGettingGrouped, params.node);
      // 	}
      //   },
    };
  }, []);

  const handleUpdateSelection = (params) => {
    let nodes = isParentSelectionEnabled
      ? _.filter(params.api.getSelectedNodes(), (n) => n.key)
      : params.api.getSelectedNodes();
    const gridData = getGridData(getState(), tableId);
    const totalCount = getTotalCount(getState(), tableId);
    if (
      selectedRowKeys?.isSelectAll &&
      (nodes?.length === gridData?.length || nodes?.length === totalCount)
    ) {
      return;
    } else if (nodes?.length === totalCount) {
      dispatch(
        updateSelectedRows({
          tableId,
          selectedRowKeys: _.map(nodes, (n) => n?.data[selectRowKey]),
          rowGroup: _.map(
            _.filter(nodes, (n) => n?.leafGroup),
            (n) => n.data
          ),
          // isSelectAll: false
          isSelectAll: true,
        })
      );
      return;
    }

    dispatch(
      updateSelectedRows({
        tableId,
        selectedRowKeys: _.map(nodes, (n) => n?.data[selectRowKey]),
        rowGroup: _.map(
          _.filter(nodes, (n) => n?.leafGroup),
          (n) => n.data
        ),
        // isSelectAll: false
        isSelectAll: false,
      })
    );
  };
  const onModelUpdated = useCallback(
    (params) => {
      const isPivotMode = gridRef?.current?.columnApi?.columnModel?.pivotMode;
      const { api: gridApi, columnApi } = gridRef?.current ?? {};

      handleUpdateGrouping(
        _.some(gridRef?.current?.api.getColumnDefs(), (f) => f.rowGroup)
      );
      if (gridRef?.current?.api.getRenderedNodes().length) {
        gridRef?.current?.api.hideOverlay();
      }
    },
    [selectedRowKeys, selectRowKey, gridRef]
  );

  if (!columnDefs.length || !gridMeta) {
    return null;
  }

  const onUpdateCell = (e) => {
    const metaData = getGridMetadata(getState(), tableId);
    const row = e.data;
    const rowIdentifier = e.colDef.field;
    const parsedValue = e.data[e.colDef.field];
    const type = e.colDef.metaData.columnType;
    dispatch(
      updateRowData({
        tableId: Number(tableId),
        row,
        isUploadGrid: config.isUploadGrid,
        rowIdentifier,
        updatedValue: type === "number" ? Number(parsedValue) : parsedValue,
        isInlineEdit: Number(tableId) === 56 ? false : true,
        metaData
      })
    );
  };
  const getMainMenuItems = (params) => {
    params.defaultItems.pop(); // removes "resetColumns"
    params.defaultItems.pop(); // removes "separator"
    // params.defaultItems.pop(); // removes "separator"

    return params.defaultItems;
  };

  const handleSelectAllClick = () => {
    selectAllHandler({
      tableId,
      gridRef,
      isSelectAll: true,
      selectRowKey,
    });
  };

  const clearSelectAll = () => {
    gridRef?.current?.api.forEachNode(function (node) {
      node.setSelected(false);
    });
    selectAllHandler({
      tableId,
      gridRef,
      selectRowKey,
      isSelectAll: false,
    });
  };

  const defaultColDef = {
    suppressMovable: true,
    enableCellChangeFlash: true
  };

  const selectedItemsCount = _.reduce(
    gridRef?.current?.api?.getSelectedNodes(),
    (count, n) => {
      return count + 1;
    },
    0
  );


  return (
    <div style={containerStyle} className="app-grid">
      <Stack isVertical gutter="small">
        {selectRowKey && (
          <StackItem>
            {selectedRowKeys?.isSelectAll && (
              <div className={"select-info"}>
                {/* {items.length ? ( */}
                <>
                  All {`${formatThousands(totalCount)}`} records are selected.{" "}
                  <span
                    className={"select-info--action"}
                    onClick={clearSelectAll}
                  >
                    Clear selection.
                  </span>
                </>
                {/* ) : ( */}
                {/* <> No records are selected </> */}
                {/* )} */}
              </div>
            )}
            {Boolean(!selectedRowKeys?.isSelectAll && selectedItemsCount) ? (
              <div className={"select-info"}>
                {`${formatThousands(selectedItemsCount)}/${formatThousands(
                  totalCount
                )} records on this page are selected`}
                <span
                  className={"select-info--action"}
                  onClick={handleSelectAllClick}
                >
                  {`Select all ${formatThousands(totalCount)} records.`}
                </span>
              </div>
            ) : (
              ""
            )}
            {/* {selectedRowKeys?.selectedRowKeys?.length && !selectedRowKeys?.isSelectAll ? (
							<div className={'select-info'}>
								{`${selectedItemsCount}/${totalCount} records on this page are selected`}
								<span className={'select-info--action'} onClick={handleSelectAllClick}>
									{`Select all ${totalCount} records.`}
								</span>
							</div>
						) : (
							<></>
						)} */}
          </StackItem>
        )}
        <StackItem>
          <FilterTags gridRef={gridRef} tableId={tableId} />
        </StackItem>

        <StackItem isGrow isStretch>
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
              ref={gridRef}
              key={tableId}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowModelType={"serverSide"}
              serverSideInfiniteScroll={true}
              rowHeight={30}
              headerHeight={30}
              serverSideInitialRowCount={PAGE_SIZE}
              cacheBlockSize={PAGE_SIZE}
              maxBlocksInCache={1}
              sideBar={getToolPanelConfig({
                suppressPivotMode: !rowPivotPanelShow,
                rowGroupPanelShow,
                rowPivotPanelShow,
              })}
              groupDisplayType={"multipleColumns"}
              suppressGroupRowsSticky={true}
              // showOpenedGroup={true}
              groupHideOpenParents={false}
              onModelUpdated={onModelUpdated}
              pivotPanelShow={"never"}
              detailCellRendererParams={detailCellRendererParams}
              masterDetail={Boolean(gridMeta?.ChildTableID)}
              rowSelection={enableSelection && selectRowKey && "multiple"}
              columnTypes={columnTypes}
              purgeClosedRowNodes={true}
              groupSelectsChildren={false}
              rowGroupPanelShow={rowGroupPanelShow && "always"}
              enableTextSelection={true}
              maxConcurrentDatasourceRequests={1}
              autoGroupColumnDef={autoGroupColumnDef}
              suppressRowClickSelection={true}
              defaultExportParams={{
                fileName: "Skulicity",
                sheetName: "Skulicity Export",
              }}
              pivotPanelShow={rowPivotPanelShow && "always"}
              onSelectionChanged={_.debounce(handleUpdateSelection, 100)}
              suppressAggFuncInHeader={!rowPivotPanelShow}
              suppressExpandablePivotGroups={true}
              groupRowsSticky={true}
              pivotMode={isGridFilterPivotMode}
              onColumnMoved={(params) => {
                updateColumnOperations({
                  params,
                  tableId,
                });
              }}
              onColumnPinned={(params) => {
                updateColumnOperations({
                  params,
                  tableId,
                });
              }}
              onColumnResized={(params) => {
                updateColumnOperations({
                  params,
                  tableId,
                });
              }}
              onColumnVisible={(params) => {
                updateColumnOperations({
                  params,
                  tableId,
                });
              }}
              getContextMenuItems={getContextMenuItems}
              rowBuffer={100}
              onFirstDataRendered={onDataRender}
              onGridReady={onGridReady}
              suppressModelUpdateAfterUpdateTransaction={true}
              noRowsOverlayComponent={Empty}
              onCellValueChanged={onUpdateCell}
              getMainMenuItems={getMainMenuItems}
            />
          </div>
        </StackItem>

        {/* {isLoadingColumnSync && */}
        {/* } */}
      </Stack>
    </div>
  );
};

export default AgGrid;
