// @ts-nocheck
import * as React from 'react';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import Heading from 'atoms/heading/heading';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import Card from 'atoms/card/card';
import moment from 'moment';
import MasterDataListItem from 'molecules/masterDataList/masterDataListItem';
import { OPTIONS, OPTION_IDENTIFIERS } from './constants';
import { useNavigate } from 'react-router-dom';
import Divider from 'atoms/divider/divider';
import Scrollable from 'atoms/scrollable/scrollable';
import { useSelector } from 'react-redux';
import { getTileOperations } from './selector';

export type OptionTypes = {
	title: string;
	description: string;
	buttonText: string;
	itemIdentifier: string;
};
const today = moment().format('MM/DD/YYYY');

const MasterData: React.FunctionComponent = () => {
	const navigate = useNavigate();
	const tileOptions = useSelector(getTileOperations);
	const handleMasterDataButtonClick = (itemIdentifier: string) => {
		switch (itemIdentifier) {
			case OPTION_IDENTIFIERS.SCHEDULES:
				return navigate('/masterdata/storeschedules');
			case OPTION_IDENTIFIERS.SHIPMENTS:
				return navigate('/masterdata/shipments');
			case OPTION_IDENTIFIERS.SHIPPING_METHOD:
				return navigate('/masterdata/shipmethods');
			case OPTION_IDENTIFIERS.STORE_ORDERS_LIST:
				return navigate('/masterdata/store/order/list');
			case OPTION_IDENTIFIERS.STORES:
				return navigate('/masterdata/stores');
			case OPTION_IDENTIFIERS.VALID_SKU_COMBINATIONS:
				return navigate('/masterdata/skuitemmapping');
			case OPTION_IDENTIFIERS.KITS:
				return navigate('/masterdata/kits');
			case OPTION_IDENTIFIERS.INVENTORY_TRANSACTIONS:
				return navigate('/masterdata/transactions');
			case OPTION_IDENTIFIERS.CUSTOMERS:
				return navigate('/masterdata/customers');
			case OPTION_IDENTIFIERS.SKUS:
				return navigate('/masterdata/skus');
			case OPTION_IDENTIFIERS.VALID_STORE_COMBINATIONS:
				return navigate('/masterdata/storesku');
			case OPTION_IDENTIFIERS.PRIMARY_STORE_SKU:
				return navigate('/masterdata/primaryStoreSku');
			// case OPTION_IDENTIFIERS.SKU_SHIPPING_METHOD_CONFIGURATION:
			// 	return navigate('/masterdata/shipmethodskuconfig');
			case OPTION_IDENTIFIERS.SKU_EXCLUSIONS:
				return navigate('/masterdata/excludestoresku');
			case OPTION_IDENTIFIERS.PRICING:
				return navigate('/masterdata/storeskucost');
			case OPTION_IDENTIFIERS.PARAMETERS:
				return navigate('/masterdata/parameterview');
			case OPTION_IDENTIFIERS.ITEMS:
				return navigate('/masterdata/items');
			case OPTION_IDENTIFIERS.ITEM_SHIPPING_METHOD_CONFIGURATION:
				return navigate('/masterdata/shipmethoditemconfig');
			case OPTION_IDENTIFIERS.ITEM_EXCLUSIONS:
				return navigate('/masterdata/excludestoreitem');
			case OPTION_IDENTIFIERS.AD_TYPES:
				return navigate('/masterdata/adTypes');
			case OPTION_IDENTIFIERS.GROUPS:
				return navigate('/masterdata/groups');
			case OPTION_IDENTIFIERS.WAREHOUSES:
				return navigate('/masterdata/warehouses');
			default:
				return;
		}
	};

	const getDisabledStatus = (itemIdentifier: string) => {
		switch (itemIdentifier) {
			case OPTION_IDENTIFIERS.SCHEDULES:
			case OPTION_IDENTIFIERS.SHIPMENTS:
			case OPTION_IDENTIFIERS.SHIPPING_METHOD:
			case OPTION_IDENTIFIERS.STORE_ORDERS_LIST:
			case OPTION_IDENTIFIERS.STORES:
			case OPTION_IDENTIFIERS.VALID_SKU_COMBINATIONS:
			case OPTION_IDENTIFIERS.KITS:
			case OPTION_IDENTIFIERS.INVENTORY_TRANSACTIONS:
			case OPTION_IDENTIFIERS.CUSTOMERS:
			case OPTION_IDENTIFIERS.SKUS:
			case OPTION_IDENTIFIERS.VALID_STORE_COMBINATIONS:
			// case OPTION_IDENTIFIERS.SKU_SHIPPING_METHOD_CONFIGURATION:
			case OPTION_IDENTIFIERS.SKU_EXCLUSIONS:
			case OPTION_IDENTIFIERS.PRICING:
			case OPTION_IDENTIFIERS.PARAMETERS:
			case OPTION_IDENTIFIERS.ITEMS:
			case OPTION_IDENTIFIERS.ITEM_SHIPPING_METHOD_CONFIGURATION:
			case OPTION_IDENTIFIERS.ITEM_EXCLUSIONS:
			case OPTION_IDENTIFIERS.AD_TYPES:
			case OPTION_IDENTIFIERS.GROUPS:
				return false;
			default:
				return true;
		}
	};

	

	return (
		<Sheet>
			<SheetBody>
				<Stack isVertical gutter="small">
					<StackItem>
						<Stack isVertical gutter="small">
							{/* <StackItem>
								<Heading type="primary">Master Data</Heading>
							</StackItem> */}
							<StackItem>
								<Heading type="tertiary">UPDATED {today}</Heading>
							</StackItem>
						</Stack>
					</StackItem>
					<StackItem isStretch>
						<Divider></Divider>
					</StackItem>
					<Scrollable>
						<Stack isWrap gutter="medium">
							{/* <InfiniteScroll
            initialLoad={false}
            style={{ width: '100%', height: '700px' }}
            pageStart={0}
            loadMore={handleLoadMore}
            hasMore={parameterGroups && parameterGroups.hasMore}
            useWindow={false}> */}
							{tileOptions?.map(option => {
								return (
									<MasterDataListItem
										title={option.title}
										description={option.description}
										buttonText={option.buttonText}
										handleButtonClick={handleMasterDataButtonClick}
										itemIdentifier={option.itemIdentifier}
										disabled={getDisabledStatus(option.itemIdentifier)}
									/>
								);
							})}

							{/* </InfiniteScroll> */}
						</Stack>
					</Scrollable>
				</Stack>
			</SheetBody>
		</Sheet>
	);
};
export default MasterData;
