// @ts-nocheck
import { put, takeEvery } from 'redux-saga/effects';
import { postAPIDataV3, putAPIDataV3 } from 'utils/api';
import { triggerActivateUser, triggerAddUserRole, triggerCreateUser, triggerDeactivateUser, triggerRemoveUserRole } from './sagaActions';
import { toggleLoader } from 'models/loaders/sagaActions';
import { LOADER_COMPONENTS } from './constants';
import { getDispatch } from 'reducers';
import { triggerFetchAutoComplete } from 'models/autoComplete/sagaActions';
import { triggerNotification } from 'organisms/notifications/sagaActions';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import * as constants from './constants';

export const handleCreateUser = async ({ payload: { userName, userEmail, componentId, roles } }) => {

	const dispatch = getDispatch();
	try {
		if (!userName) {
			return
		}
		dispatch(toggleLoader({
			componentId: LOADER_COMPONENTS.CREATE_USER,
			isLoading: true
		}));
		await postAPIDataV3({
			service: 'acl', url: 'users', body: {
				userName,
				userEmail,
				Roles: roles
			}
		});
		dispatch(triggerFetchAutoComplete({
			componentId: 'acl-all-users',
		}))
		dispatch(toggleLoader({
			componentId: LOADER_COMPONENTS.CREATE_USER,
			isLoading: false
		}));
		setTimeout(async () => {
			await dispatch(
				dynamicGridSagaActions.refreshGridData({
					tableId: constants.TABLE_ID,
				})
			);
		}, 2000);
	}
	catch (e) {
		dispatch(triggerNotification({
			message: e?.data?.message
		}))
	}

};


export const handleAddRole = async ({ payload: { userId, roles, isForced } }) => {
	try {

		const dispatch = getDispatch();

		await putAPIDataV3({
			service: 'acl', url: `users/${userId}`, body: {
				Roles: roles,
				isForced
			}
		});
		dispatch(triggerFetchAutoComplete({
			componentId: 'acl-all-users',
		}))
		setTimeout(async () => {
			await dispatch(
				dynamicGridSagaActions.refreshGridData({
					tableId: constants.TABLE_ID,
				})
			);
		}, 2000);

	}
	catch (e) {
		dispatch(triggerNotification({
			message: e?.data?.message
		}))
	}

};

export const handleRemoveRole = async ({ payload: { userId, roles } }) => {
	try {

		const dispatch = getDispatch();

		const { data } = await putAPIDataV3({
			service: 'acl', url: `users/roles/${userId}`, body: {
				Roles: roles
			}
		});
		dispatch(triggerFetchAutoComplete({
			componentId: 'acl-all-users',
		}))
	}
	catch (e) {

	}

};


export const handleDeactivateUser = async ({ payload: { userId } }) => {
	try {

		const dispatch = getDispatch();

		const { data } = await putAPIDataV3({
			service: 'acl', url: `users/state/${userId}`, body: {
				IsActive: false
			}
		});
		dispatch(triggerFetchAutoComplete({
			componentId: 'acl-all-users',
		}))
		// dispatch(triggerFetchAutoComplete({
		// 	componentId: 'acl-user-roles',
		// }))
		// dispatch(triggerFetchAutoComplete({
		// 	componentId: 'acl-all-roles',
		// }))

	}
	catch (e) {
		console.log(e)
	}

};

export const handleActivateUser = async ({ payload: { userId } }) => {
	try {

		const dispatch = getDispatch();

		const { data } = await putAPIDataV3({
			service: 'acl', url: `users/state/${userId}`, body: {
				IsActive: true
			}
		});
		dispatch(triggerFetchAutoComplete({
			componentId: 'acl-all-users',
		}))
		// dispatch(triggerFetchAutoComplete({
		// 	componentId: 'acl-user-roles',
		// }))
		// dispatch(triggerFetchAutoComplete({
		// 	componentId: 'acl-all-roles',
		// }))

	}
	catch (e) {
		console.log(e)
	}

};




export default function* rootSaga() {
	return [
		yield (takeEvery(triggerCreateUser, handleCreateUser)),
		yield (takeEvery(triggerAddUserRole, handleAddRole)),
		yield (takeEvery(triggerRemoveUserRole, handleRemoveRole)),
		yield (takeEvery(triggerDeactivateUser, handleDeactivateUser)),
		yield (takeEvery(triggerActivateUser, handleActivateUser))


	];
}