// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const reset = createAction(
  'resetModel/reset',
  function prepare(tableId) {
    return {
      payload: {
        tableId,
      },
    };
  }
);
