// @ts-nocheck
import * as React from 'react';
import './tag.scss';
import classNames from 'classnames';
import Icon from 'atoms/icons/icon';
import { Tooltip } from 'antd';

type Item = {
	value: string;
};

interface Props extends React.PropsWithChildren<any> {
	type: 'primary' | 'secondary' | 'tertiary';
	item?: Item;
	onRemove: () => void;
	onClose: Function;
	onClick: Function;
}

const Tag: React.FunctionComponent<any> = (props: Props) => {
	const { type, onClose, item, onClick, tooltipText } = props;
	const classes: any = classNames(['tag', { [`tag--${type}`]: true }]);

	const handleOnClose = React.useCallback(
		e => {
			if (onClose) {
				onClose(item);
			}
			e.stopPropagation();
		},
		[item]
	);

	return (
		!item?.isLocked && (
			<Tooltip overlayClassName="tag-tooltip" placement="rightBottom" arrowPointAtCenter title={tooltipText || props.children}>
				<div className={classes} onClick={onClick}>
					<div>{props.children}</div>

					<div style={{ marginLeft: '10px' }}>
						<Icon faAlias="faTimes" onClick={handleOnClose} isActive type="tertiary" size="tiny" />
					</div>
				</div>
			</Tooltip>
		)
	);
};

Tag.defaultProps = {
	type: 'primary',
	onClick: () => {}
};

export default Tag;
