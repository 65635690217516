// @ts-nocheck
import * as React from 'react';
import './loader.scss';

import classNames from 'classnames';
import { Spin } from 'antd';

type TProps = {
	isInset?: boolean;
};

export default function Loader({ isInset, size }: TProps) {
	const classes: any = classNames(['loader', { 'loader--inset': isInset, [`loader--${size}`]: true }]);

	return (
		<div className={classes}>
			<div className="lds-grid">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
}

export function LoaderWrap({ isInset, children, isLoading }) {
	return (
		<Spin spinning={isLoading} wrapperClassName={'loader--wrap'} indicator={<Loader isInset={isInset} />}>
			{children}
		</Spin>
	);
}
