/* eslint-disable import/no-anonymous-default-export */
// @ts-nocheck

import DemandGroupHub from './demandGroupHub/demandGroupHub';
import DemandPlanning from './demandPlanning';
import PlanogramAttributesSagas from './attributesListing/sagas';
import PlanogramAttributesReducer from './attributesListing/reducer';

import PlanogramListingSagas from './planogramListing/sagas';
import PlanogramListingReducer from './planogramListing/reducer';

import PlanogramDetailsSagas from './planogramDetails/sagas';
import PlanogramDetailsReducer from './planogramDetails/reducer';




import PlanogramDetails from './planogramDetails/planogramDetails';

import { isDemandChangeReadWrite, isDemandGroupHubReadWrite, isDemandReportingReadWrite, isPlanogramRestrictionsReadWriteEnabled } from 'models/user/selector';
import PlanogramAttributesListing from './attributesListing/index';
import PlanogramListing from './planogramListing/planogramListing';
export const Planogram = [
    {
        path: '/planogram/attributes',
        key: 'key',
        componentId: 'planogram-attributes',
        component: PlanogramAttributesListing,
        routes: [],
        importReducer: () => {
            return [PlanogramAttributesReducer];
        },
        importSagas: () => {
            return [PlanogramAttributesSagas];
        },
        headerName: 'Planogram Attributes',
        isEnabled: state => isPlanogramRestrictionsReadWriteEnabled(state),
        title: 'Skulicity | Planogram'
    },
    {
        path: '/planogram/list',
        key: 'key',
        componentId: 'planogram-list',
        component: PlanogramListing,
        routes: [],
        importReducer: () => {
            return [PlanogramListingReducer ];
        },
        isEnabled: state => isPlanogramRestrictionsReadWriteEnabled(state),
        importSagas: () => {
            return [PlanogramListingSagas];
        },
        headerName: 'Planogram List',
        name: 'Planogram',
        title: 'Skulicity | Planogram'
    },
    {
        path: '/planogram',
        key: 'key',
        componentId: 'planogram-list',
        component: PlanogramListing,
        routes: [],
        importReducer: () => {
            return [PlanogramListingReducer];
        },
        isEnabled: state => isPlanogramRestrictionsReadWriteEnabled(state),
        importSagas: () => {
            return [PlanogramListingSagas];
        },
        headerName: 'Planogram List',
        name: 'Planogram',
        title: 'Skulicity | Planogram'
    },
    {
        path: '/planogram/details/:planogramId',
        key: 'key',
        componentId: 'planogram-details',
        component: PlanogramDetails,
        routes: [],
        importReducer: () => {
            return [PlanogramDetailsReducer];
        },
        // isEnabled: state => isDemandGroupHubReadWrite(state),
        importSagas: () => {
            return [PlanogramDetailsSagas];
        },
        headerName: 'Planogram Details',
        name: 'Details',
        title: 'Skulicity | Planogram'
    },
];
