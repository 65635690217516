// @ts-nocheck
import Button from 'atoms/button/button';
import Card from 'atoms/card/card';
import { WaveSvg } from 'atoms/icons/navSvg';
import Label from 'atoms/label/label';
import Loader from 'atoms/loader';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import moment from 'moment';
import * as generateOrderActions from './generateOrderModal/sagaActions';
import * as generateOrderSelectors from './generateOrderModal/selectors';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { formatDate, formatMoney, formatPercentage, formatThousands } from 'utils/utilities';
import * as constants from './constants';
import * as NewOrerSagaActions from './newOrders/sagaActions';
import * as sagaActions from './sagaActions';
import * as selectors from './selectors';
import * as services from './services';

const today = moment().format('MM/DD/YYYY');

const Home: React.FunctionComponent = ({ title }: any) => {
	const dispatch = useDispatch();
	const openNewOrderModal = useSelector(state => generateOrderSelectors.isOpen(state));

	const inventoryTableData: any[] = useSelector(state => selectors.getInventoryTableData(state));

	const statusID: number = useSelector(state => selectors.getStatusID(state));

	let statusIntervalID = useSelector(state => selectors.getStatusIntervalID(state));

	const [loading, setLoading] = React.useState(true);
	const [status, updateStatus] = React.useState<string>('orderready');
	const navigate = useNavigate();

	React.useEffect(() => {
		dispatch(sagaActions.loadPage());
		dispatch(sagaActions.getInventoryData());
	}, [dispatch]);

	// const checkStatus = () => {
	// 	services.checkStatus(statusID).then(status => {
	// 		updateStatus(status);
	// 	});
	// };

	// React.useEffect(() => {
	// 	if (statusID) {
	// 		checkStatus();
	// 	}
	// }, [statusID, dispatch]);

	React.useEffect(() => {
		if (inventoryTableData) {
			console.log(inventoryTableData)
			setLoading(false);
			return;
		}
		// setLoading(false);
	}, [inventoryTableData]);

	const handleGenerateOrderClick = React.useCallback(() => {
		switch (status) {
			case 'inprogress':
				// navigate('/orders');
				break;
			case 'orderready':
			case 'dataready':
			case 'complete':
				dispatch(generateOrderActions.setModalView(true));
				break;
			default:
				break;
		}
	}, []);

	const translateAPIData = rawData => {
		const formattedData = [];
		rawData.forEach(item => {
			const newItem = {
				Item: item.SKUID,
				ItemDescription: item.SKUDescription,
				customerName: item.CustomerName,
				initialAvailQty: item.Initial_Available_Qty,
				currentAvailQty: item.Current_Available_Qty,
				percentAvailQtyConsumed: item.Available_Qty_Consumed,
				availCostConsumed: item.Available_Cost_Consumed,
				initialAvailCost: item.Initial_Available_Cost,
				currentAvailCost: item.Current_Available_Cost,
				additionalInfo: 'some info'
			};
			formattedData.push(newItem);
		});
		return formattedData;
	};

	const hideNewOrderModal = () => {
		dispatch(NewOrerSagaActions.resetStore());
		setNewOrderModalOpen(false);
	};

	if (loading) {
		return (
			<Sheet>
				<SheetBody>
					<Stack>
						<StackItem isGrow isStretch align="center">
							<Loader />
						</StackItem>
					</Stack>
				</SheetBody>
			</Sheet>
		);
	}

	const handleShowMeAvailabilityClick = () => {
		navigate('/availability');
	};

	const statusContent = constants.STATUS_CONTENT[status];

	return (
		<Sheet isPage>
			<SheetBody>
				<Stack gutter="large" align="center" divisions={2}>
					<StackItem isGrow isStretch span={1}>
						<Card isDefault height="250">
							<Sheet>
								<SheetBody isTransparent>
									<Stack isVertical gutter="medium" align="center">
										<StackItem position="center">
											<Label align="center" size="large">
												{statusContent.title || ''}
											</Label>
										</StackItem>
										<StackItem position="center">
											<Stack isVertical>
												<StackItem>
													{statusContent.p1 ? (
														<Label isSecondary align="center" size="small">
															{statusContent.p1}
														</Label>
													) : (
														<React.Fragment />
													)}
												</StackItem>
												<StackItem>
													{statusContent.p2 ? (
														<Label isSecondary align="center" size="small">
															{statusContent.p2}
														</Label>
													) : (
														<React.Fragment />
													)}
												</StackItem>
											</Stack>
										</StackItem>
										{statusContent.b1 ? (
											<StackItem position="bottom">
												<Button
													data-test-id="generate-order-modal"
													name={
														statusContent.b1.text ? statusContent.b1.text.toUpperCase() : ''
													}
													handleClick={() => {
														navigate('/orders?selectedTab=all');
													}}
													// disabled={constants.DISABLED_STATUSES.includes(status)}
												/>
											</StackItem>
										) : (
											<React.Fragment />
										)}
									</Stack>
								</SheetBody>
							</Sheet>
						</Card>
					</StackItem>
					<StackItem isGrow isStretch span={1}>
						<Card isDefault height={250}>
							<Sheet>
								<SheetBody isTransparent>
									<Stack isVertical gutter="medium" align="center">
										<StackItem position="center">
											<Label align="center" size="medium">
												You have {formatMoney(inventoryTableData?.Current_Available_Cost)} left
												on your availability
											</Label>
										</StackItem>
										<StackItem position="center">
											<Label align="left" size="small" isSecondary>
												{'You have consumed ' +
													formatPercentage(inventoryTableData?.Available_Qty_Consumed) +
													' of your availability.'}
												&nbsp;
												<b>Last Updated: {formatDate(inventoryTableData?.LastUpdate)}</b>
												
											</Label>
										</StackItem>
										<StackItem position="bottom">
											<Button
												name="SHOW ME MY AVAILABILITY"
												handleClick={handleShowMeAvailabilityClick}
											/>
										</StackItem>
									</Stack>
								</SheetBody>
							</Sheet>
						</Card>
					</StackItem>
				</Stack>
			</SheetBody>
		</Sheet>
	);
};
export default Home;
