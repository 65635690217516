// @ts-nocheck
import DatePicker from 'atoms/datePicker/datePicker';
import Heading from 'atoms/heading/heading';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import TextInput from 'atoms/TextInput/textInput';
import SelectBox from 'molecules/SelectBox/selectBox';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import moment from 'moment';
import React from 'react';
import TextArea from 'atoms/TextArea/textArea';
import { useDispatch, useSelector } from 'react-redux';
import * as advertisingSelectors from 'models/advertisings/selector';
import * as advertisingSagaActions from 'models/advertisings/sagaActions';
import Loader from 'atoms/loader';
import Button from 'atoms/button/button';
import { Tprops } from './types';
import _ from 'lodash';
import * as adModalSelectors from 'pages/advertising/adModal/selector';
import * as adModalSagaActions from 'pages/advertising/adModal/sagaActions';
import AntDropdown from 'atoms/antDropdown/antDropdown';
import Validation from 'atoms/validation/validation';
import ModalBody from 'atoms/modal/modalBody';
import ModalActions from 'atoms/modal/modalActions';

const StartAd = (props: Tprops) => {
	const { hideModal, handleNextClick } = props;
	const today = moment();
	const name = 'adTypes';
	const [title, setTitle] = React.useState<string>('');
	const [description, setDescription] = React.useState<string>('');
	const [startDate, setStartDate] = React.useState<any>(today);
	const [endDate, setEndDate] = React.useState<any>(today);
	const [loadDate, setLoadDate] = React.useState<any>(today);
	const [existingStore, setExistingStore] = React.useState<boolean>(false);
	const [assortedAd, setAssortedAd] = React.useState<boolean>(false);
	const [selectedCustomer, setSelectedCustomer] = React.useState<any>('');
	const [selectedAd, setSelectedAd] = React.useState<any>('');
	const [formErrors, setFormErrors] = React.useState<any>({});

	const dispatch = useDispatch();

	const adTypes = useSelector(state => advertisingSelectors.getAdTypes(state));
	const isLoading = useSelector(state => advertisingSelectors.isLoading(state, name));
	const customers = useSelector(state => advertisingSelectors.getCustomers(state, 'advertising'));
	React.useEffect(() => {
		if (adData.CustomerID && adData.AdTypeID) {
			return;
		}
		customers &&
			customers.length &&
			setSelectedCustomer(getSelectedItem(customers, customers[0].CustomerID, 'CustomerName', 'CustomerID'));
		adTypes && adTypes.length && setSelectedAd(getSelectedItem(adTypes, adTypes[0].AdTypeID, 'AdType', 'AdTypeID'));
	}, [customers, adTypes, isLoading]);

	React.useEffect(() => {
		dispatch(advertisingSagaActions.fetchAdTypes(name));
	}, [dispatch]);

	const getSelectedItem = (data: any[], value: string | number, attributeName: string, attribuetValue: string) => {
		let index = _.findIndex(data, function (item: any) {
			return item[attribuetValue] === value;
		});
		if (index > -1) {
			return {
				displayName: data[index][attributeName],
				value: data[index][attribuetValue]
			};
		} else {
			return {};
		}
	};

	const adData: any = useSelector(state => adModalSelectors.getAdModalData(state, 'advertising'));
	React.useEffect(() => {
		if (adData) {
			setTitle(adData.AdTitle);
			setDescription(adData.AdDescription);
			setStartDate(moment(adData.StartDate).format('MM-DD-YYYY'));
			setEndDate(moment(adData.EndDate).format('MM-DD-YYYY'));
			setLoadDate(moment(adData.LoadDate).format('MM-DD-YYYY'));
			setExistingStore(Boolean(adData.UseStoreInventory));
			setAssortedAd(Boolean(adData.UseAssortedSKU));
			setSelectedCustomer(getSelectedItem(customers, adData.CustomerID, 'CustomerName', 'CustomerID'));
			setSelectedAd(getSelectedItem(adTypes, adData.AdTypeID, 'AdType', 'AdTypeID'));
		}
	}, [adData, adTypes, customers]);

	const onClickNext = (e: any) => {
		let data: any = {
			AdTitle: title,
			AdDescription: description,
			StartDate: startDate,
			EndDate: endDate,
			LoadDate: loadDate,
			UseAssortedSKU: existingStore ? 1 : 0,
			UseStoreInventory: assortedAd ? 1 : 0,
			AdTypeID: selectedAd.value,
			CustomerID: selectedCustomer.value
		};
		let keys = _.keys(data);
		for (let key of keys) {
			let keyValue = _.isNil(data[key]);
			if (keyValue && key !== 'AdDescription') {
				if (key === 'AdTitle') {
					setFormErrors({
						title: {
							hasError: true,
							errorMessage: 'Title not found'
						}
					});
				}
				// if (key === 'AdDescription') {
				// 	setFormErrors({
				// 		description: {
				// 			hasError: true,
				// 			errorMessage: 'Description not found'
				// 		}
				// 	});
				// }
				return;
			}
		}
		dispatch(adModalSagaActions.updateAdData('advertising', data));
		handleNextClick(e);
	};

	const getCustomerDropdown = () => {
		let customerDropdowns: any[] = [];
		customers &&
			customers.length &&
			customers.map((singleItem: any) => {
				let data = {
					displayName: singleItem.CustomerName,
					value: singleItem.CustomerID
				};
				customerDropdowns.push(data);
			});
		return customerDropdowns;
	};
	const getAdDropdown = () => {
		let adDropdowns: any[] = [];
		adTypes &&
			adTypes.length &&
			adTypes.map((singleItem: any) => {
				let data = {
					displayName: singleItem.AdType,
					value: singleItem.AdTypeID
				};
				adDropdowns.push(data);
			});
		return adDropdowns;
	};

	const checkIfSelectStoresEnabled = () => {
		let data: any = {
			AdTitle: title,
			AdDescription: description,
			StartDate: startDate,
			EndDate: endDate,
			LoadDate: loadDate,
			UseAssortedSKU: existingStore ? 1 : 0,
			UseStoreInventory: assortedAd ? 1 : 0,
			AdTypeID: selectedAd.value,
			CustomerID: selectedCustomer.value
		};
		let keys = _.reject(_.keys(data), k => k === 'AdDescription');

		for (let key of keys) {
			let keyValue = _.isNil(data[key]);
			if (keyValue) {
				return true;
			}
		}
		return false;
	};

	const renderStartAdData = () => {
		return (
			<Stack isVertical gutter="medium">
				<StackItem>
					<Stack isVertical>
						<StackItem>
							<Heading type="tertiary"> TITLE* </Heading>
						</StackItem>
						<StackItem>
							<Validation
								hasError={formErrors?.title?.hasError}
								errorMessage={formErrors?.title?.errMessage}
							>
								<TextInput
									size="medium"
									value={title}
									onChange={e => setTitle(e.target.value)}
									placeholder={'Tile of Ad'}
								/>
							</Validation>
						</StackItem>
					</Stack>
				</StackItem>
				<StackItem>
					<Stack isVertical>
						<StackItem>
							<Heading type="tertiary"> DESCRIPTION </Heading>
						</StackItem>
						<StackItem>
							<Validation
								hasError={formErrors?.description?.hasError}
								errorMessage={formErrors?.description?.errMessage}
							>
								<TextArea
									value={description}
									onChange={e => setDescription(e.target.value)}
									placeholder={'Add a description for the Ad'}
								/>
							</Validation>
						</StackItem>
					</Stack>
				</StackItem>
				<StackItem>
					<Stack>
						<StackItem isStretch isGrow>
							<Heading type="tertiary"> START DATE* </Heading>
						</StackItem>
						<StackItem>
							<DatePicker
								name={'startDate'}
								selectedDate={moment(startDate) || ''}
								handleClick={e => {
									setStartDate(e.target.value);
								}}
							/>
						</StackItem>
					</Stack>
				</StackItem>
				<StackItem>
					<Stack>
						<StackItem isStretch isGrow>
							<Heading type="tertiary"> END DATE* </Heading>
						</StackItem>
						<StackItem>
							<DatePicker
								name={'endDate'}
								selectedDate={moment(endDate) || ''}
								handleClick={e => {
									setEndDate(e.target.value);
								}}
							/>
						</StackItem>
					</Stack>
				</StackItem>
				<StackItem>
					<Stack>
						<StackItem isStretch isGrow>
							<Heading type="tertiary"> LOAD DATE* </Heading>
						</StackItem>
						<StackItem>
							<DatePicker
								name={'loadDate'}
								selectedDate={moment(loadDate) || ''}
								handleClick={e => {
									setLoadDate(e.target.value);
								}}
							/>
						</StackItem>
					</Stack>
				</StackItem>
				<StackItem>
					<Stack gutter="small">
						<StackItem isStretch isGrow>
							<Heading type="tertiary">
								{' '}
								USE EXISTING STORE INVENTORY FOR ORDER<sup>?*</sup>{' '}
							</Heading>
						</StackItem>
						<StackItem>
							<SelectBox
								name={'Yes'}
								selected={existingStore}
								onChangeSelectBox={() => setExistingStore(true)}
							/>
						</StackItem>
						<StackItem>
							<SelectBox
								name={'No'}
								selected={!existingStore}
								onChangeSelectBox={() => setExistingStore(false)}
							/>
						</StackItem>
					</Stack>
				</StackItem>
				<StackItem>
					<Stack gutter="small">
						<StackItem isStretch isGrow>
							<Heading type="tertiary">
								{' '}
								IS THIS AN ASSORTED ITEM AD?<sup>*</sup>
							</Heading>
						</StackItem>
						<StackItem>
							<SelectBox
								name={'Yes'}
								selected={assortedAd}
								onChangeSelectBox={() => setAssortedAd(true)}
							/>
						</StackItem>
						<StackItem>
							<SelectBox
								name={'No'}
								selected={!assortedAd}
								onChangeSelectBox={() => setAssortedAd(false)}
							/>
						</StackItem>
					</Stack>
				</StackItem>
				<StackItem>
					<Stack>
						<StackItem isStretch isGrow>
							<Heading type="tertiary"> CUSTOMER </Heading>
						</StackItem>
						<StackItem>
							<AntDropdown
								placeholder="Select"
								selectedOption={selectedCustomer}
								options={getCustomerDropdown()}
								updateHandler={(item: any) => {
									setSelectedCustomer(item);
								}}
							></AntDropdown>
						</StackItem>
					</Stack>
				</StackItem>
				<StackItem>
					<Stack>
						<StackItem isStretch isGrow>
							<Heading type="tertiary"> AD TYPE*</Heading>
						</StackItem>
						<StackItem>
							<AntDropdown
								placeholder="Select"
								selectedOption={selectedAd}
								options={getAdDropdown()}
								updateHandler={(item: any) => setSelectedAd(item)}
							></AntDropdown>
						</StackItem>
					</Stack>
				</StackItem>
			</Stack>
		);
	};
	const renderLoader = () => {
		return (
			<Stack isVertical>
				<StackItem isGrow isStretch align="center">
					<Loader />
				</StackItem>
			</Stack>
		);
	};
	return (
		<>
			<ModalBody>
				<Stack isVertical gutter="medium">
					<StackItem isStretch isGrow>
						{isLoading ? renderLoader() : renderStartAdData()}
					</StackItem>
				</Stack>
			</ModalBody>
			<ModalActions>
				<Stack isVertical gutter="medium">
					<StackItem isStretch>
						<Stack align="right" gutter="small">
							<StackItem>
								<Button isSecondary name="CANCEL" handleClick={hideModal} />
							</StackItem>
							<StackItem isStretch align="right">
								<Button
									disabled={checkIfSelectStoresEnabled()}
									name={'SAVE AND SELECT STORES '}
									handleClick={onClickNext}
								/>
							</StackItem>
						</Stack>
					</StackItem>
				</Stack>
			</ModalActions>
		</>
	);
};
export default StartAd;
