// @ts-nocheck
import Heading from 'atoms/heading/heading';
import Sheet from 'atoms/sheet/sheet';
import _, { uniqueId } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as React from 'react';
import { List, Spin, Switch } from 'antd';
import Scrollable from 'atoms/scrollable/scrollable';
import Tabs from 'atoms/tabs/tabs';
import { Tab } from 'atoms/tab/tab';
import * as constants from './constants';
import * as selectors from './selectors';
import * as queries from './queries';
import { useDispatch, useSelector } from 'react-redux';
import * as sagaActions from './sagaActions';
import Loader, { LoaderWrap } from 'atoms/loader';
import Button from 'atoms/button/button';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import NumberInput from 'atoms/NumberInput/numberInput';
import Divider from 'atoms/divider/divider';
import ParameterDetailItem from './parameterDetailItem';
import Icon from 'atoms/icons/icon';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';

const ParameterDetails: React.FunctionComponent = ({
  parameterGroup,
  selector,
  selectedTab,
  index,
}) => {
  const parameterGroups = useSelector(state => {
    return selectors.getSelectedGroupParameters(state, selectedTab);
  });

  const details = useSelector(state =>
    selectors.getParamterDetails(state, parameterGroup.ParameterMasterID)
  );

  const parameters = React.useMemo(
    () => queries.getUpdatedParameters(parameterGroups.parametersList, details),
    [details]
  );
  const isLoadingDetails = useSelector(state => {
    return selectors.isLoading(
      state,
      constants.LOADER_COMPONENTS.PARAMETER_DETAILS
    );
  });

  return (
    <Sheet isInset>
      <SheetBody>
        <Stack isVertical gutter="medium">
          <StackItem isStretch>
            <LoaderWrap isLoading={isLoadingDetails}>
              <Stack style={{ width: '80%' }} isVertical gutter="small">
                {_.map(parameters, (parameter, index) => {
                  return (
                    <StackItem key={index}>
                      <ParameterDetailItem
                        selector={selector}
                        selectedTab={selectedTab}
                        parameter={parameter}
                        groupData={parameterGroup}></ParameterDetailItem>
                    </StackItem>
                  );
                })}
              </Stack>
            </LoaderWrap>
          </StackItem>
        </Stack>
      </SheetBody>
    </Sheet>
  );
};

export default ParameterDetails;
