// @ts-nocheck
import React from 'react';
import { useOutsideAlerter } from 'utils/helpers';
import './modal.css';
import classNames from 'classnames';

interface Props {
  children: React.ReactNode;
}

const ModalActions = (props: Props) => {
  return <div className="modal--actions">{props.children}</div>;
};

export default ModalActions;
