// @ts-nocheck
import { put, takeEvery } from 'redux-saga/effects';
import { getAPIDataV3, putAPIData } from 'utils/api';
import { REQUESTS } from 'utils/requests';

import * as constants from '../constants';
import * as OrdersSlice from '../reducer';
import * as slice from './reducer';
import * as sagaActions from './sagaActions';

function* handleGetHealthData({ payload }) {
	const { healthCheckId } = payload;
	yield put(
		OrdersSlice.toggleLoader({
			loaderComponent: constants.LOADER_COMPONENTS.HEALTH_CHECK,
			isLoading: true
		})
	);
	const response1 = yield getAPIDataV3({
    service: REQUESTS.GET.GET_HEALTHCHECK.service,
    url: REQUESTS.GET.GET_HEALTHCHECK.url,
    pathParams: { healthCheckId, healthCheckTypeId: 1}
  })
  .then(response => response?.data);
	const response2 = yield getAPIDataV3({
    service: REQUESTS.GET.GET_HEALTHCHECK.service,
    url: REQUESTS.GET.GET_HEALTHCHECK.url,
    pathParams: { healthCheckId, healthCheckTypeId: 2}
  }).then(response => response?.data);
	const response3 = yield getAPIDataV3({
    service: REQUESTS.GET.GET_HEALTHCHECK.service,
    url: REQUESTS.GET.GET_HEALTHCHECK.url,
    pathParams: { healthCheckId, healthCheckTypeId: 3}
  }).then(response => response?.data);
	const healthCheckData = [response1, response2, response3];

	yield put(slice.updateHealthCheckData({ healthCheckData }));
	yield put(
		OrdersSlice.toggleLoader({
			loaderComponent: constants.LOADER_COMPONENTS.HEALTH_CHECK,
			isLoading: false
		})
	);
}

function* handleUpdateHealthCheck({ payload }) {
	yield put(
		OrdersSlice.toggleLoader({
			loaderComponent: constants.LOADER_COMPONENTS.HEALTH_CHECK,
			isLoading: true
		})
	);
	yield putAPIData('misc', `healthcheck`, payload).then(response => response?.data);
	yield put(
		OrdersSlice.toggleLoader({
			loaderComponent: constants.LOADER_COMPONENTS.HEALTH_CHECK,
			isLoading: false
		})
	);
}

export default function* rootSaga() {
	return [
		yield takeEvery(sagaActions.getHealthData, handleGetHealthData),
		yield takeEvery(sagaActions.updateHealthCheck, handleUpdateHealthCheck)
	];
}
