// @ts-nocheck
import _ from 'lodash';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import exportFromJSON from 'export-from-json';
import moment from 'moment';
import { put, select, takeEvery } from 'redux-saga/effects';
import {
	checkPollingStatus,
	checkPollingStatusV2,
	deleteAPIDataV2,
	getAPIDataV2,
	getAPIDataV3,
	postAPIData,
	postAPIDataV3,
	putAPIData,
	putAPIDataV2,
	putAPIDataV3
} from 'utils/api';
import * as slice from './reducer';
import * as sagaActions from './sagaActions';
import * as tableColumnsSagaActions from 'models/tableColumns/sagaActions';
import { createFiltersFromQueries } from 'models/dynamicGrid/query';
import { triggerNotification } from 'organisms/notifications/sagaActions';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import { DISTRIBUTE_SKUS_TABLE_ID, SELECT_SKUS_TABLE_ID } from './thetaDistributeExcessAvailability/constants';
import { SELECT_STORES_TABLE_ID } from './thetaOrderCloneModal/constants';
import { generateFileFromString, objectToXML } from './utils';
import { onPublishEvent, onReceiveEvent } from 'models/subscriptions/sagaActions';
import { Events } from 'molecules/ReactGrid/agGrid/utils/events';
import { GRID_EVENTS } from 'molecules/ReactGrid/agGrid/constants';
import { getDispatch, getState } from 'reducers';
import { toggleLoader } from 'models/loaders/sagaActions';
import { getOptimizeShipMethodNumber } from './selectors';
import { updateMultiRows } from 'models/dynamicGrid/reducer';
import { getDistributeAvailabilityNumber } from './thetaDistributeExcessAvailability/selectors';
import { REQUESTS } from 'utils/requests';
import { getWebAppSettings } from 'models/user/selector';
import FileDownload from 'react-file-download';
import { getGridMetadata } from 'models/dynamicGrid/selector';

const lockUnlockRecord = async (tableId: any, data: any) => {
	const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

	return putAPIDataV3({ ...REQUESTS.PUT.TOGGLE_ORDER_STATE, body: { ...data, AppTableID: Number(tableId), isView } }).catch(error => ({
		isError: true,
		error
	}));
};

export default function* rootSaga() {
	function* updateSkuQty(orderSkuId, orderQty) { }

	function* updateRowData({ payload }) {
		let { tableId, row, rowIdentifier, updatedValue } = payload;
		const ordersFilter = dynamicGridSelectors.getGridFilters(getState(), tableId);

		if (
			tableId !== 30 &&
			(ordersFilter?.groupKeys?.length ||
				ordersFilter?.pivotColumns?.length ||
				ordersFilter?.rowGroupCols?.length)
		) {
			return;
		}
		if (tableId === 36) {
			try {
				if (updatedValue < 0) {
					throw new Error('Value should be greater than or equal to 0');
				}
				if (updatedValue % row.OrderMultiple !== 0) {
					throw new Error('Value should be a multiple of ' + row.OrderMultiple);
				}
				const { data, isError, error } = yield putAPIDataV3({
					...REQUESTS.PUT.UPDATE_DISTRIBUTE_SKUS,
					body: {
						updatedAvailability: [{ ColumnName: rowIdentifier, ColumnValue: updatedValue }],
						distNumber: getDistributeAvailabilityNumber(getState())
					},
					pathParams: {
						distributeRowId: row.DistAvailListID
					}
				}
				)
					.then()
					.catch(e => ({ isError: true, error: e }));
				if (error) {
					throw new Error(error.message);
				}
				yield put(dynamicGridSagaActions.updateTableCell(payload));

			} catch (error) {
				payload.updatedValue = {
					value: payload.updatedValue,
					hasError: true,
					errorMessage: error?.message || error?.data?.message
				};

				yield put(dynamicGridSagaActions.updateTableCell(payload));
				yield put(
					triggerNotification({
						message: error?.message || error?.data?.message
					})
				);
			}
		}

		if (tableId === 1) {
			try {
				if (updatedValue < 0) {
					throw new Error('Value should be greater than or equal to 0');
				}
				if (updatedValue % row.OrderMultiple !== 0 && row.OrderMultiple) {
					throw new Error('Value should be a multiple of ' + row.OrderMultiple);
				}

				// const { isError, error } = yield putAPIDataV2('orders', `skuqty/${row.OrderDetailID}`, {
				// 	BulkLoad: 0,
				// 	OrderQty_New: updatedValue,
				// 	SKUID: row.OrderDetailID
				// }).catch(e => {
				// 	return { isError: true, error: e };
				// });

				// if (isError) {
				// 	throw error || 'Something went wrong';
				// }
				// yield put(dynamicGridSagaActions.updateTableCell(payload));
				// yield handleFetchOrderInfo({ payload: { tableId: tableId } });
				return;
			} catch (error) {
				payload.updatedValue = {
					value: payload.updatedValue,
					hasError: true,
					errorMessage: error?.message || error?.data?.message
				};

				yield put(dynamicGridSagaActions.updateTableCell(payload));
				// yield put(
				// 	triggerNotification({
				// 		message: error?.message || error?.data?.message
				// 	})
				// );
				return;
			}
		}
		if (tableId === 2 || tableId === 3 || tableId === 4 || tableId === 5 || tableId === 6) {
			try {
				if (updatedValue < 0) {
					throw new Error('Value should be greater than or equal to 0');
				}
				if (updatedValue % row.QtyPerShelf !== 0 && row.QtyPerShelf) {
					throw new Error('Value should be a multiple of ' + row.QtyPerShelf);
				}


				yield put(slice.updateInfoTimestamp({ refreshInfoTimestamp: Date.now() }));
			} catch (error) {
				payload.updatedValue = {
					value: payload.updatedValue,
					hasError: true,
					errorMessage: error?.message || error?.data?.message
				};

				yield put(dynamicGridSagaActions.updateTableCell(payload));
				yield put(
					triggerNotification({
						message: error?.message || error?.data?.message
					})
				);
			}
		}
		if (tableId === 35) {
			try {
				const getUpdatedParams = () => {
					switch (rowIdentifier) {
						case 'ShipDate':
							return {
								newShipDate: updatedValue,
								OrderID: row.OrderID
							};
						case 'PODate':
							return {
								newPODate: updatedValue,
								OrderID: row.OrderID
							};
						default:
							return {
								NewOrderShipMethodQty: updatedValue,
								OrderID: row.OrderID
							};
					}
				};
				yield putAPIDataV3({ ...REQUESTS.PUT.UPDATE_ORDER_SPLIT_PARAMS, pathParams: { OrderSplitID: row.OrderSplitID }, body: getUpdatedParams() });
				yield put(dynamicGridSagaActions.updateTableCell(payload));
			} catch (error) {
				payload.updatedValue = {
					value: payload.updatedValue,
					hasError: true,
					errorMessage: error?.message || error?.data?.message
				};

				yield put(dynamicGridSagaActions.updateTableCell(payload));
				yield put(
					triggerNotification({
						message: error?.message || error?.data?.message
					})
				);
			}
		}
		if (tableId === 30) {
			try {
				if (updatedValue % row.OrderMultiple !== 0) {
					throw new Error('Value should be a multiple of ' + row.OrderMultiple);
				}
				if (updatedValue < 0) {
					throw new Error('Value should be greater than or equal to 0');
				}
				const metaData = yield select(state => dynamicGridSelectors.getGridMetadata(state, tableId));

				const { data, isError, error } = yield putAPIDataV3({
					...REQUESTS.PUT.PUT_ORDER_KITS, pathParams: { orderKitId: row.OrderKitID }, body: {
						orderQtyNew: updatedValue,
						orderKitId: row.OrderKitID
					}
				})
					.then()
					.catch(e => ({ isError: true, error: e }));

				if (isError) {
					throw error || 'Something went wrong';
				}

				payload[rowIdentifier] = payload.updatedValue;
				yield put(dynamicGridSagaActions.updateTableCell(payload));
				Events.publish(GRID_EVENTS.PURGE_GRID_ROUTE, {
					tableId,
					route: [row.OrderKitID]
				});

				// yield put(dynamicGridSagaActions.updateTableCell(payload));

				yield put(
					dynamicGridSagaActions.handleRowUpdateSuccess({
						tableId,
						row,
						rowIdentifier
					})
				);
				// yield put(dynamicGridSagaActions.fetchGridData(tableId));
			} catch (error) {
				payload.updatedValue = {
					value: payload.updatedValue,
					hasError: true,
					errorMessage: error?.message || error?.data?.message
				};

				yield put(dynamicGridSagaActions.updateTableCell(payload));
			}
		}
		if (tableId === 37) {
			try {
				const { response, isError, error } = yield putAPIDataV3({
					...REQUESTS.PUT.UPDATE_OPTIMIZE_DESIRED_QTY, pathParams: { orderId: row.OrderID }, body: {
						desiredQty: updatedValue,
						optimizeShipMethodNumber: getOptimizeShipMethodNumber(getState())
					}
				})
					.then()
					.catch(e => ({ isError: true, error: e }));
				if (updatedValue < 0) {
					throw new Error('Value should be a greater than 0');
				}
				if (isError) {
					throw error || 'Something went wrong';
				}
				console.log(payload);
				yield put(dynamicGridSagaActions.updateTableCell(payload));
				// yield put(dynamicGridSagaActions.fetchGridData(tableId));
			} catch (error) {
				payload.updatedValue = {
					value: payload.updatedValue,
					hasError: true,
					errorMessage: error?.message || error?.data?.message
				};
				yield put(dynamicGridSagaActions.updateTableCell(payload));
				yield put(
					triggerNotification({
						message: error?.message || error?.data?.message
					})
				);
			}
		}
	}

	//clone
	function* handleUpdateCloneSelectedParameters({ payload }) {
		const { selectedParameters } = payload;

		yield put(slice.updateCloneSelectedParameters({ selectedParameters }));
	}
	function* handleGetCloneDefaultColumnTypes() {
		const defaultColumns = yield getAPIDataV3({
			service: REQUESTS.GET.GET_ORDER_CLONE_TYPES.service,
			url: REQUESTS.GET.GET_ORDER_CLONE_TYPES.url,
			pathParams: { cloneLookupType: 1 }
		})
			.then(response => response?.data.data)
			.catch(error => ({ isError: true, error }));

		if (defaultColumns?.isError) {
			yield put(
				triggerNotification({
					message: 'An error occurred while fetching clone types, please try again later'
				})
			);
			return;
		}
		yield put(slice.updateCloneDefaultColumnTypes({ defaultColumns }));
	}
	function* handleCloneOrders({ payload }) {
		const { data, tableId } = payload;
		yield handleToggleLoading({ payload: { status: true } });
		const ordersSearch = yield select(state => dynamicGridSelectors.getGridFilters(state, tableId));
		const selectedOrders = yield select(state => dynamicGridSelectors.getTableSelection(state, tableId));
		const storeSearch = yield select(state => dynamicGridSelectors.getGridFilters(state, SELECT_STORES_TABLE_ID));
		const selectedStores = yield select(state =>
			dynamicGridSelectors.getTableSelection(state, SELECT_STORES_TABLE_ID)
		);
		const gridData = dynamicGridSelectors.getGridData(getState(), tableId);

		const selectedCustomerIds = _.uniq(
			_.map(
				_.filter(gridData, r => _.includes(selectedOrders?.selectedRowKeys, r.OrderID)),
				c => c.CustomerNum
			)
		);
		const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

		let payloadData = {
			...data,
			ordersSearch: JSON.stringify({
				filter: createFiltersFromQueries(ordersSearch.search)
			}),
			isSelectAllOrders: selectedOrders.isSelectAll,
			selectedCustomerIds,
			orderIds: selectedOrders?.selectedRowKeys.join(','),
			tableId: Number(tableId),
			isView
		};

		if (payloadData?.storeIds) {
			payloadData = {
				...payloadData,
				storeSearch: JSON.stringify({
					filter: createFiltersFromQueries(storeSearch.search)
				}),
				storeIds: selectedStores?.selectedRowKeys.join(','),
				isSelectAllStore: selectedStores.isSelectAll
			};
		}

		const { isError } = yield postAPIDataV3({ ...REQUESTS.POST.POST_ORDER_CLONE, body: payloadData })
			.then(response => response?.data)
			.catch(error => ({ isError: true, error }));
		if (isError) {
			yield put(
				triggerNotification({
					message: 'An error occurred while cloning order, please try again later'
				})
			);
			yield handleToggleLoading({ payload: { status: false } });
			return;
		}
	}

	//split
	function* handleUpdateSplitSelectedParameters({ payload }) {
		const { selectedParameters } = payload;
		yield put(slice.updateSplitSelectedParameters({ selectedParameters }));
	}
	function* handleGetSplitDefaultColumnTypes() {
		const defaultColumns = yield getAPIDataV3({
			service: REQUESTS.GET.GET_ORDER_CLONE_TYPES.service,
			url: REQUESTS.GET.GET_ORDER_CLONE_TYPES.url,
			pathParams: { cloneLookupType: 2 }
		})
			.then(response => response?.data.data)
			.catch(error => ({ isError: true, error }));
		if (defaultColumns?.isError) {
			yield put(
				triggerNotification({
					message: 'An error occurred while updating state, please try again later'
				})
			);
			return;
		}
		yield put(slice.updateSplitDefaultColumnTypes({ defaultColumns }));
	}
	function* handleSplitOrders({ payload }) {
		const { data, tableId } = payload;
		yield handleToggleLoading({ payload: { status: true } });
		const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

		const { isError } = yield postAPIDataV3({ ...REQUESTS.POST.SPLIT_ORDERS, body: { ...data, tableId, isView } })
			.then(response => response?.data)
			.catch(error => ({ isError: true, error }));
		if (isError) {
			yield put(
				triggerNotification({
					message: 'An error occurred while updating state, please try again later'
				})
			);
			yield handleToggleLoading({ payload: { status: false } });
			return;
		}
	}
	//lock-unlock records
	function* lockUnlockRecords({ payload }) {
		const { data, tableId } = payload;

		const ordersSearch = yield select(state => dynamicGridSelectors.getGridFilters(state, tableId));
		const response = yield lockUnlockRecord(tableId, {
			...data,
			selectAllSearch: JSON.stringify({
				filter: createFiltersFromQueries(ordersSearch.search)
			})
		}); //TODO: return promise here
		if (response.isError) {
			yield put(
				triggerNotification({
					message: 'An error occurred while updating state, please try again later'
				})
			);
			return;
		}
		if (response?.data?.isWarning) {
			yield put(
				triggerNotification({
					message: response?.data.message,
					type: 'warning'
				})
			);
			return;
		}
		// yield putAPIDataV2(url, data);
		let gridRows = yield select(state => dynamicGridSelectors.getGridData(state, tableId));
		const selectRowKey = dynamicGridSelectors.getGridMetadata(getState(), tableId)?.selectRowKey;

		gridRows = _.map(
			_.filter(gridRows, r => _.map(data?.OrderIDs?.split(','), o => Number(o)).includes(r[selectRowKey])),
			d => ({ ...d, IsReadOnly: data.IsReadOnly })
		);

		Events.publish(GRID_EVENTS.UPDATE_GRID, {
			tableId,
			updatedRows: gridRows
		});
		return;

		// yield handleCloseModal({ payload: { tableIds: [] } });
		// yield handleToggleLoading({ payload: { status: false } });
	}
	//update order status
	function* handleGetDefaultOrderStatus() {
		const defaultStores = yield getAPIDataV3({
			service: REQUESTS.GET.GET_ORDER_STATUS.service,
			url: REQUESTS.GET.GET_ORDER_STATUS.url,
		})
			.then(response => response?.data.data)
			.catch(error => ({ error, isError: true }));
		if (defaultStores?.isError) {
			yield put(
				triggerNotification({
					message: 'An error occurred while updating state, please try again later'
				})
			);
			return;
		}
		yield put(slice.updateDefaultOrderStatus({ defaultStores }));
	}
	function* handleUpdateOrderStatus({ payload }) {
		const { data, tableId } = payload;
		yield handleToggleLoading({ payload: { status: true } });
		const selectedOrders = yield select(state => dynamicGridSelectors.getTableSelection(state, tableId));
		const ordersSearch = yield select(state => dynamicGridSelectors.getGridFilters(state, tableId));

		const { isError } = yield putAPIDataV3({
			...REQUESTS.PUT.UPDATE_ORDER_STATUS, body: {
				tableId: Number(tableId),
				statusId: data.statusId,
				isSelectAll: selectedOrders.isSelectAll,
				orderIds: selectedOrders?.selectedRowKeys.join(','),
				selectAllSearch: JSON.stringify({
					filter: createFiltersFromQueries(ordersSearch.search)
				})
			}
		}).catch(error => ({ isError: true, error }));

		if (isError) {
			yield put(
				triggerNotification({
					message: 'An error occurred while updating Order Status, please try again later'
				})
			);
			yield handleToggleLoading({ payload: { status: false } });
			return;
		}
		yield put(
			dynamicGridSagaActions.updateSelectedRows({
				tableId,
				isSelectAll: false,
				selectedRowKeys: []
			})
		);
		yield put(dynamicGridSagaActions.refreshGridData({ tableId }));
		yield handleCloseModal({ payload: { tableIds: [] } });

		yield handleFetchOrderInfo({ payload: { tableId: tableId } });
		yield handleToggleLoading({ payload: { status: false } });
	}

	function* handleFetchShipMethods({ payload }) {
		const { reqData } = payload;

		const defaultShippingMethods = yield postAPIDataV3({ ...REQUESTS.POST.GET_ORDER_SHIPPING_METHODS, body: reqData }).then(
			response => response?.data?.data
		);
		yield put(slice.updateDefaultShipMethods({ defaultShippingMethods }));
	}

	function* handleUpdateShipMethods({ payload }) {
		const { data, tableId } = payload;
		yield handleToggleLoading({ payload: { status: true } });

		const selectedOrders = yield select(state => dynamicGridSelectors.getTableSelection(state, tableId));
		const ordersSearch = yield select(state => dynamicGridSelectors.getGridFilters(state, tableId));
		const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

		const { isError, error } = yield putAPIDataV3({
			...REQUESTS.PUT.BULK_UPDATE_ORDER_SHIPPING_METHODS, body: {
				...data,
				isView,
				isSelectAll: selectedOrders.isSelectAll,
				orderIds: selectedOrders?.selectedRowKeys.join(','),
				selectAllSearch: JSON.stringify({
					filter: createFiltersFromQueries(ordersSearch.search)
				})
			}
		}).catch(error => ({ isError: true, error }));

		if (isError) {
			yield handleToggleLoading({ payload: { status: false } });
			yield put(
				triggerNotification({
					message: 'An error occurred while updating shipping method, please try again later'
				})
			);
			throw error || 'Something went wrong';
		}
	}

	//publish order
	function* handlePublishOrders({ payload }) {
		const { tableId, data } = payload;
		const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

		const selectedOrders = yield select(state => dynamicGridSelectors.getTableSelection(state, tableId));
		const ordersSearch = yield select(state => dynamicGridSelectors.getGridFilters(state, tableId));
		yield handleToggleLoading({ payload: { status: true } });
		let reqData = {
			tableId: Number(tableId),
			isSelectAll: selectedOrders.isSelectAll,
			orderIds: selectedOrders?.selectedRowKeys.join(','),
			isErpEnabled: data.isErpEnabled,
			selectAllSearch: JSON.stringify({
				filter: createFiltersFromQueries(ordersSearch.search)
			})
		};
		const response = yield postAPIDataV3({ ...REQUESTS.POST.PUBLISH_ORDERS, body: { ...reqData, isView } })
			.then(response => response)
			.catch(error => ({ error, isError: true }));

		if (response?.isError) {
			yield put(
				triggerNotification({
					message: 'An error occurred while publishing order, please try again later'
				})
			);
			yield handleToggleLoading({ payload: { status: false } });
			return;
		}
		const today = moment().format('MM-DD-YYYY');
		let fileName;

		if (!data.isErpEnabled) {
			if (response?.data?.data?.format === 'xml') {
				fileName = `Published Orders-${today}.${response?.data?.data?.format}`;
				let xmlData = objectToXML(response?.data.data.records);
				FileDownload(xmlData, fileName);
				// generateFileFromString(`${fileName}.xml`, xmlData);
			} else if (response?.data?.data?.format === 'xls' || response?.data?.data?.format === 'xlsx') {
				fileName = `Published Orders-${today}.${response?.data?.data?.format}`;
				exportFromJSON({
					data: { root: { items: response?.data.data.records } },
					fileName,
					fields: [],
					exportType: response?.data?.data?.format
				});
			} else if (response?.data?.data?.format === 'txt') {
				fileName = `Published Orders-${today}`;
				FileDownload(response.data.data.records.map(r => (r.header ? r.header : '') + '\n').join(''), `Published Orders-${today}.txt`,);

				// exportFromJSON({
				// 	data: { root: { items: response.data.data.records } },
				// 	fileName,
				// 	fields: [],
				// 	exportType: response?.data?.data?.format
				// });
			} else {
				fileName = `Published Orders-${today}.csv`;
				exportFromJSON({
					data: response?.data.data.records,
					fileName,
					fields: [],
					exportType: 'csv'
				});
			}
			yield put(
				dynamicGridSagaActions.updateSelectedRows({
					tableId,
					isSelectAll: false,
					selectedRowKeys: []
				})
			);
			yield put(dynamicGridSagaActions.updateGrid({ tableId, filters: {}, isView }));
			yield handleCloseModal({ payload: { tableIds: [] } });

			yield handleFetchOrderInfo({ payload: { tableId: tableId } });
			yield handleToggleLoading({ payload: { status: false } });
		}
	}
	//delete order
	function* handleDeleteOrder({ payload }) {
		const { data, tableId } = payload;
		yield handleToggleLoading({ payload: { status: true } });
		const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

		const { isError, error } = yield postAPIDataV3({ ...REQUESTS.POST.DELETE_ORDER, body: { ...data, isView } }).catch(error => ({
			isError: true,
			error: error?.data
		}));

		if (isError || error?.warning) {
			yield put(
				triggerNotification({
					message: error?.message || 'An error occurred while deleting order, please try again later'
				})
			);
			if (!error.warning) {
				yield handleToggleLoading({ payload: { status: false } });
				return;
			}
		}
	}

	//change PO Date
	function* handlePODateUpdation({ payload }) {
		const { data, tableId } = payload;
		const ordersSearch = yield select(state => dynamicGridSelectors.getGridFilters(state, tableId));
		const selectedOrders = yield select(state => dynamicGridSelectors.getTableSelection(state, tableId));

		yield handleToggleLoading({ payload: { status: true } });
		const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

		const { isError } = yield putAPIDataV3({
			...REQUESTS.PUT.UPDATE_PO_DATE, body: {
				...data,
				isView,
				isSelectAll: selectedOrders.isSelectAll,
				selectAllSearch: JSON.stringify({
					filter: createFiltersFromQueries(ordersSearch.search)
				}),
				tableId: Number(tableId)
			}
		}).catch(error => ({
			isError: true,
			error
		}));
		if (isError) {
			yield put(
				triggerNotification({
					message: 'An error occurred while updating PO Date, please try again later'
				})
			);
			yield handleToggleLoading({ payload: { status: false } });
			return;
		}
	}

	//open modal
	function* handleOpenModal({ payload }) {
		const { openModalName } = payload;

		yield put(slice.handleOpenModal({ openModalName }));
	}

	//close modal
	function* handleCloseModal({ payload }) {
		const { tableIds } = payload;
		yield put(slice.handleOpenModal({ openModalName: '' }));
		if (tableIds && tableIds.length) {
			yield put(dynamicGridSagaActions.cleanUpGrids({ tableIds: tableIds }));
			yield put(slice.cleanUp());
		}
	}

	//bulk order
	function* fetchGetBulkOrderTypes() {
		const defaultColumns = yield getAPIDataV3({
			service: REQUESTS.GET.GET_FIELD_LOOKUP.service,
			url: REQUESTS.GET.GET_FIELD_LOOKUP.url,
			queryParams: { ref: "bulk_order_edit_types" }
		})
			.then(
				response => response?.data.data
			);

		yield put(slice.updateBulkOrderTypes({ defaultColumns }));
	}

	function* handleUpdateBulkEditParams({ payload }) {
		const { bulkParams } = payload;
		yield put(slice.updateBulkEditParams({ bulkParams }));
	}

	function* handleBulkOrderUpdate({ payload }) {
		const { data, tableId } = payload;
		yield handleToggleLoading({ payload: { status: true } });
		const selectedOrders = yield select(state => dynamicGridSelectors.getTableSelection(state, tableId));
		const ordersSearch = yield select(state => dynamicGridSelectors.getGridFilters(state, tableId));
		const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

		const { isError, error } = yield postAPIDataV3({
			...REQUESTS.POST.POST_ORDER_BULK_EDIT, body: {
				...data,
				isSelectAll: selectedOrders.isSelectAll,
				orderIds: selectedOrders?.selectedRowKeys.join(','),
				selectAllSearch: JSON.stringify({
					filter: createFiltersFromQueries(ordersSearch.search)
				}),
				isView
			}
		}).catch(error => ({ isError: true, error: error.data }));

		if (isError || error?.warning) {
			yield put(
				triggerNotification({
					message: error?.message || 'Unable to update orders, please try again later.',
					type: 'warning'
				})
			);
			if (!error.warning) {
				yield handleToggleLoading({ payload: { status: false } });
				return;
			}
		}
	}

	// distributeAvailability
	function* handleDistributeAvailability({ payload }) {
		yield handleToggleLoading({ payload: { status: true } });

		const { tableId, distNum } = payload;
		const distributedSKus = yield select(state => dynamicGridSelectors.getGridData(state, 36));
		// this should have selected orders not selected  row keys
		const selectedOrders = yield select(state => dynamicGridSelectors.getTableSelection(state, tableId));
		const selectedSkus = yield select(state => dynamicGridSelectors.getTableSelection(state, SELECT_SKUS_TABLE_ID));
		const orders = yield select(state => dynamicGridSelectors.getGridData(state, tableId));
		const skus = yield select(state => dynamicGridSelectors.getGridData(state, SELECT_SKUS_TABLE_ID));

		const filteredOrders = [];
		selectedOrders?.selectedRowKeys.forEach(singleSelectedOrder => {
			const index = _.findIndex(orders, function (o) {
				return o.OrderID === singleSelectedOrder || o.OrderDetailID === singleSelectedOrder;
			});
			if (index > -1) {
				filteredOrders.push(orders[index]);
			}
		});

		let filters = yield select(state => dynamicGridSelectors.getGridFilters(state, Number(tableId)));
		const ordersSearch = {
			filter: createFiltersFromQueries(filters.search)
		};

		let skuFilters = yield select(state => dynamicGridSelectors.getGridFilters(state, SELECT_SKUS_TABLE_ID));
		const skusSearch = {
			filter: createFiltersFromQueries(skuFilters.search)
		};

		//why is this used?
		let SkuIDList = _.reduce(
			distributedSKus,
			function (value, singleSku) {
				return value ? `${value},${singleSku?.SKUID}` : `${singleSku?.SKUID}`;
			},
			''
		);

		let distQtyList = _.reduce(
			distributedSKus,
			function (value, singleSku) {
				return value ? `${value},${singleSku?.DistributionQty}` : `${singleSku?.DistributionQty}`;
			},
			''
		);
		let minQtyList = _.reduce(
			distributedSKus,
			function (value, singleSku) {
				return value
					? `${value},${singleSku?.DefaultMinOrderQtyPerStore}`
					: `${singleSku?.DefaultMinOrderQtyPerStore}`;
			},
			''
		);

		let maxQtyList = _.reduce(
			distributedSKus,
			function (value, singleSku) {
				return value
					? `${value},${singleSku?.DefaultMaxOrderQtyPerStore}`
					: `${singleSku?.DefaultMaxOrderQtyPerStore}`;
			},
			''
		);
		const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

		const { isError } = yield postAPIDataV3({
			...REQUESTS.POST.DISTRIBUTE_AVAILABILITY_ASYNC, body: {
				distNumber: skus?.[0]?.DistNumber.toString(),
				tableId: Number(tableId),
				skuIdList: SkuIDList,
				isSelectAllSKU: selectedSkus.isSelectAll,
				selectAllSearchSKU: JSON.stringify(skusSearch),
				orderIdList: selectedOrders?.selectedRowKeys.join(','),
				isSelectAllOrder: selectedOrders.isSelectAll,
				selectAllOrderStore: JSON.stringify(ordersSearch),
				distQtyList: distQtyList,
				minQtyList,
				maxQtyList,
				isView
			}
		}).catch(error => ({
			isError: true,
			error
		}));
		if (isError) {
			yield put(
				triggerNotification({
					message: 'Unable to distribute availabiility, please try again later.'
				})
			);
			yield handleToggleLoading({ payload: { status: false } });
			return;
		}

		//   }
		// } catch (e) {
		//
		// }
		// const distAvailObj = {
		// 	StatusID: statusId
		// };
		// yield postAPIData('distribute/' + skus?.[0]?.DistNumber, distAvailObj);
		// let pollResponse = yield checkPollingStatus(statusId);
		// while (pollResponse.status === 'Processing') {
		// 	pollResponse = yield checkPollingStatus(statusId);
		// }

		// if (pollResponse?.isError) {
		// 	//TODO: Do something
		// } else {
		// }

		// yield handleRefreshTableData({ payload: { tableId: tableId } });
	}

	function* handleOptimizeShipping({ payload }) {
		const { tableId } = payload;
		yield handleToggleLoading({ payload: { status: true } });
		const optimizeStores = yield select(state => dynamicGridSelectors.getGridData(state, 37));
		const optimizeSelectedSkus = yield select(state => dynamicGridSelectors.getTableSelection(state, 38));
		const DesiredQtyList = _.map(optimizeStores, 'ShipMethodQtyRounded');
		const storeOrderIdList = _.map(optimizeStores, 'OrderID');

		const ordersSearch = yield select(state => dynamicGridSelectors.getGridFilters(state, tableId));
		const skuSearch = yield select(state => dynamicGridSelectors.getGridFilters(state, 38));
		const storeSearch = yield select(state => dynamicGridSelectors.getGridFilters(state, 37));

		const selectedOrders = yield select(state => dynamicGridSelectors.getTableSelection(state, tableId));
		const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

		const statusObj = {
			OrderBatchID: null,
			StatusTypeID: 4,
			isView
		};
		var response = yield postAPIDataV3({
			service: REQUESTS.POST.ADD_PROCESS_STATUS.service,
			url: REQUESTS.POST.ADD_PROCESS_STATUS.url,
			body: statusObj
		})
		const statusId = response?.data.OutputParameters.StatusID;
		const userName = sessionStorage.getItem('userName') || 'None';
		const optimizeObj = {
			skuIdList: optimizeSelectedSkus?.selectedRowKeys.join(','),
			optimizeShipMethodNumber: optimizeStores[0].OptimizeShipMethodNumber,
			isSelectAllOrders: selectedOrders.isSelectAll,
			selectAllSkusSearch: JSON.stringify({
				filter: createFiltersFromQueries(skuSearch.search)
			}),
			isSelectAllSkus: optimizeSelectedSkus.isSelectAll,
			tableId: Number(tableId),
			statusId,
			isView
		};

		const { isError, error } = yield postAPIDataV3({ ...REQUESTS.POST.POST_SHIPMENT_OPTIMIZE, body: optimizeObj }).catch(error => ({
			isError: true,
			error
		}));

		if (isError) {
			yield handleToggleLoading({ payload: { status: false } });
			yield put(
				triggerNotification({
					message: 'Unable to optimize, please try again later.'
				})
			);
			return;
		}
	}

	//toggle loading
	function* handleToggleLoading({ payload }) {
		const { status } = payload;
		yield put(slice.handleToggleLoading({ status }));
	}

	function* handleResetTable({ payload }) {
		const { tableId } = payload;
		yield put(tableColumnsSagaActions.resetTableColumns(tableId));
		yield put(tableColumnsSagaActions.fetchTableColumns(tableId));
	}

	function* handleRefreshTableData({ payload }) {
		const { tableId } = payload;
		yield put(dynamicGridSagaActions.updateGrid({ tableId, filters: { pageNumber: 1 } }));
		yield put(
			dynamicGridSagaActions.updateSelectedRows({
				tableId,
				selectedRowKeys: [],
				isSelectAll: false
			})
		);
		// yield handleFetchOrderInfo({ payload: { tableId: tableId } });
	}

	function* handleFetchOrderInfo({ payload: { tableId, search } }) {
		yield handleToggleLoading({ payload: { status: true } });
		let searchQueries = search;
		if (!searchQueries) {
			let filters = yield select(state => dynamicGridSelectors.getGridFilters(state, Number(tableId)));
			searchQueries = filters?.search;
		}
		const searchFilter = {
			filter: createFiltersFromQueries(searchQueries, tableId === 30)
		};
		const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

		const { data, isError, error } = yield postAPIDataV3({
			...REQUESTS.POST.GET_ORDERS_TABLE_SUMMARY, body: {
				search: JSON.stringify(searchFilter),
				tableId: tableId,
				isView: isView || Number(tableId) === 30
			}
		})
			.then(response => ({ data: response?.data.data }))
			.catch(error => ({ isError: true, error }));
		yield handleToggleLoading({ payload: { status: false } });

		if (error?.message === 'CANCELLED') {
			return;
		}

		if (isError) {
			yield put(
				triggerNotification({
					message: 'Unable to fetch Order Info, please try again later.'
				})
			);
			return;
		}
		yield put(slice.updateOrdersInfo({ info: data?.[0] }));
		yield handleToggleLoading({ payload: { status: false } });
	}

	function* refetchInfo({ payload: { tableId } }) {
		if (_.includes([1, 2, 30, 23, 3, 72], tableId)) {
			let metaData = getGridMetadata(getState(), tableId)
			if(metaData?.dataProviderOptions?.isSubgrid){
				yield put(sagaActions.getOrdersSummaryInfo({ tableId: metaData?.dataProviderOptions?.parentTable }));
				return;
			}
			yield put(sagaActions.getOrdersSummaryInfo({ tableId }));
		}
	}

	function* handleReceiveEvent({ payload }: TPayload) {
		switch (payload?.type) {
			case 'TABLE_EV':
				if (payload?.eventName === 'distribute_availability') {
					yield handleCloseModal({ payload: { tableIds: [36, 38] } });
					yield handleToggleLoading({ payload: { status: false } });
					if (payload.metaData.type !== 'error') {
						yield refetchInfo({ payload: { tableId: payload?.metaData?.tableId } });

						yield put(
							dynamicGridSagaActions.refreshGridData({
								tableId: payload?.metaData?.tableId,
							})
						);
						yield put(
							dynamicGridSagaActions.updateSelectedRows({
								tableId: payload?.metaData?.tableId,
								isSelectAll: false,
								selectedRowKeys: []
							})
						);
					}
				}
				if (payload?.eventName === 'clone_orders') {
					yield handleCloseModal({ payload: { tableIds: [31] } });
					yield handleToggleLoading({ payload: { status: false } });

					if (payload.metaData.type !== 'error') {
						yield put(slice.updateCloneSelectedParameters({}));
						yield put(
							dynamicGridSagaActions.refreshGridData({
								tableId: payload?.metaData?.tableId,
							})
						);
						yield handleFetchOrderInfo({ payload: { tableId: payload?.metaData?.tableId } });
						yield put(
							dynamicGridSagaActions.updateSelectedRows({
								tableId: payload?.metaData?.tableId,
								isSelectAll: false,
								selectedRowKeys: []
							})
						);
					}
				}
				if (payload?.eventName === 'optimize_shipmethod') {
					yield handleCloseModal({ payload: { tableIds: [37, 38] } });
					yield handleToggleLoading({ payload: { status: false } });
					if (payload.metaData.type !== 'error') {
						yield refetchInfo({ payload: { tableId: payload?.metaData?.tableId } });
						yield handleFetchOrderInfo({ payload: { tableId: payload?.metaData?.tableId } });

						yield put(
							dynamicGridSagaActions.refreshGridData({
								tableId: payload?.metaData?.tableId,
							})
						);
						yield put(
							dynamicGridSagaActions.updateSelectedRows({
								tableId: payload?.metaData?.tableId,
								isSelectAll: false,
								selectedRowKeys: []
							})
						);
					}
				}
				if (payload?.eventName === 'split_orders') {
					yield handleCloseModal({ payload: { tableIds: [35] } });
					yield handleToggleLoading({ payload: { status: false } });

					if (payload.metaData.type !== 'error') {
						yield refetchInfo({ payload: { tableId: payload?.metaData?.tableId } });

						yield put(
							dynamicGridSagaActions.refreshGridData({
								tableId: payload?.metaData?.tableId,
							})
						);
						yield put(
							dynamicGridSagaActions.updateSelectedRows({
								tableId: payload?.metaData?.tableId,
								isSelectAll: false,
								selectedRowKeys: []
							})
						);
					}
				}
				if (payload?.eventName === 'bulk_update') {
					yield handleCloseModal({ payload: { tableIds: [] } });
					yield handleToggleLoading({ payload: { status: false } });

					if (payload.metaData.type !== 'error') {
						yield put(
							dynamicGridSagaActions.refreshGridData({
								tableId: payload?.metaData?.tableId,
							})
						);

						yield refetchInfo({ payload: { tableId: payload?.metaData?.tableId } });
						yield put(
							dynamicGridSagaActions.updateSelectedRows({
								tableId: payload?.metaData?.tableId,
								isSelectAll: false,
								selectedRowKeys: []
							})
						);
					}
				}

				if (payload?.eventName === 'update_podate') {
					yield handleCloseModal({ payload: { tableIds: [] } });
					yield handleToggleLoading({ payload: { status: false } });

					if (payload.metaData.type !== 'error') {
						yield put(
							dynamicGridSagaActions.refreshGridData({
								tableId: payload?.metaData?.tableId,
							})
						);
						yield put(
							dynamicGridSagaActions.updateSelectedRows({
								tableId: payload?.metaData?.tableId,
								selectedRowKeys: [],
								isSelectAll: false
							})
						);

						yield handleFetchOrderInfo({ payload: { tableId: payload?.metaData?.tableId } });
					}
				}

				if (payload?.eventName === 'delete_orders') {
					yield handleCloseModal({ payload: { tableIds: [] } });
					yield handleToggleLoading({ payload: { status: false } });

					if (payload.metaData.type !== 'error') {
						yield put(
							dynamicGridSagaActions.refreshGridData({
								tableId: payload?.metaData?.tableId,
							})
						);
						yield put(
							dynamicGridSagaActions.updateSelectedRows({
								tableId: payload?.metaData?.tableId,
								selectedRowKeys: [],
								isSelectAll: false
							})
						);
						yield handleFetchOrderInfo({ payload: { tableId: payload?.metaData?.tableId } });
					}
				}

				if (payload?.eventName === 'update_shipmethods') {
					yield handleCloseModal({ payload: { tableIds: [] } });
					yield handleToggleLoading({ payload: { status: false } });

					if (payload.metaData.type !== 'error') {
						yield put(
							dynamicGridSagaActions.refreshGridData({
								tableId: payload?.metaData?.tableId,
							})
						);
						yield handleFetchOrderInfo({ payload: { tableId: payload?.metaData?.tableId } });
						yield put(
							dynamicGridSagaActions.updateSelectedRows({
								tableId: payload?.metaData?.tableId,
								isSelectAll: false,
								selectedRowKeys: []
							})
						);
					}
				}

				if (payload?.eventName === 'publish_orders') {
					yield handleCloseModal({ payload: { tableIds: [] } });
					yield handleToggleLoading({ payload: { status: false } });
					if (payload.metaData?.type !== 'error') {
						yield handleFetchOrderInfo({ payload: { tableId: payload?.metaData?.tableId } });
						yield put(
							dynamicGridSagaActions.refreshGridData({
								tableId: payload?.metaData?.tableId,
							})
						);
					}
				}
				break;
		}
	}

	async function getShipMethodOptimizeNumber({ payload }) {
		const dispatch = getDispatch();

		const { data } = await getAPIDataV3({
			service: REQUESTS.GET.GET_SHIPMENT_OPTIMIZE.service,
			url: REQUESTS.GET.GET_SHIPMENT_OPTIMIZE.url,
			pathParams: { userName: payload.userName }
		})
			.catch(error => ({
				isError: true,
				error
			}));
		dispatch(
			slice.updateOptimizeNumber({
				optimizeShipMethodNumber: data.OptimizeShipMethodNumber
			})
		);
	}

	async function getOptimizeAggregateData({ payload }) {
		const dispatch = getDispatch();
		dispatch(
			toggleLoader({
				compoonent: 'optimize-aggregate-data',
				isLoading: true
			})
		);
		const userName = sessionStorage.getItem('userName') || 'None';
		const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

		const { data } = await postAPIDataV3({
			...REQUESTS.POST.GET_SHIPMENT_STORES,
			pathParams: { userName }, body: {
				fieldGroups: {
					OptimizeShipMethodNumberInput: payload.optimizeShipMethodNumber,
					IsAggregationEnabled: true
				},
				orderIds: payload.orderIds,
				isSelectAll: payload.isOrderSelectAll,
				selectAllSearch: payload.ordersSelectAllSearch,
				tableId: payload.tableId,
				isView
			}
		}).catch(error => ({
			isError: true,
			error
		}));
		dispatch(
			slice.updateOptimizeAggregateData({
				optimizeAggregateInfo: {
					TotalCurrentQty: data?.data[0]?.TotalCurrentQty,
					TotalDesiredQty: data?.data[0]?.TotalDesiredQty
				}
			})
		);
		dispatch(
			toggleLoader({
				compoonent: 'optimize-aggregate-data',
				isLoading: false
			})
		);
	}

	return [
		yield takeEvery(dynamicGridSagaActions.updateRowData, updateRowData),
		yield takeEvery(sagaActions.getShipMethodOptimizeNumber, getShipMethodOptimizeNumber),
		yield takeEvery(sagaActions.getOptimizeAggregateData, getOptimizeAggregateData),
		yield takeEvery(sagaActions.updateCloneSelectedParameters, handleUpdateCloneSelectedParameters),
		yield takeEvery(sagaActions.getCloneDefaultColumnTypes, handleGetCloneDefaultColumnTypes),
		yield takeEvery(sagaActions.cloneOrders, handleCloneOrders),
		yield takeEvery(sagaActions.updateSplitSelectedParameters, handleUpdateSplitSelectedParameters),
		yield takeEvery(sagaActions.getSplitDefaultColumnTypes, handleGetSplitDefaultColumnTypes),
		yield takeEvery(sagaActions.splitOrders, handleSplitOrders),
		yield takeEvery(sagaActions.lockUnlockRecords, lockUnlockRecords),
		yield takeEvery(sagaActions.getDefaultOrderStatus, handleGetDefaultOrderStatus),
		yield takeEvery(sagaActions.updateOrderStatus, handleUpdateOrderStatus),
		yield takeEvery(sagaActions.getShipMethods, handleFetchShipMethods),
		yield takeEvery(sagaActions.updateShipMethods, handleUpdateShipMethods),
		yield takeEvery(sagaActions.publishOrders, handlePublishOrders),
		yield takeEvery(sagaActions.deleteOrder, handleDeleteOrder),
		yield takeEvery(sagaActions.updatePODate, handlePODateUpdation),
		yield takeEvery(sagaActions.toggleModalOpen, handleOpenModal),
		yield takeEvery(sagaActions.toggleModalClose, handleCloseModal),
		yield takeEvery(sagaActions.getBulkOrderTypes, fetchGetBulkOrderTypes),
		yield takeEvery(sagaActions.distrbuteAvailability, handleDistributeAvailability),
		yield takeEvery(sagaActions.optimizeShipping, handleOptimizeShipping),
		yield takeEvery(sagaActions.toggleLoading, handleToggleLoading),
		yield takeEvery(sagaActions.resetTable, handleResetTable),
		yield takeEvery(sagaActions.refreshTableData, handleRefreshTableData),
		yield takeEvery(sagaActions.updateBulkEditParams, handleUpdateBulkEditParams),
		yield takeEvery(sagaActions.updateBulkOrders, handleBulkOrderUpdate),
		yield takeEvery(sagaActions.getOrdersSummaryInfo, handleFetchOrderInfo),
		yield takeEvery(dynamicGridSagaActions.handleRowUpdateSuccess, refetchInfo),
		yield takeEvery(onReceiveEvent, handleReceiveEvent)
	];
}
