// @ts-nocheck

import { Switch } from 'antd';
import BodyText from 'atoms/bodyText/bodyText';
import Button from 'atoms/button/button';
import Icon from 'atoms/icons/icon';
import Label from 'atoms/label/label';
import Loader from 'atoms/loader';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import ModalHeader from 'atoms/modal/modalHeader';
import Modal from 'atoms/modal/reactModal';
import { isLoadingEnabled } from 'models/loaders/selectors';
import { getUserEmail, getUserGrpId, getUserSettings, getUserTenentList, getWebAppSettings, isAccessControlEnabled, isNewUploadEnabled } from 'models/user/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as sagaActions from './sagaActions';
import DropdownInner from 'atoms/dropdown/dropdown';
import { fetchTenentList, updateTenent } from 'models/user/sagaActions';

const Sidebar = ({ hideModal }) => {
	const dispatch = useDispatch();

	const isLoading = useSelector(state => isLoadingEnabled(state, 'USER_SETTINGS'));
	const isTenentListLoading = useSelector(state => isLoadingEnabled(state, 'tenant-list-loading'));
	const userSettings: any = useSelector(state => getUserSettings(state));
	const { ReleaseVersion } = useSelector(getWebAppSettings);
	const isAccessControlValue = useSelector(isAccessControlEnabled);
	const isNewUploadValue = useSelector(isNewUploadEnabled);
	const [accessControl, handleToggleAccessControl] = useState(isAccessControlValue)

	const [newUpload, handleUpdateGridUpload] = useState(isNewUploadValue)

	const email = useSelector(state => getUserEmail(state));
	const grpId = useSelector(state => getUserGrpId(state));
	const tenetList = useSelector(state => getUserTenentList(state))
	const userTenentList = useMemo(() => _.map(tenetList, (res) => ({
		displayName: res.UserName, value: res.PartnerID
	})), [tenetList])
	const [partnerId, setPartnerId] = useState(grpId);
	const selectedTenent = useMemo(() => {
		const findTenent = _.find(userTenentList, (tenant => tenant.value === partnerId))
		return findTenent ? {
			value: findTenent?.value,
			label: findTenent?.displayName
		} : null
	}, [partnerId, userTenentList])
	const handleChange = (value: any) => {
		setPartnerId(value);
	};

	React.useEffect(() => {
		if (tenetList?.length === 0) {
			dispatch(fetchTenentList({ email }));
		}
	}, [dispatch, email, tenetList.length]);

	const toggleAccessControl = (isEnabled) => {
		handleToggleAccessControl(Boolean(isEnabled));
	}

	useState(() => {
		handleToggleAccessControl(isAccessControlValue)
	}, [isAccessControlValue])
	useState(() => {
		handleUpdateGridUpload(isNewUploadValue)
	}, [isNewUploadValue])
	// const [isOrderWritingEnabled, setOrderWritingEnabled] = useState(userSettings?.IsOrderWritingEnabled);

	const handleSaveClick = () => {
		dispatch(
			sagaActions.updateUserAgGridSettings({
				IsAccessControlEnabled: accessControl,
				IsNewUploadEnabled: newUpload
				// IsOrderWritingEnabled: isOrderWritingEnabled
			})
		);
		if (grpId !== partnerId) {
			dispatch(updateTenent({ email, partnerId }))
		}
		// localStorage.setItem("isAccessControlEnabled", isAccessControlEnabled);

		// localStorage.setItem("IsNewUploadEnabled", isGridUploadEnabled);

	};


	// const handleToggleOrderWriting = () => {
	// 	setOrderWritingEnabled(!isOrderWritingEnabled);
	// };

	return (
		<Modal hideModal={hideModal} height={500} width={500}>
			<>
				<>
					<ModalHeader>
						<Stack position="center">
							<StackItem isGrow>User Profile Settings</StackItem>
							<StackItem>
								<StackItem isGrow isStretch align="right">
									<Label size="tiny">Version: {ReleaseVersion?.split('-')?.[1]}</Label>
								</StackItem>
							</StackItem>
						</Stack>
					</ModalHeader>
					{isLoading ? (
						<StackItem isGrow isStretch>
							<Loader isInset />
						</StackItem>
					) : (
						<>
							<ModalBody>
								<Stack isVertical>
									<StackItem isGrow isStretch>
										<Stack isVertical gutter="medium">
											<StackItem>
												<Stack gutter="small">
													<StackItem isGrow>
														<Stack isVertical>
															<StackItem>
																<Label isHighlight>Enable Access Control Settings </Label>
															</StackItem>
															<StackItem>
																<BodyText>
																	Updates the Application Tables to Support advanced{' '}
																	<br />
																	Access Controls settings in App Settings.
																</BodyText>
															</StackItem>
														</Stack>
													</StackItem>
													<StackItem>
														<Switch
															checked={accessControl}
															onClick={toggleAccessControl}
														/>
													</StackItem>
												</Stack>
											</StackItem>
											{/* <StackItem>
												<Stack gutter="small">
													<StackItem isGrow>
														<Stack isVertical>
															<StackItem>
																<Label isHighlight>Enable Pivot Order Writing </Label>
															</StackItem>
															<StackItem>
																<BodyText>
																	Updates the Orders Tables to Support advanced <br />
																	options like Grouping, Pivot Order Writing.
																</BodyText>
															</StackItem>
														</Stack>
													</StackItem>
													<StackItem>
														<Switch
															checked={isOrderWritingEnabled}
															onClick={handleToggleOrderWriting}
														/>
													</StackItem>
												</Stack>
												</StackItem> */}
											<StackItem>
												<Stack gutter="small">
													<StackItem isGrow>
														<Stack isVertical>
															<StackItem>
																<Label isHighlight>Enable New Grid Upload </Label>
															</StackItem>
															<StackItem>
																<BodyText>
																	Enables new upload flow on grids
																</BodyText>
															</StackItem>
														</Stack>
													</StackItem>
													<StackItem>
														<Switch
															checked={newUpload}
															onClick={handleUpdateGridUpload}
														/>
													</StackItem>
												</Stack>
											</StackItem>
											<StackItem>
												<Label isHighlight size={'small'} align={'center'}>Please Select a Tenent</Label>
											</StackItem>
											<DropdownInner disabled={!Boolean(tenetList?.length)}
												isLoading={isTenentListLoading}
												key={'options'}
												selectedOption={selectedTenent}
												options={userTenentList}
												isFullWidth
												updateHandler={(tenant: any) => {
													handleChange(tenant.value)
												}}
												isAutocomplete={true}
											/>
										</Stack>
									</StackItem>
								</Stack>
							</ModalBody>
							<ModalActions>
								<Stack align="right" gutter="small">
									<StackItem>
										<Button isSecondary name="CANCEL" handleClick={hideModal} />
									</StackItem>
									<StackItem>
										<Button name="SAVE" disabled={isTenentListLoading}
										 handleClick={handleSaveClick} />
									</StackItem>
								</Stack>
							</ModalActions>
						</>
					)}
				</>
			</>
		</Modal>
	);
};

const SettingsDialog = () => {
	const [isSettingsDialog, toggleDialog] = useState(false);
	return (
		<>
			<Icon
				extraClass="secondary"
				isActive
				onClick={() => {
					toggleDialog(true);
				}}
				faAlias="faCog"
			/>
			{isSettingsDialog && (
				<Sidebar
					hideModal={() => {
						toggleDialog(false);
					}}
				/>
			)}
		</>
	);
};

export default SettingsDialog;
