// @ts-nocheck
import BodyText from 'atoms/bodyText/bodyText';
import DatePicker from 'atoms/datePicker/datePicker';
import Dropdown from 'atoms/dropdown/dropdown';
import NumberInput from 'atoms/NumberInput/numberInput';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as sagaActions from '../sagaActions';
import * as selectors from '../selectors';
import Validation from 'atoms/validation/validation';

export interface OrderCloneType {
  CloneOrderTypeID: number;
  CloneOrderType: string;
}

interface DropdownType {
  displayName: string;
  value: string;
}
interface Props {
  dropdownTypes: ReadonlyArray<OrderCloneType>;
}

const SelectBulkTypes = (props: Props) => {
  const { dropdownTypes } = props;
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const bulkEditParams = useSelector(state =>
    selectors.getBulkEditParams(state)
  );

  const selectedOption = dropdownTypes.filter(
    (option: any) => option.value === bulkEditParams.bulkOrderEditTypeId
  );

  const handleDropdownSelect = (item: DropdownType) => {
    const bulkParams = {
      ...bulkEditParams,
      bulkOrderEditTypeId: item.value,
    };
    dispatch(sagaActions.updateBulkEditParams(bulkParams));
  };

  const updateInputValue = (input: any) => {
    setValue(input);
    const bulkParams = {
      ...bulkEditParams,
      bulkOrderEditValue: input,
    };

    dispatch(sagaActions.updateBulkEditParams(bulkParams));
  };

  return (
    <Stack isVertical gutter="small">
      <StackItem>
        <Stack gutter="medium">
          <StackItem position='center'>
            <Dropdown
              placeholder={'Set Value'}
              options={dropdownTypes}
              updateHandler={handleDropdownSelect}
              selectedOption={selectedOption[0]}
            />
          </StackItem>
          <StackItem>
            <Validation
              hasError={
                bulkEditParams.bulkOrderEditTypeId === 1 &&
                bulkEditParams.bulkOrderEditValue < 0
              }
              errorMessage={'Exact value cannot be less than 0'}>
              <NumberInput
                isEnabled={true}
                value={bulkEditParams.bulkOrderEditValue || value.toString()}
                minValue={bulkEditParams.bulkOrderEditTypeId === 1 ? '0' : ''}
                onChange={updateInputValue}
              />
            </Validation>
          </StackItem>
        </Stack>
      </StackItem>
      <StackItem>
        <BodyText type="secondary">
          Quantities are in Order Multiples.
        </BodyText>
      </StackItem>
    </Stack>
  );
};

export default SelectBulkTypes;
