// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const triggerFetchDemandPlanningGroups = createAction('trigger/fetchDemandPlanningGroups');
export const triggerFetchDemandPlanningGroupDetails = createAction(
	'trigger/fetchDemandPlanningGroupDetails',
	function prepare({ planningGroupId }) {
		return {
			payload: {
				planningGroupId
			}
		};
	}
);
export const triggerSaveDemandPlanningAttributeDetail = createAction(
	'trigger/SaveDemandPlanningAttributeDetail',
	function prepare({ attributeDetails, groupSelectionOptions }) {
		return {
			payload: {
				attributeDetails,
				groupSelectionOptions
			}
		};
	}
);

export const triggerUpdateToggleTagState = createAction(
	'trigger/UpdateToggleTagState',
	function prepare({ tagId, state }) {
		return {
			payload: {
				tagId, state
			}
		};
	}
);


export const triggerCreateTag = createAction(
	'trigger/triggerCreateTag',
	function prepare({ tagName }) {
		return {
			payload: {
				tagName
			}
		};
	}
);
export const triggerDeleteDemandPlanningGroup = createAction(
	'trigger/DeleteDemandPlanningGroup',
	function prepare({ planningGroupId, rowIdentifier, isBulkDelete }) {
		return {
			payload: {
				planningGroupId,
				rowIdentifier,
				isBulkDelete
			}
		};
	}
);
