// @ts-nocheck
import BodyText from 'atoms/bodyText/bodyText';
import Heading from 'atoms/heading/heading';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as React from 'react';

function AvailabilityInfo() {
  return (
    <Sheet>
      <SheetBody>
        <Stack isVertical gutter="medium" position="top">
          <StackItem>
            <Heading type="primary">Availability</Heading>
          </StackItem>
          <StackItem>
            <Heading type="secondary">Availability</Heading>
          </StackItem>
          <StackItem>
            <Heading type="tertiary">
              This page represents the current product available to write on
              orders.
            </Heading>
          </StackItem>
          <StackItem>
            <BodyText type="secondary">
              You can see both the total position and what has already been
              generated onto orders. It is cricital to understand what your
              available product is in order to make the best replenishment
              decisions.
            </BodyText>
          </StackItem>
        </Stack>
      </SheetBody>
    </Sheet>
  );
}

export default AvailabilityInfo;
