// @ts-nocheck
import BodyText from 'atoms/bodyText/bodyText';
import Button from 'atoms/button/button';
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import { Tab } from 'atoms/tab/tab';
import Tabs from 'atoms/tabs/tabs';
import _ from 'lodash';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as sagaActions from '../sagaActions';
import * as selectors from '../selectors';
import SelectSkus from './selectSkus';
import { SELECT_SKUS_TABLE_ID } from './constants';
import DistributeSkus from './distributeSkus';
import { getDistNum } from '../api';
import Loader from 'atoms/loader';
import ModalHeader from 'atoms/modal/modalHeader';
import ReactModal from 'atoms/modal/reactModal';
import ModalBody from 'atoms/modal/modalBody';
import ModalActions from 'atoms/modal/modalActions';

interface TProps {
	hideModal: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
	selectedTab: string;
}

const DISTRIBUTE_EXCESS_AVAILABILITY_STEPS = {
	SELECT_SKUS: 'selectSkus',
	DISTRIBUTE_SKUS: 'distributeSkus',
	REVIEW_DISTRIBUTION: 'reviewDistribution',
	DISTRIBUTE_AVAILABILITY: 'distributeAvailability'
};

const DISTRIBUTE_STEPS = [
	{
		title: 'Select SKUs',
		identifier: DISTRIBUTE_EXCESS_AVAILABILITY_STEPS.SELECT_SKUS
	},
	{
		title: 'Distribute SKUs',
		identifier: DISTRIBUTE_EXCESS_AVAILABILITY_STEPS.DISTRIBUTE_SKUS
	},
	{
		title: 'Review Distribution',
		identifier: DISTRIBUTE_EXCESS_AVAILABILITY_STEPS.REVIEW_DISTRIBUTION
	},
	{
		title: 'Distribute Availability',
		identifier: DISTRIBUTE_EXCESS_AVAILABILITY_STEPS.DISTRIBUTE_AVAILABILITY
	}
];

const getDisabledStatus = (identifier: string, selectedSkus: any, isLoading: boolean) => {
	switch (identifier) {
		case DISTRIBUTE_EXCESS_AVAILABILITY_STEPS.SELECT_SKUS:
			return isLoading;
		case DISTRIBUTE_EXCESS_AVAILABILITY_STEPS.DISTRIBUTE_SKUS:
			return isLoading || !selectedSkus?.selectedRowKeys?.length;
		case DISTRIBUTE_EXCESS_AVAILABILITY_STEPS.REVIEW_DISTRIBUTION:
			return isLoading || !selectedSkus?.selectedRowKeys?.length;
		case DISTRIBUTE_EXCESS_AVAILABILITY_STEPS.DISTRIBUTE_AVAILABILITY:
			return isLoading || !selectedSkus?.selectedRowKeys?.length;
		default:
			return isLoading;
	}
};

const DistributeModalHeader = ({ selectedStep, setSelectedStep, steps, selectedSkus, isLoading }: any) => {
	return (
		<Stack isVertical gutter="small">
			<StackItem isStretch>
				<Heading>Distribute Excess Availability</Heading>
			</StackItem>
			<StackItem isStretch>
				<Divider />
			</StackItem>
			<StackItem isStretch>
				<Tabs type="secondary">
					{_.map(steps, (step, index) => {
						return (
							<Tab
								key={index}
								value={index}
								isSelected={selectedStep.identifier === step.identifier}
								onSelectTabItem={() => setSelectedStep(step)}
								disabled={getDisabledStatus(step.identifier, selectedSkus, isLoading)}
							>
								{step.title}
							</Tab>
						);
					})}
				</Tabs>
			</StackItem>
		</Stack>
	);
};

const DistributeModalContent = ({ selectedStep, selectedOrders, selectedTab, selectedSkus, parent }: any) => {
	const totalRecordCount = useSelector(state => dynamicGridSelectors.getTotalCount(state, selectedTab));
	switch (selectedStep.identifier) {
		case DISTRIBUTE_EXCESS_AVAILABILITY_STEPS.DISTRIBUTE_SKUS:
			return <DistributeSkus parent={parent} selectedTab={selectedTab} />;
		case DISTRIBUTE_EXCESS_AVAILABILITY_STEPS.REVIEW_DISTRIBUTION:
			return (
				<Stack isVertical gutter="medium">
					<StackItem>
						{selectedSkus.isSelectAll ? (
							<BodyText>
								You have selected All SKUs to be distributed across{' '}
								{selectedOrders.isSelectAll
									? totalRecordCount
									: selectedOrders?.selectedRowKeys?.length}{' '}
								Orders.
							</BodyText>
						) : (
							<BodyText>
								You have selected {selectedSkus?.selectedRowKeys?.length} SKU to be distributed across{' '}
								{selectedOrders.isSelectAll
									? totalRecordCount
									: selectedOrders?.selectedRowKeys?.length}{' '}
								Orders.
							</BodyText>
						)}
					</StackItem>
				</Stack>
			);
		case DISTRIBUTE_EXCESS_AVAILABILITY_STEPS.DISTRIBUTE_AVAILABILITY:
			return <> ThetaBot has finished distributing your excess availability. </>;
		case DISTRIBUTE_EXCESS_AVAILABILITY_STEPS.SELECT_SKUS:
		default:
			return <SelectSkus parent={parent} selectedTab={selectedTab} />;
	}
};

const DistributeModalActions = ({
	selectedStep,
	setSelectedStep,
	hideModal,
	steps,
	selectedSkus,
	reviewDistrbution,
	isLoading
}: any) => {
	const getNextAction = () => {
		switch (selectedStep.identifier) {
			case DISTRIBUTE_EXCESS_AVAILABILITY_STEPS.DISTRIBUTE_SKUS:
				return (
					<Button
						name="Review Distribution >"
						handleClick={() => setSelectedStep(steps[2])}
						disabled={false}
					/>
				);
			case DISTRIBUTE_EXCESS_AVAILABILITY_STEPS.REVIEW_DISTRIBUTION:
				return (
					<Button name="Distribute Excess Availability" handleClick={reviewDistrbution} disabled={false} />
				);
			case DISTRIBUTE_EXCESS_AVAILABILITY_STEPS.DISTRIBUTE_AVAILABILITY:
				return <Button name="Continue" handleClick={hideModal} disabled={isLoading} />;
			case DISTRIBUTE_EXCESS_AVAILABILITY_STEPS.SELECT_SKUS:
			default:
				return (
					<Button
						name="Distribute SKUs >"
						handleClick={() => setSelectedStep(steps[1])}
						disabled={!Boolean(selectedSkus?.selectedRowKeys?.length) && !selectedSkus?.isSelectAll}
					/>
				);
		}
	};
	return (
		<Stack gutter="small" align="right">
			<StackItem>
				{selectedStep.identifier !== DISTRIBUTE_EXCESS_AVAILABILITY_STEPS.DISTRIBUTE_AVAILABILITY && (
					<Button isSecondary name="Cancel Distribution" handleClick={hideModal} />
				)}
			</StackItem>
			<StackItem>{getNextAction()}</StackItem>
		</Stack>
	);
};

const ThetaDistributeExcessAvailabilityModal = (props: TProps) => {
	const { hideModal, selectedTab } = props;
	const dispatch = useDispatch();
	const [ref, handleUpdateRef] = React.useState(null);

	const selectedOrders = useSelector(state => dynamicGridSelectors.getTableSelection(state, Number(selectedTab)));

	const steps = DISTRIBUTE_STEPS;
	const [selectedStep, setSelectedStep] = React.useState<any>(steps[0]);

	const selectedSkus = useSelector(state => dynamicGridSelectors.getTableSelection(state, SELECT_SKUS_TABLE_ID));

	const reviewDistrbution = () => {
		const distNum = getDistNum();
		dispatch(
			sagaActions.distrbuteAvailability({
				tableId: selectedTab,
				distNum: distNum
			})
		);
		setSelectedStep(steps[3]);
	};

	const isLoading = useSelector(state => selectors.getLoadingStatus(state));

	return (
		<ReactModal hideModal={hideModal} height={800} width={800}>
			<ModalHeader>
				<DistributeModalHeader
					selectedStep={selectedStep}
					setSelectedStep={setSelectedStep}
					selectedOrders={selectedOrders?.selectedRowKeys}
					steps={steps}
					selectedSkus={selectedSkus}
				/>
			</ModalHeader>
			<ModalBody onDomRef={handleUpdateRef}>
				{isLoading ? (
					<Stack isVertical>
						<StackItem isGrow isStretch align="center">
							<Loader />
						</StackItem>
					</Stack>
				) : (
					<DistributeModalContent
						selectedStep={selectedStep}
						setSelectedStep={setSelectedStep}
						hideModal={hideModal}
						selectedOrders={selectedOrders}
						steps={steps}
						selectedTab={selectedTab}
						selectedSkus={selectedSkus}
						isLoading={isLoading}
						parent={ref}
					/>
				)}
			</ModalBody>
			<ModalActions>
				<DistributeModalActions
					selectedStep={selectedStep}
					setSelectedStep={setSelectedStep}
					hideModal={hideModal}
					steps={steps}
					selectedSkus={selectedSkus}
					reviewDistrbution={reviewDistrbution}
					isLoading={isLoading}
				/>
			</ModalActions>
		</ReactModal>
	);
};

export default ThetaDistributeExcessAvailabilityModal;
