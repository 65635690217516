// @ts-nocheck
import { put, takeEvery } from 'redux-saga/effects';
import {  postAPIDataV3, putAPIDataV3 } from 'utils/api';
import { triggeRemoveRowAccess, triggerAddAccessLevel, triggerAddUserRole, triggerCreateUser, triggerDeactivateUser, triggerRemoveUserRole } from './sagaActions';
import { toggleLoader } from 'models/loaders/sagaActions';
import { LOADER_COMPONENTS } from './constants';
import { getDispatch } from 'reducers';
import { triggerFetchAutoComplete } from 'models/autoComplete/sagaActions';
import { triggerNotification } from 'organisms/notifications/sagaActions';

export const handleAddAccessLevel = async ({ payload: { 	selectedEntities,
	accessLevel,
	userId

} }) => {
	
	const dispatch = getDispatch();
	try {
		if (!userId) {
			return
		}
		dispatch(toggleLoader({
			componentId: LOADER_COMPONENTS.CREATE_ACCESS,
			isLoading: true
		}));
		const { data } = await postAPIDataV3({ service: 'acl', url: 'accessLevel/user/entity', body: {
			selectedEntities,
			accessLevel,
			userId
		} });
		dispatch(triggerFetchAutoComplete({
			componentId: 'acl-user-accessLevels',
		}))
		dispatch(toggleLoader({
			componentId: LOADER_COMPONENTS.CREATE_ACCESS,
			isLoading: false	
		}));
	}
	catch (e) {
		dispatch(triggerNotification({
			message: e?.data?.message
		}))
	}

};


export const handleAddRole = async ({ payload: { userId, roles, isForced } }) => {
	try {
	
		const dispatch = getDispatch();
	
		const { data } = await putAPIDataV3({
			service: 'acl', url: `users/${userId}`, body: {
				Roles: roles,
				isForced
			}
		});
		dispatch(triggerFetchAutoComplete({
			componentId: 'acl-all-users',
		}))
		
	}
	catch (e) {
		
	}

};

export const handleRemoveRole = async ({ payload: { userId, roles } }) => {
	try {
	
		const dispatch = getDispatch();
	
		const { data } = await putAPIDataV3({
			service: 'acl', url: `users/roles/${userId}`, body: {
				Roles: roles
			}
		});
		dispatch(triggerFetchAutoComplete({
			componentId: 'acl-all-users',
		}))
	}
	catch (e) {
		
	}

};


export const handleRemoveRowAccess = async ({ payload: { userId,selectedEntities } }) => {
	try {
	
		const dispatch = getDispatch();
	
		const { data } = await postAPIDataV3({
			service: 'acl', url: 'accessLevel/user/entity/toggle', body: {
			IsActive: false,
			selectedEntities,
			userId
		}});
		dispatch(triggerFetchAutoComplete({
			componentId: 'acl-user-accessLevels',
		}))
		// dispatch(triggerFetchAutoComplete({
		// 	componentId: 'acl-rowAccess-roles',
		// }))
		// dispatch(triggerFetchAutoComplete({
		// 	componentId: 'acl-all-roles',
		// }))
		
	}
	catch (e) {
			console.log(e)
	}

};




export default function* rootSaga() {
	return [
		yield (takeEvery(triggerAddAccessLevel, handleAddAccessLevel)),
		// yield (takeEvery(triggerAddUserRole, handleAddRole)),
		// yield (takeEvery(triggerRemoveUserRole, handleRemoveRole)),
		yield (takeEvery(triggeRemoveRowAccess, handleRemoveRowAccess))

	];
}