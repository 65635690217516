// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const getInventoryData = createAction('home/getInventoryData');

export const loadPage = createAction('home/load-page');

export const updateStatusID = createAction('home/update-status-id', statusID => ({
	payload: { statusID }
}));

export const updateIntervalID = createAction('home/update-status-time-interval', statusIntervalID => ({
	payload: { statusIntervalID }
}));

export const checkOrderStatus = createAction('home/order-status', ({ statusID }) => ({
	payload: { statusID }
}));
