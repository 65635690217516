// @ts-nocheck
import TextInput from "atoms/TextInput/textInput";
import DropDown from "atoms/dropdown/dropdown";
import Heading from "atoms/heading/heading";
import Label from "atoms/label/label";
import classNames from "classnames";
import Stack from "molecules/stack/stack";
import StackItem from "molecules/stackItem/stackItem";
import * as React from "react";
import CsvDownload from "react-json-to-csv";
import { updateModalData } from "./sagaActions";
import { useDispatch, useSelector } from "react-redux";
import { getData, getPlanogramTypes } from "./selectors";

function PlanogramDetails(props: Props) {
  const dispatch = useDispatch();
  const orderData = useSelector(state => getData(state));

  let planogramCreationTypes = useSelector(getPlanogramTypes);

  planogramCreationTypes = planogramCreationTypes.map(p => ({ displayName: p.PlanogramType, value: p.PlanogramTypeID }))

  const inputChangeHandler = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.type === 'checkbox' ? e.target.checked : e.target?.value;
    dispatch(
      updateModalData({
        PlanogramParameters: {
          ...orderData.PlanogramParameters,
          [e.target.name]: value
        }
      })
    );
  }, [orderData]);

  const onChangePlanogramType = React.useCallback((item: any) => {
    console.log(item)
    dispatch(
      updateModalData({
        PlanogramParameters: {
          ...orderData.PlanogramParameters,
          ['PlanogramTypeID']: item.value
        }
      }))
  }, [orderData]);
  const selectedPlanogramType = planogramCreationTypes.find(p => p.value == orderData?.PlanogramParameters?.PlanogramTypeID) || planogramCreationTypes?.[0]

  React.useEffect(() => {
    if (!orderData?.PlanogramParameters?.PlanogramTypeID) {
      dispatch(
        updateModalData({
          PlanogramParameters: {
            ...orderData.PlanogramParameters,
            ['PlanogramTypeID']: selectedPlanogramType?.value
          }
        }))
    }

  }, [orderData?.PlanogramParameters?.PlanogramTypeID, selectedPlanogramType])

  return (
    <Stack isVertical gutter="medium">
      <StackItem>
        <Heading type="secondary"> Planogram Override Details</Heading>
      </StackItem>
      <StackItem>
        <Stack>
          <StackItem isGrow>
            <Stack isVertical>
              <Label>
                Planogram Type Value
              </Label>
            </Stack>
          </StackItem>
          <StackItem>
            <DropDown selectedOption={selectedPlanogramType} updateHandler={onChangePlanogramType} options={planogramCreationTypes}>

            </DropDown>
          </StackItem>
        </Stack>
      </StackItem>
      <StackItem>
        <Stack>
          <StackItem isGrow>
            <Stack isVertical>
              <Label>
                Percent Fulfillment Override
              </Label>
            </Stack>
          </StackItem>
          <StackItem>
            <TextInput onChange={inputChangeHandler} name='PercentFullfillmentOverride' value={orderData?.PlanogramParameters?.PercentFullfillmentOverride ? orderData?.PlanogramParameters?.PercentFullfillmentOverride : ''} ></TextInput>
          </StackItem>
        </Stack>
      </StackItem>
    </Stack>
  );
}

export default PlanogramDetails;
