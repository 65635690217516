// @ts-nocheck
import * as React from 'react';
import './tab.scss';
import classNames from 'classnames';

export type TabItem = {
	displayName: string;
	value: string;
};

export interface Props {
	isSelected?: boolean;
	displayName: string;
	value: string;
	onSelectTabItem: (TabItem: TabItem) => void;
	onHover: (TabItem: TabItem) => void;
	children: React.ReactNode;
	disabled?: boolean;
}

export const Tab: React.FunctionComponent<any> = (props: Props) => {
	const { isSelected, onSelectTabItem, displayName, value, disabled } = props;
	const classes: any = classNames(['tab', { [`tab--selected`]: isSelected }, { [`tab--disabled`]: disabled }]);
	const handleSelectTabItem = React.useCallback(() => {
		if (disabled) {
			return;
		}
		onSelectTabItem({ displayName, value });
	}, [onSelectTabItem, disabled]);
	return (
		<div className={classes} onClick={handleSelectTabItem}>
			{props.children}
		</div>
	);
};

Tab.defaultProps = {
	isSelected: false
};

export default Tab;
