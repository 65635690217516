// @ts-nocheck
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import Loader from 'atoms/loader';
import ModalHeader from 'atoms/modal/modalHeader';
import ReactModal from 'atoms/modal/reactModal';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as selectors from '../selectors';
import * as constants from './constants';
import * as query from './query';
import ScheduleDetails from './scheduleDetails';
import SelectStore from './selectStore';

const StoreScheduleModal = (props: any) => {
	const { mode, data } = props;

	const dispatch = useDispatch();

	const [selectedStep, setSelectedStep] = React.useState(constants.STEPS[0]);
	const initialData =
		mode === constants.MODAL_MODES.UPDATE
			? {
					storeScheduleId: data.storeScheduleId,
					storeSchedule: data.storeSchedule,
					selectedDays: query.getDaysArr(data.selectedDays)
			  }
			: {
					storeSchedule: '',
					selectedDays: []
			  };

	React.useEffect(() => {
		if (mode === constants.MODAL_MODES.UPDATE) {
			dispatch(
				dynamicGridSagaActions.updateSelectedRows({
					tableId: constants.MODAL_TABLE_IDS.STORES,
					selectedRowKeys: data.stores,
					isSelectAll: false
				})
			);
		}
	}, []);

	const [scheduleData, updateData] = React.useState(initialData);

	const loaderCreateSchedule = useSelector(state => selectors.isLoading(state, 'createSchedule'));

	const loaderUpdateSchedule = useSelector(state => selectors.isLoading(state, 'updateSchedule'));

	const hideModal = React.useCallback(() => {
		props.hideModal();
		dispatch(
			dynamicGridSagaActions.cleanUpGrids({
				tableIds: [constants.MODAL_TABLE_IDS.STORES]
			})
		);
	}, [dispatch]);

	const updateProcessStep = React.useCallback((step: any) => {
		return () => setSelectedStep(step);
	}, []);

	const isLoading = loaderCreateSchedule || loaderUpdateSchedule;

	return (
		<ReactModal
			hideModal={hideModal}
			height={selectedStep.key === constants.STEPS_KEYS.SCHEDULE_DETAILS ? 500 : 800}
			width={selectedStep.key === constants.STEPS_KEYS.SCHEDULE_DETAILS ? 500 : 800}
		>
			<ModalHeader>
				<Stack isVertical gutter="small">
					<StackItem isStretch>
						<Heading>
							{mode === constants.MODAL_MODES.UPDATE ? 'Update Store Schedule' : 'Create a new Schedule'}
						</Heading>
					</StackItem>
					<StackItem isStretch>
						<Divider />
					</StackItem>
				</Stack>
			</ModalHeader>
			{isLoading && (
				<Stack isVertical>
					<StackItem isGrow isStretch align="center">
						<Loader />
					</StackItem>
				</Stack>
			)}
			{!isLoading && selectedStep.key === constants.STEPS_KEYS.SCHEDULE_DETAILS && (
				<ScheduleDetails
					key={'scheduleDetails'}
					hideModal={hideModal}
					handleNextClick={updateProcessStep(constants.STEPS[1])}
					data={scheduleData}
					updateData={updateData}
				/>
			)}
			{!isLoading && selectedStep.key === constants.STEPS_KEYS.SELECT_STORE && (
				<SelectStore
					key={'selectStores'}
					hideModal={hideModal}
					goBack={updateProcessStep(constants.STEPS[0])}
					data={scheduleData}
					updateData={updateData}
					mode={mode}
				/>
			)}
		</ReactModal>
	);
};

export default StoreScheduleModal;
