// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import update from 'react-addons-update';
import * as constants from './constants';
import { TReducer } from './types';
export const initialState: TReducer = {
	data: {},
	isLoading: {},
	filters: {},
	metaData: {},
	selection: {},
	userSettings: {},
	events: {
		update: {},
		remove: {}
	}
};

const slice = createSlice({
	initialState,
	name: `${constants.MODEL_NAME}`,
	reducers: {
		updateComponentSettings(state, { payload }) {
			return {
				...state,
				userSettings: {
					...state.userSettings,
					[payload.ComponentName]: {
						metaData: _.omit({ ...payload, ...payload.ComponentMetaData }, ['ComponentMetaData'])
					}
				}
			};
		},
		cleanupGridData(state, { payload: { tableId } }) {
			return {
				...state,
				data: _.omit({ ...state.data }, tableId),
				isLoading: _.omit({ ...state.isLoading }, tableId),
				filters: _.omit({ ...state.filters }, tableId),
				metaData: _.omit({ ...state.metaData }, tableId),
				selection: _.omit({ ...state.selection }, tableId)
			};
		},
		updateGridData(state, { payload: { tableId, data, isAppend } }) {
			return {
				...state,
				data: {
					...state.data,
					[tableId]: isAppend
						? [...state.data[tableId], ..._.map(data, (d, i) => ({ ...d, index: i }))]
						: [..._.map(data, (d, i) => ({ ...d, index: i }))]
				}
			};
		},

		updateSelectedRows(state, { payload: { tableId, data } }) {
			return {
				...state,
				selection: {
					...state.selection,
					[tableId]: data
				}
			};
		},
		updateLoader(state, { payload: { eventName, isLoading } }) {
			return {
				...state,
				isLoading: {
					...state.isLoading,
					[eventName]: isLoading
				}
			};
		},
		updateFilters(state, { payload: { tableId, filters } }) {
			return {
				...state,
				filters: {
					...state.filters,
					[tableId]: filters
				}
			};
		},
		updateMetadata(state, { payload: { tableId, metaData, isAppend, ...rest} }) {
			return {
				...state,
				metaData: {
					...state.metaData,
					[tableId]: isAppend ? { ...state.metaData[tableId], ...metaData, ...rest} : 
					{...metaData, ...rest}
				},
				...rest
			};
		},
		updateTableCell(state, { payload: { tableId, row, rowIdentifier, updatedValue } }) {
			try {
				return update(state, {
					data: {
						[tableId]: {
							[row.index]: {
								[rowIdentifier]: { $set: updatedValue }
							}
						}
					}
				});
			} catch (e) {}
		},
		updateMultiRows(state, { payload: { tableId, updatedRows, rowIdentifier, selectRowKey } }) {
			return {
				...state,
				data: {
					...state.data,
					[tableId]: _.map(state.data[tableId], rp => {
						let row = _.find(updatedRows, gr => gr[selectRowKey] === rp[selectRowKey]);
						if (row) {
							return { ...rp, ...row };
						}
						return rp;
					})
				}
			};
		},
		updateGridRowData(state, { payload: { tableId, row, rowIdentifier, newValue } }) {
			try {
				return update(state, {
					data: {
						[tableId]: {
							[row.index]: { $set: newValue }
						}
					}
				});
			} catch (e) {}
		},
		handleRowUpdateToggle(state, { payload }) {
			try {
				return update(state, {
					data: {
						[payload.tableId]: {
							[payload.row.index]: {
								isEditEnabled: { $set: payload.isEditEnabled }
							}
						}
					}
				});
			} catch (e) {}
		}
	}
});

export default slice;

export const {
	updateGridData,
	cleanupGridData,
	updateLoader,
	updateFilters,
	updateMultiRows,
	updateMetadata,
	updateTableCell,
	updateGridRowData,
	updateComponentSettings,
	updateSelectedRows,
	handleRowUpdateToggle
} = slice.actions;
