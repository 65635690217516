// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import Modal from 'atoms/modal/modal';
import ModalBody from 'atoms/modal/modalBody';
import './fileUploader.scss';

const { Dragger } = Upload;

const FileUploader: React.FC<Props> = ({ onUpload, files, disabled }) => {
	const [fileList, setFileList] = useState<UploadFile[]>([]);

	const handleFileUpload = (info: { fileList: UploadFile[] }) => {
		let fileList = [...info.fileList];
		fileList = fileList.slice(-1);
		fileList = fileList.map(file => {
			if (file.response) {
				file.url = file.response.url;
			}
			file.status = 'done';
			return file;
		});
		setFileList(fileList);
		onUpload(fileList);
	};

	useEffect(() => {
		setFileList(files);
	}, [files]);

	return (
		<Dragger
			name="file"
			disabled={disabled}
			multiple={false}
			onChange={handleFileUpload}
			accept=".csv"
			customRequest={() => {
				// return Promise.resolve(onUpload(fileList?.[0]));
			}}
			fileList={fileList}
			style={{ width: '100%'}}
			className="file-upload"
		>
			<p className="ant-upload-drag-icon">
				<InboxOutlined />
			</p>
			<p className="ant-upload-text">Click or drag file to this area to upload</p>
		</Dragger>
	);
};

export default FileUploader;
