// @ts-nocheck
import _ from 'lodash';
import { DROPDOWN_OPTIONS_VALUES } from './constants';
import { TTableColumnGroup } from 'models/tableColumns/types';

export const toggleColumn = (
  groups: TTableColumnGroup[],
  columnId: number
): TTableColumnGroup[] => {
  return _.map(groups, group => ({
    ...group,
    columns: _.map(group.columns, column => ({
      ...column,
      selected:
        column.AppColumnSurrogateID === columnId
          ? !column.selected
          : column.selected,
    })),
  }));
};

export const filterColumnsByStatus = (
  groups: TTableColumnGroup[],
  selected: boolean
): TTableColumnGroup[] => {
  return _.map(groups, group => ({
    ...group,
    columns: _.filter(group.columns, column => column.selected === selected),
  }));
};

export const filterColumnsBySearch = (
  groups: TTableColumnGroup[],
  query: string = ''
): TTableColumnGroup[] => {
  return _.map(groups, group => ({
    ...group,
    columns: _.filter(group.columns, column =>
      column.AppColumn.toLowerCase().includes(query.toLowerCase())
    ),
  }));
};

export const getActiveStatus = (key: string, columns: any) => {
  if (key === DROPDOWN_OPTIONS_VALUES.ENABLED) {
    return true;
  }
  if (key === DROPDOWN_OPTIONS_VALUES.NOT_ENABLED) {
    return false;
  }

  const index = _.findIndex(
    Object.keys(columns),
    singleKey => singleKey === key
  );

  return !index;
};

export const getColumnsForRendering = (
  columns: TTableColumnGroup[],
  dropdown: any,
  searchInput: string
): TTableColumnGroup[] => {
  if (!columns) {
    return [];
  }

  let columnsForRendering = [];
  if (dropdown.value === DROPDOWN_OPTIONS_VALUES.NOT_ENABLED) {
    columnsForRendering = filterColumnsByStatus(columns, false);
  } else if (dropdown.value === DROPDOWN_OPTIONS_VALUES.ENABLED) {
    columnsForRendering = filterColumnsByStatus(columns, true);
  } else {
    columnsForRendering = [...columns];
  }

  columnsForRendering = _.map(columnsForRendering, group => ({
    ...group,
    columns: _.filter(
      group.columns,
      column => column.IsCustomizableColumn && !column.IsInternalColumn
    ),
  }));

  if (searchInput.length >= 1) {
    columnsForRendering = filterColumnsBySearch(
      columnsForRendering,
      searchInput
    );
  }

  return columnsForRendering;
};

export const getSelectedColumnIds = (groups: any) => {
  return _.flatten(
    _.map(groups, group =>
      _.map(
        _.filter(group.columns, column => column.selected),
        'AppColumnSurrogateID'
      )
    )
  );
};
