// @ts-nocheck
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';

import _ from 'lodash';
import { getGridFilterName } from 'models/dynamicGrid/query';
import { fetchComponentUserSettings } from 'models/dynamicGrid/sagaActions';
import { getGridData, getGridMetadata } from 'models/dynamicGrid/selector';
import { updateTableColumnConfigAg, updateTableColumnsAg } from 'models/tableColumns/sagaActions';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDispatch, getState } from 'reducers';
import { getObjectDiff } from 'utils/utilities';
import { EVENTS, GRID_EVENTS } from '../constants';
import { Events } from '../utils/events';
import { getTableColumnQueue, getTableColumns } from '../../../../models/tableColumns/selector';
import { addColumnOperations, updateTableColumns } from 'models/tableColumns/reducer';

let eventInProgress;

export const onProcessColumnQueue = ({ params, tableId, isReset }) => {
	// return new Promise((resolve) => {
	const { api } = params;
	const dispatch = getDispatch();
	const columnOperations = getTableColumnQueue(getState(), tableId);
	const updatesQueue = columnOperations.filter(u => {
		return u.source !== 'gridOptionsChanged' && u.source !== 'flex'
	});
	// let columns = getTableColumns(getState(), tableId)?.map(c => ({ ...c, index: api.getColumnDefs()?.findIndex(col => col?.colId === c?.AppColumnName) }))?.sort((a, b) => a.index - b.index)?.map(c => _.omit(c, ['index']));


	if (!updatesQueue.length) {
		dispatch(addColumnOperations({
			tableId,
			columnOperations: [],
			isReplace: true
		}))
		return;
	}
	let columns = api.getColumnDefs().filter(c => c?.metaData?.AppColumnID).map(c => c.metaData);

	for (let update of updatesQueue) {
		for (let uCol of update.columns) {
			columns = columns.map((col, index) => {
				if (col.AppColumnName === uCol.colId) {
					col.IsColumnEnabled = update.type === 'columnVisible' ? uCol.visible : col.IsColumnEnabled;
					col.PinPreference = uCol.pinned;
					col.IsFixedColumn = Boolean(uCol.pinned);
					col.ColumnWidth = uCol.actualWidth;
				}

				return { ...col, DefaultSortOrder: index }
			})
		}
	}
	dispatch(
		updateTableColumnConfigAg({
			payload: {
				tableId: tableId,
				isBulkUpdate: true,
				bulkUpdateColumns: columns,
				updatesQueue,
				isInternal: true,
				isReset
			}
		})
	);


};


const onColumnMoved = (params, tableColumns) => {
	const { columns, toIndex, column, api } = params;
	const dispatch = getDispatch();
	// console.log('%cuseAgGrid.tsx line:27 params', 'color: white; background-color: #007acc;', params, eventInProgress);
	// console.log('%cuseAgGrid.tsx line:27 params', 'color: white; background-color: #007acc;', column?.moving, params.source, eventInProgress);
	if (params.source === 'gridOptionsChanged' || eventInProgress === 'pinned') {

		eventInProgress = '';
		return;
	}
	if (!toIndex) {
		return;
	}



	let colData = column?.colDef?.metaData;
	let updatedCols = _.map(
		api.getColumnDefs().filter(c => c?.metaData?.AppColumnID),
		(d, index) => ({ ...d.metaData, DefaultSortOrder: index })
	);
	dispatch(
		updateTableColumnConfigAg({
			payload: {
				tableId: colData?.tableId,
				isBulkUpdate: true,
				bulkUpdateColumns: updatedCols,
				isInternal: true
			}
		})
	);
};

const onColumnPinned = (params, tableColumns) => {
	const { columns, pinned } = params;
	if (params.source === 'gridOptionsChanged' || params.source === "flex") {
		return;
	}
	eventInProgress = 'pinned';
	const dispatch = getDispatch();
	if (columns.length > 1 && pinned === null) {
		return;
	}

	dispatch(
		updateTableColumnConfigAg({
			payload: {
				columnId: columns[0]?.colDef?.metaData?.AppColumnSurrogateID,
				attributes: {
					PinPreference: pinned,
					IsFixedColumn: Boolean(pinned)
				},
				tableId: columns[0].colDef.metaData.AppTableID
			}
		})
	);
};
const handleColumnsChange = (params, updatedTableColumns, tableId) => {
	const {
		columnApi: { columnModel }
	} = params;
	if (params?.column?.moving) {
		return;
	}
	if (!columnModel) {
		return;
	}
	const { displayedColumns } = columnModel;
	const dispatch = getDispatch();

	let tableColumns = _.filter(updatedTableColumns, col => col.IsColumnEnabled)
		.map(c => c.AppColumnSurrogateID)
		.filter(d => _.isEmpty(d))
		.sort()
		.join(',');

	let columns = _.compact(displayedColumns.map(d => d?.colDef?.metaData?.AppColumnSurrogateID))
		.filter(d => d !== '' && d !== undefined)
		.sort()
		.join(',');

	//
	if (!columns || columns === tableColumns) {
		return;
	}
	dispatch(
		updateTableColumnsAg({
			tableId,
			columns: _.map(
				_.filter(displayedColumns, c => c?.colDef?.field),
				({ colDef }) => colDef?.metaData?.AppColumnSurrogateID
			)
		})
	);
};
const onColumnResized = options => {

	const dispatch = getDispatch();

	let { column, finished, columns } = options;

	const updatedColumns = _.filter(columns, f => f?.colDef?.field);

	if (!finished || !updatedColumns.length || options.source === 'flex' || options.source === 'toolPanelUi') {
		return;
	}

	dispatch(
		updateTableColumnConfigAg({
			payload: {
				tableId: updatedColumns[0]?.colDef?.metaData?.tableId,
				isBulkUpdate: true,
				bulkUpdateColumns: _.map(updatedColumns, c => ({ ...c.colDef.metaData, ColumnWidth: c.actualWidth }))
			}
		})
	);
};

export function useAgGrid({ tableId, gridRef, childTableId, childGridRef }) {
	const gridRows = useSelector(state => getGridData(state, tableId));
	const [currentRows, updateCurrentRows] = useState([]);
	useEffect(() => {
		if (gridRef?.current) {
			Events.subscribe(GRID_EVENTS.PURGE_GRID_ROUTE, data => {
				if (data.tableId != tableId) {
					return;
				}
				gridRef?.current?.api?.refreshServerSide({ route: data.route, purge: true });
			});
			Events.subscribe(GRID_EVENTS.UPDATE_GRID, data => {
				if (Number(data.tableId) === Number(tableId)) {
					_.forEach(data.updatedRows, d => {
						const rowIdentifier = getGridMetadata(getState(), data.tableId)?.selectRowKey;
						_.forEach(gridRef?.current?.api.getRenderedNodes(), r => {
							if (d?.[rowIdentifier] === r?.data?.[rowIdentifier]) {
								r.setData(d);
							}
						});

						// data;
					});
				}
				else if (Number(data.tableId) === Number(childTableId)) {
					_.forEach(data.updatedRows, d => {
						const rowIdentifier = getGridMetadata(getState(), data.tableId)?.selectRowKey;
						_.forEach(childGridRef?.current?.api.getRenderedNodes(), r => {
							if (d?.[rowIdentifier] === r?.data?.[rowIdentifier]) {
								r.setData(d);
							}
						});
						// data;
					});
				}
			});
			Events.subscribe(GRID_EVENTS.CLEAR_SELECTION, data => {
				if (Number(data.tableId) === Number(tableId)) {
					gridRef?.current?.api.getSelectedNodes()?.forEach(function (node) {
						node.setSelected(false);
					});
				}
				else if (Number(data.tableId) === Number(childTableId)) {
					childGridRef?.current?.api.getSelectedNodes()?.forEach(function (node) {
						node.setSelected(false);
					});
				}

			});
			Events.subscribe(GRID_EVENTS.UPDATE_GRID_CELL, data => {

				if (Number(data.tableId) === Number(tableId)) {
					const rowKey = getGridMetadata(getState(), data.tableId)?.selectRowKey;
					_.forEach(gridRef?.current?.api.getRenderedNodes(), r => {
						if (data?.updatedRow?.row?.[rowKey] === r?.data?.[rowKey]) {

							r.setData({
								...data?.updatedRow?.row,
								[data?.updatedRow?.rowIdentifier]: data?.updatedRow?.updatedValue
							});
						}
					});
				}
				else if (Number(data.tableId) === Number(childTableId)) {
					const rowKey = getGridMetadata(getState(), data.tableId)?.selectRowKey;
					_.forEach(childGridRef?.current?.api.getRenderedNodes(), r => {
						if (data?.updatedRow?.row?.[rowKey] === r?.data?.[rowKey]) {
							r.setData({
								...data?.updatedRow?.row,
								[data?.updatedRow?.rowIdentifier]: data?.updatedRow?.updatedValue
							});
						}
					})
				}

			});

			Events.subscribe(GRID_EVENTS.DELETE_ROWS, data => {

				if (Number(data.tableId) === Number(tableId)) {
					const rowKey = getGridMetadata(getState(), data.tableId)?.selectRowKey;
					let firstRow;
					let gridData = [];
					gridRef?.current?.api.forEachNode(n => gridData.push(n));
					const rowsToRemove = gridData.filter(r => data.deletedRows.find(d => d?.[rowKey] === r.data?.[rowKey]));
					const tx = {
						remove: _.map(rowsToRemove, r => ({ id: r.id }))
					};
					// console.log();
					gridRef?.current?.api.applyServerSideTransaction(tx);
					gridRef?.current?.api.getModel().refreshStore();
				}
				else if (Number(data.tableId) === Number(childTableId)) {
					const rowKey = getGridMetadata(getState(), data.tableId)?.selectRowKey;
					let firstRow;
					let gridData = [];
					childGridRef?.current?.api.forEachNode(n => gridData.push(n));
					const rowsToRemove = gridData.filter(r => data.deletedRows.find(d => d?.[rowKey] === r.data?.[rowKey]));
					const tx = {
						remove: _.map(rowsToRemove, r => ({ id: r.id }))
					};
					// console.log();
					childGridRef?.current?.api.applyServerSideTransaction(tx);
					childGridRef?.current?.api.getModel().refreshStore();
				}


			});
		}
	}, [gridRef?.current, childGridRef?.current]);

	return {
		onColumnMoved,
		onColumnPinned,
		handleColumnsChange,
		onProcessColumnQueue,
		onColumnResized
	};
}
