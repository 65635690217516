// @ts-nocheck
import '../advanced.scss';

import { Switch } from 'antd';
import DateWrapper from 'atoms/datePicker/datePicker';
import DropDown from 'atoms/dropdown/dropdown';
import Icon from 'atoms/icons/icon';
import TextInput from 'atoms/TextInput/textInput';
import _ from 'lodash';
import { getGridFilters, getGridFiltersByColumn, getGridMetadata } from 'models/dynamicGrid/selector';
import { GRID_EVENTS } from 'molecules/ReactGrid/agGrid/constants';
import { Events } from 'molecules/ReactGrid/agGrid/utils/events';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDispatch, getState } from 'reducers';
import { formatDate } from 'utils/utilities';
import { v4 as uuidv4 } from 'uuid';

import * as query from '../query';
import * as sagaActions from '../sagaActions';
import { FilterTags } from './filterTags';
import { AgGridSearchMultiSelectFilter } from './multiSelectFilter';



export const AgGridSearchFilter = props => {
	const { currentQuery, currentColumnRef, tableId, onUpdateQueries = () => null, isTagFilter = false, isInline } = props;
	const gridMetaData = useSelector(state => getGridMetadata(state, tableId));
	const selectedCriteria = query.getSearchParameters(currentColumnRef?.colDef?.metaData ?? {});
	const [isTagFilterOpen, setTagFilterOpen] = useState(false);

	const [filterOption, updateFilterOption] = useState(
		currentQuery?.operator || query.getCriteriaOperators(selectedCriteria ?? {})[0]
	);
	const [currentSearchInput, updateCurrentSearchInput] = useState(currentQuery?.query?.from || currentQuery?.query);
	const [currentSearchInputEnd, updateCurrentSearchInputEnd] = useState(currentQuery?.query?.to);

	useEffect(() => {
		updateCurrentSearchInput(currentQuery?.query?.from || currentQuery?.query);
		updateCurrentSearchInputEnd(currentQuery?.query?.to);
		updateFilterOption(currentQuery?.operator || query.getCriteriaOperators(selectedCriteria ?? {})[0]);
	}, [currentQuery?.query, currentQuery?.operator]);

	const [isSearchMultiSelect, setSearchMultiSelect] = useState(Boolean(gridMetaData?.MultiSelectOptions));
	const isInputVisible = !_.some(['empty', 'not.empty', 'is.true', 'is.false'], c => c === filterOption.value);

	const dispatch = getDispatch();

	const onUpdateInput = (e, type) => {
		if (type === 'date') {
			updateCurrentSearchInput(formatDate(e));
			return;
		}
		updateCurrentSearchInput(e.target.value);
	};
	const isOperatorBtwn = filterOption.value === 'btw' || filterOption.value === 'nbtw';

	const removeQuery = () => {
		let queries = getGridFiltersByColumn(getState(), tableId, currentQuery?.criteria?.rowIdentifier);
		let updated = _.filter(queries, q => q.id !== currentQuery.id);
		Events.publish(GRID_EVENTS.CLEAR_SELECTION, {
			tableId,
		});
		dispatch(
			sagaActions.removeQuery({
				tableId,
				query: currentQuery,
			})
		);
		if (onUpdateQueries) {
			onUpdateQueries(updated);
		}
	};
	const updateQuery = params => {
		let updated = [];
		const searchQueriesAll = getGridFilters(getState(), tableId);
		Events.publish(GRID_EVENTS.CLEAR_SELECTION, {
			tableId,
		});
		const { id, selectedCriteria, searchInput, searchInputEnd, selectedOperator, isUpdate } = params;
		if (id && searchQueriesAll?.search.length) {
			updated = _.map(searchQueriesAll?.search, q => {
				if (q.id === id) {
					return {
						id,
						criteria: selectedCriteria,
						query:
							selectedOperator && (selectedOperator.value === 'btw' || selectedOperator.value === 'nbtw')
								? { from: searchInput, to: searchInputEnd }
								: searchInput,
						operator: selectedOperator
					};
				}
				return q;
			});
		} else {
			updated = [
				...(searchQueriesAll?.search ?? []),
				{
					id: id || uuidv4(),
					criteria: selectedCriteria,
					query:
						selectedOperator && (selectedOperator.value === 'btw' || selectedOperator.value === 'nbtw')
							? { from: searchInput, to: searchInputEnd }
							: searchInput,
					operator: selectedOperator
				}
			];
		}

		if (onUpdateQueries) {
			onUpdateQueries(_.filter(updated, val => val.id));
		}
		setTagFilterOpen(false);
		dispatch(
			sagaActions.updateAgQueries({
				searchQueries: updated,
				tableId
			})
		);
		// currentColumnRef.setFilterActive();
	};
	const handleSubmit = (e, isClick, options) => {
		console.info(e, isClick, options);
		if ((!currentSearchInput?.trim() || (isOperatorBtwn && !currentSearchInputEnd?.trim())) && isInputVisible) {
			return;
		}
		if (e?.keyCode === 13 || isClick) {
			updateQuery({
				selectedCriteria,
				searchInput: (options?.currentSearchInput || currentSearchInput)?.trim(),
				searchInputEnd: (options?.currentSearchInputEnd || currentSearchInputEnd)?.trim(),
				selectedOperator: options?.filterOption || filterOption,
				tableId,
				id: currentQuery?.id,
				isUpdate: currentQuery?.id
			});
		}
	};

	if (!currentColumnRef) {
		return null;
	}
	const isTagEnabled = isTagFilter && isTagFilterOpen;
	if (isTagEnabled) {
        return (
			<FilterTags {...props} onToggleTagFilter={(value) => {
				setTagFilterOpen(value)
			}
			}  />
		);
	}
	return <AgGridSearchMultiSelectFilter onToggle={setSearchMultiSelect} isEnabled={isSearchMultiSelect} isInline={isInline} {...props}></AgGridSearchMultiSelectFilter>;

};

