// @ts-nocheck
import React from 'react';
import Parameters from './parameters';
import ParametersReducer from './reducer';
import ParametersSagas from './sagas';

export default [
	{
		path: '/parameters',
		key: 'key',
		componentId: 'parameters',
		component: () => <Parameters />,
		routes: [],
		importReducer: () => {
			return [ParametersReducer];
		},
		importSagas: () => {
			return [ParametersSagas];
		},
		title: 'Parameters Page',
		headerName: 'Parameter Settings',
		exact: true
	}
];
