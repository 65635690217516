// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const fetchTableColumns = createAction(
  'masterDataKits/fetchTableColumns',
  function prepare(tableId) {
    return {
      payload: {
        tableId,
      },
    };
  }
);

export const fetchTableData = createAction(
  'masterDataKits/fetchTableData',
  function prepare(tableId, query = {}) {
    return {
      payload: {
        query,
        tableId,
      },
    };
  }
);

export const resetTable = createAction(
  'masterDataKits/resetTable',
  function prepare(tableId) {
    return {
      payload: {
        tableId,
      },
    };
  }
);

export const createUpdateFilterQuery = createAction(
  'masterDataKits/create-update-filter',
  function prepare(
    tableId,
    {
      searchFilters,
      searchQueries,
      isClearSearchParam,
    }
  ) {
    return {
      payload: {
        searchFilters,
        searchQueries,
        tableId,
        isClearSearchParam,
      },
    };
  }
);

export const refreshTableData = createAction(
  'masterDataKits/reset-data',
  function prepare(tableId) {
    return {
      payload: {
        tableId,
      },
    };
  }
);
