import { putAPIDataV2, putAPIDataV3 } from 'utils/api';
import { TABS_VALUES } from '../constants';




export const getTableIdSelector = selectedTab => {
	switch (selectedTab) {
		case TABS_VALUES.ORDER_SKUS:
			return 'OrderDetailID';
		case TABS_VALUES.ORDER_ITEMS:
			return 'OrderDetailVarietyID';
		case TABS_VALUES.ORDER_KITS:
			return 'OrderKitID';
		case TABS_VALUES.ALL_ORDRES:
		default:
			return 'OrderID';
	}
};
