// @ts-nocheck
import _ from 'lodash';
import React, { useState } from 'react';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import Icon from 'atoms/icons/icon';
import RenderInput from './renderInput';
import HealthCheck from 'pages/orders/healthCheck/healthCheck';
import DatePicker from 'atoms/datePicker/datePicker';
import { useDispatch, useSelector } from 'react-redux';
import * as dynamicGridSagaActions from '../dynamicGrid/sagaActions';
import moment from 'moment';
import BodyText from 'atoms/bodyText/bodyText';
import { Checkbox, Tooltip } from 'antd';
import Link from 'atoms/link/link';
import { formatPercentage, formatMoney, formatThousands, formatDate } from 'utils/utilities';
import Validation from 'atoms/validation/validation';
import { getColumnInfoByIndentifier } from './selector';
import { isRowUpdateNotificationEnabled } from 'models/dynamicGrid/query';
import { getUserSettings } from 'models/user/selector';
import { getState } from 'reducers';
import { Tag } from 'antd';
import { getGridMetadata } from 'models/dynamicGrid/selector';
export const createComponent = (componentName: string, params: any) => {
	switch (componentName) {
		// case 'RenderUpdateDropdown':
		// 	return <RenderUpdateText rowIdentifier={params.rowIdentifier} type={params.type} />;
		// case 'RenderUpdateText':
		// 	return <RenderUpdateText rowIdentifier={params.rowIdentifier} type={params.type} />;
		case 'RenderTiles':
			return <RenderTiles row={params.row || params.data}  {...params} />
		case 'RenderBooleanStatus':
			let data = params.row || params.data;
			return data?.[params?.rowIdentifier] ? 'Active' : 'Inactive';
		case 'RenderCell':
			return <RenderCellAg {...params} />
		case 'RenderIcon':
			return <RenderIcon row={params.row || params.data} rowIdentifier={params.rowIdentifier} />;
		case 'RenderInput':
			return (
				<RenderInput
					isInlineEdit={Number(params.tableId) === 63 || Number(params.tableId) === 93 || !isRowUpdateNotificationEnabled(params.tableId) || params?.isUploadGrid}
					params={params}
					isUploadGrid={params?.isUploadGrid}
					type={params.type}
					isPivotMode={params?.isPivotMode}
					row={params.row || params.data}
					rowIdentifier={params.isPivotViewEnabled ? params.colDef.colId : params.rowIdentifier}
					tableId={params.tableId}
				/>
			);
		case 'RenderCheckbox':
			return (
				<RenderCheckBox
					params={params}
					isUploadGrid={params?.isUploadGrid}
					isPivotMode={params?.isPivotMode}
					row={params.row || params.data}
					rowIdentifier={params.isPivotViewEnabled ? params.colDef.colId : params.rowIdentifier}
					tableId={params.tableId}
				/>
			);

		case 'RenderHealthCheck':
			return <RenderHealthCheck {...params} healthCheckId={params.rowIdentifier} />;
		case 'RenderLock':
			return <RenderLock row={params.row || params.data} rowIdentifier={params.rowIdentifier} />;
		case 'RenderDate':
			return (
				<RenderDate
					row={params.row || params.data}
					rowIdentifier={params.rowIdentifier}
					tableId={params.tableId}
				/>
			);
		case 'RenderLink':
			return (
				<RenderLink
					row={params.row || params.data}
					rowIdentifier={params.rowIdentifier}
					tableId={params.tableId}
				/>
			);
		default:
			return <React.Fragment />;
	}
};

const getHealthCheckType = ({ isAlert, IsWarn }) => {
	if (isAlert) {
		return 'alert';
	}
	if (IsWarn) {
		return 'warning';
	}
};

export const RenderHealthCheck = (props: any): JSX.Element => {
	let { row, rowIdentifier } = props;

	row = props.data;

	const [isHelthCheckEnabled, handleHealthCheck] = useState(false);
	return (
		<Stack align="left">
			<StackItem position="center" align="center" isGrow>
				<Icon
					faAlias="faHeartbeat"
					type={getHealthCheckType(row)}
					onClick={() => handleHealthCheck(true)}
					isActive={true}
				></Icon>
			</StackItem>
			{isHelthCheckEnabled && (
				<HealthCheck
					handleOnClose={() => handleHealthCheck(false)}
					healthCheckId={row['HealthCheckID'] || 0}
				></HealthCheck>
			)}
		</Stack>
	);
};


const getAlignment = type => {
	switch (type?.toLowerCase()) {
		case 'number':
			return 'right';
		case 'price':
			return 'right';
		case 'qty':
			return 'right';
		case 'percentage':
			return 'right';
		case 'percentagefull':
			return 'right';
		case 'text':
		default:
			return 'left';
	}
};
export const RenderUpdateText = (props: any): JSX.Element => {
	const { row, rowIdentifier, type } = props;
	if (row.isEditEnabled) {

		return <RenderInput
			isUploadGrid={props?.isUploadGrid}
			isInlineEdit type={type} row={row} rowIdentifier={rowIdentifier} />;
	}
	return <RenderCell {...props} />;
};

export const RenderCellAg = (props: any): JSX.Element => {
	let rowIdentifier, tableId, row;
	row = props?.data;

	if (props.colDef.headerName === 'Group' || props.colDef.showRowGroup) {
		rowIdentifier = props.defaultRowGroupColumnDisplayOverride
			? props.defaultRowGroupColumnDisplayOverride
			: props.colDef.showRowGroup || props.node.field;
		tableId = props?.node?.rowGroupColumn?.colDef?.metaData?.AppTableID;
	} else {

		rowIdentifier = props.colDef.field;
		tableId = props.colDef?.metaData?.AppTableID;
	}

	// console.log(props);
	// const { row, rowIdentifier, type, tableId } = props;
	const column = useSelector(state => getColumnInfoByIndentifier(state, tableId, rowIdentifier));
	let type = column?.ColumnType;

	if (row.isEditEnabled && column?.IsEditableColumn && !props?.node?.group) {
		return (
			<RenderInput
				isUploadGrid={props.isUploadGrid}
				isInlineEdit
				align={getAlignment(type)}
				type={type}
				row={row}
				rowIdentifier={rowIdentifier}
				tableId={tableId}
			/>
		);
	}

	const getValue = () => {
		let value;
		switch (type) {
			case 'price':
				value =
					(row[rowIdentifier]?.hasOwnProperty('value') ? row[rowIdentifier].value : row[rowIdentifier]) !== 'null'
						? formatMoney(row[rowIdentifier]?.hasOwnProperty('value') ? row[rowIdentifier].value : row[rowIdentifier], true)
						: '';
				break;
			case 'qty':
				value =
					(row[rowIdentifier]?.hasOwnProperty('value') ? row[rowIdentifier].value : row[rowIdentifier]) !== 'null'
						? formatThousands(row[rowIdentifier]?.hasOwnProperty('value') ? row[rowIdentifier].value : row[rowIdentifier])
						: '';
				break;
			case 'percentage':
				value =
					(row[rowIdentifier]?.hasOwnProperty('value') ? row[rowIdentifier].value : row[rowIdentifier]) !== 'null'
						? formatPercentage(row[rowIdentifier])
						: '';
				break;
			case 'percentagefull':
				value =
					(row[rowIdentifier]?.hasOwnProperty('value') ? row[rowIdentifier].value : row[rowIdentifier]) !== 'null'
						? formatPercentage(row[rowIdentifier], true)
						: '';
				break;
			case 'date':
				value =
					(row[rowIdentifier]?.hasOwnProperty('value') ? row[rowIdentifier].value : row[rowIdentifier]) !== 'null'
						? formatDate(row[rowIdentifier]?.hasOwnProperty('value') ? row[rowIdentifier].value : row[rowIdentifier])
						: '-';
				break;
			case 'number':
			case 'text':
			default:
				value =
					(row[rowIdentifier]?.hasOwnProperty('value') ? row[rowIdentifier].value : row[rowIdentifier]) === 'null'
						? ''
						: row[rowIdentifier]?.hasOwnProperty('value') ? row[rowIdentifier].value : row[rowIdentifier];
				break;
		}

		if (props?.node?.group && !_.isNil(value) && row?.['ChildRowCount']) {
			return `${value} (${row?.['ChildRowCount']})`;
		}
		return value;
	};

	let component = (
		<Stack key={`${rowIdentifier}-${row[rowIdentifier]}`} align={getAlignment(type)}>
			<StackItem position="center" isGrow isEllipsisFull>
				{
					<Tooltip title={getValue()}>
						<span className="grid-row--text">{getValue()} </span>
					</Tooltip>
				}
			</StackItem>
		</Stack>
	);
	if (type === 'bit') {
		component = (
			<Stack align="left">
				<StackItem isGrow align="center" position="center" isGrow isEllipsisFull>
					<span className="grid-row--text">
						{getValue() && <Icon type="active" isActive faAlias="faCheck" />}
					</span>
				</StackItem>
			</Stack>
		);
	}
	if (type === 'text') {
		component = (
			<Stack key={`${rowIdentifier}-${row[rowIdentifier]}`} align={getAlignment(type)} >
				<StackItem position="center" isGrow isEllipsisFull>
					<Tooltip title={getValue()}>
						<span className="grid-row--text">{getValue()}</span>
					</Tooltip>
				</StackItem>
			</Stack>
		);
	}

	if (type === 'date') {
		component = (
			<Tooltip key={`${getValue()}`} title={getValue()}>
				<span className="grid-row--text">{getValue()} </span>
			</Tooltip>
		);
	}

	if (row[rowIdentifier]?.hasError || row[rowIdentifier]?.hasWarning) {
		return <Validation row={row} rowIdentifier={rowIdentifier} tableId={tableId} {...(_.isString(row[rowIdentifier]) ? {} : row[rowIdentifier])}>{component}</Validation>;
	}

	return component;
};
export const RenderCell = (props: any): JSX.Element => {
	const { row, rowIdentifier, type, tableId } = props;
	const column = useSelector(state => getColumnInfoByIndentifier(state, tableId, rowIdentifier));
	if (row.isEditEnabled && column?.IsEditableColumn) {
		return (
			<RenderInput
				isInlineEdit
				isUploadGrid={props.isUploadGrid}
				align={getAlignment(type)}
				type={type}
				row={row}
				rowIdentifier={rowIdentifier}
				tableId={tableId}
			/>
		);
	}

	const getValue = () => {
		switch (type) {
			case 'price':
				return formatMoney(row[rowIdentifier]?.hasOwnProperty('value') ? row[rowIdentifier].value : row[rowIdentifier], true) || 0;
			case 'qty':
				return formatThousands(row[rowIdentifier]?.hasOwnProperty('value') ? row[rowIdentifier].value : row[rowIdentifier]) || 0;
			case 'percentage':
				return formatPercentage(row[rowIdentifier]);
			case 'percentagefull':
				return formatPercentage(row[rowIdentifier], true);
			case 'date':
				return row[rowIdentifier]?.hasOwnProperty('value') ? row[rowIdentifier].value : row[rowIdentifier]
					? formatDate(row[rowIdentifier]?.hasOwnProperty('value') ? row[rowIdentifier].value : row[rowIdentifier])
					: '-';
			case 'number':
			case 'text':
			default:
				return row[rowIdentifier]?.hasOwnProperty('value') ? row[rowIdentifier].value : row[rowIdentifier];
		}
	};

	let component = (
		<Stack key={`${rowIdentifier}-${row[rowIdentifier]}`} align={getAlignment(type)}>
			<StackItem position="center" isGrow isEllipsisFull>
				{<Tooltip title={getValue()}>{getValue()} </Tooltip> || (
					<span className="grid-row--text">{row[rowIdentifier]?.hasOwnProperty('value') ? row[rowIdentifier].value : row[rowIdentifier]}</span>
				)}
			</StackItem>
		</Stack>
	);
	if (type === 'text') {
		component = (
			<Stack key={`${rowIdentifier}-${row[rowIdentifier]}`} align={getAlignment(type)}>
				<StackItem position="center" isGrow isEllipsisFull>
					<Tooltip title={row[rowIdentifier]?.hasOwnProperty('value') ? row[rowIdentifier].value : row[rowIdentifier]}>
						<span className="grid-row--text">{row[rowIdentifier]?.hasOwnProperty('value') ? row[rowIdentifier].value : row[rowIdentifier]}</span>
					</Tooltip>
				</StackItem>
			</Stack>
		);
	}

	if (type === 'date') {
		component = (
			<Tooltip key={`${rowIdentifier}-${row[rowIdentifier]}`} title={getValue()}>
				<span className="grid-row--text">{getValue()} </span>
			</Tooltip>
		);
	}

	if (row[rowIdentifier]?.hasError || row[rowIdentifier]?.hasWarning) {
		return <Validation row={row} rowIdentifier={rowIdentifier} tableId={tableId} {...(_.isString(row[rowIdentifier]) ? {} : row[rowIdentifier])}>{component}</Validation>;
	}

	return component;
};

export const RenderIcon = (props: any): JSX.Element => {
	const { row, rowIdentifier } = props;

	let faIcon: string;

	switch (rowIdentifier) {
		default:
			faIcon = 'faCheck';
	}

	return (
		<Stack align="left">
			<StackItem isGrow>{row[rowIdentifier] && <Icon isActive type="secondary" faAlias={faIcon} />}</StackItem>
		</Stack>
	);
};

export const RenderTiles = (props: any): JSX.Element => {
	const { row, rowIdentifier } = props;

	let faIcon: string;

	switch (rowIdentifier) {
		default:
			faIcon = 'faCheck';
	}

	const showValue = () => {
		const fruits = _.map(row?.[rowIdentifier] ?? [], (r) =>
				r?.Value);
		const text = fruits.join();
		return text
	}

	return (
		<Tooltip key={`${rowIdentifier}-${row[rowIdentifier]}`} title={showValue()}>
			<span className="grid-row--text">
				<Stack align="left" gutter="small" isWrap>
					<StackItem isGrow>
						{_.map(row?.[rowIdentifier] ?? [], (r) =>

							<Tag>
								{r?.Value}
							</Tag>)}

					</StackItem>
				</Stack>
			</span>
		</Tooltip>
	);
};


export const RenderLock = (props: any): JSX.Element => {
	const { row, rowIdentifier } = props;
	if (!row?.[rowIdentifier]) {
		return <> </>;
	}
	return (
		<Stack gutter="small" isVertical>
			<StackItem></StackItem>
			<StackItem isGrow>
				<Icon faAlias="faLock"></Icon>
			</StackItem>
		</Stack>
	);
};

export const RenderDate = (props: any): JSX.Element => {
	const { row, rowIdentifier, tableId, isInlineEdit } = props;
	const dispatch = useDispatch();
	const column = useSelector(state => getColumnInfoByIndentifier(state, tableId, rowIdentifier));
	const handleUpdateValue = updatedValue => {
		dispatch(
			dynamicGridSagaActions.updateRowData({
				tableId: Number(tableId),
				row,
				rowIdentifier,
				updatedValue: new Date(updatedValue),
				isInlineEdit: true
			})
		);
	};

	if (row.isEditEnabled && column?.IsEditableColumn) {
		return (
			<DatePicker
				name={rowIdentifier}
				selectedDate={row[rowIdentifier] ? moment(row[rowIdentifier]) : ''}
				handleClick={e => {
					handleUpdateValue(e.target.value);
				}}
			/>
		);
	}

	return (
		<Stack>
			<StackItem>
				<span className="grid-row--text">
					{row[rowIdentifier]?.hasOwnProperty('value') ? row[rowIdentifier].value : row[rowIdentifier]
						? formatDate(row[rowIdentifier]?.hasOwnProperty('value') ? row[rowIdentifier].value : row[rowIdentifier])
						: '-'}
				</span>
			</StackItem>
		</Stack>
	);
};

export const RenderLink = (props: any): JSX.Element => {
	const { row, rowIdentifier, tableId } = props;
	if (tableId == 23) {
		return (
			<Stack>
				<StackItem>
					<Link type="tertiary" route={`/orders/details/${row[rowIdentifier]}`}>
						{' '}
						{row[rowIdentifier]}{' '}
					</Link>
				</StackItem>
			</Stack>
		);
	}
	return <RenderCell {...props} />;
};

export const RenderLinkAg = (props: any): JSX.Element => {
	let rowIdentifier, tableId, row;

	row = props?.data;

	if (props.colDef.headerName !== 'Group') {
		rowIdentifier = props.colDef.field;
		tableId = props.colDef.metaData.AppTableID;
	} else {
		rowIdentifier = props.node.field;
		tableId = props.data.AoppTableID;
	}

	// console.log(props);
	// const { row, rowIdentifier, type, tableId } = props;
	const column = useSelector(state => getColumnInfoByIndentifier(state, tableId, rowIdentifier));
	let type = column?.ColumnType;
	if (tableId == 23) {
		return (
			<Stack>
				<StackItem>
					<Link type="tertiary" route={`/orders/details/${row[rowIdentifier]}`}>
						{' '}
						{row[rowIdentifier]}{' '}
					</Link>
				</StackItem>
			</Stack>
		);
	}
	return <RenderCell {...props} />;
};

const RenderCheckBox = (props: any) => {
	const dispatch = useDispatch();
	const [debounce, updateDebounce] = React.useState(null);
	const {
		row,
		rowIdentifier,
		tableId,
		isPaginated,
		isUploadGrid,
		params,
		isPivotMode,
	} = props;
	const gridMetaData = useSelector(state => getGridMetadata(state, tableId));
	const value = row?.[rowIdentifier]?.hasOwnProperty('value') ? row?.[rowIdentifier]?.value : row?.[rowIdentifier];

	const [inputValue, updateInputValue] = React.useState(value);
	const handleUpdate = parsedValue => {
		updateInputValue(parsedValue);
		clearTimeout(debounce);
		let debounceFn = setTimeout(
			() => {
				if (_.isEmpty(parsedValue)) {
					return;
				}
				dispatch(
					dynamicGridSagaActions.updateRowData({
						tableId: Number(tableId),
						row,
						rowIdentifier,
						isUploadGrid,
						updatedValue: parsedValue,
						isInlineEdit: true,
						isGroupNode: params?.node?.group,
						isPivotMode: isPivotMode
					})
				);
			},
			2000 || isPaginated ? 1000 : 100
		);
		updateDebounce(debounceFn);
	};

	React.useEffect(() => {
		return () => updateInputValue(value);
	}, [value]);

	React.useEffect(() => {
		let value = row?.[rowIdentifier]?.hasOwnProperty('value') ? row?.[rowIdentifier]?.value : row?.[rowIdentifier];
		updateInputValue(value);
	}, [row?.[rowIdentifier]]);

	if (row?.IsParent === 0 || !row.hasOwnProperty(rowIdentifier)) {
		return <RenderCell row={row} rowIdentifier={rowIdentifier} type={'number'} />;
	}

	const handleChange = async e => {
		if (props?.params?.cellRendererFramework) {
			const result = await props?.params?.cellRendererFramework(params)
			if (result.status === 200) {
				handleUpdate(e.target.checked, {});
				e.stopPropagation();
			}
		} else {
			handleUpdate(e.target.checked, {});
			e.stopPropagation();
		}
	}

	const hasGridEditOperation = gridMetaData?.dataProviderOptions?.isGridEditDisabledOverride;
	const isLocked = row?.IsReadOnly || row?.OrderStatusID === 2 || hasGridEditOperation || false;
	return (
		<div className="render-input-right">
			<Validation row={row} rowIdentifier={rowIdentifier}  {...row[rowIdentifier]}>
				<Checkbox checked={inputValue}
					onChange={handleChange}
					disabled={isLocked}
				/>
			</Validation>
		</div>
	);
};
