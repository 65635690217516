// @ts-nocheck
import Divider from 'atoms/divider/divider';
import Icon from 'atoms/icons/icon';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ThetaGridPaginated from 'templates/thetaGridPaginated';

import * as constants from './constants';
import { triggerDeleteColumnAlias } from './sagaActions';
import CreateColumnAliasInfo from './components/createColumnAliasInfo';
import Breadcrumbs from 'atoms/breadcrumbs/breadcrumbs';
import { REQUESTS } from 'utils/requests';
import { isAppAliasSettingDelete, isAppSettingsRead } from '../../../models/user/selector';
import GridActionBar from 'atoms/GridActionBar';

export interface TProps {
	title?: string;
}
const AVAILABILITY_DELETE_MODAL = 'availabilityDeleteModal';

const ColumnAliases: React.FunctionComponent<TProps> = (props: TProps) => {
	const [openFilter, setOpenFilter] = React.useState<boolean>();
	const dispatch = useDispatch();
	const hasDeleteAccess = useSelector(isAppAliasSettingDelete);

    const hasSearchFilters = useSelector(state => dynamicGridSelectors.hasSearchFilters(state, constants.COLUMNS_TABLE_ID));

	React.useEffect(() => {
        if (openFilter === undefined) {
            setOpenFilter(hasSearchFilters);
        }
    }, [hasSearchFilters]);

	const handleFilterClick = React.useCallback(() => {
        setOpenFilter(!openFilter);
    }, [openFilter]);

	const handleRefreshClick = React.useCallback(() => {
		dispatch(
			dynamicGridSagaActions.refreshGridData({
				tableId: constants.COLUMNS_TABLE_ID,
			})
		);
	}, []);



	const gridConfig = {
		enableSelection: true,
		enableSorting: true,
		enableMultiSort: true,
		selectionCallBack: () => { },
		customActions: hasDeleteAccess && {
			width: 170,
			enabled: true,
			component: ({ row }) => {
				return (
					<Stack gutter="small" align="center" position='center'>
						<StackItem>
							<Icon
								isActive
								type="secondary"
								size="small"
								disabled={row?.IsReadOnly}
								onClick={() => {
									dispatch(triggerDeleteColumnAlias({
										isSelectAll: false,
										ColumnName: row.id
									}))
								}}
								faAlias={'faTrash'}
							/>
						</StackItem>
					</Stack>
				);
			}
		},
	};

	const templateConfig = React.useMemo(
		() => ({
			gridConfig,
			tableId: constants.COLUMNS_TABLE_ID,
			isFilterEnabled: openFilter === undefined ? false : openFilter,
			dataProviderOptions: {
				type: 'POST',
				version: 2,
				pathParams: { tableId: constants.COLUMNS_TABLE_ID },
				...REQUESTS.POST.GET_COLUMN_ALIAS,
			},
			selectRowKey: 'AppColumnID'
		}),
		[]
	);



	return (
		<React.Fragment>
			<Stack isVertical gutter="small">
				<StackItem>
					<GridActionBar>
						<Breadcrumbs crumbs={props.breadcrumbs} />
					</GridActionBar>

				</StackItem>
				<StackItem isStretch isGrow>
					<Sheet isPage>
						<Stack isVertical gutter="small">
							<StackItem align='right'>
								<Stack gutter="medium">
									<StackItem isGrow />

									<StackItem position='center'>
										<Icon
											isActive
											type="secondary"
											faAlias="faRecycle"
											onClick={handleRefreshClick}
										/>
									</StackItem>

									<StackItem>
										<CreateColumnAliasInfo />
									</StackItem>

								</Stack>
							</StackItem>
							<StackItem isStretch>
								<Divider />
							</StackItem>

							<StackItem isStretch isGrow>
								<Stack isVertical>
									<StackItem isGrow>
										<SheetBody>
											<ThetaGridPaginated key={constants.COLUMNS_TABLE_ID} 
											config={templateConfig} />
										</SheetBody>
									</StackItem>
								</Stack>
							</StackItem>
						</Stack>
					</Sheet>
				</StackItem>
			</Stack>



		</React.Fragment>
	);
};

export default ColumnAliases;
