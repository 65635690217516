// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import * as constants from './constants';
import { TReducer } from './types';
import moment from 'moment';

const checkDbHealth = () => {
	let timeStamp = localStorage.getItem('dbHealthTimestamp');
	return timeStamp && moment().diff(moment(timeStamp)) <= 3600000;
};

export const initialState: TReducer = {
	isActive: false,
	isInvalidUser: false,
	isLoggedOutUser: false
};

const slice = createSlice({
	initialState,
	name: `${constants.MODEL_NAME}`,
	reducers: {
		updateAppState(
			state,
			{ payload: { isActive, isInvalidUser, isLoggedOutUser, isNewVersionAvailable, hasNetworkError } }
		) {

			return {
				...state,
				isActive: isActive ?? state.isActive,
				hasNetworkError: hasNetworkError ?? state.hasNetworkError,
				isInvalidUser: isInvalidUser ?? state.isInvalidUser,
				isLoggedOutUser: isLoggedOutUser ?? state.isLoggedOutUser,
				isNewVersionAvailable: isNewVersionAvailable ?? state.isNewVersionAvailable
			};
		}
	}
});

export default slice;

export const { updateAppState } = slice.actions;
