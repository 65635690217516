// @ts-nocheck
import * as React from 'react';
import classNames from 'classnames';
import './menu.css';

interface Props {
  children: React.ReactNode;
}
const Menu = (props: Props) => {
  const { children } = props;
  const classes: any = classNames(['menu']);
  return <div className={classes}>{children}</div>;
};

export default Menu;
