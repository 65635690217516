// @ts-nocheck
import Button from 'atoms/button/button';
import Loader from 'atoms/loader';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as groupModalSelectors from 'organisms/groupModal/selector';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import { REQUESTS } from 'utils/requests';
import {PLANOGRAM_ADD_STORES_TABLE_ID} from './constants';
import { useDispatch } from 'react-redux';
import { updatePlanogramStores } from './sagaActions';
import { createFiltersFromQueries } from 'models/dynamicGrid/query';
import { getGridFilters } from 'models/dynamicGrid/selector';

const SelectStores = (props) => {
    const { hideModal, handleNextClick, goBack, planogramId } = props;
    const name = 'selectedMembers';
    const isLoading = useSelector(state => groupModalSelectors.isLoading(state));
    const dispatch = useDispatch();
    const gridConfig = {
        enableSelection: true,
        enableSorting: true,
        selectionCallBack: () => { }
    };
	const filters = useSelector(state => getGridFilters(state, PLANOGRAM_ADD_STORES_TABLE_ID));
   
    let selectAllSearch =  JSON.stringify({
        filter: createFiltersFromQueries(filters?.search)
    })
    const selectedMembers = useSelector(state => dynamicGridSelectors.getTableSelection(state, PLANOGRAM_ADD_STORES_TABLE_ID));
    const handleAddStores = (params) =>{
        dispatch(updatePlanogramStores({
            storeIds: selectedMembers?.selectedRowKeys,
             planogramId,
            isSelectAll: selectedMembers?.isSelectAll,
            selectAllSearch
        }))

    }
    const templateConfig = {
        gridConfig,
        isFilterEnabled: true,
        tableId: PLANOGRAM_ADD_STORES_TABLE_ID,
        dataProviderOptions: {
            type: 'POST',
            version: 2,
            ...REQUESTS.POST.GET_GRID_DETAILS_V2,
            pathParams: { tableId: PLANOGRAM_ADD_STORES_TABLE_ID },
            multiSelectFieldGroups: {
                PlanogramID: planogramId,
                HasAttachedStores: false
            },
            body: {
             
                fieldGroups: {
                    HasAttachedStores: false,
                    PlanogramID: planogramId
                }
            }
        },
        selectRowKey: 'StoreID'
    };

    const [ref, handleUpdateRef] = useState(null);

    const renderLoader = () => {
        return (
            <StackItem isGrow isStretch align="center">
                <Loader />
            </StackItem>
        );
    };

    return (
        <>
            <ModalBody onDomRef={handleUpdateRef}>
                <Stack isVertical gutter="medium">
                    {isLoading ? renderLoader() : <ThetaGridPaginated parent={ref} config={templateConfig} />}
                </Stack>
            </ModalBody>
            <ModalActions>
                <Stack gutter="medium">
                    {/* <StackItem isGrow isStretch>
                        <Stack align="left" gutter="small">
                            <StackItem>
                                <Button
                                    disabled={isLoading}
                                    isSecondary
                                    name="Reset Kit Parameters"
                                    handleClick={goBack}
                                />
                            </StackItem>
                        </Stack>
                    </StackItem> */}
                    <StackItem isGrow>
                        <Stack align="right" gutter="small">
                            <StackItem isGrow>
                                <Button disabled={isLoading} isSecondary name="CANCEL" handleClick={hideModal} />
                            </StackItem>
                            <StackItem isStretch align="right">
                                <Button
                                    disabled={!selectedMembers?.selectedRowKeys?.length || isLoading}
                                    name={'Update Stores'}
                                    handleClick={handleAddStores}
                                />
                            </StackItem>
                        </Stack>
                    </StackItem>
                </Stack>
            </ModalActions>
        </>
    );
};

export default SelectStores;
