// @ts-nocheck
import BodyText from 'atoms/bodyText/bodyText';
import Dropdown from 'atoms/dropdown/dropdown';
import Modal from 'atoms/modal/modal';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import _ from 'lodash';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalActions from '../common/modalActions';
import ModalHeader from '../common/modalHeader';
import * as sagaActions from '../sagaActions';
import * as selectors from '../selectors';
import Loader from 'atoms/loader';
import { KEYS } from '../actionBar/constants';

interface TProps {
	hideModal: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
	selectedTab: string;
}

const getCurrentShippingMethod = (selectectedOrders: any[], orders: any, selectedTab: any) => {
	let fileteredOrders: any = [];
	selectectedOrders.forEach(singleSelection => {
		let filteredOrder = _.filter(orders, function (singleOrder) {
			return singleOrder[KEYS[selectedTab]] === singleSelection;
		});
		fileteredOrders.push(filteredOrder[0]);
	});
	let currentShippings = fileteredOrders && fileteredOrders.map(order => order?.ShipMethodDescription);
	currentShippings = _.uniq(currentShippings);
	return currentShippings.join(' , ');
};

const ModalContent = ({
	selectedOrders,
	orders,
	dropdownTypes,
	setSelectedOption,
	selectedOption,
	selectedTab,
	orderTotalCount,
	isSelectAll
}: any) => {
	return (
		<Stack isVertical gutter="medium">
			<StackItem>
				<BodyText>
					Change Shipping Method on {isSelectAll ? orderTotalCount : selectedOrders.length} select orders.
				</BodyText>
			</StackItem>
			<StackItem>
				<Stack gutter="medium">
					<StackItem isStretch>
						<BodyText>Current:</BodyText>
					</StackItem>
					<StackItem isStretch>
						<BodyText>{getCurrentShippingMethod(selectedOrders, orders, selectedTab)}</BodyText>
					</StackItem>
				</Stack>
			</StackItem>
			<StackItem>
				<Stack gutter="medium">
					<StackItem isStretch>
						<BodyText>New:</BodyText>
					</StackItem>
					<StackItem>
						<Dropdown
							placeholder={'Select Item'}
							options={dropdownTypes}
							updateHandler={setSelectedOption}
							selectedOption={selectedOption}
						/>
					</StackItem>
				</Stack>
			</StackItem>
		</Stack>
	);
};

const ThetaUpdateShippingMethodModal = (props: TProps) => {
	const { hideModal, selectedTab } = props;
	const dispatch = useDispatch();
	const dropdownOptionTypes = useSelector(state => selectors.getOrdersShipMethod(state)) || [];
	const dropdownTypes = dropdownOptionTypes.map(order => {
		return {
			displayName: order.ShipMethodDescription,
			value: order.ShipMethodID
		};
	});
	const [selectedOption, setSelectedOption] = React.useState(dropdownTypes[0]);

	const selectedOrders = useSelector(state => dynamicGridSelectors.getTableSelection(state, Number(selectedTab)));
	const orders = useSelector(state => dynamicGridSelectors.getGridData(state, Number(selectedTab)));
	const orderTotalCount = useSelector(state => dynamicGridSelectors.getTotalCount(state, Number(selectedTab)));

	React.useEffect(() => {
		const reqData = {
			orderIds: selectedOrders?.selectedRowKeys,
			tableId: Number(selectedTab)
		};
		dispatch(sagaActions.getShipMethods(reqData));
	}, [dispatch]);

	const handleUpdateOrder = (e: any) => {
		const data = {
			orderIds: selectedOrders?.selectedRowKeys,
			shipMethod: selectedOption.value,
			tableId: Number(selectedTab)
		};
		dispatch(sagaActions.updateShipMethods(data, Number(selectedTab)));
	};

	const getUpdateDisabledStatus = () => {
		return !selectedOption || !selectedOption.value;
	};
	const isLoading = useSelector(state => selectors.getLoadingStatus(state));

	return (
		<Modal hideModal={hideModal} height={400}>
			<Sheet isInset>
				<SheetBody>
					<Stack isVertical gutter="small">
						<StackItem>
							<ModalHeader title={'Change Shipping Method'} />
						</StackItem>
						<StackItem isStretch isGrow>
							{isLoading ? (
								<Stack isVertical>
									<StackItem isGrow isStretch align="center">
										<Loader />
									</StackItem>
								</Stack>
							) : (
								<ModalContent
									selectedOrders={selectedOrders?.selectedRowKeys}
									orders={orders}
									dropdownTypes={dropdownTypes}
									selectedOption={selectedOption}
									setSelectedOption={setSelectedOption}
									selectedTab={selectedTab}
									orderTotalCount={orderTotalCount}
									isSelectAll={selectedOrders?.isSelectAll}
								/>
							)}
						</StackItem>
						<StackItem isStretch>
							<ModalActions
								secondaryButtonName={'Close'}
								primaryButtonName={'Update'}
								primaryButtonClick={handleUpdateOrder}
								secondaryButtonClick={hideModal}
								getPrimaryDisabledStatus={getUpdateDisabledStatus}
								isLoading={isLoading}
							/>
						</StackItem>
					</Stack>
				</SheetBody>
			</Sheet>
		</Modal>
	);
};

export default ThetaUpdateShippingMethodModal;
