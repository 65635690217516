export const REQUESTS = {
  GET: {
    GET_COLUMN_ALIAS_CONFIG: {
      url: "column/config",
      service: "adminSettings",

    },
    GET_PLANOGRAM_TYPES: {
      url: 'planogramTypes',
      service: 'lookup'
    },
    GET_UPLOAD_METADATA: {
      url: '{{tableId}}/templateData',
      service: 'upload',
    },
    GET_ALL_COLUMNS: {
      url: "column",
      service: "adminSettings",

    },
    GET_AD_TABS: {
      url: "lookup",
      service: "advertising",

    },
    GET_AD_DETAILS: {
      url: "{{adId}}",
      service: "advertising",

    },
    GET_BOOKMARK_DETAILS: {
      url: "{{bookmarkId}}",
      service: "bookmark",

    },
    GET_BOOKMARKS: {
      url: "",
      service: "bookmark",

    },
    GROUP_ATTRIBUTES: {
      url: "attributes/{{planningGroupId}}",
      service: "demandPlanning",

    },
    PLANNING_GROUP_DROPDOWN: {
      url: "groups/{{planningDimensionId}}",
      service: "demandPlanning",

    },
    VALIDATE_IMPORT: {
      url: "validateImport",
      service: "demandPlanning",

    },
    GET_TAGS: {
      url: "tags",
      service: "demandPlanning",

    },

    GET_TABLE_COLUMNS: {
      url: "columns/{{tableId}}",
      service: "grid",

    },
    GET_TABLE_UPLOAD_COLUMNS: {
      url: "columns/{{tableId}}",
      service: "upload",
    },
    GET_CUSTOM_VIEW: {
      url: "custom/{{TableID}}",
      service: "grid",

    },
    GET_COMPONENT_USER_SETTINGS: {
      url: "component/usersettings/{{tableId}}",
      service: "grid",

    },
    GET_USER_GRID_DATA: {
      url: "v2/{{tableId}}",
      service: "grid",

    },
    GET_TABLE_COLUMNS_MONGO: {
      url: "v2/columns/{{tableId}}",
      service: "grid",

    },
    GET_KITS: {
      url: "",
      service: "kits",

    },
    GET_FIELD_LOOKUP: {
      url: "field",
      service: "lookup",

    },
    GET_CUSTOMERS: {
      url: "customers",
      service: "lookup",

    },
    GET_DIMENSIONS: {
      url: "dimensions",
      service: "lookup",

    },
    GET_ERP_ENABLED: {
      url: "erp/enabled",
      service: "misc",

    },
    GET_WAREHOUSES: {
      url: "warehouses",
      service: "misc",

    },
    GET_HEALTHCHECK: {
      url: "healthcheck/{{healthCheckId}}/{{healthCheckTypeId}}",
      service: "misc",

    },
    GET_PRESET_ORDER_INFO: {
      url: "preset/info",
      service: "orders",
    },
    GET_ORDER_CLONE_TYPES: {
      url: "clone/types/{{cloneLookupType}}",
      service: "orders",

    },
    GET_ORDER_STATUS: {
      url: "status",
      service: "orders",

    },
    GET_ORDER_BATCH: {
      url: "batch/{{UserName}}",
      service: "orders",

    },
    GET_SHIPMENT_OPTIMIZE: {
      url: "ship-method/optimize/{{userName}}",
      service: "orders",

    },
    GET_PROCESS_STATUS: {
      url: "process/status/{{statusId}}",
      service: "orders",

    },
    GET_PARAMETERS_LIST: {
      url: "list",
      service: "parameters",

    },
    GET_PARAMETER_DETAILS: {
      url: "details",
      service: "parameters",

    },
    GET_PARAMETERS_BY_PARAMETER_GROUP: {
      url: "groups",
      service: "parameters",

    },
    GET_ORDERS_OVERRIDE_PARAMS: {
      url: "orders",
      service: "parameters",

    },
    GET_STORE_SCHEDULE: {
      url: "{{scheduleId}}",
      service: "storeschedule",

    },
    GET_STORE_SCHEDULES: {
      url: "",
      service: "storeschedule",

    },
    HANDLE_FETCH_NOTIFICATIONS: {
      url: "notifications",
      service: "subscription",

    },
    GET_USER_CONFIG: {
      url: "settings",
      service: "user",

    },
    GET_WEB_APP_CONFIG: {
      url: "version",
      service: "webapp",

    },
  },
  POST: {
    ADD_COLUMN_CONFIG: {
      url: "column/config",
      service: "adminSettings",

    },
    ADD_COLUMN_ALIAS: {
      url: "column/alias",
      service: "adminSettings",

    },
    GET_GRID_UPLOAD_DETAILS: {
      url: 'details/{{tableId}}',
      service: 'upload',
    },
    REMOVE_COLUMN_ALIAS: {
      url: "column/alias/remove",
      service: "adminSettings",

    },
    GET_COLUMN_ALIAS: {
      url: "column/alias/list",
      service: "adminSettings",

    },
    CREATE_AD: {
      url: "",
      service: "advertising",

    },
    ADD_AD_TYPE: {
      url: "adType",
      service: "advertising",

    },
    CREATE_PRICING_PARAMS: {
      url: "pricing/params",
      service: "advertising",

    },
    GET_MULTI_SELECT_OPTIONS: {
      url: "multiselect/{{tableId}}",
      service: "grid",
    },
    GET_MULTI_SELECT_OPTIONS_ACL: {
      url: "aclGrid/multiselect/{{tableId}}",
      service: "acl",
    },
    GET_PRICING_PARAMS: {
      url: "pricing/params/list/{{AdTempID}}",
      service: "advertising",

    },
    ROLLBACK_AD: {
      url: "rollback",
      service: "advertising",

    },
    GET_AVAILABILITY_INFO: {
      url: "info",
      service: "availability",

    },
    CREATE_UPDATE_BOOKMARK: {
      url: "",
      service: "bookmark",

    },
    GROUP_INFO: {
      url: "groupInfo/{{planningDimensionId}}",
      service: "demandPlanning",

    },
    GET_UPLOADED_DEMAND: {
      url: "staging/demand",
      service: "demandPlanning",

    },
    SAVE_STAGED_DEMAND: {
      url: "staging/submit",
      service: "demandPlanning",

    },
    ADD_DEMAND_PLANNING_TAG: {
      url: "tags",
      service: "demandPlanning",

    },
    MULTI_SELECT_SCRIPT: {
      url: "multiselect/script",
      service: "grid",

    },
    GET_GRID_DETAILS: {
      url: "details/{{tableId}}",
      service: "grid",

    },
    GET_STAGING_GRID: {
      url: "staging/{{tableId}}",
      service: "grid",

    },
    GET_FILTER_DATA: {
      url: "filter/{{tableId}}",
      service: "grid",

    },
    EXPORT_GRID_DATA: {
      url: "export/{{tableId}}",
      service: "grid",

    },
    DELETE_ROW_DATA: {
      url: "row",
      service: "grid",

    },
    DELETE_UPLOAD_STAGING_ROW_DATA: {
      url: "row",
      service: "upload",

    },
    GET_GRID_DETAILS_V2: {
      url: "v2/details/{{tableId}}",
      service: "grid",

    },
    GET_MONGO_GRID_DETAILS_V2: {
      url: "aclGrid/details/{{tableId}}",
      service: "acl",
    },
    ADD_GROUP: {
      url: "",
      service: "groups",

    },
    CREATE_KIT: {
      url: "",
      service: "kits",

    },
    SEND_MAIL: {
      url: "send",
      service: "mailer",

    },
    UPLOAD_CSV: {
      url: "upload",
      service: "misc",

    },
    GET_ORDER_SHIPPING_METHODS: {
      url: "shipMethods/list",
      service: "orders",

    },
    GET_ORDER_SPLIT_PARAMETERS: {
      url: "split/params",
      service: "orders",

    },
    SPLIT_ORDERS: {
      url: "split",
      service: "orders",

    },
    DELETE_ORDER: {
      url: "bulk/delete",
      service: "orders",

    },
    GET_ORDER_BULK_EDIT_REVIEW: {
      url: "edit/bulk/review",
      service: "orders",

    },
    GET_ORDERS_TABLE_SUMMARY: {
      url: "summary",
      service: "orders",

    },
    POST_ORDER_BULK_EDIT: {
      url: "edit/bulk",
      service: "orders",

    },
    GET_ORDER_KITS: {
      url: "kits",
      service: "orders",

    },
    GET_DISTRIBUTE_SKUS: {
      url: "distribute/sku/{{userName}}",
      service: "orders",

    },
    POST_ORDER_CLONE: {
      url: "clone",
      service: "orders",

    },
    POST_ORDER_BATCH: {
      url: "batch",
      service: "orders",
    },
    POST_DISTRIBUTE_AVAILABILITY: {
      url: "distribute",
      service: "orders",

    },
    PUBLISH_ORDERS: {
      url: "publish",
      service: "orders",

    },
    GET_SHIPMENT_STORES: {
      url: "ship-method/stores/{{userName}}",
      service: "orders",

    },
    GET_SHIPMENT_SKU: {
      url: "ship-method/sku/{{userName}}",
      service: "orders",

    },
    POST_SHIPMENT_OPTIMIZE: {
      url: "ship-method/optimize",
      service: "orders",

    },
    ADD_PROCESS_STATUS: {
      url: "process/status",
      service: "orders",

    },
    DISTRIBUTE_AVAILABILITY_ASYNC: {
      url: "distribute/async",
      service: "orders",

    },
    CREATE_ORDER: {
      url: "",
      service: "orders",

    },
    ADD_PARAMETER: {
      url: "details",
      service: "parameters",

    },
    ADD_PARAMETER_GROUP: {
      url: "groups",
      service: "parameters",

    },
    TRIGGER_WEATHER_DATA_FORE_CAST: {
      url: "weatherDataForecast",
      service: "pipeline",

    },
    TRIGGER_WEATHER_HISTORIC: {
      url: "weatherDataHistory",
      service: "pipeline",

    },
    POST_STORE_SCHEDULE: {
      url: "",
      service: "storeschedule",

    },
    GET_VALID_STORES: {
      url: "store/{{customerId}}",
      service: "storesku",

    },
    GET_VALID_SKUS: {
      url: "sku/{{customerId}}",
      service: "storesku",

    },
    SOCKET_EVENT_CALLBACK: {
      url: "callback",
      service: "subscription",

    },
    SOCKET_EVENT_CALLBACK_PIPELINE: {
      url: "callback/pipeline",
      service: "subscription",

    },
    HANDLE_UPDATE_NOTIFICATION: {
      url: "callback/notify",
      service: "subscription",

    },
    UPDATE_WEB_APP_CONFIG: {
      url: "version",
      service: "webapp",

    },
    GET_STORE_SCHEDULE_DETAILS: {
      url: "{{scheduleId}}/details",
      service: "storeschedule",

    },
    GET_SAS_TOKEN: {
      url: "getsastoken",
      service: "misc",

    },
  },
  PUT: {
    UPDATE_COLUMN_ALIAS_CONFIG: {
      url: "column/config",
      service: "adminSettings",

    },
    UPLOAD_STAGING_VALIDATE: {
      url: 'validate',
      service: 'upload',
    },

    GRID_UPLOAD_STAGING: {
      url: "staging",
      service: "upload",
    },
    GRID_UPLOAD_SUBMIT: {
      url: "submit",
      service: "upload",
    },
    UPDATE_COLUMN_ALIAS: {
      url: "column/alias",
      service: "adminSettings",

    },
    UPDATE_AD_TYPE: {
      url: "adType",
      service: "advertising",

    },
    UPDATE_PRICING_PARAMS: {
      url: "pricing/params/{{AdTempID}}",
      service: "advertising",

    },
    APPLY_BOOKMARK: {
      url: "{{bookmarkId}}/apply",
      service: "bookmark",

    },
    UPDATE_DEMAND: {
      url: "updateDemand",
      service: "demandPlanning",

    },
    UPDATE_DEMAND_PLANNING_GROUPS: {
      url: "groupAttributeDetail",
      service: "demandPlanning",

    },
    DELETE_DEMAND_PLANNING_GROUP: {
      url: "deleteGroup",
      service: "demandPlanning",

    },
    UPDATE_PLAN_QTY: {
      url: "approvePlan",
      service: "demandPlanning",

    },
    UPDATE_STAGING: {
      url: "staging",
      service: "demandPlanning",

    },
    IMPORT_DEMAND: {
      url: "staging/upload",
      service: "demandPlanning",

    },
    UPDATE_DEMAND_PLANNING_TAG: {
      url: "tags",
      service: "demandPlanning",

    },

    DELETE_BLOB_DATA: {
      url: "blob",
      service: "grid",

    },
    RESET_TABLE_COLUMNS: {
      url: "columns/reset/{{tableId}}",
      service: "grid",

    },
    UPDATE_CUSTOM_VIEW: {
      url: "columns/{{tableId}}",
      service: "grid",

    },
    UPDATE_ROW_DATA: {
      url: "row",
      service: "grid",

    },
    UPDATE_UPLOAD_ROW_DATA: {
      url: "row",
      service: "upload",

    },
    UPDATE_COLUMN_CONFIG: {
      url: "column",
      service: "grid",
    },
    UPDATE_COMPONENT_USER_SETTINGS: {
      url: "component/usersettings/{{tableId}}",
      service: "grid",

    },
    UPDATE_USER_GRID_DATA: {
      url: "v2/{{tableId}}",
      service: "grid",

    },
    TOGGLE_AG_GRID: {
      url: "v2/ag/toggle",
      service: "grid",

    },
    UPDATE_USER_COLUMN_VIEW: {
      url: "v2/columns/{{tableId}}",
      service: "grid",

    },
    UPDATE_GRID_COLUMN_MONGO: {
      url: "v2/column/{{tableId}}",
      service: "grid",

    },
    RESET_TABLE_COLUMNS_MONGO: {
      url: "v2/reset/{{tableId}}",
      service: "grid",

    },
    UPDATE_GROUP: {
      url: "",
      service: "groups",

    },
    BULK_UPDATE_ORDER_SHIPPING_METHODS: {
      url: "shipMethods",
      service: "orders",

    },
    UPDATE_ORDER_SPLIT_PARAMS: {
      url: "split/params/{{OrderSplitID}}",
      service: "orders",

    },
    UPDATE_PO_DATE: {
      url: "podate",
      service: "orders",

    },
    PUT_ORDER_KITS: {
      url: "kits/qty/{{orderKitId}}",
      service: "orders",

    },
    UPDATE_DISTRIBUTE_SKUS: {
      url: "distribute/sku/{{distributeRowId}}",
      service: "orders",

    },
    PUT_SKU_QTY: {
      url: "skuqty/{{OrderDetailID}}",
      service: "orders",

    },
    UPDATE_ORDER_STATUS: {
      url: "status",
      service: "orders",

    },
    TOGGLE_ORDER_STATE: {
      url: "state",
      service: "orders",

    },
    UPDATE_OPTIMIZE_DESIRED_QTY: {
      url: "optimize/store/{{orderId}}",
      service: "orders",

    },
    PUT_ORDERS: {
      url: "{{OrderDetailVarietyID}}",
      service: "orders",

    },
    UPDATE_PARAMETER_DETAILS: {
      url: "details/{{parameterDetailId}}",
      service: "parameters",

    },
    UPDATE_PARAMETER_GROUPS: {
      url: "groups/{{parameterMasterId}}",
      service: "parameters",

    },
    UPDATE_STORE_SCHEDULE_BULK: {
      url: "{{scheduleId}}/bulk",
      service: "storeschedule",

    },
    UPDATE_STORE_SCHEDULE_DETAILS: {
      url: "{{scheduleId}}/details",
      service: "storeschedule",

    },
    UPDATE_STORE_SCHEDULE: {
      url: "{{scheduleId}}",
      service: "storeschedule",

    },
    UPDATE_USER_CONFIG: {
      url: "settings",
      service: "user",

    },
  },
  DELETE: {
    REMOVE_COLUMN_CONFIG: {
      url: "column/config",
      service: "adminSettings",

    },
    DELETE_AD: {
      url: "{{adId}}",
      service: "advertising",

    },
    DELETE_AD_TYPE: {
      url: "adType/{{adTypeId}}",
      service: "advertising",

    },
    DELETE_BOOKMARK: {
      url: "{{bookmarkId}}",
      service: "bookmark",

    },
    DELETE_STAGING: {
      url: "staging",
      service: "demandPlanning",

    },
    DELETE_GROUP: {
      url: "{{groupID}}",
      service: "groups",

    },
    DELETE_STORE_SCHEDULE: {
      url: "{{scheduleId}}",
      service: "storeschedule",

    },
  },
  PATCH: {
    CREATE_UPDATE_BOOKMARK: {
      url: "{{bookmarkId}}",
      service: "bookmark",

    },
  },
};
