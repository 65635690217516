// @ts-nocheck
import * as React from 'react';
import Heading from 'atoms/heading/heading';
import Icon from 'atoms/icons/icon';
import Sheet from 'atoms/sheet/sheet';
import { refreshGridData } from 'models/dynamicGrid/sagaActions';
import { getGridFilters, getGridMetadata, isLoading } from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import ExportGrid from 'organisms/exportGrid';
import ThetaMenu from 'organisms/thetaMenu/thetaMenu';
import { useSelector } from 'react-redux';
import {
	CSV_UPLOAD_TABLE_ID,
	DEMAND_MATRIX_TABLE_ID,
	DEMAND_PLANNING_MENU,
	DEMAND_PLANNING_MENU_VALUES,
	DEMAND_PLANNING_TABLE_ID,
	DEMAND_PLANNING_TABS
} from './constants';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import Divider from 'atoms/divider/divider';
import { getDispatch } from 'reducers';
import Tabs from 'atoms/tabs/tabs';
import Tab from 'atoms/tab/tab';
import CreateDemandMatrix from './createDemandMatrix';
import SideCar from 'molecules/sidecar/sidecar';
import UploadData from 'organisms/uploadData';
import { useNavigate } from 'react-router';
import * as qs from 'qs';
import GridCsvUpload from 'organisms/uploadData/gridCsvUpload';
import Button from 'atoms/button/button';
import { triggerSubmitDemand } from './sagaActions';
import { isLoadingEnabled } from 'models/loaders/selectors';
import { resetTableColumnsAg } from 'models/tableColumns/sagaActions';
import { REQUESTS } from 'utils/requests';
import { isDemandChangeReadWrite, isDemandChangeWrite, isDemandReportingReadWrite, isDemandReportingWrite } from '../../models/user/selector';
import { getDemandPlanningTabs } from './selector';
import GridActionBar from 'atoms/GridActionBar';
import { useEffect } from 'react';
import { isNewUploadEnabled } from 'models/user/selector';

const DemandPlanning = () => {
	const tableId = DEMAND_PLANNING_TABLE_ID;
	const isLoadingGrid = useSelector(state => isLoading(state, tableId));
	const dispatch = getDispatch();
	const demandPlanningTabs = useSelector(getDemandPlanningTabs);
	const hasDemandChangeWrite = useSelector(isDemandChangeWrite);
	const hasDemandReportingWrite = useSelector(isDemandReportingWrite);


	const [isUploadEnabled, toggleUpload] = React.useState<boolean>(false);
	const navigate = useNavigate();
	const [isMatrixCreator, setMatrixCreator] = React.useState(true);
	const [isEmptyMatrix, setEmptyMatrix] = React.useState(false);
	const query = qs.parse(window.location.search.replace('?', ''));

	const getActiveColumn = () => {
		switch (query.selectedTab) {
			case 'matrix':
				return demandPlanningTabs?.[1];
			default:
				return demandPlanningTabs?.[0];
		}
	};
	React.useEffect(() => {
		if (!getActiveColumn()) {
			updateActiveTable({ value: demandPlanningTabs?.[0]?.tableId })
		}
		setActiveTable(getActiveColumn() ? getActiveColumn() : demandPlanningTabs?.[0]);
	}, [query]);

	const [activeTable, setActiveTable] = React.useState<TTableTab>(getActiveColumn());

	const [isInitialized, setInitialized] = React.useState(false);
	const filters = useSelector(state => getGridFilters(state, activeTable?.tableId));
	const gridMetaData = useSelector(state => getGridMetadata(state, activeTable?.tableId));
	const isLoadingGridSettings = useSelector(state => isLoadingEnabled(state, 'GRID_FILTERS_' + activeTable?.tableId));
	const gridMeta = useSelector(state => getGridMetadata(state, activeTable?.tableId));



	React.useEffect(() => {
		if (
			!isLoadingGrid &&
			!filters?.search?.length &&
			activeTable?.tableId === DEMAND_MATRIX_TABLE_ID
		) {
			setMatrixCreator(true);
			setEmptyMatrix(true);
		}
		 else if (!isLoadingGridSettings && !isInitialized && filters?.search?.length) {
			// alert(isLoadingGridSettings)
			setMatrixCreator(false);
			setEmptyMatrix(false);
			setInitialized(true)
		}
		
	}, [filters?.search,activeTable, isLoadingGridSettings]);

	useEffect(()=>{
		if(activeTable?.tableId !== DEMAND_MATRIX_TABLE_ID){
			setMatrixCreator(false);
			setEmptyMatrix(false);
		}
	}, [activeTable])

	const handleRefreshClick = React.useCallback(() => {
		dispatch(
			refreshGridData({
				tableId: activeTable?.tableId,
			})
		);
	}, [filters, activeTable]);

	const uploadCloseClickHandler = React.useCallback(() => {
		toggleUpload(false);
	}, []);

	const getDisabledStatus = React.useCallback((option: any) => {
		switch (option.value) {
			// case constants.DEMAND_PLANNING_MENU_VALUES.CUSTOMIZE_VIEW:
			case DEMAND_PLANNING_MENU_VALUES.RESET_VIEW:
				// case DEMAND_PLANNING_MENU_VALUES.UPLOAD_DATA:
				return false;
			// case constants.DEMAND_PLANNING_MENU_VALUES.DELETE:
			// 	return !selectedAvailability?.selectedRowKeys?.length;
			// case constants.DEMAND_PLANNING_MENU_VALUES.CREATE_KIT:
			// 	return !selectedAvailability?.selectedRowKeys?.length;
			default:
				return false;
		}
	}, []);
	const handleResetTableColumns = React.useCallback(async () => {
		dispatch(resetTableColumnsAg(activeTable?.tableId));
	}, [dispatch, activeTable?.tableId]);

	const handleMenuItemClick = React.useCallback((option: any) => {
		switch (option) {
			// case constants.DEMAND_PLANNING_MENU_VALUES.CUSTOMIZE_VIEW:
			// 	return customizeViewHandler(true);
			case DEMAND_PLANNING_MENU_VALUES.RESET_VIEW:
				return handleResetTableColumns();
			case DEMAND_PLANNING_MENU_VALUES.LOCK_DEMAND:
				return submitDemand();
			// case DEMAND_PLANNING_MENU_VALUES.UPLOAD_DATA:
			// 	return toggleUpload(true);
			default:
				return;
		}
	}, []);

	const getUrl = value => {
		let url;
		switch (value) {
			case 55:
				url = '/demand-planning?selectedTab=reporting';
				break;
			case 56:
				url = '/demand-planning?selectedTab=matrix';
				break;
			default:
				url = '/demand-planning';
				break;
		}
		return url;
	};
	const updateActiveTable = React.useCallback(
		({ value }: { value: number }) => {
			let url = getUrl(value);
			navigate(url);
			const tab = _.find(demandPlanningTabs, {
				tableId: value
			});

			if (tab) {
				setActiveTable(tab);
			}
		},
		[dispatch]
	);

	const gridConfig = {
		enableSelection: true,
		enableSorting: true,
		enableMultiSort: true,
		selectionCallBack: () => { }
	};

	const submitDemand = () => {
		dispatch(triggerSubmitDemand());
	};
	const isNewUpload = useSelector(isNewUploadEnabled);
	const templateConfig = React.useMemo(
		() => ({
			gridConfig,
			isFilterEnabled: true,
			tableId: activeTable?.tableId,
			dataProviderOptions: {
				type: 'POST',
				isGridEditDisabledOverride: !hasDemandChangeWrite,
				version: 2,
				...REQUESTS.POST.GET_GRID_DETAILS_V2,
				pathParams: { tableId: activeTable?.tableId },
			},
			selectRowKey: 'MatrixKey'
		}),
		[activeTable?.tableId]
	);
	return (
		<>
			<Stack isVertical>
				<StackItem isStretch>
					<GridActionBar>
						<Stack gutter="medium">
							<StackItem isGrow />
							{isNewUpload && gridMeta?.UploadTableID && <StackItem>
								<GridCsvUpload uploadTableId={gridMeta?.UploadTableID} parentTableId={activeTable?.tableId} />
							</StackItem>}
							<StackItem>
								<Icon
									isActive
									type="secondary"
									faAlias="faRecycle"
									onClick={handleRefreshClick}
								/>
							</StackItem>

							<StackItem>
								<ExportGrid key={activeTable?.tableId} tableId={activeTable?.tableId} />
							</StackItem>
							<StackItem>
								<ThetaMenu
									type="3Dots"
									options={activeTable?.tableId === 56 ? DEMAND_PLANNING_MENU : [DEMAND_PLANNING_MENU[0]]}
									handleItemClick={handleMenuItemClick}
									isDisabled={getDisabledStatus}
								/>
							</StackItem>
						</Stack>
					</GridActionBar>
				</StackItem>
				<StackItem isStretch isGrow>
					<Sheet isPage>
						<Stack isVertical gutter="medium">
							<StackItem isStretch isGrow>
								<Stack isVertical>
									<StackItem isStretch>
										<Stack>
											<StackItem isStretch isGrow>
												<Tabs>
													{_.map(demandPlanningTabs, (tab, index) => {
														return (
															<Tab
																key={index}
																value={tab.tableId}
																isSelected={activeTable?.tableId === tab.tableId}
																onSelectTabItem={updateActiveTable}
															>
																{tab.name}
															</Tab>
														);
													})}
												</Tabs>
											</StackItem>

										</Stack>
									</StackItem>
									<StackItem isGrow>
										<SheetBody>
											<Stack isVertical>
												{activeTable.tableId === 56 && (
													<StackItem position="end">
														<Button isSecondary handleClick={() => setMatrixCreator(true)}>
															Update Matrix Params
														</Button>
													</StackItem>
												)}
												<StackItem isStretch isGrow>
													{!isEmptyMatrix && (
														<ThetaGridPaginated key={activeTable?.tableId} config={templateConfig} />
													)}
												</StackItem>
											</Stack>
										</SheetBody>
									</StackItem>
								</Stack>
							</StackItem>
						</Stack>
					</Sheet>
				</StackItem>
			</Stack>


			{isMatrixCreator && (
				<CreateDemandMatrix
					isEmptyMatrix={isEmptyMatrix}
					templateConfig={templateConfig}
					handleClose={() => setMatrixCreator(false)}
				/>
			)}
			{isUploadEnabled && (
				<SideCar isActive={isUploadEnabled} handleOnClose={uploadCloseClickHandler}>
					<UploadData tableId={DEMAND_MATRIX_TABLE_ID} />
				</SideCar>
			)}
		</>
	);
};

export default DemandPlanning;
