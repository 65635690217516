// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import * as constants from './constants';
import { TTransactionReducer } from './types';

export const initialState: TTransactionReducer = {
	notificationsList: [],
	isNotificationCenterEnabled: false
};

const slice = createSlice({
	initialState,
	name: 'notifications',
	reducers: {
		updateNotifications(state, { payload: { notifications } }) {
			return {
				...state,
				notificationsList: notifications
			};
		},
		toggleNotificationCenter(state, { payload: { isNotificationCenterEnabled } }) {
			return {
				...state,
				isNotificationCenterEnabled
			};
		}
	}
});

export default slice;

export const { updateNotifications, toggleNotificationCenter } = slice.actions;
