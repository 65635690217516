// @ts-nocheck
import Button from 'atoms/button/button';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';

interface TProps {
  secondaryButtonName: string;
  primaryButtonName: string;
  primaryButtonClick: React.MouseEventHandler<HTMLButtonElement>;
  secondaryButtonClick: React.MouseEventHandler<HTMLButtonElement>;
  getPrimaryDisabledStatus: Function;
  isLoading: boolean;
}

const ModalActions = (props: TProps) => {
  const {
    secondaryButtonName,
    primaryButtonName,
    primaryButtonClick,
    secondaryButtonClick,
    getPrimaryDisabledStatus,
    isLoading
  } = props;
  return (
    <Stack gutter="small" align="right">
      <StackItem>
        <Button
          isSecondary
          name={secondaryButtonName}
          handleClick={(e) => secondaryButtonClick(e)}
          disabled={isLoading}
        />
      </StackItem>
      <StackItem>
        <Button
          name={primaryButtonName}
          handleClick={(e) => primaryButtonClick(e)}
          disabled={getPrimaryDisabledStatus() || isLoading}
        />
      </StackItem>
    </Stack>
  );
};

export default ModalActions;
