// @ts-nocheck

export const APP_OPERATIONS =  {
    "OP_CREATE_ORDER_READ": "OP_CREATE_ORDER_READ",
    "OP_CREATE_ORDER_WRITE": "OP_CREATE_ORDER_WRITE",
    "OP_CREATE_ORDER_DELETE": "OP_CREATE_ORDER_DELETE",
    "OP_ALL_ORDERS_READ": "OP_ALL_ORDERS_READ",
    "OP_ORDER_KITS_READ": "OP_ORDER_KITS_READ",
    "OP_ORDER_SKUS_DELETE": "OP_ORDER_SKUS_DELETE",
    "OP_ORDER_KITS_DELETE": "OP_ORDER_KITS_DELETE",
    "OP_ORDER_SKUS_READ": "OP_ORDER_SKUS_READ",
    "OP_ORDER_SKUS_WRITE": "OP_ORDER_SKUS_WRITE",
    "OP_ORDER_KITS_WRITE": "OP_ORDER_KITS_WRITE",
    "OP_ORDER_ITEMS_DELETE": "OP_ORDER_ITEMS_DELETE",
    "OP_ORDER_ITEMS_READ": "OP_ORDER_ITEMS_READ",
    "OP_ORDER_ITEMS_WRITE": "OP_ORDER_ITEMS_WRITE",
    "OP_AVAILABILITY_WRITE": "OP_AVAILABILITY_WRITE",
    "OP_AVAILABILITY_READ": "OP_AVAILABILITY_READ",
    "OP_AVAILABILITY_DELETE": "OP_AVAILABILITY_DELETE",
    "OP_AVAILABILITY_SUMMARY_READ": "OP_AVAILABILITY_SUMMARY_READ",
    "OP_ADVERTISING_READ": "OP_ADVERTISING_READ",
    "OP_AVAILABILITY_SUMMARY_WRITE": "OP_AVAILABILITY_SUMMARY_WRITE",
    "OP_AVAILABILITY_SUMMARY_DELETE": "OP_AVAILABILITY_SUMMARY_DELETE",
    "OP_ADVERTISING_WRITE": "OP_ADVERTISING_WRITE",
    "OP_PARAMETERS_READ": "OP_PARAMETERS_READ",
    "OP_ADVERTISING_DELETE": "OP_ADVERTISING_DELETE",
    "OP_PARAMETERS_WRITE": "OP_PARAMETERS_WRITE",
    "OP_PARAMETERS_DELETE": "OP_PARAMETERS_DELETE",
    "OP_ALL_ORDERS_WRITE": "OP_ALL_ORDERS_WRITE",
    "OP_DEMAND_REPORTING_WRITE": "OP_DEMAND_REPORTING_WRITE",
    "OP_DEMAND_GROUP_HUB_READ": "OP_DEMAND_GROUP_HUB_READ",
    "OP_DEMAND_CHANGE_WRITE": "OP_DEMAND_CHANGE_WRITE",
    "OP_DEMAND_CHANGE_READ": "OP_DEMAND_CHANGE_READ",
    "OP_DEMAND_CHANGE_DELETE": "OP_DEMAND_CHANGE_DELETE",
    "OP_ALL_ORDERS_DELETE": "OP_ALL_ORDERS_DELETE",
    "OP_DEMAND_GROUP_HUB_DELETE": "OP_DEMAND_GROUP_HUB_DELETE",
    "OP_DEMAND_GROUP_HUB_WRITE": "OP_DEMAND_GROUP_HUB_WRITE",
    "OP_MASTER_DATA_AD_TYPES_READ": "OP_MASTER_DATA_AD_TYPES_READ",
    "OP_MASTER_DATA_AD_TYPES_WRITE": "OP_MASTER_DATA_AD_TYPES_WRITE",
    "OP_MASTER_DATA_AD_TYPES_DELETE": "OP_MASTER_DATA_AD_TYPES_DELETE",
    "OP_MASTER_DATA_CUSTOMERS_READ": "OP_MASTER_DATA_CUSTOMERS_READ",
    "OP_MASTER_DATA_CUSTOMERS_WRITE": "OP_MASTER_DATA_CUSTOMERS_WRITE",
    "OP_MASTER_DATA_CUSTOMERS_DELETE": "OP_MASTER_DATA_CUSTOMERS_DELETE",
    "OP_MASTER_DATA_GROUPS_READ": "OP_MASTER_DATA_GROUPS_READ",
    "OP_MASTER_DATA_GROUPS_DELETE": "OP_MASTER_DATA_GROUPS_DELETE",
    "OP_MASTER_DATA_GROUPS_WRITE": "OP_MASTER_DATA_GROUPS_WRITE",
    "OP_MASTER_DATA_INVENTORY_TRANSACTIONS_READ": "OP_MASTER_DATA_INVENTORY_TRANSACTIONS_READ",
    "OP_MASTER_DATA_INVENTORY_TRANSACTIONS_WRITE": "OP_MASTER_DATA_INVENTORY_TRANSACTIONS_WRITE",
    "OP_MASTER_DATA_INVENTORY_TRANSACTIONS_DELETE": "OP_MASTER_DATA_INVENTORY_TRANSACTIONS_DELETE",
    "OP_MASTER_DATA_ITEM_EXCLUSIONS_READ": "OP_MASTER_DATA_ITEM_EXCLUSIONS_READ",
    "OP_MASTER_DATA_ITEM_SHIPPING_METHOD_CONFIGURATION_WRITE": "OP_MASTER_DATA_ITEM_SHIPPING_METHOD_CONFIGURATION_WRITE",
    "OP_MASTER_DATA_ITEM_EXCLUSIONS_WRITE": "OP_MASTER_DATA_ITEM_EXCLUSIONS_WRITE",
    "OP_MASTER_DATA_ITEM_EXCLUSIONS_DELETE": "OP_MASTER_DATA_ITEM_EXCLUSIONS_DELETE",
    "OP_MASTER_DATA_ITEM_SHIPPING_METHOD_CONFIGURATION_READ": "OP_MASTER_DATA_ITEM_SHIPPING_METHOD_CONFIGURATION_READ",
    "OP_MASTER_DATA_ITEM_SHIPPING_METHOD_CONFIGURATION_DELETE": "OP_MASTER_DATA_ITEM_SHIPPING_METHOD_CONFIGURATION_DELETE",
    "OP_MASTER_DATA_PARAMETERS_READ": "OP_MASTER_DATA_PARAMETERS_READ",
  "OP_MASTER_DATA_PARAMETERS_WRITE": "OP_MASTER_DATA_PARAMETERS_WRITE",
  "OP_MASTER_DATA_PARAMETERS_DELETE": "OP_MASTER_DATA_PARAMETERS_DELETE",
  
  "OP_MASTER_DATA_PRICING_READ": "OP_MASTER_DATA_PRICING_READ",
  "OP_MASTER_DATA_PRICING_WRITE": "OP_MASTER_DATA_PRICING_WRITE",
  "OP_MASTER_DATA_PRICING_DELETE": "OP_MASTER_DATA_PRICING_DELETE",
  
  "OP_MASTER_DATA_PRIMARY_STORE_SKU_READ": "OP_MASTER_DATA_PRIMARY_STORE_SKU_READ",
  "OP_MASTER_DATA_PRIMARY_STORE_SKU_WRITE": "OP_MASTER_DATA_PRIMARY_STORE_SKU_WRITE",
  "OP_MASTER_DATA_PRIMARY_STORE_SKU_DELETE": "OP_MASTER_DATA_PRIMARY_STORE_SKU_DELETE",
  
  "OP_MASTER_DATA_SCHEDULES_READ": "OP_MASTER_DATA_SCHEDULES_READ",
  "OP_MASTER_DATA_SCHEDULES_WRITE": "OP_MASTER_DATA_SCHEDULES_WRITE",
  "OP_MASTER_DATA_SCHEDULES_DELETE": "OP_MASTER_DATA_SCHEDULES_DELETE",
  
  "OP_MASTER_DATA_SHIPMENTS_READ": "OP_MASTER_DATA_SHIPMENTS_READ",
  "OP_MASTER_DATA_SHIPMENTS_WRITE": "OP_MASTER_DATA_SHIPMENTS_WRITE",
  "OP_MASTER_DATA_SHIPMENTS_DELETE": "OP_MASTER_DATA_SHIPMENTS_DELETE",

  "OP_PLANOGRAM_READ": "OP_PLANOGRAM_READ",
  "OP_PLANOGRAM_WRITE": "OP_PLANOGRAM_WRITE",
  "OP_PLANOGRAM_DELETE": "OP_PLANOGRAM_DELETE",
  
  "OP_MASTER_DATA_SHIPPING_METHODS_READ": "OP_MASTER_DATA_SHIPPING_METHODS_READ",
  "OP_MASTER_DATA_SHIPPING_METHODS_WRITE": "OP_MASTER_DATA_SHIPPING_METHODS_WRITE",
  "OP_MASTER_DATA_SHIPPING_METHODS_DELETE": "OP_MASTER_DATA_SHIPPING_METHODS_DELETE",
  
  "OP_MASTER_DATA_SKUS_READ": "OP_MASTER_DATA_SKUS_READ",
  "OP_MASTER_DATA_SKUS_WRITE": "OP_MASTER_DATA_SKUS_WRITE",
  "OP_MASTER_DATA_SKUS_DELETE": "OP_MASTER_DATA_SKUS_DELETE",
  
  "OP_MASTER_DATA_SKU_EXCLUSIONS_READ": "OP_MASTER_DATA_SKU_EXCLUSIONS_READ",
  "OP_MASTER_DATA_SKU_EXCLUSIONS_WRITE": "OP_MASTER_DATA_SKU_EXCLUSIONS_WRITE",
  "OP_MASTER_DATA_SKU_EXCLUSIONS_DELETE": "OP_MASTER_DATA_SKU_EXCLUSIONS_DELETE",
  
  "OP_MASTER_DATA_STORES_READ": "OP_MASTER_DATA_STORES_READ",
  "OP_MASTER_DATA_STORES_WRITE": "OP_MASTER_DATA_STORES_WRITE",
  "OP_MASTER_DATA_STORES_DELETE": "OP_MASTER_DATA_STORES_DELETE",
  
  "OP_MASTER_DATA_STORE_ORDER_LIST_READ": "OP_MASTER_DATA_STORE_ORDER_LIST_READ",
  "OP_MASTER_DATA_STORE_ORDER_LIST_WRITE": "OP_MASTER_DATA_STORE_ORDER_LIST_WRITE",
  "OP_MASTER_DATA_STORE_ORDER_LIST_DELETE": "OP_MASTER_DATA_STORE_ORDER_LIST_DELETE",
  
  "OP_MASTER_DATA_VALID_SKU_ITEM_COMBINATIONS_READ": "OP_MASTER_DATA_VALID_SKU_ITEM_COMBINATIONS_READ",
  "OP_MASTER_DATA_VALID_SKU_ITEM_COMBINATIONS_WRITE": "OP_MASTER_DATA_VALID_SKU_ITEM_COMBINATIONS_WRITE",
  "OP_MASTER_DATA_VALID_SKU_ITEM_COMBINATIONS_DELETE": "OP_MASTER_DATA_VALID_SKU_ITEM_COMBINATIONS_DELETE",
  
  "OP_MASTER_DATA_VALID_STORE_SKU_COMBINATIONS_READ": "OP_MASTER_DATA_VALID_STORE_SKU_COMBINATIONS_READ",
  "OP_MASTER_DATA_VALID_STORE_SKU_COMBINATIONS_WRITE": "OP_MASTER_DATA_VALID_STORE_SKU_COMBINATIONS_WRITE",
  "OP_MASTER_DATA_VALID_STORE_SKU_COMBINATIONS_DELETE": "OP_MASTER_DATA_VALID_STORE_SKU_COMBINATIONS_DELETE",
    "OP_MASTER_DATA_ITEMS_READ": "OP_MASTER_DATA_ITEMS_READ",
    "OP_MASTER_DATA_ITEMS_WRITE": "OP_MASTER_DATA_ITEMS_WRITE",
    "OP_MASTER_DATA_KITS_DELETE": "OP_MASTER_DATA_KITS_DELETE",
    "OP_MASTER_DATA_ITEMS_DELETE": "OP_MASTER_DATA_ITEMS_DELETE",
    "OP_MASTER_DATA_KITS_WRITE": "OP_MASTER_DATA_KITS_WRITE",
    "OP_MASTER_DATA_KITS_READ": "OP_MASTER_DATA_KITS_READ",
    "OP_DEMAND_REPORTING_READ": "OP_DEMAND_REPORTING_READ",
    "OP_DEMAND_REPORTING_DELETE": "OP_DEMAND_REPORTING_DELETE",
    "OP_INTELLIGENCE_READ": "OP_INTELLIGENCE_READ",
    "OP_APP_SETTINGS_READ": "OP_APP_SETTINGS_READ",
    "OP_APP_SETTINGS_WRITE": "OP_APP_SETTINGS_WRITE",
  "OP_APP_SETTINGS_DELETE": "OP_APP_SETTINGS_DELETE",

  "OP_ACL_USERS_READ": "OP_ACL_USERS_READ",
  "OP_ACL_USERS_WRITE": "OP_ACL_USERS_WRITE",
  "OP_ACL_ROLES_READ": 'OP_ACL_ROLES_READ',
  "OP_ACL_ROLES_WRITE": 'OP_ACL_ROLES_WRITE',
  "OP_ACL_DATA_RESTRICTIONS_READ": 'OP_ACL_DATA_RESTRICTIONS_READ',
  "OP_ACL_DATA_RESTRICTIONS_WRITE": 'OP_ACL_DATA_RESTRICTIONS_WRITE',
}