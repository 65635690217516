// @ts-nocheck
import * as React from 'react';
import './link.css';
import classNames from 'classnames';
import { useNavigate, useLocation } from 'react-router';

interface Props {
	type: 'primary' | 'secondary' | 'tertiary';
	children: React.ReactNode;
	route: string;
}

const Link: React.FunctionComponent<any> = (props: Props) => {
	const navigate = useNavigate();

	const { type } = props;
	const classes: any = classNames(['link', { [`link--${type}`]: true }]);
	const handleLinkClick = () => {
		return navigate(props.route);
	};
	return (
		<div className={classes} onClick={props.onClick || handleLinkClick}>
			{props.children}
		</div>
	);
};

Link.defaultProps = {
	type: 'primary'
};

export default Link;
