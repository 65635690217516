// @ts-nocheck
import FilterBar from 'atoms/filterBar/filterBar';
import Pagination from 'atoms/pagination/pagination';
import Sheet from 'atoms/sheet/sheet';
import _ from 'lodash';
import Grid from 'molecules/grid';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import SearchFilter from 'organisms/searchFilter';
import React from 'react';
// import * as query from './query';
import * as orderConstants from '../../pages/orders/constants';

const PageLayout = (props: TProps) => {
	return (
		<Sheet isPage>
			<Stack>
				<StackItem isStretch isGrow>
					{props.children}
				</StackItem>
			</Stack>
		</Sheet>
	);
};

export default PageLayout;
