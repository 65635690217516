// @ts-nocheck
import * as React from 'react';

import Heading from 'atoms/heading/heading';
import Sheet from 'atoms/sheet/sheet';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import { List } from 'antd';
import Scrollable from 'atoms/scrollable/scrollable';
import Tabs from 'atoms/tabs/tabs';
import { Tab } from 'atoms/tab/tab';
import * as constants from './constants';
import * as selectors from './selectors';
import { useDispatch, useSelector } from 'react-redux';
import * as sagaActions from './sagaActions';
import Loader from 'atoms/loader';
import ParameterDetails from './parameterDetails';
import ParameterDetailItem from './parameterDetailItem';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import ParameterGroup from './parameterGroup';
import ParameterConfig from './parameterConfig';
import SearchFilter from 'organisms/searchFilter';
import { getWebAppSettings } from 'models/user/selector';

const ParameterGroups: React.FunctionComponent = ({ selectedTab }) => {
	const [itemsPerPage, udpatePerPage] = React.useState(100);
	const [pageNumber, updatePageNo] = React.useState(1);
	const dispatch = useDispatch();
	const [activeParentGroup, updateParentGroup] = React.useState(null);
	const [search, updateSearch] = React.useState('');
	const [ref, handleUpdateRef] = React.useState(null);

	const isView = useSelector(state => getWebAppSettings(state)?.IsAclRowLevelAccessEnabled);

	React.useEffect(() => {
		udpatePerPage(100);
		updatePageNo(1);
		updateParentGroup(null);
		updateSearch('');
		dispatch(
			sagaActions.fetchParameterGroups({ parameterTypeId: selectedTab, itemsPerPage: 50, pageNumber: 1, isView }, false)
		);
	}, [dispatch, selectedTab, isView]);

	const parameterGroups = useSelector(state => {
		return selectors.getSelectedGroupParameters(state, selectedTab);
	});
	const isLoading = useSelector(state => {
		return selectors.isLoading(state, constants.LOADER_COMPONENTS.GROUPS);
	});

	const handleLoadMore = React.useCallback(() => {
		if (!isLoading) {
			updatePageNo(pageNumber + 1);
			dispatch(
				sagaActions.fetchParameterGroups(
					{
						parameterTypeId: selectedTab,
						itemsPerPage: itemsPerPage,
						pageNumber: pageNumber + 1,
						search
					},
					itemsPerPage > 1
				)
			);
		}
	}, [isLoading, selectedTab, itemsPerPage, pageNumber, search]);

	const createFiltersFromQueries = (queries: any[]) => {
		return {
			filter: _.map(queries, ({ criteria, query }) => ({
				field: criteria.rowIdentifier,
				value: query
			}))
		};
	};

	const getSearchFilters = React.useCallback(() => {
		return (
			selectedTab !== constants.PARAMETER_TYPES.GLOBAL &&
			selectedTab !== constants.PARAMETER_TYPES.DEFAULT && (
				<StackItem key={selectedTab}>
					<SearchFilter
						parameters={constants.FILTER_QUERY[selectedTab]}
						handler={query => {
							updateSearch(createFiltersFromQueries(query));
							ref.scrollTop = 0;
							updatePageNo(1);
							dispatch(
								sagaActions.fetchParameterGroups(
									{
										parameterTypeId: selectedTab,
										itemsPerPage: 50,
										pageNumber: 1,
										search: createFiltersFromQueries(query)
									},
									false
								)
							);
						}}
					/>
				</StackItem>
			)
		);
	}, [dispatch, createFiltersFromQueries, updatePageNo, selectedTab]);

	return (
		<Stack position="top" isVertical gutter="small">
			{getSearchFilters()}
			{isLoading && pageNumber === 1 ? (
				<StackItem isGrow isStretch>
					<Loader></Loader>
				</StackItem>
			) : selectedTab === constants.PARAMETER_TYPES.GLOBAL ||
			  selectedTab === constants.PARAMETER_TYPES.DEFAULT ? (
				<StackItem isStretch>
					<ParameterConfig
						selectedTab={selectedTab}
						selector={constants.DATA_SELECTORS[selectedTab]}
						parameterGroups={parameterGroups}
					/>
				</StackItem>
			) : (
				<Scrollable onDomRef={handleUpdateRef}>
					<InfiniteScroll
						initialLoad={false}
						style={{ width: '100%', height: '640px' }}
						pageStart={0}
						loadMore={handleLoadMore}
						hasMore={parameterGroups && parameterGroups.hasMore}
						useWindow={false}
					>
						<List
							dataSource={parameterGroups && parameterGroups.data}
							renderItem={(data, index) => {
								return (
									<List.Item key={index}>
										<ParameterGroup
											parameterGroup={data}
											key={index}
											selector={constants.DATA_SELECTORS[selectedTab]}
											selectedTab={selectedTab}
											index={index}
											updateParentGroup={updateParentGroup}
											isActive={index === activeParentGroup && data.ParameterMasterID}
										></ParameterGroup>
									</List.Item>
								);
							}}
						></List>
						{parameterGroups && parameterGroups.hasMore && <Loader></Loader>}
					</InfiniteScroll>
				</Scrollable>
			)}
		</Stack>
	);
};

export default ParameterGroups;
