// @ts-nocheck
import BodyText from 'atoms/bodyText/bodyText';
import Button from 'atoms/button/button';
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import Modal from 'atoms/modal/modal';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import { Tab } from 'atoms/tab/tab';
import Tabs from 'atoms/tabs/tabs';
import _ from 'lodash';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as sagaActions from '../sagaActions';
import * as selectors from '../selectors';
import SelectSkus from './selectSkus';
import { SELECT_SKUS_TABLE_ID, SELECT_STORES_TABLE_ID } from './constants';
import SelectStores from './selectStores';
import Loader from 'atoms/loader';
import ModalHeader from 'atoms/modal/modalHeader';
import ReactModal from 'atoms/modal/reactModal';
import ModalBody from 'atoms/modal/modalBody';
import ModalActions from 'atoms/modal/modalActions';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import { updateOptimizeNumber } from '../reducer';
import { getDispatch } from 'reducers';
import { createFiltersFromQueries } from 'models/dynamicGrid/query';
import { isLoadingEnabled } from 'models/loaders/selectors';

interface TProps {
	hideModal: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
	selectedTab: string;
}

const OPTIMIZE_SHIPPING_STEPS = {
	SELECT_STORES: 'selectStores',
	SELECT_SKUS: 'selectSkus',
	REVIEW_OPTIMIZATION: 'reviewOptimization',
	OPTIMIZE_SHIPPING_METHOD: 'optimizeShippingMethod'
};

const DISTRIBUTE_STEPS = [
	{
		title: 'Update Desired Ship Qty',
		identifier: OPTIMIZE_SHIPPING_STEPS.SELECT_STORES
	},
	{
		title: 'Select SKUs',
		identifier: OPTIMIZE_SHIPPING_STEPS.SELECT_SKUS
	},
	{
		title: 'Review Optimization',
		identifier: OPTIMIZE_SHIPPING_STEPS.REVIEW_OPTIMIZATION
	},
	{
		title: 'Optimize Shipping Method',
		identifier: OPTIMIZE_SHIPPING_STEPS.OPTIMIZE_SHIPPING_METHOD
	}
];

const getDisabledStatus = (identifier: string, isLoading: boolean, selectedSkus: any) => {
	switch (identifier) {
		case OPTIMIZE_SHIPPING_STEPS.SELECT_STORES:
			return isLoading;
		case OPTIMIZE_SHIPPING_STEPS.SELECT_SKUS:
		case OPTIMIZE_SHIPPING_STEPS.REVIEW_OPTIMIZATION:
		case OPTIMIZE_SHIPPING_STEPS.OPTIMIZE_SHIPPING_METHOD:
			return isLoading || !selectedSkus || !selectedSkus.length;
		default:
			return isLoading;
	}
};

const DistributeModalHeader = ({ selectedStep, setSelectedStep, steps, isLoading, selectedSkus }: any) => {
	return (
		<Stack isVertical gutter="small">
			<StackItem isStretch>
				<Heading>Optimize Shipping Method</Heading>
			</StackItem>
			<StackItem isStretch>
				<Divider />
			</StackItem>
			<StackItem isStretch>
				<Tabs type="secondary">
					{_.map(steps, (step, index) => {
						return (
							<Tab
								key={index}
								value={index}
								isSelected={selectedStep.identifier === step.identifier}
								onSelectTabItem={() => setSelectedStep(step)}
								disabled={true || getDisabledStatus(step.identifier, isLoading, selectedSkus)}
							>
								{step.title}
							</Tab>
						);
					})}
				</Tabs>
			</StackItem>
		</Stack>
	);
};

const DistributeModalContent = ({
	selectedStep,
	selectedOrders,
	selectedTab,
	selectedSkus,
	parent,
	getCurrentQty,
	getDesiredQty,
	isSelectAllOrders,
	isSelectAllSkus,
	orderTotalCount,
	skuTotalCount
}: any) => {
	const optimizeAggregateInfo = useSelector(selectors.getOptimizeAggregateInfo);
	const isLoadingAggregation = useSelector(state => isLoadingEnabled(state, 'optimize-aggregate-data'));
	switch (selectedStep.identifier) {
		case OPTIMIZE_SHIPPING_STEPS.SELECT_SKUS:
			return (
				<>
					{' '}
					<SelectSkus parent={parent} selectedTab={selectedTab} />{' '}
				</>
			);
		case OPTIMIZE_SHIPPING_STEPS.REVIEW_OPTIMIZATION:
			return (
				<Stack isVertical gutter="medium">
					<StackItem>
						<BodyText>Review optimization.</BodyText>
					</StackItem>
					<StackItem>
						<BodyText>
							You have selected {isSelectAllOrders ? orderTotalCount : selectedOrders.length} stores for
							optimization using {isSelectAllSkus ? skuTotalCount : selectedSkus.length} SKUs.
						</BodyText>
					</StackItem>
					{isLoadingAggregation ? (
						<Loader />
					) : (
						<>
							<StackItem>
								<BodyText>Current Total: {optimizeAggregateInfo?.TotalCurrentQty}</BodyText>
							</StackItem>
							<StackItem>
								<BodyText>Desired Total: {optimizeAggregateInfo?.TotalDesiredQty}</BodyText>
							</StackItem>
						</>
					)}
				</Stack>
			);
		case OPTIMIZE_SHIPPING_STEPS.OPTIMIZE_SHIPPING_METHOD:
			return <> Thetabot has finished optimizing your shipping method. </>;
		case OPTIMIZE_SHIPPING_STEPS.SELECT_STORES:
		default:
			return <SelectStores parent={parent} selectedTab={selectedTab} />;
	}
};

const DistributeModalActions = ({
	selectedStep,
	setSelectedStep,
	hideModal,
	steps,
	selectedSkus,
	optimizeShipping,
	isLoading,
	selectedTab
}: any) => {
	const selectedOrders = useSelector(state => dynamicGridSelectors.getTableSelection(state, selectedTab));
	const ordersSearch = useSelector(state => dynamicGridSelectors.getGridFilters(state, selectedTab));
	const optimizeShipMethodNumber = useSelector(state => selectors.getOptimizeShipMethodNumber(state));
	const fetchAndUpdateAggregateData = () => {
		const dispatch = getDispatch();

		dispatch(
			sagaActions.getOptimizeAggregateData({
				optimizeShipMethodNumber,
				tableId: selectedTab,
				orderIds: (selectedOrders?.selectedRowKeys || []).join(','),
				isOrderSelectAll: selectedOrders?.isSelectAll,
				ordersSelectAllSearch: JSON.stringify({
					filter: createFiltersFromQueries(ordersSearch?.search)
				})
			})
		);
	};
	const qtyGridData = useSelector(state => dynamicGridSelectors.getGridData(state, SELECT_STORES_TABLE_ID));

	const getNextAction = () => {
		switch (selectedStep.identifier) {
			case OPTIMIZE_SHIPPING_STEPS.SELECT_SKUS:
				return (
					<Button
						name="Review Optimization >"
						handleClick={() => {
							setSelectedStep(steps[2]);
						}}
						disabled={!selectedSkus || !selectedSkus.length}
					/>
				);
			case OPTIMIZE_SHIPPING_STEPS.REVIEW_OPTIMIZATION:
				return <Button name="Optimize Shipping Method" handleClick={optimizeShipping} disabled={false} />;
			case OPTIMIZE_SHIPPING_STEPS.OPTIMIZE_SHIPPING_METHOD:
				return <Button name="Continue" handleClick={hideModal} disabled={isLoading} />;
			case OPTIMIZE_SHIPPING_STEPS.SELECT_STORES:
			default:
				return (
					<Button
						disabled={!qtyGridData?.length}
						name="Select SKUs >"
						handleClick={() => {
							fetchAndUpdateAggregateData();
							setSelectedStep(steps[1]);
						}}
					/>
				);
		}
	};
	return (
		<Stack gutter="small" align="right">
			<StackItem>
				{selectedStep.identifier !== OPTIMIZE_SHIPPING_STEPS.OPTIMIZE_SHIPPING_METHOD && (
					<Button isSecondary name="Cancel Distribution" handleClick={hideModal} />
				)}
			</StackItem>
			<StackItem>{getNextAction()}</StackItem>
		</Stack>
	);
};

const ThetaOptimizeShippingMethodModal = (props: TProps) => {
	const { hideModal, selectedTab } = props;
	const dispatch = useDispatch();
	const [ref, handleUpdateRef] = React.useState(null);
	const selectedOrders = useSelector(state => dynamicGridSelectors.getTableSelection(state, Number(selectedTab)));

	const steps = DISTRIBUTE_STEPS;
	const [selectedStep, setSelectedStep] = React.useState<any>(steps[0]);

	const selectedSkus = useSelector(state => dynamicGridSelectors.getTableSelection(state, SELECT_SKUS_TABLE_ID));

	const selectedStores = useSelector(state => dynamicGridSelectors.getTableSelection(state, SELECT_STORES_TABLE_ID));

	const storesGridData = useSelector(state =>
		dynamicGridSelectors.getGridData(state, Number(SELECT_STORES_TABLE_ID))
	);

	const orderTotalCount = useSelector(state => dynamicGridSelectors.getTotalCount(state, Number(selectedTab)));
	const skuTotalCount = useSelector(state => dynamicGridSelectors.getTotalCount(state, SELECT_SKUS_TABLE_ID));

	const optimizeShipping = () => {
		dispatch(sagaActions.optimizeShipping({ tableId: selectedTab }));
		setSelectedStep(steps[3]);
	};

	useEffect(() => {
		return () => {
			dispatch(updateOptimizeNumber({ optimizeShipMethodNumber: null }));
		};
	}, []);

	const isLoading = useSelector(state => selectors.getLoadingStatus(state));

	const getCurrentQty = () => {
		return `${_.reduce(
			storesGridData,
			function (sum, n) {
				return Number(sum) + Number(n.ShipMethodQty);
			},
			0
		).toFixed(0)}`;
	};

	const getDesiredQty = () => {
		return `${_.reduce(
			storesGridData,
			function (sum, n) {
				return Number(sum) + Number(n.ShipMethodQtyRounded);
			},
			0
		).toFixed(0)}`;
	};

	//   getCurrentQty() {
	//     let currentQuantity = 0

	//     for (const selectedStore of this.state.data.stores) {
	//         currentQuantity += selectedStore.ShipMethodQty
	//     }

	//     return parseInt(currentQuantity)
	// }

	// getDesiredQty() {
	//     let currentQuantity = 0

	//     for (const selectedStore of this.state.data.stores) {
	//         currentQuantity += selectedStore.ShipMethodQtyRounded
	//     }

	//     return parseInt(currentQuantity)
	// }

	return (
		<ReactModal hideModal={hideModal} height={800} width={800}>
			<ModalHeader>
				<DistributeModalHeader
					selectedStep={selectedStep}
					setSelectedStep={setSelectedStep}
					selectedOrders={selectedOrders?.selectedRowKeys}
					steps={steps}
					selectedSkus={selectedSkus}
					isLoading={isLoading}
				/>
			</ModalHeader>
			<ModalBody onDomRef={handleUpdateRef}>
				{isLoading ? (
					<Stack isVertical>
						<StackItem isGrow isStretch align="center">
							<Loader />
						</StackItem>
					</Stack>
				) : (
					<DistributeModalContent
						selectedStep={selectedStep}
						setSelectedStep={setSelectedStep}
						hideModal={hideModal}
						selectedOrders={selectedOrders?.selectedRowKeys}
						steps={steps}
						selectedTab={selectedTab}
						selectedSkus={selectedSkus?.selectedRowKeys}
						parent={ref}
						getCurrentQty={getCurrentQty}
						getDesiredQty={getDesiredQty}
						isSelectAllOrders={selectedOrders?.isSelectAll}
						isSelectAllSkus={selectedSkus?.isSelectAll}
						orderTotalCount={orderTotalCount}
						skuTotalCount={skuTotalCount}
					/>
				)}
			</ModalBody>
			<ModalActions>
				<DistributeModalActions
					selectedStep={selectedStep}
					setSelectedStep={setSelectedStep}
					hideModal={hideModal}
					steps={steps}
					selectedSkus={selectedSkus?.selectedRowKeys}
					optimizeShipping={optimizeShipping}
					isLoading={isLoading}
					selectedTab={selectedTab}
				/>
			</ModalActions>
		</ReactModal>
	);
};

export default ThetaOptimizeShippingMethodModal;
