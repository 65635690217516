// @ts-nocheck
import React from 'react';

import RolesReducer from './reducer';
import RolesSagas from './sagas';
import Roles from './rolesAg';
import { isAclRolesReadWriteEnabled } from 'models/user/selector';

export default [
	{
		path: '/settings/roles',
		key: 'key',
		componentId: 'roles',
		component: (props) => <Roles {...props} title="asd" />,
		routes: [],
		importReducer: () => {
			return [RolesReducer];
		},
		hasWorkflow: true,
		importSagas: () => {
			return [RolesSagas];
		},
		isEnabled: state => {
			return isAclRolesReadWriteEnabled(state)
		},
		title: 'Acl Roles',
		headerName: 'Roles',
		name: 'Roles',

	}
];
