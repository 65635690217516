// @ts-nocheck
import React from 'react';
import { useOutsideAlerter } from 'utils/helpers';
import './modal.scss';
import classNames from 'classnames';

interface Props {
	children: React.ReactNode;
}

const ModalHeader = (props: Props) => {
	return <div className="app-modal--header">
		{props.children}
	</div>;
};

export default ModalHeader;
