// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";
import * as query from "./query";
import { TFilter, TMetadata } from "./types";

export const loadGrid = createAction(
  query.createActionName("load-grid"),
  ({
    tableId,
    dataProviderOptions,
    isAdvancedSearch,
    selectRowKey,
    isReset,
    isStorageEnabled,
    childTableSelectRowKey,
    extendedSearchFilters,
    isUploadEnabled,
  }: {
    tableId: number;
    url: string;
    service: string;
    dataProviderOptions?: {
      version?: number;
      type?: string;
      body?: object;
      url: string;
      service: string;
      queryParams?: object;
      pathParams?: object;
    };
    isAdvancedSearch?: boolean;
    selectRowKey?: string;
    isReset?: boolean;
    isStorageEnabled?: boolean;
    isUploadEnabled?: boolean;
    extendedSearchFilters?: any;
  }) => ({
    payload: {
      tableId,
      dataProviderOptions,
      isAdvancedSearch,
      isUploadEnabled,
      childTableSelectRowKey,
      isReset,
      selectRowKey,
      isStorageEnabled,
      extendedSearchFilters,
    },
  })
);

export const loadGridData = createAction(
  query.createActionName("load-grid-data"),
  (
    tableId: number,
    dataProvider: () => Promise<{ data: any[]; isError?: boolean }>,
    isPaginated?: boolean
  ) => ({
    payload: { tableId, dataProvider, isPaginated: isPaginated || true },
  })
);

export const updateGrid = createAction(
  query.createActionName("update-grid"),
  ({
    tableId,
    filters,
    isAppend,
    isView,
    isMultiSort,
  }: {
    tableId: number;
    filters: Partial<TFilter>;
    isAppend?: boolean;
    isView: boolean;
  }) => ({
    payload: { tableId, filters, isAppend, isView },
  })
);

export const erpEnabled = createAction(
  "grid-isErpEnabled",
  function prepare({ tableId }) {
    return {
      payload: {
        tableId,
      },
    };
  }
);

export const lazyLoad = createAction(
  query.createActionName("load-more-grid"),
  ({ tableId, isReset }) => ({
    payload: { tableId, isReset },
  })
);

export const updateSelectedRows = createAction(
  query.createActionName("update-selected-rows"),
  ({
    tableId,
    selectedRowKeys,
    isSelectAll,
    rowGroup,
  }: {
    tableId: number;
    selectedRowKeys: number[];
    isSelectAll: boolean;
    rowGroup: any;
  }) => ({
    payload: { tableId, selectedRowKeys, isSelectAll, rowGroup },
  })
);

export const refreshGridData = createAction(
  query.createActionName("refresh-grid-data"),
  ({ tableId }: { tableId: number }) => ({
    payload: { tableId },
  })
);

export const fetchGridData = createAction(
  query.createActionName("fetch-grid-data"),
  (tableId: number) => ({
    payload: { tableId },
  })
);

export const exportGridData = createAction(
  query.createActionName("export-grid-data"),
  ({
    tableId,
    exportName,
    fieldGroups,
    extraFilters,
    selectColumnList,
  }: {
    tableId: number;
    exportName: string;
    fieldGroups: any;
    extraFilters: any;
    selectColumnList: string;
  }) => ({
    payload: {
      tableId,
      exportName,
      fieldGroups,
      extraFilters,
      selectColumnList,
    },
  })
);

export const downloadGridData = createAction(
  query.createActionName("download-grid-data"),
  ({ link, tableId }: { link: number; tableId: string }) => ({
    payload: { link, tableId },
  })
);

export const updateGridFilters = createAction(
  query.createActionName("update-grid-filters"),
  (
    tableId: number,
    filters: Partial<TFilter>,
    isPaginated?: boolean,
    isStorageEnabled?: boolean
  ) => ({
    payload: {
      tableId,
      filters,
      isPaginated: isPaginated || true,
      isStorageEnabled,
    },
  })
);

export const fetchComponentUserSettings = createAction(
  query.createActionName("fetch-component-settings"),
  (payload) => ({
    payload,
  })
);

export const updateComponentUserSettings = createAction(
  query.createActionName("update-component-settings"),
  (payload) => ({
    payload,
  })
);
export const saveGridFiltersAsync = createAction(
  query.createActionName("save-grid-filters"),
  (payload) => ({
    payload,
  })
);

export const fetchGridFiltersAsync = createAction(
  query.createActionName("fetch-grid-filters"),
  (payload) => ({
    payload,
  })
);

export const updateGridMeta = createAction(
  query.createActionName("update-grid-meta"),
  (tableId: number, metaData: TMetadata, isAppend: boolean) => {
    return ({
    payload: { tableId, metaData, isAppend },
  })}
);

export const updateRowData = createAction(
  query.createActionName("on-change-row-data"),
  ({
    tableId,
    row,
    updatedValue,
    rowIdentifier,
    isInlineEdit,
    isUploadGrid,
    isGroupNode,
    isPivotMode,
    metaData
  }: {
    tableId: number;
    row: any;
    updatedValue: any;
    rowIdentifier: string;
    isInlineEdit: boolean;
    isGroupNode: boolean;
    metaData
  }) => {
    return ({
    payload: {
      tableId,
      rowIdentifier,
      row,
      updatedValue,
      isInlineEdit,
      isUploadGrid,
      isGroupNode,
      isPivotMode,
      metaData
    },
  })}
);

export const updateTableCell = createAction(
  query.createActionName("update-table-cell"),
  ({
    tableId,
    row,
    updatedValue,
    rowIdentifier,
  }: {
    tableId: number;
    row: any;
    updatedValue: any;
    rowIdentifier: string;
  }) => ({
    payload: { tableId, row, updatedValue, rowIdentifier },
  })
);

export const updateGridRow = createAction(
  query.createActionName("update-grid-row"),
  (
    tableId: number,
    row: any,
    rowIdentifier: "string",
    newValue: "string" | "number"
  ) => ({
    payload: { tableId, row, rowIdentifier, newValue },
  })
);

export const cleanUpGrids = createAction(
  query.createActionName("trigger-grids-cleanup"),
  (payload: { tableIds: number[] }) => {
    return {
      payload,
    };
  }
);

export const updateGridData = createAction(
  query.createActionName("update-grid-data"),
  (tableId: number, data: any) => ({
    payload: {
      tableId,
      data,
    },
  })
);

export const handleBulkRowDelete = createAction(
  query.createActionName("delete-grid-data-bulk"),
  ({ ...payload }) => ({
    payload,
  })
);

export const handleRowDelete = createAction(
  query.createActionName("deleteRow"),
  ({
    tableId,
    rowIdentifier,
    isSelectAll,
    fieldGroups,
    isUploadGrid,
    deleteActionFieldGroups,
    selectAllSearch,
    deletedRows,
  }: {
    tableId: number;
    rowIdentifier: any;
    selectAllSearch: any;
    isSelectAll: any;
  }) => ({
    payload: {
      tableId,
      deletedRows,
      isUploadGrid,
      fieldGroups,
      rowIdentifier,
      deleteActionFieldGroups,
      selectAllSearch,
      isSelectAll,
    },
  })
);

export const handleRowUpdateToggle = createAction(
  query.createActionName("grid/editRow"),
  ({
    tableId,
    rowIdentifier,
    row,
    isEditEnabled,
  }: {
    tableId: number;
    rowIdentifier: any;
    isEditEnabled: boolean;
    row: any;
  }) => ({
    payload: {
      tableId,
      rowIdentifier,
      row,
      isEditEnabled,
    },
  })
);

export const handleRowUpdateSuccess = createAction(
  query.createActionName("grid/editRowUpdate/success"),
  ({
    tableId,
    rowIdentifier,
    row,
  }: {
    tableId: number;
    rowIdentifier: any;
    row: any;
  }) => ({
    payload: {
      tableId,
      row,
      rowIdentifier,
    },
  })
);

export const handleRowDeleteSuccess = createAction(
  query.createActionName("grid/deleteRow/success"),
  ({
    tableId,
    rowIdentifier,
    row,
  }: {
    tableId: number;
    rowIdentifier: any;
    row: any;
  }) => ({
    payload: {
      tableId,
      row,
      rowIdentifier,
    },
  })
);

export const triggerSynchronousAgGridLoading = createAction(
  query.createActionName("grid/triggerSynchronousAgGridLoading"),
  (payload) => ({
    payload,
  })
);

export const updateColumnWidth = createAction(
  query.createActionName("grid/updateCol/width"),
  ({
    tableId,
    columnId,
    columnWidth,
    currentWidth,
  }: {
    tableId: number;
    columnId: any;
    columnWidth: any;
  }) => ({
    payload: {
      tableId,
      columnId,
      columnWidth,
      currentWidth,
    },
  })
);
