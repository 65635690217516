
// @ts-nocheck

import { getGridFilters } from 'models/dynamicGrid/selector';
import { useSelector } from 'react-redux';
import  StackItem  from 'molecules/stackItem/stackItem';
import  Stack  from 'molecules/stack/stack';
import  Label  from 'atoms/label/label';
import { AgGridSearchFilter } from 'organisms/searchFilter/components/agGridSearchFilter';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const FilterTags = ({
    tableId,
    gridRef
}) => {
    const tableFilters =  useSelector(state => getGridFilters(state, tableId));
    const activeFilterTags = _.filter(
		tableFilters?.search,
		searchQuery => searchQuery.operator && !searchQuery.isLocked
	);
    return Boolean(activeFilterTags?.length) && (
            <Stack isVertical gutter="small">
                <StackItem>
                    <Label isHighlight>Applied Filters</Label>
                </StackItem>

                <StackItem>
                    <Stack gutter="tiny" isWrap>
                        {_.map(
                            activeFilterTags,
                            (searchQuery: types.TSearchQuery, index: number) =>
                                searchQuery.operator &&
                                !searchQuery.isLocked && (
                                    <React.Fragment key={searchQuery.id}>
                                        <StackItem>
                                            <AgGridSearchFilter
                                                currentQuery={searchQuery}
                                                tableId={tableId}
                                                updateQuery={() => { }}

                                                removeQuery={() => { }}
                                                isTagFilter={true}
                                                onUpdateQueries={() => {
                                                    gridRef?.current?.api.refreshServerSide({
                                                        route: [],
                                                        purge: true
                                                    });
                                                }}
                                                currentColumnRef={gridRef?.current?.columnApi
                                                    ?.getColumns()
                                                    .find(d => {
                                                        return (
                                                            d.colDef.field ===
                                                            searchQuery.criteria.rowIdentifier
                                                        );
                                                    })}
                                            />
                                        </StackItem>
                                        {index < activeFilterTags?.length - 1 && (
                                            <StackItem position="center">
                                                <Label isSecondary> &nbsp; And &nbsp; </Label>
                                            </StackItem>
                                        )}
                                    </React.Fragment>
                                )
                        )}
                    </Stack>
                </StackItem>
            </Stack>
    )
}