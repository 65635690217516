// @ts-nocheck
import { REQUEST_STATUS } from 'models/loaders/constants';
import { initialState, name } from './reducer';

const autoCompleteSelector = state => state['autoCompleteSelector'] || initialState;

export const getAutoCompleteData = (state, componentId) => autoCompleteSelector(state)?.[componentId]?.data ?? [];

export const getComponentMetaData = (state, componentId) =>
	autoCompleteSelector(state)?.[componentId]?.metaData

export const getAutoCompleteStatus = (state, componentId) =>
	autoCompleteSelector(state)?.[componentId]?.status ?? REQUEST_STATUS.NONE;


export const isAutoCompleteLoading = (state, componentId) =>
	autoCompleteSelector(state)?.[componentId]?.status === REQUEST_STATUS.PENDING;
	