// @ts-nocheck
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import ModalHeader from 'atoms/modal/modalHeader';
import ReactModal from 'atoms/modal/reactModal';
import { Tab } from 'atoms/tab/tab';
import Tabs from 'atoms/tabs/tabs';
import _ from 'lodash';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as groupModalSelectors from 'organisms/groupModal/selector';
import * as groupModalSagaActions from 'organisms/groupModal/sagaActions';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReviewGroup from './reviewGroup';
import SelectMembers from './selectMembers';
import StartGroup from './startGroup';
type TProps = {
	name: string;
	hideModal: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
};

const AD_MODAL_STEPS = {
	START_GROUP: 'startGroup',
	SELECT_MEMBERS: 'selectMembers',
	REVIEW_GROUP: 'reviewGroup'
};

const steps = [
	{
		title: 'Start Group',
		identifier: AD_MODAL_STEPS.START_GROUP
	},
	{
		title: 'Select Members',
		identifier: AD_MODAL_STEPS.SELECT_MEMBERS
	},
	{
		title: 'Review Group',
		identifier: AD_MODAL_STEPS.REVIEW_GROUP
	}
];

const GroupModal = (props: TProps) => {
	const { hideModal, name } = props;
	const [selectedStep, setSelectedStep] = React.useState<any>(steps[0]);
	const [memberType, updateMemberType] = React.useState(null);
	const dispatch = useDispatch();
	React.useEffect(() => {
		return () => {
			dispatch(groupModalSagaActions.cleanUp());
		};
	}, [dispatch]);
	const groupData: any = useSelector(state => groupModalSelectors.getEditData(state));
	const isEditMode: boolean = useSelector(state => groupModalSelectors.getEditMode(state)) || false;
	const selectMembers: any = useSelector(state => dynamicGridSelectors.getTableSelection(state, 2));
	const getDisabledStatus = (identifier: string) => {
		switch (identifier) {
			case AD_MODAL_STEPS.START_GROUP:
				return false;
			case AD_MODAL_STEPS.SELECT_MEMBERS:
				return !Boolean(Object.keys(groupData).length);
			case AD_MODAL_STEPS.REVIEW_GROUP:
				return !Boolean(Object.keys(groupData).length) || (!selectMembers?.selectedRowKeys?.length && !selectMembers?.isSelectAll);
			default:
				return true;
		}
	};
	const getScreen = React.useCallback(() => {
		switch (selectedStep.identifier) {
			case AD_MODAL_STEPS.SELECT_MEMBERS:
				return (
					<SelectMembers
						memberType={memberType}
						goBack={() => setSelectedStep(steps[0])}
						handleNextClick={() => setSelectedStep(steps[2])}
						hideModal={hideModal}
					/>
				);
			case AD_MODAL_STEPS.REVIEW_GROUP:
				return (
					<ReviewGroup
						goBack={() => setSelectedStep(steps[1])}
						handleNextClick={hideModal}
						hideModal={hideModal}
					/>
				);
			case AD_MODAL_STEPS.START_GROUP:
			default:
				return (
					<StartGroup
						handleUpdateMemberType={updateMemberType}
						handleNextClick={() => setSelectedStep(steps[1])}
						hideModal={hideModal}
					/>
				);
		}
	}, [selectedStep.identifier]);
	return (
		<ReactModal hideModal={hideModal} height={700} width={700}>
			<ModalHeader>
				<Stack isVertical gutter="small">
					<StackItem isStretch>
						<Heading>{isEditMode ? `Editing: ${groupData.GroupTitle}` : 'Create New Group'}</Heading>
					</StackItem>
					<StackItem isStretch>
						<Divider />
					</StackItem>
					<StackItem isStretch>
						<Stack isVertical gutter="small">
							<StackItem isStretch>
								<Tabs type="secondary">
									{_.map(steps, (step, index) => {
										return (
											<Tab
												key={index}
												value={index}
												isSelected={selectedStep.identifier === step.identifier}
												// onSelectTabItem={() => setSelectedStep(step)}
												disabled={getDisabledStatus(step.identifier)}
											>
												{step.title}
											</Tab>
										);
									})}
								</Tabs>
							</StackItem>
						</Stack>
					</StackItem>
				</Stack>
			</ModalHeader>
			{getScreen()}
		</ReactModal>
	);
};

export default GroupModal;
