// @ts-nocheck
import DropDown from "atoms/dropdown/dropdown";
import FilterBarSection from "atoms/filterBar/filterBarSection";
import Icon from "atoms/icons/icon";
import Tag from "atoms/tag/tag";
import TextInput from "atoms/TextInput/textInput";
import _ from "lodash";
import Stack from "molecules/stack/stack";
import StackItem from "molecules/stackItem/stackItem";
import React from "react";
import { TSearchParameter, TSearchQuery } from "./type";
import * as tableColumnSagaActions from "../../models/tableColumns/sagaActions";
import * as tableColumnSelectors from "../../models/tableColumns/selector";
import { useDispatch, useSelector } from "react-redux";
import * as query from "./query";
import { Switch } from "antd";

type TProps = {
  parameters: TSearchParameter[];
  searchQueries: TSearchQuery[];
  handler: Function;
  tableId?: number;
  isServerHandled: boolean;
  allowOperator?: boolean;
  operator?: string;
  updateOperator?: (checked: boolean, event: MouseEvent) => void;
};

const defaultProps: Partial<TProps> = {
  parameters: [],
  searchQueries: [],
  handler: () => { },
  isServerHandled: false,
  allowOperator: false,
  operator: "AND",
  updateOperator: (checked: boolean, event: MouseEvent) => { },
};

const SearchFilter: React.FunctionComponent<Partial<TProps>> = (
  props
): React.ReactElement => {
  const {
    searchQueries,
    handler,
    tableId,
    isServerHandled,
    allowOperator,
    operator,
    updateOperator,
  } = { ...defaultProps, ...props };

  let { parameters } = { ...defaultProps, ...props };

  const [search, updateSearchQuery] =
    React.useState<TSearchQuery[]>(searchQueries);
  const [searchInput, updateSearchInput] = React.useState<string>("");
  const [selectedCriteria, updateCriteria] = React.useState<TSearchParameter>();

  let updateTimeout: any;
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (tableId && isServerHandled) {
      dispatch(tableColumnSagaActions.fetchTableColumns(tableId));
    }
  }, [tableId, isServerHandled]);

  React.useEffect(() => {
    updateSearchQuery(searchQueries);
  }, [searchQueries]);

  const validSearchInput = React.useCallback(() => {
    return searchInput.length >= 1;
  }, [searchInput]);

  const searchInputHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      updateSearchInput(e.target.value);
    },
    [updateSearchInput]
  );

  const searchInputPressHandler = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      if (e.key === "Enter" && selectedCriteria) {
        updateSearch();
      }
    },
    [selectedCriteria, searchInput, handler]
  );

  const criteriaUpdateHandler = React.useCallback(
    (item: TSearchParameter): void => updateCriteria(item),
    []
  );

  const updateSearch = React.useCallback((): void => {
    if (selectedCriteria && validSearchInput()) {
      const updatedSearch: TSearchQuery[] = _.cloneDeep(search);
      updatedSearch.push({ criteria: selectedCriteria, query: searchInput });
      updateSearchQuery(updatedSearch);
      updateSearchInput("");
      updateTimeout = setTimeout(handler(updatedSearch), 1000);
    }
  }, [
    search,
    selectedCriteria,
    searchInput,
    handler,
    updateSearchInput,
    updateSearchQuery,
    searchQueries,
  ]);

  const removeSearchTag = React.useCallback(
    (tag) => {
      const index = _.findIndex(
        search,
        (item) => item.query.toString() === tag.query.toString()
      );
      const query = [...search];
      if (index !== -1) {
        query.splice(index, 1);
        updateSearchQuery(query);
        handler(query);
      }
    },
    [search, handler, updateSearchQuery]
  );
  const tableColumns = useSelector((state) =>
    tableColumnSelectors.getTableColumns(state, tableId)
  );
  if (tableId && isServerHandled) {
    parameters = query.createFilterOptionsFromColumns(tableColumns);
  }

  return (
    <FilterBarSection>
      <Stack isVertical gutter="small">
        <StackItem>
          <Stack position="center" gutter="small">
            <StackItem>
              <TextInput
                size="medium"
                value={searchInput}
                onChange={searchInputHandler}
                onKeyDown={searchInputPressHandler}
              />
            </StackItem>
            <StackItem>
              <Icon
                faAlias="faSearchPlus"
                isActive={validSearchInput()}
                onClick={updateSearch}
              />
            </StackItem>
            <StackItem>
              <DropDown
                options={parameters}
                updateHandler={criteriaUpdateHandler}
              />
            </StackItem>
            {allowOperator && (
              <StackItem>
                <Switch
                  checkedChildren="AND"
                  unCheckedChildren="OR"
                  checked={operator === "AND"}
                  onChange={updateOperator}
                />
              </StackItem>
            )}
          </Stack>
        </StackItem>
        {search.length ? (
          <StackItem>
            <Stack gutter="medium">
              {_.map(search, (searchQuery: TSearchQuery, index: number) => (
                <React.Fragment>
                  <StackItem key={index}>
                    <Tag item={searchQuery} onClick={removeSearchTag}>
                      <Stack gutter="small">
                        <StackItem>
                          {searchQuery.criteria.displayName}
                        </StackItem>
                        <StackItem>:</StackItem>
                        <StackItem isEllipsis>
                          <div>{searchQuery.query}</div>
                        </StackItem>
                      </Stack>
                    </Tag>
                  </StackItem>
                  {index < search.length - 1 && allowOperator && (
                    <StackItem position="center">
                      <span>{_.startCase(_.lowerCase(operator))}</span>
                    </StackItem>
                  )}
                </React.Fragment>
              ))}
            </Stack>
          </StackItem>
        ) : null}
      </Stack>
    </FilterBarSection>
  );
};

SearchFilter.defaultProps = defaultProps;

export default SearchFilter;
