// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import { isLoading } from 'models/dynamicGrid/selector';

export const initialState = {};
export const name = 'models/loaders';

const slice = createSlice({
	name,
	initialState,
	reducers: {
		toggleLoaderState: (state, { payload: { component, isLoading, status } }) => {
			return {
				...state,
				[component]: { isLoading, status }
			};
		},
		updateComponentRequestStatus: (state, { payload: { component, status, message, isLoading } }) => {
			return {
				...state,
				[component]: { isLoading, status, message }
			};
		}
	}
});

export default slice;

export const { toggleLoaderState, updateComponentRequestStatus } = slice.actions;
