// @ts-nocheck
import Button from 'atoms/button/button';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as generateOrderSelectors from './selectors';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import * as constants from './constants';
import { REQUESTS } from 'utils/requests';

type TProps = {
	hideModal: React.MouseEventHandler;
	handleNextClick: React.MouseEventHandler;
	goBack: React.MouseEventHandler;
};

const SelectStores = (props: TProps) => {
	const { hideModal, handleNextClick, goBack } = props;

	const data = useSelector(state => generateOrderSelectors.getData(state));

	const selectedStores = useSelector(state =>
		dynamicGridSelectors.getTableSelection(state, constants.MODAL_TABLE_IDS.STORES)
	);

	const isLoadingGrid = useSelector(state => dynamicGridSelectors.isLoading(state, constants.MODAL_TABLE_IDS.STORES));
	const hasGridData = useSelector(
		state => dynamicGridSelectors.getGridData(state, constants.MODAL_TABLE_IDS.STORES)?.length
	);

	const [ref, handleUpdateRef] = useState(null);

	const validateFormData = React.useCallback(() => {
		let hasError = true;

		if (
			selectedStores &&
			((selectedStores.isSelectAll && hasGridData) || (selectedStores?.selectedRowKeys && selectedStores?.selectedRowKeys.length))
		) {
			hasError = false;
		}

		return hasError;
	}, [selectedStores]);

	const gridConfig = {
		enableSelection: true,
		enableSorting: true,
		selectionCallBack: () => {}
	};

	const customerOptions = useSelector(state => generateOrderSelectors.getCustomerOptions(state));

	const templateConfig = {
		gridConfig,
		isFilterEnabled: true,
		tableId: constants.MODAL_TABLE_IDS.STORES,
  
		dataProviderOptions: {
			version: 2,
			type: 'POST',
			...REQUESTS.POST.GET_GRID_DETAILS_V2,
			pathParams: { tableId: constants.MODAL_TABLE_IDS.STORES },
			queryParams: {},
			multiSelectFieldGroups: {
				CustomerID: data?.customerID.includes('-1')
				? customerOptions.map(({ value }) => value).join(',')
				: data?.customerID,
				StoreScheduleID: data?.useStoreSchedule ? data.storeScheduleID : undefined,
				StartShipDate: data?.shipStartDate,
				EndShipDate: data?.shipToDate || new Date(),
				WareHouseID: data?.isPrimaryWarehouse ? data.warehouseID : null,
				UsePrimaryWarehouse: Boolean(data.isPrimaryWarehouse),
				UsePlanogram: data.usePlanogram
			},
			body: {
				fieldGroups: {
					CustomerID: data?.customerID.includes('-1')
						? customerOptions.map(({ value }) => value).join(',')
						: data?.customerID,
					StoreScheduleID: data?.useStoreSchedule ? data.storeScheduleID : undefined,
					StartShipDate: data?.shipStartDate,
					EndShipDate: data?.shipToDate || new Date(),
					WareHouseID: data?.isPrimaryWarehouse ? data.warehouseID : null,
					UsePrimaryWarehouse: Boolean(data.isPrimaryWarehouse),
					UsePlanogram: data.usePlanogram
				}
			}
		},
		selectRowKey: 'StoreID'
	};
	return (
		<Stack isVertical gutter="small">
			<StackItem onDomRef={handleUpdateRef} isStretch isGrow>
				<Sheet isTab>
					<SheetBody>
						<ThetaGridPaginated offset={60} parent={ref} isStorageEnabled={false} config={templateConfig} />
					</SheetBody>
				</Sheet>
			</StackItem>
			<StackItem>
				<Stack>
					<StackItem isGrow>
						<Stack align="left" gutter="small">
							<StackItem>
								<Button isSecondary name="Reset Order Info" handleClick={goBack} />
							</StackItem>
						</Stack>
					</StackItem>
					<StackItem>
						<Stack align="right" gutter="small">
							<StackItem isStretch align="right">
								<Button
									disabled={(isLoadingGrid && !hasGridData) || validateFormData()}
									name={'SELECT SKUs'}
									handleClick={handleNextClick}
								/>
							</StackItem>
						</Stack>
					</StackItem>
				</Stack>
			</StackItem>
		</Stack>
	);
};

export default SelectStores;
