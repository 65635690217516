// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';
import * as query from './query';

export const loadAdvertisings = createAction(query.createActionName('load-ads'), (name: string, url: string) => ({
	payload: { name, url }
}));

export const fetchAdsData = createAction(query.createActionName('fetch-ads-data'), (name: string) => ({
	payload: { name }
}));

export const fetchCustomers = createAction(query.createActionName('fetch-ads-customers'), (name: string) => ({
	payload: { name }
}));

export const updateAdsMeta = createAction(query.createActionName('update-ads-meta'), (name: string, metaData: any) => ({
	payload: { name, metaData }
}));

export const fetchAdTypes = createAction(query.createActionName('fetch-ads-types'), (name: string) => ({
	payload: { name }
}));

export const fetchAdTabs = createAction(query.createActionName('fetch-ads-tabs'), payload => ({
	payload
}));

export const fetchStores = createAction(
	query.createActionName('fetch-ads-stores'),
	(name: string, customerId: string) => ({
		payload: { name, customerId }
	})
);

export const deleteAdvertisings = createAction(query.createActionName('delete-ad'), (adId: string) => ({
	payload: { adId }
}));

export const deleteAdType = createAction(query.createActionName('delete-ad-type'), (adTypeId: string) => ({
	payload: { adTypeId }
}));
