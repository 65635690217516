// @ts-nocheck
import BodyText from 'atoms/bodyText/bodyText';
import _ from 'lodash';
import { createFiltersFromQueries } from 'models/dynamicGrid/query';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import { useSelector } from 'react-redux';
import ThetaGrid from 'templates/thetaGrid';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import { REQUESTS } from 'utils/requests';
import { getOptimizeSkusData } from '../api';
import { SELECT_SKUS_TABLE_ID } from './constants';

const SelectSkus = (props: any) => {
	const { selectedTab, parent } = props;

	const selectedOrders = useSelector(state => dynamicGridSelectors.getTableSelection(state, selectedTab));
	const ordersSearch = useSelector(state => dynamicGridSelectors.getGridFilters(state, selectedTab));
	const gridConfig = {
		enableSelection: true,
		enableSorting: true,
		selectionCallBack: () => {}
	};
	const userName = sessionStorage.getItem('userName') || 'None';
	const templateConfig = {
		gridConfig,
		isFilterEnabled: true,
		tableId: SELECT_SKUS_TABLE_ID,
		dataProviderOptions: {
			version: 2,
			...REQUESTS.POST.GET_SHIPMENT_SKU,
			pathParams: { userName },
			type: 'POST',
			multiSelectFieldGroups: {
				OrderIDList: (selectedOrders?.selectedRowKeys || []).join(','),
				isSelectAll: selectedOrders?.isSelectAll,
				AppTableID: Number(selectedTab)
			},
			multiSelectFilterFieldGroups: {
				selectAllSearch: ordersSearch?.search ?? []
			},
			body: {
				orderIds: (selectedOrders?.selectedRowKeys || []).join(','),
				isSelectAll: selectedOrders?.isSelectAll,
				selectAllSearch: JSON.stringify({
					filter: createFiltersFromQueries(ordersSearch?.search)
				}),
				tableId: Number(selectedTab)
			}
		},
		selectRowKey: 'SKUID',
  
	};

	return (
		// <Stack isVertical gutter="medium">
		//   <StackItem isStretch>
		//     <BodyText>Select SKUs for Availability Distribution.</BodyText>
		//   </StackItem>
		<ThetaGridPaginated isStorageEnabled={false} parent={parent} config={templateConfig} />
		// </Stack>
	);
};

export default SelectSkus;
