// @ts-nocheck
import { TSearchParameter } from 'organisms/searchFilter';
import { TTableTab } from './types';
import { REQUESTS } from 'utils/requests';

export const ORDER_TABS = {
	ALL: 23,
	SKUS: 1,
	ITEMS: 2,
	KITS: 30
};

export const ORDER_COLUMN_TABLES = [23, 1, 2];

export const ORDER_TYPES_TABS: TTableTab[] = [
	{
		name: 'All Orders',
		tableId: 23,
		
	},
	{
		name: 'Order Kits',
		tableId: 30,
		
	},

	{
		name: 'Order SKUs',
		tableId: 1,
		
	},
	{
		name: 'Order Items',
		tableId: 2,
		
	}
];

export const LOADER_COMPONENTS = {
	COLUMN_DEF: 'columnDef',
	HEALTH_CHECK: 'healthCheck'
};

export const FILTER_QUERY_TYPES = {
	PER_PAGE: 'perPage',
	STATUS: 'statusFilters',
	QUERIES: 'searchQueries',
	PAGE_NO: 'pageNo',
	SORT_FIELD: 'sortField',
	SORT_DIRECTION: 'sortDirection',
	PAGE_NUMBER: 'pageNumber'
};

export const TABS = [
	{
		name: 'All Orders',
		value: '23'
	},
	{
		name: 'Order Kits',
		value: '30'
	},
	{
		name: 'Order SKUs',
		value: '1'
	},
	{
		name: 'Order Items',
		value: '2'
	}
];

export const SEARCH_PARAMS: TSearchParameter[] = [
	{
		displayName: 'Order Number',
		value: 1,
		rowIdentifier: 'OrderNumber'
	},
	{
		displayName: 'Order Status',
		value: 2,
		rowIdentifier: 'OrderStatus'
	},
	{
		displayName: 'Customer',
		value: 3,
		rowIdentifier: 'CustomerName'
	}
];

export const ORDER_STATUS_PARAMS = [
	{
		displayName: 'Cancelled',
		value: '1'
	},
	{
		displayName: 'Completed',
		value: '2'
	},
	{
		displayName: 'Open',
		value: '3'
	}
];

export const GROUP_EDIT_TYPES = [
	{
		displayName: 'Bulk',
		value: '1'
	}
];

export const ORDER_STATUS_ID_KEY = 'OrderStatusID';

export const TABS_NAMES = {
	ALL_ORDRES: 'All Orders',
	ORDER_SKUS: 'Order SKUs',
	ORDER_ITEMS: 'Order Items',
	ORDER_KITS: 'Order Kits'
};

export const TABS_VALUES = {
	ALL_ORDRES: 23,
	ORDER_SKUS: 1,
	ORDER_ITEMS: 2,
	ORDER_KITS: 30
};

export const SORT_DIRECTION = {
	ASC: 'ASC',
	DESC: 'DESC'
};

export const PREFIX = 'orders';
