// @ts-nocheck
import { createAction, createSlice, Action, AnyAction } from '@reduxjs/toolkit';
import * as sagaActions from './sagaActions';
import * as constants from './constants';
import _ from 'lodash';

export const initialState = {
	uploadStatus: null,
	activePage: constants.PAGES.UPLOAD,
	warehouses: [],
	customers: [], 
};

const slice = createSlice({
	name: 'upload',
	initialState,
	reducers: {
		updateUploadStatus(state, { payload: { uploadStatus } }) {
			return {
				...state,
				uploadStatus
			};
		},
		setActivePage(state, { payload: { activePage } }) {
			return {
				...state,
				activePage
			};
		},
		setUploadMetadata(state, { payload: { metaData, tableId } }) {
			return {
				...state,
				metaData: {
					...state.metaData,
					[tableId]: metaData
				}
			};
		},
		resetUpload() {
			return {
				...initialState
			};
		},

		updateWarehouses(state, { payload: { fetchedWarehouses } }) {
			return {
				...state,
				warehouses: fetchedWarehouses
			};
		},
		updateCustomers(state, { payload: { fetchedCustomers } }) {
			return {
				...state,
				customers: fetchedCustomers
			};
		}
	}
});

export default slice;

export const { setActivePage, updateUploadStatus, resetUpload, updateErpEnabled, updateWarehouses, updateCustomers, setUploadMetadata } =
	slice.actions;
