// @ts-nocheck
import { takeEvery } from 'redux-saga/effects';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';

function* updateRowData({ payload }) {
	// console.log(payload, " -----")
	// if (tableId === 'asas') {
	// }
}

export default function* rootSaga() {
	return [];
}
