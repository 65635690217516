// @ts-nocheck
import * as React from 'react';
import '../sheet.scss';
import classNames from 'classnames';

interface Props {
	//   isFillHeight?: boolean;
	children: React.ReactNode;
}

function SheetAction(props: Props) {
	//   const { isFillHeight } = props;
	const classes: any = classNames(['sheet--action']);

	return <div className={classes}>{props.children}</div>;
}

export default SheetAction;
