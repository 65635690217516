// @ts-nocheck
import { initialState } from './reducer';
import * as constants from './constants';
import { isAllOrdersReadWrite, isOrderItemsReadWrite, isOrderKitsReadWrite, isOrderSkusReadWrite } from 'models/user/selector';

const orderSelector = state => state.orders || initialState;

export const getActiveTableColumnDefinitions = (state, tableId) => {
	return orderSelector(state)[tableId];
};

export const getTableData = (state, selectedTab: string) => {
	return orderSelector(state)['orders'][selectedTab];
};

export const getSelectedOrders = (state, selectedTab: string) => {
	return orderSelector(state)['selectedOrders'][selectedTab];
};
// export const getPaginationFilters = (state, selectedTab: string) => {
//   return orderSelector(state)['pagination'][selectedTab] || {};
// };
export const getOptimizeAggregateInfo = state => {
	return orderSelector(state)?.optimizeAggregateInfo;
};
export const getOptimizeShipMethodNumber = state => {
	return orderSelector(state)?.optimizeShipMethodNumber;
};
export const isHealthCheckActive = state => {
	return orderSelector(state).isHealthCheckEnabled;
};

export const getSelectedHealthCheckId = state => {
	return orderSelector(state).HealthCheckID;
};

export const getIsLoading = (state, tableId) => orderSelector(state).isLoading[tableId];

export const getQueryFilters = (state: any, tableId: string) => {
	return orderSelector(state)['queryFilters'][tableId];
};
// export const fetchDataByAvailabilityTableId = (state, tableId) => {
// 	return tableId === constants.AVAILABILITY_TABLE_ID ? orderSelector(state).availabilityItems :
// 		orderSelector(state).availabilitySummary
// }

export const getCloneSelectedParameters = state => {
	return orderSelector(state)['orderClone']['selectedParameters'];
};

export const getDefaultCloneTypes = state => {
	return orderSelector(state)['orderClone']['cloneTypes'];
};

export const getCloneOrderInfo = state => {
	return orderSelector(state)['orderClone'];
};
//split
export const getSplitSelectedParameters = state => {
	return orderSelector(state)['orderSplit']['selectedParameters'];
};

export const getDefaultSplitTypes = state => {
	return orderSelector(state)['orderSplit']['splitTypes'];
};

export const getSplitOrderInfo = state => {
	return orderSelector(state)['orderSplit'];
};
//update order status
export const getUpdateStatusDefaultStores = state => {
	return orderSelector(state)['updateOrderStatus']['defaultStores'];
};

//update ship method
export const getOrdersShipMethod = state => {
	return orderSelector(state)['updateShipMethods']['defaultShippingMethods'];
};

//open modal
export const getOpenModalName = state => {
	return orderSelector(state)['openModalName'];
};

//bulk order
export const getBulkOrderTypes = state => {
	return orderSelector(state)['bulkOrder']['bulkOrderTypes'];
};

export const getBulkEditParams = state => {
	return orderSelector(state)['bulkOrder']['bulkEditParams'];
};

//open modal
export const getLoadingStatus = state => {
	return orderSelector(state)['loading'];
};

export const getOrderInfo = state => {
	return orderSelector(state).info;
};

export const getRefreshInfoTimestamp = state => {
	return orderSelector(state).refreshInfoTimestamp;
};


export const getOrderTabs = (state) => {
	return [
		{
			name: 'All Orders',
			tableId: 23,
			isEnabled: isAllOrdersReadWrite(state)
		},
		{
			name: 'Order Kits',
			tableId: 30,
			isEnabled: isOrderKitsReadWrite(state)
		},
	
		{
			name: 'Order SKUs',
			tableId: 1,
			isEnabled: isOrderSkusReadWrite(state)
		},
		{
			name: 'Order Items',
			tableId: 2,
			isEnabled: isOrderItemsReadWrite(state)
		}
	].filter(t => t.isEnabled)
}