// @ts-nocheck
import classNames from 'classnames';
import * as React from 'react';
import './button.scss';
import CsvDownload from 'react-json-to-csv';

interface Props {
	name?: string;
	disabled?: boolean;
	handleClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
	isSecondary?: boolean;
	type?: 'primary' | 'secondary' | 'active';
	size?: 'small' | 'large' | 'medium';
	isTransparent?: boolean;
	children?: React.ReactNode;
	isCsv?: boolean;
	isAppButton: boolean;
	csvFileName?: string;
	'data-test-id': string;
	csvFileData?: unknown;
}

function Button(props: Props) {
	const {
		name,
		disabled,
		size,
		isTransparent,
		isCsv,
		handleClick,
		type,
		isSecondary,
		csvFileData,
		csvFileName,
		isAppButton,
		children
	} = props;
	const classes: any = classNames([
		'button',
		{ [`button--secondary`]: isSecondary },
		{ [`button--${type}`]: true },
		{ [`button--${size}`]: true },
		{ [`button--disabled`]: disabled },
		{ 'button--transparent': isTransparent },
		{ 'button--app': isAppButton }
	]);
	return isCsv ? (
		<CsvDownload delimiter={','} disabled={disabled} className={classes} fileName={csvFileName} data={csvFileData}>
			Download
		</CsvDownload>
	) : (
		<button data-test-id={props['data-test-id']} className={classes} disabled={disabled} onClick={handleClick}>
			{children || name}
		</button>
	);
}

export default Button;
