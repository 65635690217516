// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const onActivate = createAction('uploadData/onActivate', function prepare({ tableId }) {
	return {
		payload: {
			tableId
		}
	};
});

export const onUploadFile = createAction('uploadData/onUpload', function prepare({ tableId, params }) {
	return {
		payload: {
			tableId,
			params
		}
	};
});



export const revalidateUploadGrid = createAction('uploadData/revalidate', function prepare({ tableId,isIncrementalUpload  }) {
	return {
		payload: {
			tableId,
			isIncrementalUpload
		}
	};
});

export const onUploadFileV2 = createAction(
	'uploadData/onUploadV2',
	function prepare({ tableId, params, uploadParams }) {
		return {
			payload: {
				tableId,
				params,
				uploadParams
			}
		};
	}
);

export const submitUploadFile = createAction('uploadData/submit', function prepare({ tableId,fieldGroups, parentTableId, params, uploadParams, isIncrementalUpload }) {
	return {
		payload: {
			tableId,
			parentTableId,
			params,
			uploadParams,
			fieldGroups,
			isIncrementalUpload
		}
	};
});
export const triggerFetchDownloadTemplate = createAction(
	'uploadData/triggerFetchDownloadTemplate',
	function prepare({ tableId }) {
		return {
			payload: {
				tableId
			}
		};
	}
);

export const triggerFetchUploadMetadata = createAction(
	'uploadData/triggerFetchUploadMetadata',
	function prepare({ tableId }) {
		return {
			payload: {
				tableId
			}
		};
	}
);

export const handleAsyncGridUpload = createAction(
	'uploadData/handleAsyncGridUpload',
	function prepare(payload) {
		return {
			payload
		};
	}
);

export const resetUpload = createAction('uploadData/resetUpload');

export const getWarehouses = createAction('uploadData/warehouses');
export const getCustomers = createAction('uploadData/customers');
