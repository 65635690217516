// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';
import * as query from './query';

export const loadModal = createAction(query.createActionName('load-modal'), () => ({
	payload: {}
}));

export const setModalView = createAction(query.createActionName('set-modal-view'), (isOpen: boolean) => ({
	payload: { isOpen }
}));

export const updateModalData = createAction(query.createActionName('update-modal-data'), (data: any) => ({
	payload: { data }
}));

export const fetchCustomers = createAction(query.createActionName('fetch-customer-data'), () => ({
	payload: {}
}));

export const fetchShippingMethods = createAction(query.createActionName('fetch-shipping-methods'), () => ({
	payload: {}
}));

export const fetchWarehouses = createAction(query.createActionName('fetch-warehouses'), () => ({
	payload: {}
}));

export const fetchStoreSchedules = createAction(query.createActionName('fetch-store-schedules'), () => ({
	payload: {}
}));

export const generateOrders = createAction(query.createActionName('generate-orders'), (orderInfo, storeOrderSet) => ({
	payload: { orderInfo, storeOrderSet }
}));

export const cleanUp = createAction(query.createActionName('generate-order-clean-up'), () => ({
	payload: {}
}));
