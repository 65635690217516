// @ts-nocheck
import Breadcrumbs from 'atoms/breadcrumbs/breadcrumbs';
import Button from 'atoms/button/button';
import Divider from 'atoms/divider/divider';
import DropDown from 'atoms/dropdown/dropdown';
import Heading from 'atoms/heading/heading';
import Icon from 'atoms/icons/icon';
import Loader from 'atoms/loader';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import _ from 'lodash';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import * as tableColumnSagaActions from 'models/tableColumns/sagaActions';
import * as tableColumnSelectors from 'models/tableColumns/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import CustomizeViewModal from 'organisms/CustomizeViewModal';
import ThetaMenu from 'organisms/thetaMenu/thetaMenu';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import * as constants from './constants';
import * as query from './query';
import * as sagaActions from './sagaActions';
import * as selectors from './selectors';
import StoreScheduleDaysModal from './storeScheduleDaysModal';
import StoreScheduleModal from './storeScheduleModal';
import ThetaDeleteModal from 'organisms/deleteModal/deleteModal';
import ExportGrid from 'organisms/exportGrid';
import { Empty } from 'antd';
import { REQUESTS } from 'utils/requests';
import { isMasterDataSchedulesDelete, isMasterDataSchedulesWrite } from 'models/user/selector';
import GridActionBar from 'atoms/GridActionBar';

const StoreScheduleMasterData: React.FunctionComponent = (props: any) => {
	const [openFilter, setOpenFilter] = React.useState<boolean>(false);
	const [storeScheduleModalMode, setStoreScheduleModalMode] = React.useState<string>(constants.MODAL_MODES.NEW);
	const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
	const dispatch = useDispatch();

	React.useEffect(() => {
		// dispatch(tableColumnSagaActions.fetchTableColumns(constants.TABLE_ID));
		dispatch(sagaActions.loadPage());
	}, [dispatch]);
	const hasDeleteAccess = useSelector(isMasterDataSchedulesDelete);
	const hasWriteAccess = useSelector(isMasterDataSchedulesWrite);
	const hasSearchFilters = useSelector(state => dynamicGridSelectors.hasSearchFilters(state, constants.TABLE_ID));

	const isCustomizeViewModalEnabled = useSelector(state => tableColumnSelectors.isCustomizeViewModalEnabled(state));

	const selectedStores = useSelector(state => dynamicGridSelectors.getTableSelection(state, constants.TABLE_ID));

	const selectedStoresSearch = useSelector(state => dynamicGridSelectors.getGridFilters(state, constants.TABLE_ID));

	const storeScheduleModalVisible = useSelector(state => selectors.isStoreScheduleModalVisible(state));

	const ScheduleDaysModalVisible = useSelector(state => selectors.isScheduleDaysModalVisible(state));

	const storeSchedules = useSelector(state => selectors.getStoreSchedules(state));

	const selectedSchedule = useSelector(state => selectors.getSelectedSchedule(state));

	console.log(selectedSchedule)
	const scheduleDetails = useSelector(state => dynamicGridSelectors.getGridData(state, constants.TABLE_ID));

	const isPageLoading = useSelector(state => selectors.isLoading(state, 'page'));

	const columnLoading = useSelector(state => tableColumnSelectors.isLoading(state, constants.TABLE_ID.toString()));

	React.useEffect(() => {
		if (openFilter === undefined) {
			setOpenFilter(!!hasSearchFilters);
		}
	}, [hasSearchFilters]);

	const handleRefreshClick = React.useCallback(() => {
		dispatch(
			dynamicGridSagaActions.refreshGridData({
				tableId: constants.TABLE_ID,
			})
		);
	}, [dispatch]);

	const handleFilterClick = React.useCallback(() => {
		setOpenFilter(!openFilter);
	}, [openFilter]);

	const handleResetTableColumns = React.useCallback(async () => {
			dispatch(tableColumnSagaActions.resetTableColumnsAg(constants.TABLE_ID));
	}, [dispatch]);

	const customizeViewHandler = (isCustomizeViewModalEnabled: boolean) => {
		dispatch(tableColumnSagaActions.setCustomizeViewModal(isCustomizeViewModalEnabled));
	};

	const hideStoreScheduleModal = () => {
		dispatch(sagaActions.updateStoreScheduleModalVisibility(false));
	};

	const hideStoreScheduleDaysModal = () => {
		dispatch(sagaActions.updateScheduleDaysModalVisibility(false));
	};

	const handleMenuItemClick = React.useCallback(
		(option: string) => {
			switch (option) {
				case constants.MENU_ITEMS_VALUES.UPDATE_SCHEDULE:
					return handleUpdateScheduleClick();
				case constants.MENU_ITEMS_VALUES.DELETE_SCHEDULE:
					return handleDeleteScheduleClick();
				case constants.MENU_ITEMS_VALUES.CUSTOMIZE_VIEW:
					return customizeViewHandler(true);
				case constants.MENU_ITEMS_VALUES.RESET_VIEW:
					return handleResetTableColumns();
				case constants.MENU_ITEMS_VALUES.UPDATE_SCHEDULE_DAYS:
					return handleUpdateScheduleDaysClick();
				default:
					return;
			}
		},
		[handleResetTableColumns]
	);

	const hideCustomizeViewModal = () => {
		customizeViewHandler(false);
	};

	const handleDropdownSelect = React.useCallback(
		(scheduleOption: any): void => {
			const schedule = _.find(storeSchedules, {
				StoreScheduleID: scheduleOption.value
			});

			if (schedule.StoreScheduleID !== selectedSchedule.StoreScheduleID) {
				dispatch(sagaActions.changeSchedule(schedule));
			}
		},
		[storeSchedules, selectedSchedule]
	);

	const handleAddNewScheduleClick = () => {
		setStoreScheduleModalMode(constants.MODAL_MODES.NEW);
		dispatch(sagaActions.updateStoreScheduleModalVisibility(true));
	};

	const handleUpdateScheduleClick = () => {
		setStoreScheduleModalMode(constants.MODAL_MODES.UPDATE);
		dispatch(sagaActions.updateStoreScheduleModalVisibility(true));
	};

	const handleUpdateScheduleDaysClick = () => {
		dispatch(sagaActions.updateScheduleDaysModalVisibility(true));
	};

	const handleDeleteScheduleClick = () => {
		setDeleteModalOpen(true);
	};

	const hideDeleteModal = () => {
		setDeleteModalOpen(false);
	};

	const handleScheduleDelete = () => {
		dispatch(sagaActions.deleteSchedule(selectedSchedule.StoreScheduleID));
		setDeleteModalOpen(false);
	};

	const getDisabledStatus = React.useCallback(
		(option: any) => {
			switch (option.value) {
				case constants.MENU_ITEMS_VALUES.UPDATE_SCHEDULE:
					return !hasWriteAccess || !Boolean(selectedSchedule?.StoreScheduleID);
				case constants.MENU_ITEMS_VALUES.DELETE_SCHEDULE:
					return !hasDeleteAccess || !Boolean(selectedSchedule?.StoreScheduleID);
				case constants.MENU_ITEMS_VALUES.CUSTOMIZE_VIEW:
				case constants.MENU_ITEMS_VALUES.RESET_VIEW:
					return false;
				case constants.MENU_ITEMS_VALUES.UPDATE_SCHEDULE_DAYS:
					return !hasWriteAccess || !(selectedStores && selectedStores?.selectedRowKeys?.length);
				default:
					return !hasWriteAccess;
			}
		},
		[selectedStores, selectedSchedule, hasWriteAccess]
	);

	if (isPageLoading || isPageLoading === undefined) {
		return (
			<Stack isVertical>
				<StackItem isGrow isStretch align="center">
					<Loader />
				</StackItem>
			</Stack>
		);
	}

	const gridConfig = {
		enableSelection: true,
		enableSorting: true,
		enableMultiSort: true,
		selectionCallBack: () => { }
	};

	const getScheduleData = () => {
		let data: any = {
			storeScheduleId: selectedSchedule.StoreScheduleID,
			storeSchedule: selectedSchedule.StoreScheduleDescription
		};

		if (scheduleDetails && scheduleDetails?.length) {
			data = {
				...data,
				selectedDays: scheduleDetails[0].Day?.split(','),
				stores: _.map(scheduleDetails, 'StoreID')
			};
		} else {
			data = {
				...data,
				selectedDays: [],
				stores: []
			};
		}

		return data;
	};

	const templateConfig = {
		gridConfig,
		isFilterEnabled: openFilter === undefined ? false : openFilter,
		tableId: constants.TABLE_ID,
		dataProviderOptions: {
			type: 'POST',
			version: 2,
			...REQUESTS.POST.GET_STORE_SCHEDULE_DETAILS,
			pathParams: { scheduleId: selectedSchedule?.StoreScheduleID }
		},
		selectRowKey: 'StoreID'
	};

	return (
		<React.Fragment>
			<Stack isVertical>
				<StackItem isStretch>
					<GridActionBar>
						<Stack>
							<StackItem isGrow>
								<Breadcrumbs crumbs={props.breadcrumbs} />
							</StackItem>
							<StackItem>
								<Stack gutter="medium">
									<StackItem isGrow />
									<StackItem>
										<Icon
											isActive
											type="secondary"
											faAlias="faRecycle"
											onClick={handleRefreshClick}
										/>
									</StackItem>

									<StackItem>
										<ExportGrid
											tableId={constants.TABLE_ID}
											extraFilters={[
												{
													criteria: {
														displayName: 'Store Schedule ID',
														value: 'ss.StoreScheduleID',
														queryName: 'ss.StoreScheduleID',
														rowIdentifier: 'StoreScheduleID',
														type: 'number',
														label: 'Store Schedule ID'
													},
													query: selectedSchedule?.StoreScheduleID,
													operator: {
														value: 'eq',
														symbol: '=',
														displayName: 'Equals'
													}
												}
											]}
										/>
									</StackItem>
									<StackItem>
										<ThetaMenu
											type="3Dots"
											options={
											 constants.MENU_ITEMS.filter(x => x.value !== 'CUSTOMIZE_VIEW')
											}
											handleItemClick={handleMenuItemClick}
											isDisabled={getDisabledStatus}
										/>
									</StackItem>
								</Stack>
							</StackItem>
						</Stack>

					</GridActionBar>
				</StackItem>
				<StackItem isStretch isGrow>
					<Sheet>
						<SheetBody>
							<Stack isVertical gutter="medium">

								<StackItem>
									<Stack position="center" gutter="medium">
										<StackItem isGrow>
											<Stack position="center" gutter="medium">
												<StackItem>
													<Heading type="secondary">Please select a schedule:</Heading>
												</StackItem>
												<StackItem>
													<DropDown
														options={query.createStoreScheduleDropdownOptions(storeSchedules)}
														updateHandler={handleDropdownSelect}
														selectedOption={
															storeSchedules?.length
																? query.createStoreScheduleDropdownOption(selectedSchedule)
																: ''
														}
													/>
												</StackItem>
											</Stack>
										</StackItem>
										{hasWriteAccess && <StackItem>
											<Button name={'ADD A NEW SCHEDULE'} handleClick={handleAddNewScheduleClick}>
												<Stack position="center" gutter="small">
													<StackItem>
														<Icon isActive type="tertiary" faAlias="faPlus" size="tiny" />
													</StackItem>
													<StackItem>Create</StackItem>
												</Stack>
											</Button>
										</StackItem>}
									</Stack>
								</StackItem>
								<StackItem isGrow isStretch>
									{!selectedSchedule?.StoreScheduleID ? (
										<Stack>
											<StackItem isStretch isGrow align="center">
												<Empty></Empty>
											</StackItem>
										</Stack>
									) : (
										<ThetaGridPaginated key={selectedSchedule?.StoreScheduleID} config={templateConfig} />
									)}
								</StackItem>
							</Stack>
						</SheetBody>
					</Sheet>
				</StackItem>
			</Stack>

			{isCustomizeViewModalEnabled && (
				<CustomizeViewModal hideModal={hideCustomizeViewModal} tableId={constants.TABLE_ID} />
			)}
			{storeScheduleModalVisible && (
				<StoreScheduleModal
					hideModal={hideStoreScheduleModal}
					mode={storeScheduleModalMode}
					data={storeScheduleModalMode === constants.MODAL_MODES.UPDATE ? getScheduleData() : undefined}
				/>
			)}
			{ScheduleDaysModalVisible && (
				<StoreScheduleDaysModal
					storeScheduleID={selectedSchedule.StoreScheduleID}
					hideModal={hideStoreScheduleDaysModal}
					selectedStores={selectedStores}
					selectedStoresSearch={selectedStoresSearch}
				/>
			)}
			{deleteModalOpen && (
				<ThetaDeleteModal
					hideModal={hideDeleteModal}
					modalTitle={'Schedule'}
					title={selectedSchedule.StoreScheduleDescription}
					handleDeleteClick={handleScheduleDelete}
				/>
			)}
		</React.Fragment>
	);
};

export default StoreScheduleMasterData;
