// @ts-nocheck
import { createFiltersFromQueries } from 'models/dynamicGrid/query';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import React from 'react';
import { useSelector } from 'react-redux';
import ThetaGrid from 'templates/thetaGrid';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import ThetaGridPaginatedAg from 'templates/thetaGridPaginated/thetaGridPaginatedAg';
import { REQUESTS } from 'utils/requests';
import { getOrderDetails } from '../api';
import * as selectors from '../selectors';
import { SELECT_ORDER_DETAILS_TABLE_ID } from './constants';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { updateGridMeta } from 'models/dynamicGrid/sagaActions';
import { useDispatch } from 'react-redux';
import { getGridMetadata } from 'models/dynamicGrid/selector';
import { updateMetadata } from 'models/dynamicGrid/reducer';
import { useState } from 'react';

const SelectOrderDetails = (props: any) => {
	const orderSplitInfo = useSelector(state => selectors.getSplitOrderInfo(state));

	const selectedOrders = useSelector(state => dynamicGridSelectors.getTableSelection(state, 23));
	const metaData = useSelector(state => getGridMetadata(state,SELECT_ORDER_DETAILS_TABLE_ID ));
	const ordersSearch = useSelector(state => dynamicGridSelectors.getGridFilters(state, 23));

	const gridConfig = {
		enableSelection: false,
		enableSorting: true,
		selectionCallBack: () => {}
	};
	const dispatch = useDispatch();
	const templateConfig = {
		gridConfig,
		isFilterEnabled: true,
		tableId: SELECT_ORDER_DETAILS_TABLE_ID,
		dataProviderOptions: {
			type: 'POST',
			...REQUESTS.POST.GET_ORDER_SPLIT_PARAMETERS,
			multiSelectFieldGroups: {
				OrderSplitID: props?.orderSplitId
			},
			body: {
				refresh: new Date().getTime().toString(),
				// OrderID: selectedOrders?.selectedRowKeys.join(','),
				splitOrderTypeID: orderSplitInfo?.selectedParameters?.orderSplitType,
				newPODate: orderSplitInfo?.selectedParameters.poDate,
				newShipDate: orderSplitInfo?.selectedParameters.shipDate,
				ordersSearch: JSON.stringify({
					filter: createFiltersFromQueries(ordersSearch.search)
				}),
				isSelectAllOrders: selectedOrders.isSelectAll,
				orderIds: selectedOrders?.selectedRowKeys?.join(','),
				tableId: Number(props.tableId)
			},
			version: 2
		},

		selectRowKey: 'OrderID'
	};	
	const [isUpdating, setUpdating] = useState(false);

	
	
	return <ThetaGridPaginated onDataRender={()=>{
				dispatch(
					updateGridMeta(SELECT_ORDER_DETAILS_TABLE_ID, {
						 			dataProviderOptions: templateConfig.dataProviderOptions,
						} ?? {},true 
					 ));
	}}  isStorageEnabled={false} parent={props.parent} config={templateConfig} />;
};

export default SelectOrderDetails;
