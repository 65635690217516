// @ts-nocheck
import { TSearchParameter } from 'organisms/searchFilter';

export const PARAMETER_TYPES = {
	GLOBAL: 1,
	GROUPS: 2,
	CUSTOMERS: 3,
	STORES: 4,
	SKUS: 5,
	DEFAULT: 0
};

export const LOADER_COMPONENTS = {
	GROUPS: 'groups',
	PARAMETER_DETAILS: 'parameter-details'
};

export const PARAMETERS_TABS = [
	{
		name: 'Global',
		value: 1
	},
	{
		name: 'Groups',
		value: 2
	},
	{
		name: 'Customers',
		value: 3
	},
	{
		name: 'Stores',
		value: 4
	},
	{
		name: 'SKUs',
		value: 5
	},
	{
		name: 'Default',
		value: 0
	}
];

export const DATA_SELECTORS = {
	3: 'CustomerID',
	4: 'StoreID',
	5: 'SKUID',
	2: 'GroupID'
};

export const FILTER_QUERY = {
	[PARAMETER_TYPES.STORES]: [
		{
			displayName: 'Store Description',
			value: 'StoreDescription',
			rowIdentifier: 'StoreDescription'
		},
		{
			displayName: 'Store',
			value: 'Store',
			rowIdentifier: 'Store'
		}
	],
	[PARAMETER_TYPES.SKUS]: [
		{
			displayName: 'SKU',
			value: 'SKU',
			rowIdentifier: 'SKU'
		},
		{
			displayName: 'Sku Description',
			value: 'SkuDescription',
			rowIdentifier: 'SkuDescription'
		}
	],
	[PARAMETER_TYPES.GROUPS]: [
		{
			displayName: 'Group Title',
			value: 'GroupTitle',
			rowIdentifier: 'GroupTitle'
		},
		{
			displayName: 'Group Description',
			value: 'GroupDescription',
			rowIdentifier: 'GroupDescription'
		}
	],
	[PARAMETER_TYPES.CUSTOMERS]: [
		{
			displayName: 'Customer Name',
			value: 'CustomerName',
			rowIdentifier: 'CustomerName'
		}
	]
};
