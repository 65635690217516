// @ts-nocheck
import _ from 'lodash';

const ORDER_STATUS_TABLE_ID = [23, 1, 2];

export const createGridRows = (tableData: any[]) => {
	return _.map(tableData, (row, index) => ({
		index,
		metaData: row
	}));
};

export const checkFilterOpen = (filters: any, tableId: any) => {
	if (ORDER_STATUS_TABLE_ID.includes(tableId)) {
		return filters?.search?.length > 1;
	}
	return Boolean(filters?.search?.length);
};
