// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';
import * as query from './query';
//get --> fetch
//post --> trigger /

export const updateModalToggle = createAction(query.createActionName('open-ad-modal'), (eventName, isOpen) => ({
	payload: { eventName, isOpen }
}));

export const updateAdData = createAction(query.createActionName('update-ad-data'), (eventName, data) => ({
	payload: { eventName, data }
}));

export const editAdData = createAction(query.createActionName('edit-ad'), (eventName, data) => ({
	payload: { eventName, data }
}));

export const createPricingData = createAction(query.createActionName('create-pricing-data'), data => ({
	payload: { data }
}));

export const updatePricingData = createAction(query.createActionName('update-pricing-data'), data => ({
	payload: { data }
}));

export const cleanUp = createAction('clean-up');

export const editAdType = createAction(query.createActionName('edit-ad-type'), (eventName, data) => ({
	payload: { eventName, data }
}));

export const updateAdType = createAction(query.createActionName('update-ad-type'), (eventName, data) => ({
	payload: { eventName, data }
}));

export const saveAdType = createAction(query.createActionName('save-ad-type'), title => ({
	payload: { title }
}));

export const createNewAdvertising = createAction(query.createActionName('create-ad'), payload => ({
	payload
}));

export const updateAdvertising = createAction(query.createActionName('create-ad'), payload => ({
	payload
}));
