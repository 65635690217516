// @ts-nocheck
import { initialState } from './reducer';
import * as HomeSelectors from './reducer';
import { getItemFromLocalStorage } from 'utils/storage';

const homeSelector = state => state.home || initialState;

export const getInventoryTableData = state => {
	return homeSelector(state)['inventoryData'];
};

export const getIsLoading = (state, attr) => homeSelector(state).isLoading[attr];

export const getStatusID = (state: any) => homeSelector(state).statusID || getItemFromLocalStorage('statusID');

export const getStatusIntervalID = (state: any) => homeSelector(state).statusIntervalID;
