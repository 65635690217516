// @ts-nocheck
import _ from 'lodash';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import { triggerNotification } from 'organisms/notifications/sagaActions';
import { put, select, takeEvery } from 'redux-saga/effects';
import { deleteAPIDataV2, deleteAPIDataV3, getAPIDataV2, getAPIDataV3, postAPIDataV3, putAPIDataV2, putAPIDataV3 } from 'utils/api';
import { getItemFromLocalStorage } from 'utils/storage';
import * as constants from './constants';
import * as slice from './reducer';
import * as sagaActions from './sagaActions';
import * as selectors from './selectors';
import * as dynamicGridQuery from 'models/dynamicGrid/query';
import { MODAL_TABLE_IDS } from './storeScheduleModal/constants';
import { REQUESTS } from 'utils/requests';
import { getWebAppSettings } from 'models/user/selector';
import { getState } from 'reducers';

//un
const getStoreSchedules = async (): Promise<{
	data: any[];
	isError: boolean;
}> => {
	try {
		const { data } = await getAPIDataV3({...REQUESTS.GET.GET_STORE_SCHEDULES});
		return { data: data.data.storeSchedules, isError: false };
	} catch (error) {
		return { data: [], isError: true };
	}
};

const getStoreScheduleDetails = async (
	scheduleId: number,
	query: any
): Promise<{
	data: any[];
	isError: boolean;
}> => {
	try {
		const { data } = await postAPIDataV3({
      service: REQUESTS.POST.GET_STORE_SCHEDULE_DETAILS.service,
      url: REQUESTS.POST.GET_STORE_SCHEDULE_DETAILS.url,
      body: query,
      pathParams: { scheduleId: scheduleId || 0}
    });
		return { data: data.data, isError: false };
	} catch (error) {
		return { data: [], isError: true };
	}
};

const postCreateSchedule = async (body: any) => {
	try {
		const { data } = await postAPIDataV3({...REQUESTS.POST.POST_STORE_SCHEDULE, body: body});
		return { isError: false, data: data.data };
	} catch (error) {
		return { error, isError: true };
	}
};

const putUpdateSchedule = async (storeScheduleId: string | number, body: any) => {
	try {
		const { data } = await putAPIDataV3({...REQUESTS.PUT.UPDATE_STORE_SCHEDULE, pathParams: {scheduleId:storeScheduleId}, body: body});
		return { isError: false, data: data.data };
	} catch (error) {
		return { error, isError: true };
	}
};

const putUpdateStoreScheduleBulk = async (storeScheduleId: string | number, data: any) => {
	try {
		await putAPIDataV3({...REQUESTS.PUT.UPDATE_STORE_SCHEDULE_BULK,pathParams: {scheduleId:storeScheduleId}, body: data});
		return { isError: false };
	} catch (error) {
		return { error, isError: true };
	}
};

const deleteStoreSchedule = async (storeScheduleId: string | number) => {
	try {
		await deleteAPIDataV3({
      service: REQUESTS.DELETE.DELETE_STORE_SCHEDULE.service,
      url: REQUESTS.DELETE.DELETE_STORE_SCHEDULE.url,
      pathParams: { scheduleId: storeScheduleId },
    });
		return { isError: false };
	} catch (error) {
		return { error, isError: true };
	}
};

function* loadPage({ payload }: any) {
	yield put(slice.updateLoader({ eventName: 'page', isLoading: true }));

	let filters = getItemFromLocalStorage(constants.TABLE_ID);

	if (!filters) {
		filters = constants.INITIAL_FILTER;
	}

	yield put(dynamicGridSagaActions.updateGridFilters(constants.TABLE_ID, filters));

	const data = yield getSchedules();
	if (data?.length) {
		yield put(
			slice.updateSelectedSchedule({
				selectedSchedule:
					data.find(s => s.StoreScheduleID === payload?.selectedSchedule?.storeScheduleId) ??
					data[data?.length - 1]
			})
		);
		// yield getScheduleDetails({
		//   payload: { scheduleId: data[0].StoreScheduleID },
		// });
	}

	yield put(slice.updateLoader({ eventName: 'page', isLoading: false }));
}

function* getSchedules() {
	yield put(slice.updateLoader({ eventName: 'storeScheduleList', isLoading: true }));

	const { data, isError } = yield getStoreSchedules();

	if (isError) {
		// TODO: Handler Error
		yield put(triggerNotification({ message: 'Failed to get Schedules, Please try again later.' }));
		yield put(slice.updateLoader({ eventName: 'storeScheduleList', isLoading: false }));
		return;
	}

	yield put(slice.updateStoreSchedulesList({ data: data || [] }));

	yield put(slice.updateLoader({ eventName: 'storeScheduleList', isLoading: false }));

	return data;
}

function* getScheduleDetails({ payload }: any) {
	const { scheduleId } = payload;

	yield put(slice.updateLoader({ eventName: 'scheduleDetails', isLoading: true }));

	const filters = yield select(state => selectors.getFilters(state));

	const query = {
		itemsPerPage: filters.itemsPerPage,
		pageNumber: filters.pageNumber
	};

	const { data, isError } = yield getStoreScheduleDetails(scheduleId, query);

	if (isError) {
		yield put(triggerNotification({ message: 'Failed to get Schedule Details, Please try again later.' }));
		yield put(slice.updateLoader({ eventName: 'scheduleDetails', isLoading: false }));
		return;
		// TODO: Error Handler
	}

	yield put(dynamicGridSagaActions.updateGridData(constants.TABLE_ID, data));

	yield put(slice.updateLoader({ eventName: 'scheduleDetails', isLoading: false }));
}

function* changeSchedule({ payload }: any) {
	const { schedule } = payload;

	yield put(slice.updateSelectedSchedule({ selectedSchedule: schedule }));

	// yield getScheduleDetails({
	//   payload: { scheduleId: schedule.StoreScheduleID },
	// });
}

function* createSchedule({ payload }: any) {
	const { schedule } = payload;
	yield put(slice.updateLoader({ eventName: 'createSchedule', isLoading: true }));

	const postBody = {
		description: schedule.storeSchedule
	};
	const { isError, data, error } = yield postCreateSchedule(postBody);
	if (isError) {
		yield put(
			triggerNotification({
				message: error?.data?.message || 'Failed to Create a Schedule, Please try again later.'
			})
		);
		yield put(slice.updateLoader({ eventName: 'createSchedule', isLoading: false }));
		return;
	}
	const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

	const updateBody = {
		storeId: schedule.stores.join(','),
		day: schedule.days.join(','),
		isScheduled: true,
		isView,
		isSelectAll: schedule.isSelectAll,
		selectAllSearch: JSON.stringify({
			filter: dynamicGridQuery.createFiltersFromQueries(schedule?.selectAllSearch)
		})
	};

	const updateBulk = yield putUpdateStoreScheduleBulk(data?.StoreScheduleID, updateBody);

	if (updateBulk?.isError) {
		yield put(
			triggerNotification({ message: updateBulk.error || 'Failed to Create a Schedule, Please try again later.' })
		);
		yield put(slice.updateLoader({ eventName: 'createSchedule', isLoading: false }));
		return;
	}

	yield put(slice.updateLoader({ eventName: 'createSchedule', isLoading: false }));
	yield loadPage({});
	yield put(dynamicGridSagaActions.cleanUpGrids({ tableIds: MODAL_TABLE_IDS.STORES }));

	yield put(
		slice.updateStoreScheduleModalVisibility({
			isStoreScheduleModalVisible: false
		})
	);
}

function* updateSchedule({ payload }: any) {
	const { schedule } = payload;

	yield put(slice.updateLoader({ eventName: 'updateSchedule', isLoading: true }));
	const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

	const postBody = {
		description: schedule.storeSchedule,
		isActive: true,
		isView
	};

	const { isError, error } = yield putUpdateSchedule(schedule.storeScheduleId, postBody);

	if (isError) {
		yield put(
			triggerNotification({
				message: error?.data?.message || 'Failed to update Schedule, Please try again later.'
			})
		);
		yield put(slice.updateLoader({ eventName: 'updateSchedule', isLoading: false }));
		return;
	}

	const updateBody = {
		storeId: schedule?.stores.join(','),
		day: schedule.days.join(','),
		isScheduled: true,
		isView,
		isSelectAll: schedule.isSelectAll,
		selectAllSearch: JSON.stringify({
			filter: dynamicGridQuery.createFiltersFromQueries(schedule.selectAllSearch)
		})
	};

	const updateBulk = yield putUpdateStoreScheduleBulk(schedule.storeScheduleId, updateBody);

	if (updateBulk?.isError) {
		yield put(
			triggerNotification({ message: updateBulk.error || 'Failed to update Schedule, Please try again later.' })
		);
		yield put(slice.updateLoader({ eventName: 'updateSchedule', isLoading: false }));
		return;
	}

	yield put(slice.updateLoader({ eventName: 'updateSchedule', isLoading: false }));

	yield put(
		slice.updateStoreScheduleModalVisibility({
			isStoreScheduleModalVisible: false
		})
	);
	yield getSchedules();

	const schedules = yield select(state => selectors.getStoreSchedules(state));

	yield put(
		slice.updateSelectedSchedule({
			selectedSchedule: _.find(schedules, {
				StoreScheduleID: schedule?.storeScheduleId
			})
		})
	);
	yield loadPage({
		payload: {
			selectedSchedule: schedule
		}
	});
	yield put(dynamicGridSagaActions.cleanUpGrids({ tableIds: MODAL_TABLE_IDS.STORES }));

	// yield getScheduleDetails({
	//   payload: { scheduleId: schedule.storeScheduleId },
	// });
}

function* updateScheduleDays({ payload }: any) {
	const { schedule } = payload;
	const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

	yield put(slice.updateLoader({ eventName: 'updateScheduleDays', isLoading: true }));
	const updateBody = {
		storeId: schedule.stores.join(','),
		day: schedule.days.join(','),
		isScheduled: true,
		isView,
		isSelectAll: schedule.isSelectAll,
		selectAllSearch: JSON.stringify({
			filter: dynamicGridQuery.createFiltersFromQueries(schedule.selectAllSearch.search)
		})
	};

	const { isError } = yield putUpdateStoreScheduleBulk(schedule.storeScheduleId, updateBody);

	if (isError) {
		yield put(
			triggerNotification({ message: updateBulk.error || 'Failed to update Schedule, Please try again later.' })
		);
		yield put(slice.updateLoader({ eventName: 'updateScheduleDays', isLoading: false }));
		return;
	}

	yield put(slice.updateLoader({ eventName: 'updateScheduleDays', isLoading: false }));

	yield put(
		slice.updateScheduleDaysModalVisibility({
			isScheduleDaysModalVisible: false
		})
	);

	yield getScheduleDetails({
		payload: { scheduleId: schedule.storeScheduleId }
	});
	yield loadPage({
		payload: {
			selectedSchedule: schedule
		}
	});
	yield put(dynamicGridSagaActions.cleanUpGrids({ tableIds: MODAL_TABLE_IDS.STORES }));
}

function* deleteSchedule({ payload }: any) {
	const { storeScheduleId } = payload;

	yield put(slice.updateLoader({ eventName: 'deleteSchedule', isLoading: true }));

	const { isError, data, error } = yield deleteStoreSchedule(storeScheduleId);

	if (isError) {
		yield put(triggerNotification({ message: 'Failed to delete Schedule, Please try again later.' }));
		yield put(slice.updateLoader({ eventName: 'deleteSchedule', isLoading: false }));
		return;
	}

	yield put(slice.updateLoader({ eventName: 'deleteSchedule', isLoading: false }));

	yield loadPage({});
}

function* updateStoreScheduleModalVisibility({ payload }: any) {
	const { isStoreScheduleModalVisible } = payload;

	yield put(slice.updateStoreScheduleModalVisibility({ isStoreScheduleModalVisible }));
}

function* updateScheduleDaysModalVisibility({ payload }: any) {
	const { isScheduleDaysModalVisible } = payload;

	yield put(slice.updateScheduleDaysModalVisibility({ isScheduleDaysModalVisible }));
}

export default function* rootSaga() {
	return [
		yield takeEvery(sagaActions.loadPage, loadPage),
		yield takeEvery(sagaActions.changeSchedule, changeSchedule),
		yield takeEvery(sagaActions.getScheduleDetails, getScheduleDetails),
		yield takeEvery(sagaActions.createSchedule, createSchedule),
		yield takeEvery(sagaActions.updateSchedule, updateSchedule),
		yield takeEvery(sagaActions.updateScheduleDays, updateScheduleDays),
		yield takeEvery(sagaActions.deleteSchedule, deleteSchedule),
		yield takeEvery(sagaActions.updateStoreScheduleModalVisibility, updateStoreScheduleModalVisibility),
		yield takeEvery(sagaActions.updateScheduleDaysModalVisibility, updateScheduleDaysModalVisibility)
	];
}
