// @ts-nocheck
import { createAction, createSlice, Action, AnyAction } from '@reduxjs/toolkit';
import * as sagaActions from './sagaActions';
export const initialState = {
  healthCheckData: [],
};

interface RejectedAction extends Action {
  error: Error;
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith('rejected');
}
const slice = createSlice({
  name: 'healthCheck',
  initialState,
  reducers: {
    updateHealthCheckData(state, { payload: { healthCheckData } }) {
      return {
        ...state,
        healthCheckData,
      };
    },
  },
});

export default slice;

export const { updateHealthCheckData } = slice.actions;
