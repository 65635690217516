// @ts-nocheck
export const TABS_IDENTIFIER = {
	STORES: 'Store',
	SKUS: 'SKU',
	CUSTOMERS: 'Customer'
};

export const DEFAULT_TABS = [
	{
		title: 'Stores',
		identifier: TABS_IDENTIFIER.STORES
	},
	{
		title: 'SKUs',
		identifier: TABS_IDENTIFIER.SKUS
	},
	{
		title: 'Customers',
		identifier: TABS_IDENTIFIER.CUSTOMERS
	}
];

export const TABLE_ID = 46;
