// @ts-nocheck
export const TABLE_ID = 21;

export const STORE_PREFIX = 'skuItemMapping';

export const MENU_ITEMS_NAMES = {
  CUSTOMIZE_VIEW: 'Customize View',
  RESET_VIEW: 'Reset View',
  UPLOAD_DATA: 'Upload Data',
  DELETE: "Delete",

};

export const MENU_ITEMS_VALUES = {
  CUSTOMIZE_VIEW: 'CUSTOMIZE_VIEW',
  RESET_VIEW: 'RESET_VIEW',
  UPLOAD_DATA: 'UPLOAD_DATA',
  DELETE: "DELETE",

};

export const MENU_ITEMS = [
  {
    displayName: MENU_ITEMS_NAMES.CUSTOMIZE_VIEW,
    value: MENU_ITEMS_VALUES.CUSTOMIZE_VIEW,
  },
  {
    displayName: MENU_ITEMS_NAMES.RESET_VIEW,
    value: MENU_ITEMS_VALUES.RESET_VIEW,
  },
  {
    displayName: MENU_ITEMS_NAMES.UPLOAD_DATA,
    value: MENU_ITEMS_VALUES.UPLOAD_DATA,
  },
  {
    displayName: MENU_ITEMS_NAMES.DELETE,
    value: MENU_ITEMS_VALUES.DELETE,
  },
];
