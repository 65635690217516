// @ts-nocheck
import _ from 'lodash';
import * as constants from './constants';
import * as query from './query';
import { initialState } from './reducer';
import { TReducer, TTableColum, TTableColumnGroup, TColumnDefinition, TGridColumn } from './types';
import { createComponent } from './gridComponents';
import { createSelector } from 'reselect';
import { getWebAppSettings } from 'models/user/selector';

const stateData = (state: any): TReducer => state[constants.MODEL_NAME] || initialState;

export const isAppActive = (state: any) => {
	return stateData(state).isActive;
};

export const hasNetworkError = (state: any) => {
	return stateData(state).hasNetworkError;
};

export const isInvalidUser = (state: any) => {
	return stateData(state).isInvalidUser;
};

export const isLoggedOut = (state: any) => {
	return stateData(state).isLoggedOutUser;
};

export const isNewVersionAvailable = (state: any) => {
	return (
		localStorage.getItem('releaseVersion') &&
		getWebAppSettings(state)?.ReleaseVersion !== localStorage.getItem('releaseVersion')
	);
};
