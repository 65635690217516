// @ts-nocheck
import { triggerFetchAutoComplete } from 'models/autoComplete/sagaActions';
import { createFiltersFromQueries } from 'models/dynamicGrid/query';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import { getGridFilters, getTableSelection } from 'models/dynamicGrid/selector';
import { toggleLoader } from 'models/loaders/sagaActions';
import { GRID_EVENTS } from 'molecules/ReactGrid/agGrid/constants';
import { Events } from 'molecules/ReactGrid/agGrid/utils/events';
import { getDispatch, getState } from 'reducers';
import { takeEvery } from 'redux-saga/effects';
import { getAPIDataV3, postAPIDataV3, putAPIDataV3 } from 'utils/api';
import { REQUESTS } from 'utils/requests';

import { ACL_TAGS_COMPONENTS, PG_TABLE_ID } from './constants';
import * as slice from './reducer';
import * as sagaActions from './sagaActions';

async function fetchDemandPlanningGroups() {
	const dispatch = getDispatch();

	dispatch(
		toggleLoader({
			component: 'fetchDemandPlanningGroups',
			isLoading: true
		})
	);
	const { data, isError } = await getAPIDataV3({
    service: REQUESTS.GET.GET_DIMENSIONS.service,
    url: REQUESTS.GET.GET_DIMENSIONS.url,
  })
    .then((response) => ({ data: response?.data }))
    .catch((e) => ({ isError: true }));
	dispatch(slice.setAvailableDimensions({ demandPlanningDimensions: data?.data ?? [] }));
	dispatch(
		toggleLoader({
			component: 'fetchDemandPlanningGroups',
			isLoading: true
		})
	);
}

async function fetchDemandPlanningGroupDetails({ payload: { planningGroupId } }) {
	const dispatch = getDispatch();

	dispatch(
		toggleLoader({
			component: 'demandGroupDetails/' + planningGroupId,
			isLoading: true
		})
	);
	const attributes = await getAPIDataV3({
    service: REQUESTS.GET.GROUP_ATTRIBUTES.service,
    url: REQUESTS.GET.GROUP_ATTRIBUTES.url,
    pathParams: {planningGroupId}
  })
		.then(response => ({ data: response?.data?.data }))
		.catch(e => ({ isError: true }));
	dispatch(
		slice.setDimensionAttributes({
			dimensionPlanningGroupId: planningGroupId,
			attributes: attributes?.data
		})
	);
	dispatch(
		toggleLoader({
			component: 'demandGroupDetails' + planningGroupId,
			isLoading: false
		})
	);
	// dispatch(slice.setAvailableDimensions({ demandPlanningDimensions: data?.data ?? [] }));
}

async function saveAttributeDetailEffect({ payload: { attributeDetails, groupSelectionOptions } }) {
	const dispatch = getDispatch();

	dispatch(
		toggleLoader({
			component: 'saveAttributeDetail',
			isLoading: true
		})
	);
	const attributes = await putAPIDataV3({service: REQUESTS.PUT.UPDATE_DEMAND_PLANNING_GROUPS.service, url: REQUESTS.PUT.UPDATE_DEMAND_PLANNING_GROUPS.url, body: {
		planningGroupID: attributeDetails.planningGroupId,
		planningGroupValue: attributeDetails.planningGroupValue,
		groupAttribute: attributeDetails.groupAttribute,
		isSelectAll: groupSelectionOptions?.isSelectAll,
		selectAllSearch: groupSelectionOptions?.selectAllSearch,
		groupID: attributeDetails.groupId
	}})
		.then(response => ({ data: response?.data?.data }))
		.catch(e => ({ isError: true }));

	dispatch(
		dynamicGridSagaActions.refreshGridData({
			tableId: PG_TABLE_ID,
			isSoftRefresh: true
		})
	);
	dispatch(
		slice.setSelectedGroupAttributeDetail({
			attributeDetails: null
		})
	);
	dispatch(
		toggleLoader({
			component: 'saveAttributeDetail',
			isLoading: false
		})
	);
}

async function deletePlanningGroupEffect({ payload: { planningGroupId, rowIdentifier, isBulkDelete } }) {
	const dispatch = getDispatch();
	const { selectedRowKeys, isSelectAll } = getTableSelection(getState(), PG_TABLE_ID);
	const filters = getGridFilters(getState(), PG_TABLE_ID);
	dispatch(
		toggleLoader({
			component: 'deletePlanningGroup',
			isLoading: true
		})
	);
	const attributes = await putAPIDataV3({service: REQUESTS.PUT.DELETE_DEMAND_PLANNING_GROUP.service, url: REQUESTS.PUT.DELETE_DEMAND_PLANNING_GROUP.url, body: {
		
		...(isBulkDelete ? {
			planningGroupId,
			rowIdentifier: selectedRowKeys,
			isSelectAll,
			selectAllSearch: JSON.stringify({
				filter: createFiltersFromQueries(filters.search)
			}),
		} : {
			planningGroupId,
			rowIdentifier
		})
	}})
		.then(response => ({ data: response?.data?.data }))
		.catch(e => ({ isError: true }));

		Events.publish(GRID_EVENTS.CLEAR_SELECTION, {
			tableId: PG_TABLE_ID,
		});
	dispatch(
		dynamicGridSagaActions.refreshGridData({
			tableId: PG_TABLE_ID,
			isSoftRefresh: true
		})
	);
	dispatch(
		toggleLoader({
			component: 'deletePlanningGroup',
			isLoading: false
		})
	);
}

export const toggleTagStateEffect = async ({ payload: { tagId, state } }) => {
	const dispatch = getDispatch();
	await putAPIDataV3({service: REQUESTS.PUT.UPDATE_DEMAND_PLANNING_TAG.service, url: REQUESTS.PUT.UPDATE_DEMAND_PLANNING_TAG.url, body: {
		state,
		tagId
	}
});



	if (state === 'Active' || state === 'Inactive') {
		dispatch(triggerFetchAutoComplete({
			componentId: ACL_TAGS_COMPONENTS.UNUSED
		}))
		dispatch(triggerFetchAutoComplete({
			componentId: ACL_TAGS_COMPONENTS.ACTIVE
		}))
	}

	if (state === 'Deleted') {
		dispatch(triggerFetchAutoComplete({
			componentId: ACL_TAGS_COMPONENTS.UNUSED
		}))
	}
}

const createTag = async ({ payload: {tagName } }) => {
	const dispatch = getDispatch();
	await postAPIDataV3({service: REQUESTS.POST.ADD_DEMAND_PLANNING_TAG.service, url: REQUESTS.POST.ADD_DEMAND_PLANNING_TAG.url, body: {
		tagName
	}});
	dispatch(triggerFetchAutoComplete({
		componentId: ACL_TAGS_COMPONENTS.UNUSED
	}))

}

export default function* rootSaga() {
	return [
		yield takeEvery(sagaActions.triggerFetchDemandPlanningGroups, fetchDemandPlanningGroups),
		yield takeEvery(sagaActions.triggerSaveDemandPlanningAttributeDetail, saveAttributeDetailEffect),
		yield takeEvery(sagaActions.triggerDeleteDemandPlanningGroup, deletePlanningGroupEffect),
		yield takeEvery(sagaActions.triggerUpdateToggleTagState, toggleTagStateEffect),
		yield takeEvery(sagaActions.triggerCreateTag, createTag),



		yield takeEvery(sagaActions.triggerFetchDemandPlanningGroupDetails, fetchDemandPlanningGroupDetails)
	];
}
