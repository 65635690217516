// @ts-nocheck
import Button from 'atoms/button/button';
import Loader from 'atoms/loader';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as groupModalSelectors from 'organisms/groupModal/selector';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import { REQUESTS } from 'utils/requests';

import { Tprops } from './types';

const SelectMembers = (props: Tprops) => {
	const { hideModal, handleNextClick, goBack } = props;
	const name = 'selectedMembers';
	const isLoading = useSelector(state => groupModalSelectors.isLoading(state));

	const gridConfig = {
		enableSelection: true,
		enableSorting: true,
		selectionCallBack: () => {}
	};

	const selectedMembers = useSelector(state => dynamicGridSelectors.getTableSelection(state, 20));


	const templateConfig = {
		gridConfig,
		isFilterEnabled: true,
		tableId: 20,
		dataProviderOptions: {
			type: 'POST',
			version: 2,
			...REQUESTS.POST.GET_GRID_DETAILS_V2,
			pathParams: { tableId: 20 },
		},
		selectRowKey: 'StoreID'
	};

	const [ref, handleUpdateRef] = useState(null);

	const renderLoader = () => {
		return (
			<StackItem isGrow isStretch align="center">
				<Loader />
			</StackItem>
		);
	};

	return (
		<>
			<ModalBody onDomRef={handleUpdateRef}>
				<Stack isVertical gutter="medium">
					{isLoading ? renderLoader() : <ThetaGridPaginated parent={ref} config={templateConfig} />}
				</Stack>
			</ModalBody>
			<ModalActions>
				<Stack gutter="medium">
					<StackItem isGrow isStretch>
						<Stack align="left" gutter="small">
							<StackItem>
								<Button
									disabled={isLoading}
									isSecondary
									name="Reset Kit Parameters"
									handleClick={goBack}
								/>
							</StackItem>
						</Stack>
					</StackItem>
					<StackItem>
						<Stack align="right" gutter="small">
							<StackItem>
								<Button disabled={isLoading} isSecondary name="CANCEL" handleClick={hideModal} />
							</StackItem>
							<StackItem isStretch align="right">
								<Button
									disabled={!selectedMembers?.selectedRowKeys?.length || isLoading}
									name={'Review Kit'}
									handleClick={handleNextClick}
								/>
							</StackItem>
						</Stack>
					</StackItem>
				</Stack>
			</ModalActions>
		</>
	);
};

export default SelectMembers;
