// @ts-nocheck
export const MODAL_NAME = 'generateOrders';

export const STEPS_KEYS = {
	ORDER_INFO: 'ORDER_INFO',
	SELECT_STORE: 'SELECT_STORE',
	SELECT_SKU: 'SELECT_SKU',
	RESERVE_INVENTORY: 'RESERVE_INVENTORY',
	REVIEW_ORDERS: 'REVIEW_ORDERS'
};

export const STEPS = [
	{
		key: 'ORDER_INFO',
		title: 'Order Info'
	},
	{
		key: 'SELECT_STORE',
		title: 'Select Stores'
	},
	{
		key: 'SELECT_SKU',
		title: 'Select SKUs'
	},
	{
		key: 'RESERVE_INVENTORY',
		title: 'Reserve Inventory'
	},
	{
		key: 'REVIEW_ORDERS',
		title: 'Review Orders'
	}
];

export const MODAL_TABLE_IDS = {
	STORES: 51,
	KIT_SKUS: 54,
	SKUS: 32,
	INVENTORY: 41
};
