// @ts-nocheck
import * as React from 'react';
import './filterBar.css';
import classNames from 'classnames';

interface Props extends React.PropsWithChildren<any> {}

const FilterBarSection: React.FunctionComponent<any> = (props: Props) => {
	const classes: any = classNames(['filter-bar-section']);

	return (
		<div ref={props?.ref} data-test-id="filter-bar-section" className={classes}>
			{props.children}
		</div>
	);
};

export default FilterBarSection;
