// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const triggerCreateRole = createAction('roles/triggerCreate', ({ roleName, componentId }) => ({
	payload: {
		roleName,
		componentId
	}
}));


export const triggerToggleRoleState = createAction('roles/triggerToggleRoleState', ({ roleId, componentId, isActive }) => ({
	payload: {
		roleId,
		componentId,
		isActive : false
	}
}));



export const triggerAddOperation = createAction('roles/addOperation', ({ roleId, operations }) => ({
	payload: {
		roleId, operations
	}
}));

export const triggerRemoveOperation = createAction('roles/removeOperation', ({ roleId, operations }) => ({
	payload: {
		roleId, operations
	}
}));

