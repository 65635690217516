// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';
import * as query from './query';

export const loadDynamicList = createAction(
	query.createActionName('load-list'),
	({ url, method, service, version, body, apiQuery }) => ({
		payload: { url, method, service, version, body, apiQuery }
	})
);
