// @ts-nocheck
import { Action, AnyAction, createSlice } from '@reduxjs/toolkit';
export const initialState = {
	inventoryData: null,
	isLoading: {},
	statusID: undefined,
	statusIntervalID: undefined
};

interface RejectedAction extends Action {
	error: Error;
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
	return action.type.endsWith('rejected');
}

const slice = createSlice({
	name: 'home',
	initialState,
	reducers: {
		updateInventoryData(state, { payload: { data } }) {
			return {
				...state,
				inventoryData: data
			};
		},
		toggleLoader(state, { payload: { loaderComponent, isLoading } }) {
			return {
				...state,
				isLoading: {
					[loaderComponent]: isLoading
				}
			};
		},
		updateStatusID(state, { payload: { statusID } }) {
			return {
				...state,
				statusID
			};
		},
		updateIntervalID(state, { payload: { statusIntervalID } }) {
			return {
				...state,
				statusIntervalID
			};
		}
	}
});

export default slice;

export const { updateInventoryData, toggleLoader, updateStatusID, updateIntervalID } = slice.actions;
