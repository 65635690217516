// @ts-nocheck
import * as React from 'react';
import TextInput from 'atoms/TextInput/textInput';
import NumberInput from 'atoms/NumberInput/numberInput';
import classNames from 'classnames';
import { Form, Input, Tooltip } from 'antd';
import './validation.scss';
import Icon from 'atoms/icons/icon';
import { getGridMetadata } from 'models/dynamicGrid/selector';
import { useSelector } from 'react-redux';

const Validation = (props: any) => {
	const { children, hasError, errorMessage, hasWarning, isTheme, value, tableId, row, rowIdentifier } = props;
	const gridMetaData = useSelector(state => getGridMetadata(state, tableId));
	const hasGridEditOperationDisabled = gridMetaData?.isGridEditDisabledOverride;
	const classes: any = classNames(['validation-form-item', { 'validation-form-item-theme': props.isTheme }, { 'validation-edit-disabled': hasGridEditOperationDisabled }]);
	return (
		<Form.Item className={classes} validateStatus={hasError || hasWarning ? 'error' : row?.[rowIdentifier]?.isUpdated && 'success'}>
			<div className="validation">
				<div className="validation--input">{children}</div>
				{(hasError || hasWarning) && (
					<Tooltip placement="topLeft" title={errorMessage} arrowPointAtCenter>
						<div>
							<Icon
								extraClass={hasWarning ? 'warning' : 'error'}
								faAlias="faInfoCircle"
								size="small"
							></Icon>
						</div>
					</Tooltip>
				)}
			</div>
		</Form.Item>
	);
};

export default Validation;
