// @ts-nocheck
import BodyText from 'atoms/bodyText/bodyText';
import _ from 'lodash';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ThetaGrid from 'templates/thetaGrid';
import { getSelectStoresData } from '../api';
import { SELECT_STORES_TABLE_ID } from './constants';
import { getTableIdSelector } from '../actionBar/query';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import * as dynamicGridQuery from 'models/dynamicGrid/query';
import { getShipMethodOptimizeNumber } from '../sagaActions';
import * as selectors from '../selectors';
import Loader from 'atoms/loader';
import { Button } from 'antd';
import { REQUESTS } from 'utils/requests';

const SelectStores = (props: any) => {
	const { selectedTab, parent } = props;
	const orders = useSelector(state => dynamicGridSelectors.getGridData(state, selectedTab));
	const userName = sessionStorage.getItem('userName') || 'None';

	const selectedOrders = useSelector(state => dynamicGridSelectors.getTableSelection(state, selectedTab));
	const stores = useSelector(state => dynamicGridSelectors.getGridData(state, SELECT_STORES_TABLE_ID));
	const ordersSearch = useSelector(state => dynamicGridSelectors.getGridFilters(state, selectedTab));
	const dispatch = useDispatch();
	const optimizeShipMethodNumber = useSelector(state => selectors.getOptimizeShipMethodNumber(state));
	const [roundType, setRoundType] = React.useState('');

	useEffect(() => {
		dispatch(getShipMethodOptimizeNumber({ userName }));
	}, []);
	const getSelectedStores = () => {
		let selectedStores = [];
		for (let selectedOrder of selectedOrders?.selectedRowKeys) {
			let order = _.filter(orders, function (singleOrder) {
				return singleOrder[getTableIdSelector(selectedTab)] === selectedOrder;
			});
			selectedStores.push(order[0].OrderID);
		}
		return selectedStores.join(',');
	};

	const gridConfig = {
		enableSelection: false,
		enableSorting: true,
		selectionCallBack: () => {}
	};

	const templateConfig = useMemo(
		() => ({
			gridConfig,
			isFilterEnabled: true,
			tableId: SELECT_STORES_TABLE_ID,
			
			dataProviderOptions: {
				version: 2,
				type: 'POST',
				...REQUESTS.POST.GET_SHIPMENT_STORES,
				pathParams: { userName },
				multiSelectFieldGroups: {
					OrderIDList: (selectedOrders?.selectedRowKeys || []).join(','),
					isSelectAll: selectedOrders?.isSelectAll,
					AppTableID: Number(selectedTab),
					OptimizeShipMethodNumberInput: optimizeShipMethodNumber
				},
				multiSelectFilterFieldGroups: {
					selectAllSearch: ordersSearch?.search ?? []
				},
				body: {
					fieldGroups: {
						OptimizeShipMethodNumberInput: optimizeShipMethodNumber,
						roundType: roundType ?? null
					},
					orderIds: (selectedOrders?.selectedRowKeys || []).join(','),
					isSelectAll: selectedOrders?.isSelectAll,
					selectAllSearch: JSON.stringify({
						filter: dynamicGridQuery.createFiltersFromQueries(ordersSearch?.search)
					}),
					tableId: Number(selectedTab)
				}
			},
			selectRowKey: 'OrderID'
		}),
		[roundType, optimizeShipMethodNumber, ordersSearch, selectedTab, userName]
	);

	if (!optimizeShipMethodNumber) {
		return <Loader isInset />;
	}

	return (
		<Stack isVertical>
			<StackItem>
				<Stack align="right" gutter="small" position="center">
					<StackItem isGrow isStretch>
						Round All:
					</StackItem>
					<StackItem>
						<Button onClick={() => setRoundType('DEFAULT')}>DEFAULT</Button>
					</StackItem>
					<StackItem>
						<Button onClick={() => setRoundType('UP')}>UP</Button>
					</StackItem>
					<StackItem>
						<Button onClick={() => setRoundType('DOWN')}>DOWN</Button>
					</StackItem>
				</Stack>
			</StackItem>
			<StackItem isStretch isGrow>
				<ThetaGridPaginated key={roundType} isStorageEnabled={false} parent={parent} config={templateConfig} />
			</StackItem>
		</Stack>
	);
};

export default SelectStores;
