// @ts-nocheck
import _ from 'lodash';
import { put, takeEvery, select, all } from 'redux-saga/effects';
import * as slice from './reducer';
import * as sagaActions from './sagaActions';
import { getAPIDataAcl, getAPIDataV3, getUserBarData, getUserRefresh, getUserSettingsData, postAPIData } from 'utils/api';
import { checkLocal } from 'utils/api';
import { getAPIDataV2 } from 'utils/api';
import { parseJwt } from 'utils/utilities';
import { logOut } from 'utils/api';
import { onReceiveEvent } from 'models/subscriptions/sagaActions';
import { getMsalInstance } from 'utils/msalInstance';
import { getUserEmail, getUserSettings, getWebAppSettings } from './selector';
import { getDispatch, getState } from 'reducers';
import { toggleLoaderState } from 'models/loaders/reducer';

type TPayload = {
	payload: {
		tableId: number;
		columns?: number[];
		force?: boolean;
	};
};

export default function* rootSaga() {
	function* fetchUserData() {
		let grpId, userEmail, userName, settings, aclOperations;
		let hasSettings = Object.keys(getUserSettings(getState()) || getWebAppSettings(getState()))?.length;

		if (hasSettings) {
			return;
		}
		yield put(
			toggleLoaderState({
				component: 'user-data-loading',
				isLoading: true
			})
		)
		try {
			let userSettingsData = yield getUserSettingsData();
			aclOperations = yield getAPIDataV3({ service: 'acl', url: 'apps/operations' });

			settings = userSettingsData.response;

		} catch (e) {
			yield put(
				toggleLoaderState({
					component: 'user-data-loading',
					isLoading: false
				})
			)
			return;
		}
		let response;

		if (!localStorage.getItem('releaseVersion')) {
			localStorage.setItem('releaseVersion', settings?.webAppSettings?.ReleaseVersion);
		}
		yield put(
			slice.updateUserInfo({
				settings: settings?.Config,
				operations: aclOperations?.data?.data?.data,
				role: aclOperations?.data?.data?.role,
				webAppSettings: settings?.webAppSettings
			})
		);
		yield put(
			toggleLoaderState({
				component: 'user-data-loading',
				isLoading: false
			})
		)
	}
	function* fetchTenentList({ payload: { email } }) {

		yield put(
			toggleLoaderState({
				component: 'tenant-list-loading',
				isLoading: true
			})
		)
		try {
			const response = yield postAPIData(`tenant/list`, { email });
			yield put(
				slice.updateUserInfo({ allTenentList: response?.data?.data?.selectedparentPartners ?? [] })
			);
			yield put(
				toggleLoaderState({
					component: 'tenant-list-loading',
					isLoading: false
				})
			)
		} catch (e) {
			yield put(
				toggleLoaderState({
					component: 'tenant-list-loading',
					isLoading: false
				})
			)
			return;
		}
	}
	function* updateTenent({ payload: { email, partnerId} }) {

		// yield put(
		// 	toggleLoaderState({
		// 		component: 'user-data-loading',
		// 		isLoading: true
		// 	})
		// )
		try {
			const response = yield postAPIData(`tenant/selectcompany`, { email, partnerId});
			const grpId = response?.data?.data?.PartnerID
			yield put(
				slice.updateGrpId({ grpId})
			);
			// yield put(
			// 	toggleLoaderState({
			// 		component: 'user-data-loading',
			// 		isLoading: false
			// 	})
			// )
		} catch (e) {
			// yield put(
			// 	toggleLoaderState({
			// 		component: 'user-data-loading',
			// 		isLoading: false
			// 	})
			// )
			return;
		}
	}
	function* checkDbHealth(userInfo) {
		// alert(123);
		//
		// try {
		// 	let a = yield getAPIDataV2('subscription', 'health');
		// 	if (a.error) {
		// 		throw a.error;
		// 	}
		// } catch (e) {}
	}

	return [
		yield takeEvery(sagaActions.fetchUserData, fetchUserData),
		yield takeEvery(sagaActions.checkDbHealth, checkDbHealth),
		yield takeEvery(sagaActions.fetchTenentList, fetchTenentList),
		yield takeEvery(sagaActions.updateTenent, updateTenent)
	];
}
