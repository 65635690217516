// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const fetchParameterGroups = createAction('fetch/parameter-groups', function prepare(
	parameters
	, isAppend
) {
	return {
		payload: { parameters, isAppend },
	};
});


export const fetchParameterDetails = createAction('fetch/parameter-details', function prepare(
	parameters
) {
	return {
		payload: { parameters },
	};
});

export const upsertParameterDetails = createAction('trigger/update-parameter-state', function prepare(
	{ UpdatedValue,
		ParameterID,
		IsOn,
		ParameterValue,
		ParameterMasterID,
		ParameterDetailID,
		ParameterSelector,
		ParameterTypeID,
		ParameterGroupItemID }
) {
	return {
		payload: {
			UpdatedValue,
			ParameterID,
			IsOn,
			ParameterValue,
			ParameterDetailID,
			ParameterMasterID,
			ParameterTypeID,
			ParameterSelector,
			ParameterGroupItemID
		},
	};
});



export const updateParameterGroupState = createAction('trigger/update-parameter-group-state', function prepare(
	{
		ParameterTypeID,
		ParameterGroupItemID,
		UpdatedValue,
		rowSelector,
		rowIndex,
		ParameterMasterID
	}
) {

	return {
		payload: {
			ParameterTypeID,
			ParameterGroupItemID,
			UpdatedValue,
			rowSelector,
			ParameterMasterID,
			rowIndex
		},
	};
});
