// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const triggerNotification = createAction(
	'triggerNotification',
	function prepare({ info, type, duration, message, isPersistent, autoClose}) {
		return {
			payload: {
				isPersistent,
				info,
				type,
				duration,
				message,
				autoClose
			}
		};
	}
);
export const removeNotification = createAction('removeNotification', function prepare({ id }) {
	return {
		payload: {
			id
		}
	};
});

export const fetchNotifications = createAction('fetchNotifications', function prepare(payload) {
	return {
		payload
	};
});

export const markAsRead = createAction('markAsRead', function prepare({ notification }) {
	return {
		payload: {
			notification
		}
	};
});
