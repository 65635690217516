// @ts-nocheck
import Button from 'atoms/button/button';
import DropDown from 'atoms/dropdown/dropdown';
import Heading from 'atoms/heading/heading';
import Label from 'atoms/label/label';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import ModalHeader from 'atoms/modal/modalHeader';
import ReactModal from 'atoms/modal/reactModal';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import MultiSelectDropdown from 'organisms/multiSelect/multiSelect';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { COLUMNS_TABLE_ID, SELECTION_COMPONENT } from '../constants';
import TextInput from 'atoms/TextInput/textInput';
import { triggerCreateAppColumnAlias } from '../sagaActions';
import { REQUESTS } from 'utils/requests';
import { isAppAliasSettingDelete, isAppAliasSettingWrite } from 'models/user/selector';

const CreateColumnAliasInfo = ({ aliasInfo }) => {
	const dimensionAttributes = [];
	const hasWriteAccess = useSelector(isAppAliasSettingWrite);
	const [appColumnName, setColumnName] = useState(null);
	const [isDialogEnabled, setDialog] = useState(false);
    const [columnAliasName, setColumnAliasName] = useState('');

	const dispatch = useDispatch();

	const handleClose = () => {
		setDialog(false);
		setColumnName('');
		setColumnAliasName('')
	};

	const handleSave = () => {
		dispatch(
			triggerCreateAppColumnAlias({
                columnName: appColumnName,
                aliasName: columnAliasName
			})
		);
		handleClose()

	};



	const selectionProviderOptions = React.useMemo(() => ({
		type: 'GET',
		version: 2,
		...REQUESTS.GET.GET_ALL_COLUMNS,
		tableId: COLUMNS_TABLE_ID,
		body: {
			// attributeName: attributeName?.displayName
		}
	}), []);

	if (!isDialogEnabled) {
		return hasWriteAccess && <Button handleClick={setDialog}> Create Column Alias</Button>
	}

	return (
		<>
			{hasWriteAccess && <Button handleClick={setDialog}> Create Column Alias</Button>}
			{isDialogEnabled && <ReactModal height={550} width={400} hideModal={handleClose}>
				<ModalHeader>
					<Heading type="tertiary">Create Column Alias</Heading>
				</ModalHeader>
				<ModalBody>
					<Stack isVertical gutter="medium">
						<StackItem>
							<Stack isVertical gutter="small">
								<StackItem>
									<Label>Column Name: </Label>
								</StackItem>
								<StackItem isGrow>
									<MultiSelectDropdown
										componentId={SELECTION_COMPONENT}
										key={SELECTION_COMPONENT}
										onChange={(e, selectAllOptions) => {
											setColumnName(e.target.value)
											// setGroupName(e.target.value, selectAllOptions);
											// setGroupSelectionOptions(selectAllOptions)
										}}
										selection={appColumnName}
										isInline
										isSingleSelect
										hasInputToggle
										selectionProviderOptions={selectionProviderOptions}
										columnName={'AppColumn'}
										columnLabelName={'AppColumn'}
									/>
								</StackItem>
							</Stack>
						</StackItem>
						<StackItem>
							<Stack isVertical gutter="small">
								<StackItem>
									<Label>Alias Name: </Label>
								</StackItem>
								<StackItem>
									<TextInput value={columnAliasName} onChange={e => setColumnAliasName(e.target.value)} />
								</StackItem>
							</Stack>
					</StackItem>
					
					</Stack>
				</ModalBody>
				<ModalActions>
					<Stack gutter="small" align="right">
						<StackItem>
							<Button type="secondary" handleClick={handleClose}>
								{' '}
								Cancel
							</Button>
						</StackItem>
						<StackItem>
							<Button handleClick={handleSave} type="primary" disabled={!columnAliasName || !appColumnName}>
								Save
							</Button>
						</StackItem>
					</Stack>
				</ModalActions>
			</ReactModal>
			
			}
			</>
	);
};

export default CreateColumnAliasInfo;
