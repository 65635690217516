// @ts-nocheck
import { put, takeEvery } from 'redux-saga/effects';
import { toggleLoaderState, updateAutoCompleteData, updateComponentMetaData } from './reducer';
import { toggleLoader, triggerFetchAutoComplete } from './sagaActions';
import { getAPIData, getAPIDataV2, getAPIDataV3 } from 'utils/api';
import { getDispatch, getState } from 'reducers';
import { REQUEST_STATUS } from 'models/loaders/constants';
import { getComponentMetaData } from './selectors';

export const handleFetchAutoCompleteData = async ({ payload: { componentId, options, isAppend, params } }) => {
	try {
		const dispatch = getDispatch();
		dispatch(
			updateAutoCompleteData({
				componentId,
				status: REQUEST_STATUS.PENDING
			})
		);
		if (options || params) {
			dispatch(updateComponentMetaData({
				componentId,
				metaData: { options, params }
			}))			
		}

		const metaData = getComponentMetaData(getState(), componentId);
		const requestOptions = options ?? metaData?.options;
		const requestParams = params ?? metaData?.params;
		const response = await getAPIDataV3({ service: requestOptions?.service, url: requestOptions?.url, queryParams: { ...requestOptions?.body, ...requestParams } });
		dispatch(
			updateAutoCompleteData({
				componentId,
				data : response?.data?.data,
				status: REQUEST_STATUS.COMPLETE,
				isAppend
			})
		);
	} catch (e) {
		console.log(e)
	}
};

export default function* rootSaga() {
	return [yield takeEvery(triggerFetchAutoComplete, handleFetchAutoCompleteData)];
}
