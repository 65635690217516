// @ts-nocheck
import Sheet from 'atoms/sheet/sheet';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as React from 'react';
import { useState } from 'react';
import { getDispatch } from 'reducers';
import {
	triggerDeleteDemandPlanningGroup,
	triggerFetchDemandPlanningGroupDetails,
	triggerFetchDemandPlanningGroups,
	triggerSaveDemandPlanningAttributeDetail
} from './sagaActions';
import Sidebar from 'molecules/sidebar/sidebar';
import SidebarItem from 'molecules/sidebar/sidebarItem';
import BodyText from 'atoms/bodyText/bodyText';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import Heading from 'atoms/heading/heading';
import Card from 'atoms/card/card';
import { useSelector } from 'react-redux';
import { getDemandDimesionGroups, getDemandPlanningDimensions } from './selectors';
import Scrollable from 'atoms/scrollable/scrollable';
import Label from 'atoms/label/label';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import { MENU, MENU_VALUES, PG_TABLE_ID } from './constants';
import GroupDimensionDetail from './groupDimensionDetail';
import Button from 'atoms/button/button';
import Icon from 'atoms/icons/icon';
import { setSelectedGroupAttributeDetail } from './reducer';
import { handleBulkRowDelete, refreshGridData } from 'models/dynamicGrid/sagaActions';
import ThetaMenu from 'organisms/thetaMenu/thetaMenu';
import { resetTableColumnsAg } from 'models/tableColumns/sagaActions';
import Loader from 'atoms/loader';
import Divider from 'atoms/divider/divider';
import TagSelector from './components/tagSelector/tagSelector';
import ThetaDeleteModal from 'organisms/deleteModal/deleteModal';
import { getTableSelection } from 'models/dynamicGrid/selector';
import { GRID_EVENTS } from 'molecules/ReactGrid/agGrid/constants';
import { Events } from 'molecules/ReactGrid/agGrid/utils/events';
import { updateSelectedRows } from 'models/dynamicGrid/reducer';
import { REQUESTS } from 'utils/requests';
import GridActionBar from 'atoms/GridActionBar';

const DemandGroupHub = props => {
	const dispatch = getDispatch();
	const [isDeleteModal, toggleDeleteModal] = React.useState(false);
	const [planningDimensionId, setPlanningDimensionId] = useState(null);
	const [isConfigureTagsEnabled, setConfigureTagsEnabled] = useState(false);
	const demandPlanningDimensions = useSelector(getDemandPlanningDimensions);
	const selectedItems = useSelector(state => getTableSelection(state, PG_TABLE_ID));
	const handleRefreshClick = React.useCallback(() => {
		dispatch(
			refreshGridData({
				tableId: PG_TABLE_ID,
			})
		);
	}, []);

	const handleResetTableColumns = React.useCallback(async () => {
		dispatch(resetTableColumnsAg(PG_TABLE_ID));
	}, []);

	const handleDeleteGroups = () => {
		toggleDeleteModal(true);
	};

	const handleMenuItemClick = React.useCallback(
		(option: any) => {
			switch (option) {
				case MENU_VALUES.RESET_VIEW:
					return handleResetTableColumns();
				case MENU_VALUES.DELETE:
					return handleDeleteGroups();
			}
		},
		[handleResetTableColumns]
	);
	const getDisabledStatus = React.useCallback((option: any) => {
		switch (option.value) {
			case MENU_VALUES.RESET_VIEW:
				return false;

			case MENU_VALUES.DELETE:
				return !selectedItems?.selectedRowKeys?.length;
			default:
				return false;
		}
	}, [selectedItems]);

	const handleBulkDeleteClick = () => {
		dispatch(
			triggerDeleteDemandPlanningGroup({
				planningGroupId: planningDimensionId,
				isBulkDelete: true
			})
		);
		toggleDeleteModal(false);
	};

	React.useEffect(() => {
		dispatch(triggerFetchDemandPlanningGroups());
	}, [dispatch]);
	const onSelectDemandPlanningGroup = planningGroupId => {
		dispatch(updateSelectedRows({ tableId: PG_TABLE_ID, data: { selectedRowKeys: [], isSelectAll: false } }))
		dispatch(triggerFetchDemandPlanningGroupDetails({ planningGroupId }));
		setPlanningDimensionId(planningGroupId);

	};
	React.useEffect(() => {
		if (!planningDimensionId && demandPlanningDimensions?.length) {
			onSelectDemandPlanningGroup(demandPlanningDimensions?.[0]?.PlanningGroupID);
		}
	}, [demandPlanningDimensions]);
	const gridConfig = {
		enableSelection: true,
		enableSorting: true,
		enableMultiSort: true,
		customActions: {
			width: 100,
			enabled: true,
			component: ({ row }) => {
				return (
					<Stack gutter="medium" align="center">
						<StackItem>
							<Icon
								isActive="true"
								type="secondary"
								size="medium"
								disabled={row?.IsReadOnly}
								onClick={value => {
									// alert(123);
									dispatch(
										setSelectedGroupAttributeDetail({
											selectedAttributeDetail: row
										})
									);
								}}
								faAlias={'faEdit'}
							></Icon>
						</StackItem>
						<StackItem>
							<Icon
								isActive
								type="secondary"
								size="small"
								disabled={row?.IsReadOnly}
								onClick={() => {
									dispatch(
										triggerDeleteDemandPlanningGroup({
											planningGroupId: planningDimensionId,
											rowIdentifier: row?.GroupID
										})
									);
								}}
								faAlias={'faTrash'}
							></Icon>
						</StackItem>
					</Stack>
				);
			}
		},
		selectionCallBack: () => { }
	};

	const templateConfig = React.useMemo(
		() => ({
			gridConfig,
			tableId: PG_TABLE_ID,

			dataProviderOptions: {
				type: 'POST',
				version: 2,
				...REQUESTS.POST.GROUP_INFO,
				pathParams: { planningDimensionId: planningDimensionId },
			},
			selectRowKey: 'GroupID'
		}),
		[planningDimensionId]
	);

	if (!planningDimensionId) {
		return <Loader></Loader>;
	}
	return (
		<>
			{isConfigureTagsEnabled && <TagSelector onClose={() => setConfigureTagsEnabled(false)} />}
			<Stack isVertical>
				<StackItem isStretch>
					<GridActionBar>
						<Stack gutter="medium">
							<StackItem isGrow position='start'>
								<Button handleClick={() => setConfigureTagsEnabled(true)}> Configure Tags</Button>
							</StackItem>
							<StackItem position='center'>
								<Icon isActive type="secondary" faAlias="faRecycle" onClick={handleRefreshClick} />
							</StackItem>
							{/* <StackItem>
									<ExportGrid key={activeTable.tableId} tableId={activeTable.tableId} />
								</StackItem> */}

							<StackItem position='center'>
								<ThetaMenu
									type="3Dots"
									options={MENU}
									handleItemClick={handleMenuItemClick}
									isDisabled={getDisabledStatus}
								/>
							</StackItem>
						</Stack>

					</GridActionBar>
				</StackItem>
				<StackItem isStretch isGrow>
					<Sheet isPage>
						<SheetBody>
							<Stack isVertical gutter="medium">

								<StackItem isGrow>
									<Stack>
										<StackItem>
											<Card isBordered>
												<Stack isVertical gutter="small">
													<StackItem>
														<Heading type="secondary">Planning Groups</Heading>
													</StackItem>
													<StackItem>
														<Sidebar height={500} isSecondary isExpanded>
															{_.map(
																demandPlanningDimensions,
																({ PlanningGroupID, PlanningGroupName }) => (
																	<SidebarItem
																		isActive={PlanningGroupID === planningDimensionId}
																		onClick={() =>
																			onSelectDemandPlanningGroup(PlanningGroupID)
																		}
																	>
																		{PlanningGroupName}
																	</SidebarItem>
																)
															)}
														</Sidebar>
													</StackItem>
												</Stack>
											</Card>
										</StackItem>
										<StackItem isGrow>
											<Card isBordered>
												<Stack isVertical gutter="small" align="left">
													<StackItem position="end">
														<Button
															handleClick={() => {
																dispatch(
																	setSelectedGroupAttributeDetail({
																		selectedAttributeDetail: {}
																	})
																);
															}}
															type="action"
														>
															<Stack align="space-between" gutter="small" position="center">
																<StackItem isGrow align="center">
																	<BodyText>Create New</BodyText>
																</StackItem>
																<StackItem isStretch>
																	<Divider size="small" isVertical></Divider>
																</StackItem>
																<StackItem>
																	<Icon
																		disabled={false}
																		isPadded={false}
																		faAlias="faPlus"
																		size="tiny"
																	/>
																</StackItem>
															</Stack>
															{/* // add icon here */}
														</Button>
													</StackItem>
													<StackItem isGrow isStretch>
														<ThetaGridPaginated key={planningDimensionId} config={templateConfig} />
													</StackItem>
												</Stack>
											</Card>
										</StackItem>
									</Stack>
								</StackItem>
							</Stack>
						</SheetBody>
					</Sheet>
				</StackItem>
			</Stack>


			<GroupDimensionDetail planningDimensionId={planningDimensionId} />
			{isDeleteModal && (
				<ThetaDeleteModal
					hideModal={() => {
						toggleDeleteModal(false);
					}}
					modalTitle={'Groups'}
					title={` (${selectedItems?.isSelectAll ? 'All' : selectedItems?.selectedRowKeys?.length
						}) selected  item(s)`}
					handleDeleteClick={handleBulkDeleteClick}
					isLocked={true}
				/>
			)}
		</>

	);
};

export default DemandGroupHub;
