// @ts-nocheck
import { Action, AnyAction, createSlice } from '@reduxjs/toolkit';
export const initialState = {
	appColumns: [],
    
};



const slice = createSlice({
	name: 'columnAliases',
	initialState,
	reducers: {
		updateAppColumns(state, { payload: { appColumns } }) {
			return {
				...state,
				appColumns
			};
		},
	
	}
});

export default slice;

export const { updateAppColumns, updateGridCellUpdateStatus } = slice.actions;
