// @ts-nocheck
import _ from 'lodash';

import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import { useSelector } from 'react-redux';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import * as selectors from '../selectors';
import { REVIEW_ORDER_TABLE_ID } from './constants';
import * as dynamicGridQuery from 'models/dynamicGrid/query';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import { REQUESTS } from 'utils/requests';
const ReviewOrders = (props: any) => {
	const { selectedTab, parent } = props;

	const gridConfig = {
		enableSelection: false,
		enableSorting: true,
		selectionCallBack: () => {}
	};

	const selectedOrders = useSelector(state => dynamicGridSelectors.getTableSelection(state, selectedTab));
	const ordersSearch = useSelector(state => dynamicGridSelectors.getGridFilters(state, selectedTab));
	const bulkEditParams = useSelector(state => selectors.getBulkEditParams(state));
	let OrderDetailId;
	if (window.location.pathname.match('orders/details')) {
		OrderDetailId = window.location.pathname?.split('/').reverse()[0];
	}

	const templateConfig = {
		gridConfig,
		isFilterEnabled: true,
		tableId: REVIEW_ORDER_TABLE_ID,
		// selectRowKey: 'SKU',
		dataProviderOptions: {
			version: 2,
			type: 'POST',
			...REQUESTS.POST.GET_ORDER_BULK_EDIT_REVIEW,
			multiSelectFieldGroups: {
				orderIds: (selectedOrders?.selectedRowKeys || []).join(','),
				isSelectAll: selectedOrders?.isSelectAll,
				AppTableID: selectedTab,
				orderId: OrderDetailId,
				bulkOrderEditTypeId: Number(bulkEditParams.bulkOrderEditTypeId),
				bulkOrderEditValue: Number(bulkEditParams.bulkOrderEditValue)
			},
			multiSelectFilterFieldGroups: {
				selectAllSearch: ordersSearch.search
			},
			body: {
				orderIds: (selectedOrders?.selectedRowKeys || []).join(','),
				isSelectAll: selectedOrders?.isSelectAll,
				selectAllSearch: JSON.stringify({
					filter: dynamicGridQuery.createFiltersFromQueries(ordersSearch.search)
				}),
				orderId: OrderDetailId,
				tableId: Number(selectedTab),
				bulkOrderEditTypeId: Number(bulkEditParams.bulkOrderEditTypeId),
				bulkOrderEditValue: Number(bulkEditParams.bulkOrderEditValue)
			}
		}
	};
	return (
		<Stack isVertical gutter="medium">
			<StackItem isStretch isGrow>
				<ThetaGridPaginated isAdvanecdSearch isStorageEnabled={false} parent={parent} config={templateConfig} />
			</StackItem>
		</Stack>
	);
};

export default ReviewOrders;
