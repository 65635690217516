// @ts-nocheck
import OrdersPage from './orders';
import OrderDetailsPage from './orders/orderDetails';
import ParametersPage from './parameters';
import AvailabilityPage from './availability';
import AdvertisingPage from './advertising';
import MasterDataPage from './masterData';
import HomePage from './home';
import IntelligencePage from './intelligence';
import KitsPage from './orders/kits';
import ResetModel from './models/resetModel';
import PaginationModel from './models/paginationModel';
import DemandPlanningPage from './demandPlanning';
import AdminSettings from './adminSettings';
import Users from './adminSettings/accessControl/users';
import RowAccess from './adminSettings/accessControl/rowAccess';
import Roles from './adminSettings/accessControl/roles';
import { Planogram } from './planogram';


export const Pages = [
	OrdersPage,
	AdvertisingPage,
	ParametersPage,
	AvailabilityPage,
	MasterDataPage,
	IntelligencePage,
	KitsPage,
	HomePage,
	DemandPlanningPage,
	ResetModel,
	Planogram,
	AdminSettings,
	PaginationModel,

];





export const BreadCrumbsPages = [MasterDataPage, OrdersPage,Planogram, AdminSettings];
