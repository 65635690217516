// @ts-nocheck
import { createFiltersFromQueries } from 'models/dynamicGrid/query';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import { onReceiveEvent } from 'models/subscriptions/sagaActions';
import { triggerNotification } from 'organisms/notifications/sagaActions';
import { put, select, takeEvery } from 'redux-saga/effects';
import { getAPIData,  postAPIData, postAPIDataV3 } from 'utils/api';
import { REQUESTS } from 'utils/requests';

import * as slice from './reducer';
import * as sagaActions from './sagaActions';
import { getActiveTable, getModalParams, isAvailabilityUpdate } from './selector';
import { getWebAppSettings } from 'models/user/selector';
import { getState } from 'reducers';

const getShippingMethods = (): Promise<any> => {
	return postAPIDataV3({
    service: REQUESTS.POST.GET_GRID_DETAILS_V2.service,
    url: REQUESTS.POST.GET_GRID_DETAILS_V2.url,
    pathParams: { tableId: 19 },
  })
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true }));
};

const createKit = (data: any): Promise<any> => {
	const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

	
	return postAPIDataV3({service: REQUESTS.POST.CREATE_KIT.service, url: REQUESTS.POST.CREATE_KIT.url, body: {...data, isView}})
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true }));
};

function* updateModalParams({ payload }: any) {
	const { data } = payload;
	yield put(slice.updateModalParams({ data }));
}

function* cleanUp() {
	yield put(slice.cleanUp());
	yield put(
		dynamicGridSagaActions.cleanUpGrids({
			tableIds: [20]
		})
	);
}

function* saveData({ payload }: any) {
	const { data } = payload;
	yield put(slice.updateLoader({ isLoading: true }));

	const activeTable = yield select(getActiveTable);
	const selectedStores = yield select(state => dynamicGridSelectors.getTableSelection(state, 20));
	let selectedStoresSearch = yield select(state => dynamicGridSelectors.getGridFilters(state, 20));
	const modalParams = yield select(getModalParams);
	const activeTableSelection = yield select(state => dynamicGridSelectors.getTableSelection(state, activeTable));
	let activeTableSearch = yield select(state => dynamicGridSelectors.getGridFilters(state, activeTable));
	activeTableSearch = JSON.stringify({
		filter: createFiltersFromQueries(activeTableSearch?.search)
	});
	selectedStoresSearch = JSON.stringify({
		filter: createFiltersFromQueries(selectedStoresSearch?.search)
	});

	const isAvailabilityFlow = yield select(isAvailabilityUpdate);

	const { response, isError } = yield createKit({
		tableId: activeTable,
		selectedStores,
		activeTableSearch,
		activeTableSelection,
		selectedStoresSearch,
		isAvailabilityFlow,
		...modalParams
	}).catch(error => ({ isError: true, error }));
}

function* handleReceiveEvent({ payload }: TPayload) {
	switch (payload?.type) {
		case 'TABLE_EV':
			if (payload?.eventName === 'create_kit') {
				if (payload.metaData.type === 'error') {
					yield put(triggerNotification({ message: 'Unable to save, please try again later.' }));
					yield put(slice.updateLoader({ isLoading: false }));

					return;
				}
				yield put(slice.updateLoader({ isLoading: false }));
				yield put(slice.updateModalToggle({ isOpen: false }));
			}
	}
}
function* fetchShippingMethods() {
	yield put(
		slice.updateLoader({
			eventName: 'shipMethods',
			isLoading: true
		})
	);

	const { response, isError } = yield getShippingMethods();

	if (isError) {
		yield put(
			triggerNotification({
				message: 'An error occurred while fetching shipping methods, please try again later.'
			})
		);
		yield put(
			slice.updateLoader({
				eventName: 'shipMethods',
				isLoading: false
			})
		);
		return;
	}

	yield put(slice.updateShippingMethods({ shipMethods: response?.data }));

	if (response?.data.length) {
		yield put(
			slice.updateModalParams({
				data: { shipMethodID: response?.data[0].ShipMethodID }
			})
		);
	}

	yield put(
		slice.updateLoader({
			eventName: 'shipMethods',
			isLoading: false
		})
	);
}

export default function* rootSaga() {
	return [
		yield takeEvery(sagaActions.updateModalParams, updateModalParams),
		yield takeEvery(sagaActions.cleanUp, cleanUp),
		yield takeEvery(sagaActions.saveData, saveData),
		yield takeEvery(sagaActions.fetchShippingMethods, fetchShippingMethods),
		yield takeEvery(onReceiveEvent, handleReceiveEvent)
	];
}
