// @ts-nocheck
import React from 'react';
import MasterData from './masterData';
import StoreSchedulesReducer from './storeSchedules/reducer';
import StoreSchedulesSagas from './storeSchedules/sagas';
import StoreScheduleMasterData from './storeSchedules';
import MasterDataShipMethods from './shippingMethods/index';
import ShipMethodSagas from './shippingMethods/sagas';
import ShipMethodReducer from './shippingMethods/reducer';
import ShipmentsSagas from './shipments/sagas';
import ShipmentsReducer from './shipments/reducer';
import MasterDataShipments from './shipments/index';
import StoreOrderListSagas from './storeOrdersList/sagas';
import StoreOrderListReducer from './storeOrdersList/reducer';
import MasterDataStoreOrderList from './storeOrdersList/index';
import UploadDataSagas from '../../organisms/uploadData/sagas';
import UploadDataSlice from '../../organisms/uploadData/reducer';
import StoreSagas from './stores/sagas';
import StoreReducer from './stores/reducer';
import MasterDataStores from './stores/index';
import MasterDataWarehouses from './warehouses'
import MasterDataKits from './kits/index';
import MasterDataKitsSagas from './kits/sagas';
import MasterDataKitsReducer from './kits/reducer';
import MasterDataSkuItemMapping from './skuItemMapping/index';
import MasterDataSkuItemSagas from './skuItemMapping/sagas';
import MasterDataSkuItemReducer from './skuItemMapping/reducer';
import MasterDataTransactions from './transactions';
import TransactionReducer from './transactions/reducer';
import TransactionSagas from './transactions/sagas';
import MasterDataCustomers from './customers';
import CustomerReducer from './customers/reducer';
import CustomerSagas from './customers/sagas';
import MasterDataSkus from './skus';
import SkusReducer from './skus/reducer';
import SkusSagas from './skus/sagas';
import MasterDataStoreSkuCombination from './storeSkuCombination';
import StoreSkuCombinationReducer from './storeSkuCombination/reducer';
import StoreSkuCombinationSagas from './storeSkuCombination/sagas';
import MasterDataSkuShippingMethodConfiguration from './skuShippingMethodConfiguration';
import MasterDataSkuExclusion from './skuExclusions';
import SkuExclusionReducer from './skuExclusions/reducer';
import SkuExclusionSagas from './skuExclusions/sagas';
import MasterDataPricing from './pricing';
import PricingReducer from './pricing/reducer';
import PricingSagas from './pricing/sagas';
import MasterDataParametersView from './parametersview';
import MasterDataItems from './items';
import ItemsReducer from './items/reducer';
import ItemsSagas from './items/sagas';
import MasterDataShipMethodItemConfig from './itemShippingMethodConfig';
import ShipMethodItemConfigReducer from './itemShippingMethodConfig/reducer';
import ShipMethodItemConfigSagas from './itemShippingMethodConfig/sagas';
import MasterDataItemExclusion from './itemExclusions';
import ItemExclusionReducer from './itemExclusions/reducer';
import ItemExclusionSagas from './itemExclusions/sagas';
import MasterDataAdTypes from './adTypes';
import MasterDataGroups from './groups/groups';
import GroupDataSagas from './groups/sagas';
import PrimaryStoreSku from './primaryStoreSku';
import { isMasterDataAdTypesReadWrite, isMasterDataCustomersReadWrite, isMasterDataGroupsReadWrite, isMasterDataInventoryTransactionsReadWrite, isMasterDataItemExclusionsReadWrite, isMasterDataItemShippingMethodConfigurationReadWrite, isMasterDataItemsReadWrite, isMasterDataKitsReadWrite, isMasterDataParametersReadWrite, isMasterDataPricingReadWrite, isMasterDataPrimaryStoreSkuReadWrite, isMasterDataRead, isMasterDataSchedulesReadWrite, isMasterDataShipmentsReadWrite, isMasterDataShippingMethodsReadWrite, isMasterDataSkuExclusionsReadWrite, isMasterDataSkusReadWrite, isMasterDataStoreOrderListReadWrite, isMasterDataStoresReadWrite, isMasterDataValidSkuItemCombinationsReadWrite, isMasterDataValidStoreSkuCombinationsRead, isMasterDataValidStoreSkuCombinationsReadWrite } from 'models/user/selector';

export default [
	{
		path: '/masterdata',
		key: 'key',
		componentId: 'masterdata',
		component: (props: any) => <MasterData {...props} />,
		routes: [],
		title: 'Skulicity | Master Data Page',
		exact: true,
		isEnabled: state => isMasterDataRead(state),
		headerName: 'Master Data',
		name: 'Master Data'
	},
	{
		path: '/masterdata/storeschedules',
		key: 'key',
		componentId: 'storeSchedules',
		component: (props: any) => <StoreScheduleMasterData {...props} />,
		routes: [],
		title: 'Skulicity | Master Data Store Schedule Page',
		exact: true,
		isEnabled: state => isMasterDataSchedulesReadWrite(state),
		importReducer: () => {
			return [StoreSchedulesReducer];
		},
		importSagas: () => {
			return [StoreSchedulesSagas];
		},
		headerName: 'Store Schedules',
		name: 'Store Schedules'
	},
	{
		path: '/masterdata/shipmethods',
		key: 'key',
		componentId: 'shipmethods',
		component: (props: any) => <MasterDataShipMethods {...props} />,
		routes: [],
		title: 'Skulicity | Master Data Ship Methods Page',
		exact: true,
		isEnabled: state => isMasterDataShippingMethodsReadWrite(state),
		importReducer: () => {
			return [ShipMethodReducer, UploadDataSlice];
		},
		importSagas: () => {
			return [ShipMethodSagas, UploadDataSagas];
		},
		name: 'Ship Methods',
		headerName: 'Ship Methods'
	},
	{
		path: '/masterdata/shipments',
		key: 'key',
		componentId: 'shipments',
		component: (props: any) => <MasterDataShipments {...props} />,
		routes: [],
		isEnabled: state => isMasterDataShipmentsReadWrite(state),
		title: 'Skulicity | Master Data Shipments Page',
		exact: true,
		importReducer: () => {
			return [ShipmentsReducer];
		},
		importSagas: () => {
			return [ShipmentsSagas];
		},
		name: 'Shipments',
		headerName: 'Shipments'
	},
	{
		path: '/masterdata/store/order/list',
		key: 'key',
		componentId: 'storeOrderList',
		component: (props: any) => <MasterDataStoreOrderList {...props} />,
		routes: [],
		title: 'Skulicity | Master Data Store Order List Page',
		isEnabled: state => isMasterDataStoreOrderListReadWrite(state),
		exact: true,
		importReducer: () => {
			return [StoreOrderListReducer];
		},
		importSagas: () => {
			return [StoreOrderListSagas];
		},
		name: 'Store Order List',
		headerName: 'Store Order List'
	},
	{
		path: '/masterdata/stores',
		key: 'key',
		componentId: 'stores',
		component: (props: any) => <MasterDataStores {...props} />,
		routes: [],
		isEnabled: state => isMasterDataStoresReadWrite(state),
		title: 'Skulicity | Master Data Stores',
		exact: true,
		importReducer: () => {
			return [StoreReducer];
		},
		importSagas: () => {
			return [StoreSagas];
		},
		name: 'Stores',
		headerName: 'Stores'
	},
	{
		path: '/masterdata/kits',
		key: 'key',
		componentId: 'masterDataKits',
		component: (props: any) => <MasterDataKits {...props} />,
		routes: [],
		title: 'Skulicity | Master Data Kits',
		isEnabled: state => isMasterDataKitsReadWrite(state),
		exact: true,
		importReducer: () => {
			return [MasterDataKitsReducer];
		},
		importSagas: () => {
			return [MasterDataKitsSagas];
		},
		name: 'Kits',
		headerName: 'Kits'
	},
	{
		path: '/masterdata/skuitemmapping',
		key: 'key',
		componentId: 'masterDataSkuItemMapping',
		component: (props: any) => <MasterDataSkuItemMapping {...props} />,
		routes: [],
		title: 'Skulicity | Master Data Sku Item Mapping',
		isEnabled: state => isMasterDataValidSkuItemCombinationsReadWrite(state),
		exact: true,
		importReducer: () => {
			return [MasterDataSkuItemReducer];
		},
		importSagas: () => {
			return [MasterDataSkuItemSagas];
		},
		name: 'Valid SKU/Item Combinations',
		headerName: 'Valid SKU/Item Combinations'
	},
	{
		path: '/masterdata/transactions',
		key: 'key',
		componentId: 'transactions',
		component: (props: any) => <MasterDataTransactions {...props} />,
		routes: [],
		title: 'Skulicity | Master Data Transactions Page',
		isEnabled: state => isMasterDataInventoryTransactionsReadWrite(state),
		exact: true,
		importReducer: () => {
			return [TransactionReducer];
		},
		importSagas: () => {
			return [TransactionSagas];
		},
		name: 'Transactions',
		headerName: 'Transactions'
	},
	{
		path: '/masterdata/customers',
		key: 'key',
		componentId: 'customers',
		component: (props: any) => <MasterDataCustomers {...props} />,
		routes: [],
		isEnabled: state => isMasterDataCustomersReadWrite(state),
		title: 'Skulicity | Master Data Transactions Page',
		exact: true,
		importReducer: () => {
			return [CustomerReducer];
		},
		importSagas: () => {
			return [CustomerSagas];
		},
		name: 'Customers',
		headerName: 'Customers'
	},
	{
		path: '/masterdata/skus',
		key: 'key',
		componentId: 'skus',
		component: (props: any) => <MasterDataSkus {...props} />,
		routes: [],
		isEnabled: state => isMasterDataSkusReadWrite(state),
		title: 'Skulicity | Master Data SKUs Page',
		exact: true,
		importReducer: () => {
			return [SkusReducer];
		},
		importSagas: () => {
			return [SkusSagas];
		},
		name: 'SKUs',
		headerName: 'SKUs'
	},
	{
		path: '/masterdata/storesku',
		key: 'key',
		componentId: 'storesku',
		isEnabled: state => isMasterDataValidStoreSkuCombinationsReadWrite(state),
		component: (props: any) => <MasterDataStoreSkuCombination {...props} />,
		routes: [],
		title: 'Skulicity | Master Data Store Sku Page',
		exact: true,
		importReducer: () => {
			return [StoreSkuCombinationReducer];
		},
		importSagas: () => {
			return [StoreSkuCombinationSagas];
		},
		name: 'Valid Store/SKU Combinations',
		headerName: 'Valid Store/SKU Combinations'
	},
	{
		path: '/masterdata/shipmethodskuconfig',
		key: 'key',
		componentId: 'shipmethodskuconfig',
		
		component: (props: any) => <MasterDataSkuShippingMethodConfiguration {...props} />,
		routes: [],
		title: 'Skulicity | Master Data Sku Shipping Method Configuration',
		exact: true,
		importReducer: () => {
			return [];
		},
		importSagas: () => {
			return [];
		},
		name: 'SKU Shipping Method Configuration',
		headerName: 'SKU Shipping Method Configuration'
	},
	{
		path: '/masterdata/excludestoresku',
		key: 'key',
		isEnabled: state => isMasterDataSkuExclusionsReadWrite(state),
		componentId: 'excludestoresku',
		component: (props: any) => <MasterDataSkuExclusion {...props} />,
		routes: [],
		title: 'Skulicity | Master Data Sku Exclusions',
		exact: true,
		importReducer: () => {
			return [SkuExclusionReducer];
		},
		importSagas: () => {
			return [SkuExclusionSagas];
		},
		name: 'SKU Exclusions',
		headerName: 'SKU Exclusions'
	},
	{
		path: '/masterdata/storeskucost',
		key: 'key',
		componentId: 'storeskucost',
		component: (props: any) => <MasterDataPricing {...props} />,
		routes: [],
		isEnabled: state => isMasterDataPricingReadWrite(state),

		title: 'Skulicity | Master Data Pricing',
		exact: true,
		importReducer: () => {
			return [PricingReducer];
		},
		importSagas: () => {
			return [PricingSagas];
		},
		name: 'Pricing',
		headerName: 'Pricing'
	},
	{
		path: '/masterdata/parameterview',
		key: 'key',
		componentId: 'parameterview',
		component: (props: any) => <MasterDataParametersView {...props} />,
		routes: [],
		isEnabled: state => isMasterDataParametersReadWrite(state),

		title: 'Skulicity | Master Data Parameters',
		exact: true,
		importReducer: () => {
			return [];
		},
		importSagas: () => {
			return [];
		},
		name: 'Parameters',
		headerName: 'Parameters'
	},
	{
		path: '/masterdata/items',
		key: 'key',
		componentId: 'items',
		component: (props: any) => <MasterDataItems {...props} />,
		routes: [],
		title: 'Skulicity | Master Data Items',
		exact: true,
		isEnabled: state => isMasterDataItemsReadWrite(state),

		importReducer: () => {
			return [ItemsReducer];
		},
		importSagas: () => {
			return [ItemsSagas];
		},
		name: 'Items',
		headerName: 'Items'
	},
	{
		path: '/masterdata/primaryStoreSku',
		key: 'key',
		componentId: 'items',
		component: (props: any) => <PrimaryStoreSku {...props} />,
		routes: [],
		title: 'Skulicity | Master Data Primary Store SKU',
		exact: true,
		isEnabled: state => isMasterDataPrimaryStoreSkuReadWrite(state),

		importReducer: () => {
			return [];
		},
		importSagas: () => {
			return [];
		},
		name: 'Primary Store SKU',
		headerName: 'Primary Store SKU'
	},
	{
		path: '/masterdata/shipmethoditemconfig',
		key: 'key',
		componentId: 'shipmethoditemconfig',
		component: (props: any) => <MasterDataShipMethodItemConfig {...props} />,
		routes: [],
		title: 'Skulicity | Master Data Ship Method Item Confirguration',
		exact: true,
		isEnabled: state => isMasterDataItemShippingMethodConfigurationReadWrite(state),

		importReducer: () => {
			return [ShipMethodItemConfigReducer];
		},
		importSagas: () => {
			return [ShipMethodItemConfigSagas];
		},
		name: 'Item Shipping Method Configuration',
		headerName: 'Item Shipping Method Configuration'
	},
	{
		path: '/masterdata/excludestoreitem',
		key: 'key',
		componentId: 'excludestoreitem',
		component: (props: any) => <MasterDataItemExclusion {...props} />,
		routes: [],
		title: 'Skulicity | Master Data Item Exclusion',
		exact: true,
		importReducer: () => {
			return [ItemExclusionReducer];
		},
		isEnabled: state => isMasterDataItemExclusionsReadWrite(state),

		importSagas: () => {
			return [ItemExclusionSagas];
		},
		name: 'Item Exclusions',
		headerName: 'Item Exclusions'
	},
	{
		path: '/masterdata/adTypes',
		key: 'key',
		componentId: 'adTypes',
		component: (props: any) => <MasterDataAdTypes {...props} />,
		routes: [],
		title: 'Skulicity | Master Data Ad Types',
		exact: true,
		isEnabled: state => isMasterDataAdTypesReadWrite(state),

		name: 'Ad Types',
		headerName: 'Ad Types'
	},
	{
		path: '/masterdata/groups',
		key: 'key',
		componentId: 'groups',
		component: (props: any) => <MasterDataGroups {...props} />,
		routes: [],
		title: 'Skulicity | Master Data Groups',
		isEnabled: state => isMasterDataGroupsReadWrite(state),

		exact: true,
		// importReducer: () => {
		//   return [ItemExclusionReducer];
		// },
		importSagas: () => {
			return [GroupDataSagas];
		},
		name: 'Groups',
		headerName: 'Groups'
	},
	{
		path: '/masterdata/warehouses',
		key: 'key',
		componentId: 'warehouses',
		component: (props: any) => <MasterDataWarehouses {...props} />,
		// routes: [],
		title: 'Skulicity | Master Data Warehouses',
		// isEnabled: state => isMasterDataGroupsReadWrite(state),
		exact: true,
		// importReducer: () => {
		//   return [ItemExclusionReducer];
		// },
		// importSagas: () => {
		// 	return [GroupDataSagas];
		// },
		name: 'Warehouses',
		headerName: 'Warehouses'
	}
];
