// @ts-nocheck
import React from 'react';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import Card from 'atoms/card/card';
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import { useDispatch, useSelector } from 'react-redux';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';

import { formatThousands, formatPercentage, formatMoney } from 'utils/utilities';
import BodyText from 'atoms/bodyText/bodyText';
import { AVAILABILITY } from './constants';
import { useEffect } from 'react';
import * as sagaActions from './sagaActions';
import * as selectors from './selectors';
import _ from 'lodash';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import SheetAction from 'atoms/sheet/sheetAction/sheetAction';

export interface Props {
	tableId: number;
}

const NotificationCard = ({ heading, primaryText, secondaryText, value }) => {
	return (
		<Card isDescription>
			<Sheet>
				<SheetBody>
					<Stack gutter="small" isVertical align="center">
						<StackItem>
							<Heading type="tertiary">{heading}</Heading>
						</StackItem>
						<StackItem>
							<Heading type="card" normalWeight={true}>
								{primaryText}
							</Heading>
						</StackItem>

						{/* </SheetBody> */}
						<StackItem>
							<Heading type="tertiary">{secondaryText}</Heading>
						</StackItem>
						<StackItem>
							<Heading type="card" normalWeight={true}>
								{value}
							</Heading>
						</StackItem>
					</Stack>
				</SheetBody>
			</Sheet>
		</Card>
	);
};

const NotificationCards: React.FunctionComponent<Props> = ({ tableId, availabilityInfo }) => {
	const dispatch = useDispatch();

	const filters = useSelector(state => dynamicGridSelectors.getGridFilters(state, tableId));

	useEffect(() => {
		if (!filters?.search) {
			return;
		}
		dispatch(sagaActions.getAvailabilityInfo({ tableId }));
	}, [dispatch, filters?.search]);

	return (
		<Stack gutter="small" align="space-between">
			<StackItem>
				<NotificationCard
					heading="Initial Availability Cost"
					primaryText={formatMoney(availabilityInfo?.Initial_Available_Cost || 0)}
					secondaryText={'Initial Available Quantity'}
					value={formatThousands(availabilityInfo?.Initial_Available_Qty || 0)}
				></NotificationCard>
			</StackItem>
			<StackItem>
				<NotificationCard
					heading="Current Availability Cost"
					primaryText={formatMoney(availabilityInfo?.Current_Available_Cost || 0)}
					secondaryText={'Current Available Quantity'}
					value={formatThousands(availabilityInfo?.Current_Available_Qty || 0)}
				></NotificationCard>
			</StackItem>
			<StackItem>
				<NotificationCard
					heading="On Order Cost"
					primaryText={
						formatMoney(
							Number(availabilityInfo?.Initial_Available_Cost) -
								Number(availabilityInfo?.Current_Available_Cost)
						) || 0
					}
					secondaryText={'On Order Quantity'}
					value={formatThousands(
						Number(availabilityInfo?.Initial_Available_Qty ?? 0) -
							Number(availabilityInfo?.Current_Available_Qty ?? 0)
					)}
				></NotificationCard>
			</StackItem>

			<StackItem>
				<NotificationCard
					heading="% Availability Cost Consumed "
					primaryText={formatPercentage(
						(availabilityInfo?.Initial_Available_Cost  - availabilityInfo?.Current_Available_Cost)  / availabilityInfo?.Initial_Available_Cost
					) ?? 0 }
					secondaryText="% Quantity Consumed"
					value={
						formatPercentage(
							(availabilityInfo?.Initial_Available_Qty  - availabilityInfo?.Current_Available_Qty ) /
								availabilityInfo?.Initial_Available_Qty 
						) ?? 0
					}
				></NotificationCard>
			</StackItem>
		</Stack>
	);
};

export default NotificationCards;
