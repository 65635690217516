// @ts-nocheck
import _ from 'lodash';
import * as dynamicGridQuery from 'models/dynamicGrid/query';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import { triggerNotification } from 'organisms/notifications/sagaActions';
import * as homeSagaActions from 'pages/home/sagaActions';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { getAPIDataV3, postAPIDataV3 } from 'utils/api';
import { REQUESTS } from 'utils/requests';

import * as constants from './constants';
import * as slice from './reducer';
import * as sagaActions from './sagaActions';
import { getCustomerOptions, getParameterList } from './selectors';
import { getWebAppSettings } from 'models/user/selector';
import { getState } from 'reducers';

const getCustomers = (): Promise<any> => {
	const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

	return postAPIDataV3({
		...REQUESTS.POST.GET_GRID_DETAILS_V2, body: {
			isView,
			multiSortColumns: [{ sortField: "CustomerName", sortDirection: "asc" }]
		}, pathParams: { tableId: 10 }
	})
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true }));
};

const getParametersData = (url: string, filters: any = {}): Promise<any> => {
	return getAPIDataV3({
		service: REQUESTS.GET.GET_ORDERS_OVERRIDE_PARAMS.service,
		url: REQUESTS.GET.GET_ORDERS_OVERRIDE_PARAMS.url,
	})
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true }));
};

const getPlanogramTypes = (url: string, filters: any = {}): Promise<any> => {
	return getAPIDataV3({
		service: REQUESTS.GET.GET_PLANOGRAM_TYPES.service,
		url: REQUESTS.GET.GET_PLANOGRAM_TYPES.url,
	})
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true }));
};

const getShippingMethods = (): Promise<any> => {
	const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

	return postAPIDataV3({
		service: REQUESTS.POST.GET_GRID_DETAILS.service,
		url: REQUESTS.POST.GET_GRID_DETAILS.url,
		body: {
			isView, multiSortColumns: [{
				sortField: "ShipMethodDescription",
				sortDirection: "asc"
			}]
		},
		pathParams: { tableId: 19 },
	})
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true }));
};

const getWarehouses = (): Promise<any> => {
	const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

	return getAPIDataV3({
		service: REQUESTS.GET.GET_WAREHOUSES.service,
		url: REQUESTS.GET.GET_WAREHOUSES.url,
		body: { isView, sort: "ASC" }
	})
		.then(response => ({ response }))
		.catch(error => ({ error, isError: true }));
};

const getStoreSchedules = (): Promise<any> => {
	return getAPIDataV3(REQUESTS.GET.GET_STORE_SCHEDULES)
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true }));
};

const getPresetOrderInfo = (): Promise<any> => {
	return getAPIDataV3({
		service: REQUESTS.GET.GET_PRESET_ORDER_INFO.service,
		url: REQUESTS.GET.GET_PRESET_ORDER_INFO.url,
	})
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true }));
};

export default function* rootSaga() {
	function* setModalView({ payload }: any) {
		const { isOpen } = payload;

		yield put(slice.setModalView({ isOpen }));
	}

	function* updateModalData({ payload }: any) {
		const { data } = payload;

		yield put(slice.updateModalData({ data }));
	}

	function* fetchCustomers() {
		const { response, isError, error } = yield getCustomers();

		yield put(
			slice.updateLoader({
				eventName: 'customers',
				isLoading: true
			})
		);

		if (error?.message === 'CANCELLED') {
			return;
		}
		if (isError) {
			// TODO: Error Handling

			yield put(
				triggerNotification({
					message: 'Failed to fetch Customers'
				})
			);

			yield put(
				slice.updateLoader({
					eventName: 'customers',
					isLoading: false
				})
			);
			return;
		}

		yield put(slice.updateCustomerData({ customers: response?.data }));

		// if (response?.data.length) {
		// 	yield put(
		// 		slice.updateModalData({
		// 			data: { customerID: [response?.data[0].CustomerID] }
		// 		})
		// 	);
		// }

		yield put(
			slice.updateLoader({
				eventName: 'customers',
				isLoading: false
			})
		);
	}

	function* fetchShippingMethods() {
		const { response, isError, error } = yield getShippingMethods();

		yield put(
			slice.updateLoader({
				eventName: 'shipMethods',
				isLoading: true
			})
		);

		if (error?.message === 'CANCELLED') {
			return;
		}

		if (isError) {
			yield put(
				triggerNotification({
					message: 'An error occurred while fetching shipping methods, please try again later.'
				})
			);
			yield put(
				slice.updateLoader({
					eventName: 'shipMethods',
					isLoading: false
				})
			);
			return;
		}

		yield put(slice.updateShippingMethods({ shippingMethods: response?.data }));

		if (response?.data?.length) {
			yield put(
				slice.updateModalData({
					data: { shipMethodID: response?.data.ShipMethodID }
				})
			);
		}

		yield put(
			slice.updateLoader({
				eventName: 'shipMethods',
				isLoading: false
			})
		);
	}

	function* fetchParameters() {
		const { response, isError, error } = yield getParametersData();

		if (error?.message === 'CANCELLED') {
			return;
		}
		if (isError) {
			yield put(
				triggerNotification({
					message: 'An error occurred while fetching parameters, please try again later.'
				})
			);
			return;
		}

		yield put(
			slice.updateLoader({
				eventName: 'parameters',
				isLoading: true
			})
		);


		if (isError) {
			// TODO: Error Handling
			// yield put(
			//   slice.updateLoader({ eventName: tableId.toString(), isLoading: false })
			// );
			return;
		}
		yield put(slice.updateParameters({ parametersList: response }));

		yield put(
			slice.updateLoader({
				eventName: 'parameters',
				isLoading: false
			})
		);
	}

	function* fetchPlanogramTypes() {
		const { response, isError, error } = yield getPlanogramTypes();

		if (error?.message === 'CANCELLED') {
			return;
		}
		if (isError) {
			yield put(
				triggerNotification({
					message: 'An error occurred while fetching Planogram Types, please try again later.'
				})
			);
			return;
		}

		yield put(
			slice.updateLoader({
				eventName: 'parameters',
				isLoading: true
			})
		);


		if (isError) {
			// TODO: Error Handling
			// yield put(
			//   slice.updateLoader({ eventName: tableId.toString(), isLoading: false })
			// );
			return;
		}
		yield put(slice.updatePlanogramTypes({ planogramTypes: response.data }));

		yield put(
			slice.updateLoader({
				eventName: 'parameters',
				isLoading: false
			})
		);
	}

	function* fetchWarehouses() {
		const { response, isError, error } = yield getWarehouses();

		if (error?.message === 'CANCELLED') {
			return;
		}

		if (isError) {
			yield put(
				triggerNotification({
					message: 'An error occurred while fetching warehouses, please try again later.'
				})
			);
			return;
		}

		yield put(
			slice.updateLoader({
				eventName: 'warehouses',
				isLoading: true
			})
		);

		if (isError) {
			// TODO: Error Handling
			// yield put(
			//   slice.updateLoader({ eventName: tableId.toString(), isLoading: false })
			// );
			return;
		}
		yield put(slice.updateWarehouses({ warehouses: response?.data?.data }));
		if (response?.data?.data.length) {
			yield put(
				slice.updateModalData({
					data: { warehouseID: response?.data?.data[0].WarehouseID }
				})
			);
		}

		yield put(
			slice.updateLoader({
				eventName: 'warehouses',
				isLoading: false
			})
		);
	}

	function* fetchPresetInfo() {
		const { response, isError, error } = yield getPresetOrderInfo();

		if (error?.message === 'CANCELLED') {
			return;
		}
		if (isError) {
			// TODO: Error Handling

			yield put(
				triggerNotification({
					message: 'Failed to fetch Preset info'
				})
			);

			return;
		}

		let parametersList = yield select(getParameterList);
		const { orderInfo, parametersInfo, pogInfo } = response;
		const customerOptions = yield select(state => getCustomerOptions(state));

		const customerID = orderInfo?.CustomerID?.split(',').map(c => Number(c));

		if (orderInfo) {
			yield put(
				slice.updateModalData({
					data: {
						// PODate: orderInfo?.PODate,
						customerID:
							customerID?.length === customerOptions.length
								? ['-1']
								: orderInfo?.CustomerID?.split(',').map(c => Number(c)),
						isKitOrder: orderInfo?.IsKitOrder,
						orderBatchMemo: orderInfo?.OrderBatchMemo,
						shipMethodID: orderInfo?.ShipMethodID,
						// shipStartDate: orderInfo?.ShipDate,
						// shipDateRange: [moment(orderInfo?.ShipDate), moment(orderInfo?.EndShipDate || new Date())],
						useStoreSchedule: Boolean(orderInfo?.StoreScheduleID),
						storeScheduleID: orderInfo?.StoreScheduleID,
						warehouseID: orderInfo?.WarehouseID,
						hasParameters: Boolean(parametersInfo?.length),
						isZeroQty: orderInfo?.ZeroQuantityOrder,
						isPrimaryWarehouse: orderInfo?.UsePrimaryWarehouse,
						usePlanogram: pogInfo ? Object.keys(pogInfo).length : false,
						PlanogramParameters: pogInfo
					}
				})
			);
		}
		if (parametersInfo?.length) {
			parametersList = _.map(parametersList, p => {
				const param = _.find(parametersInfo, ({ ParameterID }) => ParameterID === p.ParameterID);
				if (param) {
					return {
						...p,
						...param,
						UpdatedValue: param.ParameterValue
					};
				}
				return p;
			});
			yield put(
				slice.updateParameters({
					parametersList
				})
			);
		}
	}

	function* fetchStoreSchedules() {
		const { response, isError, error } = yield getStoreSchedules();
		if (error?.message === 'CANCELLED') {
			return;
		}
		if (isError) {
			yield put(
				triggerNotification({
					message: 'An error occurred while fetching store schedules, please try again later.'
				})
			);
			return;
		}

		yield put(
			slice.updateLoader({
				eventName: 'storeSchedules',
				isLoading: true
			})
		);

		if (isError) {
			// TODO: Error Handling
			// yield put(
			//   slice.updateLoader({ eventName: tableId.toString(), isLoading: false })
			// );
			return;
		}

		yield put(
			slice.updateStoreSchedules({
				storeSchedules: response?.data.storeSchedules
			})
		);

		if (response?.data.storeSchedules.length) {
			yield put(
				slice.updateModalData({
					data: {
						storeScheduleID: response?.data.storeSchedules[0].warehouseID
					}
				})
			);
		}

		yield put(
			slice.updateLoader({
				eventName: 'storeSchedules',
				isLoading: false
			})
		);
	}

	function* generateOrders({ payload }: any) {
		const { orderInfo, storeOrderSet } = payload;
		const customerOptions = yield select(state => getCustomerOptions(state));

		yield put(
			slice.updateLoader({
				eventName: 'generateOrders',
				isLoading: true
			})
		);

		const storeSearch = yield select(state =>
			dynamicGridSelectors.getGridFilters(state, constants.MODAL_TABLE_IDS.STORES)
		);

		const skuSearch = yield select(state =>
			dynamicGridSelectors.getGridFilters(
				state,
				orderInfo.isKitOrder ? constants.MODAL_TABLE_IDS.KIT_SKUS : constants.MODAL_TABLE_IDS.SKUS
			)
		);

		const reserveInvSearch = yield select(state =>
			dynamicGridSelectors.getGridFilters(state, constants.MODAL_TABLE_IDS.INVENTORY)
		);

		const selectedStores = yield select(state =>
			dynamicGridSelectors.getTableSelection(state, constants.MODAL_TABLE_IDS.STORES)
		);

		const selectedSKUs = yield select(state =>
			dynamicGridSelectors.getTableSelection(
				state,
				orderInfo.isKitOrder ? constants.MODAL_TABLE_IDS.KIT_SKUS : constants.MODAL_TABLE_IDS.SKUS
			)
		);

		const selectedReserveInventory = yield select(state =>
			dynamicGridSelectors.getTableSelection(state, constants.MODAL_TABLE_IDS.INVENTORY)
		);

		const Parameters = yield select(state => {
			return _.map(getParameterList(state), ({ ParameterID, UpdatedValue, Default_Value, IsOn }) => {
				return {
					ParameterID,
					UpdatedValue: UpdatedValue || Default_Value,
					IsOn
				};
			});
		});
		const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

		const StatusID = yield postAPIDataV3({
			service: REQUESTS.POST.ADD_PROCESS_STATUS.service,
			url: REQUESTS.POST.ADD_PROCESS_STATUS.url,
			body: {
				StatusTypeID: 1,
				isView
			},
		})
			.then((response) => response?.data?.OutputParameters?.StatusID)
			.catch((error) => ({
				isError: true,
				error,
			}));

		const orderForSubmit = {
			CustomerID: orderInfo.customerID.includes('-1')
				? customerOptions.map(({ value }) => value).join(',')
				: orderInfo.customerID.join(','),
			PODate: orderInfo.PODate,
			OrderBatchMemo: orderInfo.orderBatchMemo,
			//   storeSKUList: storeOrderSet,
			...(orderInfo.useStoreSchedule
				? {
					ShipEndDate: orderInfo?.useStoreSchedule ? orderInfo?.shipDateRange[1] : null,
					StoreScheduleID: orderInfo.storeScheduleID
				}
				: {}),
			shippingMethodID: orderInfo.shipMethodID,
			IsKitOrder: orderInfo.isKitOrder,
			ShipDate: orderInfo?.useStoreSchedule ? orderInfo.shipDateRange[0] : orderInfo.shipStartDate,
			ShipMethodID: `${orderInfo.shipMethodID}`,
			UserEmail: 'dev@thetaretail.com',
			WarehouseID: Number(orderInfo.warehouseID),
			UserName: 'None',
			StatusID,
			hasParameters: orderInfo.hasParameters,
			ZeroQuantityOrder: orderInfo.isZeroQty,
			UsePrimaryWarehouse: orderInfo.isPrimaryWarehouse,
			Parameters,
			StoreSearch: dynamicGridQuery.createFiltersFromQueries(storeSearch.search),
			SKUSearch: dynamicGridQuery.createFiltersFromQueries(skuSearch.search),
			ReserveStoreSearch: dynamicGridQuery.createFiltersFromQueries(reserveInvSearch?.search),
			SKUIDList: selectedSKUs.isSelectAll ? 'All' : selectedSKUs?.selectedRowKeys?.join(','),
			StoreIDList: selectedStores.isSelectAll ? 'All' : selectedStores?.selectedRowKeys?.join(','),
			PlanogramParameters: _.omit(orderInfo?.usePlanogram ? { usePlanogram: true, ...orderInfo.PlanogramParameters }
				: {}, ['OrderBatchPOGParamsID', 'OrderBatchID']),
			ReserveStoreIDList: selectedReserveInventory
				? selectedReserveInventory?.isSelectAll
					? 'All'
					: selectedReserveInventory?.selectedRowKeys?.join(',')
				: '',
			isView
		};

		try {
			//
			const { isError } = yield postAPIDataV3({ ...REQUESTS.POST.CREATE_ORDER, body: orderForSubmit }).catch(error => ({
				isError: true,
				error
			}));

			if (isError) {
				throw new Error({ message: 'An error occurred while creating order, please try again later.' });
			}

			// yield put(homeSagaActions.updateStatusID(statusReturn.data.OutputParameters.StatusID));

			// yield put(slice.setModalView({ isOpen: false }));

			//   yield put(
			//     slice.updateError({ hasError: true, message: 'Something went wrong' })
			//   );
		} catch (e) {
			triggerNotification({
				...e
			});
		}

		yield put(homeSagaActions.updateStatusID(StatusID));

		yield put(
			slice.updateLoader({
				eventName: 'generateOrders',
				isLoading: false
			})
		);

		yield put(
			triggerNotification({
				message: 'We are processing your orders and will notify you once they are ready.',
				type: 'success'
			})
		);

		setTimeout(() => {
			window.location.href = '/orders?selectedTab=all';
		}, 2000);
	}

	function* loadModal() {
		yield put(
			slice.updateLoader({
				eventName: 'loadModal',
				isLoading: true
			})
		);

		yield all([
			fetchCustomers(),
			fetchShippingMethods(),
			fetchWarehouses(),
			fetchStoreSchedules(),
			fetchParameters(),
			fetchPlanogramTypes()
		]);

		yield fetchPresetInfo();

		yield put(
			slice.updateLoader({
				eventName: 'loadModal',
				isLoading: false
			})
		);
	}

	function* cleanUp() {
		yield put(slice.cleanUP());
	}

	return [
		yield takeEvery(sagaActions.setModalView, setModalView),
		yield takeEvery(sagaActions.updateModalData, updateModalData),
		yield takeEvery(sagaActions.fetchCustomers, fetchCustomers),
		yield takeEvery(sagaActions.fetchShippingMethods, fetchShippingMethods),
		yield takeEvery(sagaActions.fetchWarehouses, fetchWarehouses),
		yield takeEvery(sagaActions.fetchStoreSchedules, fetchStoreSchedules),
		yield takeEvery(sagaActions.generateOrders, generateOrders),
		yield takeEvery(sagaActions.loadModal, loadModal),
		yield takeEvery(sagaActions.cleanUp, cleanUp)
	];
}
