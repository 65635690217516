// @ts-nocheck
import Button from 'atoms/button/button';
import Heading from 'atoms/heading/heading';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import * as dynamicGridQuery from 'models/dynamicGrid/query';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as generateOrderSelectors from './selectors';
import * as generateOrderSagaActions from './sagaActions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as constants from './constants';
import ModalBody from 'atoms/modal/modalBody';
import ModalActions from 'atoms/modal/modalActions';
import _ from 'lodash';
import Loader from 'atoms/loader';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import { Alert } from 'antd';

type TProps = {
	hideModal: React.MouseEventHandler;
	goBack: React.MouseEventHandler;
};

const ReviewOrders = (props: TProps) => {
	const { hideModal, goBack } = props;

	const dispatch = useDispatch();

	const orderInfo = useSelector(state => generateOrderSelectors.getData(state));

	const generatingOrders = useSelector(state => generateOrderSelectors.isLoading(state, 'generateOrders'));

	const error = useSelector(state => generateOrderSelectors.getError(state));

	const selectedStores = useSelector(state =>
		dynamicGridSelectors.getTableSelection(state, constants.MODAL_TABLE_IDS.STORES)
	);

	const selectedSKUs = useSelector(state =>
		dynamicGridSelectors.getTableSelection(
			state,
			orderInfo.isKitOrder ? constants.MODAL_TABLE_IDS.KIT_SKUS : constants.MODAL_TABLE_IDS.SKUS
		)
	);

	const selectedReserveInventory = useSelector(state =>
		dynamicGridSelectors.getTableSelection(state, constants.MODAL_TABLE_IDS.INVENTORY)
	);

	const storeSearch = useSelector(state =>
		dynamicGridSelectors.getGridFilters(state, constants.MODAL_TABLE_IDS.STORES)
	);

	const skuSearch = useSelector(state =>
		dynamicGridSelectors.getGridFilters(
			state,
			orderInfo.isKitOrder ? constants.MODAL_TABLE_IDS.KIT_SKUS : constants.MODAL_TABLE_IDS.SKUS
		)
	);

	const reserveInvSearch = useSelector(state =>
		dynamicGridSelectors.getGridFilters(state, constants.MODAL_TABLE_IDS.INVENTORY)
	);

	const storeTotalCount = useSelector(state =>
		dynamicGridSelectors.getTotalCount(state, constants.MODAL_TABLE_IDS.STORES)
	);
	const skuTotalCount = useSelector(state =>
		dynamicGridSelectors.getTotalCount(
			state,
			orderInfo.isKitOrder ? constants.MODAL_TABLE_IDS.KIT_SKUS : constants.MODAL_TABLE_IDS.SKUS
		)
	);

	const reservedInventoryCount = useSelector(state =>
		dynamicGridSelectors.getTotalCount(state, constants.MODAL_TABLE_IDS.INVENTORY)
	);

	const handleNextClick = () => {
		const storeOrderSet: any[] = [];

		let parsedStoreSearch: any = '';
		let parsedSKUSearch: any = '';

		if (selectedStores.isSelectAll) {
			parsedStoreSearch = {
				filter: dynamicGridQuery.createFiltersFromQueries(storeSearch ? storeSearch.search : [])
			};
		}

		if (selectedSKUs.isSelectAll) {
			parsedSKUSearch = {
				filter: dynamicGridQuery.createFiltersFromQueries(skuSearch ? skuSearch.search : [])
			};
		}

		// storeOrderSet.push({
		//   stores: selectedStores.isSelectAll
		//     ? 'ALL'
		//     : selectedStores?.selectedRowKeys.join(','),
		//   SKUs: selectedSKUs.isSelectAll
		//     ? 'ALL'
		//     : selectedSKUs?.selectedRowKeys.join(','),
		//   storeSearch: JSON.stringify(parsedStoreSearch),
		//   skuSearch: JSON.stringify(parsedSKUSearch),
		//   isReservedInv: 0,
		// });

		// if (selectedReserveInventory && !selectedStores.isSelectAll) {
		//   let parsedReserveInvSearch: any = '';

		//   if (selectedReserveInventory.isSelectAll) {
		//     parsedReserveInvSearch = {
		//       filter: dynamicGridQuery.createFiltersFromQueries(
		//         reserveInvSearch ? reserveInvSearch.search : []
		//       ),
		//     };
		//   }

		//   storeOrderSet.push({
		//     stores: selectedReserveInventory.isSelectAll
		//       ? 'ALL'
		//       : selectedReserveInventory.selectedRowKeys.join(','),
		//     SKUs: selectedSKUs.isSelectAll
		//       ? 'ALL'
		//       : selectedSKUs?.selectedRowKeys.join(','),
		//     storeSearch: JSON.stringify(parsedReserveInvSearch),
		//     skuSearch: JSON.stringify(parsedSKUSearch),
		//     isReservedInv: 1,
		//   });
		// }

		dispatch(generateOrderSagaActions.generateOrders(orderInfo, storeOrderSet));
	};

	const storeCount = selectedStores?.isSelectAll ? storeTotalCount : selectedStores?.selectedRowKeys?.length;

	const skuCount = selectedSKUs?.isSelectAll ? skuTotalCount : selectedSKUs?.selectedRowKeys?.length;

	const reserveInventoryCount = selectedReserveInventory?.isSelectAll
		? reservedInventoryCount
		: selectedReserveInventory?.selectedRowKeys?.length;

	const isLoading = generatingOrders;

	return (
		<Stack isVertical gutter="small">
			<StackItem isGrow>
				<Sheet isTab>
					<SheetBody>
						{error && error.hasError ? <StackItem>{error.message}</StackItem> : <React.Fragment />}
						{isLoading ? (
							<Stack isVertical>
								<StackItem isGrow isStretch align="center">
									<Loader />
								</StackItem>
							</Stack>
						) : (
							<Stack isVertical gutter="medium">
								<StackItem>
									<Heading type="secondary">You have selected {storeCount} stores.</Heading>
								</StackItem>
								<StackItem>
									<Heading type="secondary">You have selected {skuCount} SKUs.</Heading>
								</StackItem>
								<StackItem>
									<Heading type="secondary">
										You have set aside allocation for {reserveInventoryCount || 0} stores.
									</Heading>
								</StackItem>

								<StackItem isGrow isStretch position="bottom">
									<Alert
										style={{
											height: '6rem'
										}}
										showIcon
										message="Note"
										description="Please note that the Orders Processing might take up to 10 minutes depending on the selections."
										type="info"
									/>
								</StackItem>
							</Stack>
						)}
					</SheetBody>
				</Sheet>
			</StackItem>
			<StackItem>
				<Stack align="left" gutter="small">
					<StackItem isGrow>
						<Button
							disabled={isLoading}
							isSecondary
							name={orderInfo.hasReserveInventory ? 'Reselect reserve inventory' : 'Reselect SKUs'}
							handleClick={goBack}
						/>
					</StackItem>

					<StackItem isStretch align="right">
						<Button disabled={isLoading} name={'Create Order'} handleClick={handleNextClick} />
					</StackItem>
				</Stack>
			</StackItem>
		</Stack>
	);
};

export default ReviewOrders;
