// @ts-nocheck
import Button from 'atoms/button/button';
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import Modal from 'atoms/modal/modal';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import SheetAction from 'atoms/sheet/sheetAction/sheetAction';
import './deleteModal.scss';
import Loader from 'atoms/loader';
import { getLoadingStatus } from './selectors';
import { useSelector } from 'react-redux';

interface Props {
	hideModal: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
	modalTitle: string;
	title: string; //TODO: Find a new keyword
	handleDeleteClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const ThetaDeleteModal = (props: Props) => {
	const { hideModal, modalTitle, title, handleDeleteClick, isLocked } = props;
	const loading = useSelector(state => getLoadingStatus(state));

	return (
		<Modal isLocked={isLocked} hideModal={hideModal} height={300}>
			<Sheet isInset>
				<SheetBody>
					<Stack isVertical gutter="small">
						<StackItem isStretch>
							<Heading isSecondary> Delete {modalTitle} </Heading>
						</StackItem>
						<StackItem isStretch>
							<Divider />
						</StackItem>
						{loading ? (
							<StackItem isGrow isStretch align="center">
								<Loader />
							</StackItem>
						) : (
							<StackItem isGrow>
								<Stack isVertical gutter="medium">
									<StackItem isStretch>
										<Heading type="secondary">Would you like to delete {title}?</Heading>
									</StackItem>
								</Stack>
							</StackItem>
						)}
					</Stack>
				</SheetBody>
				<SheetAction>
					<Stack align="right" gutter="small">
						<StackItem>
							<Button name="NO, DO NOT DELETE" isSecondary handleClick={hideModal} disabled={loading} />
						</StackItem>
						<StackItem isStretch align="right">
							<Button
								name={`YES, DELETE ${modalTitle.toUpperCase()}`}
								handleClick={handleDeleteClick}
								disabled={loading}
							/>
						</StackItem>
					</Stack>
				</SheetAction>
			</Sheet>
		</Modal>
	);
};

export default ThetaDeleteModal;
