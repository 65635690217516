// @ts-nocheck
import { createFiltersFromQueries } from 'models/dynamicGrid/query';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import { rowDelete } from 'models/dynamicGrid/sagas';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import { triggerNotification } from 'organisms/notifications/sagaActions';
import { put, select, takeEvery } from 'redux-saga/effects';
import * as slice from './reducer';
import * as sagaActions from './sagaActions';

export default function* rootSaga() {
	//delete sku exclusion
	function* handleDeletePricing({ payload }) {
		const { tableId } = payload;
		const pricingSearch = yield select(state => dynamicGridSelectors.getGridFilters(state, tableId));
		const pricingSelection = yield select(state => dynamicGridSelectors.getTableSelection(state, tableId));

		yield handleToggleLoading({ payload: { status: true } });
		const { isError, error } = yield rowDelete({
			tableId: tableId,
			rowIdentifier: pricingSelection?.selectedRowKeys,
			isSelectAll: pricingSelection?.isSelectAll,
			selectAllSearch: JSON.stringify({
				filter: createFiltersFromQueries(pricingSearch?.search)
			})
		});
		if (isError || error?.warning) {
			yield put(
				triggerNotification({
					message: error?.message || 'An error occurred while deleting sku exclusions, please try again later'
				})
			);

			if (!error.warning) {
				yield handleToggleLoading({ payload: { status: false } });
				return;
			}
		}
		yield put(dynamicGridSagaActions.updateGrid({ tableId, filters: {} }));
		yield put(
			dynamicGridSagaActions.updateSelectedRows({
				tableId,
				selectedRowKeys: [],
				isSelectAll: false
			})
		);

		yield handleCloseModal({ payload: { tableIds: [] } });
		yield handleToggleLoading({ payload: { status: false } });
	}

	//toggle loading
	function* handleToggleLoading({ payload }) {
		const { status } = payload;
		yield put(slice.handleToggleLoading({ status }));
	}

	//open modal
	function* handleOpenModal({ payload }) {
		const { openModalName } = payload;
		yield put(slice.handleOpenModal({ openModalName }));
	}

	//close modal
	function* handleCloseModal() {
		yield put(slice.handleOpenModal({ openModalName: '' }));
	}

	return [
		yield takeEvery(sagaActions.deletePricing, handleDeletePricing),
		yield takeEvery(sagaActions.toggleLoading, handleToggleLoading),
		yield takeEvery(sagaActions.toggleModalOpen, handleOpenModal),
		yield takeEvery(sagaActions.toggleModalClose, handleCloseModal)
	];
}
