// @ts-nocheck
import Heading from 'atoms/heading/heading';
import Sheet from 'atoms/sheet/sheet';
import _ from 'lodash';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as React from 'react';
import Tabs from 'atoms/tabs/tabs';
import { Tab } from 'atoms/tab/tab';
import * as constants from './constants';
import ParameterGroups from './parameterGroups';
import SearchFilter from 'organisms/searchFilter';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';

const Parameters: React.FunctionComponent = () => {
	const [selectedTab, handleSelectTabItem] = React.useState(constants.PARAMETERS_TABS[0].value);

	return (
		<Sheet isPage>
			<Stack position="top" isVertical gutter="small">
				{/* <StackItem isStretch>
					<Heading> Parameters Settings</Heading>
				</StackItem> */}
				<StackItem isStretch isGrow>
					<Stack isVertical>
						<StackItem isStretch>
							<Tabs>
								{_.map(
									constants.PARAMETERS_TABS,
									(tab: { name: string; value: string }, index: number) => {
										return (
											<Tab
												key={index}
												value={tab.value}
												isSelected={selectedTab === tab.value}
												onSelectTabItem={tab => {
													handleSelectTabItem(tab.value);
												}}
											>
												{tab.name}
											</Tab>
										);
									}
								)}
							</Tabs>
						</StackItem>

						<StackItem isStretch isGrow>
							<SheetBody>
								<ParameterGroups selectedTab={selectedTab} />
							</SheetBody>
						</StackItem>
					</Stack>
				</StackItem>
			</Stack>
		</Sheet>
	);
};

export default Parameters;
