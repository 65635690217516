// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import * as constants from './constants';
import update from 'react-addons-update';

export const initialState: any = {
	modalParams: {},
	isOpen: false,
	isAvailabilityUpdate: false,
	tableId: null,
	isLoading: false,
	shipMethods: []
};

const slice = createSlice({
	initialState,
	name: `${constants.MODEL_NAME}`,
	reducers: {
		cleanUp() {
			return {
				...initialState
			};
		},
		updateModalParams(state, { payload: { data } }) {
			return {
				...state,
				modalParams: {
					...state.modalParams,
					...data
				}
			};
		},
		updateModalToggle(state, { payload: { isOpen, isAvailabilityUpdate, tableId } }) {
			return {
				...state,
				isOpen: isOpen,
				isAvailabilityUpdate,
				tableId
			};
		},
		updateLoader(state, { payload: { isLoading } }) {
			return {
				...state,
				isLoading: isLoading
			};
		},
		updateShippingMethods(state, { payload: { shipMethods } }) {
			return {
				...state,
				shipMethods
			};
		}
	}
});

export default slice;
export const { updateModalParams, updateModalToggle, cleanUp, updateLoader, updateShippingMethods } = slice.actions;
