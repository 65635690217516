// @ts-nocheck
export const PLANOGRAM_ATTRIBUTES_TABLE_ID = 93;
export const PLANOGRAM_STORES_TABLE_ID = 94;
export const PLANOGRAM_ALL_ATTRIBUTES_LIST = 91;
export const PLANOGRAM_ADD_STORES_TABLE_ID = 95;
export const TABLE_ID = 92;

// export const TABLE_ID_STORAGE = 93;

export const STORE_PREFIX = 'planogram/details';

export const MENU_ITEMS_NAMES = {
	CUSTOMIZE_VIEW: 'Customize View',
	RESET_VIEW: 'Reset View',
	UPLOAD_DATA: 'Upload Data',
	DELETE: 'Deactivate'
};


export const TABS = [
	{ name: 'Stores', value: PLANOGRAM_STORES_TABLE_ID },
	{ name: 'Attributes', value: PLANOGRAM_ATTRIBUTES_TABLE_ID }
]


export const LOADER_COMPONENTS = {
	SAVE_ATTRIBUTE_DETAILS: "SAVE_ATTRIBUTE_DETAILS"
}
export const MENU_ITEMS_VALUES = {
	CUSTOMIZE_VIEW: 'CUSTOMIZE_VIEW',
	RESET_VIEW: 'RESET_VIEW',
	UPLOAD_DATA: 'UPLOAD_DATA',
	DELETE: 'DELETE'
};

export const MENU_ITEMS = [

	{
		displayName: MENU_ITEMS_NAMES.RESET_VIEW,
		value: MENU_ITEMS_VALUES.RESET_VIEW
	},
	{
		displayName: MENU_ITEMS_NAMES.UPLOAD_DATA,
		value: MENU_ITEMS_VALUES.UPLOAD_DATA
	},
	{
		displayName: MENU_ITEMS_NAMES.DELETE,
		value: MENU_ITEMS_VALUES.DELETE
	}
];
