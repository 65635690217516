// @ts-nocheck

import { isAllOrdersWrite, isOrderItemsWrite, isOrderKitsWrite, isOrderSkusWrite } from 'models/user/selector';
import { DROPDOWN_MENU_ALL_ORDERS, DROPDOWN_MENU_ALL_ORDERS_COMPLETED, DROPDOWN_MENU_ORDER_ITEMS, DROPDOWN_MENU_ORDER_KITS, DROPDOWN_MENU_ORDER_SKUS } from './constants';

export const getDropdownMenu = (state, selectedTab, orderStatus) => {
    let options = [];
    if (selectedTab === '23') {
        if (orderStatus.value === '1' || orderStatus.value === '2') {
            options = DROPDOWN_MENU_ALL_ORDERS_COMPLETED;
        }
        options = DROPDOWN_MENU_ALL_ORDERS;
        if (!isAllOrdersWrite(state)) {
            options = options.slice(0,2)
        }
    return options;

    }
    if (selectedTab === '1') {
        if (orderStatus.value === '1' || orderStatus.value === '2') {
            options =DROPDOWN_MENU_ALL_ORDERS_COMPLETED;
        }
        options = DROPDOWN_MENU_ORDER_SKUS;
        if (!isOrderSkusWrite(state)) {
            options = options.slice(0,2)
        }
        return options;

    }
    if (selectedTab === '2') {
        if (orderStatus.value === '1' || orderStatus.value === '2') {
            options =DROPDOWN_MENU_ALL_ORDERS_COMPLETED;
        }
        options = DROPDOWN_MENU_ORDER_ITEMS;
        if (!isOrderItemsWrite(state)) {
            options = options.slice(0,2)
        }
        return options;
    }
    if (selectedTab === '30') {
        if (orderStatus.value === '1' || orderStatus.value === '2') {
            options = DROPDOWN_MENU_ALL_ORDERS_COMPLETED;
        }
        options = DROPDOWN_MENU_ORDER_KITS;
        if (!isOrderKitsWrite(state)) {
            options = options.slice(0,2)
        }
     return options;

    }
    options = DROPDOWN_MENU_ORDER_ITEMS;
    if (!isOrderItemsWrite(state)) {
        options = options.slice(0,2)
    }
    return options;

};