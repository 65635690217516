// @ts-nocheck
import Reducer from './reducer';
import Sagas from './sagas';
export default [
  {
    path: '/',
    key: 'key',
    componentId: 'paginationModel',
    // component: () => <Home title="asd" />,
    routes: [],
    importReducer: () => {
      return [Reducer];
    },
    importSagas: () => {
      return [Sagas];
    },
    // title: 'Skulicity | Models',
  },
];
