// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import * as constants from './constants';
import { TTransactionReducer } from './types';

export const initialState: TTransactionReducer = {};

const slice = createSlice({
	initialState,
	name: 'model/bookmarks',
	reducers: {
		setBookmarksList(state, { payload: { tableId, bookmarks } }) {
			return {
				...state,
				[tableId]: bookmarks
			};
		}
	}
});

export default slice;

export const { setBookmarksList } = slice.actions;
