// @ts-nocheck
import Button from 'atoms/button/button';
import Loader from 'atoms/loader';
import * as advertisingSelectors from 'models/advertisings/selector';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ThetaGrid from 'templates/thetaGrid';
import { Tprops } from './types';
import { AD_MODAL_TABLE_IDS } from './constants';
import * as sagaActions from './sagaActions';
import _ from 'lodash';
import * as adModalSelectors from './selector';
import ModalBody from 'atoms/modal/modalBody';
import ModalActions from 'atoms/modal/modalActions';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import { createFiltersFromQueries } from 'models/dynamicGrid/query';
import { REQUESTS } from 'utils/requests';
const SelectSkus = (props: Tprops) => {
	const { hideModal, handleNextClick, goBack } = props;
	const name = 'adSkus';
	const tableId = AD_MODAL_TABLE_IDS.SKUS;
	const [ref, handleUpdateRef] = React.useState(null);
	const dispatch = useDispatch();
	const isLoading = false;
	// const isLoading = useSelector(state => advertisingSelectors.isLoading(state, name));
	const selectedStores = useSelector(state =>
		dynamicGridSelectors.getTableSelection(state, AD_MODAL_TABLE_IDS.STORES)
	);
	const searchQueryStores = useSelector(state =>
		dynamicGridSelectors.getGridFilters(state, AD_MODAL_TABLE_IDS.STORES)
	);

	const gridConfig = {
		enableSelection: true,
		enableSorting: true
	};
	const storeSearch = useSelector(state =>
		dynamicGridSelectors.getGridFilters(state, AD_MODAL_TABLE_IDS.STORES)
	);

	const skus = useSelector(state => dynamicGridSelectors.getGridData(state, AD_MODAL_TABLE_IDS.SKUS));
	const adData: any = useSelector(state => adModalSelectors.getAdModalData(state, 'advertising')) || {};

	const selectedSkus = useSelector(state => dynamicGridSelectors.getTableSelection(state, AD_MODAL_TABLE_IDS.SKUS));
	const enterPricingData = useSelector(state => adModalSelectors.getPricingData(state));

	const handleSelectPricing = (e: any) => {
		if (skus && skus.length) {
			let data = [];
			for (let item of selectedSkus?.selectedRowKeys) {
				let index = _.findIndex(skus, function (singleItem: any) {
					return singleItem['SKUID'] === item;
				});
				if (index > -1) {
					let enterPricingIndex = _.findIndex(enterPricingData, function (singleItem: any) {
						return singleItem['SKUID'] === item;
					});
					if (enterPricingIndex > -1) {
						if (skus[index].AdCost || skus[index].MinOrderQty) {
							data.push(skus[index]);
						} else {
							data.push(enterPricingData[enterPricingIndex]);
						}
					} else {
						data.push(skus[index]);
					}
				}
			}

			// let data = [];
			//   for (let item of selectedSkus?.selectedRowKeys) {
			//     let index = _.findIndex(skus, function(singleItem: any) {
			//       return singleItem['SKUID'] === item;
			//     });
			//         data.push(skus[index]);
			//     }
			dispatch(sagaActions.updatePricingData(data));
			handleNextClick(e);
		}
	};

	const templateConfig = {
		gridConfig,
		isFilterEnabled: true,
		tableId: tableId,
		selectRowKey: 'SKUID',
		dataProviderOptions: {
			...REQUESTS.POST.GET_VALID_SKUS,
			pathParams: { customerId: adData.CustomerID },
			type: 'POST',
			version: 2,
			multiSelectFieldGroups: {
				StoreList: selectedStores.isSelectAll ? 'ALL' : selectedStores?.selectedRowKeys.join(','),
			},
			multiSelectFilterFieldGroups: {
				StoreSearch: selectedStores?.isSelectAll ? storeSearch?.search ?? [] : []
			},
			body: {
				...(selectedStores.isSelectAll
					? {
						isSelectAll: true,
						selectAllSearch: JSON.stringify({
							filter: createFiltersFromQueries(searchQueryStores?.search)
						})
					}
					: {
						storeIds: selectedStores?.selectedRowKeys,
						selectAllSearch: JSON.stringify({
							filter: createFiltersFromQueries(searchQueryStores?.search)
						})
					})
			}
		}
	};

	const renderLoader = () => {
		return (
			<StackItem isGrow isStretch align="center">
				<Loader />
			</StackItem>
		);
	};

	return (
		<>
			<ModalBody onDomRef={handleUpdateRef}>
				{isLoading ? renderLoader() : <ThetaGridPaginated parent={ref} config={templateConfig} />}
			</ModalBody>
			<ModalActions>
				<Stack gutter="medium">
					<StackItem isGrow isStretch>
						<Stack align="left" gutter="small">
							<StackItem>
								<Button isSecondary name="RE-SELECT STORES" handleClick={goBack} />
							</StackItem>
						</Stack>
					</StackItem>
					<StackItem>
						<Stack align="right" gutter="small">
							<StackItem>
								<Button isSecondary name="CANCEL" handleClick={hideModal} />
							</StackItem>
							<StackItem isStretch align="right">
								<Button
									disabled={!selectedSkus?.selectedRowKeys?.length}
									name={'SELECT PRICING'}
									handleClick={handleSelectPricing}
								/>
							</StackItem>
						</Stack>
					</StackItem>
				</Stack>
			</ModalActions>
		</>
	);
};

export default SelectSkus;
