// @ts-nocheck
import BodyText from 'atoms/bodyText/bodyText';
import Button from 'atoms/button/button';
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import { Tab } from 'atoms/tab/tab';
import Tabs from 'atoms/tabs/tabs';
import _ from 'lodash';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import { formatDate } from 'utils/utilities';
import { useDispatch, useSelector } from 'react-redux';
import * as sagaActions from '../sagaActions';
import * as selectors from '../selectors';
import SelectParameters from './selectParameters';
import SelectStores from './selectStores';
import { SELECT_STORES_TABLE_ID } from './constants';
import Loader from 'atoms/loader';
import ModalHeader from 'atoms/modal/modalHeader';
import ReactModal from 'atoms/modal/reactModal';
import ModalBody from 'atoms/modal/modalBody';
import ModalActions from 'atoms/modal/modalActions';

interface TProps {
	hideModal: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
	selectedTab: string;
}

const CLONE_ODRER_STEPS = {
	SELECT_PARAMETERS: 'selectParameters',
	SELECT_STORES: 'selectStores',
	REVIEW_ORDERS: 'reviewOrders'
};

const SINGLE_CLONE = [
	{
		title: 'Select Parameters',
		identifier: CLONE_ODRER_STEPS.SELECT_PARAMETERS
	},
	{
		title: 'Select Stores',
		identifier: CLONE_ODRER_STEPS.SELECT_STORES
	},
	{
		title: 'Review Orders',
		identifier: CLONE_ODRER_STEPS.REVIEW_ORDERS
	}
];

const MULTIPLE_CLONE = [
	{
		title: 'Select Parameters',
		identifier: CLONE_ODRER_STEPS.SELECT_PARAMETERS
	},
	{
		title: 'Review Orders',
		identifier: CLONE_ODRER_STEPS.REVIEW_ORDERS
	}
];

const getDisabledStatus = (orderCloneInfo: any, selectedStores: any, identifier: string) => {
	switch (identifier) {
		case CLONE_ODRER_STEPS.SELECT_PARAMETERS:
			return false;
		case CLONE_ODRER_STEPS.SELECT_STORES:
			return (
				!orderCloneInfo?.selectedParameters?.orderCloneType ||
				!orderCloneInfo?.selectedParameters?.shipDate ||
				!orderCloneInfo?.selectedParameters?.poDate
			);
		case CLONE_ODRER_STEPS.REVIEW_ORDERS:
			return !selectedStores?.selectedRowKeys?.length;
		default:
			return true;
	}
};

const CloneModalHeader = ({
	isMultipleClone,
}: any) => {
	return (
		<Stack isVertical gutter="small">
			<StackItem isStretch>
				<Heading>{isMultipleClone ? 'Clone Orders' : 'Clone Order'}</Heading>
			</StackItem>
		</Stack>
	);
};

const CloneModalTabs =  ({
	selectedStep,
	setSelectedStep,
	steps,
	orderCloneInfo,
	selectedStores,
	isLoading
}: any) => {
	return (
		<Stack isVertical gutter="small">
			<StackItem isStretch>
				<Tabs type="secondary">
					{_.map(steps, (step, index) => {
						return (
							<Tab
								key={index}
								value={index}
								isSelected={selectedStep.identifier === step.identifier}
								onSelectTabItem={() => setSelectedStep(step)}
								disabled={
									getDisabledStatus(orderCloneInfo, selectedStores, step.identifier) || isLoading
								}
							>
								{step.title}
							</Tab>
						);
					})}
				</Tabs>
			</StackItem>
		</Stack>
	);
};

const CloneModalContent = ({
	selectedStep,
	setSelectedStep,
	hideModal,
	selectedOrders,
	orderCloneInfo,
	selectedCloneType,
	dropdownTypes,
	parent,
	isSelectAll,
	orderTotalCount,
	selectedStores,
	selectedStoresTotalCount
}: any) => {
	switch (selectedStep.identifier) {
		case CLONE_ODRER_STEPS.SELECT_STORES:
			return <SelectStores parent={parent} orderCloneInfo={orderCloneInfo} selectedOrders={selectedOrders} />;
		case CLONE_ODRER_STEPS.REVIEW_ORDERS:
			return (
				<Stack isVertical gutter="medium">
					<StackItem>
						<BodyText>
							You have selected {isSelectAll ? orderTotalCount : selectedOrders.length} orders to{' '}
							{selectedCloneType?.[0]?.displayName} into{' '}
							{selectedStores?.isSelectAll
								? selectedStoresTotalCount
								: selectedStores?.selectedRowKeys.length}{' '}
						</BodyText>
					</StackItem>
					<StackItem>
						<BodyText>
							Your new Ship Date is {formatDate(orderCloneInfo?.selectedParameters?.shipDate)}.
						</BodyText>
					</StackItem>
					<StackItem>
						<BodyText>
							Your new P.O. Date is {formatDate(orderCloneInfo?.selectedParameters?.poDate)}.
						</BodyText>
					</StackItem>
				</Stack>
			);
		case CLONE_ODRER_STEPS.SELECT_PARAMETERS:
		default:
			return <SelectParameters dropdownTypes={dropdownTypes} />;
	}
};

const CloneModalActions = ({
	selectedStep,
	setSelectedStep,
	hideModal,
	cloneOrder,
	steps,
	isMultipleClone,
	orderCloneInfo,
	selectedStores,
	isLoading
}: any) => {
	const getNextAction = () => {
		switch (selectedStep.identifier) {
			case CLONE_ODRER_STEPS.SELECT_STORES:
				return (
					<Button
						name="Review Order"
						handleClick={() => setSelectedStep(steps[2])}
						disabled={!selectedStores?.selectedRowKeys?.length || isLoading}
					/>
				);
			case CLONE_ODRER_STEPS.REVIEW_ORDERS:
				return <Button name="Clone Order" handleClick={cloneOrder} disabled={isLoading} />;
			case CLONE_ODRER_STEPS.SELECT_PARAMETERS:
			default:
				return (
					<Button
						name={isMultipleClone ? 'Review Order' : 'Select Stores'}
						handleClick={() => setSelectedStep(steps[1])}
						disabled={
							!orderCloneInfo?.selectedParameters?.orderCloneType ||
							!orderCloneInfo?.selectedParameters?.shipDate ||
							!orderCloneInfo?.selectedParameters.poDate ||
							isLoading
						}
					/>
				);
		}
	};
	return (
		<Stack gutter="small" align="right">
			<StackItem>
				<Button isSecondary name="Cancel Order Clone" handleClick={hideModal} disabled={isLoading} />
			</StackItem>
			<StackItem>{getNextAction()}</StackItem>
		</Stack>
	);
};

const ThetaOrderCloneModal = (props: TProps) => {
	const { hideModal, selectedTab } = props;
	const dispatch = useDispatch();
	const selectedOrders = useSelector(state => dynamicGridSelectors.getTableSelection(state, Number(selectedTab)));

	const isMultipleClone = selectedOrders?.selectedRowKeys?.length > 1;
	const steps = isMultipleClone ? MULTIPLE_CLONE : SINGLE_CLONE;
	const [selectedStep, setSelectedStep] = React.useState<any>(steps[0]);
	const [ref, handleUpdateRef] = React.useState(null);
	React.useEffect(() => {
		dispatch(sagaActions.getCloneDefaultColumnTypes());
	}, [dispatch]);

	const orderCloneInfo = useSelector(state => selectors.getCloneOrderInfo(state));

	const selectedStores = useSelector(state => dynamicGridSelectors.getTableSelection(state, SELECT_STORES_TABLE_ID));
	const orderTotalCount = useSelector(state => dynamicGridSelectors.getTotalCount(state, Number(selectedTab)));
	const isLoading = useSelector(state => selectors.getLoadingStatus(state));
	const selectedStoresTotalCount = useSelector(state =>
		dynamicGridSelectors.getTotalCount(state, Number(SELECT_STORES_TABLE_ID))
	);

	const cloneOrder = (e: any) => {
		const reqData = {
			orderCloneTypeId: orderCloneInfo?.selectedParameters?.orderCloneType,
			orderIds: selectedOrders?.selectedRowKeys,
			shipDate: orderCloneInfo?.selectedParameters?.shipDate,
			poDate: orderCloneInfo?.selectedParameters?.poDate,
			storeIds: selectedStores?.selectedRowKeys
		};
		dispatch(sagaActions.cloneOrders({ tableId: selectedTab, data: reqData }));
	};
	const dropdownOptionTypes = useSelector(state => selectors.getDefaultCloneTypes(state)) || [];
	const dropdownTypes = dropdownOptionTypes.map(order => {
		return { displayName: order.CloneOrderType, value: order.CloneOrderTypeID };
	});
	const selectedOption = dropdownTypes.filter(
		option => option.value === orderCloneInfo?.selectedParameters?.orderCloneType
	);
	return (
		<ReactModal
			hideModal={hideModal}
			height={selectedStep?.identifier === steps[0]?.identifier ? 400 : 800}
			width={selectedStep?.identifier === steps[0]?.identifier ? 500 : 800}
		>
			<ModalHeader>
				<CloneModalHeader
					isMultipleClone={isMultipleClone}
				/>
			</ModalHeader>
			<ModalBody onDomRef={handleUpdateRef}>
				<Stack isVertical gutter="medium">
					<StackItem>
					<CloneModalTabs selectedStep={selectedStep}
					setSelectedStep={setSelectedStep}
					selectedOrders={selectedOrders?.selectedRowKeys}
					steps={steps}
					isMultipleClone={isMultipleClone}
					orderCloneInfo={orderCloneInfo}
					selectedStores={selectedStores}
					isLoading={isLoading} />
					</StackItem>
					<StackItem isGrow isStretch>
					{isLoading ? (
					<Stack isVertical>
						<StackItem isGrow isStretch align="center">
							<Loader />
						</StackItem>
					</Stack>
				) : (
					<CloneModalContent
						selectedStep={selectedStep}
						setSelectedStep={setSelectedStep}
						hideModal={hideModal}
						selectedOrders={selectedOrders?.selectedRowKeys}
						orderCloneInfo={orderCloneInfo}
						steps={steps}
						isMultipleClone={isMultipleClone}
						selectedCloneType={selectedOption}
						dropdownTypes={dropdownTypes}
						parent={ref}
						isSelectAll={selectedOrders.isSelectAll}
						orderTotalCount={orderTotalCount}
						selectedStores={selectedStores}
						selectedStoresTotalCount={selectedStoresTotalCount}
					/>
				)}

					</StackItem>
				</Stack>
			
			
			</ModalBody>
			<ModalActions>
				<CloneModalActions
					selectedStep={selectedStep}
					setSelectedStep={setSelectedStep}
					hideModal={hideModal}
					cloneOrder={cloneOrder}
					steps={steps}
					isMultipleClone={isMultipleClone}
					selectedStores={selectedStores}
					orderCloneInfo={orderCloneInfo}
					isLoading={isLoading}
				/>
			</ModalActions>
		</ReactModal>
	);
};

export default ThetaOrderCloneModal;
