// @ts-nocheck
import { initialState, reducerName } from './reducer';
// import * as reducer from './reducer';
const demandGroupHubSelector = state => state[reducerName] || initialState;

export const getDemandPlanningDimensions = state => {
	return demandGroupHubSelector(state).demandPlanningDimensions;
};

export const getDemandPlanningDimensionDetail = (state, planningGroupId) => {
	return demandGroupHubSelector(state).demandPlanningDimensions?.find(d => d.PlanningGroupID === planningGroupId);
};

export const getDemandDimesionGroups = (state, dimensionPlanningId) => {
	return demandGroupHubSelector(state)[dimensionPlanningId]?.dimensionGroups ?? [];
};

export const getDimesionAttributes = (state, dimensionPlanningId) => {
	return demandGroupHubSelector(state)[dimensionPlanningId]?.dimensionAttributes ?? [];
};

export const getSelectedAttributeDetail = state => demandGroupHubSelector(state).selectedAttributeDetail;
