// @ts-nocheck
import { initialState } from './reducer';
import * as constants from './constants';

const getStateData = (state: any) =>
  state[constants.MODEL_NAME] || initialState;

export const getGroupModalState = (state: any): boolean => {
  const reducer = getStateData(state);

  return reducer.isOpen;
};

export const getGroupModalData = (state: any): boolean => {
  const reducer = getStateData(state);

  return reducer.groupData;
};
export const getEditMode = (state: any): boolean => {
  const reducer = getStateData(state);
  return reducer.editMode;
};

export const getEditData = (state: any): boolean => {
  const reducer = getStateData(state);
  return reducer.editData;
};

export const isLoading = (state: any): boolean => {
  const reducer = getStateData(state);
  return reducer.isLoading;
};

export const getCustomers =(state:any) =>{
  const reducer = getStateData(state);
  return reducer.customers;
}
