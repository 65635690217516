// @ts-nocheck
// called in main file
// will get items and on click handler for menu items and toggleMenu

import * as React from 'react';
import classNames from 'classnames';
import './thetaMenu.css';
import MenuItem, { Option } from '../../atoms/menuItem/menuItem';
import Menu from '../../atoms/menu/menu';
import _, { uniqueId } from 'lodash';
import Icon from 'atoms/icons/icon';
import { useOutsideAlerter } from 'utils/helpers';
import Heading from 'atoms/heading/heading';
import Button from 'atoms/button/button';
import { isNewUploadEnabled } from 'models/user/selector';
import { useSelector } from 'react-redux';

interface Props {
	type: 'Image' | 'String' | 'Button' | '3Dots';
	options: ReadonlyArray<Option>;
	handleItemClick: Function;
	handleName?: string | undefined;
	src?: string | undefined;
	isDisabled: Function;
}
const ThetaMenu = (props: Props) => {
	const { type, options, handleItemClick, handleName, src, isDisabled } = props;
	const classes: any = classNames(['theta-menu']);
	const wrapperRef = React.useRef(null);
	const [openMenu, toggleMenu] = React.useState(false);

	const showMenuItems = React.useCallback(() => {
		if (openMenu) {
			return toggleMenu(false);
		}
		toggleMenu(true);
	}, [toggleMenu]);

	const handleMenuItemSelect = React.useCallback(
		(option: Option) => {
			toggleMenu(false);
			handleItemClick(option.value);
		},
		[toggleMenu, handleItemClick]
	);

	const renderMenuHandle = () => {
		switch (type) {
			case 'Image':
				return <Icon isActive type="secondary" size="small" src={src} />;
			case 'String':
				return <Heading type="secondary"> {handleName} </Heading>;
			case 'Button':
				return <> </>;
			case '3Dots':
				return <Icon isActive type="secondary" faAlias="faEllipsisH" />;
			case '3DotsButton':
				return (
					<Button type="secondary" size="tiny">
						<Icon isActive type="secondary" size="small" faAlias="faEllipsisH" />
					</Button>
				);
			default:
				return <> </>;
		}
	};

	useOutsideAlerter(wrapperRef, () => {
		toggleMenu(false);
	});

	const handleItemSelect = (option: Option) => {
		handleMenuItemSelect(option);
	};
	const isNewUpload = useSelector(isNewUploadEnabled);
	const updatedOptions = isNewUpload ?  options.filter(o => {
		return o.value !== "UPLOAD_DATA"
	}) : options

	return (
		<div className={classes} ref={wrapperRef}>
			<div onClick={showMenuItems}>{renderMenuHandle()}</div>
			{openMenu && (
				<Menu>
					{_.map(updatedOptions, (option: Option) => {
						return (
							<MenuItem
								onSelectItem={handleItemSelect}
								key={uniqueId()}
								item={option}
								disabled={isDisabled(option)}
							/>
						);
					})}
				</Menu>
			)}
		</div>
	);
};

export default ThetaMenu;
