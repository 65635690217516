// @ts-nocheck
import Redducer from './reducer';
import Sagas from './sagas';
export default [
  {
    path: '/',
    key: 'key',
    componentId: 'resetModel',
    // component: () => <Home title="asd" />,
    routes: [],
    // importReducer: () => {
    //   return [ResetReducer];
    // },
    importSagas: () => {
      return [Sagas];
    },
    // title: 'Skulicity | Models',
  },
];
