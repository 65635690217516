// @ts-nocheck
import React from 'react';
import Intelligence from './intelligence';
import { isIntelligenceRead } from 'models/user/selector';
export default [
	{
		path: '/intelligence',
		key: 'key',
		componentId: 'intelligence',
		component: () => <Intelligence />,
		routes: [],
		importReducer: () => [],
		importSagas: () => [],
		title: 'Intelligence',
		isEnabled: (state) => {
			return isIntelligenceRead(state)
		},
		headerName: 'Intelligence'
	}
];
