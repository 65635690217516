// @ts-nocheck
import { Modal } from 'antd';
import Button from 'atoms/button/button';
import Icon from 'atoms/icons/icon';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import { isNewVersionAvailable } from 'models/subscriptions/selector';
import { getWebAppSettings } from 'models/user/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import { useCallback } from 'react';
import { useLayoutEffect } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// import './userBar.scss';
// import { ReactComponent as UserSVG } from '../../atoms/miscSvg/user.svg';
// import { logOut, getUserBarData, checkLocal, getUserRefresh, getUserGraphData } from '../../API.js';
// import { parseJwt } from '../../Utilities.js';

export const ReleaseVersion = props => {
	const isNewVersion = useSelector(isNewVersionAvailable);
	const { ReleaseVersion, isBlocking } = useSelector(getWebAppSettings);
	const getLatestVersion = () => {
		localStorage.setItem('releaseVersion', ReleaseVersion);
		window.location.reload(true);
	};
	return (
		<Stack isVertical>
			<StackItem>
				{ReleaseVersion &&
					isNewVersion &&
					(isBlocking ? (
						<Modal centered visible closable={false} maskClosable={false} footer={null}>
							<Sheet>
								<SheetBody>
									<Stack isVertical gutter="medium" align="center" position="center">
										<StackItem>
											<Icon imgAlias="ThetaLogoDark" size="large" />
										</StackItem>
										<StackItem>A newer release version is available for this app,</StackItem>
										<StackItem>
											Please Click&nbsp;<a onClick={getLatestVersion}> here </a>&nbsp;to get the
											latest version.
										</StackItem>
									</Stack>
								</SheetBody>
							</Sheet>
						</Modal>
					) : (
						<Button type="transparent">
							A newer release version is available for this app, Please Click &nbsp;
							<a onClick={getLatestVersion}> here </a>&nbsp; to get the latest version.
						</Button>
					))}
			</StackItem>
			<StackItem isGrow>{isNewVersion && isBlocking ? null : props.children}</StackItem>
		</Stack>
	);
};
