
// @ts-nocheck
import Sheet from 'atoms/sheet/sheet';
import { fetchGridFiltersAsync } from 'models/dynamicGrid/sagaActions';
import { fetchTableColumnsAg } from 'models/tableColumns/sagaActions';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import { AgExternalSearchFilter } from 'organisms/searchFilter/agExternalSearch';
import * as React from 'react';
import { getDispatch } from 'reducers';
import { DEMAND_MATRIX_TABLE_ID } from './constants';
import Button from 'atoms/button/button';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import { createDemandMatrix } from './sagaActions';
import { useState } from 'react';
import Heading from 'atoms/heading/heading';
import Divider from 'atoms/divider/divider';
import SideCar from 'molecules/sidecar/sidecar';
import MultiSelectDropdown from 'organisms/multiSelect/multiSelect';
import FileUploader from 'molecules/fileUploader';
import GridCsvUpload from 'organisms/uploadData/gridCsvUpload';
import ReactModal from 'atoms/modal/reactModal';
import ModalBody from 'atoms/modal/modalBody';
import ModalHeader from 'atoms/modal/modalHeader';

const CreateDemandMatrix = props => {
	const dispatch = getDispatch();
	const tableId = DEMAND_MATRIX_TABLE_ID;
	const { templateConfig, handleClose, isEmptyMatrix } = props;
	const [filters, setFilters] = useState(null);
	React.useEffect(() => {
		dispatch(
			fetchGridFiltersAsync({
				tableId,
				enableCache: true
			})
		);
		dispatch(fetchTableColumnsAg(tableId, { forceUpdate: false }));
	}, [tableId]);

	const handleCreateMatrix = () => {
		dispatch(createDemandMatrix({ tableId, filters }));
		handleClose();
	};
	return (
		<ReactModal height={600} width={600}>
			<ModalHeader>
				<Stack>
					<StackItem>
						<Heading type="primary">{isEmptyMatrix ? 'Create' : 'Update'} Demand Matrix</Heading>
					</StackItem>
				</Stack>
			</ModalHeader>
			<ModalBody>
				<Sheet>
					<Stack isVertical gutter="medium">
						<>
							<StackItem isGrow>
								<AgExternalSearchFilter onChange={setFilters} tableId={tableId} />
							</StackItem>
							<StackItem position="end">
								<Stack gutter="small">
									<StackItem>
										{!isEmptyMatrix && (
											<Button isSecondary handleClick={handleClose}>
												Cancel
											</Button>
										)}
									</StackItem>
									<StackItem>
										<Button handleClick={handleCreateMatrix}>
											{isEmptyMatrix ? 'Create' : 'Update'} Demand Matrix
										</Button>
									</StackItem>
								</Stack>
							</StackItem>
						</>
					</Stack>
				</Sheet>
			</ModalBody>
		</ReactModal>
	);
};

export default CreateDemandMatrix;
