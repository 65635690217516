// @ts-nocheck
import _ from 'lodash';
import qs from 'qs';
import { getAPIDataV2, postAPIDataV3, postAPIData, putAPIData } from 'utils/api';
import { createFiltersFromQueries } from 'models/dynamicGrid/query';
import { REQUESTS } from 'utils/requests';
import { getWebAppSettings } from 'models/user/selector';
import { getState } from 'reducers';

var distNum = 0;


export const getSelectSkusData = async (tableId: number, orderIdList: any): Promise<any> => {
	const reqData = {
		OrderIDList: orderIdList.join(','),
		AppTableID: Number(tableId)
	};
	const userName = sessionStorage.getItem('userName') || 'None';
	const { response, isError } = await postAPIDataV3({ ...REQUESTS.POST.GET_DISTRIBUTE_SKUS, pathParams: { userName }, body: reqData })
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true })); //TODO:Confirm this

	distNum = response?.distNum;
	return { data: response?.resultSet?.Table1 || [], isError: isError };
};

export const getDistributeSkusData = async (selectedSkus: any): Promise<any> => {
	return { data: selectedSkus, isError: false };
};

export const getSelectStoresData = async (
	orderIds: any,
	ordersSearch: any,
	selectedOrders: any,
	tableId: any
): Promise<any> => {
	const reqData = {
		orderIds: orderIds,
		ordersSearch: JSON.stringify({
			filter: createFiltersFromQueries(ordersSearch.search)
		}),
		isSelectAllOrders: selectedOrders.isSelectAll,
		tableId: Number(tableId)
	};
	const userName = sessionStorage.getItem('userName') || 'None';

	const { response, isError } = await postAPIDataV3({...REQUESTS.POST.GET_SHIPMENT_STORES, pathParams:{userName}, body: reqData})
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true }));

	return { data: response?.data, isError: isError };
};

export const getDistNum = () => {
	return distNum;
};

export const getOptimizeSkusData = async (
	orderIds: any,
	ordersSearch: any,
	selectedOrders: any,
	tableId: any
): Promise<any> => {
	const isView = getWebAppSettings(getState())?.IsAclRowLevelAccessEnabled;

	const reqData = {
		orderIds: orderIds,
		ordersSearch: JSON.stringify({
			filter: createFiltersFromQueries(ordersSearch.search)
		}),
		isSelectAllOrders: selectedOrders.isSelectAll,
		tableId: Number(tableId),
   		 isView
	};
	const userName = sessionStorage.getItem('userName') || 'None';

	const { response, isError } = await postAPIDataV3({...REQUESTS.POST.GET_SHIPMENT_SKU, pathParams:{userName}, body: reqData})
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true })); //TODO:Confirm this

	return { data: response?.data, isError: isError };
};
