// @ts-nocheck

import { isMasterDataAdTypesReadWrite, isMasterDataCustomersReadWrite, isMasterDataGroupsReadWrite, isMasterDataInventoryTransactionsReadWrite, isMasterDataItemExclusionsReadWrite, isMasterDataItemShippingMethodConfigurationReadWrite, isMasterDataItemsReadWrite, isMasterDataKitsReadWrite, isMasterDataParametersReadWrite, isMasterDataPricingReadWrite, isMasterDataPrimaryStoreSkuReadWrite, isMasterDataSchedulesReadWrite, isMasterDataShipmentsReadWrite, isMasterDataShippingMethodsReadWrite, isMasterDataSkuExclusionsReadWrite, isMasterDataSkusReadWrite, isMasterDataStoreOrderListReadWrite, isMasterDataStoresReadWrite, isMasterDataValidSkuItemCombinationsReadWrite, isParametersReadWrite } from 'models/user/selector';
import { isMasterDataShippingMethodConfigurationRead } from '../../models/user/selector';
import { isMasterDataValidStoreSkuCombinationsReadWrite } from 'models/user/selector';
import { OPTION_IDENTIFIERS } from './constants';


export const getTileOperations = (state) => {
    return _.filter([
        {
            title: 'Ad Types',
            description: 'Create or edit types of Ads',
            buttonText: 'EDIT',
            itemIdentifier: OPTION_IDENTIFIERS.AD_TYPES,
            isEnabled: isMasterDataAdTypesReadWrite(state)
        },
        {
            title: 'Customers',
            description: 'View Customer list',
            buttonText: 'VIEW',
            isEnabled: isMasterDataCustomersReadWrite(state),

            itemIdentifier: OPTION_IDENTIFIERS.CUSTOMERS
        },
        {
            title: 'Groups',
            description: 'Create custom groups of Stores, SKUs, or Customers for use in order writing or parameter setting',
            buttonText: 'EDIT',
            itemIdentifier: OPTION_IDENTIFIERS.GROUPS,
            isEnabled: isMasterDataGroupsReadWrite(state),

        },
        {
            title: 'Inventory Transactions',
            description: 'View List of all the inventory transactions',
            buttonText: 'VIEW',
            itemIdentifier: OPTION_IDENTIFIERS.INVENTORY_TRANSACTIONS,
            isEnabled: isMasterDataInventoryTransactionsReadWrite(state),
        },
        {
            title: 'Item Exclusions',
            description: 'View list of invalid Store/Item combinations',
            buttonText: 'VIEW',
            isEnabled: isMasterDataItemExclusionsReadWrite(state),
            itemIdentifier: OPTION_IDENTIFIERS.ITEM_EXCLUSIONS
        },
        {
            title: 'Item Shipping Method Configuration',
            description: 'View Shipping Method setup by Item',
            buttonText: 'VIEW',
            isEnabled: isMasterDataItemShippingMethodConfigurationReadWrite(state),
            itemIdentifier: OPTION_IDENTIFIERS.ITEM_SHIPPING_METHOD_CONFIGURATION
        },
        {
            title: 'Items',
            description: 'View Item list',
            buttonText: 'VIEW',
            isEnabled: isMasterDataItemsReadWrite(state),
            itemIdentifier: OPTION_IDENTIFIERS.ITEMS
        },
        {
            title: 'Kits',
            description: 'View Kit Definition by SKU',
            isEnabled: isMasterDataKitsReadWrite(state),
            buttonText: 'VIEW',
            itemIdentifier: OPTION_IDENTIFIERS.KITS
        },
        {
            title: 'Parameters',
            description: 'View Parameter list',
            buttonText: 'VIEW',
            isEnabled: isMasterDataParametersReadWrite(state),
            itemIdentifier: OPTION_IDENTIFIERS.PARAMETERS
        },
        {
            title: 'Pricing',
            description: 'View current and historical SKU costs and retails',
            buttonText: 'VIEW',
            isEnabled: isMasterDataPricingReadWrite(state),
            itemIdentifier: OPTION_IDENTIFIERS.PRICING
        },
        {
            title: 'SKU Exclusions',
            description: 'View list of invalid Store/SKU combinations',
            buttonText: 'VIEW',
            isEnabled: isMasterDataSkuExclusionsReadWrite(state),
            itemIdentifier: OPTION_IDENTIFIERS.SKU_EXCLUSIONS
        },
        {
            title: 'Primary Store SKU',
            description: 'View Primary Store SKU',
            buttonText: 'VIEW',
            isEnabled: isMasterDataPrimaryStoreSkuReadWrite(state),
            itemIdentifier: OPTION_IDENTIFIERS.PRIMARY_STORE_SKU
        },
        {
            title: 'SKUs',
            description: 'View SKU list',
            isEnabled: isMasterDataSkusReadWrite(state),
            buttonText: 'VIEW',
            itemIdentifier: OPTION_IDENTIFIERS.SKUS
        },
        {
            title: 'Schedules',
            description: 'Add/View store schedules',
            isEnabled: isMasterDataSchedulesReadWrite(state),
            buttonText: 'VIEW',
            itemIdentifier: OPTION_IDENTIFIERS.SCHEDULES
        },
        {
            title: 'Shipments',
            description: 'View List of Retail Shipments',
            buttonText: 'VIEW',
            isEnabled: isMasterDataShipmentsReadWrite(state),
            itemIdentifier: OPTION_IDENTIFIERS.SHIPMENTS
        },
        {
            title: 'Shipping Methods',
            description: 'View list of Shipping Methods',
            buttonText: 'VIEW',
            isEnabled: isMasterDataShippingMethodsReadWrite(state),
            itemIdentifier: OPTION_IDENTIFIERS.SHIPPING_METHOD
        },
        {
            title: 'Store Order List',
            description: 'View Store Order List',
            buttonText: 'VIEW',
            isEnabled: isMasterDataStoreOrderListReadWrite(state),
            itemIdentifier: OPTION_IDENTIFIERS.STORE_ORDERS_LIST
        },
        {
            title: 'Stores',
            description: 'View Store list',
            buttonText: 'VIEW',
            isEnabled: isMasterDataStoresReadWrite(state),
            itemIdentifier: OPTION_IDENTIFIERS.STORES
        },
        {
            title: 'Valid SKU/Item Combinations',
            description: 'View valid SKU/Item combinations',
            isEnabled: isMasterDataValidSkuItemCombinationsReadWrite(state),
            buttonText: 'VIEW',
            itemIdentifier: OPTION_IDENTIFIERS.VALID_SKU_COMBINATIONS
        },
        {
            title: 'Valid Store/SKU Combinations',
            description: 'View master configuration of valid Store/SKU combinations',
            buttonText: 'VIEW',
            isEnabled: isMasterDataValidStoreSkuCombinationsReadWrite(state),
            itemIdentifier: OPTION_IDENTIFIERS.VALID_STORE_COMBINATIONS
        },
        {
            title: 'Warehouses',
            description: 'View Warehouses list',
            buttonText: 'VIEW',
            isEnabled: true,
            itemIdentifier: OPTION_IDENTIFIERS.WAREHOUSES
        },
    ], f => f.isEnabled);
}