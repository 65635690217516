// @ts-nocheck
import './advanced.scss';

import DateWrapper from 'atoms/datePicker/datePicker';
import DropDown from 'atoms/dropdown/dropdown';
import Loader from 'atoms/loader';
import TextInput from 'atoms/TextInput/textInput';
import _ from 'lodash';
import { getGridFilters } from 'models/dynamicGrid/selector';
import { isLoadingEnabled } from 'models/loaders/selectors';
import { getTableColumns } from 'models/tableColumns/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import MultiSelectDropdown from 'organisms/multiSelect/multiSelect';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDispatch } from 'reducers';
import { formatDate } from 'utils/utilities';
import { v4 as uuidv4 } from 'uuid';

import * as query from './query';
import * as sagaActions from './sagaActions';
import * as types from './type';
import { REQUESTS } from 'utils/requests';

type TProps = {
	parameters: types.TSearchParameter[];
	searchFilters: types.TSearchQuery[];
	tableId?: number;
	isServerHandled: boolean;
	allowOperator?: boolean;
	operator?: string;
	updateOperator?: (checked: boolean, event: MouseEvent) => void;
};

const defaultProps: Partial<TProps> = {
	parameters: [],
	searchFilters: [],
	isServerHandled: false,
	allowOperator: false,
	operator: 'AND',
	updateOperator: (checked: boolean, event: MouseEvent) => {}
};

export const AgExternalSearchFilterOption = ({
	currentQuery,
	columnData,
	searchQueries,
	tableId,
	onUpdateQueries = () => null,
	selectionProviderOptions,
	isTagFilter = false
}) => {
	const dispatch = getDispatch();
	const selectedCriteria = query.getSearchParameters(columnData ?? {});
	const [isTagFilterOpen, setTagFilterOpen] = useState(false);
	const [filterOption, updateFilterOption] = useState(
		currentQuery?.operator || query.getCriteriaOperators(selectedCriteria ?? {})[0]
	);
	const [currentSearchInput, updateCurrentSearchInput] = useState(currentQuery?.query?.from || currentQuery?.query);
	const [currentSearchInputEnd, updateCurrentSearchInputEnd] = useState(currentQuery?.query?.to);

	useEffect(() => {
		updateCurrentSearchInput(currentQuery?.query?.from || currentQuery?.query);
		updateCurrentSearchInputEnd(currentQuery?.query?.to);
		updateFilterOption(currentQuery?.operator || query.getCriteriaOperators(selectedCriteria ?? {})[0]);
	}, [currentQuery]);

	if (!columnData) {
		return null;
	}

	const dropdownOptions = query.getCriteriaOperators(selectedCriteria);
	const isInputVisible = !_.some(['empty', 'not.empty', 'is.true', 'is.false'], c => c === filterOption.value);

	const onUpdateInput = (e, type) => {
		if (type === 'date') {
			updateCurrentSearchInput(formatDate(e));
			return;
		}
		updateCurrentSearchInput(e.target.value);

		updateQuery({
			selectedCriteria,
			searchInput: e.target.value,
			searchInputEnd: currentSearchInputEnd,
			selectedOperator: filterOption,
			tableId,
			id: currentQuery?.id,
			isUpdate: currentQuery?.id
		});
	};
	const isOperatorBtwn = filterOption.value === 'btw' || filterOption.value === 'nbtw';
	const isOperatorIn = filterOption.value === 'in' || filterOption.value === 'not.in' || filterOption.value === 'bulk.in';

	const removeQuery = () => {
		let queries = searchQueries;
		let updated = _.filter(queries, q => q.id !== currentQuery.id);
		dispatch(
			sagaActions.removeQuery({
				tableId,
				query: currentQuery,
			})
		);
		if (onUpdateQueries) {
			onUpdateQueries(updated);
		}
	};
	const updateQuery = params => {
		let updated = [];
		const { id, selectedCriteria, searchInput, searchInputEnd, selectedOperator, isUpdate } = params;
		if (id && searchQueries?.length) {
			updated = _.map(searchQueries, q => {
				if (q.id === id) {
					return {
						id,
						criteria: selectedCriteria,
						query:
							selectedOperator && (selectedOperator.value === 'btw' || selectedOperator.value === 'nbtw')
								? { from: searchInput, to: searchInputEnd }
								: searchInput,
						operator: selectedOperator
					};
				}
				return q;
			});
		} else {
		
			updated = [
				...(searchQueries ?? []),
				{
					id: id || uuidv4(),
					criteria: selectedCriteria,
					query:
						selectedOperator && (selectedOperator.value === 'btw' || selectedOperator.value === 'nbtw')
							? { from: searchInput, to: searchInputEnd }
							: searchInput,
					operator: selectedOperator
				}
			];
		}
		if (onUpdateQueries) {
			onUpdateQueries(_.filter(updated, val => val.id));
		}
		
	};
	const handleSubmit = (e, isClick, options) => {
		if ((!currentSearchInput?.trim() || (isOperatorBtwn && !currentSearchInputEnd?.trim())) && isInputVisible) {
			return;
		}
		updateQuery({
			selectedCriteria,
			searchInput: (options?.currentSearchInput || currentSearchInput)?.trim(),
			searchInputEnd: (options?.currentSearchInputEnd || currentSearchInputEnd)?.trim(),
			selectedOperator: options?.filterOption || filterOption,
			tableId,
			id: currentQuery?.id,
			isUpdate: currentQuery?.id
		});
	};

	const onUpdateModel = ({ dateEvent, filter, isRange }) => {
		if (filter) {
			updateFilterOption(filter);
			if (
				(filter.value === 'btw' || filter.value === 'nbtw') &&
				(!currentSearchInput || !currentSearchInputEnd)
			) {
				return;
			}
			handleSubmit(null, true, {
				filterOption: filter
			});
			return;
		}
		if (dateEvent) {
			const dateValue = dateEvent.target.value;
			if (_.isArray(dateValue)) {
				updateCurrentSearchInput(formatDate(dateValue[0]));
				updateCurrentSearchInputEnd(formatDate(dateValue[1]));
			} else {
				updateCurrentSearchInput(formatDate(dateValue));
			}
			handleSubmit(null, true, {
				currentSearchInput: _.isArray(dateValue) ? formatDate(dateValue[0]) : formatDate(dateValue),
				currentSearchInputEnd: _.isArray(dateValue) ? formatDate(dateValue[1]) : ''
			});
		}
	};

	return (
		<Stack gutter="medium">
			<StackItem isStretch isGrow>
				<Stack gutter="small" divisions={3}>
					<StackItem span={1}>
						<DropDown
							key={'options'}
							selectedOption={filterOption}
							options={dropdownOptions}
							updateHandler={e => {
								onUpdateModel({ filter: e });
							}}
						/>
					</StackItem>
					{
						isOperatorBtwn &&
						isInputVisible && (
							<StackItem position="center" span={1}>
								<TextInput
									value={currentSearchInputEnd}
									type={selectedCriteria.type}
									onChange={e => updateCurrentSearchInputEnd(e.target.value)}
								></TextInput>
							</StackItem>
						)}
					{selectedCriteria.type === 'date' && (
						<StackItem position="center" span={1}>
							<DateWrapper
								selectedDate={
									isOperatorBtwn ? [currentSearchInput, currentSearchInputEnd] : currentSearchInput
								}
								allowEmpty
								handleClick={e => onUpdateModel({ dateEvent: e })}
								isRange={isOperatorBtwn}
							/>
						</StackItem>
					)}
					{selectedCriteria.type !== 'date' && filterOption.value !== 'in' && isInputVisible && (
						<StackItem position="center" span={1}>
							<Stack gutter="small">
								<StackItem isStretch isGrow>
									<MultiSelectDropdown
										componentId={'select-' + columnData.AppColumnName}
										key={'select-' + columnData.AppColumnName}
										onChange={e => {
											onUpdateInput(e);
											handleSubmit(null, true, {
												currentSearchInput: e.target.value
											});
										}}
										isSingleSelect
										selection={currentSearchInput}
										selectionProviderOptions={selectionProviderOptions}
										columnName={columnData.AppColumnName}
									/>
								</StackItem>
							</Stack>
						</StackItem>
					)}
				
					{filterOption.value === 'in' && (
						<StackItem span={2}>
							<MultiSelectDropdown
								componentId={'select-' + columnData.AppColumnName}
								key={'select-' + columnData.AppColumnName}
								onChange={onUpdateInput}
								selection={currentSearchInput}
								selectionProviderOptions={selectionProviderOptions}
								columnName={columnData.AppColumnName}
							/>
						</StackItem>
					)}

				
				</Stack>
			</StackItem>
			
		</Stack>
	);
};

export const AgExternalSearchFilter = props => {
	console.log({props})
	const { tableId, onChange } = props;
	const columns = useSelector(state => getTableColumns(state, tableId));
	const selectionProviderOptions = useMemo(
		() => ({
			type: 'POST',
			version: 2,
			...REQUESTS.POST.GET_MULTI_SELECT_OPTIONS,
			pathParams: {tableId},
			tableId,
			body: {}
		}),
		[tableId]
	);
	let searchQueriesDefault = useSelector(state => getGridFilters(state, tableId));
	const gridSearchQueries = useSelector(state => getGridFilters(state, tableId));
	const [isInitialized, upddateIsInitialized] = useState(false);

	const [searchQueries, updateSearchQueriesValue] = useState([]);
	const isLoadingFilters = useSelector(state => isLoadingEnabled(state, 'fetch_grid_filters/' + tableId));

	const updateSearchQueries = searchQueries => {
		updateSearchQueriesValue(searchQueries);
		onChange(searchQueries);
	};
	useEffect(() => {
		if (!searchQueries.length) {
			updateSearchQueriesValue(searchQueriesDefault?.search || []);
			onChange(searchQueriesDefault?.search);
		}
	}, [searchQueries, searchQueriesDefault]);
	
	let queries = searchQueries?.length ? [...searchQueries]: [{}];
	let filterAllowedColumns = _.filter(columns, col => col.IsFilterEnabled);
	return (
		<Stack gutter="small" isVertical>
			<StackItem isStretch isGrow>
				<Stack isVertical gutter="small" align="left">
					{isLoadingFilters ? (
						<StackItem isGrow isStretch>
							<Loader></Loader>
						</StackItem>
					) : (
						_.map(filterAllowedColumns, (column, index) => {
							const currentQuery = queries?.find(q => q?.criteria?.rowIdentifier === column.AppColumnName);
							return (
								<StackItem>
									<Stack isVertical gutter="small">
										<StackItem>{column.AppColumnAliasName || column.AppColumn}</StackItem>
										<StackItem>
											<AgExternalSearchFilterOption
												key={column?.AppColumn}
												columnData={column}
												currentQuery={currentQuery}
												searchQueries={queries}
												tableId={tableId}
												selectionProviderOptions={selectionProviderOptions}
												onUpdateQueries={params => {
													updateSearchQueries(params);
												}}
											/>
										</StackItem>
									</Stack>
								</StackItem>
							);
						})
					)}
				</Stack>
			</StackItem>
		</Stack>
	);
};
