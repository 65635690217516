// @ts-nocheck
import '../advanced.scss';

import Tag from 'atoms/tag/tag';
import _ from 'lodash';
import { getGridFiltersByColumn } from 'models/dynamicGrid/selector';
import { GRID_EVENTS } from 'molecules/ReactGrid/agGrid/constants';
import { Events } from 'molecules/ReactGrid/agGrid/utils/events';
import React, { useEffect, useState } from 'react';
import { getDispatch, getState } from 'reducers';

import * as query from '../query';
import * as sagaActions from '../sagaActions';


export const FilterTags = props => {
	const { currentQuery, currentColumnRef, tableId, onUpdateQueries = () => null, onToggleTagFilter} = props;
	const selectedCriteria = query.getSearchParameters(currentColumnRef?.colDef?.metaData ?? {});
	

	const dispatch = getDispatch();


	const removeQuery = () => {
		let queries = getGridFiltersByColumn(getState(), tableId, currentQuery?.criteria?.rowIdentifier);
		let updated = _.filter(queries, q => q.id !== currentQuery.id);
		Events.publish(GRID_EVENTS.CLEAR_SELECTION, {
			tableId,
		});
		dispatch(
			sagaActions.removeQuery({
				tableId,
				query: currentQuery,
			})
		);
		if (onUpdateQueries) {
			onUpdateQueries(updated);
		}
	};
	if (!currentColumnRef) {
		return null;
	}
	return (
			currentQuery && (
				<Tag onClick={() => onToggleTagFilter(true)} closable onClose={removeQuery} tooltipText={`${currentQuery?.criteria?.displayName} ${currentQuery?.operator?.displayName} ${_.isObject(currentQuery?.query)
						? `${currentQuery?.query?.from} And ${currentQuery?.query?.to}`
						: currentQuery?.query ?? ''
					}`}>
					{_.truncate(
						`${currentQuery?.criteria?.displayName} ${currentQuery?.operator?.displayName} ${_.isObject(currentQuery?.query)
							? `${currentQuery?.query?.from} And ${currentQuery?.query?.to}`
							: currentQuery?.query ?? ''
						}`,
						20
					)}
				</Tag>
			)
		);
};

