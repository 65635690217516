// @ts-nocheck
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Spin, Input, Dropdown, Checkbox, Radio } from 'antd';
import { loadMultiSelectOptions } from './sagaActions';
import { useDispatch, useSelector } from 'react-redux';
import { getMultiSelectOptions } from './selectors';
import './multiSelect.scss';
import Card from 'atoms/card/card';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import StackItem from 'molecules/stackItem/stackItem';
import Stack from 'molecules/stack/stack';
import { isLoading } from '../groupModal/selector';
import { isLoadingEnabled } from 'models/loaders/selectors';
import Button from 'atoms/button/button';
import BodyText from 'atoms/bodyText/bodyText';
import Heading from 'atoms/heading/heading';
import Label from 'atoms/label/label';
import Link from 'atoms/link/link';
import SheetAction from 'atoms/sheet/sheetAction/sheetAction';
import Divider from 'atoms/divider/divider';
import TextArea from 'atoms/TextArea/textArea';

const { Search } = Input;
const PAGE_SIZE = 20;

const TextAreaComponent = ({ selection, updateSelected, componentId, onClose }) => {
	const [value, updateValue] = React.useState(selection);
	const selectionLabels = selection?.split(/,(?![ ])/) ?? [];

	const updatedValues = _.compact(value?.split('\n'));
	const isSelectionEqual = selection === updatedValues?.join(',');
	const eventHandler = useCallback((event) => {
		// if(document.querySelectorAll)
		console.log(123, selection, value, isSelectionEqual)
		if (!isSelectionEqual) {
			updateValue(selection);
		}
	}, [isSelectionEqual])
	useEffect(() => {

		if (!isSelectionEqual) {
			document.getElementById('root').addEventListener('click', eventHandler);
		}
		else {
			document.getElementById('root').removeEventListener('click', eventHandler);
		}
		// Clean up the event listener when the component unmounts
		return () => {
			document.getElementById('root').removeEventListener('click', eventHandler);
		};
	}, [isSelectionEqual, eventHandler])

	const handleApply = () => {
		updateSelected(_.uniq(updatedValues).map(item => ({ label: item.trim(), value: item.trim() })))
	}
	return <Card key={componentId} height={220} width={230} maxWidth={300} className="multiselect">
		<Sheet>
			<SheetBody>
				<Stack isVertical gutter="small">
					<StackItem>
						<TextArea onChange={e => {
							console.log(_.compact(e.target.value.split('\n')))
							updateValue(e.target.value);
						}} rows={9} placeholder={"Type or Paste text here."} value={value}></TextArea>
					</StackItem>
					<StackItem align="right">
						<Stack align='right' gutter="small">

							<StackItem align="right">
								<Button handleClick={() => handleApply()}>Apply</Button>
							</StackItem>
						</Stack>

					</StackItem>
				</Stack>

			</SheetBody>
		</Sheet>
	</Card>

}


const LazyLoadedComponent = ({
	onSelectValues,
	onClose,
	componentId,
	selectionProviderOptions,
	columnName,
	size,
	columnLabelName,
	selection,
	isInline,
	type,
	isSingleSelect
}) => {
	// const [options, setData] = useState([]);
	const [selected, updateSelected] = useState([])
	const [loading, setLoading] = useState(false);
	const [isSelectAll, setIsSelectAll] = useState(false);
	const [pageNo, setPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState('');
	const dispatch = useDispatch();
	const selectionLabels = selection?.split(/,(?![ ])/) ?? [];
	let updatedSelection = useMemo(() => selectionLabels.map(l => ({
		label: l,
		value: l,
		checked: true
	})), [selectionLabels]);
	const isLoading = useSelector(state => isLoadingEnabled(state, 'multiselect/loadOptions/' + componentId));
	const isSelectionEqual = updatedSelection.map(l => l.label).sort().join(',') === selected.map(l => l.label).sort().join(',');
	const eventHandler = useCallback((event) => {

		// if(document.querySelectorAll)
		let excludedClassName = 'multiselect'; // Replace with your specific classname
		if (isInline) {
			excludedClassName = "filter-bar-section";
		}

		if (!event.target.closest('.' + excludedClassName) && !isSelectionEqual) {
			updateSelected(updatedSelection);
		}
	}, [updatedSelection, isSelectionEqual, isInline])
	useEffect(() => {

		if (!isSelectionEqual) {
			document.getElementById('root').addEventListener('click', eventHandler);
		}
		else {
			document.getElementById('root').removeEventListener('click', eventHandler);
		}
		// Clean up the event listener when the component unmounts
		return () => {
			document.getElementById('root').removeEventListener('click', eventHandler);
		};
	}, [isSelectionEqual, eventHandler])
	const {
		options = [],
		selectedValues = []
	} = useSelector(state => getMultiSelectOptions(state, componentId));

	useEffect(() => {

		updateSelected(
			isSingleSelect ? updatedSelection.slice(0, 1) : updatedSelection
		)

	}, [selection]);

	const handleScroll = event => {
		const target = event.target;
		if (isLoading) {
			return;
		}
		if (target.scrollHeight - target.scrollTop <= target.clientHeight + 100 && options?.[0]?.total > options.length) {
			loadMore({ pageNo: pageNo + 1 });
			setPage(pageNo + 1);
		}
	};

	const handleSearch = value => {
		setSearchTerm(value);
		setPage(1);
		dispatch(
			loadMultiSelectOptions({
				page: 1,
				pageSize: PAGE_SIZE,
				componentId,
				isAppend: false,
				selectionProviderOptions,
				columnName,
				columnLabelName,
				search: value
			})
		);
	};

	const loadMore = async opt => {
		if (!loading) {
			dispatch(
				loadMultiSelectOptions({
					page: opt?.pageNo || pageNo,
					pageSize: PAGE_SIZE,
					componentId,
					isAppend: (opt?.pageNo ?? pageNo) > 1,
					selectionProviderOptions,
					columnName,
					columnLabelName,
					search: searchTerm
				})
			);
		}
	};

	// useEffect(() => {
	// 	if (!options.length) {
	// 		loadMore();
	// 	}
	// }, [componentId, dispatch]);

	useEffect(() => {
		loadMore();
	}, [selectionProviderOptions])

	const handleApply = () => {
		onSelectValues(selected, { isSelectAll, selectAllSearch: searchTerm });
	};
	const handleSelection = (value, checked) => {
		if (isSingleSelect) {
			updateSelected(
				options?.map(item => {
					if (item.value === value) {
						return { ...item, checked };
					}
					return { ...item, checked: false };
				}).filter(f => f.checked)
			)
			return;
		}
		updateSelected(
			selected.find(f => f.value === value) ?
				selected.filter(item => item.value !== value) : [...selected, { value, label: value, checked: true }]
		)
	}

	const handleSelectAll = checked => {
		if (!checked) {
			updateSelected([]);
		} else {
			updateSelected(
				options.map(item => {
					return { ...item, checked }
				})
			)
		}
		setIsSelectAll(checked)
	};

	const selectedData = useMemo(() => {
		return options.map(d => _.find(selected, n => n.value == d.value) ? _.find(selected, n => n.value == d.value) : d)
	}, [options, selected, componentId]);

	return (
		<Card key={componentId} height={size == "small" ? 220 : 270} width={230} maxWidth={300} className="multiselect">
			<Sheet>
				<SheetBody>
					<Stack isVertical gutter="small">
						<StackItem isStretch gutter="small">
							<Stack className="search" gutter="small">
								<StackItem isStretch isGrow>
									<Search placeholder="Search" onSearch={handleSearch} enterButton />
								</StackItem>
							</Stack>
						</StackItem>
						<StackItem isGrow>
							<Stack isVertical gutter="tiny">
								{!isSingleSelect && Boolean(options?.length) && !isLoading && (
									<StackItem>
										<Stack gutter="small">
											<StackItem position="center">
												<Checkbox
													indeterminate={
														isSelectAll ||
														(_.filter(selectedData, { checked: true })?.length &&
															_.filter(selectedData, { checked: true })?.length !== options?.length)
													}
													size="small"
													onChange={e => handleSelectAll(e.target.checked)}
													checked={_.every(selectedData, item => item.checked)}
												/>
											</StackItem>
											<StackItem position="center">
												<Label>(Select All)</Label>
											</StackItem>
										</Stack>
									</StackItem>
								)}
								<StackItem>
									<div
										className="scroll"
										style={{
											height: (size == "small" ? 130 : isSingleSelect ? '180px' : '160px'),
											overflowY: 'auto',
											scrollbarWidth: '1px',
											width: '100%'
										}}
										onScroll={_.debounce(handleScroll, 500)}
									>
										<Stack isVertical gutter="tiny" >
											{pageNo === 1 && isLoading
												? null
												: selectedData?.map((item, index) => (

													<>
														<StackItem>
															<Stack isVertical gutter="tiny">

																<StackItem>
																	<Stack gutter="small">
																		<StackItem>
																			{!isSingleSelect && (
																				<Checkbox
																					size="small"
																					onChange={e =>
																						handleSelection(
																							item.value,
																							e.target.checked
																						)
																					}
																					checked={item.checked}
																				/>
																			)}

																			{isSingleSelect && (
																				<Radio
																					type="radio"
																					name="option"
																					// key={item.value + item.checked}
																					checked={item.checked}

																					onClick={e => {
																						handleSelection(item.value, true)
																					}
																					}
																					style={{ marginRight: 8 }}
																				/>
																			)}
																		</StackItem>
																		<StackItem>
																			{/* <div style={{ flex: 1, textAlign: 'left', fontSize: '0.9rem' }}> */}

																			<Label>{item.label}</Label>
																			{/* </div> */}
																		</StackItem>
																	</Stack>
																</StackItem>

															</Stack>
														</StackItem>
														<StackItem isStretch>
															<Divider size="tiny"></Divider>
														</StackItem>
													</>
												))}

											{isLoading && (
												<StackItem isStretch align="center">
													<Spin style={{ display: 'block', marginTop: 16 }} />
												</StackItem>
											)}
										</Stack>
									</div>
								</StackItem>
							</Stack>
						</StackItem>
						<StackItem>
							<Stack align="right" gutter="small">
								{(!isInline) && <StackItem>
									<Button type="secondary" handleClick={() => {
										updateSelected(updatedSelection);
										onClose()
									}}>
										Cancel
									</Button>
								</StackItem>}
								<StackItem>
									<Button disabled={!selected?.length} handleClick={handleApply}>Apply</Button>
								</StackItem>
							</Stack>
						</StackItem>
					</Stack>
				</SheetBody>
			</Sheet>
		</Card>
	);
};

const MultiSelect = ({ selectionProviderOptions, columnName, size, columnLabelName, componentId, onChange, selection, isSingleSelect, isInline, hasInputToggle, type }) => {
	const [selectedValues, onSelectValues] = useState([]);
	const [isDropdown, toggleDropdown] = useState(false);
	const isTextArea = type === 'textarea';

	const handleOnChange = (selectedValues, selectAllOptions) => {
		onSelectValues(selectedValues);
		onChange({ target: { value: _.map(selectedValues, s => s.label).join(',') } }, selectAllOptions);
		toggleDropdown(false);
	};
	useEffect(() => {
		if (!selection) {
			onSelectValues([]);
		}
		if (isSingleSelect && selection?.split(',')?.length > 1) {
			onChange({ target: { value: selection?.split(',')?.[0] } });
		}
	}, [selection]);

	if (!isInline) {
		console.log(selection)
	}
	const layout = useMemo(() => isTextArea ? <TextAreaComponent componentId={componentId} onClose={() => {
		toggleDropdown(false)
	}} updateSelected={handleOnChange} selection={selection} /> :
		<LazyLoadedComponent
			selectionProviderOptions={selectionProviderOptions}
			onSelectValues={handleOnChange}
			onClose={() => {
				toggleDropdown(false)
			}}
			columnName={columnName}
			size={size}
			columnLabelName={columnLabelName}
			isInline={isInline}
			componentId={componentId}
			selection={selection}
			type={type}
			isSingleSelect={isSingleSelect}
		/>, [selectionProviderOptions, isTextArea, columnName, columnLabelName, componentId, selection, isSingleSelect, isInline, size, handleOnChange])


	return (
		<Stack isVertical>
			{isInline && hasInputToggle && <StackItem> <button
				style={{
					width: '100%',
					maxWidth: '250px',
					minHeight: '2rem',
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					fontSize: '0.9rem',
					minWidth: '14rem',
					textOverflow: 'ellipsis',
					textAlign: 'left'
				}}
				onClick={() => {
					toggleDropdown(!isDropdown);
				}}
			>
				{selection || _.map(selectedValues, s => s.label)}
			</button></StackItem>}
			{isInline ? <StackItem>{layout}</StackItem> :
				<StackItem><div className="multiselect">
					<Dropdown
						trigger={['click']}
						onOpenChange={isOpen => {
							toggleDropdown(isOpen);
						}}
						open={isDropdown}
						overlay={layout}
					>
						<button
							style={{
								width: '100%',
								width: '14rem',
								// maxWidth: '200px',
								minHeight: '2rem',
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								fontSize: '0.9rem',
								textOverflow: 'ellipsis',
								textAlign: 'left'
							}}
							onClick={() => {
								toggleDropdown(!isDropdown);
							}}
						>
							{selection || _.map(selectedValues, s => s.label)}
						</button>
						{/* {isDropdown && overlay} */}
					</Dropdown>
				</div>
				</StackItem>
			}
		</Stack>

	);
};

export default MultiSelect;
