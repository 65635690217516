// @ts-nocheck
import { takeEvery, select, put } from 'redux-saga/effects';
import { putAPIDataV2 } from 'utils/api';
import * as sagaActions from './sagaActions';
import * as selectors from './selectors';
import _ from 'lodash';
import { getItemFromLocalStorage, setItemInLocalStorage } from 'utils/storage';
import * as slice from './reducer';

function* handleUpdatePagination({ payload }) {
	let { tableId, pageNo, perPage, sortDirection, sortField } = payload;

	yield put(
		slice.createUpdatePagination({
			tableId,
			pageNo,
			perPage,
			sortDirection,
			sortField
		})
	);
}
export { handleUpdatePagination };

export default function* rootSaga() {
	return [
		// yield takeEvery(sagaActions.getPaginationQuery, handleGetPaginationQuery),
		yield takeEvery(sagaActions.updatePagination, handleUpdatePagination)
	];
}
