// @ts-nocheck
import { data } from 'pages/test/data';
import { createAction, createSlice, Action, AnyAction } from '@reduxjs/toolkit';
import * as sagaActions from './sagaActions';
import * as constants from './constants';
import update from 'react-addons-update';
import _ from 'lodash';

const queryFilters = {
	searchQueries: [],
	statusFilters: []
};

export const initialState = {
	0: { hasMore: false, data: [], parametersList: [] },
	1: { hasMore: false, data: [], parametersList: [] },
	2: { hasMore: false, data: [], parametersList: [] },
	3: { hasMore: false, data: [], parametersList: [] },
	4: { hasMore: false, data: [], parametersList: [] },
	5: { hasMore: false, data: [], parametersList: [] },
	isLoading: {},
	details: {}
};

interface RejectedAction extends Action {
	error: Error;
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
	return action.type.endsWith('rejected');
}
const slice = createSlice({
	name: 'parameters',
	initialState,
	reducers: {
		updateLoader(state, { payload: { eventName, isLoading } }) {
			return {
				...state,
				isLoading: {
					...state.isLoading,
					[eventName]: isLoading,
				},
			};
		},
		updateParameterGroups(state, { payload: { customers, selectedGroupId, parametersList, isAppend } }) {
			const updatedCustomers = isAppend ? [...state[selectedGroupId].data, ...customers] : [...customers];
			return {
				...state,
				[selectedGroupId]: {
					hasMore: updatedCustomers.length < customers?.[0]?.TotalRecordCount,
					data: updatedCustomers,
					parametersList
				}
			}
		},
		updateParameterDetails(state, { payload: { parameterMasterId, details } }) {
			return {
				...state,
				details: {
					...state.details,
					[parameterMasterId]: details
				}
			}
		},
		updateParameterGroupDetails(state, { payload: { parameterTypeId, updatedData, rowIndex } }) {

			return update(state, {
				[parameterTypeId]: {
					data: {
						[rowIndex]: {
							[_.keys(updatedData)[0]]: { $set: updatedData[_.keys(updatedData)[0]] }
						}
					},
				}
			})
		},
		updateParameterValue(state, { payload: { parameterMasterId, details } }) {
			return update(state, {
				details: {
					[parameterMasterId]: {
						$set: { ...state.details[parameterMasterId], ...details }
					},
				}
			})
		}
	}
});

export default slice;

export const {
	updateParameterValue,
	updateParameterGroups,
	updateParameterDetails,
	updateParameterGroupDetails,
	updateLoader
} = slice.actions;
