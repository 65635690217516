// @ts-nocheck
import { useMsal } from '@azure/msal-react';
import { Avatar, Dropdown, Layout, Menu } from 'antd';
import Button from 'atoms/button/button';
import Heading from 'atoms/heading/heading';
import Icon from 'atoms/icons/icon';
import Label from 'atoms/label/label';
import Modal from 'atoms/modal/modal';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import { loginRequest } from 'authConfig';
import _ from 'lodash';
import { isLoggedOut } from 'models/subscriptions/selector';
import { checkDbHealth, fetchUserData } from 'models/user/sagaActions';
import { getCurrentWorkspace, getUserInfo } from 'models/user/selector';
import { SignInButton, SignOutButton } from 'molecules/authentication/authentication';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import NotificationCenter from 'organisms/notifications/notificationCenter';
import SettingsDialog from 'organisms/settingsDialog/settingsDialog';
import { Pages } from 'pages';
import React from 'react';
import { useCallback } from 'react';
import { useLayoutEffect } from 'react';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { checkLocal, getUserBarData, getUserData, getUserRefresh, logOut, relogin } from 'utils/api';
import { getMsalInstance } from 'utils/msalInstance';
import { parseJwt } from 'utils/utilities';

// import './userBar.scss';
// import { ReactComponent as UserSVG } from '../../atoms/miscSvg/user.svg';
// import { logOut, getUserBarData, checkLocal, getUserRefresh, getUserGraphData } from '../../API.js';
// import { parseJwt } from '../../Utilities.js';

export const UserBar = ({ isAuthenticated }) => {
	const instance = getMsalInstance();
	const currentWorkspace = useSelector(getCurrentWorkspace);
	const userInfo = useSelector(state => getUserInfo(state));
	const dispatch = useDispatch();
	const isLoggedOutUser: any = useSelector(state => isLoggedOut(state));

	// React.useEffect(() => {
	// 	if (isAuthenticated && !isLoggedOutUser) {
	// 		dispatch(fetchUserData());
	// 	}
	// }, [dispatch, isAuthenticated, isLoggedOutUser]);

	// React.useEffect(() => {
	// 	if (isLoggedOutUser && window && window.location && window.location.reload) {
	// 		getUserRefresh();
	// 		// relogin();
	// 	}
	// }, [isLoggedOutUser]);

	useEffect(() => {
		window.addEventListener('focus', () => {
			// if (userInfo) {
			// dispatch(checkDbHealth());
			// }
		});

		return () => {
			window.removeEventListener('focus', () => { });
		};
	}, []);

	const logOutSession = (refresh = false) => {
		const msalInstance = getMsalInstance();
		msalInstance.logoutPopup({
			postLogoutRedirectUri: '/',
			mainWindowRedirectUri: '/' // redirects the top level app after logout
		});
		localStorage.clear();
	};
	const menu = (
		<Menu>
			<Menu.Item>
				<a
					onClick={() => {
						logOutSession();
					}}
				>
					Logout
				</a>
			</Menu.Item>
		</Menu>
	);

	return (
		<>
			<Stack gutter="medium">
				<StackItem isGrow isStretch>
					<Heading type="secondary">{currentWorkspace?.headerName}</Heading>
				</StackItem>
				{/* <StackItem isGrow position="center">
					<NotificationCenter></NotificationCenter>
				</StackItem> */}
				{isAuthenticated && userInfo?.userName && (
					<>
						<StackItem position="center">
							<SettingsDialog />
						</StackItem>
						<StackItem>
							<Dropdown overlay={menu} trigger='click'>
								<Layout style={{ background: 'transparent' }}>
									<Stack gutter="medium" position="center">
										<StackItem>
											<Avatar style={{ color: '#fff', backgroundColor: '#000000' }}>
												{userInfo?.userName?.[0]}
											</Avatar>
										</StackItem>

										<StackItem>
											<Label>{userInfo?.userName}</Label>
										</StackItem>
									</Stack>
								</Layout>
							</Dropdown>
						</StackItem>
					</>
				)}
			</Stack>
		</>
	);
};

export default UserBar;
