// @ts-nocheck
import Button from 'atoms/button/button';
import Loader from 'atoms/loader';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as groupModalSelectors from 'organisms/groupModal/selector';
import { TABS_IDENTIFIER } from 'pages/masterData/groups/constants';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import { REQUESTS } from 'utils/requests';

import { getTableId } from './constants';
import { Tprops } from './types';
import { getWebAppSettings } from 'models/user/selector';

const SelectMembers = (props: Tprops) => {
	const { hideModal, handleNextClick, goBack, memberType } = props;
	const name = 'selectedMembers';
	const isLoading = useSelector(state => groupModalSelectors.isLoading(state));

	const groupData: any = useSelector(state => groupModalSelectors.getGroupModalData(state)) || {};
	const selectedTab = memberType;
	const gridConfig = {
		enableSelection: true,
		enableSorting: true,
		selectionCallBack: () => {}
	};

	const selectedMembers = useSelector(state =>
		dynamicGridSelectors.getTableSelection(state, getTableId(groupData.GroupType))
	);

	const getSelectRowKey = () => {
		switch (selectedTab) {
			case TABS_IDENTIFIER.SKUS:
				return 'SKUID';
			case TABS_IDENTIFIER.CUSTOMERS:
				return 'CustomerID';
			case TABS_IDENTIFIER.STORES:
			default:
				return 'StoreID';
		}
	};

	const getPathParams = () => {
		switch (groupData.GroupType) {
			case TABS_IDENTIFIER.SKUS:
        return { tableId: 58 };
			case TABS_IDENTIFIER.CUSTOMERS:
				return { tableId: 59 };
			case TABS_IDENTIFIER.STORES:
				return { tableId: 57 };
		}
	};

	const extendedSearchFilters = useMemo(
		() => [
			{
				id: '1234',
				query: groupData.CustomerID,
				criteria: {
					displayName: 'Customer Number',
					value: 's.CustomerID',
					queryName: 's.CustomerID',
					rowIdentifier: 'CustomerNum',
					type: 'number',
					label: 'Customer Number'
				},
				operator: {
					value: 'eq',
					symbol: '=',
					displayName: 'Equals'
				},
				isLocked: true
			}
		],
		[groupData]
	);
	const isView = useSelector(state => getWebAppSettings(state)?.IsAclRowLevelAccessEnabled);


	const templateConfig = {
		gridConfig,
		isFilterEnabled: true,
		extendedSearchFilters,
   		IsViewimplementationoverride: isView,
		tableId: getTableId(groupData.GroupType),
		dataProviderOptions: {
			type: 'POST',
			version: 2,
			...REQUESTS.POST.GET_GRID_DETAILS_V2,
			pathParams: getPathParams(),
		},
		selectRowKey: getSelectRowKey()
	};

	const [ref, handleUpdateRef] = useState(null);

	const renderLoader = () => {
		return (
			<StackItem isGrow isStretch align="center">
				<Loader />
			</StackItem>
		);
	};

	return (
		<>
			<ModalBody onDomRef={handleUpdateRef}>
				<Stack isVertical gutter="medium">
					{isLoading ? (
						renderLoader()
					) : (
						<ThetaGridPaginated parent={ref} config={templateConfig} isStorageEnabled={false} />
					)}
				</Stack>
			</ModalBody>
			<ModalActions>
				<Stack gutter="medium">
					<StackItem isGrow isStretch>
						<Stack align="left" gutter="small">
							<StackItem>
								<Button disabled={isLoading} isSecondary name="RESET GROUP INFO" handleClick={goBack} />
							</StackItem>
						</Stack>
					</StackItem>
					<StackItem>
						<Stack align="right" gutter="small">
							<StackItem>
								<Button disabled={isLoading} isSecondary name="CANCEL" handleClick={hideModal} />
							</StackItem>
							<StackItem isStretch align="right">
								<Button
									disabled={!selectedMembers?.selectedRowKeys?.length || isLoading}
									name={'REVIEW GROUP'}
									handleClick={handleNextClick}
								/>
							</StackItem>
						</Stack>
					</StackItem>
				</Stack>
			</ModalActions>
		</>
	);
};

export default SelectMembers;
