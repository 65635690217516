// @ts-nocheck
import { initialState } from './reducer';
import * as constants from './constants';

const parametersSelector = state => state.parameters || initialState;

export const getSelectedGroupParameters = (state, selectedGroupId) => parametersSelector(state)[selectedGroupId]

export const getParamterDetails = (state, parameterMasterId) => parametersSelector(state).details[parameterMasterId]

export const isLoading = (state: any, eventName: string): boolean => {
	const data = parametersSelector(state);

	return data.isLoading[eventName];
};
// export const getActiveTableColumnDefinitions = (state, tableId) => {
// 	return orderSelector(state)[tableId];
// };

// export const getTableData = (state, selectedTab: string) => {
// 	return orderSelector(state)['orders'][selectedTab];
// };

// export const getSelectedOrders = (state, selectedTab: string) => {
// 	return orderSelector(state)['selectedOrders'][selectedTab];
// };
// // export const getPaginationFilters = (state, selectedTab: string) => {
// //   return orderSelector(state)['pagination'][selectedTab] || {};
// // };

// export const isHealthCheckActive = state => {
// 	return orderSelector(state).isHealthCheckEnabled;
// };

// export const getSelectedHealthCheckId = state => {
// 	return orderSelector(state).HealthCheckID;
// };

// export const getIsLoading = (state, tableId) =>
// 	orderSelector(state).isLoading[tableId];

// export const getQueryFilters = (state: any, tableId: string) => {
// 	return orderSelector(state)['queryFilters'][tableId];
// };
// // export const fetchDataByAvailabilityTableId = (state, tableId) => {
// // 	return tableId === constants.AVAILABILITY_TABLE_ID ? orderSelector(state).availabilityItems :
// // 		orderSelector(state).availabilitySummary
// // }
