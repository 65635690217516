// @ts-nocheck
import _ from 'lodash';
import { fetchGridData } from 'models/dynamicGrid/sagas';
import { createComponent } from 'models/tableColumns/gridComponents';
import { getColumnInfoByIndentifier, getTableColumns } from 'models/tableColumns/selector';
import { getUserSettings } from 'models/user/selector';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getDispatch, getState } from 'reducers';
import { getGridData, getGridMetadata, getTableSelection, getGridFiltersPivotMode } from 'models/dynamicGrid/selector';
import * as dynamicGridSagaActions from '../../../models/dynamicGrid/sagaActions';
import { Bookmarks } from './bookmarks/bookmarks';
import { PAGE_SIZE } from './constants';
import GridActionHandler from './gridActions';
import { selectAllHandler } from './handlers';
import SelectBoxAg from './SelectBoxAg';

const buildTree = (data, keys, parentNode, currentKeyIndex = 0, aggregationColumns, tableId) => {
	const currentKey = keys[currentKeyIndex];
	for (const value of data[currentKey]) {
		const node = {
			groupId: parentNode.groupId ? `${parentNode.groupId}|${value}` : value,
			headerName: value,
			children: []
		};
		if (currentKeyIndex === keys.length - 1) {
			// If this is the last key, create the aggregation column nodes
			for (const column of aggregationColumns) {
				let col = getColumnInfoByIndentifier(getState(), tableId, column);
				const columnNode = {
					colId: `${node.groupId}|${column}`,
					headerName: `${col.AppColumnAliasName || col.AppColumn}`,
					metaData: { ...col, tableId },
					cellRenderer: props => {
						return createComponent(col.ComponentName, {
							...props,

							rowIdentifier: col.AppColumnName,
							tableId,
							type: col.ColumnType,
							// defaultRowGroupColumn,
							isPivotViewEnabled: true,
							suppressMovable: true
						});
					},
					field: `${node.groupId}|${column}`,
					resizable: true
				};
				node.children.push(columnNode);
			}
		} else {
			// If this is not the last key, recursively build the tree
			buildTree(data, keys, node, currentKeyIndex + 1, aggregationColumns, tableId);
		}
		parentNode.children.push(node);
	}
};

export function createServerSideDatasource(server, gridRef, options) {
	let colDefHashExt;

	const createColsHash = colDefs => {
		if (!colDefs) {
			return null;
		}
		var parts = [];
		var that = this;
		colDefs.forEach(function (colDef) {
			if (colDef.children) {
				parts.push(colDef.groupId);
				parts.push('[' + createColsHash(colDef.children) + ']');
			} else {
				parts.push(colDef.colId);
				// headerName can change if the aggFunc was changed in a value col. if we didn't
				// do this, then the grid would not pick up on new header names as we move from
				// eg min to max.
				if (colDef.headerName) {
					parts.push(colDef.headerName);
				}
			}
		});
		return parts.join(',');
	};
	const setSecondaryColsIntoGrid = secondaryColDefs => {
		var colDefHash = createColsHash(secondaryColDefs);
		if (colDefHashExt !== colDefHash) {
			gridRef?.current?.columnApi?.setSecondaryColumns(secondaryColDefs);
			colDefHashExt = colDefHash;
		}
	};
	let totalRecordCount;
	return {
		getRows: params => {
			var response = server.getData(params.request, options, gridRef);
			response
				.then((response = {}) => {
					const pageNumber = params.request.endRow ? Math.ceil(params.request.endRow / PAGE_SIZE) : 1;
					const selectedRowKeys: any = getTableSelection(getState(), options.tableId);
					console.log({response})
					let { data = [], columns } = response;
					if (pageNumber === 1) {
						totalRecordCount = data?.[0]?.TotalRecordCount ;
					  }

					  console.log({totalRecordCount})
					params.success({
						rowData: _.map(data, d => {
							_.keys(d).forEach(k => {
								if (d[k] === null) {
									d[k] = 'null';
								}
							});
							return d;
						}),
						rowCount: totalRecordCount ?? 0
					});
					// params.successCallback(_.map(data, d => {
					// 	_.keys(d).forEach(k => {
					// 		if (d[k] === null) {
					// 			d[k] = 'null';
					// 		}
					// 	});
					// 	return d;
					// }), totalRecordCount);

					const hasGridData = getGridData(getState(), options.tableId)?.length;
					if ((pageNumber > 1 || hasGridData) && selectedRowKeys?.isSelectAll) {
						gridRef?.current?.api?.forEachNode(function (node) {
							node.setSelected(true);
						});
					}
					if (pageNumber === 1 && selectedRowKeys?.selectedRowKeys?.length && !selectedRowKeys?.isSelectAll) {
						const gridMetaData = getGridMetadata(getState(), options.tableId);
						gridRef?.current?.api?.forEachNode(function (node) {
							if (selectedRowKeys?.selectedRowKeys.includes(node?.data?.[gridMetaData?.selectRowKey])) {
								node.setSelected(true);
							}
						});
					}

					const aggregationColumns = params.request.valueCols.map(v => v.field);
					const rootNode = { groupId: '', headerName: '', children: [] };

					if (columns && Object.keys(columns)?.length) {
						buildTree(columns, Object.keys(columns), rootNode, 0, aggregationColumns, options.tableId);
						setSecondaryColsIntoGrid(rootNode.children);
					}

					if (!totalRecordCount) {
						gridRef?.current?.api.showNoRowsOverlay();
					}
				})
				.catch(e => {
					console.log(e);
					params.fail();
				});
		}
	};
}

export const getAllowedAggFuncs = colType => {
	switch (colType) {
		case 'number':
		case 'qty':
			return ['sum', 'min', 'max', 'count', 'avg'];
		case 'price':
			return ['min', 'max', 'count'];
		case 'date':
			return ['min', 'max'];
		case 'percentage':
			return ['sum', 'min', 'max', 'avg'];
		case 'percentagefull':
			return ['sum', 'min', 'max', 'avg'];
		case 'text':
		default:
			return ['min', 'max', 'count'];
	}
};

export const createServer = tableId => {
	return {
		getData: (request, options, gridRef) => {
			const dispatch = getDispatch();
			const tableColumns = getTableColumns(getState(), tableId);
			const isAgView = getUserSettings(getState())?.IsAgView;
			console.log({request, options, gridRef})
			let sortField = getColumnInfoByIndentifier(
				getState(),
				tableId,
				request?.sortModel?.[0]?.colId
			)?.AppColumnQueryName;

			let sortModel =
				request?.groupKeys?.length !== request?.rowGroupCols?.length
					? _.filter(
						request.sortModel,
						s =>
							request?.rowGroupCols?.find(c => c.id === s.colId) ||
							request?.valueCols?.find(c => c.id === s.colId)
					)
					: request.sortModel;
			const pivotColumns = _.map(request.pivotCols, col => ({
				AliasName: col.field,
				QueryName: getColumnInfoByIndentifier(getState(), tableId, col.id)?.AppColumnQueryName
			}));
			let sortDirection = sortModel?.[0]?.sort === 'asc' ? 'ASC' : 'DESC';
			let multiSortColumns = _.map(sortModel, f => ({
				sortField:
					request.pivotMode || options.isViewImplementationOverride
						? getColumnInfoByIndentifier(getState(), tableId, f.colId)?.AppColumnName
						: getColumnInfoByIndentifier(getState(), tableId, f.colId)?.AppColumnQueryName,
				sortDirection: f.sort
			}));
			let groupAggregation =
				request.rowGroupCols.length && request?.groupKeys?.length !== request?.rowGroupCols?.length
					? request.valueCols.map(v => ({
						QueryName: `${v.aggFunc}(${request.pivotMode || options.isViewImplementationOverride
							? getColumnInfoByIndentifier(getState(), tableId, v.field).AppColumnName
							: getColumnInfoByIndentifier(getState(), tableId, v.field).AppColumnQueryName
							})`,
						AliasName: v.field
					}))
					: [];

			if (groupAggregation.length) {
				multiSortColumns = _.map(sortModel, f => ({
					sortField: groupAggregation.find(
						g => g.AliasName === getColumnInfoByIndentifier(getState(), tableId, f.colId)?.AppColumnName
					)
						? groupAggregation.find(
							g =>
								g.AliasName ===
								getColumnInfoByIndentifier(getState(), tableId, f.colId)?.AppColumnName
						).QueryName
						: getColumnInfoByIndentifier(getState(), tableId, f.colId)?.AppColumnQueryName,
					sortDirection: f.sort
				}));
			}


			dispatch(
				dynamicGridSagaActions.updateGridFilters(
					tableId,
					{
						rowGroupCols: _.map(request.rowGroupCols, col => {
							let selected = getColumnInfoByIndentifier(getState(), tableId, col.field);
							return {
								AliasName: selected.AppColumnName,
								QueryName:
									request.pivotMode || options.isViewImplementationOverride
										? selected.AppColumnName
										: selected.AppColumnQueryName
							};
						}),
						groupKeys: request.groupKeys,
						pivotColumns: request.pivotMode ? pivotColumns : [],
						isPivotMode: request.pivotMode,
						isMultiSort: multiSortColumns?.length,
						multiSortColumns,
						groupAggregation
					},
					true,
					options.isStorageEnabled
				)
			);
			// in this simplified fake server all rows are contained in an array
			return fetchGridData({
				payload: {
					tableId,
					isAdvancedSearch: true,
					sortDirection,
					sortField,
					multiSortColumns,
					isMultiSort: multiSortColumns?.length,
					pageNumber: Math.ceil(request.endRow / PAGE_SIZE),
					isAppend: Math.ceil(request.endRow / PAGE_SIZE) > 1,
					itemsPerPage: PAGE_SIZE,
					isView: request.pivotMode || options.isViewImplementationOverride,
					isPivot: request.pivotMode
				}
			});
		}
	};
};

export const getToolPanelConfig = ({ suppressPivotMode, rowGroupPanelShow, rowPivotPanelShow }) => {
	return {
		toolPanels: [
			{
				id: 'columns',
				labelDefault: 'Columns',
				labelKey: 'columns',
				iconKey: 'columns',
				toolPanel: 'agColumnsToolPanel',
				minWidth: 225,
				maxWidth: 225,
				toolPanelParams: {
					suppressRowGroups: !rowGroupPanelShow,
					suppressPivotMode: !rowPivotPanelShow,
					suppressValues: !rowPivotPanelShow
				},
				width: 225
			},
			{
				id: 'filters',
				labelDefault: 'Filters',
				labelKey: 'filters',
				iconKey: 'filter',
				toolPanel: 'agFiltersToolPanel',
				minWidth: 330,
				maxWidth: 400,
				width: 330
			},
			{
				id: 'bookmarksPanel',
				labelDefault: 'Bookmarks',
				labelKey: 'bookmarksPanel',
				iconKey: 'menu',
				toolPanelFramework: Bookmarks,
				width: 300,
				maxWidth: 400,
				minWidth: 300
			}
		]
		// defaultToolPanel: 'bookmarksPanel'
	};
};

export const getSelectionColumn = (tableId, gridRef, isParentSelectionEnabled, selectRowKey) => ({
	field: '',
	// headerCheckboxSelection: true,
	checkboxSelection: params =>
		isParentSelectionEnabled ? params?.node?.group === true : params?.node?.group !== true,
	checkbox: false,
	showDisabledCheckboxes: false,
	headerComponent: SelectBoxAg,
	lockPosition: 'left',
	pinned: 'left',
	metaData: { tableId },

	// lockPinned: true,
	headerComponentParams: {
		menuIcon: 'fa-bars',
		onChangeHandler: isSelected => {
			// if (gridRef?.current?.api.getRenderedNodes().length) {
			selectAllHandler({ tableId, isSelectAll: isSelected, gridRef, selectRowKey });
			// }
		}
	},
	suppressColumnsToolPanel: true,
	fixed: true,
	// hide: isGroupingEnabled,
	maxWidth: 50
});

export const getActionColumn = ({ tableId, gridRef, customActions, isGroupingEnabled, gridConfig }) => ({
	field: '',
	lockPosition: 'right',
	pinned: 'right',
	metaData: { tableId },
	cellRenderer: props => (
		<GridActionHandler {...props} gridConfig={gridConfig} customActions={customActions}></GridActionHandler>
	),
	cellRendererParams: {
		prop1: 'example'
	},
	suppressMenu: true,
	suppressColumnsToolPanel: true,
	fixed: true,
	hide: false,
	width: customActions ? customActions.width || 200 : 50
});
