// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const createBookmark = createAction('model/bookmark/create', function prepare(payload: { tableId: string }) {
	return {
		payload
	};
});

export const applyBookmark = createAction(
	'model/bookmark/apply',
	function prepare(payload: { tableId: string; bookmarkId: string }) {
		return {
			payload
		};
	}
);

export const saveBookmark = createAction(
	'model/bookmark/createUpdate',
	function prepare(payload: { tableId: string; bookmarkId: string; bookmarkName: string }) {
		return {
			payload
		};
	}
);

export const deleteBookmark = createAction('model/bookmark/delete', function prepare(payload: { bookmarkId: string }) {
	return {
		payload
	};
});

export const fetchBookmarksList = createAction(
	'model/bookmark/fetch/all',
	function prepare(payload: { tableId: string }) {
		return {
			payload
		};
	}
);
