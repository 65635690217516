// @ts-nocheck
import * as React from 'react';

export function useOutsideAlerter(ref: any, callback: () => void) {
	React.useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: any) {
			if (ref.current && !ref.current.contains(event.target)) {
				callback();
			}
		}

		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, callback]);
}

export const getApiBaseURL = () => {

	if (isLocalHost()) {
		return 'https://apim-thetaretail-dev.azure-api.net';
	} else if (isDev() || isDevStaging() || isStagingApp()) {
		return 'https://apim-thetaretail-dev.azure-api.net';
	}
	return 'https://apim-thetaretail-prod.azure-api.net';
};

export const getAclBaseURL = () => {


	if (isLocalHost()) {
		// return 'http://localhost:8060/api'; 
	}
	if (isDev() || isDevStaging() || isLocalHost()) {
		return 'https://apim-thetaretail-dev.azure-api.net/api/acl';
	} else if (isStagingApp()) {
		return 'https://apim-thetaretail-dev.azure-api.net/api/acl/staging';
	}
	return 'https://apim-thetaretail-prod.azure-api.net/api/acl';
};


export const getSocketBaseURL = () => {

	if (isLocalHost()) {
		// return 'ws://localhost:8050';
	}

	if (isLocalHost() || isDev() || isDevStaging()) {
		return 'wss://as-thetaretail-dev.azurewebsites.net/';
	} else if (isStagingApp()) {
		return 'wss://as-thetaretail-dev-staging.azurewebsites.net/';
	}
	return 'wss://as-thetaretail-prod.azurewebsites.net/';
};

export const isStagingApp = () => {
	return window.location.origin.match(/thetaretail-staging/);
};

export const isLocalHost = () => {
		return window.location.origin.match(/localhost/);
}

export const isDevStaging = () => {
	return window.location.origin.match(/dev-staging|dashboard\.shelfscience/);
}

export const isDev = () => {
	return window.location.origin.match(/-dev/);
}