// @ts-nocheck
import * as React from 'react';
import './tabs.scss';
import classNames from 'classnames';

interface Props {
	type: 'primary' | 'secondary';
	children: React.ReactNode;
}

const Tabs: React.FunctionComponent<any> = (props: Props) => {
	const { type, isOverflow } = props;
	const classes: any = classNames(['tabs', { [`tabs--${type}`]: true }]);

	return <div className={classes}>{props.children}</div>;
};

Tabs.defaultProps = {
	type: 'primary'
};
export default Tabs;
