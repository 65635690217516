export const OPTION_IDENTIFIERS = {
	AD_TYPES: 'AD_TYPES',
	CUSTOMERS: 'CUSTOMERS',
	GROUPS: 'GROUPS',
	INVENTORY_TRANSACTIONS: 'INVENTORY_TRANSACTIONS',
	ITEM_EXCLUSIONS: 'ITEM_EXCLUSIONS',
	ITEM_SHIPPING_METHOD_CONFIGURATION: 'ITEM_SHIPPING_METHOD_CONFIGURATION',
	ITEMS: 'ITEMS',
	PARAMETERS: 'PARAMETERS',
	PRICING: 'PRICING',
	SKU_EXCLUSIONS: 'SKU_EXCLUSIONS',
	PRIMARY_STORE_SKU: 'PRIMARY_STORE_SKU',
	SKU_SHIPPING_METHOD_CONFIGURATION: 'SKU_SHIPPING_METHOD_CONFIGURATION',
	SKUS: 'SKUS',
	SHIPMENTS: 'SHIPMENTS',
	SCHEDULES: 'SCHEDULES',
	SHIPPING_METHOD: 'SHIPPING_METHOD',
	STORE_ORDERS_LIST: 'STORE_ORDERS_LIST',
	STORES: 'STORES',
	WAREHOUSES: 'WAREHOUSES',
	VALID_SKU_COMBINATIONS: 'VALID_SKU_COMBINATIONS',
	VALID_STORE_COMBINATIONS: 'VALID_STORE_COMBINATIONS',
	KITS: 'KITS'
};
export const OPTIONS = [
	{
		title: 'Ad Types',
		description: 'Create or edit types of Ads',
		buttonText: 'EDIT',
		itemIdentifier: OPTION_IDENTIFIERS.AD_TYPES
	},
	{
		title: 'Customers',
		description: 'View Customer list',
		buttonText: 'VIEW',
		itemIdentifier: OPTION_IDENTIFIERS.CUSTOMERS
	},
	{
		title: 'Warehouses',
		description: 'View Warehouses list',
		buttonText: 'VIEW',
		itemIdentifier: OPTION_IDENTIFIERS.WAREHOUSES
	},
	{
		title: 'Groups',
		description: 'Create custom groups of Stores, SKUs, or Customers for use in order writing or parameter setting',
		buttonText: 'EDIT',
		itemIdentifier: OPTION_IDENTIFIERS.GROUPS
	},
	{
		title: 'Inventory Transactions',
		description: 'View List of all the inventory transactions',
		buttonText: 'VIEW',
		itemIdentifier: OPTION_IDENTIFIERS.INVENTORY_TRANSACTIONS
	},
	{
		title: 'Item Exclusions',
		description: 'View list of invalid Store/Item combinations',
		buttonText: 'VIEW',
		itemIdentifier: OPTION_IDENTIFIERS.ITEM_EXCLUSIONS
	},
	{
		title: 'Item Shipping Method Configuration',
		description: 'View Shipping Method setup by Item',
		buttonText: 'VIEW',
		itemIdentifier: OPTION_IDENTIFIERS.ITEM_SHIPPING_METHOD_CONFIGURATION
	},
	{
		title: 'Items',
		description: 'View Item list',
		buttonText: 'VIEW',
		itemIdentifier: OPTION_IDENTIFIERS.ITEMS
	},
	{
		title: 'Kits',
		description: 'View Kit Definition by SKU',
		buttonText: 'VIEW',
		itemIdentifier: OPTION_IDENTIFIERS.KITS
	},
	{
		title: 'Parameters',
		description: 'View Parameter list',
		buttonText: 'VIEW',
		itemIdentifier: OPTION_IDENTIFIERS.PARAMETERS
	},
	{
		title: 'Pricing',
		description: 'View current and historical SKU costs and retails',
		buttonText: 'VIEW',
		itemIdentifier: OPTION_IDENTIFIERS.PRICING
	},
	{
		title: 'SKU Exclusions',
		description: 'View list of invalid Store/SKU combinations',
		buttonText: 'VIEW',
		itemIdentifier: OPTION_IDENTIFIERS.SKU_EXCLUSIONS
	},
	{
		title: 'Primary Store SKU',
		description: 'View Primary Store SKU',
		buttonText: 'VIEW',
		itemIdentifier: OPTION_IDENTIFIERS.PRIMARY_STORE_SKU
	},
	{
		title: 'SKUs',
		description: 'View SKU list',
		buttonText: 'VIEW',
		itemIdentifier: OPTION_IDENTIFIERS.SKUS
	},
	{
		title: 'Schedules',
		description: 'Add/View store schedules',
		buttonText: 'VIEW',
		itemIdentifier: OPTION_IDENTIFIERS.SCHEDULES
	},
	{
		title: 'Shipments',
		description: 'View List of Retail Shipments',
		buttonText: 'VIEW',
		itemIdentifier: OPTION_IDENTIFIERS.SHIPMENTS
	},
	{
		title: 'Shipping Methods',
		description: 'View list of Shipping Methods',
		buttonText: 'VIEW',
		itemIdentifier: OPTION_IDENTIFIERS.SHIPPING_METHOD
	},
	{
		title: 'Store Order List',
		description: 'View Store Order List',
		buttonText: 'VIEW',
		itemIdentifier: OPTION_IDENTIFIERS.STORE_ORDERS_LIST
	},
	{
		title: 'Stores',
		description: 'View Store list',
		buttonText: 'VIEW',
		itemIdentifier: OPTION_IDENTIFIERS.STORES
	},
	{
		title: 'Valid SKU/Item Combinations',
		description: 'View valid SKU/Item combinations',
		buttonText: 'VIEW',
		itemIdentifier: OPTION_IDENTIFIERS.VALID_SKU_COMBINATIONS
	},
	{
		title: 'Valid Store/SKU Combinations',
		description: 'View master configuration of valid Store/SKU combinations',
		buttonText: 'VIEW',
		itemIdentifier: OPTION_IDENTIFIERS.VALID_STORE_COMBINATIONS
	}
];

export const MASTER_DATA_MENU_OPTIONS = {
	UPDATE_SCHEDULE: 'UPDATE_SCHEDULE',
	DELETE_CURRENT_SCHEDULE: 'DELETE_CURRENT_SCHEDULE',
	CUSTOMIZE_SCHEDULE: 'CUSTOMIZE_SCHEDULE',
	UPDATE_SCHEDULE_FOR_STORES: 'UPDATE_SCHEDULE_FOR_STORES'
};
export const MASTER_DATA_MENU_OPTIONS_NAMES = {
	UPDATE_SCHEDULE: 'UPDATE SCHEDULE',
	DELETE_CURRENT_SCHEDULE: 'DELETE CURRENT SCHEDULE',
	CUSTOMIZE_SCHEDULE: 'CUSTOMIZE SCHEDULE',
	UPDATE_SCHEDULE_FOR_STORES: 'UPDATE SCHEDULE FOR STORES'
};

export const DROPDOWN_MENU_ALL_ORDERS = [
	{
		displayName: MASTER_DATA_MENU_OPTIONS_NAMES.UPDATE_SCHEDULE,
		value: MASTER_DATA_MENU_OPTIONS.UPDATE_SCHEDULE
	},
	{
		displayName: MASTER_DATA_MENU_OPTIONS_NAMES.DELETE_CURRENT_SCHEDULE,
		value: MASTER_DATA_MENU_OPTIONS.DELETE_CURRENT_SCHEDULE
	},
	{
		displayName: MASTER_DATA_MENU_OPTIONS_NAMES.CUSTOMIZE_SCHEDULE,
		value: MASTER_DATA_MENU_OPTIONS.CUSTOMIZE_SCHEDULE
	},
	{
		displayName: MASTER_DATA_MENU_OPTIONS_NAMES.UPDATE_SCHEDULE_FOR_STORES,
		value: MASTER_DATA_MENU_OPTIONS.UPDATE_SCHEDULE_FOR_STORES
	}
];

export const MASTER_SEARCH_PARAMS_OPTIONS = {
	STORE: 1,
	CUSTOMER_NAME: 2,
	STATE: 3,
	VOLUME_CLASS: 4,
	ATTRIBUTE_1: 5,
	ATTRIBUTE_2: 6,
	ATTRIBUTE_3: 7,
	ATTRIBUTE_4: 8,
	ATTRIBUTE_5: 9
};

export const MASTER_SEARCH_PARAMS = [
	{
		displayName: 'Store',
		value: MASTER_SEARCH_PARAMS_OPTIONS.STORE,
		rowIdentifier: 'Store'
	},
	{
		displayName: 'Customer Name',
		value: MASTER_SEARCH_PARAMS_OPTIONS.CUSTOMER_NAME,
		rowIdentifier: 'CustomerName'
	},
	{
		displayName: 'State',
		value: MASTER_SEARCH_PARAMS_OPTIONS.STATE,
		rowIdentifier: 'State'
	},
	{
		displayName: 'Volume Class',
		value: MASTER_SEARCH_PARAMS_OPTIONS.VOLUME_CLASS,
		rowIdentifier: 'VolumeClass'
	},
	{
		displayName: 'Attribute 1',
		value: MASTER_SEARCH_PARAMS_OPTIONS.ATTRIBUTE_1,
		rowIdentifier: 'StoreAttribute1'
	},
	{
		displayName: 'Attribute 2',
		value: MASTER_SEARCH_PARAMS_OPTIONS.ATTRIBUTE_2,
		rowIdentifier: 'StoreAttribute2'
	},
	{
		displayName: 'Attribute 3',
		value: MASTER_SEARCH_PARAMS_OPTIONS.ATTRIBUTE_3,
		rowIdentifier: 'StoreAttribute3'
	},
	{
		displayName: 'Attribute 4',
		value: MASTER_SEARCH_PARAMS_OPTIONS.ATTRIBUTE_4,
		rowIdentifier: 'StoreAttribute4'
	},
	{
		displayName: 'Attribute 5',
		value: MASTER_SEARCH_PARAMS_OPTIONS.ATTRIBUTE_5,
		rowIdentifier: 'StoreAttribute5'
	}
];
