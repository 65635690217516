// @ts-nocheck
import * as React from 'react';
import './stack.scss';
import classNames from 'classnames';

interface Props extends React.PropsWithChildren<any> {
	isVertical?: boolean;
	gutter?: 'small' | 'large' | 'medium' | 'tiny' | 'none';
	isWrap?: boolean;
	position?: 'top' | 'bottom' | 'center';
	align?: 'left' | 'right' | 'center' | 'space-between';
	style?: any;
}

const Stack: React.FunctionComponent<Props> = (props: Props) => {
	const { isVertical, gutter, isWrap, position, style, children, align, divisions, className } = props;
	const classes: any = classNames([
		'stack',
		className,
		{
			'stack--vertical': isVertical,
			'stack--wrap': isWrap,
			[`stack--gutter-${gutter}`]: true,
			[`stack--position-${position}`]: true,
			[`stack--align-${align}`]: true
		}
	]);

	const childrenWithProps = React.Children.map(children, child => {
		// Checking isValidElement is the safe way and avoids a
		// typescript error too.
		if (React.isValidElement(child)) {
			return React.cloneElement(child, { divisions });
		}
		return child;
	});
	return (
		<div style={style} className={classes}>
			{childrenWithProps}
		</div>
	);
};

const defaultProps: Props = {
	isVertical: false,
	gutter: 'none',
	isWrap: false,
	align: 'left'
};

Stack.defaultProps = defaultProps;

export default Stack;
