// @ts-nocheck
import { IToolPanel } from 'ag-grid-community';
import Button from 'atoms/button/button';
import Heading from 'atoms/heading/heading';
import Label from 'atoms/label/label';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import ModalHeader from 'atoms/modal/modalHeader';
import ReactModal from 'atoms/modal/reactModal';
import TextInput from 'atoms/TextInput/textInput';
import { applyBookmark, saveBookmark } from 'models/bookmarks/sagaActions';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React, { useEffect, useState } from 'react';
import { getDispatch } from 'reducers';

interface CustomToolPanelProps extends IToolPanel {}

export const BookmarkDialog: React.FC<CustomToolPanelProps> = props => {
	const { tableId, selectedBookmark, onHide } = props;
	const [bookmarkName, setBookmarkName] = useState(selectedBookmark?.BookmarkName);
	const dispatch = getDispatch();
	const handleSaveBookmark = () => {
		dispatch(
			saveBookmark({
				tableId,
				bookmarkId: selectedBookmark?.BookmarkID,
				bookmarkName
			})
		);
		onHide();
		// if (selected) dispatch(applyBookmark({ bookmarkId, tableId }));
	};
	return (
		<>
			<ReactModal hideModal={onHide} height={400} width={400}>
				<ModalHeader>
					<Heading type="secondary">Create Bookmark</Heading>
				</ModalHeader>
				<ModalBody>
					<Stack isVertical gutter="small">
						<StackItem>
							<Label>Bookmark Name:</Label>
						</StackItem>
						<StackItem isStretch>
							<TextInput
								value={bookmarkName}
								onChange={e => {
									setBookmarkName(e.target.value);
								}}
							></TextInput>
						</StackItem>
					</Stack>
				</ModalBody>
				<ModalActions>
					<Stack align="right" gutter="small">
						<StackItem>
							<Button isSecondary name="CANCEL" handleClick={onHide} />
						</StackItem>

						<StackItem isStretch align="right">
							<Button
								disabled={!bookmarkName?.trim()?.length}
								name="CREATE"
								handleClick={handleSaveBookmark}
							/>
						</StackItem>
					</Stack>
				</ModalActions>
			</ReactModal>
		</>
	);
};
