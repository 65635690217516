// @ts-nocheck
import Accordion from 'atoms/accordion/accordion';
import AccordionItem from 'atoms/accordion/accordionItem';
import BodyText from 'atoms/bodyText/bodyText';
import Heading from 'atoms/heading/heading';
import _ from 'lodash';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import './customizeVIewModal.css';

type TProps = {
  name: string;
  columns: any;
  isActive: boolean;
  selectHandler: React.ChangeEventHandler;
  setActiveGroup: Function;
};

const CustomizeColumnGroup = (props: TProps) => {
  const { columns, name, selectHandler, isActive, setActiveGroup } = props;

  const titleClickHandler = (e: React.MouseEvent) => {
    setActiveGroup(name);
  };

  return (
    <Stack>
      <StackItem isGrow>
        <Accordion>
          <AccordionItem
            isActive={isActive}
            title={name}
            titleClickHandler={titleClickHandler}
            body={
              <Stack gutter="small" isWrap>
                {_.map(columns, (column, index) => (
                  <StackItem isGrow>
                    <CustomizeColumn
                      column={column}
                      selectHandler={selectHandler}
                      key={`${name}-${index}`}
                    />
                  </StackItem>
                ))}
              </Stack>
            }
          />
        </Accordion>
      </StackItem>
    </Stack>
  );
};

export default CustomizeColumnGroup;

const CustomizeColumn = (props: any) => {
  const { column, selectHandler } = props;

  return (
    // <div className="customizeColumns">
    <div className="customizeColumns">
      <Stack gutter="medium">
        <StackItem position="center">
          <input
            type={'checkbox'}
            value={column.AppColumnSurrogateID}
            onChange={selectHandler}
            checked={column.selected}
          />
        </StackItem>
        <StackItem position="center" isGrow>
          <BodyText>{column.AppColumnAliasName || column.AppColumn}</BodyText>
        </StackItem>
      </Stack>
    </div>
  );
};
