// @ts-nocheck
export const MODAL_NAME = 'modal-generateOrders';

export const STEPS_KEYS = {
	SCHEDULE_DETAILS: 'SCHEDULE_DETAILS',
	SELECT_STORE: 'SELECT_STORE'
};

export const STEPS = [
	{
		key: 'SCHEDULE_DETAILS',
		title: 'Schedule Details'
	},
	{
		key: 'SELECT_STORE',
		title: 'Select Stores'
	}
];

export const DAYS = [
	{ key: 'Monday', value: 'Monday' },
	{ key: 'Tuesday', value: 'Tuesday' },
	{ key: 'Wednesday', value: 'Wednesday' },
	{ key: 'Thursday', value: 'Thursday' },
	{ key: 'Friday', value: 'Friday' },
	{ key: 'Saturday', value: 'Saturday' },
	{ key: 'Sunday', value: 'Sunday' }
];

export const MODAL_TABLE_IDS = {
	STORES: 49
};

export const MODAL_MODES = {
	NEW: 'new',
	UPDATE: 'update'
};
