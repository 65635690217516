// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';

export const triggerFetchAutoComplete = createAction('autoComplete/fetch', ({ componentId, options,params, isAppend }) => ({
	payload: {
		componentId,
		options,
		params
	}
}));
