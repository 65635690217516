// @ts-nocheck
import * as React from 'react';
import './divider.scss';
import classNames from 'classnames';

interface Props {
	isVertical: boolean;
}

const Divider: React.FunctionComponent<any> = (props: Props) => {
	const { isVertical } = props;
	const classes: any = classNames([
		'divider',
		{ 'divider-vertical': isVertical, [`divider--${props?.size}`]: !!props?.size }
	]);

	return <div className={classes} />;
};

Divider.defaultProps = {
	isVertical: false
};

export default Divider;
