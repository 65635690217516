// @ts-nocheck
import { REQUEST_STATUS } from './constants';
import { initialState, name } from './reducer';

const auth = state => state[name] || initialState;

export const isLoadingEnabled = (state, component) => {
	return auth(state)?.[component]?.['isLoading'];
};

export const isRequestPending = (state, component) => {
	return auth(state)?.[component]?.status === REQUEST_STATUS.PENDING;
};

export const isRequestComplete = (state, component) => {
	return auth(state)?.[component]?.status === REQUEST_STATUS.COMPLETE;
};

export const isRequestError = (state, component) => {
	return auth(state)?.[component]?.status === REQUEST_STATUS.ERROR
		? {
				error: auth(state)?.[component]
		  }
		: false;
};
