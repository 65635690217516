// @ts-nocheck
import _ from 'lodash';
import moment from 'moment-timezone';
import { formatDate } from 'utils/utilities';
import * as constants from './constants';
import {} from './types';

export const createActionName = (eventName: string) => {
	return `${constants.MODAL_NAME}-action/${eventName}`;
};

export const getStepsKeys = (steps: any[]): any[string] => {
	return _.map(steps, 'key');
};

export const getSteps = (steps: any[]): any => {
	const parsedSteps = {};

	for (const step of steps) {
		const key: string = step.key;
		delete step.key;
		parsedSteps[key] = step;
	}

	return parsedSteps;
};

export const isValid = (key: string, value: any, params: any = {}): { hasError: boolean; errorMessage: string } => {

	const { data } = params;
	let hasError = false;
	let errorMessage = '';

	switch (key) {
		case 'shipStartDate':
			if (params.useStoreSchedule) {
				break;
			}
		case 'PODate':
			if (!value || !formatDate(value).length) {
				hasError = true;
				errorMessage = 'Value is required.';
			}
			break;

		case 'shipDateRange':
			if (
				params &&
				params.useStoreSchedule &&
				(!value || !(moment(value?.[0]).isValid() && moment(value?.[1]).isValid()))
			) {
				hasError = true;
				errorMessage = 'Value is required.';
			}
			break;
		case 'customerID':
			if (!value || !value?.length) {
				hasError = true;
				errorMessage = 'Value is required.';
			}
			break;
		case 'shipMethodID':
		case 'warehouseID':
			if (!value) {
				hasError = true;
				errorMessage = 'Value is required.';
			}
			break;

		case 'storeScheduleID':
			if (params && params.useStoreSchedule && !value) {
				hasError = true;
				errorMessage = 'Value is required.';
			}
			break;

		default:
			hasError = false;
	}
	return { hasError, errorMessage };
};

export const validateForm = (data: any): boolean => {
	let hasError = false;
	_.forEach(Object.keys(data), key => {
		const result = isValid(key, data[key], data);
		if (result.hasError && !hasError) {
			hasError = result.hasError;
		}
	});

	return hasError;
};
