// @ts-nocheck
import { Button, Tooltip } from 'antd';
import Icon from 'atoms/icons/icon';
import * as React from 'react';

interface Props extends React.PropsWithChildren<any> {}

const FullScreen: React.FunctionComponent<any> = (props: Props) => {
	const [isFullScreen, toggleFullScreen] = React.useState();

	return (
		<div id="full-screen" style={{ width: '100%', height: '100%' }}>
			<Button
				style={{ position: 'absolute', zIndex: 800, right: '6px', top: '95%', padding: '2px' }}
				size="small"
				onClick={() => {
					var elem = document.getElementById('full-screen');
					if (document.fullscreenElement) {
						document.exitFullscreen();
					}
					if (elem.requestFullscreen) {
						elem.requestFullscreen();
					} else if (elem.webkitRequestFullscreen) {
						/* Safari */
						elem.webkitRequestFullscreen();
					} else if (elem.msRequestFullscreen) {
						/* IE11 */
						elem.msRequestFullscreen();
					}
				}}
			>
				<Tooltip title="Enable Full Screen Mode">
					{' '}
					<Icon size="small" faAlias="faExpand"></Icon>
				</Tooltip>
			</Button>
			{props.children}{' '}
		</div>
	);
};

export default FullScreen;
