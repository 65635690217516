// @ts-nocheck

import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { Alert, Checkbox, Divider, Popover, Radio, Upload, message } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import Modal from 'atoms/modal/modal';
import StackItem from 'molecules/stackItem/stackItem';
import FileUploader from 'molecules/fileUploader';
import ModalBody from 'atoms/modal/modalBody';
import Stack from 'molecules/stack/stack';
import { getTableSelection } from 'models/dynamicGrid/selector';
import ModalHeader from 'atoms/modal/modalHeader';
import { toggleLoaderState } from 'models/loaders/reducer';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import Sheet from 'atoms/sheet/sheet';
import { PAGES, UPLOAD_DATA_CHANGE_TYPES, UPLOAD_TYPE } from './constants';
import * as sagaActions from './sagaActions';
import Dropdown from 'atoms/dropdown/dropdown';
import Heading from 'atoms/heading/heading';
import ModalActions from 'atoms/modal/modalActions';
import Button from 'atoms/button/button';
import { useDispatch, useSelector } from 'react-redux';
import { onUploadFileV2, revalidateUploadGrid, submitUploadFile, triggerFetchDownloadTemplate } from './sagaActions';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import { getActivePage } from './selectors';
import * as selectors from './selectors';
import { setActivePage } from './reducer';
import Icon from 'atoms/icons/icon';
import { isLoadingEnabled, isRequestComplete, isRequestPending } from 'models/loaders/selectors';
import Loader from 'atoms/loader';
import BodyText from 'atoms/bodyText/bodyText';
import ReactModal from 'atoms/modal/reactModal';
import { getComponentUserSettings, getERPStatus, getGridData, getGridMetadata } from 'models/dynamicGrid/selector';
import { fetchComponentUserSettings, handleBulkRowDelete, refreshGridData } from 'models/dynamicGrid/sagaActions';
import { REQUESTS } from 'utils/requests';
import Label from 'atoms/label/label';
import Scrollable from 'atoms/scrollable/scrollable';
import { REQUEST_STATUS } from 'models/loaders/constants';

const { Dragger } = Upload;

const getUploadContent = (rules) => {
	return (<ul>
	{rules?.map(r => <li>
	  {
		<Label size="tiny">{r}</Label>
	  }
	</li>)}

	</ul>)
}
interface Props {
    visible: boolean;
    onCancel: () => void;
    onUpload: ({ file, params }: { files: UploadFile; params: any }) => void;
}

const GridCsvUploadModal: React.FC<Props> = ({ visible, onCancel, hasDataChangeType=true, title, onUpload,onUploadComplete= _.noop, parentTableId, uploadTableId, uploadParams, tableOptions, onClose, fieldGroups }) => {
    const isAvailability = parentTableId === 7 || parentTableId === 25;
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const dispatch = useDispatch();
    const isUploading = useSelector(state => isLoadingEnabled(state, `upload`));
    const userSettings = useSelector(state => getComponentUserSettings(state, 'file-upload'));
    const isLoadingUserSettings = useSelector(state => isLoadingEnabled(state, `file-upload/${parentTableId}`));
    const gridRows = useSelector(state => getGridData(state, uploadTableId));
    const [customers, setCustomers] = React.useState([]);
    const [warehouses, setWarehouses] = React.useState([]);
    const [importType, updateImportType] = useState('csv')
    const stagingParams = uploadParams ?? {
        ...REQUESTS.PUT.GRID_UPLOAD_STAGING,
        submitService: REQUESTS.PUT.GRID_UPLOAD_SUBMIT.service,
        submitUrl: REQUESTS.PUT.GRID_UPLOAD_SUBMIT.url,
        tableId: uploadTableId
    };
	const uploadMetaData = useSelector(state => selectors.getUploadMetadata(state, parentTableId));

    const gridMetaData = useSelector(state => getGridMetadata(state, uploadTableId));
    const [selectedCustomer, setSelectedCustomer] = React.useState({});
    const [selectedWarehouse, setSelectedWarehouse] = React.useState({});
    const isLoadingErp = useSelector(state => isLoadingEnabled(state, 'isLoadingErp'));
    const isErpEnabled = useSelector(state => getERPStatus(state, parentTableId)) || false;

    const fetchedCustomers = useSelector(state => selectors.fetchCustomers(state)) || [];
    const fetchedWarehouses = useSelector(state => selectors.fetchWarehouses(state)) || [];
    const [isIncrementalUpload, handleIsIncrementalUpload] = React.useState({
        displayName: UPLOAD_DATA_CHANGE_TYPES[1].displayName,
        value: UPLOAD_DATA_CHANGE_TYPES[1].value
    });

    const isUploadPending = useSelector(state => isRequestPending(state, 'upload'));
    const isUploadComplete = useSelector(state => isRequestComplete(state, 'upload'));
    const selectedRowKeys: any = useSelector(state => getTableSelection(state, uploadTableId));


    const uploadRulesTemplate = getUploadContent(uploadMetaData?.Rules);


    React.useEffect(() => {
        if (isErpEnabled && userSettings?.importType === 'erp') {
            updateImportType(UPLOAD_TYPE.ERP);
        }
        if (isLoadingUserSettings) {
            return;
        }
        setCustomers(fetchedCustomers);
        setWarehouses(fetchedWarehouses);

        updateImportType(userSettings?.importType || 'csv');
        setSelectedWarehouse(
            fetchedWarehouses.find(({ value }) => value === userSettings?.warehouse) || fetchedWarehouses[0]
        );
        setSelectedCustomer(
            fetchedCustomers.find(({ value }) => value === userSettings?.customer) || fetchedCustomers[0]
        );
        handleIsIncrementalUpload(
            UPLOAD_DATA_CHANGE_TYPES.find(({ value }) => value === userSettings?.isIncrementalUpload) ||
            UPLOAD_DATA_CHANGE_TYPES[1]
        );
    }, [isErpEnabled, fetchedCustomers, fetchedWarehouses, userSettings, isLoadingUserSettings]);


    React.useEffect(() => {
        if (isUploadComplete) {
            setGridUpload(false);
            
            onUploadComplete()
        }
        return () => {
            dispatch(
                toggleLoaderState({
                    component: 'upload',
                    status: REQUEST_STATUS.NONE
                })
            )
            dispatch(setActivePage({ activePage: PAGES.UPLOAD }));

        }
    }, [isUploadComplete, onUploadComplete])


    const activePage = useSelector(getActivePage);
    const handleFileUpload = (fileList: UploadFile[]) => {
        setFileList(fileList);
    };
    const handleDownloadTemplate = () => {
        dispatch(
            triggerFetchDownloadTemplate({
                tableId: uploadTableId
            })
        );
    };
    const handleUpload = () => {

        let params = {
            isIncrementalUpload: isIncrementalUpload?.value,
            importType,
            parentTableId,
            file: fileList?.[0],
        }

        if (isAvailability && importType === UPLOAD_TYPE.ERP) {
            params['warehouse'] = selectedWarehouse.value;
            params['customer'] = selectedCustomer.value;
        }

        if (onUpload) {
            onUpload({
                files: fileList,
                params: {
                    isIncrementalUpload: isIncrementalUpload?.value,
                    importType,
                    parentTableId
                }
            });
        }

        dispatch(
            onUploadFileV2({
                tableId: uploadTableId,
                params,
                uploadParams: stagingParams
            })
        );
    };


    const handleSubmit = () => {
        if (onUpload) {
            onUpload({
                files: fileList,
                params: {
                    isIncrementalUpload: isIncrementalUpload?.value
                }
            });
        }
        dispatch(
            submitUploadFile({
                tableId: uploadTableId,
                fieldGroups,
                parentTableId,
                uploadParams: stagingParams,
                isIncrementalUpload: isIncrementalUpload?.value
            })
        );

    };
    const isDeleteEnabled = (selectedRowKeys?.isSelectAll && selectedRowKeys?.selectedRowKeys?.length) || selectedRowKeys?.selectedRowKeys?.length
    const gridConfig = {
        enableSelection: true,
        enableSorting: true,
        enableMultiSort: true,
        isUploadGrid: true,
        parentTableId,
        gridActions: true,
        selectionCallBack: () => { }
    };

    const reValidate = () => {
        dispatch(revalidateUploadGrid({
            tableId: uploadTableId,
            isIncrementalUpload: isIncrementalUpload?.value
        }));
    }

    React.useEffect(() => {
        dispatch(sagaActions.resetUpload());
        if (isAvailability) {
            dispatch(sagaActions.getWarehouses());
            dispatch(sagaActions.getCustomers());
        }
        dispatch(fetchComponentUserSettings({ tableId: parentTableId, component: 'file-upload' }));
        return () => {
            dispatch(sagaActions.resetUpload());
        };
    }, []);

    const templateConfig = React.useMemo(
        () => ({
            gridConfig,
            isFilterEnabled: true,
            tableId: uploadTableId,

            dataProviderOptions: {
                type: 'POST',
                version: 2,
                ...REQUESTS.POST.GET_GRID_UPLOAD_DETAILS,
                body: {

                },
                multiSelectFieldGroups: {
                    isTemplateRequired: true
                },
                pathParams: { tableId: uploadTableId },
                ...tableOptions?.dataProviderOptions
            },

            selectRowKey: 'RowID'
        }),
        [uploadTableId]
    );
    const [isGridUpload, setGridUpload] = useState(false);

    useEffect(() => {
        if (!isGridUpload) {
            setFileList(null)
            dispatch(setActivePage({ activePage: PAGES.UPLOAD }));
        }
    }, [dispatch, isGridUpload]);

    const onDeleteAll = () => {
        dispatch(handleBulkRowDelete({ tableId: uploadTableId, isUploadGrid: true }));
    };

    return (
        <>
            <ReactModal height={activePage === PAGES.UPLOAD ? 650 : 800} width={activePage === PAGES.UPLOAD ? 500 : 800}>
                <Sheet isInset>
                    <SheetBody>

                        <Stack isVertical gutter="small">
                            <StackItem isStretch>
                                <ModalHeader> <Stack>
                                    <StackItem>
                                    {title ?? 'File Upload'}
                                        </StackItem>
                                        <StackItem>
{/*                                             
												<Popover style={{zIndex: 1000}} placement="topRight" title={'Upload Info'} content={uploadRulesTemplate}>
											<span>	Icon </span>
       												 </Popover> */}
                                        </StackItem>
                                        </Stack></ModalHeader>
                            </StackItem>
                            {isUploadPending ? <StackItem isGrow align='center' position='center'><Loader></Loader></StackItem> : <Scrollable isStretch isGrow >
                                <ModalBody>
                                    {!isLoadingErp && activePage === PAGES.UPLOAD_GRID && (
                                        <Stack isVertical gutter="small">
                                            <> {Boolean(gridRows?.[0]?.ErrorCount) && <StackItem isStretch>
                                                <Alert type="error" banner message="There are errors in the uploaded data, please re-upload or contact administrator for more info.">

                                                </Alert>
                                            </StackItem>}

                                                <StackItem isStretch align='right'>
                                                    <Stack gutter="small">
                                                        <StackItem isGrow>
                                                            {!gridMetaData?.isPristine &&
                                                                <Alert type="warning" banner message="You have made changes that have not been validated, Please revalidate to continue.">

                                                                </Alert>
                                                            }
                                                        </StackItem>
                                                        <StackItem >
                                                            <Button handleClick={() => {
                                                                reValidate();
                                                            }}>Revalidate</Button>
                                                        </StackItem>
                                                        <StackItem isStretch align='right'>
                                                            <Button disabled={!isDeleteEnabled} handleClick={onDeleteAll}>Delete Selection</Button>
                                                        </StackItem>
                                                    </Stack>

                                                </StackItem>
                                            </>


                                            <StackItem isGrow>
                                                <ThetaGridPaginated
                                                    isStorageEnabled={false}
                                                    key={uploadTableId}
                                                    config={templateConfig}
                                                />
                                            </StackItem>
                                        </Stack>
                                    )}
                                    {!isLoadingErp && activePage === PAGES.UPLOAD && (
                                        <Stack isVertical gutter="small">
                                            {isUploading ? (
                                                <>
                                                    <StackItem isStretch align="center">
                                                        <Loader></Loader>
                                                    </StackItem>
                                                    <StackItem isStretch align="center">
                                                        <BodyText>Please wait while we upload your file.</BodyText>
                                                    </StackItem>
                                                </>
                                            ) : (
                                                <>
                                                    <StackItem align="center">
                                                        <Stack gutter="small">
                                                            <StackItem isStretch> <Label isHighlight>Upload Method: </Label> </StackItem>
                                                            <StackItem isStretch position="center" >
                                                                <Stack gutter="tiny" position="center">
                                                                    <StackItem>
                                                                        <Radio name="radio" checked={importType === 'csv'} onChange={e => {
                                                                            updateImportType('csv')
                                                                        }} />

                                                                    </StackItem>
                                                                    <StackItem>
                                                                        <Label isHighlight>CSV</Label>
                                                                    </StackItem>
                                                                </Stack>
                                                            </StackItem>
                                                            <StackItem isStretch position="center">
                                                                <Stack gutter="tiny" position="center">
                                                                    <StackItem>
                                                                        <Radio
                                                                            disabled={!isErpEnabled}
                                                                            name="radio" checked={importType === 'erp'} onChange={e => {
                                                                                updateImportType('erp')
                                                                            }} />

                                                                    </StackItem>
                                                                    <StackItem>
                                                                        <Label isHighlight>ERP</Label>
                                                                    </StackItem>
                                                                </Stack>
                                                            </StackItem>
                                                        </Stack>
                                                    </StackItem>
                                                    <StackItem align="center">
                                                        <FileUploader disabled={importType === 'erp'} onUpload={handleFileUpload}></FileUploader>
                                                    </StackItem>

                                                    <StackItem isStretch>
                                                        <Divider></Divider>
                                                    </StackItem>
                                                    <StackItem isStretch align="right">
                                                        <Button handleClick={handleDownloadTemplate}>
                                                            Download File Template
                                                        </Button>
                                                    </StackItem>
                                                    {isAvailability && importType === UPLOAD_TYPE.ERP && (
                                                        <StackItem>
                                                            <Stack isVertical gutter="small">
                                                                <StackItem>
                                                                    <Heading type="tertiary"> Customer</Heading>
                                                                </StackItem>
                                                                <StackItem>
                                                                    <Dropdown
                                                                        selectedOption={selectedCustomer}
                                                                        updateHandler={setSelectedCustomer}
                                                                        options={customers}
                                                                    ></Dropdown>
                                                                </StackItem>
                                                            </Stack>
                                                        </StackItem>
                                                    )}
                                                    {isAvailability && importType === UPLOAD_TYPE.ERP && (
                                                        <StackItem>
                                                            <Stack isVertical gutter="small">
                                                                <StackItem>
                                                                    <Heading type="tertiary"> Warehouses</Heading>
                                                                </StackItem>
                                                                <StackItem>
                                                                    <Dropdown
                                                                        selectedOption={selectedWarehouse}
                                                                        updateHandler={setSelectedWarehouse}
                                                                        options={warehouses}
                                                                    ></Dropdown>
                                                                </StackItem>
                                                            </Stack>
                                                        </StackItem>
                                                    )}
                                                    {hasDataChangeType && <StackItem>
                                                        <Stack isVertical gutter="small">
                                                            <StackItem>
                                                                <Heading type="tertiary">
                                                                    {' '}
                                                                    Select Data Change Type
                                                                </Heading>
                                                            </StackItem>
                                                            <StackItem>
                                                                <Dropdown
                                                                    selectedOption={isIncrementalUpload}
                                                                    updateHandler={handleIsIncrementalUpload}
                                                                    options={UPLOAD_DATA_CHANGE_TYPES}
                                                                ></Dropdown>
                                                            </StackItem>
                                                        </Stack>
                                                    </StackItem>}
                                                </>
                                            )}
                                        </Stack>
                                    )}
                                </ModalBody>
                            </Scrollable>}

                            <StackItem isStretch position="bottom">
                                <ModalActions>
                                    <Stack gutter="small" align="right">
                                        <StackItem>
                                            <Button isSecondary handleClick={() => onClose(false)}>
                                                Cancel
                                            </Button>
                                        </StackItem>
                                        {activePage === PAGES.UPLOAD ? (
                                            <StackItem>
                                                <Button disabled={!fileList?.length && importType !== 'erp'} handleClick={() => handleUpload()}>Upload</Button>
                                            </StackItem>
                                        ) : (
                                            <StackItem>
                                                <Button disabled={!gridRows?.length || gridRows?.[0]?.ErrorCount || !gridMetaData?.isPristine} handleClick={() => handleSubmit()}>Submit</Button>
                                            </StackItem>
                                        )}
                                    </Stack>
                                </ModalActions>
                            </StackItem>
                        </Stack>
                    </SheetBody>
                </Sheet>
            </ReactModal>
        </>
    );
};

export default GridCsvUploadModal;
