// @ts-nocheck
import * as React from 'react';
import './accordion.css';
import StackItem from 'molecules/stackItem/stackItem';
import Stack from 'molecules/stack/stack';
import Divider from 'atoms/divider/divider';

interface Props {
  title: string;
  body: React.ReactElement;
  isActive: boolean;
  titleClickHandler?: Function;
}

const AccordionItem: React.FunctionComponent<any> = (props: Props) => {
  const { title, body, isActive, titleClickHandler } = props;

  const [collapse, updateCollapse] = React.useState(isActive || false);

  React.useEffect(() => {
    updateCollapse(isActive);
  }, [isActive]);

  const toggleCollapse = React.useCallback(
    (e: React.MouseEvent) => {
      updateCollapse(!collapse);
      if (titleClickHandler) {
        titleClickHandler();
      }
    },
    [collapse]
  );

  return (
    <div className="accordion--item">
      <Stack isVertical gutter="small">
        <StackItem>
          <div
            style={{ padding: '0px', width: '100%' }}
            onClick={toggleCollapse}>
            {title}
          </div>
        </StackItem>
        <StackItem isStretch>
          <Divider />
        </StackItem>
        {collapse ? <StackItem>{body}</StackItem> : <React.Fragment />}
      </Stack>
    </div>
  );
};

export default AccordionItem;
