// @ts-nocheck
import * as advertisingSagaActions from 'models/advertisings/sagaActions';
import qs from 'qs';
import { put, select, takeEvery } from 'redux-saga/effects';
import { deleteAPIData, deleteAPIDataV3 } from 'utils/api';
import * as slice from './reducer';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import * as sagaActions from './sagaActions';
import _ from 'lodash';
import { triggerNotification } from 'organisms/notifications/sagaActions';
import { TABLE_ID } from './constants';
import { GRID_EVENTS } from 'molecules/ReactGrid/agGrid/constants';
import { Events } from 'molecules/ReactGrid/agGrid/utils/events';
import { REQUESTS } from 'utils/requests';

const handleDeleteGroup = (payload): Promise<any> => {
	const groupId = payload;
	return deleteAPIDataV3({
      service: REQUESTS.DELETE.DELETE_GROUP.service,
      url: REQUESTS.DELETE.DELETE_GROUP.url,
      pathParams: {groupID: groupId}
    })
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true }));
};

function* deleteGroup({ payload }: any) {
	const { groupId, groups } = payload;
	const { response, isError } = yield handleDeleteGroup(groupId);
	if (isError) {
		yield put(triggerNotification({ message: 'Unable to delete, please try again later.' }));
		return;
	}

		Events.publish(GRID_EVENTS.DELETE_ROWS, {
			tableId: TABLE_ID,
			deletedRows: groups
		});
		return;
	yield put(dynamicGridSagaActions.fetchGridData(TABLE_ID));
}

export default function* rootSaga() {
	return [yield takeEvery(sagaActions.deleteGroup, deleteGroup)];
}
