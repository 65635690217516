// @ts-nocheck
import Heading from 'atoms/heading/heading';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';

const SelectBox = props => {
  const { selected, name, onChangeSelectBox } = props;

  return (
    <Stack gutter="small" position='center' align='center'>
      <StackItem>
        <Heading type="tertiary">{name}</Heading>
      </StackItem>

      <StackItem>
        <input
          type={'checkbox'}
          onChange={onChangeSelectBox}
          checked={selected}
          style={{ cursor: 'pointer' }}
        />
      </StackItem>
    </Stack>
  );
};

export default SelectBox;
