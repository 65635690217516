// @ts-nocheck
import { TTableTab } from './types';
export const PREFIX = 'availability';

export const AVAILABILITY = 7;
export const AVAILABILITY_SUMMARY = 25;

export const AVAILABILITY_TYPES_TABS: TTableTab[] = [
	{
		name: 'Availability',
		tableId: AVAILABILITY,
	},
	{
		name: 'Availability Summary',
		tableId: AVAILABILITY_SUMMARY,
	}
];

export const AVAILABILITY_MENU_NAMES = {
	CUSTOMIZE_VIEW: 'Customize View',
	RESET_VIEW: 'Reset View',
	UPLOAD_DATA: 'Upload Data',
	DELETE: 'Delete',
	// CREATE_KIT: 'Create Kits'
};

export const AVAILABILITY_MENU_VALUES = {
	CUSTOMIZE_VIEW: 'CUSTOMIZE_VIEW',
	RESET_VIEW: 'RESET_VIEW',
	UPLOAD_DATA: 'UPLOAD_DATA',
	DELETE: 'Delete',
	CREATE_KIT: 'Create_Kit'
};

export const AVAILABILITY_MENU = [
	
	{
		displayName: AVAILABILITY_MENU_NAMES.CUSTOMIZE_VIEW,
		value: AVAILABILITY_MENU_VALUES.CUSTOMIZE_VIEW
	},
	{
		displayName: AVAILABILITY_MENU_NAMES.RESET_VIEW,
		value: AVAILABILITY_MENU_VALUES.RESET_VIEW
	},
	{
		displayName: AVAILABILITY_MENU_NAMES.UPLOAD_DATA,
		value: AVAILABILITY_MENU_VALUES.UPLOAD_DATA
	},
	{
		displayName: AVAILABILITY_MENU_NAMES.DELETE,
		value: AVAILABILITY_MENU_VALUES.DELETE
	}
];

export const AVAILABILITY_SUMMARY_MENU = [
	{
		displayName: AVAILABILITY_MENU_NAMES.CUSTOMIZE_VIEW,
		value: AVAILABILITY_MENU_VALUES.CUSTOMIZE_VIEW
	},
	{
		displayName: AVAILABILITY_MENU_NAMES.RESET_VIEW,
		value: AVAILABILITY_MENU_VALUES.RESET_VIEW
	},
	{
		displayName: AVAILABILITY_MENU_NAMES.UPLOAD_DATA,
		value: AVAILABILITY_MENU_VALUES.UPLOAD_DATA
	}
];

export const SIDE_CAR_COMPONENTS = ['UPLOAD', 'INFO'];
