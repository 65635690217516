// @ts-nocheck
import { TFilter } from 'models/dynamicGrid/types';

export const TABLE_ID = 24;

export const INITIAL_FILTER: TFilter = {
  itemsPerPage: 50,
  pageNumber: 1,
  sortDirection: 'ASC',
  search: [],
  operator: 'AND',
};

export const MENU_ITEMS_NAMES = {
  UPDATE_SCHEDULE: 'Update Schedule',
  DELETE_SCHEDULE: 'Delete Current Schedule',
  CUSTOMIZE_VIEW: 'Customize View',
  RESET_VIEW: 'Reset View',
  UPDATE_SCHEDULE_DAYS: 'Update Selected Schedule Days',
};

export const MENU_ITEMS_VALUES = {
  UPDATE_SCHEDULE: 'UPDATE_SCHEDULE',
  DELETE_SCHEDULE: 'DELETE_SCHEDULE',
  CUSTOMIZE_VIEW: 'CUSTOMIZE_VIEW',
  RESET_VIEW: 'RESET_VIEW',
  UPDATE_SCHEDULE_DAYS: 'UPDATE_SCHEDULE_DAYS',
};

export const MENU_ITEMS = [
  {
    displayName: MENU_ITEMS_NAMES.UPDATE_SCHEDULE,
    value: MENU_ITEMS_VALUES.UPDATE_SCHEDULE,
  },
  {
    displayName: MENU_ITEMS_NAMES.DELETE_SCHEDULE,
    value: MENU_ITEMS_VALUES.DELETE_SCHEDULE,
  },
  {
    displayName: MENU_ITEMS_NAMES.CUSTOMIZE_VIEW,
    value: MENU_ITEMS_VALUES.CUSTOMIZE_VIEW,
  },
  {
    displayName: MENU_ITEMS_NAMES.RESET_VIEW,
    value: MENU_ITEMS_VALUES.RESET_VIEW,
  },
  {
    displayName: MENU_ITEMS_NAMES.UPDATE_SCHEDULE_DAYS,
    value: MENU_ITEMS_VALUES.UPDATE_SCHEDULE_DAYS,
  },
];

export const MODAL_MODES = {
  NEW: 'new',
  UPDATE: 'update',
};
