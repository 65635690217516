// @ts-nocheck
import Button from 'atoms/button/button';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as generateOrderSelectors from 'organisms/generateOrderModal/selectors';
import React, { useState } from 'react';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import * as constants from './constants';
import { useDispatch, useSelector } from 'react-redux';
import * as sagaActions from '../sagaActions';
import { REQUESTS } from 'utils/requests';

type TProps = {
	hideModal: React.MouseEventHandler;
	handleNextClick: React.MouseEventHandler;
	goBack: React.MouseEventHandler;
};

const SelectStores = (props: TProps) => {
	const { mode, data } = props;
	const dispatch = useDispatch();

	const selectedStores = useSelector(state =>
		dynamicGridSelectors.getTableSelection(state, constants.MODAL_TABLE_IDS.STORES)
	);

	const selectedStoresSearch = useSelector(state =>
		dynamicGridSelectors.getGridFilters(state, constants.MODAL_TABLE_IDS.STORES)
	);
	const [ref, handleUpdateRef] = useState(null);

	const gridConfig = {
		enableSelection: true,
		enableSorting: true,
		enableMultiSort: true,
		selectionCallBack: () => {}
	};

	const validateForm = () => {
		if (!selectedStores || (!selectedStores?.selectedRowKeys?.length && !selectedStores?.isSelectAll)) {
			return false;
		}

		return true;
	};

	const submitHandler = React.useCallback(() => {
		let schedule: any = {
			storeSchedule: data.storeSchedule,
			days: _.map(data?.selectedDays, 'value'),
			stores: selectedStores?.selectedRowKeys,
			selectAllSearch: selectedStoresSearch.search,
			isSelectAll: selectedStores.isSelectAll
		};

		if (mode === constants.MODAL_MODES.NEW) {
			dispatch(sagaActions.createSchedule(schedule));
		} else {
			schedule = { ...schedule, storeScheduleId: data?.storeScheduleId };
			dispatch(sagaActions.updateSchedule(schedule));
		}
	}, [data, selectedStores, selectedStoresSearch]);

	const templateConfig = {
		gridConfig,
		isFilterEnabled: true,
		tableId: constants.MODAL_TABLE_IDS.STORES,

		dataProviderOptions: {
			version: 2,
			type: 'POST',
			queryParams: {},
			...REQUESTS.POST.GET_GRID_DETAILS_V2,
			pathParams: { tableId: constants.MODAL_TABLE_IDS.STORES },
		},
		selectRowKey: 'StoreID'
	};

	return (
		<>
			<ModalBody onDomRef={handleUpdateRef}>
				<ThetaGridPaginated isStorageEnabled={false} parent={ref} config={templateConfig} />
			</ModalBody>
			<ModalActions>
				<Stack>
					<StackItem isGrow>
						<Stack align="left" gutter="small">
							<StackItem>
								<Button isSecondary name="Edit Store Schedule Details" handleClick={props.goBack} />
							</StackItem>
						</Stack>
					</StackItem>
					<StackItem>
						<Stack align="right" gutter="small">
							<StackItem>
								<Button isSecondary name="CANCEL" handleClick={props.hideModal} />
							</StackItem>
							<StackItem isStretch align="right">
								<Button disabled={!validateForm()} name={'Submit'} handleClick={submitHandler} />
							</StackItem>
						</Stack>
					</StackItem>
				</Stack>
			</ModalActions>
		</>
	);
};

export default SelectStores;
