// @ts-nocheck
import Card from 'atoms/card/card';
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import Icon from 'atoms/icons/icon';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import * as tableColumnSagaActions from 'models/tableColumns/sagaActions';
import * as tableColumnSelectors from 'models/tableColumns/selector';
import SideCar from 'molecules/sidecar/sidecar';
import Snackbar from 'molecules/snackbar/snackbar';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import CustomizeViewModal from 'organisms/CustomizeViewModal';
import ThetaMenu from 'organisms/thetaMenu/thetaMenu';
import UploadData from 'organisms/uploadData';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import * as constants from './constants';
import Breadcrumbs from 'atoms/breadcrumbs/breadcrumbs';
import ThetaDeleteModal from 'organisms/deleteModal/deleteModal';
import ExportGrid from 'organisms/exportGrid';
import ToggleAgGrid from 'organisms/toggleAgGrid';
import { REQUESTS } from 'utils/requests';
import { isMasterDataItemsWrite, isPlanogramRestrictionsWriteEnabled } from 'models/user/selector';
import { isMasterDataItemsDelete } from '../../../models/user/selector';
import GridActionBar from 'atoms/GridActionBar';
import GridCsvUpload from 'organisms/uploadData/gridCsvUpload';
import { isNewUploadEnabled } from 'models/user/selector';
import Button from 'atoms/button/button';
// import AttributesDetail, { AttributeDetails } from './attributeDetails';
import Tab from 'atoms/tab/tab';
import Tabs from 'atoms/tabs/tabs';
import { TABS } from './constants';
import { useMemo } from 'react';
import ReactModal from 'atoms/modal/reactModal';
import SelectStores from './selectStores';
import AddAttributes from './addAttributes';
import { getIsStoresEnabled } from './selectors';
import { setIsStoresEnabled } from './reducer';
import  GridCsvUploadModal  from 'organisms/uploadData/gridCsvUploadModal';


const ITEMS_MODAL = 'items-modal';

const PlanogramDetails: React.FunctionComponent = props => {
	const [openFilter, setOpenFilter] = React.useState<boolean>();
	const [isUploadEnabled, toggleUpload] = React.useState<boolean>(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { breadcrumbs } = props;
	const query = window.location.pathname?.split('/');
	const [planogramId, setId] = React.useState(query[query.length - 1]);
	const [activeTab, setActiveTab] = React.useState(TABS[0]?.value)
	const hasWriteAccess = useSelector(isMasterDataItemsWrite);
	const hasDeleteAccess = useSelector(isMasterDataItemsDelete);
	const hasSearchFilters = useSelector(state => dynamicGridSelectors.hasSearchFilters(state, constants.PLANOGRAM_ATTRIBUTES_TABLE_ID));
	const isStoreUpdateEnabled = useSelector(getIsStoresEnabled)
	const [isAttributeUpdateEnabled, setAttributeUpdateEnabled] = React.useState(false);
	const [isDeleteModal, toggleDeleteModal] = React.useState(false);
	const gridMeta = useSelector(state => dynamicGridSelectors.getGridMetadata(state, activeTab));
	const [isAttributeDetails, toggleAttributeDetails] = React.useState(false);
	const tableId = constants.PLANOGRAM_ATTRIBUTES_TABLE_ID.toString();
	const isCustomizeViewModalEnabled = useSelector(state => tableColumnSelectors.isCustomizeViewModalEnabled(state));
	// React.useEffect(() => {
	//   dispatch(tableColumnSagaActions.fetchTableColumns(constants.PLANOGRAM_ATTRIBUTES_TABLE_ID));
	// }, [dispatch, tableId]);

	const handleFilterClick = React.useCallback(() => {
		setOpenFilter(!openFilter);
	}, [openFilter]);

	const handleRefreshClick = React.useCallback(() => {
		dispatch(
			dynamicGridSagaActions.refreshGridData({
				tableId: activeTab
			})
		);
	}, [activeTab, dispatch]);

	const handleBackClick = React.useCallback(() => {
		navigate(`/masterData`);
	}, [navigate]);

	const handleResetTableColumns = React.useCallback(async () => {
			dispatch(tableColumnSagaActions.resetTableColumnsAg(constants.PLANOGRAM_ATTRIBUTES_TABLE_ID));
	}, [dispatch, tableId]);

	const customizeViewHandler = React.useCallback((isCustomizeViewModalEnabled: boolean) => {
		dispatch(tableColumnSagaActions.setCustomizeViewModal(isCustomizeViewModalEnabled));
	}, []);

	const handleMenuItemClick = React.useCallback(
		(option: string) => {
			switch (option) {
				case constants.MENU_ITEMS_VALUES.CUSTOMIZE_VIEW:
					return customizeViewHandler(true);
				case constants.MENU_ITEMS_VALUES.RESET_VIEW:
					return handleResetTableColumns();
				case constants.MENU_ITEMS_VALUES.UPLOAD_DATA:
					return toggleUpload(true);
				case constants.MENU_ITEMS_VALUES.DELETE:
					return deleteItems();
				default:
					return;
			}
		},
		[handleResetTableColumns]
	);

	const selectedItems = useSelector(state => dynamicGridSelectors.getTableSelection(state, constants.PLANOGRAM_ATTRIBUTES_TABLE_ID));
	const getDisabledStatus = React.useCallback(
		(option: any) => {
			switch (option.value) {
				case constants.MENU_ITEMS_VALUES.CUSTOMIZE_VIEW:
				case constants.MENU_ITEMS_VALUES.RESET_VIEW:
				case constants.MENU_ITEMS_VALUES.UPLOAD_DATA:
					return false;
				case constants.MENU_ITEMS_VALUES.DELETE:
					return !selectedItems?.selectedRowKeys?.length;
				default:
					return true;
			}
		},
		[selectedItems]
	);

	const hideCustomizeViewModal = React.useCallback(() => {
		customizeViewHandler(false);
	}, []);

	const uploadCloseClickHandler = React.useCallback(() => {
		toggleUpload(false);
	}, []);

	const handleBulkDeleteClick = () => {
		dispatch(dynamicGridSagaActions.handleBulkRowDelete({ tableId: constants.PLANOGRAM_ATTRIBUTES_TABLE_ID }));
		toggleDeleteModal(false);
	};

	const deleteItems = () => {
		toggleDeleteModal(true);
	};
	const isNewUpload = useSelector(isNewUploadEnabled);

	



	const gridConfig = {
		enableSelection: true,
		enableSorting: true,
		enableMultiSort: true,
		gridActions: ['delete'],
		selectionCallBack: () => { }
	};
	const isPlanogramWrite = useSelector(isPlanogramRestrictionsWriteEnabled);

	const templateConfig = useMemo(() => ({
		gridConfig,
		tableId: activeTab,

		dataProviderOptions: {
			type: 'POST',
			...REQUESTS.POST.GET_GRID_DETAILS_V2,
			pathParams: { tableId: activeTab },
			multiSelectFieldGroups: {
				PlanogramID: planogramId,
        HasAttachedStores: true
			},
			body: {
				
				deleteActionFieldGroups: {
					PlanogramID: planogramId
				},
				fieldGroups: {
					PlanogramID: planogramId,
					...(constants.PLANOGRAM_STORES_TABLE_ID === activeTab ? { HasAttachedStores: true } : {})
				},
			},
			version: 2
		},
		selectRowKey: constants.PLANOGRAM_STORES_TABLE_ID === activeTab ? 'StoreID': 'PlanogramAttributeDistributionID'
	}), [ activeTab]);

	return (
		<>
			<Stack isVertical>

				<StackItem>
					<GridActionBar>
						<Stack gutter="medium" align="right">

							{isNewUpload && isPlanogramWrite && gridMeta?.UploadTableID && <StackItem>
								<GridCsvUpload uploadTableId={gridMeta?.UploadTableID} parentTableId={tableId} />
							</StackItem>}
							<StackItem>
								<Icon isActive type="secondary" faAlias="faRecycle" onClick={handleRefreshClick} />
							</StackItem>

							<StackItem>
								<ExportGrid tableId={constants.PLANOGRAM_ATTRIBUTES_TABLE_ID} />
							</StackItem>
							<StackItem>
								<ThetaMenu
									type="3Dots"
									options={
										hasWriteAccess ? constants.MENU_ITEMS : [constants.MENU_ITEMS[0]]
									}
									handleItemClick={handleMenuItemClick}
									isDisabled={getDisabledStatus}
								/>
							</StackItem>
						</Stack>
					</GridActionBar>
				</StackItem>
				<StackItem isStretch isGrow>
					<Sheet isPage>

						<SheetBody>
							<Stack isVertical gutter="small">
								<StackItem>
									<Stack>
										<StackItem>
											<Breadcrumbs crumbs={breadcrumbs} />
										</StackItem>
										<StackItem>
											: #{planogramId}
										</StackItem>
									</Stack>

								</StackItem>
								<StackItem isStretch>
									<Tabs>
										{_.map(TABS, (tab: { name: string; value: string }, index) => {
											return (
												<Tab
													key={index}
													value={tab.value}
													isSelected={activeTab === tab.value}
													onSelectTabItem={() => { setActiveTab(tab.value) }}
													displayName={tab.name}
												>
													{tab.name}
												</Tab>
											);
										})}
									</Tabs>
								</StackItem>
								<StackItem isStretch isGrow>
									<Sheet isPage>
										<Stack isVertical gutter="tiny">

											<StackItem align="right" isStretch>
												{activeTab === TABS?.[1]?.value ? <Button size="small" name={'Create Planogram Attribute'} handleClick={() => setAttributeUpdateEnabled(true)}>
												{isPlanogramWrite && <Stack align="space-between" gutter="small" position="center">
												<StackItem isGrow align="center" >
															Add Attributes

														</StackItem> 
														<StackItem isStretch>
															<Divider size="small" isVertical></Divider>
														</StackItem>
														<StackItem>
															<Icon disabled={false} isPadded={false} faAlias="faPlus" size="tiny" />
														</StackItem>
													</Stack>}
												</Button>
													:
													<Button size="small" name={'Create Planogram Attribute'} handleClick={() =>{
															dispatch(setIsStoresEnabled({
																isStoresEnabled: true
															}))
														}}>
														<Stack align="space-between" gutter="small" position="center">
														{isPlanogramWrite && <StackItem isGrow align="center" >
																Add Stores

															</StackItem>}
															<StackItem isStretch>
																<Divider size="small" isVertical></Divider>
															</StackItem>
															<StackItem>
																<Icon disabled={false} isPadded={false} faAlias="faPlus" size="tiny" />
															</StackItem>
														</Stack>
													</Button>
												}
											</StackItem>
											<StackItem isGrow isStretch>
												<ThetaGridPaginated config={templateConfig} />
											</StackItem>
										</Stack>
									</Sheet>
								</StackItem>
							</Stack>
						</SheetBody>
					</Sheet>

				</StackItem>

			</Stack>

			{isStoreUpdateEnabled && <ReactModal height={700} width={700}>
				<SelectStores hideModal={() => {
							dispatch(setIsStoresEnabled({
								isStoresEnabled: false
							}))
				}} planogramId={planogramId} />
			</ReactModal>}
			{isAttributeUpdateEnabled &&
                <GridCsvUploadModal fieldGroups={{
					PlanogramID: planogramId
				}}
			
				hasDataChangeType={false} onUploadComplete={()=>{
					setAttributeUpdateEnabled(false);
                }} title={'Update Planogram - Add Attributes'} uploadTableId={100} parentTableId={93} onClose={() => {
                    setAttributeUpdateEnabled(false);
                }} {...props} />
            }

			{isDeleteModal && (
				<ThetaDeleteModal
					hideModal={() => {
						toggleDeleteModal(false);
					}}
					
					modalTitle={'ITEMS'}
					title={` (${selectedItems?.isSelectAll ? 'All' : selectedItems?.selectedRowKeys?.length
						}) selected  item(s)`}
					handleDeleteClick={handleBulkDeleteClick}
					isLocked={true}
				/>
			)}
			{/* {isAttributeDetails && <AttributeDetails onHide={() => toggleAttributeDetails(false)} />} */}
		</>
	);
};
export default PlanogramDetails;
