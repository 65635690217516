// @ts-nocheck

import React, { useState } from 'react';
import _ from 'lodash';
import { Button, notification, Divider, Space } from 'antd';
import {
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getNotifications } from './selectors';
import { useEffect } from 'react';
import { updateNotifications } from './reducer';
import { triggerNotification, removeNotification, markAsRead } from './sagaActions';

const Context = React.createContext({ name: 'Default' });

const Notification = () => {
	const dispatch = useDispatch();
	const [api, contextHolder] = notification.useNotification();
	const [notifications, updateNotifications] = useState([]);
	const updatedNotifications = useSelector(state => getNotifications(state));

	useEffect(() => {
		updateNotifications(updatedNotifications);
		if (notifications?.length < updatedNotifications?.length) {
			for (let i = 0; i < updatedNotifications.length; i++) {
				dispatch(markAsRead({ notification: updatedNotifications[i] }));
				if (!updatedNotifications[i]?.isRead) {
					notification[updatedNotifications[i]?.type || 'error']({
						message: updatedNotifications[i]?.type?.toUpperCase() || 'ERROR',
						description:
							updatedNotifications[i]?.message || 'Something went wrong, please try again later.',
						onClick: () => {},
						duration: updatedNotifications[i].isPersistent ? 0 : updatedNotifications[i]?.duration || 5,
						onClose: () => {
							//
							dispatch(removeNotification({ id: updatedNotifications[i].id }));
						},
						autoClose: updatedNotifications[i]?.autoClose || 2000,
					});
				}
			}
		}
	}, [notifications, updatedNotifications, dispatch]);

	return <Context.Provider value={{ name: 'Ant Design' }}>{contextHolder}</Context.Provider>;
};

export default Notification;
