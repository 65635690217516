// @ts-nocheck
import * as React from 'react';
import classNames from 'classnames';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import './masterDataListItem.scss';
import Button from 'atoms/button/button';
import Icon from 'atoms/icons/icon';
import Label from 'atoms/label/label';
import BodyText from 'atoms/bodyText/bodyText';
interface Props extends React.PropsWithChildren<any> {
	title: string;
	description: string;
	buttonText: string;
	handleButtonClick: any;
	itemIdentifier: string;
	disabled: Boolean;
}

const MasterDataListItem: React.FunctionComponent<Props> = (props: Props) => {
	const { title, description, buttonText, handleButtonClick, itemIdentifier, disabled } = props;
	const classes: any = classNames(['masterDataListItem']);
	const handleMasterDataButtonClick = () => {
		handleButtonClick(itemIdentifier);
	};
	return (
		<div className={classes} onClick={handleMasterDataButtonClick}>
			<Stack gutter="medium">
				<StackItem isGrow>
					<Stack gutter="small" isVertical>
						<StackItem>
							<Label isHighlight>{title}</Label>
						</StackItem>
						<StackItem>
							<BodyText isWrap>{description}</BodyText>
						</StackItem>
					</Stack>
				</StackItem>
				<StackItem position="center">
					{/* <Button
						isSecondary
						name={buttonText}
						handleClick={handleMasterDataButtonClick}
						disabled={disabled}
					/> */}
					{buttonText === 'VIEW' ? (
						<Icon isActive type="secondary" onClick={handleMasterDataButtonClick} faAlias="faEye"></Icon>
					) : (
						<Icon isActive type="secondary" onClick={handleMasterDataButtonClick} faAlias="faEdit"></Icon>
					)}
				</StackItem>
			</Stack>
		</div>
	);
};
export default MasterDataListItem;
