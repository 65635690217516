// @ts-nocheck
import * as React from 'react';
import './textInput.scss';
import classNames from 'classnames';
import { DatePicker } from 'antd';
import { formatDate, formatDateTz } from 'utils/utilities';
interface Props {
	value?: string;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
	size?: string;
	placeholder?: string;
	name?: string;
	type?: string;
	max?: number;
	min?: number;
}

const TextInput = (props: Props) => {
	const { value, onChange, onKeyDown, size, max, min, placeholder, name, type = 'string', disabled } = props;
	const classes: any = classNames(['text-input'], {
		[`text-input--${size}`]: true,
		[`text-input--agGrid`]: true
	});

	if (type == 'date') {
		return (
			<div className="antd-date-picker">
				<DatePicker
					value={value && formatDateTz(value)}
					// value={formatDate(value)}
					onChange={e => onChange(e, type)}
					size={size}
					format={'L'}
					allowClear={false}
				/>
			</div>
		);
	}
	let updatedType = type === 'price' || type === 'qty' || type === 'number' ? 'number' : 'text';
	return (
		<input
			name={name || ''}
			value={value}
			type={updatedType}
			className={classes}
			disabled={disabled}
			max={max}
			min={min}
			onChange={e => onChange(e, type)}
			onKeyDown={onKeyDown}
			placeholder={placeholder || ''}
		/>
	);
};

export default TextInput;
