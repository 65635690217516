// @ts-nocheck
import { REQUEST_STATUS } from 'models/loaders/constants';
import { initialState, name } from './reducer';

const appSelectorState = state => state['availableApps'] || initialState;

export const getAvailableApps = (state, componentId) => appSelectorState(state).apps;
export const getAvailableWorkflows = (state, componentId) => appSelectorState(state).workflows ?? [];
export const getSelectedApp = (state, componentId) => appSelectorState(state).app.selected;
export const getSelectedWorkflow = (state, componentId) => appSelectorState(state)?.workflow?.selected || null;
export const isLoadingAvailableAppsAndWorkflows = (state, componentId) =>
	appSelectorState(state).status === REQUEST_STATUS.PENDING || appSelectorState(state).status === REQUEST_STATUS.NONE;
