// @ts-nocheck
import _ from 'lodash';
import qs from 'qs';
import { put, select, takeEvery } from 'redux-saga/effects';
import { getAPIData, getAPIDataV2, deleteAPIData, postAPIDataV3 } from 'utils/api';
import * as slice from './reducer';
import * as sagaActions from './sagaActions';
import * as selector from './selector';
import { TMetadata } from './types';
import { triggerNotification } from 'organisms/notifications/sagaActions';

type TPayload = {
	payload: {
		name: string;
		url?: string;
		metaData?: TMetadata;
	};
};

const getData = ({ url, method, service, version, body, query }): Promise<any> => {
	const apiQuery = {
		...query,
		refresh: new Date().getTime().toString()
	};

	if (method === 'POST' && version === 2) {
		return postAPIDataV3({
			service: service, queryParams: {
				apiQuery
		}, url, body: body})
			.then(response => ({ response: response?.data }))
			.catch(error => ({ error, isError: true }));
	}

	return getAPIData(`${url}?${qs.stringify(apiQuery)}`)
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true }));
};

export default function* rootSaga() {
	function* loadDynamicList({ payload }: TPayload) {
		const { url } = payload;
		if (!url) {
			console.error('load advertising url not found');
			return;
		}
		const metaData: TMetadata = {
			dataURL: url
		};
		const listData = yield getData(payload);
	}

	return [yield takeEvery(sagaActions.loadDynamicList, loadDynamicList)];
}
