// @ts-nocheck
import * as React from 'react';
import './sidebar.scss';
import classNames from 'classnames';
import _ from 'lodash';
// import SidebarItem from './sidebarItem';
// import Stack from 'molecules/stack/stack';
// import StackItem from 'molecules/stackItem/stackItem';

interface Props extends React.PropsWithChildren<any> {
	isExpanded?: boolean;
	isSecondary?: boolean;
	menuItems?: Array<any>;
}

const Sidebar: React.FunctionComponent<Props> = (props: Props) => {
	const { isExpanded, children, isSecondary, height } = props;
	const classes: any = classNames([
		'sidebar',
		{
			'sidebar--expanded': isExpanded,
			'sidebar--secondary': isSecondary
		}
	]);

	return (
		<aside className={classes} style={{ height: `${height}px` }}>
			{children}
		</aside>
	);
};

const defaultProps: Props = {
	isExpanded: false
};

Sidebar.defaultProps = defaultProps;

export default Sidebar;
