// @ts-nocheck
import Button from 'atoms/button/button';
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import Modal from 'atoms/modal/modal';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import TextInput from 'atoms/TextInput/textInput';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as adModalSagaActions from 'pages/advertising/adModal/sagaActions';
import * as adModalSelectors from 'pages/advertising/adModal/selector';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as advertisingSelectors from 'models/advertisings/selector';
import Loader from 'atoms/loader';

const MasterDataAdTypeModal = props => {
	const { hideModal } = props;
	const [title, setTitle] = React.useState('');
	const dispatch = useDispatch();

	const adTypeData: any = useSelector(state => adModalSelectors.getAdModalTypeData(state)) || {};
	const isEditMode: boolean = useSelector(state => adModalSelectors.getEditMode(state)) || false;
	const isLoading = useSelector(state => advertisingSelectors.isLoading(state, 'adTypesModal'));
	const handleSaveChanges = async () => {
		try {
			if (isEditMode) {
				let data = {
					AdDescription: '',
					AdID: adTypeData.AdTypeID,
					AdTitle: title,
					AdType: title,
					AdTypeID: adTypeData.AdTypeID,
					name: adTypeData.AdType,
					value: title
				};
				dispatch(adModalSagaActions.updateAdType('adType', data));
			} else {
				dispatch(adModalSagaActions.saveAdType(title));
			}
		} catch (e) {
			console.error(e);
		}
	};

	React.useEffect(() => {
		setTitle(adTypeData.AdType);
	}, [adTypeData]);

	const renderLoader = () => {
		return (
			<Stack isVertical>
				<StackItem isGrow isStretch align="center">
					<Loader />
				</StackItem>
			</Stack>
		);
	};
	return (
		<Modal hideModal={hideModal} height={300}>
			<Sheet isInset>
				<SheetBody>
					<Stack isVertical gutter="small">
						<StackItem isStretch>
							<Heading>{isEditMode ? 'Edit Ad Type' : 'Create New Ad Type'}</Heading>
						</StackItem>
						<StackItem isStretch>
							<Divider />
						</StackItem>
						{isLoading ? (
							renderLoader()
						) : (
							<StackItem isGrow isStretch>
								<Stack isVertical align="center" gutter="small">
									<StackItem align="left">
										<Heading type="tertiary"> TITLE* </Heading>
									</StackItem>
									<StackItem>
										<TextInput
											size="medium"
											value={title}
											onChange={e => setTitle(e.target.value)}
											placeholder={'Tile of Ad Type'}
										/>
									</StackItem>
								</Stack>
							</StackItem>
						)}

						<StackItem isStretch>
							<Stack gutter="small" align="right">
								<StackItem>
									<Button
										disabled={isLoading}
										isSecondary
										name="CANCEL CHANGES"
										handleClick={hideModal}
									/>
								</StackItem>
								<StackItem>
									<Button
										disabled={!title || isLoading}
										name={'SAVE CHANGES'}
										handleClick={handleSaveChanges}
									/>
								</StackItem>
							</Stack>
						</StackItem>
					</Stack>
				</SheetBody>
			</Sheet>
		</Modal>
	);
};

export default MasterDataAdTypeModal;
