// @ts-nocheck
import { Action, AnyAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const DEFAULT_PAGINATION = {
	perPage: 50,
	pageNo: 1,
	sortDirection: 'ASC'
	//   sortField: ''
};

const BULK_UPLOAD_PAGINATION = {
	perPage: 50,
	pageNo: 1
};

export const initialState = {
	28: {
		...DEFAULT_PAGINATION
	},
	19: {
		...DEFAULT_PAGINATION
	},
	26: {
		...DEFAULT_PAGINATION
	},
	20: {
		...DEFAULT_PAGINATION
	},
	23: {
		...DEFAULT_PAGINATION
	},
	1: {
		...DEFAULT_PAGINATION
	},
	2: {
		...DEFAULT_PAGINATION
	},
	7: {
		...DEFAULT_PAGINATION
	},
	8: {
		...DEFAULT_PAGINATION
	},
	9: {
		...DEFAULT_PAGINATION
	},
	25: {
		...DEFAULT_PAGINATION
	},
	24: {
		...DEFAULT_PAGINATION
	},
	30: {
		...DEFAULT_PAGINATION
	},
	bulkUpload: {
		...BULK_UPLOAD_PAGINATION
	},
	29: {
		...DEFAULT_PAGINATION
	},
	21: {
		...DEFAULT_PAGINATION
	},
	27: {
		...DEFAULT_PAGINATION
	},
	10: {
		...DEFAULT_PAGINATION
	},
	18: {
		...DEFAULT_PAGINATION
	},
	22: {
		...DEFAULT_PAGINATION
	},
	17: {
		...DEFAULT_PAGINATION
	},
	16: {
		...DEFAULT_PAGINATION
	},
	15: {
		...DEFAULT_PAGINATION
	},
	14: {
		...DEFAULT_PAGINATION
	},
	13: {
		...DEFAULT_PAGINATION
	},
	12: {
		...DEFAULT_PAGINATION
	},
	11: {
		...DEFAULT_PAGINATION
	}
};

interface RejectedAction extends Action {
	error: Error;
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
	return action.type.endsWith('rejected');
}
const slice = createSlice({
	name: 'pagination',
	initialState,
	reducers: {
		createUpdatePagination(state, { payload: { tableId, pageNo, perPage, sortDirection, sortField } }) {
			return {
				...state,
				[tableId]: {
					...state[tableId],
					..._.pickBy(
						{
							pageNo,
							perPage,
							sortDirection,
							sortField
						},
						_.identity
					)
				}
			};
		}
	}
});

export default slice;

export const { createUpdatePagination } = slice.actions;
