// @ts-nocheck
import { initialState } from './reducer';
import * as constants from './constants';

const getStateData = (state: any) => state[constants.MODEL_NAME] || initialState;

export const isLoading = (state: any, eventName: string): boolean => {
	const reducer = getStateData(state);

	return reducer.isLoading[eventName];
};

export const getAdsData = (state: any, name: string): any[] => {
	const reducer = getStateData(state);

	const data = reducer.data[name];

	return data;
};

export const getAdTabs = state => getStateData(state).adTabs;

export const getAdsMetadata = (state: any, name: string): any => {
	const reducer = getStateData(state);
	return reducer.metaData[name];
};

export const getCustomers = (state: any, name: string): any[] => {
	const reducer = getStateData(state);
	const data = reducer.customers[name];
	return data;
};

export const getAdTypes = (state: any): any => {
	const reducer = getStateData(state);
	return reducer.adTypes;
};

export const fetchStores = (state: any, name: string): any => {
	const reducer = getStateData(state);
	return reducer.stores[name];
};
