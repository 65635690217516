// @ts-nocheck
import Button from 'atoms/button/button';
import Heading from 'atoms/heading/heading';
import ModalActions from 'atoms/modal/modalActions';
import ModalBody from 'atoms/modal/modalBody';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import * as adModalSelectors from 'pages/advertising/adModal/selector';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from 'utils/utils';

import { AD_MODAL_TABLE_IDS } from './constants';
import { createNewAdvertising } from './sagaActions';
import { Tprops } from './types';

const ReviewAd = (props: Tprops) => {
	const { hideModal, handleNextClick, goBack } = props;
	const dispatch = useDispatch();

	const selectedStores = useSelector(state =>
		dynamicGridSelectors.getTableSelection(state, AD_MODAL_TABLE_IDS.STORES)
	);
	const selectedSkus = useSelector(state => dynamicGridSelectors.getTableSelection(state, AD_MODAL_TABLE_IDS.SKUS));
	const pricingTableData =
		useSelector(state => dynamicGridSelectors.getGridData(state, AD_MODAL_TABLE_IDS.ENTER_PRICING)) || [];
	const adData: any = useSelector(state => adModalSelectors.getAdModalData(state, 'advertising')) || {};
	const isEditMode: boolean = useSelector(state => adModalSelectors.getEditMode(state)) || false;
	const editData: any = useSelector(state => adModalSelectors.getEditData(state)) || {};
	const getInputData = (attribute: string) => {
		let inputData = [];
		for (let item of pricingTableData) {
			if (item[attribute]) {
				inputData.push(item[attribute].toString());
			}
		}
		return inputData.join(',');
	};

	const handleSaveAdClick = async (e: any) => {
		let data = {
			...adData,
			AdCostList: getInputData('AdCost'),
			MinOrderQtyList: getInputData('MinOrderQty'),
			SKUIDList:
				selectedSkus &&
				selectedSkus?.selectedRowKeys &&
				selectedSkus?.selectedRowKeys.length &&
				selectedSkus?.selectedRowKeys.join(','),
			StoreIDList:
				selectedStores &&
				selectedStores?.selectedRowKeys &&
				selectedStores?.selectedRowKeys.length &&
				selectedStores?.selectedRowKeys.join(',')
		};
		if (isEditMode) {
			dispatch(createNewAdvertising({ AdID: editData[0].AdID }));
		} else {
			dispatch(createNewAdvertising());
		}
		// handleNextClick(e);
	};

	return (
		<>
			<ModalBody>
				<Stack isVertical gutter="medium">
					<StackItem isStretch isGrow>
						<Stack isVertical gutter="medium">
							<StackItem isStretch>
								<Heading type="secondary">
									Your ad starts on {formatDate(adData.StartDate)} and ends on{' '}
									{formatDate(adData.EndDate)}.
								</Heading>
							</StackItem>
							<StackItem isStretch>
								<Heading type="secondary">
									This ad is for {selectedSkus?.selectedRowKeys?.length} SKUs in{' '}
									{selectedStores?.selectedRowKeys?.length} store.
								</Heading>
							</StackItem>
						</Stack>
					</StackItem>
				</Stack>
			</ModalBody>
			<ModalActions>
				<Stack gutter="medium">
					<StackItem isStretch isGrow>
						<Stack align="left" gutter="small">
							<StackItem>
								<Button isSecondary name="RE-SELECT PRICING" handleClick={goBack} />
							</StackItem>
						</Stack>
					</StackItem>
					<StackItem>
						<Stack align="right" gutter="small">
							<StackItem>
								<Button isSecondary name="CANCEL" handleClick={hideModal} />
							</StackItem>
							<StackItem isStretch align="right">
								<Button name={'SAVE AD'} handleClick={handleSaveAdClick} />
							</StackItem>
						</Stack>
					</StackItem>
				</Stack>
			</ModalActions>
		</>
	);
};

export default ReviewAd;
