// @ts-nocheck
import BodyText from 'atoms/bodyText/bodyText';
import Button from 'atoms/button/button';
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import Modal from 'atoms/modal/modal';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import React from 'react';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import { useDispatch, useSelector } from 'react-redux';
import ModalHeader from '../common/modalHeader';
import ModalActions from '../common/modalActions';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import _ from 'lodash';
import Dropdown from 'atoms/dropdown/dropdown';
import * as sagaActions from '../sagaActions';
import * as selectors from '../selectors';
import Loader from 'atoms/loader';
import { KEYS } from '../actionBar/constants';
interface TProps {
	hideModal: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
	selectedTab: string;
}

const getCurrentOrderStatus = (selectedOrders: any[], orders: any, selectedTab: any) => {
	let filteredOrders: any = [];
	selectedOrders.forEach(singleSelection => {
		let filteredOrder = _.filter(orders, function (singleOrder) {
			return singleOrder[KEYS[selectedTab]] === singleSelection;
		});
		filteredOrders.push(filteredOrder[0]);
	});
	let currentStatuses = filteredOrders && filteredOrders.map(order => order?.OrderStatus);
	currentStatuses = _.compact(_.uniq(currentStatuses));
	return currentStatuses.join(',');
};

const ModalContent = ({
	selectedOrders,
	orders,
	dropdownTypes,
	setSelectedOption,
	selectedOption,
	selectedTab
}: any) => {
	return (
		<Stack isVertical gutter="medium">
			<StackItem>
				<BodyText>Change Order Status - {selectedOrders?.isSelectAll ? 'All' : selectedOrders?.selectedRowKeys?.length} selected orders.</BodyText>
			</StackItem>
			<StackItem>
				<Stack gutter="medium">
					<StackItem isStretch>
						<BodyText>Current:</BodyText>
					</StackItem>
					<StackItem isStretch>
						<BodyText>{getCurrentOrderStatus(selectedOrders?.selectedRowKeys, orders, selectedTab)}</BodyText>
					</StackItem>
				</Stack>
			</StackItem>
			<StackItem>
				<Stack gutter="medium">
					<StackItem isStretch>
						<BodyText>New:</BodyText>
					</StackItem>
					<StackItem>
						<Dropdown
							placeholder={'Select Item'}
							options={dropdownTypes}
							updateHandler={setSelectedOption}
							selectedOption={selectedOption}
						/>
					</StackItem>
				</Stack>
			</StackItem>
		</Stack>
	);
};

const ThetaUpdateOrderStatusModal = (props: TProps) => {
	const { hideModal, selectedTab } = props;
	const dispatch = useDispatch();
	const dropdownOptionTypes = useSelector(state => selectors.getUpdateStatusDefaultStores(state)) || [];
	const dropdownTypes = dropdownOptionTypes.map(order => {
		return { displayName: order?.OrderStatus, value: order?.OrderStatusID };
	});
	const [selectedOption, setSelectedOption] = React.useState(dropdownTypes[0]);

	const selectedOrders = useSelector(state => dynamicGridSelectors.getTableSelection(state, Number(selectedTab)));
	const orders = useSelector(state => dynamicGridSelectors.getGridData(state, Number(selectedTab)));
	React.useEffect(() => {
		dispatch(sagaActions.getDefaultOrderStatus());
	}, [dispatch]);

	const isLoading = useSelector(state => selectors.getLoadingStatus(state));

	const getOrderIds = () => {
		if (Number(selectedTab) == 30) {
			let filteredOrders = _.filter(orders, singleOrder =>
				selectedOrders?.selectedRowKeys.includes(singleOrder.OrderKitID)
			);
			filteredOrders = filteredOrders.map(singleOrder => singleOrder.OrderID);
			return filteredOrders.join(',');
		} else {
			return selectedOrders?.selectedRowKeys.join(',');
		}
	};

	const handleUpdateOrder = (e: any) => {
		const data = {
			orderId: getOrderIds(),
			statusId: selectedOption.value
		};
		dispatch(sagaActions.updateOrderStatus(data, selectedTab));
	};
	const getUpdateDisabledStatus = () => {
		return !selectedOption || !selectedOption.value;
	};
	return (
		<Modal hideModal={hideModal} height={400}>
			<Sheet isInset>
				<SheetBody>
					<Stack isVertical gutter="small">
						<StackItem>
							<ModalHeader title={'Update Order Status'} />
						</StackItem>
						<StackItem isStretch isGrow>
							{isLoading ? (
								<Stack isVertical>
									<StackItem isGrow isStretch align="center">
										<Loader />
									</StackItem>
								</Stack>
							) : (
								<ModalContent
									selectedOrders={selectedOrders}
									orders={orders}
									dropdownTypes={dropdownTypes}
									selectedOption={selectedOption}
									setSelectedOption={setSelectedOption}
									selectedTab={selectedTab}
								/>
							)}
						</StackItem>
						<StackItem isStretch>
							<ModalActions
								secondaryButtonName={'Close'}
								primaryButtonName={'Update'}
								primaryButtonClick={handleUpdateOrder}
								secondaryButtonClick={hideModal}
								getPrimaryDisabledStatus={getUpdateDisabledStatus}
								isLoading={isLoading}
							/>
						</StackItem>
					</Stack>
				</SheetBody>
			</Sheet>
		</Modal>
	);
};

export default ThetaUpdateOrderStatusModal;
