// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';

export const initialState: any = {
  storeSchedules: [],
  selectedSchedule: undefined,
  isLoading: {},
  filters: {
    scheduleId: undefined,
    itemsPerPage: 50,
    pageNumber: 1,
    search: [],
    sortDirection: 'ASC',
  },
  isStoreScheduleModalVisible: false,
  isScheduleDaysModalVisible: false,
};

const slice = createSlice({
  initialState,
  name: 'storeSchedules',
  reducers: {
    updateStoreSchedulesList(state, { payload: { data } }) {
      return {
        ...state,
        storeSchedules: data,
      };
    },
    updateSelectedSchedule(state, { payload: { selectedSchedule } }) {
      return {
        ...state,
        selectedSchedule,
      };
    },
    updateLoader(state, { payload: { eventName, isLoading } }) {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [eventName]: isLoading,
        },
      };
    },
    updateStoreScheduleModalVisibility(
      state,
      { payload: { isStoreScheduleModalVisible } }
    ) {
      return {
        ...state,
        isStoreScheduleModalVisible,
      };
    },
    updateScheduleDaysModalVisibility(
      state,
      { payload: { isScheduleDaysModalVisible } }
    ) {
      return {
        ...state,
        isScheduleDaysModalVisible,
      };
    },
  },
});

export default slice;

export const {
  updateStoreSchedulesList,
  updateLoader,
  updateSelectedSchedule,
  updateStoreScheduleModalVisibility,
  updateScheduleDaysModalVisibility,
} = slice.actions;
