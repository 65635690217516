// @ts-nocheck
import * as dynamicGridReducer from 'models/dynamicGrid/reducer';
import _ from 'lodash';
import * as sagaActions from './sagaActions';
import { put, takeEvery, select } from 'redux-saga/effects';
import { notification } from 'antd';
import * as slice from './reducer';
import { v4 as uuidv4 } from 'uuid';
import { getQueries } from './selectors';
import { refreshGridData, updateGrid } from 'models/dynamicGrid/sagaActions';
import { getGridFilters } from 'models/dynamicGrid/selector';
import * as dynamicGridSlice from 'models/dynamicGrid/reducer';
import { getDispatch, getState } from 'reducers';

export default function* rootSaga() {
	function* createUpdateSearchQuery({ payload }: any) {
		const { id, selectedCriteria, searchInput, searchInputEnd, tableId, selectedOperator, isUpdate } =
			payload;
		let searchQueries = yield select(state => getQueries(state, payload.tableId));
		searchQueries = searchQueries || [];
		if (id && searchQueries.length) {
			searchQueries = _.map(searchQueries, q => {
				if (q.id === id) {
					return {
						id,
						criteria: selectedCriteria,
						query:
							selectedOperator && (selectedOperator.value === 'btw' || selectedOperator.value === 'nbtw')
								? { from: searchInput, to: searchInputEnd }
								: searchInput,
						operator: selectedOperator
					};
				}
				return q;
			});
		} else {
			searchQueries = [
				...searchQueries,
				{
					id: id || uuidv4(),
					criteria: selectedCriteria,
					query:
						selectedOperator && (selectedOperator.value === 'btw' || selectedOperator.value === 'nbtw')
							? { from: searchInput, to: searchInputEnd }
							: searchInput,
					operator: selectedOperator
				}
			];
		}

		yield put(slice.updateSearchQueries({ searchQueries, tableId }));
		yield reloadGrid(tableId, searchQueries);
	}

	function reloadGrid(tableId, search, isStorageEnabled = true) {
		const dispatch = getDispatch();
		let filters = getGridFilters(getState(), tableId);

		let index =
			filters &&
			filters.search &&
			_.findIndex(filters.search, function (singleCreteria) {
				return singleCreteria?.criteria?.rowIdentifier === 'OrderStatusID';
			});
		let updatedFiltes = [...search];
		dispatch(
			updateGrid({
				tableId,
				filters: {
					search: _.compact(updatedFiltes),
					pageNumber: 1,
					itemsPerPage: 50
				},
				isStorageEnabled
			})
		);
	}

	function* removeQuery({ payload }: any) {
		const dispatch = getDispatch();
		const { query, tableId } = payload;
		let searchQueries = yield select(state => getQueries(state, tableId));
		searchQueries = _.filter(searchQueries, q => q.id !== query.id);
		let filters = getGridFilters(getState(), tableId);
		yield put(slice.updateSearchQueries({ searchQueries, tableId }));

			yield put(dynamicGridSlice.updateFilters({ tableId, filters: { ...filters, search: searchQueries } }));
			dispatch(
				refreshGridData({
					tableId,
				})
			);
	}

	function* cleanupFilters({ payload }: any) {
		yield put(slice.updateSearchQueries({ searchQueries: null, tableId }));
	}

	async function updateAgQueries({ payload }) {
		const dispatch = getDispatch();
		const { searchQueries, tableId } = payload;
		let filters = getGridFilters(getState(), tableId);
		dispatch(slice.updateSearchQueries({ searchQueries, tableId }));
		dispatch(dynamicGridSlice.updateFilters({ tableId, filters: { ...filters, search: searchQueries } }));
		dispatch(
			dynamicGridSlice.updateSelectedRows({
				tableId,
				selectedRowKeys: [],
				isSelectAll: false
			})
		);

	}
	return [
		yield takeEvery(sagaActions.createUpdateSearchQueries, createUpdateSearchQuery),
		yield takeEvery(sagaActions.removeQuery, removeQuery),
		yield takeEvery(sagaActions.updateAgQueries, updateAgQueries)
	];
}
