// @ts-nocheck
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import Modal from 'atoms/modal/modal';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import { Tab } from 'atoms/tab/tab';
import Tabs from 'atoms/tabs/tabs';
import _ from 'lodash';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import './adModal.scss';
import StartAd from './startAd';
import SelectStores from './selectStores';
import SelectSkus from './selectSkus';
import EnterPricing from './enterPricing';
import ReviewAd from './reviewAd';
import { useSelector, useDispatch } from 'react-redux';
import * as adModalSelectors from 'pages/advertising/adModal/selector';
import * as adModalSagaActions from 'pages/advertising/adModal/sagaActions';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import { AD_MODAL_TABLE_IDS } from './constants';
import Scrollable from 'atoms/scrollable/scrollable';
import ModalHeader from 'atoms/modal/modalHeader';
import ReactModal from 'atoms/modal/reactModal';
import { isLoadingEnabled } from 'models/loaders/selectors';
import Loader from 'atoms/loader';

type TProps = {
	name: string;
	hideModal: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
};

const AD_MODAL_STEPS = {
	START_AD: 'startAd',
	SELECT_STORES: 'selectStores',
	SELECT_SKUS: 'selectSkus',
	ENTER_PRICING: 'enterPricing',
	REVIEW_AD: 'reviewAd'
};

const AdModal = (props: TProps) => {
	const { hideModal, name } = props;
	const dispatch = useDispatch();

	React.useEffect(() => {
		return () => {
			dispatch(adModalSagaActions.cleanUp());
		};
	}, [dispatch]);

	const steps = [
		{
			title: 'Start Ad',
			identifier: AD_MODAL_STEPS.START_AD
		},
		{
			title: 'Select Stores',
			identifier: AD_MODAL_STEPS.SELECT_STORES
		},
		{
			title: 'Select SKUs',
			identifier: AD_MODAL_STEPS.SELECT_SKUS
		},
		{
			title: 'Enter Pricing',
			identifier: AD_MODAL_STEPS.ENTER_PRICING
		},
		{
			title: 'Review Ad',
			identifier: AD_MODAL_STEPS.REVIEW_AD
		}
	];

	const [selectedStep, setSelectedStep] = React.useState<any>(steps[0]);

	const adData: any = useSelector(state => adModalSelectors.getAdModalData(state, 'advertising')) || {};

	const selectedStores: any = useSelector(state =>
		dynamicGridSelectors.getTableSelection(state, AD_MODAL_TABLE_IDS.STORES)
	);
	const selectedSkus = useSelector(state => dynamicGridSelectors.getTableSelection(state, AD_MODAL_TABLE_IDS.SKUS));
	const enterPricingData = useSelector(state =>
		dynamicGridSelectors.getGridData(state, AD_MODAL_TABLE_IDS.ENTER_PRICING)
	);
	const isLoading = useSelector(state => isLoadingEnabled(state, 'adTypesModal'));
	const getDisabledStatus = (identifier: string) => {
		switch (identifier) {
			case AD_MODAL_STEPS.START_AD:
				return false;
			case AD_MODAL_STEPS.SELECT_STORES:
				return !Boolean(Object.keys(adData)?.length);
			case AD_MODAL_STEPS.SELECT_SKUS:
				return !Boolean(Object.keys(adData).length) || !selectedStores?.selectedRowKeys?.length;
			case AD_MODAL_STEPS.ENTER_PRICING:
				return (
					!Boolean(Object.keys(adData).length) ||
					!selectedStores?.selectedRowKeys?.length ||
					!selectedSkus?.selectedRowKeys?.length ||
					!enterPricingData?.length
				);
			case AD_MODAL_STEPS.REVIEW_AD:
				return (
					!Boolean(Object.keys(adData)?.length) ||
					!selectedStores?.selectedRowKeys?.length ||
					!selectedSkus?.selectedRowKeys?.length ||
					!enterPricingData?.length
				);
			default:
				return true;
		}
	};

	const getScreen = React.useCallback(() => {
		switch (selectedStep.identifier) {
			case AD_MODAL_STEPS.SELECT_STORES:
				return (
					<SelectStores
						goBack={() => setSelectedStep(steps[0])}
						handleNextClick={() => setSelectedStep(steps[2])}
						hideModal={hideModal}
					/>
				);
			case AD_MODAL_STEPS.SELECT_SKUS:
				return (
					<SelectSkus
						goBack={() => setSelectedStep(steps[1])}
						handleNextClick={() => setSelectedStep(steps[3])}
						hideModal={hideModal}
					/>
				);
			case AD_MODAL_STEPS.ENTER_PRICING:
				return (
					<EnterPricing
						goBack={() => setSelectedStep(steps[2])}
						handleNextClick={() => setSelectedStep(steps[4])}
						hideModal={hideModal}
					/>
				);
			case AD_MODAL_STEPS.REVIEW_AD:
				return (
					<ReviewAd
						goBack={() => setSelectedStep(steps[3])}
						handleNextClick={hideModal}
						hideModal={hideModal}
					/>
				);
			case AD_MODAL_STEPS.START_AD:
			default:
				return <StartAd handleNextClick={() => setSelectedStep(steps[1])} hideModal={hideModal} />;
		}
	}, [selectedStep.identifier]);
	const isEditMode: boolean = useSelector(state => adModalSelectors.getEditMode(state)) || false;

	return (
		<ReactModal hideModal={hideModal} height={800} width={800}>
			<ModalHeader>
				<Stack isVertical gutter="small">
					<StackItem isStretch>
						<Heading>{isEditMode ? `Editing: ${adData.AdTitle}` : 'Create New Ad'}</Heading>
					</StackItem>
					<StackItem isStretch>
						<Divider />
					</StackItem>
					<StackItem isStretch>
						<Stack isVertical gutter="small">
							<StackItem isStretch>
								<Tabs>
									{_.map(steps, (step, index) => {
										return (
											<Tab
												key={index}
												value={index}
												isSelected={selectedStep.identifier === step.identifier}
												onSelectTabItem={() => setSelectedStep(step)}
												disabled={getDisabledStatus(step.identifier)}
											>
												{step.title}
											</Tab>
										);
									})}
								</Tabs>
							</StackItem>
						</Stack>
					</StackItem>
				</Stack>
			</ModalHeader>
			{isLoading ? <Loader /> : getScreen()}
		</ReactModal>
	);
};

export default AdModal;
