// @ts-nocheck
// userSlice.js

import { createSlice } from '@reduxjs/toolkit';

// Initial state
export const initialState = {};

// Slice
const slice = createSlice({
	name: 'autoCompleteSelector',
	initialState,
	reducers: {
		updateAutoCompleteData: (state, { payload: { componentId, data, status, isAppend } }) => {
			if (state[componentId]) {
				state[componentId].data = data
					? isAppend
						? [...state[componentId].data, ...data]
						: data
					: state[componentId].data;
				state[componentId].status = status ?? state[componentId].status;
			} else {
				state[componentId] = {
					data,
					status
				};
			}
		},
		updateComponentMetaData: (state, { payload: { componentId, metaData } }) => {
			state[componentId].metaData = metaData
		}
	}
});

// Actions
export const { updateAutoCompleteData, updateComponentMetaData } = slice.actions;

// Reducer
export default slice;
