// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";
import * as constants from "./constants";
import { TTransactionReducer } from "./types";

export const initialState: TTransactionReducer = {
  info: {
    Available_Cost_Consumed: 0,
    Available_Qty_Consumed: 0,
    Current_Available_Cost: 0,
    Current_Available_Qty: 0,
    Initial_Available_Cost: 0,
    Initial_Available_Qty: 0,
  },
  openModalName: "",
  loading: false,
};

const slice = createSlice({
  initialState,
  name: "availability",
  reducers: {
    updateAvailabilityInfo(state, { payload: { info } }) {
      return {
        ...state,
        info,
      };
    },
  },
});

export default slice;

export const { updateAvailabilityInfo } = slice.actions;
