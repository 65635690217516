// @ts-nocheck
import { postAPIData, checkLocal } from 'utils/api';

let count = 0;

export const getReportData = async (mode = 'new') => {
	try {
		if (checkLocal()) {
			count = count + 1;
			if (count % 2) {
				return require('./dummy-1.json');
			}

			return require('./dummy.json');
		}

		const response = await postAPIData('dbselect', { mode });
		return response?.data;
	} catch (error) {
		console.error(error);
		return {};
	}
};
