// @ts-nocheck
import * as React from 'react';
import './datePicker.scss';
import moment from 'moment';
import classNames from 'classnames';
import { DatePicker } from 'antd';
import { formatDate, formatDateTz } from 'utils/utilities';
import _ from 'lodash';

interface Props {
	selectedDate: string;
	handleClick: React.ChangeEventHandler;
	name: string;
	min?: string;
	max?: string;
}

const { RangePicker } = DatePicker;

const DateWrapper = (props: Props) => {
	const { selectedDate, handleClick, name, min, max, placeholder, isRange, allowEmpty } = props;

	const today = moment().format('MM/DD/YYYY');
	const classes: any = classNames(['datePicker']);
	if (isRange) {
		return (
			<DatePicker.RangePicker
				allowEmpty={allowEmpty && isRange ? [true, true] : false}
				disabledDate={d => !d || (min && d.isBefore(min)) || (max && d.isAfter(max))}
				className={classes}
				// value={selectedDate}
				defaultValue={_.compact([
					selectedDate?.[0] ? formatDateTz(selectedDate?.[0]) : '',
					selectedDate?.[1] ? formatDateTz(selectedDate[1]) : ''
				])}
				// disabledDate={d => !d || (min && d.isBefore(min)) || (max && d.isAfter(max))}
				// onCalendarChange={val => setDates(val)}
				placeholder={placeholder}
				format={'MM/DD/YYYY'}
				onMouseDown={e => {
					//
					// if (moment(e.target.value)._isValid) {
					// 	handleClick({
					// 		target: {
					// 			value: e || '',
					// 			name
					// 		}
					// 	});
					// }
					e.preventDefault();
					e.stopPropagation();
				}}
				onChange={d => {
					handleClick({
						target: {
							value: d || '',
							name
						}
					});
				}}
				// onOpenChange={onOpenChange}
			/>
		);
	}
	return (
		<DatePicker
			className={classes}
			disabledDate={d => !d || (min && d.isBefore(min)) || (max && d.isAfter(max))}
			defaultValue={selectedDate ? formatDateTz(selectedDate) : null}
			// value={selectedDate}
			name={name}
			placeholder={placeholder}
			format={'MM/DD/YYYY'}
			onMouseDown={e => {
				//
				// if (moment(e.target.value)._isValid) {
				// 	handleClick({
				// 		target: {
				// 			value: e || '',
				// 			name
				// 		}
				// 	});
				// }
				e.preventDefault();
				e.stopPropagation();
			}}
			onChange={d => {
				handleClick({
					target: {
						value: d || '',
						name
					}
				});
			}}
		/>
		// <input
		// 	className={classes}
		// 	type="date"
		// 	min={min || today}
		// 	max={max}
		// 	defaultValue={moment(selectedDate).format('YYYY-MM-DD') || ''}
		// 	value={moment(selectedDate).format('YYYY-MM-DD') || ''}
		// 	onChange={handleClick}
		// />
	);
};

export default DateWrapper;
